import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import { clearVersionAction, editVersionAction } from '../../../redux/action/versionAction';
import { useDispatch } from 'react-redux';
import Switch from "react-switch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { BASE_URL } from '../../../resources/APIEndpoints';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AWS from 'aws-sdk'
import '../../css/games.css'

function EditVersionModel({show, onHide}) {
  const dispatch = useDispatch()

  const close = () => {
    setFileName('')
    dispatch(clearVersionAction())
    onHide();
  }



  let versionDetails = useSelector((state)=>{
    return state.version.versionsDetail
  })

  const [platform, setPlatform] = useState("")
  const [minorVersion, setMinorVersion] = useState("")
  const [majorVersion, setMajorVersion] = useState("")
  const [patchVersion, setPatchVersion] = useState("")

  const [env, setEnv] = useState()

  useEffect(()=>{
    if(versionDetails){
      setPlatform(versionDetails.platform)
      setMinorVersion(versionDetails.minorVersion)
      setMajorVersion(versionDetails.majorVersion)
      setPatchVersion(versionDetails.patchVersion)
      setMajorRequired(versionDetails.isMajorVersionRequired)
      setMinorRequired(versionDetails.isMinorVersionRequired)
      setPatchRequired(versionDetails.isPatchVersionRequired)
    }else{
        
    }

    let envType = localStorage.getItem('type')
    setEnv(envType)

}, [versionDetails, minorVersion])



    const [majorError, setMajorErr] = useState(false)
    const [minorError, setMinorErr] = useState(false)
    const [patchError, setPatchErr] = useState(false)
    const [platformError, setPlatformErr] = useState(false)

    const onPlatform = (e) => {
        if(e.target.value === "" || e.target.value === null){
            setPlatformErr(true)
        }else{
            setPlatform(e.target.value)
            setPlatformErr(false)
        }
    }

    const onMajorVersion = (e) => {
        if(e.target.value === "" || e.target.value === null){
            setMajorErr(true)
        }else{
            setMajorVersion(parseInt(e.target.value))
            setMajorErr(false)
        }
    }

    const onMinorVersion = (e) => {
        if(e.target.value === "" || e.target.value === null){
            setMinorErr(true)
        }else{
            setMinorVersion(parseInt(e.target.value))
            setMinorErr(false)
        }
    }

    const onPatchVersion = (e) => {
        if(e.target.value === "" || e.target.value === null){
            setPatchErr(true)
        }else{
            setPatchVersion(parseInt(e.target.value))
            setPatchErr(false)
        }
    }

    const validation = () => {
        if(majorVersion === null || majorVersion === ''){
            setMajorErr(true)
        }
        if(minorVersion === null || minorVersion === ''){
            setMinorErr(true)
        }
        if(patchVersion === null || patchVersion === ''){
            setPatchErr(true)
        }
        if(platform === undefined || platform === '' || platform === undefined){
            setPlatformErr(true)
        }
    }




  const editVersion = () => {

        const editVersionData = {
            majorVersion : majorVersion,
            minorVersion : minorVersion,
            patchVersion : patchVersion,
            platform : platform,
            appDownloadUrl : appUrl
        }

        if(editVersionData.majorVersion === null || editVersionData.minorVersion === null || editVersionData.patchVersion === null || editVersionData.majorVersion === '' || editVersionData.minorVersion === '' || editVersionData.patchVersion === '' || editVersionData.platform === "" || editVersionData.platform === undefined){
            validation()
        }else{
            dispatch(editVersionAction(editVersionData))
            setFileName('')
            onHide();
        }

    }

    const[majorRequired, setMajorRequired] = useState()
    const onMajor = () => {
        setMajorRequired(!majorRequired)
    }

    const[minorRequired, setMinorRequired] = useState()
    const onMinor = () => {
        setMinorRequired(!minorRequired)
    }

    const[patchRequired, setPatchRequired] = useState(true)
    const onPatch = () => {
        setPatchRequired(!patchRequired)
    }

    const [fileName, setFileName] = useState()
    const [appUrl, setAppUrl] = useState()

    const[loading, setLoading] = useState(false)

    const [total, setTotal] =  useState()
    const [loaded, setLoaded] = useState()
    const [percentage, setPercentage] = useState()

    const onUploadProgress = (progressEvent) => {
        const {loaded, total} = progressEvent;
        let percentage = Math.floor((loaded*100)/total)
        setPercentage(percentage)
        setTotal(total)
        setLoaded(loaded)
        document.getElementById('successfull').style.width = `${percentage}px`
    }

    const onAPKUpload = async(e) => {
        setFileName(e.target.files[0].name)
        setLoading(true)

        var file = e.target.files[0];  

        var fileNameEnv = ''
        
        if(env == 'Production'){
            fileNameEnv = `builds/production/${e.target.files[0].name}`
        }else if(env == 'Staging'){
            fileNameEnv = `builds/staging/${e.target.files[0].name}`
        }else if(env == 'Development'){
            fileNameEnv = `builds/dev/${e.target.files[0].name}`
        }

        let res = await axios.get(`${BASE_URL}/upload-url?contentType=application/vnd.android.package-archive`, {
            params : {fileName : fileNameEnv}
        })
        setAppUrl(res.data.getUrl)
        let putObjectData = await axios.put(res.data.postUrl, file,
            {
                headers: { 'CONTENT-TYPE' : 'application/vnd.android.package-archive' },
                onUploadProgress
            }
        )
        if(res.status == 200){
            toast.success('File Uploaded Successfully.')
            setLoading(false)
        }else{
            toast.success('Please Reupload the File.')
        }

        // const bucketName = 'playstarz-staging'

        // let s3 = new AWS.S3({
        //     accessKeyId: "AKIAJMJH63QEIQBIPVXA",
        //     secretAccessKey: "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",
        //     region: "ap-south-1"
        // });

        // let params = {
        //     ContentType: 'application/vnd.android.package-archive',
        //     Bucket: bucketName,
        //     Body: file,
        //     ACL: 'public-read',
        //     Key : 'gamestarz.apk',
        // }
        // s3.putObject(params, (err, data)=>{
        //   if(err){

        //   }else{
        //     toast.success("Files Uploaded Successfully.")
        //     setAppUrl('https://playstarz-staging.s3.ap-south-1.amazonaws.com/gamestarz.apk')
        //     setLoading(false)
        //   }
        // })

    }

    return (
      <div>
        <ToastContainer />
          <Modal className='ModalCustom' scrollable={true} size="lg" centered show={show} onHide={onHide}>
              <Modal.Header>
                  <div className="modal_head">
                      <h6>Edit Version</h6>
                  </div>
              </Modal.Header>
              <Modal.Body className='modal_body'>
                    <Row>
                        <Col lg={6}>
                            <label className='my-2'>Platform</label>
                            <input type='text' disabled defaultValue={versionDetails.platform} className='form-control' name='platform' onChange={(e)=>{setPlatform(e.target.value)}}/>
                            {platformError === true ?  <span style={{color : "red"}}>*Platform is Required</span> : ""}
                        </Col>
                        <Col lg={6}>
                          <label className='my-2'>Major Version</label>
                          <input type='number' defaultValue={versionDetails.majorVersion}  min='0' className='form-control' name='majorVersion' onChange={(e)=>{setMajorVersion(parseInt(e.target.value))}}/>
                          {majorError === true ?  <span style={{color : "red"}}>*Major version is Required</span> : ""}
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <label className='my-2'>Minor Version</label>
                            <input type='number' defaultValue={versionDetails.minorVersion}  min='0' className='form-control' name='ninorVersion' onChange={(e)=>{setMinorVersion(parseInt(e.target.value))}}/>
                            {minorError === true ?  <span style={{color : "red"}}>*Minor version is Required</span> : ""}
                        </Col>  
                        <Col lg={6}>
                            <label className='my-2'>Patch Version</label>
                            <input type='number' defaultValue={versionDetails.patchVersion} min='0' className='form-control' name='patchVersion' onChange={(e)=>{setPatchVersion(parseInt(e.target.value))}}/>
                            {patchError === true ?  <span style={{color : "red"}}>*Patch version is Required</span> : ""}
                        </Col>
                    </Row>

                    <Row className='mt-2'>
                        <Col lg={6}>
                            <label>Major Version Required</label> <br />
                            <Switch disabled onChange={()=>{onMajor()}} checked={majorRequired}/>
                        </Col>

                        <Col>
                            <label>Minor Version Required</label> <br />
                            <Switch disabled onChange={()=>{onMinor()}} checked={minorRequired} />
                        </Col>
                    </Row>

                    <Row className='mt-2'>
                        <Col xl={6} lg={6} md={6}>
                            <label>Patch Version Required</label> <br />
                            <Switch disabled onChange={()=>{onPatch()}} checked={patchRequired}/>
                        </Col>
                        { versionDetails.platform == 'android' || versionDetails.platform == "Android" || versionDetails.platform == "ANDROID" ?
                        <Col xl={6} lg={6} md={6} className='input-file'>
                            <label>Upload File</label> <br />
                            <div className='d-flex align-items-center'>
                                <input id="PD" type="file" className="form-control" onChange={(e)=>{onAPKUpload(e)}} />
                                <label for="PD" className='label-btn'>Choose File</label> 
                                {<span className='fileName'>{fileName != null || fileName != '' || fileName != undefined ? fileName : fileName == null || fileName == '' || fileName == undefined ? '' : ''}</span>}       
                            </div>
                            {loading == true ? 
                                <div className='container-div my-2'>
                                    <div id='percentage' className='percentage'>
                                        <div id ='successfull' className='successfull'></div>
                                    </div>
                                    <div className='progress-bar'>{loaded} bytes of {total} bytes : {percentage} %</div>
                                </div>
                                // <span className='my-2'>{percentage}% of 100%</span>
                                // <span class="flex_display">
                                //     <span className="Loading_Heading">Uploading</span>
                                //     <span className="Loading_One"></span>
                                //     <span className="Loading_Two"></span>
                                //     <span className="Loading_Three"></span>
                                // </span>
                            : ""}
                        </Col>
                        : ''}  
                    </Row> 
              </Modal.Body>
              <Modal.Footer>
                  <Row>
                      <Col>
                          <Button className="btn btn-success btn -sm mx-2" onClick={editVersion}>Save</Button>
                          <Button className="btn btn-danger btn -sm mx-2" onClick={close}>Cancel</Button>
                      </Col>
                  </Row>
              </Modal.Footer>
      </Modal> 
  </div>
)

}

export default EditVersionModel