import { TOTAL_MONEY_COLLECTED, TOTAL_LIVE_COMPETITIONS, TOTAL_CHALLENGES, TOTAL_PAID_CHALLENGES } from "../actionType/actionTypes";
import AnalyticsServices from "../../services/AnalyticsServices";

export const getTotalMoneyCollectedAction = (body) => {
    return async (dispatch) => {
        await AnalyticsServices.getMoneyCollected(body)
        .then(res=> {
            dispatch(getMoneyCollected(res.data.data))
        })
    }
}

export const getTotalLiveCompetitionAction = (body) => {
    return async (dispatch) => {
        await AnalyticsServices.getTotalLiveCompetition(body)
        .then(res=>{
            dispatch(getTotalLiveCompetition(res.data.data[0].livecompetitions))
        })
    }
}

export const getTotalChallengesAction = (body) => {
    return async (dispatch) => {
        await AnalyticsServices.getTotalChallenges(body)
        .then(res=>{
            dispatch(getTotalChallenges(res.data.totalChallenges))
            dispatch(getTotalPaidChallenges(res.data.paidChallenges))
        })
    }
} 

export const getMoneyCollected = (data) => {
    return {
        type : TOTAL_MONEY_COLLECTED,
        payload : data
    }
}

export const getTotalLiveCompetition = (data) => {
    return {
        type : TOTAL_LIVE_COMPETITIONS,
        payload : data
    }
}

export const getTotalChallenges = (data) => {
    return {
        type : TOTAL_CHALLENGES,
        payload : data
    }
}

export const getTotalPaidChallenges = (data) => {
    return {
        type : TOTAL_PAID_CHALLENGES,
        payload : data
    }
}