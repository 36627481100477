
import {PPSALL_ID,EDIT_PLAYSTARZ_SCORING, CREATE_CUSTOM_LEADERBOARD, CLEAR_LEADERBOARD, GET_LINK, ALL_LEADERBOARD, GET_PLAYER_DISTRIBUTION, SEARCH_LEADERBOARD, CREATE_PD_LEADERBOARD, CREATE_PLAYSTARZ_SCORING, GET_PLAYSTARZ_SCORING ,LEADERBOARD_BY_ID, GET_UNIVERSAL_LEADERBOARD, GET_LEADERBOARD_DETAIL, SEARCH_UNIVERSAL_LEADERBOARD, SEARCH_LEADERBOARD_DETAILS} from "../actionType/actionTypes";

const initialState = {
    leaderboards : [],
    leaderboardDetail : [],
    UniversalLeaderboardDetail : [],
    leaderboardNew : [],
    PPSALL : [],
    Link : [],
    isLoading: true
}

export const leaderboardReducer = (state = initialState, action) => {

    switch(action.type){

        case ALL_LEADERBOARD : {
            return {
                ...state,
                leaderboards : action.payload,
                isLoading: false
            }
        }

        case LEADERBOARD_BY_ID : {
            return {
                ...state,
                leaderboardDetail : action.payload
            }
        } 
        
        case GET_UNIVERSAL_LEADERBOARD : {
            return {
                ...state,
                UniversalLeaderboardDetail : action.payload,
                isLoading: false
            }
        }

        case GET_LEADERBOARD_DETAIL : {
            return {
                ...state,
                leaderboardNew : action.payload,
                isLoading: false
            }
        }

        case SEARCH_LEADERBOARD : {
            return {
                ...state,
                leaderboards: action.payload,
                // isLoading: false
            }
        }
     
        case CLEAR_LEADERBOARD : {
            return {
                ...state,
                leaderboardDetail : []
            }
        }

        case PPSALL_ID : {
            return {
                ...state,
                Id : action.payload
            }
        }

        case GET_LINK : {
            return {
                ...state,
                Link : action.payload
            }
        }

        case EDIT_PLAYSTARZ_SCORING : {
            return {
                ...state,
                PPSALL : action.payload
            }
        }

        case CREATE_PD_LEADERBOARD : {
            return {
                ...state,
                playerDistribution : action.payload
            }
        }

        case GET_PLAYER_DISTRIBUTION : {
            return {
                ...state,
                playerDistribution : action.payload
            }
        }

        case CREATE_PLAYSTARZ_SCORING : {
            return {
                ...state,
                PPSALL: action.payload
            }
        }

        case GET_PLAYSTARZ_SCORING : {
            return {
                ...state,
                PPSALL: action.payload
            }
        }

        case SEARCH_UNIVERSAL_LEADERBOARD : {
            return {
                ...state,
                UniversalLeaderboardDetail : action.payload
            }
        }

        case SEARCH_LEADERBOARD_DETAILS : {
            return {
                ...state,
                leaderboardDetail : action.payload
            }
        }

        case CREATE_CUSTOM_LEADERBOARD : {
            return {
                ...state,
                leaderboards : action.payload
            }
        }

        default : {
            return {
                ...state
            }
        }

    }

}