import axios from '../utlis/interceptor'
import {BASE_URL, SEARCH_TEMPLATE, STOP_COMPETITION, GET_COMPETITION, CREATE_COMPETITION, EDIT_COMPETITION, SEARCH_COMPETITION, GET_COMPETITION_DETAIL, GET_TEMPLATES, CREATE_TEMPLATES, EDIT_TEMPLATES, GET_PRIZE_DISTRIBUTION, GET_ORGANIZED_COMPETITION, CREATE_ORGANIZED_COMPETITION, EDIT_ORGANIZED_COMPETITION } from "../resources/APIEndpoints";

function competitionAction() {
    this.getallCompetition = (body) => axios.post(BASE_URL + GET_COMPETITION, body)
    this.createCompetition = (body) => axios.post(BASE_URL + CREATE_COMPETITION, body)
    this.editCompetition = (body) => axios.post(BASE_URL + EDIT_COMPETITION, body)
    this.searchCompetition = (body) => axios.post(BASE_URL + SEARCH_COMPETITION, body)
    this.getallCompetitionDetail = (body) => axios.post(BASE_URL + GET_COMPETITION_DETAIL, body)
    this.getallCompetitionById = (body) => axios.post(BASE_URL + GET_COMPETITION, body)
    this.stopCompetition = (body) => axios.post(BASE_URL + STOP_COMPETITION, body)

    this.getOrganizedCompetition = (body) => axios.post(BASE_URL + GET_ORGANIZED_COMPETITION, body)
    this.createOrganizedCompetition = (body) => axios.post(BASE_URL + CREATE_ORGANIZED_COMPETITION, body)
    this.editOrganizedCompetition = (body) => axios.post(BASE_URL + EDIT_ORGANIZED_COMPETITION, body)

    this.getAllTemplates = (body) => axios.post(BASE_URL + GET_TEMPLATES, body)
    this.getTemplateById = (body) => axios.post(BASE_URL + GET_TEMPLATES, body)
    this.createTemplates = (body) => axios.post(BASE_URL + CREATE_TEMPLATES, body)
    this.editTemplates = (body) => axios.post(BASE_URL + EDIT_TEMPLATES, body)
    this.searchTemplate = (body) => axios.post(BASE_URL + SEARCH_TEMPLATE, body)
    this.getPD = (body) => axios.post(BASE_URL + GET_PRIZE_DISTRIBUTION, body)
}

export default new competitionAction()
