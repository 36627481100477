import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import { Link} from "react-router-dom";
import { Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useDispatch, useSelector} from 'react-redux';

export default function AttributeDetailModal({show, onHide}) {

    let attributeDetail = useSelector((state)=>{
        return state.businessLogic.attributeDetails
    })

    const close = () => {
        onHide();
    }

    if(attributeDetail && attributeDetail.length != 0){
        return (
            <Modal scrollable={true} contentClassName="new_modal" size="lg" centered show={show} onHide={onHide} className='competitiondetail-modal'>
                <Modal.Header>
                    <div className="modal_head">
                        <span className='competitiondetail-heading'>Attribute Detail</span>
                    </div>
                </Modal.Header>
    
                <Modal.Body className='competitiondetail-body'>
                    <Row className='my-3'>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <label>Attribute Id</label>
                            <input className='form-control' disabled defaultValue={attributeDetail[0].id != "" ? attributeDetail[0].id : "-"} />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <label>Attribute Name</label>
                            <input className='form-control' disabled defaultValue={attributeDetail[0].attributeName != "" ? attributeDetail[0].attributeName : "-"} />
                        </Col>
                    </Row>

                    <Row className='my-3'>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <label>Data Type</label>
                            <input className='form-control' disabled defaultValue={attributeDetail[0].dataTypeMaster.dataTypeName != "" ? attributeDetail[0].dataTypeMaster.dataTypeName : "-"} />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <label>Operators</label>
                            <p className='input-bg'>{attributeDetail[0].dataTypeMaster != null && attributeDetail[0].dataTypeMaster.operator.map((e, i)=>{return(<span>{i+1}&#93; &nbsp; {e.operatorSymbol} &nbsp;</span>)})}</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='competition-footer'>
                    <Row>
                        <Col>
                            <Button className="btn btn-danger btn -sm mx-2" onClick={close}>Cancel</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }else{
        return(
            <p></p>
        )
    }
}
