import React, {useEffect, useState}  from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import { Link} from "react-router-dom";
import { Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from 'react-redux';
import { editReferralAction, getAllReferralAction } from '../../../redux/action/referralAction';

function AddReferralModal({show, onHide}){

    const dispatch = useDispatch();
    const [id, setId] = useState()
    const [userAction, setUserAction] = useState()
    const [events, setEvents] = useState()
    const [batches, setBatches] = useState()
    const [rewardType, setRewardType] = useState()
    const [referralReward, setReferralReward] = useState()
    const [refereeReward, setRefereeReward] = useState()


    let referralData = useSelector((state)=>{ 
        return state.referral.referralDetail
    })

    useEffect(()=>{
        if(referralData.length){
            setId(referralData[0].id)
            setUserAction(referralData[0].referralActionTypeId)
            setEvents(referralData[0].noOfEvents)
            setBatches(referralData[0].noOfBatches)
            setRewardType(referralData[0].referralRewardsType.id)
            setReferralReward(referralData[0].referrerValue)
            setRefereeReward(referralData[0].referredValue)
        }else{
            
        }
    }, [referralData])

    // setUserAction()
    const edit = () => {

        const editReferral = [{
            id: id,
            referrerValue: referralReward,
            referredValue: refereeReward,
            referralActionTypeId: userAction,
            referralRewardsTypeId: rewardType,
            noOfEvents: events,
            noOfBatches: batches
        }]
        dispatch(editReferralAction(editReferral))
        onHide();
    }

    const close = () => {
        onHide();
    }    

    
    if(referralData && referralData.length){
        return (
            <React.Fragment>
               <div>
                    <Modal scrollable={true} size="xl" centered show={show} onHide={onHide}>
                    <Modal.Header>
                        <h5>Edit Referral</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Row className='my-3'>
                                <Col lg={6} md={6} sm={12}>
                                    <label className='mb-2'>User Action</label>
                                    <select className='form-control' onChange={(e)=>{setUserAction(parseInt(e.target.value))}}>
                                        <option value=''>{userAction === 1 ? "invite + signup" : userAction === 2 ? "play x no of matches" : userAction === 3 ? "deposit x amount" : userAction === 4 ? "participate in 'x' no of competitions" : userAction === 5 ? "make purchases worth 'x' from store" : '-'}</option>
                                        <option value='1'>Invite + Sign Up 'x' friends</option>
                                        <option value='2'>Play 'x' no of matches</option>
                                        <option value='3'>Deposit 'x' amount</option>
                                        <option value='4'>Participate in 'x' no of competition</option>
                                        <option value='5'>Make purchase worth 'x' from store</option>
                                    </select>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <label className='mb-2'>Number of events</label>
                                    <input type='number' className='form-control' value={events} onChange={(e)=>{setEvents(parseInt(e.target.value))}}/>
                                </Col>
                            </Row>
    
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <Row>
                                        <Col lg={12} md={12} sm={12}>
                                            <h6>Referrer Rewards</h6>
                                        </Col>
                                    </Row>
    
                                    <Row className='mt-2'>
                                        <Col lg={12} md={12} sm={12}>
                                            <label className='mb-2'>Reward Type</label>
                                            <select className='form-control' onChange={(e)=>{setRewardType(parseInt(e.target.value))}}>
                                                <option value=''>{rewardType === 1 ? "bonus cash" : rewardType === 2 ? "virtual currency" : rewardType === 3 ? "inventory rewards" : "-"}</option>
                                                <option value='1'>Bonus Cash</option>
                                                <option value='2'>Virtual Currancy</option>
                                                <option value='3'>Inventory Rewards</option>
                                            </select>
                                        </Col>
                                    </Row>
    
                                    <Row className='mt-2'>
                                        <Col lg={12} md={12} sm={12}>
                                            <label className='mb-2'>Reward Structure</label>
                                            <input type='number' className='form-control' value={batches} onChange={(e)=>{setBatches(parseInt(e.target.value))}}/>
                                        </Col>
                                    </Row>
    
                                    <Row className='mt-2'>
                                        <Col lg={12} md={12} sm={12}>
                                            <label className='mb-2'>Is Recurring</label>
                                            <input type='checkbox' className='mx-2'/>
                                        </Col>
                                    </Row>
    
                                    <Row className='mt-2'>
                                        <Col lg={12} md={12} sm={12}>
                                            <label className='mb-2'>Reward</label>
                                            <input type='number' className='form-control' value={referralReward} onChange={(e)=>{setReferralReward(parseInt(e.target.value))}} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <Row>
                                        <Col lg={12} md={12} sm={12}>
                                            <h6>Referee Rewards</h6>
                                        </Col>
                                    </Row>
    
                                    <Row className='mt-2'>
                                        <Col lg={12} md={12} sm={12}>
                                            <label className='mb-2'>Reward</label>
                                            <input type='number' className='form-control' value={refereeReward} onChange={(e)=>{setRefereeReward(parseInt(e.target.value))}} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
    
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            <Col>
                                <button className='btn btn-success' onClick={edit}>Save Referral</button>
                                <Button className="btn btn-danger mx-2" onClick={close}>Cancel</Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal> 
            </div>
           </React.Fragment>
        )
    }else{return(<p></p>)}
}

export default AddReferralModal