import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import { Link} from "react-router-dom";
import { Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useDispatch, useSelector} from 'react-redux';


export default function ModulesDetailModal({show, onHide}) {

    let modulesDetail = useSelector((state)=>{
        return state.businessLogic.moduleDetails
    })

    const close = () => {
        onHide();
    }

    if(modulesDetail && modulesDetail.length != 0){
        return (
            <Modal scrollable={true} contentClassName="new_modal" size="md" centered show={show} onHide={onHide}>
                <Modal.Header>
                    <div className="modal_head">
                        <span className='competitiondetail-heading'>Module Detail</span>
                    </div>
                </Modal.Header>
    
                <Modal.Body className='competitiondetail-body'>

                    <Row className='my-3'>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <label>Module Id</label>
                            <input className='form-control' disabled defaultValue={modulesDetail[0].id != "" ? modulesDetail[0].id : "-"} />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <label>Module Name</label>
                            <input className='form-control' disabled defaultValue={modulesDetail[0].conditionName != "" ? modulesDetail[0].conditionName : "-"} />
                        </Col>
                    </Row>

                    <Row className='my-3'>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <label>Created At</label>
                            <input className='form-control' disabled defaultValue={modulesDetail[0].createdAt != "" ? modulesDetail[0].createdAt.slice(0,10) : "-"} />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            
                        </Col>
                    </Row>
    
                </Modal.Body>

                <Modal.Footer className='competition-footer'>
                    <Row>
                        <Col>
                            <Button className="btn btn-danger btn -sm mx-2" onClick={close}>Cancel</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }else{
        return(<p></p>)
    }
}
