import OrganisationService from "../../services/OrganisationService";
import { GET_PARTNERS, GET_PARTNERS_BY_ID, VERIFY_PARTNER } from "../actionType/actionTypes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getAllPartnersAction = (body) => {
    return async(dispatch) => {
        await OrganisationService.getAllPartners(body)
        .then(res=>{
            dispatch(getAllPartners(res.data.organizationData))
        })
    }
}

export const getPartnersByIdAction = (body) => {
    return async (dispatch) => {
        await OrganisationService.getPartnersById(body)
        .then(res=>{
            dispatch(getPartnersById(res.data.organizationData))
        })
    }
}

export const verifyPartnerAction = (body, id, setShowVerifyModal) => {
    return async (dispatch) => {
        await OrganisationService.verifyPartner(body)
        .then(res=>{
            if(res.data.status == 201){
                res.data.message && res.data.message.forEach((e)=>{
                    toast.success(e)
                })

                let partnersDetail = {
                    gameAdminSubmissionFilters : {
                      id : [id],
                      pagination : {}
                    }
                }
                dispatch(getPartnersByIdAction(partnersDetail))

                setShowVerifyModal(false)
            }
        })
    }
}

export const getAllPartners = (data) => {
    return {
        type : GET_PARTNERS,
        payload : data
    }
}

export const getPartnersById = (data) => {
    return {
        type : GET_PARTNERS_BY_ID,
        payload : data
    }
}

export const verifyPartner = (data) => {
    return {
        type : VERIFY_PARTNER,
        payload : data
    }
}