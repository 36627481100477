import React, { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Row, Col} from 'react-bootstrap'
import { useHistory } from "react-router";
import { loginUser } from "../redux/action/authAction";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/games.css'

function LoginPage(props) {
    const dispatch = useDispatch()
    const [details, setDetails] = useState({ username: "", password: "" });
    let history = useHistory();
    
    const userlogin = (e) => {
      if(details.username === "" || details.password === ""){
        dispatch(loginUser(details, props.history))
      }else{
        dispatch(loginUser(details, props.history))
      }
    }
    return (
    <>
    <ToastContainer />
        <div className="bg-img" style={{overflow: "hidden"}}>
            <Row>
                <Col className="col col-lg-4 col-md-4 col-sm-12">
                    <div className="container card p-3 login_Box">
                        <h2 className="text-primary text-center">Login</h2>
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="form-group my-4">
                                    <label>Username</label>
                                    <input className="form-control" type="text" name="username" onChange={(e) => setDetails({ ...details, username: e.target.value })} value={details.username}/>
                                </div>
                                <div className="form-groups my-4">
                                    <label>Password</label>
                                    <input className="form-control" type="Password" name="password" id="last-name" onChange={(e) =>setDetails({ ...details, password: e.target.value }) } value={details.password} />
                                </div>
                                <div className="text-center my-4">
                                    <button className="btn btn-primary mt-4" onClick={userlogin}>Login</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </>
    )
}

export default LoginPage;