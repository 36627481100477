import PlaystarzBankServices from "../../services/PlaystarzBankServices";
import { ADD_SCRATCH_CARD, CREATE_COUPONS, GET_SINGLE_PAYOUT, GET_COUPONS, GET_COUPON_BY_ID, GET_SCRATCH_CARD, GET_SCRATCH_CARD_BY_ID, GET_TRANSACTION, CLEAR_COUPON_DETAIL, CLEAR_SCRATCH_CARD_DETAIL, EDIT_COUPON, EDIT_SCRATCH_CARD, SEARCH_COUPON, SEARCH_SERATCH_CARD, ADD_SINGLE_PAYOUT } from "../actionType/actionTypes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getAllTransactionAction = (body) => {
    return async(dispatch) => {
        await PlaystarzBankServices.getAllTransaction(body)
        .then(res=>{
            dispatch(getAllTransaction(res.data.transactionData))
        })
    }
}

export const getAllCouponsAction = (body) => {
    return async(dispatch) => {
        await PlaystarzBankServices.getAllCoupons(body)
        .then(res=>{
            dispatch(getAllCoupons(res.data.coupon))
        })
    }
}

export const getCouponByIdAction = (body) => {
    return async(dispatch) => {
        await PlaystarzBankServices.getAllCouponsById(body)
        .then(res=>{
            dispatch(getAllCouponsById(res.data.coupon))
        })
    }
}

export const searchCouponAction = (body) => {
    return async(dispatch) => {
        await PlaystarzBankServices.searchCoupon(body)
        .then(res=>{
            dispatch(searchCoupon(res.data.couponData))
        })
    }
}

export const searchScratchCardAction = (body) => {
    return async(dispatch) => {
        await PlaystarzBankServices.searchScratchCard(body)
        .then(res=>{
            dispatch(searchScratchCard(res.data.couponData))
        })
    }
}

export const createCouponsAction = (body, history) => {
    return async(dispatch) => {
        await PlaystarzBankServices.createCoupons(body)
        .then(res=>{
            toast.success(res.data.message)
            localStorage.removeItem('books')
            setTimeout(()=>{
                history.push('/coupons')
            }, 2000)
        }).catch(err=>{
           if(err.response.data.statusCode === 400){
                err.response.data.message.forEach((error)=>{
                    toast.error(error)
                })
           }
        })
    }
}

export const editCouponAction = (body, history) => {
    return async(dispatch) => {
        await PlaystarzBankServices.editCoupon(body)
        .then(res=>{
            toast.success(res.data.message)
            localStorage.removeItem('books')
            setTimeout(()=>{
                history.push('/coupans')
            }, 2000)
        }).catch(err=>{
           if(err.response.data.statusCode === 400){
                err.response.data.message.forEach((error)=>{
                    toast.error(error)
                })
           }
        })
    }
}

export const clearCouponAction = (body) => {
    return async(dispatch) => {
        dispatch(clearCoupon())
    }
}

export const getAllScratchCardAction = (body) => {
    return async(dispatch) => {
        await PlaystarzBankServices.getAllScratchCard(body)
        .then(res=>{
            dispatch(getAllScratchCard(res.data.scratchCard))
        })
    }
}

export const getScratchCardByIdAction = (body) => {
    return async(dispatch) => {
        await PlaystarzBankServices.getAllScratchCardById(body)
        .then(res=>{
            dispatch(getAllScratchCardById(res.data.scratchCard))
        })
    }
}

export const createScratchCardAction = (body, history) => {
    return async(dispatch) => {
        await PlaystarzBankServices.createScratchCard(body)
        .then(res=>{
            toast.success(res.data.message)
            localStorage.removeItem('books')
            setTimeout(()=>{
                history.push('/scratch-card')
            }, 2000)
        }).catch(err=>{
           if(err.response.data.statusCode === 400){
                err.response.data.message.forEach((error)=>{
                    toast.error(error)
                })
           }else if(err.response.data.statusCode === 409){
                toast.error(err.response.data.message)
           }
        })
    }
}

export const editScratchCardAction = (body, history) => {
    return async(dispatch) => {
        await PlaystarzBankServices.editScratchCard(body)
        .then(res=>{
            toast.success(res.data.message)
            localStorage.removeItem('books')
            setTimeout(()=>{
                history.push('/scratch-card')
            }, 2000)
        }).catch(err=>{
            if(err.response.data.statusCode === 400){
                err.response.data.message.forEach((error)=>{
                    toast.error(error)
                })
           }
        })
    }
}

export const searchTransactionAction = (body) => {
    return async(dispatch) => {
        await PlaystarzBankServices.searchTransaction(body)
        .then(res=>{
            dispatch(getAllTransaction(res.data.walletTransactionHistoryData))
        })
    }
}

export const getSinglePayoutAction = (body, history) => {
    return async(dispatch) => {
        await PlaystarzBankServices.getSinglePayout(body)
        .then(res=>{
            dispatch(getSinglePayout(res.data.transactionData))
        })
    }
}

export const addSinglePayoutAction = (body, history) => {
    return async(dispatch) => {
        await PlaystarzBankServices.addSinglePayout(body)
        .then(res=>{
            toast.success(res.data.message)
            setTimeout(()=>{
                history.push('/payout')
            }, 1000)
        }).catch(err=>{
            toast.error(err.response.data.message)
        })
    }
}

export const clearSCAction = (body) => {
    return async(dispatch) => {
        dispatch(clearSC())
    }
}

export const getAllTransaction = (data) => {
    return {
        type: GET_TRANSACTION,
        payload : data
    }
}

export const getAllCoupons = (data) => {
    return {
        type: GET_COUPONS,
        payload : data
    }
}

export const getAllCouponsById = (data) => {
    return {
        type : GET_COUPON_BY_ID,
        payload : data
    }
}

export const createCoupons = (data) => {
    return {
        type: CREATE_COUPONS,
        payload : data
    }
}

export const editCoupon = (data) => {
    return {
        type : EDIT_COUPON,
        payload : data
    }
}

export const clearCoupon = (data) => {
    return{
        type : CLEAR_COUPON_DETAIL,
        payload : data,
    }
}

export const getAllScratchCard = (data) => {
    return {
        type : GET_SCRATCH_CARD,
        payload : data
    }
}

export const getAllScratchCardById = (data) => {
    return {
        type : GET_SCRATCH_CARD_BY_ID,
        payload : data
    }
}

export const createScratchCard = (data) => {
    return {
        type : ADD_SCRATCH_CARD,
        payload : data
    }
}

export const editScratchCard = (data) => {
    return {
        type : EDIT_SCRATCH_CARD,
        payload : data
    }
}

export const clearSC = (data) => {
    return {
        type : CLEAR_SCRATCH_CARD_DETAIL,
        payload : data
    }
}

export const searchCoupon = (data) => {
    return {
        type : SEARCH_COUPON,
        payload : data
    }
}

export const searchScratchCard = (data) => {
    return {
        type : SEARCH_SERATCH_CARD,
        payload : data
    }
}

export const getSinglePayout = (data) => {
    return {
        type : GET_SINGLE_PAYOUT,
        payload : data
    }
}

export const addSinglePayout = (data) => {
    return {
        type : ADD_SINGLE_PAYOUT,
        payload : data
    }
}