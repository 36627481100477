import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import { Link} from "react-router-dom";
import { Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from 'react-redux';
import { getallCompetitionAction , clearCompetitionAction} from '../../../redux/action/competitionAction';

function CompetitionDetail({show, onHide}) {

    const dispatch = useDispatch();
    
    let competitions = useSelector((state)=>{ 
        return state.competition.competitionById
    })

    const close = () => {
        dispatch(clearCompetitionAction())
        onHide();
    }

    if(competitions && competitions.length != 0){
    return(
        <Modal scrollable={true} contentClassName="new_modal" size="lg" centered show={show} onHide={onHide} className='competitiondetail-modal'>
            <Modal.Header>
                <div className="modal_head">
                    <span className='competitiondetail-heading'>Competition Detail</span>
                </div>
            </Modal.Header>

            <Modal.Body className='competitiondetail-body'>
                <Row className='my-2'>
                    <Col lg={6}>
                        <label>Competition Name</label>
                        <input className='form-control' disabled defaultValue={competitions[0].competitionName != "" ? competitions[0].competitionName : "-"} />
                    </Col>
                    <Col lg={6}>
                        <label>Competition Format</label>
                        <input className='form-control' disabled defaultValue={(competitions[0].competitionFormatTypeMasterId != null || competitions[0].competitionFormatTypeMasterId != "") && competitions[0].competitionFormatTypeMasterId == 2 ? "Tournament (Leaderboard)" : (competitions[0].competitionFormatTypeMasterId != null || competitions[0].competitionFormatTypeMasterId != "") && competitions[0].competitionFormatTypeMasterId == 3 ? "Instant Battle" : (competitions[0].competitionFormatTypeMasterId != null || competitions[0].competitionFormatTypeMasterId != "") && competitions[0].competitionFormatTypeMasterId == 5 ? "Tournament (Bracket)" : "No data found"} />
                    </Col>
                </Row>

                <Row className='my-2'>
                    <Col lg={6}>
                        <label>Match Format</label>
                        <input className='form-control' disabled defaultValue={competitions[0].match.matchFormatTypeMasterId != null || competitions[0].match.matchFormatTypeMasterId != "" && competitions[0].match.matchFormatTypeMasterId == 1 ? "Single Player" : competitions[0].match.matchFormatTypeMasterId != null || competitions[0].match.matchFormatTypeMasterId != "" && competitions[0].match.matchFormatTypeMasterId == 2 ? "MultiPlayer" : "No data found"} />
                    </Col>
                    <Col lg={6}>
                        <label>Entry Fee</label>
                        <input className='form-control' disabled defaultValue={competitions[0].entryFee} />
                    </Col>
                </Row>
                <Row className='my-2'>
                    <Col lg={6}>
                        <label>Leaderboard Outcome Type</label>
                        <input className='form-control' disabled defaultValue={competitions[0].match.leaderboardOutcomeTypeMasterId != null || competitions[0].match.leaderboardOutcomeTypeMasterId != "" && competitions[0].match.leaderboardOutcomeTypeMasterId === 1 ? "High Score" : competitions[0].match.leaderboardOutcomeTypeMasterId != null || competitions[0].match.leaderboardOutcomeTypeMasterId != "" && competitions[0].match.leaderboardOutcomeTypeMasterId === 2 ? "Time Trail" : competitions[0].match.leaderboardOutcomeTypeMasterId != null || competitions[0].match.leaderboardOutcomeTypeMasterId != "" && competitions[0].match.leaderboardOutcomeTypeMasterId === 3 ? "Win/Loss/Draw" : competitions[0].match.leaderboardOutcomeTypeMasterId != null || competitions[0].match.leaderboardOutcomeTypeMasterId != "" && competitions[0].match.leaderboardOutcomeTypeMasterId === 4 ? "Podium Finish" : competitions[0].match.leaderboardOutcomeTypeMasterId != null || competitions[0].match.leaderboardOutcomeTypeMasterId != "" && competitions[0].match.leaderboardOutcomeTypeMasterId === 5 ? "Winning  Collected" : "No data found"} />
                    </Col>
                    <Col lg={6}>
                        <label>Match Outcome Type </label>
                        <input className='form-control' disabled defaultValue={competitions[0].match.matchOutcomeTypeMasterId != null || competitions[0].match.matchOutcomeTypeMasterId != "" && competitions[0].match.matchOutcomeTypeMasterId === 1 ? "High Score" : competitions[0].match.matchOutcomeTypeMasterId != null || competitions[0].match.matchOutcomeTypeMasterId != "" && competitions[0].match.matchOutcomeTypeMasterId === 2 ? "Time Trail" : competitions[0].match.matchOutcomeTypeMasterId != null || competitions[0].match.matchOutcomeTypeMasterId != "" && competitions[0].match.matchOutcomeTypeMasterId === 3 ? "Win/Loss/Draw" : competitions[0].match.matchOutcomeTypeMasterId != null || competitions[0].match.matchOutcomeTypeMasterId != "" && competitions[0].match.matchOutcomeTypeMasterId === 4 ? "Podium Finish" : competitions[0].match.matchOutcomeTypeMasterId != null || competitions[0].match.matchOutcomeTypeMasterId != "" && competitions[0].match.matchOutcomeTypeMasterId === 5 ? "Winning  Collected" : "No data found"} />
                    </Col>
                </Row>

                <Row className='my-2'>
                    <Col lg={6}>
                        <label>Minimum Players</label>
                        <input className='form-control' disabled defaultValue={competitions[0].minPlayers != "" ? competitions[0].minPlayers : "-"} />
                    </Col>
                    <Col lg={6}>
                        <label>Maximum Players</label>
                        <input className='form-control' disabled defaultValue={competitions[0].maxPlayers != "" ? competitions[0].maxPlayers : "-"} />
                    </Col>
                </Row>
                <Row className='my-2'>
                    <Col lg={6}>
                        <label>PD Mode</label>
                        <input className='form-control' disabled defaultValue={competitions[0].prizeDistributionMode != "" ? competitions[0].prizeDistributionMode : "-"} />
                    </Col>
                    <Col lg={6}>
                        <label>Hosting Fee Per User</label>
                        <input className='form-control' disabled defaultValue={competitions[0].hostingFee != "" ? competitions[0].hostingFee : "-"} />
                    </Col>
                </Row>

                <Row className='my-2'>
                    <Col>
                        <label>Competition Prize Distribution Schedule</label>
                        <input className="form-control" disabled defaultValue={competitions[0].prizeDistributionSchedule != "" ? competitions[0].prizeDistributionSchedule : "-"}/>
                    </Col>
                    <Col lg={6}>
                        <label>No of slots</label>
                        <input className='form-control' defaultValue={competitions[0].totalNumberOfSlot != "" || competitions[0].totalNumberOfSlot != undefined  ? competitions[0].totalNumberOfSlot : "-"} disabled />
                    </Col>
                </Row>

                <Row className='my-2'>
                    <Col>
                        <label class="form-check-label">Is Special Event</label>
                        <input type="checkbox" className="form-check-input mx-2" checked={competitions[0].isSpecialEvent == true ? true : false}/>
                    </Col>
                </Row>

                <Row className='competition-main'>
                    <Col>
                    <label className='my-2'>Prize Distribution Rule : </label>
                    {competitions[0] && competitions[0].prizeDistributionRule != null ?
                        <table className='table table table-striped text-center table-custom'>
                            <thead className='table-head'>
                                <tr>
                                    <th scope="col" className='head-border-left'>Start Rank</th>
                                    <th scope="col">End Rank</th>
                                    <th scope="col" className='head-border-right'>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {competitions[0].prizeDistributionRule && competitions[0].prizeDistributionRule.map((e,i)=>{
                                    return(
                                            <tr className='table-body' key={i}>
                                                <td>{e.startRank}</td>
                                                <td>{e.endRank}</td>
                                                <td>{e.amount}</td>
                                            </tr>
                                    )
                                })}
                            </tbody>
                        </table> 
                        : competitions[0].prizeDistributionRule == null ? <p>No Data Available</p>
                        : "No Matches Available."}
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer className='competition-footer'>
                <Row>
                    <Col>
                        <Button className="btn btn-danger btn -sm mx-2" onClick={close}>Cancel</Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
    }else{
        return(<label></label>)
    }
}

export default CompetitionDetail