import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TransactionFilter from '../modals/Filters/TransactionFilter';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faPen,faPlusCircle, faSearch, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col, InputGroup, Row, Nav, Form, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import AddReferralModal from '../modals/ReferralModals/AddReferralModal';
import { icon } from '@fortawesome/fontawesome-svg-core';
import axios from '../../utlis/interceptor';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AddReferralAction, getAllReferralAction, getReferralByIdAction, editReferralAction } from '../../redux/action/referralAction';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import { Breadcrumb } from "react-bootstrap";

function Referrals() {
    const dispatch = useDispatch();
    const [inputFields, setInputFields] = useState([])
    const [errors, setErrors] = useState([])
    const [referrals, setReferrals] = useState()

    const [noEvent, setNoevents] = useState()

    useEffect(()=>{
        let refs = {active : true}
        dispatch(getAllReferralAction(refs))
    }, [referralData])

    const [isShow, setIsshow] = useState(false)

    const [showReferral, setShowReferral] = useState(false)

    const AddUserAction = () => {
        setErrors([...errors], {eventError: false, batchesError: false})
        setInputFields([...inputFields, {active: true, referrerValue: '', referredValue: '', referralActionTypeId: '', referralRewardsTypeId: '', noOfEvents : '', noOfBatches: '' }])
    }

    const handleInputChange = (i, event) =>{
        const values = [...inputFields];
        values[i][event.target.name] = parseInt(event.target.value);
        setInputFields(values) 
    }


    let referralData = useSelector((state)=>{ 
        return state.referral.referrals
    })

    const [batchError, setBatchError] = useState([])

    const handleSubmit = (e) => {
        // validation();
        dispatch(AddReferralAction(inputFields))
        // setShowReferral(!showReferral)
        // setInputFields([])
        e.preventDefault(); 
    }

    const handleDelete = (index) => {
        const values = [...inputFields];
        values.splice(index, 1)
        setInputFields(values)
    }

    const AddReferral = (data) => {
        let referralDetail = {
            id : [data],
            active : true
        }
        dispatch(getReferralByIdAction(referralDetail))
        setIsshow(!isShow)
    }

    const deleteReferral = (data) => {
        let deleteReferralData = [{
            id : data,
            active : "false"
        }]
        dispatch(editReferralAction(deleteReferralData))
    }
    // const [a, setA] = useState([])
    // useEffect(()=>{
    //     axios.get('https://dev.gamestarz.xyz/v1/static-values')
    //     .then(res=>{
    //         setA(res.data.referralActionMasterIds);
    //     })
    // }, [])

    const a = [
        {label: "invite + signup", value: 1}, 
        {label: "play x no of matches", value: 2},
        {label: "deposit x amount", value: 3},
        {label: "participate in 'x' no of competitions", value: 4},
        {label: "make purchases worth 'x' from store", value: 5},
    ]

    const onChange = (i, selected) => {
        const values = [...inputFields]
        values[i].referralActionTypeId = selected.value;
        
    }

    // const validation = () => {
    //     const length = inputFields.length
    //     const Errs = [...errors]
    //     for(var i = 0 ; i<length ; i++){
    //         if(inputFields[i].noOfEvents == null || inputFields[i].noOfEvents == ""){    
    //             Errs[i] = true;
    //             setErrors(Errs)
    //         }else{
    //             Errs[i] = false;
    //             setErrors(Errs)
    //         }

    //         if(inputFields[i].noOfBatches == null || inputFields[i].noOfBatches == ""){    
    //             Errs[i] = true;
    //             setErrors(Errs)
    //         }else{
    //             Errs[i] = false;
    //             setErrors(Errs)
    //         }
    //     }
    // }

    return (
        <>
        <ToastContainer />
        <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
            <Row>
                <Col lg={12}>
                    <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                        <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-dark" active>Referrals</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        <div className="d-lg-flex justify-content-end flex-wrap flex-md-nowrap align-items-center py-4">
            <Col xs={12} sm={12} md={12} lg={6}></Col>
            <Col xs={12} sm={12} md={12} lg={6} className="px-0 mb-0 text-right">
                {/* <ButtonGroup>
                    <Button className='export' variant="outline-primary" size="sm">Export</Button>
                </ButtonGroup> */}
            </Col>
        </div>
        <div className="task-wrapper border bg-white border-light shadow-sm py-5 rounded referrals">  
            <div className='container'>
                <div className="row mb-4">
                    <div className="col col-lg-6 col-md-12 text-left">
                        <p className='page-heading'>Referrals</p>
                    </div>
                    <div className="col col-lg-12 col-md-12 col-sm-12 text-right">
                        
                    </div>
                </div>
                <Row>
                    <Col lg={12} md={12} sm={12}>
                        <Button className="button-style me-3" onClick={AddUserAction} style={{width: "200px", display: "Inline !important"}}>
                            <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                            <span className="me-2" >User Action</span>
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <form onSubmit={handleSubmit}>
                            {inputFields.map((e, i)=>{
                                return(
                                    <div key={i} className='my-2 py-2'>
                                        <Row className='text-right'>
                                            <Col>
                                                <FontAwesomeIcon style={{color: "red", fontSize: "25px", cursor: "pointer"}} onClick={()=>{handleDelete(i)}} icon={faTrashAlt} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <label className='mb-2'>User Action</label>
                                                <select name='referralActionTypeId' type='text' className='form-control' onChange={event => handleInputChange(i, event)}>
                                                    <option value='' selected>Select Action Type</option>
                                                    <option value="1">Invite + Sign Up 'x' friends</option>
                                                    <option value='2'>Play 'x' no of matches</option>
                                                    <option value='3'>Deposit 'x' amount</option>
                                                    <option value='4'>Participate in 'x' no of competition</option>
                                                    <option value='5'>Make purchase worth 'x' from store</option>
                                                </select>
                                                {/* <Select defaultValue={inputFields[i].referralActionTypeId == 1 ? "invite + signup" : inputFields[i].referralActionTypeId == 2 ? "play x no of matches" : inputFields[i].referralActionTypeId == 3 ? "deposit x amount" : "-"} name='referralActionTypeId' options={a} onChange={event => onChange(i, event)}></Select> */}
                                            </Col>
                                            <Col lg={6}>
                                                <label className='mb-2'>Number of events</label>
                                                <input type='number' name='noOfEvents' className='form-control' value={inputFields[i].noOfEvents} onChange={event => handleInputChange(i, event)}/>
                                                {/* {errors[i] == true ? <span style={{color : "red"}}>No Of Events is requires</span> : ""} */}
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={6} md={6} sm={12}>
                                                <Row className='mt-2'>
                                                    <Col lg={12} md={12} sm={12}>
                                                        <h6>Referrer Rewards</h6>
                                                    </Col>
                                                </Row>

                                                <Row className='mt-2'>
                                                    <Col lg={12} md={12} sm={12}>
                                                        <label className='mb-2'>Reward Type</label>
                                                        <select name='referralRewardsTypeId' className='form-control' defaultValue={inputFields[i].noOfBatches} onChange={event => handleInputChange(i, event)}>
                                                            <option value=''>Select Reward Type</option>
                                                            <option value='1'>Bonus Cash</option>
                                                            <option value='2'>Virtual Currancy</option>
                                                            <option value='3'>Inventory Rewards</option>
                                                        </select>
                                                    </Col>
                                                </Row>

                                                <Row className='mt-2'>
                                                    <Col lg={12} md={12} sm={12}>
                                                        <label className='mb-2'>Reward Structure</label>
                                                        <input type='number' name='noOfBatches' max={e.noOfEvents} value={inputFields[i].noOfBatches} className='form-control' onChange={(event) => {handleInputChange(i, event)}}/>
                                                    </Col>
                                                </Row>

                                                <Row className='mt-2'>
                                                    <Col lg={12} md={12} sm={12}>
                                                        <label className='mb-2'>Is Recurring</label>
                                                        <input type='checkbox' className='mx-2'/>
                                                    </Col>
                                                </Row>

                                                <Row className='mt-2'>
                                                    <Col lg={12} md={12} sm={12}>
                                                        <label className='mb-2'>Reward</label>
                                                        <input name='referrerValue' type='number' className='form-control' value={inputFields[i].referrerValue} onChange={event => handleInputChange(i, event)}/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={6} md={6} sm={12}>
                                                <Row className='mt-2'>
                                                    <Col lg={12} md={12} sm={12}>
                                                        <h6>Referee Rewards</h6>
                                                    </Col>
                                                </Row>

                                                <Row className='mt-2'>
                                                    <Col lg={12} md={12} sm={12}>
                                                        <label className='mb-2'>Reward</label>
                                                        <input name='referredValue' type='number' className='form-control' value={inputFields[i].referredValue} onChange={event => handleInputChange(i, event)}/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })}
                            {inputFields.length>0 ?
                            <Row className='mt-3'>
                                <Col>
                                    <button className='btn btn-success'>Save Referrals</button>
                                    {/* <button className='btn btn-danger mx-2' onClick={onCancel}>Cancel</button> */}
                                </Col>
                            </Row>
                            : ""}
                            
                        </form>
                    </Col>
                </Row>

                {showReferral === false ? 
                    (referralData && referralData.map((e, i)=>{
                    return(
                        <div key={i} className='my-3 py-3 card'>
                            <Row className='text-right'>
                                <Col>
                                    <FontAwesomeIcon className='mx-4' style={{color: "black", fontSize: "20px", cursor: "pointer"}} icon={faPen} onClick={()=>{AddReferral(e.id)}}/>
                                    <FontAwesomeIcon style={{color: "red", fontSize: "20px", cursor: "pointer"}} icon={faTrashAlt} onClick={()=>{deleteReferral(e.id)}} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h6>Referral Id : {e.id}</h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <label className='mb-2'>User Action</label>
                                    <select disabled name='referralActionTypeId' type='text' className='form-control' >
                                        <option value={e.referralActionTypeId}>{e.referralActionTypeId === 1 ? "invite + signup" : e.referralActionTypeId === 2 ? "play x no of matches" : e.referralActionTypeId === 3 ? "deposit x amount" : e.referralActionTypeId === 4 ? "participate in 'x' no of competitions" : e.referralActionTypeId === 5 ? "make purchases worth 'x' from store" : '-'}</option>
                                        <option value="1">Invite + Sign Up 'x' friends</option>
                                        <option value='2'>Play 'x' no of matches</option>
                                        <option value='3'>Deposit 'x' amount</option>
                                        <option value='4'>Participate in 'x' no of competition</option>
                                        <option value='5'>Make purchase worth 'x' from store</option>
                                    </select>
                                </Col>
                                <Col lg={6}>
                                    <label className='mb-2'>Number of events</label>
                                    <input type='number' name='noOfEvents' disabled value={e.noOfEvents} className='form-control' />
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <Row className='mt-2'>
                                        <Col lg={12} md={12} sm={12}>
                                            <h6>Referrer Rewards</h6>
                                        </Col>         
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col lg={12} md={12} sm={12}>
                                            <label className='mb-2'>Reward Type</label>
                                            <select disabled name='referralRewardsTypeId' className='form-control' >
                                                <option>{e.referralRewardsType.id === 1 ? "bonus cash" : e.referralRewardsType.id === 2 ? "virtual currency" : e.referralRewardsType.id === 3 ? "inventory rewards" : "-"}</option>
                                                <option value='1'>Bonus Cash</option>
                                                <option value='2'>Virtual Currancy</option>
                                                <option value='3'>Inventory Rewards</option>
                                            </select>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col lg={12} md={12} sm={12}>
                                            <label className='mb-2'>Reward Structure</label>
                                            <input disabled type='number' name='noOfBatches' max={e.noOfEvents} value={e.noOfBatches} className='form-control' />
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col lg={12} md={12} sm={12}>
                                            <label className='mb-2'>Is Recurring</label>
                                            <input disabled type='checkbox' className='mx-2'/>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col lg={12} md={12} sm={12}>
                                            <label className='mb-2'>Reward</label>
                                            <input disabled name='referrerValue' type='number' value={e.referrerValue} className='form-control' />
                                        </Col>
                                    </Row>
                                </Col>
                                
                                <Col lg={6} md={6} sm={12}>
                                    <Row className='mt-2'>
                                        <Col lg={12} md={12} sm={12}>
                                            <h6>Referee Rewards</h6>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col lg={12} md={12} sm={12}>
                                            <label className='mb-2'>Reward</label>
                                            <input disabled name='referredValue' type='number' value={e.referredValue} className='form-control' />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    )
                }))
                : ""}
            </div>
            <AddReferralModal show={isShow} onHide={AddReferral} />
        </div>
    </>
    )
}

export default Referrals