
import React, { useEffect, useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { isMobile, isWindows } from "react-device-detect";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faImage, faGamepad, faSignOutAlt, faTimes, faCrosshairs, faUniversity, faPeopleArrows, faCodeBranch, faChartPie, faBuilding, faUserFriends, faBusinessTime, faFile} from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Accordion, Navbar, Row, Col } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import '../pages/css/games.css'
import { Routes } from "../routes";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import SidebarImg from '../pages/Images/Sidebar_Logo.jpg'
import axios from "axios";
import customAxios from '../utlis/interceptor'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL, USER_EDIT } from "../resources/APIEndpoints";
import { faDochub } from "@fortawesome/free-brands-svg-icons";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const contracted = props.contracted ? "contracted" : "";
  const showClass = show ? "show" : "";
  const[gameLogo, setGameLogo] = useState("")
  const[GameLogoError, setGameLogoError] = useState(false)

  const onCollapse = () => setShow(!show);
  const onMouseEnter = () => props.onMouseEnter && props.onMouseEnter();
  const onMouseLeave = () => props.onMouseLeave && props.onMouseLeave();

  const events = isMobile ? {} : { onMouseEnter, onMouseLeave };

  const CollapsableNavItem = (props) => {
  const { eventKey, title, icon, children = null } = props;
  const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

  return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, target, icon, image, badgeText, badgeBg, badgeColor = "white" } = props;
    const classNames = badgeText ? "d-flex justify-content-between align-items-center" : "";
    const navItemClassName = link === pathname ? "active" : "";

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link as={Link} to={link} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            {!show && contracted && !icon && !image ? (
              <span className="sidebar-text-contracted">{title[0]}</span>
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count">{badgeText}</Badge> : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  function convertDataURIToBinary(dataURI) {
    var BASE64_MARKER = ';base64,';
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
  
    for(var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  // To Convert file into base64 format
  const convertbase64 = (file) => {
      return new Promise((resolve, err)=>{
      const fileReader = new FileReader();
      fileReader.onload = (eve) =>{
        resolve(fileReader.result)
      }
      fileReader.readAsDataURL(file)
    })
  }

  const onLogoChange = async(e) => {
    // Converting Image to base64 and then converting to binary to upload
    var file = e.target.files[0];   
    const file_binary = await convertbase64(file)
    const res = await axios.get(`${BASE_URL}/upload-url?contentType=image/jpg`)
    const userLogo = res.data.getUrl
    var config = {
        method: 'put',
        url: res.data.postUrl,
        data :convertDataURIToBinary(file_binary),
      }
      axios(config)
      .then(function (response) {
        let orgLogo = {
            id : "ea6d777e-336e-4be9-8040-a70e9a655341",
            thumbUrl : res.data.getUrl
        }
        customAxios.post(BASE_URL + USER_EDIT, orgLogo)
        .then(res=>{
          toast.success("Logo Uploaded Successfully.")
          localStorage.setItem('orgLogo', userLogo)
          window.location.reload()
        })
        //localStorage.setItem('orgLogo', res.data.getUrl)
      })
      .catch(function (error) {
          toast.error("Logo not uploaded. Please try again!")
      });

    
  }


  const clearLocal = () => {
    localStorage.removeItem('books')
  }

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="light" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand as={Link} className="me-lg-5" to={Routes.Games.path}>
          <Image src='https://playstarz-staging.s3.ap-south-1.amazonaws.com/e66a32d4-2f64-4f2a-a111-9440b482dd4aimage.jpg'  height='auto' width='auto' className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar {...events} className={`${contracted} collapse ${showClass} sidebar d-md-block text-white`}>
          <div className="sidebar-inner px-4 py-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src='https://playstarz-staging.s3.ap-south-1.amazonaws.com/e66a32d4-2f64-4f2a-a111-9440b482dd4aimage.jpg' className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>Hi, Admin</h6>
                  <Button as={Link} variant="secondary" size="xs" className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0 ab">
              <Row className={!props.contracted ? 'text-center mb-2' : 'text-center mb-5'}>
                  <Col>
                      <Button as={Link} to={Routes.DashboardOverview.path} variant="white" style={{border : '2px solid #000', borderRadius: '15px'}}>
                        <img src={localStorage.getItem('orgLogo') == null ? SidebarImg : localStorage.getItem('orgLogo') != '' || localStorage.getItem('orgLogo') != null ? localStorage.getItem('orgLogo') : ""} width={130} height={130} className="img-fluid" />
                      </Button>
                  </Col> 
              </Row> 
              {!props.contracted ? 
              <Row className="text-center mb-5">
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className="input-file">
                  <input type="file" id='org_logo' accept="image/jpeg, image/png, .jpeg, .jpg, .png" onChange={(e)=>{onLogoChange(e)}} className='file-input' />
                  <label for='org_logo' className="label-btn"><FontAwesomeIcon icon={faImage}/> Choose File</label>
                </Col>
              </Row>
              : ""}


              <a href='#' onClick={clearLocal}>
                <NavItem title="Overview" icon={faChartPie} link={Routes.DashboardOverview.path} ></NavItem>
              </a>

              <CollapsableNavItem eventKey="games/" title="Games" icon={faGamepad}>
                <a href='#' onClick={clearLocal}>
                  <NavItem title="Games" link={Routes.Games.path}></NavItem>
                </a>

                <a href='#' onClick={clearLocal}>
                  <NavItem title="External Games" link={Routes.ExternalGames.path}></NavItem>
                </a>

              </CollapsableNavItem>

              <CollapsableNavItem eventKey="leaderboard/" title="Leaderboard" icon={faTrophy}>
                <a href='#' onClick={clearLocal}>
                  <NavItem title="Leaderboard Main" link={Routes.Leaderboard.path} />
                </a>

                <a href='#' onClick={clearLocal}>
                  <NavItem title="Gamestarz Scoring" link={Routes.PPSALL.path} />
                </a>
              </CollapsableNavItem>

              
              <CollapsableNavItem eventKey="competition/" title="Competitions" icon={faCrosshairs}>
                <a href='#' onClick={clearLocal}>
                  <NavItem title="Competitions" link={Routes.Competition.path} />
                </a>

                <a href='#' onClick={clearLocal}>
                  <NavItem title="Organize" link={Routes.gamesInComp.path} />
                </a>
              </CollapsableNavItem>


              {/* <a href='#' onClick={clearLocal}>
                <NavItem title="Competitions" icon={faCrosshairs} link={Routes.Competition.path} />
              </a> */}

              <CollapsableNavItem eventKey="transaction-history/" title="Gamestarz Bank" icon={faUniversity}>

              <a href='#' onClick={clearLocal}>
                <NavItem title="Transaction History" link={Routes.TransactionsHistory.path} />
              </a>

              <a href='#' onClick={clearLocal}>
                <NavItem title="Payout" link={Routes.Payout.path}/>
              </a>

              <a href='#' onClick={clearLocal}>
                <NavItem title="Coupons" link={Routes.Coupans.path} />
              </a>

              <a href='#' onClick={clearLocal}>
                <NavItem title="Scratch Card" link={Routes.ScratchCard.path} />
              </a>
              </CollapsableNavItem>

              <a href='#' onClick={clearLocal}>
                <NavItem title="Referrals" icon={faPeopleArrows} link={Routes.Referrals.path} />
              </a>

              <a href='#' onClick={clearLocal}>
                <NavItem title="Version" icon={faCodeBranch} link={Routes.Version.path} />
              </a>

              <a href='#' onClick={clearLocal}>
                <NavItem title="Partners" icon={faBuilding} link={Routes.Organisation.path} />
              </a>

              <a href='#' onClick={clearLocal}>
                <NavItem title="Teams" icon={faUserFriends} link={Routes.Teams.path} />
              </a>

              <a href='#' onClick={clearLocal}>
                <NavItem title="Business Logic" icon={faBusinessTime} link={Routes.BusinessLogic.path} />
              </a>

              <a href='#' onClick={clearLocal}>
                <NavItem title="Document" icon={faFile} link={Routes.document.path} />
              </a>
              {/* <NavItem title="Users List" icon={faUserCheck} link={Routes.Users.path} />
              <NavItem title="Transactions" icon={faHandHoldingUsd} link={Routes.Transactions.path} />
              <NavItem title="Task List" icon={faClipboardList} link={Routes.Tasks.path} />
              <NavItem title="Settings" icon={faCog} link={Routes.Settings.path} />
              <NavItem title="Calendar" icon={faCalendarAlt} link={Routes.Calendar.path} />
              <NavItem title="Map" icon={faMapMarkedAlt} link={Routes.Map.path} />
              <NavItem title="Widgets" link={Routes.Widgets.path} icon={faThLarge} />

              <CollapsableNavItem eventKey="tables/" title="Tables" icon={faTable}>
                <NavItem title="DataTables" link={Routes.Datatables.path} />
                <NavItem title="Bootstrap Tables" link={Routes.BootstrapTables.path} />
              </CollapsableNavItem>

              <CollapsableNavItem eventKey="examples/" title="Page Examples" icon={faFileAlt}>
                <NavItem title="Pricing" link={Routes.Pricing.path} />
                <NavItem title="Billing" link={Routes.Billing.path} />
                <NavItem title="Invoice" link={Routes.Invoice.path} />
                <NavItem title="Sign In" link={Routes.Signin.path} />
                <NavItem title="Sign Up" link={Routes.Signup.path} />
                <NavItem title="Forgot password" link={Routes.ForgotPassword.path} />
                <NavItem title="Reset password" link={Routes.ResetPassword.path} />
                <NavItem title="Lock" link={Routes.Lock.path} />
                <NavItem title="404 Not Found" link={Routes.NotFound.path} />
                <NavItem title="500 Server Error" link={Routes.ServerError.path} />
              </CollapsableNavItem>

              <Dropdown.Divider className="my-3 border-indigo" />

              <CollapsableNavItem eventKey="documentation/" title="Getting Started" icon={faBook}>
                <NavItem title="Overview" link={Routes.DocsOverview.path} />
                <NavItem title="Download" link={Routes.DocsDownload.path} />
                <NavItem title="Quick Start" link={Routes.DocsQuickStart.path} />
                <NavItem title="License" link={Routes.DocsLicense.path} />
                <NavItem title="Folder Structure" link={Routes.DocsFolderStructure.path} />
                <NavItem title="Build Tools" link={Routes.DocsBuild.path} />
                <NavItem title="Changelog" link={Routes.DocsChangelog.path} />
              </CollapsableNavItem>

              <CollapsableNavItem eventKey="components/" title="Components" icon={faBoxOpen}>
                <NavItem title="Accordion" link={Routes.Accordions.path} />
                <NavItem title="Alerts" link={Routes.Alerts.path} />
                <NavItem title="Badges" link={Routes.Badges.path} />
                <NavItem title="Breadcrumbs" link={Routes.Breadcrumbs.path} />
                <NavItem title="Buttons" link={Routes.Buttons.path} />
                <NavItem title="Forms" link={Routes.Forms.path} />
                <NavItem title="Modals" link={Routes.Modals.path} />
                <NavItem title="Navbars" link={Routes.Navbars.path} />
                <NavItem title="Navs" link={Routes.Navs.path} />
                <NavItem title="Pagination" link={Routes.Pagination.path} />
                <NavItem title="Popovers" link={Routes.Popovers.path} />
                <NavItem title="Progress" link={Routes.Progress.path} />
                <NavItem title="Tables" link={Routes.Tables.path} />
                <NavItem title="Tabs" link={Routes.Tabs.path} />
                <NavItem title="Toasts" link={Routes.Toasts.path} />
                <NavItem title="Tooltips" link={Routes.Tooltips.path} />
                <NavItem title="Widgets" link={Routes.WidgetsComponent.path} />
              </CollapsableNavItem>

              <CollapsableNavItem eventKey="plugins/" title="Plugins" icon={faChartPie}>
                <NavItem title="Charts" link={Routes.PluginCharts.path} />
                <NavItem title="Calendar" link={Routes.PluginCalendar.path} />
                <NavItem title="DataTable" link={Routes.PluginDatatable.path} />
                <NavItem title="Map" link={Routes.PluginMap.path} />
                <NavItem title="DropZone" link={Routes.PluginDropzone.path} />
                <NavItem title="SweetAlert" link={Routes.PluginSweetAlert.path} />
              </CollapsableNavItem> */}

            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
