import React, {useState} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import { addVersionAction } from '../../../redux/action/versionAction';
import { useDispatch } from 'react-redux';
import Switch from "react-switch";

function AddVersionModel({show, onHide}) {

    const dispatch = useDispatch()

    const close = () => {
        onHide();
        setMajorRequired(true)
        setMinorRequired(true)
        setPatchRequired(true)
    }

    const [platform, setPlatform] = useState("")
    const [minorVersion, setMinorVersion] = useState("")
    const [majorVersion, setMajorVersion] = useState("")
    const [patchVersion, setPatchVersion] = useState("")

    const [majorError, setMajorErr] = useState(false)
    const [minorError, setMinorErr] = useState(false)
    const [patchError, setPatchErr] = useState(false)
    const [platformError, setPlatformErr] = useState(false)

    const onPlatform = (e) => {
        if(e.target.value === "" || e.target.value === null){
            setPlatformErr(true)
        }else{
            setPlatform(e.target.value)
            setPlatformErr(false)
        }
    }

    const onMajorVersion = (e) => {
        if(e.target.value === "" || e.target.value === null){
            setMajorErr(true)
        }else{
            setMajorVersion(parseInt(e.target.value))
            setMajorErr(false)
        }
    }

    const onMinorVersion = (e) => {
        if(e.target.value === "" || e.target.value === null){
            setMinorErr(true)
        }else{
            setMinorVersion(parseInt(e.target.value))
            setMinorErr(false)
        }
    }

    const onPatchVersion = (e) => {
        if(e.target.value === "" || e.target.value === null){
            setPatchErr(true)
        }else{
            setPatchVersion(parseInt(e.target.value))
            setPatchErr(false)
        }
    }

    const validation = () => {
        if(majorVersion === null || majorVersion === ''){
            setMajorErr(true)
        }
        if(minorVersion === null || minorVersion === ''){
            setMinorErr(true)
        }
        if(patchVersion === null || patchVersion === ''){
            setPatchErr(true)
        }
        if(platform === undefined || platform === '' || platform === undefined){
            setPlatformErr(true)
        }
    }

    const addVersion = () => {
        const addVersionData = {
            majorVersion : majorVersion,
            minorVersion : minorVersion,
            patchVersion : patchVersion,
            platform : platform,
            isMajorVersionRequired : majorRequired,
            isMinorVersionRequired : minorRequired,
            isPatchVersionRequired : patchRequired
        }
        if(addVersionData.majorVersion === null || addVersionData.minorVersion === null || addVersionData.patchVersion === null || addVersionData.majorVersion === '' || addVersionData.minorVersion === '' || addVersionData.patchVersion === '' || addVersionData.platform === "" || addVersionData.platform === undefined){
            validation()
        }else{
            dispatch(addVersionAction(addVersionData))
            onHide();
            setMajorRequired(true)
            setMinorRequired(true)
            setPatchRequired(true)
        }
    }

    const[majorRequired, setMajorRequired] = useState(true)
    const onMajor = () => {
        setMajorRequired(!majorRequired)
    }

    const[minorRequired, setMinorRequired] = useState(true)
    const onMinor = () => {
        setMinorRequired(!minorRequired)
    }

    const[patchRequired, setPatchRequired] = useState(true)
    const onPatch = () => {
        setPatchRequired(!patchRequired)
    }

    return (
        <div>
            <Modal scrollable={true} size="lg" centered show={show} onHide={onHide}>
                <Modal.Header>
                    <div className="modal_head">
                        <h6>Add Version</h6>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={6}>
                            <label className='my-2'>Platform</label>
                            <input type='text' className='form-control' name='platform' onChange={(e)=>{onPlatform(e)}}/>
                            {platformError === true ?  <span style={{color : "red"}}>*Platform is Required</span> : ""}
                        </Col>
                        <Col lg={6}>
                            <label className='my-2'>Major Version</label>
                            <input type='number' className='form-control'  min='0' name='majorVersion' onChange={(e)=>{onMajorVersion(e)}}/>
                            {majorError === true ?  <span style={{color : "red"}}>*Major version is Required</span> : ""}
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <label className='my-2'>Minor Version</label>
                            <input type='number' className='form-control' min='0' name='ninorVersion' onChange={(e)=>{onMinorVersion(e)}}/>
                            {minorError === true ?  <span style={{color : "red"}}>*Minor version is Required</span> : ""}
                        </Col>
                        <Col lg={6}>
                            <label className='my-2'>Patch Version</label>
                            <input type='number' className='form-control' min='0' name='patchVersion' onChange={(e)=>{onPatchVersion(e)}}/>
                            {patchError === true ?  <span style={{color : "red"}}>*Patch version is Required</span> : ""}
                        </Col>
                    </Row>

                    <Row className='mt-2'>
                        <Col lg={6}>
                            <label>Major Version Required</label> <br />
                            <Switch onChange={()=>{onMajor()}} checked={majorRequired} />
                        </Col>

                        <Col>
                            <label>Minor Version Required</label> <br />
                            <Switch onChange={()=>{onMinor()}} checked={minorRequired} />
                        </Col>
                    </Row>

                    <Row className='mt-2'>
                        <Col lg={6}>
                            <label>Patch Version Required</label> <br />
                            <Switch onChange={()=>{onPatch()}} checked={patchRequired} />
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col>
                            <Button className="btn btn-success btn -sm mx-2" onClick={addVersion}>Add</Button>
                            <Button className="btn btn-danger btn -sm mx-2" onClick={close}>Cancel</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
        </Modal> 
    </div>
)
}

export default AddVersionModel