import React, {useEffect, useState} from 'react'
import axios from '../../../utlis/interceptor';
import {useDispatch, useSelector} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Routes } from '../../../routes';
import {faEdit, faPlusCircle, faEllipsisH, faEye, faArchive, faPlus, faSearch, faToggleOn, faToggleOff, faLessThan} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col, InputGroup, Row, Nav, Form, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import ActivateCoupanModal from '../../modals/PlaystazBankModels/CoupanModals/ActivateCoupan';
import DeactivateCoupanModal from '../../modals/PlaystazBankModels/CoupanModals/DeactivateCoupan';
import CoupanDetailsModal from '../../modals/PlaystazBankModels/CoupanModals/CoupanDetailsModal';
import CouponFilter from '../../modals/Filters/CouponFilter';
import { clearCouponAction, getAllCouponsAction, getCouponByIdAction, searchCouponAction } from '../../../redux/action/playstarzbankAction';
import Pagination from '@mui/material/Pagination'
import { BASE_URL, GET_COUPONS } from '../../../resources/APIEndpoints';
import { CSVLink, CSVDownload } from "react-csv";
import { Breadcrumb } from "react-bootstrap";

function Coupans(props) {

    const dispatch = useDispatch();

    const [showActivate, setActivate] = useState(false);
    const onActivate = () => {
        setActivate(!showActivate)
    }

    const [showDeactivate, setDeactivate] = useState(false);
    const onDeactivate = () => {
        setDeactivate(!showDeactivate)
    }

    const [showDetail, setShowDetail] = useState(false);
    const onDetail = (couponId) => {
        setShowDetail(!showDetail)
        let toggleDetail = !showDetail;
        let couponDetailById = {
            couponFilters : {
                id : couponId,
                pagination :{

                }
            }    
        }
        if(toggleDetail === true){
            dispatch(getCouponByIdAction(couponDetailById))
        }
    }

    const [filterCoupon, setFilterCoupon] = useState(false)
    const onFilterCoupon = () => {
        setFilterCoupon(!filterCoupon)
    }

    const [page, setPage] = useState(1)
    const [counts , setCounts] = useState()
    const [posts, setPosts] = useState([])
    const [limit, setLimit] = useState(10)

    useEffect(()=>{
        let couponData = {
            couponFilters : {
                referralRewardsTypeId : rewardFilter,
                couponStatus : statusFilter,
                pagination :{
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }    
        }
        Object.keys(couponData.couponFilters).forEach((key) => (couponData.couponFilters[key].length === 0) && delete couponData.couponFilters[key])
        dispatch(getAllCouponsAction(couponData))
    }, [page])

    useEffect(()=>{
        let couponData = {
            couponFilters : {
                pagination :{

                }
            }    
        }
        axios.post(`${BASE_URL}/coupon/getCoupon`, couponData)
        .then(res=>{
            const pageNo = Math.ceil(res.data.coupon.length/limit)
            setCounts(pageNo)
        })
    }, [])

    let couponsData = useSelector((state)=>{ 
        return state.playstarzbank.coupons
    })

    const serachCoupon = (e) => {
        if(e.target.value === ""){
            let couponData = {
                couponFilters : {
                    pagination :{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }    
            }
            dispatch(getAllCouponsAction(couponData))
        }else{
            let serachCouponName = {
                couponName : e.target.value
            }
            dispatch(searchCouponAction(serachCouponName))
        }
    }

    const onCouponSort = (e) => {
        dispatch(clearCouponAction())
        let data = {}
        if(e.target.value === "Created"){
            data = {
                couponFilters : {
                    couponSort : {
                        createdAt : "ASC"
                    },
                    pagination:{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
            }
        }else if(e.target.value === "StartDate"){
            data = {
                couponFilters : {
                    couponSort : {
                        startDate : "DESC"
                    },
                    pagination:{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
            }
        }else if(e.target.value === "EndDate"){
            data = {
                couponFilters : {
                    couponSort : {
                        endDate : "DESC"
                    },
                    pagination:{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
            }
        }else if(e.target.value === "Status"){
            data = {
                couponFilters : {
                    couponSort : {
                        status : "DESC"
                    },
                    pagination:{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
            }
        }
        dispatch(getAllCouponsAction(data))
    }

    const [allCoupons, setAllCoupons] = useState()
    useEffect(()=>{
        let allCoupons = {
            couponFilters : {
                pagination :{}
            } 
        }

        axios.post(BASE_URL + GET_COUPONS, allCoupons)
        .then(res=>{
            setAllCoupons(res.data.coupon)
        })
    }, [])



    const headers = [
        { label : 'Coupon Id', key : 'id'},
        { label : 'Coupon Name', key : 'couponName'},
        { label : 'Coupon Description', key : 'couponDescription'},
        { label : 'Coupon Cashback Type', key : 'couponCashbackType'},
        { label : 'Max Cashback', key : 'maxCashback'},
        { label : 'Coupon Total Limit', key : 'couponTotalLimitCount'},
        { label : 'Coupon Limit Per User', key : 'couponTotalLimitCountPerUser'},
        { label : 'Cashback Status', key : 'couponStatus'},
        { label : 'Coupon Start Date', key : 'startDate'},
        { label : 'Coupon End Date', key : 'endDate'},
    ]
     
    const csvReport = {
        filename : `Coupon_Report_${new Date().toISOString().slice(0, 10)}.csv`,
        headers : headers,
        data : allCoupons
    }

    const [rewardFilter, setRewardFilter] = useState([])
    const [statusFilter, setStatusFilter] = useState([])

    const filterData = (CouponReward, couponStatus) => {

        setRewardFilter(CouponReward)
        setStatusFilter(couponStatus)

        let filterDataCoupon = {
            couponFilters : {
                referralRewardsTypeId : CouponReward,
                couponStatus : couponStatus,
                pagination :{
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
        }
        Object.keys(filterDataCoupon.couponFilters).forEach((key) => (filterDataCoupon.couponFilters[key].length === 0) && delete filterDataCoupon.couponFilters[key])
        dispatch(getAllCouponsAction(filterDataCoupon))

        delete filterDataCoupon.couponFilters.pagination.offset
        delete filterDataCoupon.couponFilters.pagination.limit
        axios.post(BASE_URL + GET_COUPONS, filterDataCoupon)
        .then(res=>{
            const pageNo = Math.ceil(res.data.coupon.length/limit)
            setCounts(pageNo)
        })
    }

    const [disableExport, setDisableExport] = useState(false)

    const exportData = () => {
        setDisableExport(true)
        setTimeout(() => {
          setDisableExport(false)
        }, 5000);
     }
    
    return (
        <>
        <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
            <Row>
                <Col lg={12}>
                    <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                        <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-dark" active>Coupons</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        <div className="d-lg-flex justify-content-end flex-wrap flex-md-nowrap align-items-center py-4">
            <Col xs={12} sm={12} md={12} lg={6}>
                <Row className="px-3">
                    <Button as={Link} to={Routes.CreateCoupan.path} className="button-style me-3" style={{width: "200px", display: "Inline !important"}}>
                    <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                    <span>Create Coupon</span>
                    </Button>
                </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} className="px-0 mb-0 text-right">
                <ButtonGroup>
                    {allCoupons ? <Button disabled={disableExport ? true : false} onClick={exportData} className="export"><CSVLink {...csvReport}>Export</CSVLink></Button> : ""}
                </ButtonGroup>
            </Col>
        </div>
        <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded gamestarz">
            <div className="row mb-4 px-4">
                <div className="col col-lg-6 col-md-12 text-left">
                    <p className='page-heading'>Coupons</p>
                </div>
                <div className="col col-lg-6 col-md-12 col-sm-12 text-right">
                    <span style={{cursor: "pointer"}} onClick={onFilterCoupon} className={rewardFilter.length != 0 || statusFilter.length != 0 ? 'applied-filter' : 'filter'}><i className="fas fa-filter mx-2"></i><span>Filter</span></span>
                </div>
            </div>

            <Row className="d-flex px-4">
                <Col lg={4} md={4} sm={12} xs={12} className="mb-sm-4 pb-sm-4">
                    {/* <label>Sort By</label> */}
                    <select className="form-control" onChange={(e)=>{onCouponSort(e)}}>
                        <option>Sort By</option>
                        <option value="Created">Created At</option>
                        <option value="StartDate">Start Date/Time</option>
                        <option value="EndDate">End Date/Time</option>
                        <option value="Status">Coupon Status</option>
                        <option>No of Times Used</option>
                    </select>
                </Col>
                {/* <Col lg={4} md={4} sm={12} xs={12}></Col> */}
                <Col lg={4} md={4} sm={12} xs={12}>
                    <InputGroup>
                        <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control type="text" placeholder="Search Coupon Name" onChange={(e)=>{serachCoupon(e)}}/>
                    </InputGroup>
                </Col>
            </Row>

            <div className="container-fluid coupon">
            <div className="mt-5">
                <Row>
                    <Col style={{overflowX : "scroll"}} lg={12} md={12} sm={12}>
                        <table className="table table-striped text-center table-custom">
                            <thead className='table-head'>
                                <tr>
                                    <th className='head-border-left' scope="col">Coupon Name</th>
                                    <th scope="col">Reward Type</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">End Date</th>
                                    <th scope="col">On Deposit Greater than "Amount'</th>
                                    <th scope="col">Max Coupons Available</th>
                                    <th scope="col">Max Coupon Limit per User</th>
                                    <th scope="col">Coupon Status</th>
                                    <th className='head-border-right' scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody className='table-body'>
                               {couponsData && couponsData.map((e)=>{
                                   return(
                                    <tr className='table-body'>
                                        <td><b>{e.couponName}</b></td>
                                        <td>{e.referralRewardsType && e.referralRewardsType.id === 1 ? "bonus cash" : e.referralRewardsType && e.referralRewardsType.id === 2 ? "virtual currency" : e.referralRewardsType && e.referralRewardsType.id === 3 ? "inventory rewards" : e.referralRewardsTypeId && e.referralRewardsTypeId === 1 ? "bonus cash" : e.referralRewardsTypeId && e.referralRewardsTypeId === 2 ? "virtual currency" : e.referralRewardsTypeId && e.referralRewardsTypeId === 3 ? "inventory rewards" : "-"}</td>
                                        <td>{e.startDate.slice(0,10)}</td>
                                        <td>{e.endDate.slice(0,10)}</td>
                                        <td>{e.couponRule.all[0].value}</td>
                                        <td>{e.couponTotalLimitCount}</td>
                                        <td>{e.couponTotalLimitCountPerUser}</td>
                                        <td>{e.couponStatus != "" ? <span className={e.couponStatus == 'active' ? 'success' : e.couponStatus == 'inactive' ? 'danger' : ''}>{e.couponStatus}</span> : "-"}</td>
                                        <td>
                                            <Dropdown className="ms-3">
                                                <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="icon icon-sm icon-dark">
                                                    <FontAwesomeIcon icon={faEllipsisH} />
                                                </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                <Dropdown.Item className="ms-0" onClick={()=>{onDetail(e.id)}}>
                                                    <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                                                </Dropdown.Item>
                                                <Dropdown.Item as={Link} to={{pathname:Routes.EditCoupon.path, id: e.id}} className="ms-0">
                                                    <FontAwesomeIcon icon={faEdit} className="me-2"/> Edit
                                                </Dropdown.Item>
                                                <Dropdown.Item as={Link} className="text-success ms-0" onClick={onActivate}>
                                                <FontAwesomeIcon icon={faToggleOn} className="me-2" /> Activate
                                                </Dropdown.Item>
                                                <Dropdown.Item as={Link} className="text-danger ms-0" onClick={onDeactivate}>
                                                <FontAwesomeIcon icon={faToggleOff} className="me-2" /> Deactivate
                                                </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                   )
                               })}

                            </tbody>
                        </table>
                    </Col>
                </Row>

                <div className="d-flex justify-content-left m-4">
                    <Row>
                        <Col>
                            <Pagination 
                            count={counts} 
                            defaultPage={page} 
                            color="info" className='paginate'
                            variant="outlined" shape="rounded"
                            showFirstButton={false} 
                            showLastButton={false}  
                            onChange={(event, value)=> setPage(value)}
                            />
                        </Col>
                    </Row>
                </div>

            </div> 
        </div>
        <ActivateCoupanModal show={showActivate} onHide={onActivate} />
        <DeactivateCoupanModal show={showDeactivate} onHide={onDeactivate} />
        <CoupanDetailsModal show={showDetail} onHide={onDetail} />
        <CouponFilter show={filterCoupon} onHide={onFilterCoupon} couponFilterData={filterData} />
    </div>
    </>
    )
}

export default Coupans