export const ALL_GAMES = "ALL_GAMES"
export const CREATE_GAME = "CREATE_GAME"
export const CREATE_MATCH = "CREATE_MATCH"
export const GAME_BY_ID = "GAME_BY_ID"
export const MATCH_BY_GAME_ID = "MATCH_BY_GAME_ID"
export const EDIT_GAME = "EDIT_GAME"
export const SORT = "SORT"
export const CLEAR_GAME_DETAIL = "CLEAR_GAME_DETAIL"
export const SEARCH_GAMES = "SEARCH_GAMES"
export const DELETE_PLATFORM = "DELETE_PLATFORM"

export const GET_EXTERNAL_GAMES = "GET_EXTERNAL_GAMES"
export const SEARCH_EXTERNAL_GAMES = "SEARCH_EXTERNAL_GAMES"
export const EDIT_EXTERNAL_GAMES = "EDIT_EXTERNAL_GAMES"
export const GET_EXTERNAL_GAMES_BY_ID = " GET_EXTERNAL_GAMES_BY_ID"

export const ALL_LEADERBOARD = "ALL_LEADERBOARD"
export const GET_PLAYER_DISTRIBUTION = "GET_PLAYER_DISTRIBUTION"
export const CREATE_CUSTOM_LEADERBOARD = "CREATE_CUSTOM_LEADERBOARD"
export const LEADERBOARD_BY_ID = "LEADERBOARD_BY_ID"
export const GET_UNIVERSAL_LEADERBOARD = "UNIVERSAL_LEADERBOARD"
export const SEARCH_LEADERBOARD = "SEARCH_LEADERBOARD"
export const GET_LEADERBOARD_DETAIL = "GET_LEADERBOARD_DETAIL"
export const CREATE_PD_LEADERBOARD = "CREATE_PD_LEADERBOARD"
export const CREATE_PLAYSTARZ_SCORING = "CREATE_PLAYSTARZ_SCORING"
export const GET_PLAYSTARZ_SCORING = "GET_PLAYSTARZ_SCORING"
export const EDIT_PLAYSTARZ_SCORING = "EDIT_PLAYSTARZ_SCORING"
export const PPSALL_ID = "PPSALL_ID"
export const GET_LINK = "GET_LINK"
export const CLEAR_LEADERBOARD = "CLEAR_LEADERBOARD"
export const SEARCH_UNIVERSAL_LEADERBOARD = "SEARCH_UNIVERSAL_LEADERBOARD"
export const SEARCH_LEADERBOARD_DETAILS = "SEARCH_LEADERBOARD_DETAILS"

export const GET_COMPETITION = "GET_COMPETITION"
export const CREATE_COMPETITION = "CREATE_COMPETITION"
export const EDIT_COMPETITION = "EDIT_COMPETITION"
export const SEARCH_COMPETITION = "SEARCH_COMPETITION"
export const GET_COMPETITION_DETAIL = "GET_COMPETITION_DETAIL"
export const GET_COMPETITION_BY_ID = "GET_COMPETITION_BY_ID"
export const STOP_COMPETITION = "STOP_COMPETITION"
export const CLEAR_COMPETITION = "CLEAR_COMPETITION"
export const GET_ORGANIZED_COMPETITION = "GET_ORGANIZED_COMPETITION"
export const CREATE_ORGANIZED_COMPETITION = "CREATE_ORGANIZED_COMPETITION"
export const EDIT_ORGANIZED_COMPETITION = "EDIT_ORGANIZED_COMPETITION"

export const GET_TEMPLATES = 'GET_TEMPLATES'
export const GET_TEMPLATES_BY_ID = "GET_TEMPLATES_BY_ID"
export const CREATE_TEMPLATES = 'CREATE_TEMPLATES'
export const EDIT_TEMPLATES = 'EDIT_TEMPLATES'
export const SEARCH_TEMPLATE = 'SEARCH_TEMPLATE'
export const GENRATE_PD = "GENRATE_PD"
export const CLEAR_TEMPLATES = "CLEAR_TEMPLATES"

export const GET_TRANSACTION = "GET_TRANSACTION"
export const GET_COUPONS = "GET_COUPONS"
export const CREATE_COUPONS = "CREATE_COUPONS"
export const GET_SCRATCH_CARD = "GET_SCRATCH_CARD"
export const ADD_SCRATCH_CARD = "ADD_SCRATCH_CARD"
export const GET_COUPON_BY_ID = "GET_COUPON_BY_ID"
export const GET_SCRATCH_CARD_BY_ID = "GET_SCRATCH_CARD_BY_ID"
export const CLEAR_COUPON_DETAIL = "CLEAR_COUPON_DETAIL"
export const CLEAR_SCRATCH_CARD_DETAIL = "CLEAR_SCRATCH_CARD_DETAIL"
export const EDIT_COUPON = "EDIT_COUPON"
export const EDIT_SCRATCH_CARD = "EDIT_SCRATCH_CARD"
export const SEARCH_COUPON = "SEARCH_COUPON"
export const SEARCH_SERATCH_CARD = "SEARCH_SCRATCH_CARD"
export const SEARCH_TRANSACTION = "SEARCH_TRANSACTION"
export const ADD_SINGLE_PAYOUT = "ADD_SINGLE_PAYOUT"
export const GET_SINGLE_PAYOUT = "GET_SINGLE_PAYOUT"

export const GET_REFERRAL = "GET_REFERRAL"
export const ADD_REFERRAL = "ADD_REFERRAL"
export const GET_REFERRAL_BY_ID = "GET_REFERRAL_BY_ID"
export const EDIT_REFERRAL = "EDIT_REFERRAL"

export const LOGIN = "LOGIN"
export const LOGIN_FAILED = "LOGIN_FAILED"
export const LOGOUT = "LOGOUT"

export const GET_VERSION = "GET_VERSION"
export const ADD_VERSION = "ADD_VERSION"
export const EDIT_VERSION = "EDIT_VERSION"
export const GET_VERSION_BY_ID = "GET_VERSION_BY_ID"
export const CLEAR_VERSION = "CLEAR_VERSION"

export const GET_PARTNERS = "GET_PARTNERS"
export const GET_PARTNERS_BY_ID = "GET_PARTNERS_BY_ID"
export const VERIFY_PARTNER = "VERIFY_PARTNER"

export const TOTAL_MONEY_COLLECTED = "TOTAL_MONEY_COLLECTED"
export const TOTAL_LIVE_COMPETITIONS = "TOTAL_LIVE_COMPETITIONS"
export const TOTAL_CHALLENGES = "TOTAL_CHALLENGES"
export const TOTAL_PAID_CHALLENGES = "TOTAL_PAID_CHALLENGES"

export const GET_TEAM = 'GET_TEAM'
export const CREATE_TEAM = 'CREATE_TEAM'
export const GET_TEAM_BY_ID = "GET_TEAM_BY_ID"
export const EDIT_TEAM = "EDIT_TEAM"

export const GET_ALL_CONDITIONTYPE = 'GET_ALL_CONDITIONTYPE'
export const GET_CONDITION_DETAIL = 'GET_CONDITION_DETAIL'
export const GET_ALL_ATTRIBUTE = "GET_ALL_ATTRIBUTE"
export const GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE = 'GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE'
export const GET_RULE = "GET_RULE"
export const CREATE_RULE = "CREATE_RULE"
export const EDIT_RULE = 'EDIT_RULE'
export const GET_RULE_DETAIL = "GET_RULE_DETAIL"
export const CREATE_CONDITIONTYPE = "CREATE_CONDITIONTYPE"
export const CREATE_ATTRIBUTE = "CREATE_ATTRIBUTE"
export const EDIT_ATTRIBUTE = "EDIT_ATTRIBUTE"
export const SEARCH_MODULE = "SEARCH_MODULE"
export const SEARCH_ATTRIBUTE = "SEARCH_ATTRIBUTE"

export const GET_DOCUMENTS = 'GET_DOCUMENTS'
export const GET_DOCUMENTS_DETAIL = 'GET_DOCUMENT_DETAIL'
export const CREATE_DOCUMENTS = 'CREATE_DOCUMENTS'
export const EDIT_DOCUMENTS = 'EDIT_DOCUMENTS'
export const CLEAR_DOCUMENT_DETAIL = 'CLEAR_DOCUMENT_DETAIL'