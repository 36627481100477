import React, {useState} from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import transitions from '@material-ui/core/styles/transitions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function TransactionFilter({show, onHide, FilterTransaction}) {

    const transitionsFilter = () => {
        FilterTransaction(transactionPurposeType, transactionRecipient, transactionSource, transactionType)
        onHide();
    }

    const close = () => {
        setShowTransactionType(false)
        setDebit(false)
        setCredit(false)
        setShowTransactionPurposeType(false)
        setCompEntryFee(false)
        setCompWinning(false)
        setCompLeaderboard(false)
        setCompStore(false)
        setCompCoupon(false)
        setCompScratchCard(false)
        setCompSignUp(false)
        setCompReferral(false)
        setCompWithdraw(false)
        setCompDeposite(false)
        setCompEntry(false)
        setCompStoreRefund(false)
        setCompRefund(false)
        setShowTransactionSource(false)
        setPlayerWalletSource(false)
        setWinningWalletSource(false)
        setBonusWalletSource(false)
        setPlaystarzBankSource(false)
        setDirtcubeBankSource(false)
        setShowTransactionRecipient(false)
        setPlayerWallet(false)
        setWinningWallet(false)
        setBonusWallet(false)
        setPlaystarzBank(false)
        setDirtcubeBank(false)
        FilterTransaction([], [], [], [])
        onHide();
    }

    const [transactionType, setTransactionType] = useState([])
    const [showTransactionType, setShowTransactionType]  = useState(false)
    const [debit, setDebit] = useState(false)
    const [credit, setCredit] = useState(false)

    const onTransactionType = (e) => {
        if(showTransactionType == false){
            setCredit(false)
            setDebit(false)
        }
        setShowTransactionType(!showTransactionType)
    }

    const ontransType = (e) => {
        let transactionList = [...transactionType]
        if(e.target.checked){
            if(e.target.value == 1) {
                setTransactionType([...transactionType, e.target.value])
                setCredit(!credit)
            }else if(e.target.value == 2){
                setTransactionType([...transactionType, e.target.value])
                setDebit(!debit)
            }
        }else{
            if(e.target.value == 1) {
                setCredit(!credit)
            }else if(e.target.value == 2){
                setDebit(!debit)
            }
            transactionList.splice(transactionType.indexOf(e.target.value), 1)
            setTransactionType(transactionList)
        }
        
    }

    const [transactionPurposeType, setTransactionPurposeType] = React.useState([])
    const [showTransactionPurposeType, setShowTransactionPurposeType] = useState(false)
    const [compEntryFee, setCompEntryFee] = useState(false)
    const [compWinning, setCompWinning] = useState(false)
    const [compLeaderboard, setCompLeaderboard] = useState(false)
    const [compStore, setCompStore] = useState(false)
    const [compCoupon, setCompCoupon] = useState(false)
    const [compScratchCard, setCompScratchCard] = useState(false)
    const [compSignUp, setCompSignUp] = useState(false)
    const [compReferral, setCompReferral] = useState(false)
    const [compWithdraw, setCompWithdraw] = useState(false)
    const [compDeposit, setCompDeposite] = useState(false)
    const [compEntry, setCompEntry] = useState(false)
    const [compStoreRefund, setCompStoreRefund] = useState(false)
    const [compRefund, setCompRefund] = useState(false)

    const onTransactionPurposeType = (event) => {
        setShowTransactionPurposeType(event.target.checked);
    };

    const onTransPurposeType = (e) => {
        let transactionPuposeList = [...transactionPurposeType]
        if(e.target.checked){
            if(e.target.value == 2){
                setTransactionPurposeType([...transactionPurposeType, e.target.value])
                setCompEntryFee(!compEntryFee)
            }else if(e.target.value == 3){
                setTransactionPurposeType([...transactionPurposeType, e.target.value])
                setCompWinning(!compWinning)
            }else if(e.target.value == 4){
                setTransactionPurposeType([...transactionPurposeType, e.target.value])
                setCompLeaderboard(!compLeaderboard)
            }else if(e.target.value == 5){
                setTransactionPurposeType([...transactionPurposeType, e.target.value])
                setCompStore(!compStore)
            }else if(e.target.value == 6){
                setTransactionPurposeType([...transactionPurposeType, e.target.value])
                setCompCoupon(!compCoupon)
            }else if(e.target.value == 7){
                setTransactionPurposeType([...transactionPurposeType, e.target.value])
                setCompScratchCard(!compScratchCard)
            }else if(e.target.value == 8){
                setTransactionPurposeType([...transactionPurposeType, e.target.value])
                setCompSignUp(!compSignUp)
            }else if(e.target.value == 9){
                setTransactionPurposeType([...transactionPurposeType, e.target.value])
                setCompReferral(!compReferral)
            }else if(e.target.value == 10){
                setTransactionPurposeType([...transactionPurposeType, e.target.value])
                setCompWithdraw(!compWithdraw)
            }else if(e.target.value == 11){
                setTransactionPurposeType([...transactionPurposeType, e.target.value])
                setCompDeposite(!compDeposit)
            }else if(e.target.value == 12){
                setTransactionPurposeType([...transactionPurposeType, e.target.value])
                setCompEntry(!compEntry)
            }else if(e.target.value == 13){
                setTransactionPurposeType([...transactionPurposeType, e.target.value])
                setCompStoreRefund(!compStoreRefund)
            }else if(e.target.value == 14){
                setTransactionPurposeType([...transactionPurposeType, e.target.value])
                setCompRefund(!compRefund)
            }
        }else{
            if(e.target.value == 2){
                setCompEntryFee(!compEntryFee)
            }else if(e.target.value == 3){
                setCompWinning(!compWinning)
            }else if(e.target.value == 4){
                setCompLeaderboard(!compLeaderboard)
            }else if(e.target.value == 5){
                setCompStore(!compStore)
            }else if(e.target.value == 6){
                setCompCoupon(!compCoupon)
            }else if(e.target.value == 7){
                setCompScratchCard(!compScratchCard)
            }else if(e.target.value == 8){
                setCompSignUp(!compSignUp)
            }else if(e.target.value == 9){
                setCompReferral(!compReferral)
            }else if(e.target.value == 10){
                setCompWithdraw(!compWithdraw)
            }else if(e.target.value == 11){
                setCompDeposite(!compDeposit)
            }else if(e.target.value == 12){
                setCompEntry(!compEntry)
            }else if(e.target.value == 13){
                setCompStoreRefund(!compStoreRefund)
            }else if(e.target.value == 14){
                setCompRefund(!compRefund)
            }
            transactionPuposeList.splice(transactionPurposeType.indexOf(e.target.value), 1)
            setTransactionPurposeType(transactionPuposeList)
        }
    }

    const [transactionSource, setTransactionSource] = React.useState([]);
    const [showtransactionSource, setShowTransactionSource] = useState(false)
    const [playerWalletSource, setPlayerWalletSource] = useState(false)
    const [winningWalletSource, setWinningWalletSource] = useState(false)
    const [bonousWalletSource, setBonusWalletSource] = useState(false)
    const [playstarzBankSource, setPlaystarzBankSource] = useState(false)
    const [dirtcubeBankSource, setDirtcubeBankSource] = useState(false)

    const onTransactionSource = (event) => {
        setShowTransactionSource(event.target.checked);
    };

    const onTransSource = (e) => {
        let transactionSourceList = [...transactionSource]
        if(e.target.checked){
            if(e.target.value == 1) {
                setTransactionSource([...transactionSource, e.target.value])
                setPlayerWalletSource(!playerWalletSource)
            }else if(e.target.value == 2){
                setTransactionSource([...transactionSource, e.target.value])
                setWinningWalletSource(!winningWalletSource)
            }else if(e.target.value == 3){
                setTransactionSource([...transactionSource, e.target.value])
                setBonusWalletSource(!bonousWalletSource)
            }else if(e.target.value == 4){
                setTransactionSource([...transactionSource, e.target.value])
                setPlaystarzBankSource(!playstarzBankSource)
            }else if(e.target.value == 6){
                setTransactionSource([...transactionSource, e.target.value])
                setDirtcubeBankSource(!dirtcubeBankSource)
            }
        }else{
            if(e.target.value == 1) {
                setPlayerWalletSource(!playerWalletSource)
            }else if(e.target.value == 2){
                setWinningWalletSource(!winningWalletSource)
            }else if(e.target.value == 3){
                setBonusWalletSource(!bonousWalletSource)
            }else if(e.target.value == 4){
                setPlaystarzBankSource(!playstarzBankSource)
            }else if(e.target.value == 6){
                setDirtcubeBankSource(!dirtcubeBankSource)
            }
            transactionSourceList.splice(transactionSource.indexOf(e.target.value), 1)
            setTransactionSource(transactionSourceList)
        }
    }

    const [transactionRecipient, setTransactionRecipient] = React.useState([]);
    const [showtransactionRecipient, setShowTransactionRecipient] = useState(false)
    const [playerWallet, setPlayerWallet] = useState(false)
    const [winningWallet, setWinningWallet] = useState(false)
    const [bonousWallet, setBonusWallet] = useState(false)
    const [playstarzBank, setPlaystarzBank] = useState(false)
    const [dirtcubeBank, setDirtcubeBank] = useState(false)

    const onTransactionRecipient = (event) => {
        setShowTransactionRecipient(event.target.checked);
    };

    const onTransRecipient = (e) => {
        let transactionRecipientList = [...transactionRecipient]
        if(e.target.checked){
            if(e.target.value == 1) {
                setTransactionRecipient([...transactionRecipient, e.target.value])
                setPlayerWallet(!playerWallet)
            }else if(e.target.value == 2){
                setTransactionRecipient([...transactionRecipient, e.target.value])
                setWinningWallet(!winningWallet)
            }else if(e.target.value == 3){
                setTransactionRecipient([...transactionRecipient, e.target.value])
                setBonusWallet(!bonousWallet)
            }else if(e.target.value == 4){
                setTransactionRecipient([...transactionRecipient, e.target.value])
                setPlaystarzBank(!playstarzBank)
            }else if(e.target.value == 6){
                setTransactionRecipient([...transactionRecipient, e.target.value])
                setDirtcubeBank(!dirtcubeBank)
            }
        }else{
            if(e.target.value == 1) {
                setPlayerWallet(!playerWallet)
            }else if(e.target.value == 2){
                setWinningWallet(!winningWallet)
            }else if(e.target.value == 3){
                setBonusWallet(!bonousWallet)
            }else if(e.target.value == 4){
                setPlaystarzBank(!playstarzBank)
            }else if(e.target.value == 6){
                setDirtcubeBank(!dirtcubeBank)
            }
            transactionRecipientList.splice(transactionRecipient.indexOf(e.target.value), 1)
            setTransactionRecipient(transactionRecipientList)
        }
    }

    // const [showReferral, setShowReferral] = useState(false)
    // const [referralReward, setReferralReaward] = useState([])
    // const [cashBonus, setCashBonus] = useState(false)
    // const [currency, setCurrency] = useState(false)
    // const [inventory, setInventory] = useState(false)

    // const onReferralReward = () => {
    //     setShowReferral(!showReferral)
    // }

    // const onReferralRewardType = (e) => {
    //     let referralRewardList = [...referralReward]
    //     if(e.target.checked){
    //         if(e.target.value == 1) {
    //             setReferralReaward([...referralReward, e.target.value])
    //             setCashBonus(!cashBonus)
    //         }else if(e.target.value == 2){
    //             setReferralReaward([...referralReward, e.target.value])
    //             setCurrency(!currency)
    //         }else if(e.target.value == 3){
    //             setReferralReaward([...referralReward, e.target.value])
    //             setInventory(!inventory)
    //         }
    //     }else{
    //         if(e.target.value == 1) {
    //             setCashBonus(!cashBonus)
    //         }else if(e.target.value == 2){
    //             setCurrency(!currency)
    //         }else if(e.target.value == 3){
    //             setInventory(!inventory)
    //         }
    //         referralRewardList.splice(referralReward.indexOf(e.target.value), 1)
    //         setReferralReaward(referralRewardList)
    //     }
    // }

    return (
        <React.Fragment>
            <Modal size="lg" centered show={show} onHide={onHide} className='filter-main'>
            <Modal.Header><p className='heading-title'>Add Filter</p></Modal.Header>
                <Modal.Body>

                    <Row className='my-2'>
                        <Col lg={12} md={12} sm={12}>
                            <FormControlLabel control={<Checkbox checked={showTransactionType} onChange={onTransactionType} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Transaction Type" />
                        </Col>
                    </Row>

                    {showTransactionType == true ?
                        <Row className='my-2'>
                            <Col lg={6} md={6} sm={6}>
                                <FormControlLabel control={<Checkbox checked={credit} onChange={(e)=>{ontransType(e)}}  value={1} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Credit" />
                            </Col>

                            <Col lg={6} md={6} sm={6}>
                                <FormControlLabel control={<Checkbox checked={debit} onChange={(e)=>{ontransType(e)}} value={2} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Debit" />
                            </Col>
                        </Row>
                    : ""}

                    <Row className='my-2'>
                        <Col lg={12} md={12} sm={12}>
                            <FormControlLabel control={<Checkbox checked={showTransactionPurposeType} onChange={onTransactionPurposeType} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Transaction Purpose Type" />
                        </Col>
                    </Row>
                    {showTransactionPurposeType == true ?
                        <div>
                            <Row className='my-2'>
                                <Col lg={4} md={4} sm={12}>
                                    <FormControlLabel control={<Checkbox checked={compEntryFee} onChange={(e)=>{onTransPurposeType(e)}} value={2} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Competition Entry Fee" />
                                </Col>
                                <Col lg={4} md={4} sm={12}>
                                    <FormControlLabel control={<Checkbox checked={compWinning} onChange={(e)=>{onTransPurposeType(e)}} value={3} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Competition Winning" />
                                </Col>
                                <Col lg={4} md={4} sm={12}>
                                    <FormControlLabel control={<Checkbox checked={compLeaderboard} onChange={(e)=>{onTransPurposeType(e)}} value={4} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Leaderboard Prize" />
                                </Col>
                            </Row>

                            <Row className='my-2'>
                                <Col lg={4} md={4} sm={12}>
                                    <FormControlLabel control={<Checkbox checked={compStore} onChange={(e)=>{onTransPurposeType(e)}} value={5} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Store Purchase" />
                                </Col>
                                <Col lg={4} md={4} sm={12}>
                                    <FormControlLabel control={<Checkbox checked={compCoupon} onChange={(e)=>{onTransPurposeType(e)}} value={6} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Coupon Cashback" />
                                </Col>
                                <Col lg={4} md={4} sm={12}>
                                    <FormControlLabel control={<Checkbox checked={compScratchCard} onChange={(e)=>{onTransPurposeType(e)}} value={7} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Scratch Card Bonus" />
                                </Col>
                            </Row>

                            <Row className='my-2'>
                                <Col lg={4} md={4} sm={12}>
                                    <FormControlLabel control={<Checkbox checked={compSignUp} onChange={(e)=>{onTransPurposeType(e)}} value={8} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Sign Up Bonus" />
                                </Col>
                                <Col lg={4} md={4} sm={12}>
                                    <FormControlLabel control={<Checkbox checked={compReferral} onChange={(e)=>{onTransPurposeType(e)}} value={9} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Referral Bonus" />
                                </Col>
                                <Col lg={4} md={4} sm={12}>
                                    <FormControlLabel control={<Checkbox checked={compWithdraw} onChange={(e)=>{onTransPurposeType(e)}} value={10} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Withdrawal" />
                                </Col>
                            </Row>

                            <Row className='my-2'>
                                <Col lg={4} md={4} sm={12}>
                                    <FormControlLabel control={<Checkbox checked={compDeposit} onChange={(e)=>{onTransPurposeType(e)}} value={11} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Deposit" />
                                </Col>
                                <Col lg={4} md={4} sm={12}>
                                    <FormControlLabel control={<Checkbox checked={compEntry} onChange={(e)=>{onTransPurposeType(e)}} value={12} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Competition Entry Fee Refund" />
                                </Col>
                                <Col lg={4} md={4} sm={12}>
                                    <FormControlLabel control={<Checkbox checked={compStoreRefund} onChange={(e)=>{onTransPurposeType(e)}} value={13} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Store Purchase Refund" />
                                </Col>
                            </Row>

                            <Row className='my-2'>
                                <Col lg={4} md={4} sm={12}>
                                    <FormControlLabel control={<Checkbox checked={compRefund} onChange={(e)=>{onTransPurposeType(e)}} value={14} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Withdrawal Refund" />
                                </Col>
                            </Row>
                        </div>
                    : ""}

                    
                    <Row className='my-2'>
                        <Col lg={12} md={12} sm={12}>
                            <FormControlLabel control={<Checkbox checked={showtransactionSource} onChange={onTransactionSource} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Transaction Source" />
                        </Col>
                    </Row>
                    {showtransactionSource == true ?
                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <Row className='my-2'>
                                    <Col lg={4} md={4} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={playerWalletSource} onChange={(e)=>{onTransSource(e)}} value={1} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Player Deposit Wallet" />
                                    </Col>
                                    <Col lg={4} md={4} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={winningWalletSource} onChange={(e)=>{onTransSource(e)}} value={2} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Player Winnings Wallet" />
                                    </Col>
                                    <Col lg={4} md={4} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={bonousWalletSource} onChange={(e)=>{onTransSource(e)}} value={3} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Player Bonus Wallet" />
                                    </Col>
                                </Row>

                                <Row className='my-2'>
                                    <Col lg={4} md={4} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={playstarzBankSource} onChange={(e)=>{onTransSource(e)}} value={4} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="PlayStarz Bank" />
                                    </Col>
                                    <Col lg={4} md={4} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={dirtcubeBankSource} onChange={(e)=>{onTransSource(e)}} value={6} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Dirtcube Bank" />
                                    </Col>
                                    <Col lg={4} md={4} sm={12}>
                                        
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </div>      
                    : ""}


                    <Row className='my-2'>
                        <Col lg={12} md={12} sm={12}>
                            <FormControlLabel control={<Checkbox checked={showtransactionRecipient} onChange={onTransactionRecipient} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Transaction Recipient" />
                        </Col>
                    </Row>
                    {showtransactionRecipient == true ?
                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <Row className='my-2'>
                                    <Col lg={4} md={4} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={playerWallet} onChange={(e)=>{onTransRecipient(e)}} value={1} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Player Deposit Wallet" />
                                    </Col>
                                    <Col lg={4} md={4} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={winningWallet} onChange={(e)=>{onTransRecipient(e)}} value={2} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Player Winnings Wallet" />
                                    </Col>
                                    <Col lg={4} md={4} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={bonousWallet} onChange={(e)=>{onTransRecipient(e)}} value={3} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Player Bonus Wallet" />
                                    </Col>
                                </Row>

                                <Row className='my-2'>
                                    <Col lg={4} md={4} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={playstarzBank} onChange={(e)=>{onTransRecipient(e)}} value={4} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="PlayStarz Bank" />
                                    </Col>
                                    <Col lg={4} md={4} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={dirtcubeBank} onChange={(e)=>{onTransRecipient(e)}} value={6} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Dirtcube Bank" />
                                    </Col>
                                    <Col lg={4} md={4} sm={12}>
                                        
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </div>      
                    : ""}
                    
                    {/* <Row className='my-2'>
                        <Col lg={12} md={12} sm={12}>
                            <FormControlLabel control={<Checkbox checked={showReferral} onChange={onReferralReward} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Referral Reward" />
                        </Col>
                    </Row>
                    {showReferral == true ?
                        <Row>
                            <Col lg={4} md={4} sm={12}>
                                <FormControlLabel control={<Checkbox checked={cashBonus} onChange={(e)=>{onReferralRewardType(e)}} value={1} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Bonus Cash" />
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <FormControlLabel control={<Checkbox checked={currency} onChange={(e)=>{onReferralRewardType(e)}} value={2} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Virtual Currency" />
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                            <FormControlLabel control={<Checkbox checked={inventory} onChange={(e)=>{onReferralRewardType(e)}} value={3} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Inventory Rewards" />    
                            </Col>
                        </Row>
                    : ""} */}

                </Modal.Body>
                <Modal.Footer className='filter-footer'>
                    <button className="btn btn-md btn-success" onClick={transitionsFilter}>Apply Filter</button>
                    <button className="btn btn-md btn-danger" onClick={close}>Clear Filters</button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default TransactionFilter
