import React from 'react'
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import { Provider } from 'react-redux';
import store from './redux/store/store'
import { Routes } from './routes';
import PrivateRoute from './reusable/PrivateRoute';
import { getLoggedInUser } from './redux/action/authAction';

/* Pages */
import Games from './pages/Games';
import ExternalGames from './pages/Games/ExternalGame'
import AddGame from './pages/AddGame';
import PPSALL from './pages/PPSALL'
import LeaderboardMain from './pages/LeaderboardMain';
import LeaderboardDetail from './pages/LeaderboardDetail';
import PointDistribution from './pages/PointDistribution'
import PDReport from './pages/PDReport';
import EditGame from './pages/EditGame';
import LoginPage from './pages/LoginPage'
import LoginPageTwo from './pages/LoginPageTwo'
import LoginPageThree from './pages/LoginPageThree'
import Competition from './pages/CompetitionMain'
import CreateCompetition from './pages/CreateCompetition'
import CompetitionDetail from './pages/CompetitionDetail';
import EditCompetition from './pages/EditCompetition';
import TransactionsHistory from './pages/PlaystarzBank/TransactionHistory/TransactionHistory'
import Payout from './pages/PlaystarzBank/Payout';
import SinglePayout from './pages/PlaystarzBank/SinglePayout';
import BulkPayout from './pages/PlaystarzBank/BulkPayout';
import Coupans from './pages/PlaystarzBank/Coupans/Coupans';
import ScratchCard from './pages/PlaystarzBank/ScratchCard/ScratchCard';
import CreateCoupan from './pages/PlaystarzBank/Coupans/CreateCoupan';
import CreateSC from './pages/PlaystarzBank/ScratchCard/CreateSC';
import Referrals from './pages/Referrals/Referrals';
import EditCoupon from './pages/PlaystarzBank/Coupans/EditCoupon';
import EditScratchCard from './pages/PlaystarzBank/ScratchCard/EditScratchCard';
import PageNotFound from './pages/examples/NotFound'
import Version from './pages/Version/Version';
import CompetitionResult from './pages/Competition/CompetitionResult';
import DashboardOverview from './pages/dashboard/DashboardOverview';
import LoginPageOne from './pages/LoginPageOne';
import Templates from './pages/Competition/Templates';
import EditTemplates from './pages/Competition/EditTemplates';
import CopyTemplate from './pages/Competition/CopyTemplate';
import ViewExternalGames from './pages/Games/ViewExternalGames';
import Organisation from './pages/Organisation/Organisation';
import ViewOrganisation from './pages/Organisation/ViewOrganisation';
import CustomLeaderboard from './pages/Leaderboard/CustomLeaderboard';
import Teams from './pages/Teams/Teams';
import CreateTeams from './pages/Teams/CreateTeams';
import EditTeam from './pages/Teams/EditTeam';
import BusinessLogic from './pages/BusinessLogic/BusinessLogic';
import Rules from './pages/BusinessLogic/RuleNOutcome/Rules';
import Attribute from './pages/BusinessLogic/Attribute/Attribute';
import Decision from './pages/BusinessLogic/Decision/Decision';
import EditDecision from './pages/BusinessLogic/Decision/EditDecision'
import Outcome from './pages/BusinessLogic/RuleNOutcome/Outcome';
import Module from './pages/BusinessLogic/Module/Module';
import EditOutcome from './pages/BusinessLogic/RuleNOutcome/EditOutcome';
import EditModule from './pages/BusinessLogic/Module/EditModule';
import EditAttribute from './pages/BusinessLogic/Attribute/EditAttribute';
import Document from './pages/Documents/Document';
import AddDocument from './pages/Documents/AddDocument';
import EditDocument from './pages/Documents/EditDocument';
import Gamesincompetition from './pages/Competition/Gamesincompetition';
import Gamewithcompetition from './pages/Competition/Gamewithcompetition';

function App() {

    const token = localStorage.getItem('token')

    if(token){
        store.dispatch(getLoggedInUser())
    }
    
    return(
        <Provider store={store}>
            <div className="App">
                    <Switch>
                        <Route exact path={Routes.Login.path} component={LoginPage} />
                        <Route exact path='/loginOne' component={LoginPageOne} />
                        <Route exact path='/loginTwo' component={LoginPageTwo} />
                        <Route exact path='/loginThree' component={LoginPageThree} />
                        <div>
                            <PrivateRoute exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
                            <PrivateRoute exact path={Routes.AddGame.path} component={AddGame} />
                            <PrivateRoute exact path={Routes.Games.path} component={Games} />
                            <PrivateRoute exact path={Routes.ExternalGames.path} component={ExternalGames} />
                            <PrivateRoute exact path={Routes.ViewExternalGame.path} component={ViewExternalGames} />
                            <PrivateRoute exact path={Routes.EditGame.path} component={EditGame} />
                            <PrivateRoute exact path={Routes.PPSALL.path} component={PPSALL} />
                            <PrivateRoute exact path={Routes.Leaderboard.path} component={LeaderboardMain} />
                            <PrivateRoute exact path={Routes.CreateCustomLeaderboard.path} component={CustomLeaderboard} />
                            <PrivateRoute exact path={Routes.LeaderboardDetail.path} component={LeaderboardDetail} />
                            <PrivateRoute exact path={Routes.PointDistribution.path} component={PointDistribution}/>
                            <PrivateRoute exact path={Routes.PDReport.path} component={PDReport} />
                            <PrivateRoute exact path={Routes.Competition.path} component={Competition} />
                            <PrivateRoute exact path={Routes.CreateTemplates.path} component={Templates} />
                            <PrivateRoute exact path={Routes.CopyTemplates.path} component={CopyTemplate} />
                            <PrivateRoute exact path={Routes.EditTemplates.path} component={EditTemplates} />
                            <PrivateRoute exact path={Routes.CreateCompetition.path} component={CreateCompetition} />
                            <PrivateRoute exact path={Routes.EditCompetition.path} component={EditCompetition} />
                            <PrivateRoute exact path={Routes.CompetitionDetail.path} component={CompetitionDetail} />
                            <PrivateRoute exact path={Routes.CompetitionResult.path} component={CompetitionResult} />
                            <PrivateRoute exact path={Routes.TransactionsHistory.path} component={TransactionsHistory} />
                            <PrivateRoute exact path={Routes.Payout.path} component={Payout} />
                            <PrivateRoute exact path={Routes.SinglePayout.path} component={SinglePayout} />
                            <PrivateRoute exact path={Routes.BulkPayout.path} component={BulkPayout} />
                            <PrivateRoute exact path={Routes.Coupans.path} component={Coupans} />
                            <PrivateRoute exact path={Routes.ScratchCard.path} component={ScratchCard} />
                            <PrivateRoute exact path={Routes.CreateCoupan.path} component={CreateCoupan} />
                            <PrivateRoute exact path={Routes.CreateSC.path} component={CreateSC} />
                            <PrivateRoute exact path={Routes.Referrals.path} component={Referrals} />
                            <PrivateRoute exact path={Routes.EditCoupon.path} component={EditCoupon} />
                            <PrivateRoute exact path={Routes.EditScratchCard.path} component={EditScratchCard} />
                            <PrivateRoute exact path={Routes.Version.path} component={Version} />
                            <PrivateRoute exact path={Routes.Organisation.path} component={Organisation} />
                            <PrivateRoute exact path={Routes.ViewOrganisation.path} component={ViewOrganisation} />
                            <PrivateRoute exact path={Routes.Teams.path} component={Teams} />
                            <PrivateRoute exact path={Routes.CreateTeams.path} component={CreateTeams} />
                            <PrivateRoute exact path={Routes.EditTeams.path} component={EditTeam} />
                            <PrivateRoute exact path={Routes.BusinessLogic.path} component={BusinessLogic} />
                            <PrivateRoute exact path={Routes.Rules.path} component={Rules} />
                            <PrivateRoute exact path={Routes.Module.path} component={Module} />
                            <PrivateRoute exact path={Routes.EditModule.path} component={EditModule} />
                            <PrivateRoute exact path={Routes.Attribute.path} component={Attribute} />
                            <PrivateRoute exact path={Routes.Decision.path} component={Decision} />
                            <PrivateRoute exact path={Routes.EditRule.path} component={EditDecision} />
                            <PrivateRoute exact path={Routes.EditOutcome.path} component={EditOutcome} />
                            <PrivateRoute exact path={Routes.Outcome.path} component={Outcome} />
                            <PrivateRoute exact path={Routes.editAttribute.path} component={EditAttribute} />
                            <PrivateRoute exact path={Routes.document.path} component={Document} />
                            <PrivateRoute exact path={Routes.createDocument.path} component={AddDocument} />
                            <PrivateRoute exact path={Routes.editDocument.path} component={EditDocument} />
                            <PrivateRoute exact path={Routes.gamesInComp.path} component={Gamesincompetition}/>
                            <PrivateRoute exact path={Routes.gamesWithComp.path} component={Gamewithcompetition}/> 
                        </div>
                    </Switch>  
            </div> 
        </Provider>
    )

}


export default App