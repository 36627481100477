import React, {useState} from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import { useHistory } from "react-router-dom";
import {getallGamesAction} from '../../../redux/action/gameAction'
import {useDispatch, useSelector} from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function ExternalFilter({show, onHide, filterGames}) {


    const dispatch = useDispatch();
    let history = useHistory();

    const [showgameworld, setShowgameworld] = useState(false)

    const onchangeWorld = () => {
        setShowgameworld(!showgameworld)
    } 

    const [world, setWorld] = useState([])
    const [action, setAction] = useState(false)
    const [adventure, setAdventure] = useState(false)
    const [arcade, setArcade] = useState(false)
    const [battleRoyale, setBattleRoyale] = useState(false)
    const [casual, setCasual] = useState(false)
    const [card, setCatd] = useState(false)
    const [rolePlaying, setRolePlaying] = useState(false)
    const [moba, setMoba] = useState(false)
    const [puzzle, setPuzzle] = useState(false)
    const [racing, setRacing] = useState(false)
    const [strategy, setStrategy] = useState(false)
    const [sports, setSports] = useState(false)
    const [trivia, setTrivia] = useState(false)
    const [word, setWord] = useState(false)
    const [simulation, setSimulation] = useState(false)

    const onWorld = (e) => {
        let worldList = [...world]
        if(e.target.checked){
            if(e.target.value == 1) {
                setWorld([...world, e.target.value])
                setAction(!action)
            }else if(e.target.value == 2){
                setWorld([...world, e.target.value])
                setAdventure(!adventure)
            }else if(e.target.value == 3){
                setWorld([...world, e.target.value])
                setArcade(!arcade)
            }else if(e.target.value == 4){
                setWorld([...world, e.target.value])
                setBattleRoyale(!battleRoyale)
            }else if(e.target.value == 5){
                setWorld([...world, e.target.value])
                setCasual(!casual)
            }else if(e.target.value == 6){
                setWorld([...world, e.target.value])
                setCatd(!card)
            }else if(e.target.value == 7){
                setWorld([...world, e.target.value])
                setRolePlaying(!rolePlaying)
            }else if(e.target.value == 8){
                setWorld([...world, e.target.value])
                setMoba(!moba)
            }else if(e.target.value == 9){
                setWorld([...world, e.target.value])
                setPuzzle(!puzzle)
            }else if(e.target.value == 10){
                setWorld([...world, e.target.value])
                setRacing(!racing)
            }else if(e.target.value == 11){
                setWorld([...world, e.target.value])
                setStrategy(!strategy)
            }else if(e.target.value == 12){
                setWorld([...world, e.target.value])
                setSports(!sports)
            }else if(e.target.value == 13){
                setWorld([...world, e.target.value])
                setTrivia(!trivia)
            }else if(e.target.value == 14){
                setWorld([...world, e.target.value])
                setWord(!word)
            }else if(e.target.value == 15){
                setWorld([...world, e.target.value])
                setSimulation(!simulation)
            }
        }else{
            if(e.target.value == 1) {
                setAction(!action)
            }else if(e.target.value == 2){
                setAdventure(!adventure)
            }else if(e.target.value == 3){
                setArcade(!arcade)
            }else if(e.target.value == 4){
                setBattleRoyale(!battleRoyale)
            }else if(e.target.value == 5){
                setCasual(!casual)
            }else if(e.target.value == 6){
                setCatd(!card)
            }else if(e.target.value == 7){
                setRolePlaying(!rolePlaying)
            }else if(e.target.value == 8){
                setMoba(!moba)
            }else if(e.target.value == 9){
                setPuzzle(!puzzle)
            }else if(e.target.value == 10){
                setRacing(!racing)
            }else if(e.target.value == 11){
                setStrategy(!strategy)
            }else if(e.target.value == 12){
                setSports(!sports)
            }else if(e.target.value == 13){
                setTrivia(!trivia)
            }else if(e.target.value == 14){
                setWord(!word)
            }else if(e.target.value == 15){
                setSimulation(!simulation)
            }
            worldList.splice(world.indexOf(e.target.value), 1)
            setWorld(worldList)
        }
    }

    const [Orientation, setOrientation] = useState([])
    const [showorientation, setShoworientation] = useState(false)
    const [portrait, setPortrait] = useState(false)
    const [landscape, setLandscape] = useState(false)
    
    const onchangeOrientation = () => {
        setShoworientation(!showorientation)
    }

    const onOrientation = (e) => {
        let orientationList = [...Orientation]
        if(e.target.checked){
            if(e.target.value == "true"){
                setOrientation([...Orientation, e.target.value])
                setLandscape(!landscape)
            }else if(e.target.value == "false"){
                setOrientation([...Orientation, e.target.value])
                setPortrait(!portrait)
            }
        }else{
            if(e.target.value == "true"){
                setLandscape(!landscape)
            }else if(e.target.value == "false"){
                setPortrait(!portrait)
            }
            orientationList.splice(Orientation.indexOf(e.target.value), 1)
            setOrientation(orientationList)
        }
    }

    const [gameStatus, setGameStatus] = useState([])
    const [showmatchformat, setShowmatchformat] = useState(false)
    const [accpted, setAccepted] = useState(false)
    const [rejected, setRejected] = useState(false)
    const [inReview, setInReview] = useState(false)

    const onchangeMatchformat = () => {
        setShowmatchformat(!showmatchformat)
    }

    const onGameStatus = (e) => {
        let gameStatusList = [...gameStatus]
        if(e.target.checked){
            if(e.target.value == "accepted") {
                setGameStatus([...gameStatus, e.target.value])
                setAccepted(!accpted)
            }else if(e.target.value == "rejected"){
                setGameStatus([...gameStatus, e.target.value])
                setRejected(!rejected)
            }else if(e.target.value == "in review"){
                setGameStatus([...gameStatus, e.target.value])
                setInReview(!inReview)
            }
        }else{
            if(e.target.value == "accepted") {
                setAccepted(!accpted)
            }else if(e.target.value == "rejected"){
                setRejected(!rejected)
            }else if(e.target.value == "in review"){
                setInReview(!inReview)
            }
            gameStatusList.splice(gameStatus.indexOf(e.target.value), 1)
            setGameStatus(gameStatusList)
        }
    }

    const [platform, setPlatform] = useState([])
    const [showgameplatform, setShowgameplatform] = useState(false)
    const [ios, setIOS] = useState(false)
    const [android, setAndroid] = useState(false)

    const onchangeGameplatform = () => {
        setShowgameplatform(!showgameplatform)
    }

    const GamePlatform = (e) => {
        let platformList = [...platform]
        if(e.target.checked){
            if(e.target.value == 'android'){
                setPlatform([...platform, e.target.value])
                setAndroid(!android)
            }else if(e.target.value == 'ios'){
                setPlatform([...platform, e.target.value])
                setIOS(!ios)
            }
        }else{
            if(e.target.value == 'android'){
                setAndroid(!android)
            }else if(e.target.value == 'ios'){
                setIOS(!ios)
            }
            platformList.splice(platform.indexOf(e.target.value), 1)
            setPlatform(platformList)
        }
    }


    const [showgamegenre, setShowgamegenre] = useState(false)

    const onchangeGenre = () => {
        setShowgamegenre(!showgamegenre)
    }
    
    const AddFilter = () => {
        filterGames(Orientation, world, gameStatus, platform)
        onHide();
    }

    const close = () => {

        setWorld(false)
        setAction(false)
        setAdventure(false)
        setArcade(false)
        setBattleRoyale(false)
        setCasual(false)
        setCatd(false)
        setRolePlaying(false)
        setMoba(false)
        setPuzzle(false)
        setRacing(false)
        setStrategy(false)
        setSports(false)
        setTrivia(false)
        setWord(false)
        setSimulation(false)

        setIOS(false)
        setAndroid(false)

        setPortrait(false)
        setLandscape(false)

        setAccepted(false)
        setRejected(false)
        setInReview(false)

        filterGames([], [], [], [])
        onHide();

    }

    return (
        <React.Fragment>
                <Modal size="xl" id='filter_Modal' centered show={show} onHide={onHide} className='filter-main'>
                    <Modal.Header><p className='heading-title'>Add Filter</p></Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    {/* <FormControlLabel control={<Checkbox checked={showorientation} onChange={onchangeOrientation} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} className='filter-title' label="Screen Orientation" /> */}
                                    <label className='filter-title'>Screen Orientation</label>
                                    <div className='my-2'>
                                        <Row>
                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel className='filter-label' control={<Checkbox checked={portrait} onChange={(e)=>{onOrientation(e)}} value="false"  sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}/>} label={<span style={{ fontSize: '14px' }}>Portrait</span>} />
                                            </Col>

                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={landscape} onChange={(e)=>{onOrientation(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value= "true" />} label={<span style={{ fontSize: '14px' }}>Landscape</span>} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    {/* <FormControlLabel className='filter-title' control={<Checkbox checked={showgameworld} onChange={onchangeWorld} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Game World" /> */}
                                    <label className='filter-title'>Game Genre</label>
                                    <div className='my-2'>
                                        <Row>
                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={action} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={1} />} label={<span style={{ fontSize: '14px' }}>action</span>} />
                                            </Col>

                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={adventure} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={2} />} label={<span style={{ fontSize: '14px' }}>adventure</span>}/>
                                            </Col>

                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={arcade} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={3} />} label={<span style={{ fontSize: '14px' }}>arcade</span>}/>
                                            </Col>

                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={battleRoyale} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={4} />}  label={<span style={{ fontSize: '14px' }}>battle-royale</span>}/>
                                            </Col>

                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={casual} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={5} />} label={<span style={{ fontSize: '14px' }}>casual</span>}/>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={card} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={6} />} label={<span style={{ fontSize: '14px' }}>card</span>} />
                                            </Col>

                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={rolePlaying} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={7} />} label={<span style={{ fontSize: '14px' }}>role-playing</span>}/>
                                            </Col>

                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={moba} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={8} />} label={<span style={{ fontSize: '14px' }}>moba</span>}/>
                                            </Col>

                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={puzzle} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={9} />}  label={<span style={{ fontSize: '14px' }}>puzzle</span>}/>
                                            </Col>

                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={racing} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={10} />} label={<span style={{ fontSize: '14px' }}>racing</span>}/>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={strategy} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={11} />} label={<span style={{ fontSize: '14px' }}>strategy</span>} />
                                            </Col>

                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={sports} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={12} />} label={<span style={{ fontSize: '14px' }}>sports</span>}/>
                                            </Col>

                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={trivia} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={13} />} label={<span style={{ fontSize: '14px' }}>trivia</span>}/>
                                            </Col>

                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={word} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={14} />}  label={<span style={{ fontSize: '14px' }}>word</span>}/>
                                            </Col>

                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={simulation} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={15} />} label={<span style={{ fontSize: '14px' }}>simulation</span>}/>
                                            </Col>
                                        </Row>

                                        {/* <Row>
                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={racing} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={4} />} label="Racing" />
                                            </Col>

                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={sport} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={1} />} label="Sports" />
                                            </Col>

                                            <Col lg={2} md={2} sm={12}>
                                                    
                                            </Col>
                                        </Row> */}
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    {/* <FormControlLabel className='filter-title' control={<Checkbox checked={showmatchformat} onChange={onchangeMatchformat} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Match Format" /> */}
                                    <label className='filter-title'>Game Status</label>
                                    <div className='my-2'>
                                        <Row>
                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={accpted} onChange={(e)=>{onGameStatus(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value="accepted" />}  label={<span style={{ fontSize: '14px' }}>Accepted</span>}/>
                                            </Col>

                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={inReview} onChange={(e)=>{onGameStatus(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value="in review" />}  label={<span style={{ fontSize: '14px' }}>In Review</span>}/>
                                            </Col>

                                            <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={rejected} onChange={(e)=>{onGameStatus(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value="rejected" />}  label={<span style={{ fontSize: '14px' }}>Rejected</span>}/>
                                            </Col>
                                        </Row>  
                                    </div>
                                </Col>
                            </Row>

                            <Row  className='my-2'>
                                <Col lg={12} md={12} sm={12}>
                                    {/* <FormControlLabel className='filter-title' control={<Checkbox checked={showgameplatform} onChange={onchangeGameplatform} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={2} />} label="Platform" /> */}
                                    <label className='filter-title'>Game Platform</label>
                                    <div className='my-2'>
                                        <Row>
                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={android} onChange={(e)=>{GamePlatform(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value="android" />}  label={<span style={{ fontSize: '14px' }}>Android</span>}/>
                                            </Col>

                                            <Col lg={2} md={2} sm={12}>
                                                <FormControlLabel control={<Checkbox checked={ios} onChange={(e)=>{GamePlatform(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value="ios" />}  label={<span style={{ fontSize: '14px' }}>iOS</span>}/>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className='filter-footer'>
                        <button className="btn btn-md btn-success" onClick={AddFilter}>Apply Filter</button>
                        <button className="btn btn-mc btn-danger" onClick={close}>Clear Filter</button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
    )
}

export default ExternalFilter