import React, {useState} from 'react'
import { Modal, Row, Col } from "react-bootstrap"
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function CouponFilter({show, onHide, couponFilterData}) {

    const couponFilter = () => {
        // let couponFilter = {
        //     referralRewardsTypeId:CouponReward,
        //     couponStatus : couponStatus
        // }
        // Object.keys(couponFilter).forEach((key) => (couponFilter[key].length === 0) && delete couponFilter[key])
        couponFilterData(CouponReward, couponStatus)
        onHide();
    }

    const close = () => {
        couponFilterData([], [])
        onHide();
    }

    const [reward, setReward] = useState(false)
    const [CouponReward, setCouponReward] = useState([])
    const [virtualCurrency, setVirtualCurrency] = useState(false)
    const [Cashback, setCashback] = useState(false)
    const [Voucher, setVoucher] = useState(false)
    const [Inventory, setInventory] = useState(false)

    const onRewardType = () => {
        setReward(!reward)
    }

    const onCouponRewardType = (e) => {
        let CouponRewardList = [...CouponReward]
        if(e.target.checked){
            if(e.target.value == 1) {
                setCouponReward([...CouponReward, e.target.value])
                setVirtualCurrency(!virtualCurrency)
            }else if(e.target.value == 2){
                setCouponReward([...CouponReward, e.target.value])
                setCashback(!Cashback)
            }else if(e.target.value == 3){
                setCouponReward([...CouponReward, e.target.value])
                setVoucher(!Voucher)
            }
            else if(e.target.value == 4){
                setCouponReward([...CouponReward, e.target.value])
                setInventory(!Inventory)
            }
        }else{
            if(e.target.value == 1) {
                setVirtualCurrency(!virtualCurrency)
            }else if(e.target.value == 2){
                setCashback(!Cashback)
            }else if(e.target.value == 3){
                setVoucher(!Voucher)
            }
            else if(e.target.value == 4){
                setInventory(!Inventory)
            }
            CouponRewardList.splice(CouponReward.indexOf(e.target.value), 1)
            setCouponReward(CouponRewardList)
        }
    }


    const [status, setStatus] = useState(false)
    const [couponStatus, setCouponStatus] = useState([])
    const [yetStart, setYetStart] = useState(false)
    const [Active, setActive] = useState(false)
    const [Deactive, setDeactive] = useState(false)

    const onStatus = () => {
        setStatus(!status)
    }

    const onCouponStatus = (e) => {
        let couponStatusList = [...couponStatus]
        if(e.target.checked){
            if(e.target.value == 'yet to start'){
                setCouponStatus([...couponStatus, e.target.value])
                setYetStart(!yetStart)
            }else if(e.target.value == 'active'){
                setCouponStatus([...couponStatus, e.target.value])
                setActive(!Active)
            }else if(e.target.value == 'inactive'){
                setCouponStatus([...couponStatus, e.target.value])
                setDeactive(!Deactive)
            }
        }else{
            if(e.target.value == 'yet to start'){
                setYetStart(!yetStart)
            }else if(e.target.value == 'active'){
                setActive(!Active)
            }else if(e.target.value == 'inactive'){
                setDeactive(!Deactive)
            }
            couponStatusList.splice(couponStatus.indexOf(e.target.value), 1)
            setCouponStatus(couponStatusList)
        }
    }

    const [country, setCountry] = useState(false)
    const [couponCountry, setCouponCountry] = useState([])
    const [india, setIndia] = useState(false);
    const onCountry = () => {
        setCountry(!country)
    }

    const onCouponCountry = (e) => {
        let couponCountryList = [...couponCountry]
        if(e.target.checked){
            if(e.target.value == 1){
                setCouponCountry([...couponCountry, e.target.value])
                setIndia(!india)
            }
        }else{
            if(e.target.value == 1){
                setIndia(!india)
            }
            couponCountryList.splice(couponStatus.indexOf(e.target.value), 1)
            setCouponCountry(couponCountryList)
        }
    }

    return (
        <div>
            <Modal centered show={show} onHide={onHide} size="xl" aria-labelledby="contained-modal-title-vcenter" className='filter-main'>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <p className='heading-title'>Add Filter</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="justify-content-center">
                    <Col>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <label className='filter-title'>Reward Type</label>
                                {/* <FormControlLabel control={<Checkbox checked={reward} onChange={onRewardType} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Reward Type" /> */}
                            </Col>
                        </Row>
                            <div className='my-2'>
                                <Row>
                                    <Col xs={12} sm={12} lg={2} md={2}>
                                        <FormControlLabel control={<Checkbox checked={virtualCurrency} onChange={(e)=>{onCouponRewardType(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={1} />} label={<span style={{ fontSize: '14px' }}>Bonus Cash</span>}/>
                                    </Col>
                                    <Col xs={12} sm={12} lg={2} md={2}>
                                        <FormControlLabel control={<Checkbox checked={Cashback} onChange={(e)=>{onCouponRewardType(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={2} />} label={<span style={{ fontSize: '14px' }}>Virtual Cashback</span>}/>
                                    </Col>
                                    <Col xs={12} sm={12} lg={2} md={2}>
                                        <FormControlLabel control={<Checkbox checked={Voucher} onChange={(e)=>{onCouponRewardType(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={3} />} label={<span style={{ fontSize: '14px' }}>Inventory Reward</span>}/>
                                    </Col>
                                </Row>
                            </div>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <label className='filter-title'>Coupon Status</label>
                                {/* <FormControlLabel control={<Checkbox checked={status} onChange={onStatus} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Coupon Status" /> */}
                            </Col>
                        </Row>
                            <div className='my-2'>
                                <Row>
                                    <Col xs={12} sm={12} lg={2} md={2}>
                                        <FormControlLabel control={<Checkbox checked={yetStart} onChange={(e)=>{onCouponStatus(e)}} value="yet to start" sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label={<span style={{ fontSize: '14px' }}>Yet to Start</span>} />
                                    </Col>
                                    <Col xs={12} sm={12} lg={2} md={2}>
                                        <FormControlLabel control={<Checkbox checked={Active} onChange={(e)=>{onCouponStatus(e)}} value="active" sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label={<span style={{ fontSize: '14px' }}>Active</span>}/>
                                    </Col>
                                    <Col xs={12} sm={12} lg={2} md={2}>
                                        <FormControlLabel control={<Checkbox checked={Deactive} onChange={(e)=>{onCouponStatus(e)}} value="inactive" sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label={<span style={{ fontSize: '14px' }}>Inactive</span>}/>
                                    </Col>
                                </Row>
                            </div>

                        <Row className='my-2'>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                {/* <FormControlLabel control={<Checkbox checked={country} onChange={onCountry} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Country" /> */}
                                <label className='filter-title'>Country</label>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} lg={2} md={2}>
                                <FormControlLabel control={<Checkbox checked={india} onChange={onCouponCountry} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}  value={1} />} label={<span style={{ fontSize: '14px' }}>India</span>}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='filter-footer'>
                <Row>
                    <Col className="text-right mt-2">
                        <button className="btn btn-md btn-danger mx-2" onClick={couponFilter}>Apply Filter</button>
                        <button className="btn btn-md btn-success" onClick={close}>Clear Filter</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
        </div>
    )
}

export default CouponFilter