import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faSearch, faPlusCircle, faTrash, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col,InputGroup, Row, Nav, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import { Breadcrumb } from "react-bootstrap";
import { Link , useHistory} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { createModuleAction } from '../../../redux/action/businessLogicAction';
import { Routes } from '../../../routes';

export default function Module(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const [moduleName, setModuleName] = useState("")

    const onModuleName = (e) => {
        setModuleName(e.target.value)
    }

    const [disableBtn, setDisableBtn] = useState(false)

    const saveModule = () => {
        let createModule = {
            conditionName : moduleName
        }
        if(createModule.conditionName != '' || createModule.conditionName != undefined){
            dispatch(createModuleAction(createModule, props.history))
            setDisableBtn(true)
        }
    }

    const cancel = () => {
        history.push(Routes.BusinessLogic.path)
    }

    return (
        <>
        <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                        <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                            <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                            <Breadcrumb.Item href="#business-logic">Business Logic</Breadcrumb.Item>
                            <Breadcrumb.Item className="text-dark" active>Module</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>

            <div className="d-lg-flex justify-content-end flex-wrap flex-md-nowrap align-items-center py-4">
                
            </div>
            <div className="task-wrapper border bg-white border-light shadow-sm py-5 rounded gamestarz">
                <div className="row mb-4 px-4">
                    <div className="col col-lg-6 col-md-12 text-left">
                        <h4>Create Module</h4>
                    </div>
                    <div className="col col-lg-6 col-md-12 col-sm-12 text-right">

                    </div>
                </div>

                <div className="container-fluid payout">

                    <Row className='my-2'>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <label className='my-2'>Module Name</label>
                            <input type='text' className='form-control' onChange={(e)=>{onModuleName(e)}}/>
                        </Col>
                    </Row>

                    <Row className='my-3'>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Button className='btn btn-success' disabled={disableBtn ? true : false} onClick={saveModule}>Save</Button>
                            <Button className='btn btn-danger mx-3' onClick={cancel}>Cancel</Button>
                        </Col>
                    </Row>

                    {/* <Row className='my-4'>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <Button as={Link} to={Routes.Rules.path} className="button-style me-3" style={{width: "200px", display: "Inline !important"}}>
                                <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                                <span>Rule</span>
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <table className="table table-striped text-center table-custom">
                                <thead className='table-head'>
                                    <tr>
                                        <th className='head-border-left' scope="col">Created At</th>
                                        <th scope='col'>Rule</th>
                                        <th className='head-border-right' scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className='table-body'>
                                    <tr>
                                        <td>2022-01-16</td>
                                        <td>Rule No. 1</td>
                                        <td>
                                            <Dropdown className="ms-3">
                                                <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span className="icon icon-sm icon-dark">
                                                        <FontAwesomeIcon icon={faEllipsisH} />
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                    <Dropdown.Item as={Link}  className="ms-0">
                                                        <FontAwesomeIcon icon={faEye} className="me-2"/> View
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={Link}  className="text-warning ms-0">
                                                        <FontAwesomeIcon icon={faEdit} className="me-2"/> Edit
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={Link}  className="text-danger ms-0">
                                                        <FontAwesomeIcon icon={faTrash} className="me-2"/> Delete
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>   
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>2022-08-14</td>
                                        <td>Rule No. 2</td>
                                        <td>
                                            <Dropdown className="ms-3">
                                                <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span className="icon icon-sm icon-dark">
                                                        <FontAwesomeIcon icon={faEllipsisH} />
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                    <Dropdown.Item as={Link}  className="ms-0">
                                                        <FontAwesomeIcon icon={faEye} className="me-2"/> View
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={Link}  className="text-warning ms-0">
                                                        <FontAwesomeIcon icon={faEdit} className="me-2"/> Edit
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={Link}  className="text-danger ms-0">
                                                        <FontAwesomeIcon icon={faTrash} className="me-2"/> Delete
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>   
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>2022-03-06</td>
                                        <td>Rule No. 3</td>
                                        <td>
                                            <Dropdown className="ms-3">
                                                <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span className="icon icon-sm icon-dark">
                                                        <FontAwesomeIcon icon={faEllipsisH} />
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                    <Dropdown.Item as={Link}  className="ms-0">
                                                        <FontAwesomeIcon icon={faEye} className="me-2"/> View
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={Link}  className="text-warning ms-0">
                                                        <FontAwesomeIcon icon={faEdit} className="me-2"/> Edit
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={Link}  className="text-danger ms-0">
                                                        <FontAwesomeIcon icon={faTrash} className="me-2"/> Delete
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>   
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row> */}

                </div>
            </div>
        </>
    )
}
