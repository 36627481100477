import React, {useState} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBan} from '@fortawesome/free-solid-svg-icons'

function StopCompetition(props) {
    return (
        <React.Fragment>
           <Modal isOpen={props.addOpen} toggle={props.Addtoggle} centered>
               <ModalHeader><h6></h6></ModalHeader>
               <ModalBody>
                   <Row className="text-center mt-3">
                        <Col>
                            <FontAwesomeIcon icon={faBan} className="me-2 fa-3x text-warning" />
                        </Col>
                   </Row>
                   <Row className="text-center mt-3">
                        <Col>
                            <h6>Do you want to stop this Competition?</h6>
                        </Col>
                   </Row>
                   <Row className="justify-content-center text-center my-3">
                        <Col lg={12} md={12} sm={12}>
                            <button className="btn btn-danger mx-2" onClick={props.Stop}>Stop</button>
                            <button className="btn btn-warning mx-2" onClick={props.Addtoggle}>Cancel</button>
                        </Col>
                   </Row>
               </ModalBody>
           </Modal>
       </React.Fragment>
    )
}

export default StopCompetition