import React, { useEffect, useState } from 'react'
import {Row, Col, ButtonGroup, Button, InputGroup, Form, Nav, Dropdown} from '@themesberg/react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import { createCustomLeaderboardAction } from '../../redux/action/leaderboardAction';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

function CustomLeaderboard(props){

    const dispatch = useDispatch()
    const history = useHistory()

    const[leaderboardName, setLeaderboardName] = useState("")
    const[leaderboardDetail, setLeaderboardDetail] = useState("")
    const[leaderboardId, setLeaderboardId] = useState("")
    const[leaderboardOutcomeType, setLeaderboardOutcomeType] = useState("")

    const [leaderboardOutcomData, setLeaderboardOutcomData] = useState("")

    const getLeaderboardOutcomeType = async() => {
        let res = await axios.get('https://dev.gamestarz.xyz/v1/static-values')
        setLeaderboardOutcomData(res.data.leaderboardOutcome);
    }

    useEffect(() => {
        getLeaderboardOutcomeType();
    }, [])

    const onLeaderboardName = (e) => {
        if(e.target.value == "" || e.target.value == null){
            setLeaderboardNameError(true)
        }else{
            setLeaderboardName(e.target.value)
            setLeaderboardNameError(false)
        }
    }

    const onLeaderboardDetail = (e) => {
        if(e.target.value == "" || e.target.value == null){
            setLeaderboardNameError(true)
        }else{
            setLeaderboardDetail(e.target.value)
        }
    }

    const onLeaderboardId = (e) => {
        if(e.target.value == "" || e.target.value == null){
            setLeaderboardIdError(true)
        }else{
            setLeaderboardId(parseInt(e.target.value))
            setLeaderboardIdError(false)
        }
    }

    const onLeaderboardOutcomeType = (e) => {
        if(e.target.value == "" || e.target.value == null){
            setLeaderboardOutcomeError(true)
        }else{
            setLeaderboardOutcomeType(parseInt(e.target.value))
            setLeaderboardOutcomeError(false)
        }
    }


    const [state, setState] = useState({rank: "", points: ""})
    const [results, setResult] = useState([])
    const [point, setPoint] = useState("")
    
    const onChangePPSALL = (e) => {
        const ranks = [];
        for(var i = 1; i<= e.target.value;i++){
            ranks.push({rank: i, points:null})
        }
        setResult(ranks)
    }

    const handleChange = (i, value) => {
        const points = parseInt(value)
        results[i].points = points
        setResult(results);
    }

    const[win, setWin] = useState("")
    const WinPoints = (e) =>{
        setWin(parseInt(e.target.value))
    }

    const[draw, setDraw] = useState("")
    const DrawPoints = (e) =>{
        setDraw(parseInt(e.target.value))
    }

    const[loss, setLoss] = useState("")
    const LossPoints = (e) =>{
        setLoss(parseInt(e.target.value))
    }

    const [leaderboardNameErr, setLeaderboardNameError] = useState(false)
    const [leaderboardIdErr, setLeaderboardIdError] = useState(false)
    const [leaderboardOutcomeErr, setLeaderboardOutcomeError] = useState(false)

    const validation = () => {
        if(leaderboardName == null || leaderboardName == ""){
            setLeaderboardNameError(true)
        }

        if(leaderboardId == null || leaderboardId == ""){
            setLeaderboardIdError(true)
        }

        if(leaderboardOutcomeType == null || leaderboardOutcomeType == ""){
            setLeaderboardOutcomeError(true)
        }
    }

    const cancel = () => {
        history.push('/main-leaderboard')
    }


    const saveCustomLeaderboard = () => {

        let customeLeaderboard = {
            leaderboardName: leaderboardName,
            externalLeaderboardObjectId: leaderboardId,
            leaderboardOutcomeTypeMasterId: leaderboardOutcomeType
        }

        if(leaderboardName == "" || leaderboardId == "" || leaderboardOutcomeType == ""){
            validation()
        }else{
            dispatch(createCustomLeaderboardAction(customeLeaderboard, props.history))
        }

    }

    return (
        <>
            <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                        <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                            <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                            <Breadcrumb.Item href='#main-leaderboard'>Leaderboard</Breadcrumb.Item>
                            <Breadcrumb.Item className="text-dark" active>Custom Leaderboard</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5 gamestarz">
                <div className="container payout">
                    <Row className="my-3">
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <h4>Custom Leaderboard</h4>
                        </Col>
                    </Row>

                    <Row className='my-3'>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <label>Leaderboard Name</label>
                            <input className='form-control input_bg' type="text" onChange={(e)=>{onLeaderboardName(e)}} />
                            {leaderboardNameErr == true ? <span className='text-danger'>Leaderboard Name is required</span> : ""}
                        </Col>
                    </Row>

                    <Row className='my-3'>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <label>Leaderboard Description</label>
                            <textarea className='form-control input_bg' rows={4} onChange={(e)=>{onLeaderboardDetail(e)}}></textarea>
                        </Col>
                    </Row>

                    <Row className='my-3'>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <label>Leaderboard Object Id</label>
                            <input className='form-control input_bg' type="text" onChange={(e)=>{onLeaderboardId(e)}} />
                            {leaderboardIdErr == true ? <span className='text-danger'>Leaderboard Object Id is required</span> : ""}
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <label>Leaderboard Outcome Type</label>
                            <select className="form-control input_bg" onChange={(e)=>{onLeaderboardOutcomeType(e)}} >
                                <option value="" selected hidden>Select Leaderboard Outcome Type</option>
                                    {leaderboardOutcomData && leaderboardOutcomData.map((e)=>{
                                        return(
                                            <option key={e.id} value={e.id}>{e.leaderBoardOutComeName === "high_score" ? "High Score" : e.leaderBoardOutComeName === "time_trail" ? "Time Trail" : e.leaderBoardOutComeName === "podium_finish_weighting_system" ? "Podium Finish Weighting System" : e.leaderBoardOutComeName === "winnings_collected" ? "Winning Collected" : e.leaderBoardOutComeName === "win_draw_loss_weighting_system" ? "Win/Loss/Draw" : ""}</option>
                                        )
                                    })}
                            </select>
                            {leaderboardOutcomeErr == true ? <span className='text-danger'>Leaderboard Outcome Type is required</span> : ""}
                        </Col>
                    </Row>

                    {leaderboardOutcomeType === 3 ?
                        <Row>
                            <Col>
                                <label>Win Points</label>
                                <input type="number" min = "0" className="form-control input_bg" onChange={(e)=>{WinPoints(e)}}></input>
                            </Col>
                            <Col>
                                <label>Draw Pointts</label>
                                <input type="number" min = "0" className="form-control input_bg" onChange={(e)=>{DrawPoints(e)}}></input>
                            </Col>
                            <Col>
                                <label>Loss Points</label>
                                <input type="number" min = "0" className="form-control input_bg" onChange={(e)=>{LossPoints(e)}}></input>
                            </Col>
                        </Row>
                    : ""}

                    {leaderboardOutcomeType == 4 ?
                        <Row>
                            <Col lg={6} md={6} sm={6}>
                                <label>No of Podium Ranks</label>
                                <input type="number" min="1" className="form-control input_bg" onChange={(e)=>{onChangePPSALL(e)}}/>
                            </Col>
                        </Row>                                                    
                    : "" }

                    {leaderboardOutcomeType == 4 && results && results !="" && results != null ? 
                        <Row className="mt-3">
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Row>
                                    <Col lg={4} md={6} sm={6}><h6>Starting Rank</h6></Col>
                                    <Col lg={8} md={6} sm={6}><h6>Amount</h6></Col>
                                </Row>
                            </Col>
                        </Row> 
                    : ""}
                                                                
                    {leaderboardOutcomeType == 4 && results ? results.map((e, i)=>{
                        return (
                            <Row key={i} className="mt-3">
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <Row>
                                        <Col lg={4} md={6} sm={6}>{e.rank}</Col>
                                        <Col lg={8} md={6} sm={6}><input type="number" className="form-control input_bg" min="0" onChange={(evt)=>{handleChange(i,evt.target.value)}}/></Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    }) 
                    : ""}

                    <Row className='my-3'>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <button className='btn btn-success' onClick={saveCustomLeaderboard}>Save</button>
                            <button className='btn btn-danger mx-2' onClick={cancel}>Cancel</button>
                        </Col>
                    </Row>

                </div>
            </div>
        </>
    )
}


export default CustomLeaderboard