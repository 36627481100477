import CompetitionServices from "../../services/CompetitionServices";
import {CLEAR_COMPETITION, CLEAR_TEMPLATES, CREATE_COMPETITION, SEARCH_TEMPLATE, STOP_COMPETITION, GET_COMPETITION, EDIT_COMPETITION, SEARCH_COMPETITION, GET_COMPETITION_DETAIL, GET_COMPETITION_BY_ID, GET_TEMPLATES, CREATE_TEMPLATES, EDIT_TEMPLATES, GET_TEMPLATES_BY_ID, GENRATE_PD, GET_ORGANIZED_COMPETITION, CREATE_ORGANIZED_COMPETITION, EDIT_ORGANIZED_COMPETITION } from "../actionType/actionTypes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const getallCompetitionAction = (body) => {
    return async (dispatch) => {
        await CompetitionServices.getallCompetition(body)
        .then(res=>{
            dispatch(getallCompetition(res.data.competitionData))
        })
    }
}

export const getCompetitionByIdAction = (body) => {
    return async (dispatch) => {
        await CompetitionServices.getallCompetitionById(body)
        .then(res=>{
            dispatch(getallCompetitionById(res.data.competitionData))
        })
    }
}

export const getCompetitionDetailAction = (body) => {
    return async (dispatch) => {
        await CompetitionServices.getallCompetitionDetail(body)
        .then(res=>{
            dispatch(getallCompetitionDetail(res.data.competitionLeaderboardData))
        })
    }
}

export const createCompetitionAction = (body, history) => {
    return async (dispatch) => {
        await CompetitionServices.createCompetition(body)
        .then(res=>{
            toast.success(res.data.message);
            localStorage.removeItem('books')
            setTimeout(()=>{
                history.push('/competition');
            }, 2000)
        }).catch((err)=>{ 
            if(err.response.data.statusCode === 400){
                err.response.data.message && err.response.data.message.forEach(element => {
                    toast.error(element)
                });
            }else{
                toast.error(err.response.data.message)
            }
        })
    }
}

export const editCompetitionAction = (body, history) => {
    return async(dispatch) => {
        await CompetitionServices.editCompetition(body)
        .then(res=>{
            toast.success(res.data.message)
            localStorage.removeItem('books')
            if(history.location.pathname == '/competition'){    
                setTimeout(()=>{
                    window.location.reload()
                }, 1000)
            }else{
                setTimeout(()=>{
                    history.push('/competition')
                }, 1000)
            }
        }).catch(err=>{    
            toast.error(err.response.data.message)
        })
    }
}

export const stopCompetitionAction = (body) => {
    return async(dispatch) => {
        await CompetitionServices.stopCompetition(body)
        .then(res=>{
            // toast.success("Competition Stopped Successfully.")
        })
    }
}

export const searchCompetitionAction = (body) => {
    return async(dispatch) => {
        await CompetitionServices.searchCompetition(body)
        .then(res=>{
            dispatch(searchCompetition(res.data.competitionData))
        })
    }
}

export const getAllTemplatesAction = (body) => {
    return async(dispatch) => {
        await CompetitionServices.getAllTemplates(body)
        .then(res=>{
            dispatch(getAllTemplates(res.data.templateData))
        })
    }
}

export const createTemplateAction = (body, history) => {
    return async(dispatch) => {
        await CompetitionServices.createTemplates(body)
        .then(res=>{
            toast.success(res.data.message)
            setTimeout(()=>{
                history.push('/competition')
                window.location.reload()
            }, 1500)
        }).catch(err=>{
            if(err.response.data.statusCode == 400){
                err.response.data.message && err.response.data.message.forEach((e)=>{
                    toast.error(e)
                })
            }else if(err.response.data.statusCode == 409){
                toast.error(err.response.data.message)
            }
        })
    }
}

export const editTemplatesAction = (body, history) => {
    return async(dispatch) => {
        await CompetitionServices.editTemplates(body)
        .then(res=>{
            toast.success(res.data.message)
            setTimeout(()=>{
                history.push('/competition')
            }, 1500)
        })
    }
}

export const getTemplateByIdAction = (body) => {
    return async(dispatch) => {
        await CompetitionServices.getTemplateById(body)
        .then(res=>{
            dispatch(getTemplateById(res.data.templateData))
        })
    }
}

export const searchTemplateAction = (body) => {
    return async(dispatch) => {
        await CompetitionServices.searchTemplate(body)
        .then(res=>{
            dispatch(searchTemplate(res.data.templatesData))
        })
    }
}

export const getPDAction = (body) => {
    return async(dispatch) => {
        await CompetitionServices.getPD(body)
        .then(res=>{
            dispatch(getPD(res.data.prizeDistributionData))
        })
    }
}

export const clearCompetitionAction = (body) => {
    return async(dispatch) => {
        dispatch(clearCompetition())
    }
}

export const clearTemplateAction = (body) => {
    return async(dispatch) => {
        dispatch(clearTemplate())
    }
}

export const getOrganizedCompetitionAction = (body) => {
    return async(dispatch) => {
        await CompetitionServices.getOrganizedCompetition(body)
        .then(res=>{
            dispatch(getOrganizedCompetition(res.data))
        })
    }
}

export const createOrganizedCompetitionAction = (body) => {
    return async(dispatch) => {
        await CompetitionServices.createOrganizedCompetition(body)
        .then(res=>{
            dispatch(createOrganizedCompetition(res.data))
        })
    }
}

export const editOrganizedCompetitionAction = (body, history) => {
    return async(dispatch) => {
        await CompetitionServices.editOrganizedCompetition(body)
        .then(res=>{
            toast.success(res.data.message)
            setTimeout(()=>{
                history.push('/competition-games')
            }, 1500)
        })
    }
}

export const clearCompetition = (data) => {
    return{
        type : CLEAR_COMPETITION,
        payload : data
    }
}
export const clearTemplate = (data) => {
    return{
        type : CLEAR_TEMPLATES,
        payload : data
    }
}

export const getallCompetition = (data) => {
    return {
        type: GET_COMPETITION,
        payload: data
    }
}

export const createTemplates = (data) => {
    return {
        type : CREATE_TEMPLATES,
        payload : data
    }
}

export const editTemplates = (data) => {
    return {
        type : EDIT_TEMPLATES,
        payload : data
    }
}

export const getAllTemplates = (data) => {
    return {
        type : GET_TEMPLATES,
        payload : data
    }
}

export const getTemplateById = (data) => {
    return {
        type : GET_TEMPLATES_BY_ID,
        payload : data
    }
}

export const getallCompetitionDetail = (data) => {
    return {
        type : GET_COMPETITION_DETAIL,
        payload : data
    }
}

export const createCompetition = (data) => {
    return {
        type: CREATE_COMPETITION,
        payload: data
    }
}

export const editCompetition = (data) => {
    return {
        type : EDIT_COMPETITION,
        payload : data
    }
}

export const searchCompetition = (data) => {
    return {
        type : SEARCH_COMPETITION,
        payload : data
    }
}

export const getallCompetitionById = (data) => {
    return {
        type : GET_COMPETITION_BY_ID,
        payload : data
    }
}

export const stopCompetition = (data) => {
    return {
        type : STOP_COMPETITION,
        payload : data
    }
}

export const searchTemplate = (data) => {
    return {
        type : SEARCH_TEMPLATE,
        payload : data
    }
}

export const getPD = (data) => {
    return {
        type : GENRATE_PD,
        payload : data
    }
} 

export const getOrganizedCompetition = (data) => {
    return {
        type : GET_ORGANIZED_COMPETITION,
        payload : data
    }
} 

export const createOrganizedCompetition = (data) => {
    return {
        type : CREATE_ORGANIZED_COMPETITION,
        payload : data
    }
} 

export const editOrganizedCompetition = (data) => {
    return {
        type : EDIT_ORGANIZED_COMPETITION,
        payload : data
    }
} 