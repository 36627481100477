import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {cleargameAction, createGameAction} from '../redux/action/gameAction'
import { Col, Row, Button, Dropdown, ButtonGroup, Card , Form, Image} from '@themesberg/react-bootstrap';
import placeholder from './Images/attach_img.png'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Checkbox, Select, TextField } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAd, faEdit, faImage, faPlus, faTrash, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import './css/AddGame.css'
import CountryModal from './modals/CountryModal';
import {Game_Id,Game_Name, Game_Logo, Game_Description, Game_How_to, Preview_Video, Game_Screenshots, Game_Platform, Asset_Bundle_Version, Minimum_App_Version, Game_Genre, Screen_Orientation, Game_World, Matches_Created, Match_Name, Match_Format, Match_How_To, Match_Outcome_Type, Leaderboard_Outcome_Type, Included_Countries, Phase, Save, Save_Match, Save_As_Draft, Cancel, Min_Players, Max_Players, Podium_Rank_Match_Outcome, Podium_Rank_Leaderboard_Outcome, Rank, Points, Win_Points, Draw_Points, Loss_Points} from './../constants/LabelConstants'
import { DropzoneArea } from 'material-ui-dropzone';
import axios from "axios";
import axiosUpload from "../utlis/interceptor";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import { BASE_URL } from "../resources/APIEndpoints";
import { Breadcrumb } from "react-bootstrap";

// import {useDropzone} from 'react-dropzone'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { parse } from "@fortawesome/fontawesome-svg-core";

// getting the values of local storage
const getDatafromLS=()=>{
    const data = localStorage.getItem('books');
    if(data){
      return JSON.parse(data);
    }
    else{
      return []
    }
  }

function AddGame(props) {

    const history = useHistory();
    const dispatch = useDispatch();

    const icon = <CheckBoxOutlineBlankIcon fontSize = "small" />
    const checkedIcon = <CheckBoxIcon fontSize = "small" />
    
    const[Gamename, setGamename] = useState("")
    const[GamenameError, setGamenameError] = useState(false)
    const[gameLogo, setGameLogo] = useState("")
    const[GameLogoError, setGameLogoError] = useState(false)
    const[previewVideo, setPreviewvideo] = useState([])
    const[videoError, setVideoError] = useState(false)
    const[screenshot, setScreenshot] = useState();
    const[screenshotError, setScrrenshotError] = useState(false)
    const[androidbundle, setAndroidBundle] = useState("")
    const[androidError, setAndroidError] = useState(false)
    const[iOSbundle, setIosBundle] = useState("")
    const[iOSError, setiOSError] = useState(false)
    const[Description, setDescription] = useState("")
    const[DescriptionError, setDescriptionError] = useState(false)
    const[detail, setDetail] = useState("")
    const[detailError, setdetailError] = useState(false)
    const[bundlevesion, setBundleversion] = useState("")
    const[bundleError, setBundleError] = useState(false)
    const[version, setVersion] = useState("")
    const[versionError, setversionError] = useState(false)
    const[Orientation, setOrientation] = useState()
    const[OrientationError, setOrientationError] = useState(false)
    const[type, setType] = useState("")
    const[typeError, settypeError] = useState(false)
    const[World, setWorld] = useState("")
    const[WorldError, setWorldError] = useState(false)
    const[Genre, setGenre] = useState("")
    const[GenreError, setGenreError] = useState(false)
    const[minplayers, setMinplayers] = useState()
    const[minplayersError, setminplayersError] = useState(false)
    const[maxplayers, setMaxplayers] = useState()
    const[maxplayersError, setmaxplayersError] = useState(false)
    const[states, setStates] = useState("")
    // const[phase, setPhase] = useState("")
    const[draft, setDraft] = useState("")
    const[drafterror, setdrafterror] = useState(false)
    const[{alt, src}, setLogo] = useState({ src: placeholder, alt: 'Upload an Image'}); 
    const platformdata = [{PlatformName: 'Android'},{PlatformName: 'iOS'}]
    const[platform] = useState(platformdata)
    const[platformnew, setPlatformNew] = useState([])
    const[platformError, setPlatformError] = useState(false)
    const Countrydata = [{CountryName: 'India', id:1}]
    const[country, setCountry] = useState(Countrydata)
    const[countrynew, setCountryNew] = useState([])
    // Match Data
    const[showAccordian, setShowAccordian] = useState(false)
    const [matchName, setMatchName] = useState("")
    const [matchNameErr, setMatchNameErr] = useState(false)
    const [matchFormat, setMatchFormat] = useState()
    const [matchFormatErr, setMatchFormatErr] = useState(false)
    const [matchHowTo, setMatchHowTo] = useState("")
    const [matchHowToErr, setMatchHowToErr] = useState(false)   
    const [matchOutcome, setMachOutcome] = useState()
    const [matchOutcomeErr, setmatchOutcomeErr] = useState(false)
    const [LeaderboardOutcome, setLeaderBoardOutcome] = useState()
    const [LeaderboardOutcomeErr, setLeaderboardOutcomeErr] = useState(false)
    const [PodiumWeigh, setPodiumWeigh] = useState("")
    const [result, setresult] = useState("")
    const [countryError, setCountryError] = useState(false)

    // const[phaseError, setPhaseError] = useState(false)

    const [GameworldData, setGameWorldData] = useState("")
    const [leaderboardOutcomData, setLeaderboardOutcomData] = useState("")
    const [matchOutcomeData, setMatchOutcomeData] = useState("")
    const [matchFormatData, setMatchFormatData] = useState("")

    const [statesList, setStateList] = useState("")

    const getStates = async() => {
        
        let res = await axios.get('https://dev.gamestarz.xyz/v1/static-values')
        
        setLeaderboardOutcomData(res.data.leaderboardOutcome);
        setMatchOutcomeData(res.data.matchOutcome)
        setGameWorldData(res.data.gameWorld)
        setMatchFormatData(res.data.matchFormat)
        setStateList(res.data.states)
    }

    useEffect(() => {
        getStates();
        onPageLoadOrganization();
    }, [onPageLoadOrganization])

    const validationDraft = () => {
        if(Gamename === "" || Gamename === null){
            setGamenameError(true);
        }
        if(World === "" || World === null){
            setWorldError(true);
        }
    }
    // Input Validation 

    const onChangeGamename = (e) =>{
        setGamenameError(false);
        setGamename(e.target.value)
    }

    const onchangeDescription = (e) =>{
        setDescriptionError(false);
        setDescription(e.target.value)
    }

    const onchangeDetail = (e) =>{
        setdetailError(false);
        setDetail(e.target.value)
    }

    const onchangeBundleversion = (e) =>{
        setBundleError(false);
        setBundleversion(e.target.value)
    }

    const onchangeVersion = (e) =>{
        setversionError(false);
        setVersion(e.target.value)
    }

    const onchangeOrientation = (e) =>{
        setOrientationError(false);
        setOrientation(e.target.value)
    }

    const onchangeType = (e) =>{
        settypeError(false);
        setType(e.target.value)
    }
    
    const onchangeGenre = (e) =>{
        setGenreError(false);
        setGenre(e.target.value)
    }

    const onchangeWorld = (e) =>{
        setWorldError(false);
        setWorld(parseInt(e.target.value))
    }

    const [gameObject, setGameObject] = useState(null)
    const onGameObject = (e) => {
        setGameObject(parseInt(e.target.value))
    }

    const onchangeMinplayers = (e) =>{
        setminplayersError(false);
        setMinplayers(parseInt(e.target.value))
    }

    const onchangeMaxplayers = (e) =>{
        setmaxplayersError(false);
        setMaxplayers(parseInt(e.target.value))
    }
    
    const onchangeDraft = (e) => {
        setdrafterror(false);
        setDraft(e.target.value)
    }

    const onchangeMatchName = (e) => {
        setMatchNameErr(false)
        setMatchName(e.target.value);
    }

    const onchangeMatchFormat = (e) => {
        setMatchFormatErr(false)
        setMatchFormat(parseInt(e.target.value));
    }

    const onchangeMatchHowTo = (e) => {
        setMatchHowToErr(false)
        setMatchHowTo(e.target.value);
    }

    const onchnageMatchOutcomeType = (e) => {
        setmatchOutcomeErr(false)
        setMachOutcome(parseInt(e.target.value))
    }

    const onchangeLeaderBoardOutcomeType = (e) => {
        setLeaderBoardOutcome(parseInt(e.target.value))
        setLeaderboardOutcomeErr(false)
    }

    const [loadingLogo, setLoadingLogo] = useState(false)
    const uploadLogo = async(e) =>{
        setLoadingLogo(true)
        // For Logo Preview
        if(e.target.files[0]) {
            setLogo({
                src: URL.createObjectURL(e.target.files[0]),
                alt: e.target.files[0].name
            });    
        } 
    
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/upload-url?contentType=image/jpg`)
        setGameLogo(res.data.getUrl);
        setGameLogoError(false)
        var config = {
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
          }
          axios(config)
          .then(function (response) {
            setLoadingLogo(false)
            toast.success("Logo Uploaded Successfully.")
          })
          .catch(function (error) {
              toast.error("Logo not uploaded. Please try again!")
          });
    }
    const [videoErrorsize, setVideoSizeError] = useState(false)

    const [loadingVideo, setLoadingVideo] = useState(false)
    const [{srcVideo}, setVideoSrc] = useState({ srcVideo: placeholder})
    const uploadVideo = async (e) =>{
        var fileSize = Math.round((e.target.files[0].size/1024));
        if(fileSize<11000){
            setLoadingVideo(true)
            // Converting Image to base64 and then converting to binary to upload
            var file = e.target.files[0];   
    
            const file_binary = await convertbase64(file)
            const res = await axios.get(`${BASE_URL}/upload-url?contentType=octet-stream/mp4`)
            setPreviewvideo(res.data.getUrl)
            
            setVideoError(false)
            var config = {
                method: 'put',
                url: res.data.postUrl,
                data :convertDataURIToBinary(file_binary),
            }
            axios(config)
            .then(function (response) {
                setLoadingVideo(false)
                toast.success("Video Uploaded Successfully.")
                if(file) {
                    setVideoSrc({srcVideo : URL.createObjectURL(file)})   
                } 
            })
            .catch(function (error) {
                toast.error("File Not Uploaded. Please try Again!")
            });
            setVideoSizeError(false)
        }else{
            toast.error("File size is too large")
            setVideoSizeError(true)
            document.getElementById("vidooUpload").value = ""; 
        }
    }

    const [loadingAndroid, setLoadingAndroid] = useState(false)
    const uploadAndroid = async (e) => {
        // Converting Image to base64 and then converting to binary to upload
        setLoadingAndroid(true)
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/upload-url?contentType=octet-stream`)
        setAndroidBundle(res.data.getUrl)
        var config = {
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
          }
          axios(config)
          .then(function (response) {
            setLoadingAndroid(false)
            toast.success("Files Uploaded Successfully.")
            setAndroidError(false)
          })
          .catch(function (error) {
            toast.error("File Not Uploaded. Please try Again!")
          });
    }

    const [loadingIOS, setLoadingIOS] = useState(false)
    const uploadiOS = async (e) => {
        setLoadingIOS(true)
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/upload-url?contentType=octet-stream`)
        setIosBundle(res.data.getUrl)
        var config = {
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
          }
          axios(config)
          .then(function (response) {
            toast.success("Files Uploaded Successfully.")
            setiOSError(false)
            setLoadingIOS(false)
          })
          .catch(function (error) {
            toast.error("File Not Uploaded. Please try Again!")
          });  
    }


        // To Convert Base64 to Binary
        function convertDataURIToBinary(dataURI) {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));
          
            for(var i = 0; i < rawLength; i++) {
              array[i] = raw.charCodeAt(i);
            }
            return array;
        }
    
        // To Convert file into base64 format
        const convertbase64 = (file) => {
            return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
            })
        }

    const toggleAccordian = () => {
       if(showAccordian == true){
            setShowAccordian(false)
       }else{
            setShowAccordian(true)
       }
       setToggleIcon(true)
    }

    const onChangePodiumRanks = (e) => {
        setPodiumWeigh(e.target.value); 
        const result = [];
        for(var i = 1; i<=e.target.value;i++){
            result.push(i)
        }
        setresult(result)
    }

    const [statemodel, setStateModel] = useState(false);

    const showModel = () => {
        setStateModel(!statemodel)
    }



    let result_ss = []
    const [loadingSS, setLoadingSS] = useState(false)
    const changeSS = async(e) => {
        e.forEach(async (element) => {
        setLoadingSS(true)
        const file_binary = await convertbase64(element)
        const res = await axios.get(`${BASE_URL}/upload-url?contentType=image/jpeg`)
        let k = res.data.getUrl
        result_ss.push(k)
        var config = {
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
          }
          axios(config)
          .then(function (response) {
            setLoadingSS(false)
          })
          .catch(function (error) {
            toast.error("Please upload the file again")
          });
        });
        setScreenshot(result_ss);
        if(screenshot){
            // toast.success("Files uploaded successfully")
        }
        setScrrenshotError(false)
  }


  const [deleteShow, setDeleteShow] = useState(false);

  const handleDeleteClose = () => {
    setDeleteShow(false)
  }

  const handleDeleteShow = () => {
    setDeleteShow(true)
  }
  
  /* Data to DB */
  const [stateData, setStateData] = useState([])
  const a = (data) =>{

    setStateData(data)
  }

  /* Data for Mapping */
  const [exclude, setExclude] = useState([])
  const statesShow = (data) => {
      setExclude(data)
  }

    const[win, setWin] = useState("")
    const WinPoints = (e) =>{
        setWin(parseInt(e.target.value))
    }
    const[draw, setDraw] = useState("")
    const DrawPoints = (e) =>{
        setDraw(parseInt(e.target.value))
    }
    const[loss, setLoss] = useState("")
    const LossPoints = (e) =>{
        setLoss(parseInt(e.target.value))
    }
    
    const [state, setState] = useState({rank: "", points: ""})
    const [results, setResult] = useState([])
    const [point, setPoint] = useState("")
    
    const onChangePPSALL = (e) => {
        const ranks = [];
        for(var i = 1; i<= e.target.value;i++){
            ranks.push({rank: i, points:null})
        }
        setResult(ranks)
    }
    const handleChange = (i, value) => {
        const points = parseInt(value)
        results[i].points = points
        setResult(results);
    }

    const [matchesDetail, setMatchesDetail] = useState(getDatafromLS())
    const [isEdit, setIsedit] = useState(null)
    const [matchesDetailErr, setMatchesDetailErr] = useState(false)
    
    useEffect(()=>{
        localStorage.setItem('books',JSON.stringify(matchesDetail));
        const Token = localStorage.getItem('books')
      },[matchesDetail])
      
    const [toggleIcon, setToggleIcon] = useState(true)

    const editMatch = (matchName) => {
        if(showAccordian){
            setShowAccordian(true)
        }else{
            setShowAccordian(!showAccordian)
        }
        const editMatches = matchesDetail.find((ele)=>{
            return ele.match_name === matchName
        });
        setMatchName(editMatches.match_name)
        setMatchFormat(editMatches.match_format_type_id)
        setMatchHowTo(editMatches.match_how_to)
        setLeaderBoardOutcome(editMatches.leader_board_outcome_type_id)
        setMachOutcome(editMatches.match_format_type_id)
        setToggleIcon(false)
        setIsedit(matchName)
    }

    const deleteMatch = (matchName) => {
        const filteredBooks= matchesDetail.filter((element,index)=>{
            return element.match_name !== matchName
          })
          setMatchesDetail(filteredBooks);
    }

    /*  Validation For Inputs */

    const validation = (e) => {

        if(Gamename === "" || Gamename === null){
            setGamenameError(true)
        }

        if(gameLogo === "" || gameLogo === null){
            setGameLogoError(true)
        }

        if(Description === "" || Description === null){
            setDescriptionError(true)
        }

        if(detail === "" || detail === null){
            setdetailError(true)
        }

        if(previewVideo === "" || previewVideo === null || previewVideo.length === 0){
            setVideoError(true)
        }

        if(screenshot === "" || screenshot === null || screenshot.length === 0){
            setScrrenshotError(true);
        }

        if(iOSbundle === "" || iOSbundle === null || iOSbundle.length === 0){
            setiOSError(true)
        }

        if(androidbundle === "" || androidbundle === null || androidbundle.length === 0){
            setAndroidError(true)
        }

        if(platformnew === "" || platformnew === null || platformnew.length === 0){
            setPlatformError(true)
        }

        if(bundlevesion === "" || bundlevesion === null){
            setBundleError(true)
        }

        if(version === "" || version === null){
            setversionError(true)
        }

        if(Orientation === "" || Orientation === null || Orientation === undefined){
            setOrientationError(true)
        }

        if(type === "" || type === null){
            settypeError(true)
        }

        if(World === "" || World === null){
            setWorldError(true)
        }

        if(Genre === "" || Genre === null){
            setGenreError(true)
        }

        if(minplayers === "" || minplayers === null){
            setminplayersError(true)
        }

        if(maxplayers === "" || maxplayers === null){
            setmaxplayersError(true)
        }

        if(matchName === "" || matchName === null){
            setMatchNameErr(true)
        }

        if(matchFormat === "" || matchFormat === null){
            setMatchFormatErr(true);
        }

        if(matchHowTo === "" || matchHowTo === null){
            setMatchHowToErr(true)
        }

        if(countrynew === "" || countrynew.length === 0){
            setCountryError(true)
        }

        if(matchesDetail.length === 0){
            setMatchesDetailErr(true)
        }

        if(androidbundle === "" || androidbundle === null){
            setAndroidError(true)
        }

        if(iOSbundle === "" || iOSbundle === null){
            setIosBundle(true)
        }

    }

    const onEditMatch = () => { 
        const obj = []
        results.forEach((e)=>{
            var em = ""
            var key_one = e.rank
            var key_two = e.points

            obj.push({[key_one] : key_two})
        })
        
        if(toggleIcon){
            if(matchFormat === 1){
            if(LeaderboardOutcome === 3){
                let match={
                    match_name : matchName,
                    match_format_type_id : parseInt(matchFormat),
                    match_outcome_type_id : parseInt(matchOutcome),
                    leader_board_outcome_type_id : parseInt(LeaderboardOutcome),
                    match_min_players : 1,
                    match_max_players : 1,
                    match_how_to : matchHowTo,
                    leader_board_weighting_system: [{win: win, loose:loss, draw:draw}],
                }
                setMatchesDetail([...matchesDetail, match]);
                setMatchName('')
                setMatchFormat('')
                setMatchHowTo('')
                setLeaderBoardOutcome('')
                setMachOutcome('')
            }else if(LeaderboardOutcome === 4){
                let match = {
                    match_name : matchName,
                    match_format_type_id : parseInt(matchFormat),
                    match_outcome_type_id : parseInt(matchOutcome),
                    leader_board_outcome_type_id : parseInt(LeaderboardOutcome),
                    match_min_players : 1,
                    match_max_players : 1,
                    match_how_to : matchHowTo,
                    leader_board_weighting_system: obj,
                }
                setMatchesDetail([...matchesDetail, match]);
                setMatchName('')
                setMatchFormat('')
                setMatchHowTo('')
                setLeaderBoardOutcome('')
                setMachOutcome('')
                setResult([])
            }else if(LeaderboardOutcome === 1 || LeaderboardOutcome === 2 || LeaderboardOutcome === 5){
                let match = {
                    match_name : matchName,
                    match_format_type_id : parseInt(matchFormat),
                    match_outcome_type_id : parseInt(matchOutcome),
                    leader_board_outcome_type_id : parseInt(LeaderboardOutcome),
                    match_min_players : 1,
                    match_max_players : 1,
                    match_how_to : matchHowTo,
                    leader_board_weighting_system: [],
                }
                setMatchesDetail([...matchesDetail, match]);
                setMatchName('')
                setMatchFormat('')
                setMatchHowTo('')
                setLeaderBoardOutcome('')
                setMachOutcome('')
            }
            
            }else if(matchFormat === 2){
                if(LeaderboardOutcome === 3){
                    let match={
                        match_name : matchName,
                        match_format_type_id : parseInt(matchFormat),
                        match_outcome_type_id : parseInt(matchOutcome),
                        leader_board_outcome_type_id : parseInt(LeaderboardOutcome),
                        match_min_players : minplayers,
                        match_max_players : maxplayers,
                        match_how_to : matchHowTo,
                        leader_board_weighting_system: [{win: win, loose:loss, draw:draw}],
                    }
                    setMatchesDetail([...matchesDetail, match]);
                    setMatchName('')
                    setMatchFormat('')
                    setMatchHowTo('')
                    setMinplayers('')
                    setMaxplayers('')
                    setLeaderBoardOutcome('')
                    setMachOutcome('')
                }else if(LeaderboardOutcome === 4){
                    let match = {
                        match_name : matchName,
                        match_format_type_id : parseInt(matchFormat),
                        match_outcome_type_id : parseInt(matchOutcome),
                        leader_board_outcome_type_id : parseInt(LeaderboardOutcome),
                        match_min_players : minplayers,
                        match_max_players : maxplayers,
                        match_how_to : matchHowTo,
                        leader_board_weighting_system: obj,
                    }
                    setMatchesDetail([...matchesDetail, match]);
                    setMatchName('')
                    setMatchFormat('')
                    setMatchHowTo('')
                    setMinplayers('')
                    setMaxplayers('')
                    setLeaderBoardOutcome('')
                    setMachOutcome('')
                    setResult([])
                }else if(LeaderboardOutcome === 1 || LeaderboardOutcome === 2 || LeaderboardOutcome === 5){
                    let match = {
                        match_name : matchName,
                        match_format_type_id : parseInt(matchFormat),
                        match_outcome_type_id : parseInt(matchOutcome),
                        leader_board_outcome_type_id : parseInt(LeaderboardOutcome),
                        match_min_players : minplayers,
                        match_max_players : maxplayers,
                        match_how_to : matchHowTo,
                        leader_board_weighting_system: [],
                    }
                    setMatchesDetail([...matchesDetail, match]);
                    setMatchName('')
                    setMatchFormat('')
                    setMatchHowTo('')
                    setMinplayers('')
                    setMaxplayers('')
                    setLeaderBoardOutcome('')
                    setMachOutcome('')
                }
            }
            else if(matchFormat === 3){
                if(LeaderboardOutcome === 3){
                    let match={
                        match_name : matchName,
                        match_format_type_id : parseInt(matchFormat),
                        match_outcome_type_id : parseInt(matchOutcome),
                        leader_board_outcome_type_id : parseInt(LeaderboardOutcome),
                        match_min_players : minplayers,
                        match_max_players : maxplayers,
                        match_how_to : matchHowTo,
                        leader_board_weighting_system: [{win: win, loose:loss, draw:draw}],
                    }
                    setMatchesDetail([...matchesDetail, match]);
                    setMatchName('')
                    setMatchFormat('')
                    setMatchHowTo('')
                    setMinplayers('')
                    setMaxplayers('')
                    setLeaderBoardOutcome('')
                    setMachOutcome('')
                }else if(LeaderboardOutcome === 4){
                    let match = {
                        match_name : matchName,
                        match_format_type_id : parseInt(matchFormat),
                        match_outcome_type_id : parseInt(matchOutcome),
                        leader_board_outcome_type_id : parseInt(LeaderboardOutcome),
                        match_min_players : minplayers,
                        match_max_players : maxplayers,
                        match_how_to : matchHowTo,
                        leader_board_weighting_system: obj,
                    }
                    setMatchesDetail([...matchesDetail, match]);
                    setMatchName('')
                    setMatchFormat('')
                    setMatchHowTo('')
                    setMinplayers('')
                    setMaxplayers('')
                    setLeaderBoardOutcome('')
                    setMachOutcome('')
                    setResult([])
                }else if(LeaderboardOutcome === 1 || LeaderboardOutcome === 2 || LeaderboardOutcome === 5){
                    let match = {
                        match_name : matchName,
                        match_format_type_id : parseInt(matchFormat),
                        match_outcome_type_id : parseInt(matchOutcome),
                        leader_board_outcome_type_id : parseInt(LeaderboardOutcome),
                        match_min_players : minplayers,
                        match_max_players : maxplayers,
                        match_how_to : matchHowTo,
                        leader_board_weighting_system: [],
                    }
                    setMatchesDetail([...matchesDetail, match]);
                    setMatchName('')
                    setMatchFormat('')
                    setMatchHowTo('')
                    setMinplayers('')
                    setMaxplayers('')
                    setLeaderBoardOutcome('')
                    setMachOutcome('')
                }
            }
            setMatchesDetailErr(false)
        }
        else if(!toggleIcon){
            setMatchesDetail(
                matchesDetail.map((ele)=>{
                    if(ele.match_name === isEdit){
                        return {...ele, match_name: matchName, match_format_type_id : matchFormat, match_how_to : matchHowTo, match_outcome_type_id : matchOutcome, leader_board_outcome_type_id : LeaderboardOutcome}
                    } 
                    return ele
                })
            )
            
        }

        setMatchName('')
        setMatchFormat('')
        setMatchHowTo('')
        setMinplayers('')
        setMaxplayers('')
        setLeaderBoardOutcome('')
        setMachOutcome('')
        setShowAccordian(false)
    }

    const Onsave = () => {

        if(platformnew){
        setPlatformError(false)
        let result = []
        platformnew.map((e)=>{  
            result.push(e.PlatformName)
        })

        var platforms = [];
        for(var i = 0; i< result.length; i++){
            if(result[i] === "Android"){
                var a = {platform_name: "android", game_asset_bundle_url: androidbundle, game_asset_bundle_version: bundlevesion}
                platforms.push(a)
            }else if(result[i] === "iOS"){
                var a = {platform_name: "ios", game_asset_bundle_url: iOSbundle, game_asset_bundle_version: bundlevesion}
                platforms.push(a)
            }
        }
        }
        
        const data ={
            game_name: Gamename,
            game_logo: gameLogo,
            game_screen_shot_url: screenshot, 
            organisation_id : organizationId,
            game_preview_video_url: [previewVideo],
            game_description: Description,
            game_how_to: detail,
            game_platforms: platforms,
            game_minimum_app_version: version,
            is_game_screen_orientation_landscape:  String(Orientation) == "true",
            game_world_id: parseInt(World),
            game_genre: Genre,
            match: matchesDetail,
            game_object_id: gameObject,
            game_geo: [
                {
                  country_id: 1,
                  state_id : stateData
                }
              ],
            is_draft: false,
        }

        if(data.game_name === "" || data.game_description === "" || data.game_how_to === "" || data.game_platform === "" || data.game_preview_video_url.length === 0 || data.game_asset_bundel_version === "" || data.game_minimum_app_version === "" || data.is_game_screen_orientation_landscape === "" || data.game_type_id === "" || data.game_world_id === "" || data.game_genre ==="" || data. min_players === "" || data.max_players === "" || data.match === "" || data.match.length === 0){
            validation();
        }else{    
            dispatch(createGameAction(data, props.history))
            dispatch(cleargameAction());
        }
    }

    const Ondraft = () => {
        setDescriptionError(false)
        setGameLogoError(false);
        setdetailError(false)
        setVideoError(false);
        setScrrenshotError(false);
        setPlatformError(false);
        setOrientationError(false);
        setBundleError(false);
        setversionError(false);
        setGenreError(false);
        // setWorldError(false);
        setMatchNameErr(false);
        setMatchHowToErr(false);
        setCountryError(false);
        // setPhaseError(false);
        if(platformnew){    
            let result = []
            platformnew.map((e)=>{  
                result.push(e.PlatformName)
            })

            var platforms = [];
            for(var i = 0; i< result.length; i++){
                if(result[i] === "Android"){
                    var a = {platform_name: "android", game_asset_bundle_url: androidbundle, game_asset_bundle_version: bundlevesion}
                    platforms.push(a)
                }else if(result[i] === "iOS"){
                    var a = {platform_name: "ios", game_asset_bundle_url: iOSbundle, game_asset_bundle_version: bundlevesion}
                    platforms.push(a)
                }
            }
        }
        
        const data = {
            game_name : Gamename,
            game_logo : gameLogo,
            organisation_id : organizationId,
            game_screen_shot_url : screenshot, 
            game_preview_video_url : (previewVideo.length === 0 ? "" : [previewVideo]),
            game_description : Description,
            game_how_to : detail,
            game_platforms : platforms,
            game_minimum_app_version: version,
            is_game_screen_orientation_landscape:  String(Orientation) == "true",
            game_world_id : World,
            game_genre : Genre,
            is_draft : true,
            match: matchesDetail,
            game_geo: [
                {
                  country_id: 1,
                  state_id: stateData
                }
              ]
        }

        if(data.game_name === "" || data.game_world_id === ""){
            validationDraft();
        }else{
            const filterData = Object.keys(data).filter(f => data[f] !== undefined && data[f].length > 0)
            .reduce((r, i) => { r[i] = data[i]; return r; }, {});
            filterData.is_draft = true;
            filterData.game_world_id = World;
            dispatch(createGameAction(filterData, props.history))
        }

    }

    const CancelPage = () => {
        localStorage.removeItem('books')
        history.push('/games')
    }

    const cancel = () => {
        setShowAccordian(false);
        setMatchName('')
        setMatchFormat('')
        setMatchHowTo('')
        setMinplayers('')
        setMaxplayers('')
        setLeaderBoardOutcome('')
        setMachOutcome('')
    }

    const [organization, setOrganization] = useState([])

    const defaultProps = {
        options: organization,
        getOptionLabel: (option) => option.organisationName,
    };

    const onPageLoadOrganization = async() => {
        
        let organization = {
            gameAdminSubmissionFilters :{
                pagination : {

                }
            }
            
        }

        let res = await axiosUpload.post(`${BASE_URL}/organization/getAllOrganization`, organization)
        let orgResult = []
        for(var i = 0 ; i < res.data.organizationData.length  ; i++){
            if(res.data.organizationData[i].organisationName == null){

            }else{
                orgResult.push(res.data.organizationData[i])
            }  
        }
        setOrganization(orgResult)
    }

    const oninput = async(e) => {
        if(e.target.value == ""){
            let organization = {
                gameAdminSubmissionFilters :{
                    pagination : {
    
                    }
                }
                
            }
            let res = await axiosUpload.post(`${BASE_URL}/organization/getAllOrganization`, organization)
            setOrganization(res.data.organizationData)
        }else{
            let organization = {
                organizationName : e.target.value
            }
        
            let res = await axiosUpload.post(`${BASE_URL}/organization/search`, organization)
            setOrganization(res.data.gameData)
        }
    }

    const [organizationId, setOrganizationId] = useState('')

    const onOrganization = (e,i) => {
        setOrganizationId(i.id)
    }

    return (
        <div>
            <ToastContainer />
            <div className="container-fluid mt-3">
                <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                    <Row>
                        <Col lg={12}>
                            <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                                <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                                <Breadcrumb.Item href='#games'>Games</Breadcrumb.Item>
                                <Breadcrumb.Item className="text-dark" active>Add Game</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </div>
                <Row className="add-game">
                    <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Body>
                            <Row className="p-3">
                                <Col>
                                    <Row>
                                        <Col lg={12} md={12} sm={12}>
                                            <Row className="mb-3">
                                                <Col>
                                                    <p className="game-heading">Add Game</p>
                                                </Col>
                                            </Row>
                                                <Row>
                                                    <Col className="mb-3" lg={6} md={6} sm={6}>
                                                        <label for="Name">{Game_Name}</label>
                                                        <input type="text" className="form-control input_bg" onChange={(e)=>{onChangeGamename(e)}}></input>
                                                        {GamenameError ? (
                                                            <span style={{color : "red"}}>*Game Name is Required</span>
                                                        ) : ("")}
                                                    </Col>
                                                    <Col className="mb-3" lg={6} md={6} sm={6}>
                                                        <label className="mb-2">Search Organization</label>
                                                        <Autocomplete disableClearable {...defaultProps} onChange={(e,i)=>{onOrganization(e,i)}} onInputChange={(e)=>{oninput(e)}} options={organization} id="auto-complete" autoComplete includeInputInList
                                                        renderInput={(params) => (<TextField {...params} variant="standard" />)} />  
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-3" lg={12} md={12} sm={12}>
                                                        <label for="Description">{Game_Description}</label>
                                                        <textarea className="form-control input_bg" onChange={(e)=>{onchangeDescription(e)}} rows="4"/>
                                                        {DescriptionError ? (
                                                            <span style={{color : "red"}}>*Game Description is Required</span>
                                                        ) : ("")}
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-3" lg={12} md={12} sm={12}>
                                                        <label for="How_to">{Game_How_to}</label>
                                                        <textarea type="text" className="form-control input_bg" onChange={(e)=>{onchangeDetail(e)}} rows="4"/>
                                                        {detailError ? (
                                                            <span style={{color : "red"}}>*Game Detail is Required</span>
                                                        ) : ("")}
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-center">
                                                    <Col className="mb-3" lg={6} md={6} sm={12}>
                                                        <label for="platform" className="mb-2">{Game_Platform}</label>
                                                        {/* <input type="text" className="form-control" onChange={(e)=>{onchangePlatform(e)}}/> */}
                                                        <Autocomplete multiple disableCloseOnSelect 
                                                        onChange={(e,newvalue)=>{setPlatformNew(newvalue); setPlatformError(false)}} 
                                                        getOptionSelected={(option, value) => option.PlatformName === value.PlatformName}
                                                        filterSelectedOptions
                                                        options={platform} getOptionLabel={(option)=> option.PlatformName}
                                                        renderOption={(option, {selected})=>(
                                                        <React.Fragment>
                                                            <Checkbox icon={icon}
                                                                checkedIcon={checkedIcon} style={{marginLeft: "10px"}}
                                                                checked={selected}
                                                            />
                                                            {option.PlatformName}
                                                        </React.Fragment>  
                                                        )}
                                                        renderInput={(params)=>(<TextField {...params} variant="outlined" />)}
                                                        />
                                                        {platformError ? 
                                                            <span style={{color : "red"}}>*Platform is Required</span>
                                                        : ""}
                                                    </Col>
                                                    <Col className="pt-2" lg={6} md={6} sm={12}>
                                                        <label for="Orientation">{Screen_Orientation}</label>
                                                        <input className="form-check-input mx-2 input_bg" type="radio" name="orientation" value="true" onChange={(e)=>{onchangeOrientation(e)}}/>
                                                        <label>Landscape</label>
                                                        <input className="form-check-input mx-2 input_bg" type="radio" name="orientation" value="false" onChange={(e)=>{onchangeOrientation(e)}}/>
                                                        <label>Potrait</label>
                                                        {OrientationError ?
                                                            <div><span style={{color : "red"}}>*Orientation is Required</span></div>
                                                        : ""}
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                        {platformnew && platformnew.map((e)=>{
                                                            if(e.PlatformName === "Android"){
                                                                return(
                                                                    <Col className="mb-3" lg={6} md={6} sm={12}>
                                                                        <label for="Bundle">{e.PlatformName} Asset Bundle</label>
                                                                        <input type="file" className="form-control input_bg" onChange={(e)=>{uploadAndroid(e)}} />
                                                                        {androidError ? <span style={{color : "red"}}>Android Asset Bundle is Required</span> : ""}
                                                                        {loadingAndroid == true ? 
                                                                            <span class="flex_display">
                                                                                <span className="Loading_Heading">Uploading</span>
                                                                                <span className="Loading_One"></span>
                                                                                <span className="Loading_Two"></span>
                                                                                <span className="Loading_Three"></span>
                                                                            </span>
                                                                        : ""}
                                                                    </Col>
                                                                )
                                                            }else if(e.PlatformName === "iOS"){
                                                                return(
                                                                    <Col className="mb-3" lg={6} md={6} sm={12}>
                                                                        <label for="Bundle">{e.PlatformName} Asset Bundle</label>
                                                                        <input type="file" className="form-control input_bg" onChange={(e)=>{uploadiOS(e)}} />
                                                                        {iOSError ? <span style={{color : "red"}}>iOS Asset Bundle is Required</span> : ""}
                                                                        {loadingIOS == true ? 
                                                                            <span class="flex_display">
                                                                                <span className="Loading_Heading">Uploading</span>
                                                                                <span className="Loading_One"></span>
                                                                                <span className="Loading_Two"></span>
                                                                                <span className="Loading_Three"></span>
                                                                            </span>
                                                                        : ""}
                                                                    </Col>
                                                                )
                                                            }
                                                        })}
                                                </Row>

                                                <Row>
                                                    <Col className="mb-3" lg={6} md={6} sm={12}>
                                                        <label for="BundleVersion">{Asset_Bundle_Version}</label>
                                                        <input type="text" className="form-control input_bg" onChange={(e)=>{onchangeBundleversion(e)}}/>
                                                        {bundleError ? (
                                                            <span style={{color : "red"}}>*Bundle Version is Required</span>
                                                        ) : ("")}
                                                    </Col>
                                                    <Col className="mb-3" lg={6} md={6} sm={12}>
                                                        <label for="AppVersion">{Minimum_App_Version}</label>
                                                        <input type="text" className="form-control input_bg" onChange={(e)=>{onchangeVersion(e)}}/>
                                                        {versionError ? (
                                                            <span style={{color : "red"}}>*App Version is Required</span>
                                                        ) : ("")}
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-3" lg={6} md={6} sm={12}>
                                                        <label for="Genre">{Game_Genre}</label>
                                                        <input type="text" className="form-control input_bg" onChange={(e)=>{onchangeGenre(e)}}/>
                                                        {GenreError ? (
                                                            <span style={{color : "red"}}>*Genre is Required</span>
                                                        ) : ("")}
                                                    </Col>
                                                    <Col className="mb-3" lg={6} md={6} sm={12}>
                                                        <label for="World">{Game_World}</label>
                                                        <select className="form-control input_bg" onChange={(e)=>{onchangeWorld(e)}}>
                                                            <option value="">Select Game World</option>
                                                            {GameworldData && GameworldData.map((e)=>{
                                                                return(
                                                                    <option key={e.id} value={e.id}>{e.gameWorldName}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        {WorldError ? (
                                                            <span style={{color : "red"}}>*World is Required</span>
                                                        ) : ("")}
                                                    </Col>
                                                </Row>

                                                <Row className="mb-2">
                                                    <Col lg={6} md={6} sm={12}>
                                                        <label>Game Object Id</label>
                                                        <input type='number' className="form-control input_bg" onChange={(e)=>{onGameObject(e)}} />
                                                    </Col>
                                                </Row>

                                                {matchesDetail && matchesDetail.length !=0 ? 
                                                    <div>
                                                        <Row className="mt-3 mb-1 container">
                                                    <Col sm={12} md={12} lg={12}>
                                                        <h6>{Matches_Created}</h6>
                                                    </Col>
                                                </Row>
                                                            
                                                <Row className="mb-3">
                                                    <Col style={{overflowX : "scroll"}}>
                                                        <table class="table table-hover table-custom">
                                                            <thead className="table-head">
                                                                <tr>
                                                                    <th scope="col" className="head-border-left">Match Name</th>
                                                                    <th scope="col">Match Format</th>
                                                                    <th scope="col">Min Players</th>
                                                                    <th scope="col">Max Players</th>
                                                                    <th scope="col">Match Outcome</th>
                                                                    <th scope="col">Leaderboard Outcome</th>
                                                                    <th scope="col" className="head-border-right">Actions </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {matchesDetail && matchesDetail.map((e)=>{
                                                                    return(
                                                                        
                                                                        <tr>
                                                                            <th scope="row">{e.match_name}</th>
                                                                            <td>{e.match_format_type_id === 1 ? "Single Player" : e.match_format_type_id === 2 ? "Multiplayer" : e.match_format_type_id === 3 ? "Multiplayer Team" : ""}</td>
                                                                            <td>{e.match_min_players ? e.match_min_players: "-"}</td>
                                                                            <td>{e.match_max_players ? e.match_max_players: "-"}</td>
                                                                            <td>{e.match_outcome_type_id === 1 ? "High Score" : e.match_outcome_type_id === 2 ? "Time Trail" :  e.match_outcome_type_id === 3 ? "Win/Loss/Draw" :  e.match_outcome_type_id === 4 ? "Podium Finsish" :  e.match_outcome_type_id === 5 ? "Winnings Collected" : "" }</td>
                                                                            <td>{e.leader_board_outcome_type_id === 1 ? "High Score" : e.leader_board_outcome_type_id === 2 ? "Time Trail" :  e.leader_board_outcome_type_id === 3 ? "Win/Loss/Draw Weighting" :  e.leader_board_outcome_type_id === 4 ? "Podium Finsish" :  e.leader_board_outcome_type_id === 5 ? "Winnings Collected" : "" }</td>
                                                                            <td>
                                                                            <div class="form-check form-switch align-items-center">
                                                                                <input className="form-check-input mt-3" type="checkbox" id="flexSwitchCheckDefault" />
                                                                                <Button style={{backgroundColor: "white", border: 'none'}}>
                                                                                    <FontAwesomeIcon icon={faEdit} style={{color: "yellow"}} onClick={()=>{editMatch(e.match_name)}}/>
                                                                                </Button>
                                                                                <Button style={{backgroundColor: "white", border: 'none'}} onClick={()=>{deleteMatch(e.match_name)}}>
                                                                                    <FontAwesomeIcon icon={faTrash} style={{color: "grey"}}/>
                                                                                </Button>
                                                                            </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                </Row>
                                            </div>
                                            : ""}

                                            
                                            <Row className="mb-3 match">
                                                <Col lg={12} md={6} sm={6}>
                                                    <Row>
                                                        <Col lg={12} md={12} sm={12}>
                                                            <div className="me-lg-3">
                                                                <Button className="me-2 accordian-btn add-match" onClick={()=> toggleAccordian()}>
                                                                    <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                                                                    <span>Create Match</span>
                                                                </Button>
                                                                {matchesDetail.length === 0 && matchesDetailErr === true ? <span style={{color : "red"}}>*Match is Required</span> : ""}
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    { showAccordian ? 
                                                        <div>                  
                                                            <Row className="my-3">
                                                                <Col lg={6} md={12} sm={12}>
                                                                    <label>{Match_Name}</label>
                                                                    <input type = "text" className="form-control input_bg"onChange={(e)=>{onchangeMatchName(e)}} value={matchName}></input>
                                                                    {matchNameErr ? <span style={{color : "red"}}>Match Name is Require</span> : ""}
                                                                </Col>
                                                                <Col lg={6} md={12} sm={12}>
                                                                    <label>Select Match Format</label>
                                                                    <select className="form-control input_bg" onChange={(e)=>{onchangeMatchFormat(e)}} value={matchFormat}>
                                                                        <option value="" selected hidden>Select Match Format</option>
                                                                            {matchFormatData && matchFormatData.map((e)=>{
                                                                                return(
                                                                                    <option key={e.id} value={e.id}>{e.matchFormatName === "single_player" ? "Single Player" : e.matchFormatName === "multi_player" ? "Multiplayer" : e.matchFormatName === "multi_player_team" ? "Multiplayer Team" : "No Data"}</option>
                                                                                )
                                                                            })}
                                                                    </select>
                                                                    {matchFormatErr ? <span style={{color : "red"}}>Match Format is Require</span> : ""}
                                                                </Col>
                                                            </Row>

                                                            {matchFormat  && matchFormat === 2 || matchFormat === 3 ?
                                                                <div>
                                                                    <Row>
                                                                        <Col className="mb-3" lg={6} md={12} sm={12}>
                                                                            <label for="MinPlayers">{Min_Players}</label>
                                                                            <input type="number" min="1" className="form-control input_bg" onChange={(e)=>{onchangeMinplayers(e)}} value={minplayers}/>
                                                                            {minplayersError ? (
                                                                                <span style={{color : "red"}}>*Minmum Players is Required</span>
                                                                            ) : ("")}
                                                                        </Col>
                                                                        <Col className="mb-3" lg={6} md={12} sm={12}>
                                                                            <label for="MaxPlayers">{Max_Players}</label>
                                                                            <input type="number" min="1" className="form-control input_bg" onChange={(e)=>{onchangeMaxplayers(e)}} value={maxplayers}/>
                                                                            {maxplayersError ? (
                                                                                <span style={{color : "red"}}>*Maximum Players is Required</span>
                                                                            ) : ("")}
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            : ""}

                                                            <Row className="mb-3">
                                                                <Col lg={12} md={12} sm={12}>
                                                                    <label>{Match_How_To}</label>
                                                                    <textarea className="form-control input_bg" rows="5" onChange={(e)=>{onchangeMatchHowTo(e)}} value={matchHowTo}></textarea>
                                                                    {matchHowToErr ? <span style={{color : "red"}}>Match How To is Require</span> : ""}
                                                                </Col>
                                                            </Row>

                                                            <Row className="mb-3">
                                                                <Col lg={6} md={12} sm={12}>
                                                                    <label>{Match_Outcome_Type}</label>
                                                                    <select className="form-control input_bg" onChange={(e)=>{onchnageMatchOutcomeType(e)}} value={matchOutcome}>
                                                                        <option value="" selected hidden>Select Match Outcome Type</option>
                                                                        {matchOutcomeData && matchOutcomeData.map((e)=>{
                                                                            return(
                                                                                <option key={e.id} value={e.id}>{e.matchOutcomeName === "high_score" ? "High Score" : e.matchOutcomeName === "time_trail" ? "Time Trail" : e.matchOutcomeName === "podium_finish" ? "Podium Finish" : e.matchOutcomeName === "winnings_collected" ? "Winning Collected" : e.matchOutcomeName === "win_loss_draw" ? "Win/Loss/Draw" : ""}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                    {matchOutcomeErr ? <span style={{color : "red"}}>Match Outcome is Require</span> : ""}
                                                                </Col>
                                                                <Col lg={6} md={12} sm={12}>
                                                                    <label>{Leaderboard_Outcome_Type}</label>
                                                                    <select className="form-control input_bg" onChange={(e)=>{onchangeLeaderBoardOutcomeType(e)}} value={LeaderboardOutcome}>
                                                                        <option value="" selected hidden>Select Leaderboard Outcome Type</option>
                                                                        {leaderboardOutcomData && leaderboardOutcomData.map((e)=>{
                                                                            return(
                                                                                <option key={e.id} value={e.id}>{e.leaderBoardOutComeName === "high_score" ? "High Score" : e.leaderBoardOutComeName === "time_trail" ? "Time Trail" : e.leaderBoardOutComeName === "podium_finish_weighting_system" ? "Podium Finish Weighting System" : e.leaderBoardOutComeName === "winnings_collected" ? "Winning Collected" : e.leaderBoardOutComeName === "win_draw_loss_weighting_system" ? "Win/Loss/Draw" : ""}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                    {LeaderboardOutcomeErr ? <span style={{color : "red"}}>Leaderboard Outcome is Require</span> : ""}
                                                                </Col>
                                                            </Row>
                                                                
                                                        <Row>
                                                        {matchOutcome === 4 ?
                                                            <Col lg={6} md={6} sm={6}>
                                                                <label>{Podium_Rank_Match_Outcome}</label>
                                                                <input type="number" className="form-control input_bg" />
                                                            </Col>
                                                        : "" }
                                                        {LeaderboardOutcome === 4 ?
                                                                <Col lg={6} md={6} sm={6}>
                                                                    <label>{Podium_Rank_Leaderboard_Outcome}</label>
                                                                    <input type="number" min="1" className="form-control input_bg" onChange={(e)=>{onChangePPSALL(e)}}/>
                                                                </Col>
                                                        : "" }
                                                        {LeaderboardOutcome === 3 ?
                                                            <Row>
                                                                <Col>
                                                                    <label>{Win_Points}</label>
                                                                    <input type="number" min = "0" className="form-control input_bg" onChange={(e)=>{WinPoints(e)}}></input>
                                                                </Col>
                                                                <Col>
                                                                    <label>{Draw_Points}</label>
                                                                    <input type="number" min = "0" className="form-control input_bg" onChange={(e)=>{DrawPoints(e)}}></input>
                                                                </Col>
                                                                <Col>
                                                                    <label>{Loss_Points}</label>
                                                                    <input type="number" min = "0" className="form-control input_bg" onChange={(e)=>{LossPoints(e)}}></input>
                                                                </Col>
                                                            </Row>
                                                        : ""}
                                                        </Row>

                                                        {LeaderboardOutcome === 4 && results && results !="" && results != null ? 
                                                            <Row className="mt-3">
                                                                <Col lg={12} md={12} sm={12}>
                                                                    <Row>
                                                                        <Col lg={4} md={6} sm={6}><h6>Starting Rank</h6></Col>
                                                                        <Col lg={8} md={6} sm={6}><h6>Amount</h6></Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row> 
                                                        : ""}
                                                                
                                                        {LeaderboardOutcome === 4 && results ? results.map((e, i)=>{
                                                            return (
                                                                <Row key={i} className="mt-3">
                                                                    <Col lg={12} md={12} sm={12}>
                                                                        <Row>
                                                                            <Col lg={4} md={6} sm={6}>{e.rank}</Col>
                                                                            <Col lg={8} md={6} sm={6}><input type="number" className="form-control input_bg" min="0" onChange={(evt)=>{handleChange(i,evt.target.value)}}/></Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }) 
                                                        : ""}

                                                        {toggleIcon ?
                                                            <Row className ="my-3">
                                                                <Col sm={12} md={12} lg={12}>
                                                                    <button type="button" className="btn btn-outline-success" onClick={onEditMatch}>Save Match</button>
                                                                    <button type="button" className="btn btn-outline-danger mx-3" onClick={cancel}>Cancel</button>
                                                                </Col>
                                                            </Row>
                                                            :
                                                            <Row className ="my-3">
                                                                <Col sm={12} md={12} lg={12}>
                                                                    <button type="button" className="btn btn-success" onClick={onEditMatch}>Edit Match</button>
                                                                    <button type="button" className="btn btn-danger mx-3" onClick={cancel}>Cancel</button>
                                                                </Col>
                                                            </Row>
                                                            }

                                                        </div>
                                                    : ""}
                                                        
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={6}>
                                                <label className="mb-1" for="IncludeCountry">{Included_Countries}</label>
                                                    <Autocomplete className="input-no_bg" multiple disableCloseOnSelect filterSelectedOptions 
                                                        options={country} getOptionLabel={(option)=> option.CountryName}
                                                        onChange={(e,newvalue)=>{setCountryNew(newvalue); setCountryError(false)}}
                                                        renderOption={(option, {selected})=>(
                                                        <React.Fragment>
                                                            <Checkbox icon={icon}
                                                                checkedIcon={checkedIcon} style={{marginLeft: "10px"}}
                                                                checked={selected}  allowSelectAll={true} onClick={() => handleDeleteShow()}
                                                                />
                                                                {option.CountryName}
                                                        </React.Fragment>  
                                                            
                                                        )}
                                                        renderInput={(params)=>(<TextField {...params} variant="outlined" />)}
                                                    />
                                                    {countryError ? <div><span style={{color : "red"}}>Country is Required</span></div> : ""}
                                                </Col>
                                            </Row> 

                                            <Row className="my-3">
                                                <Col lg={6} md={6} sm={12}>
                                                    <Row>
                                                        {countrynew.length != 0 && exclude && exclude.length != 0 ? <span style={{fontSize : "12px"}}>Excluded States : </span> : ""}
                                                        {countrynew.length != 0 && statesList && statesList.map((e)=>{
                                                            return(
                                                                (exclude.map((i, x)=>{
                                                                    if(i === e.id){
                                                                        return(
                                                                            <Col lg={6} md={6} sm={6} style={{fontSize : "12px"}}>{x+1}&#x5D; {e.stateName} </Col>
                                                                        )
                                                                    }
                                                                }))
                                                            )
                                                        })}
                                                    </Row>
                                                </Col>
                                            </Row>  

                                            <Row>
                                                <Col className="mb-3">
                                                    <button type="button" className="btn btn-success" onClick={Onsave}>Save</button>
                                                    <button className="btn btn-danger mx-4" onClick={CancelPage}>Cancel</button>
                                                    <button type="button" className="btn btn-outline-warning" onClick={Ondraft}>Save As Draft</button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Body>
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Row className="my-2">
                                            <Col className="mb-3" xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Row className="align-items-center">
                                                    <Col xl={3} lg={3} md={12} sm={12} xs={12} className="mx-2">
                                                        <Row>
                                                            <Col><img className="img-fluid" src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img></Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xl={8} lg={8} md={12} sm={12} xs={12} className="mx-1">
                                                        <Row>
                                                            <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                                                                <label className="right-label-heading">{Game_Logo}</label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl={8} lg={8} md={8} sm={12} xs={12} className='input-file'>
                                                                <input type="file" id='logo' accept="image/jpeg, image/png, .jpeg, .jpg, .png" onChange={(e)=>{uploadLogo(e)}} className='file-input' />
                                                                <label for='logo' className="label-btn"><FontAwesomeIcon icon={faImage}/> Choose File</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {loadingLogo == true ? 
                                                        <span class="flex_display">
                                                            <span className="Loading_Heading">Uploading</span>
                                                            <span className="Loading_One"></span>
                                                            <span className="Loading_Two"></span>
                                                            <span className="Loading_Three"></span>
                                                        </span>
                                                    : ""}
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col>
                                                        {GameLogoError ? (
                                                            <div><span style={{color : "red"}}>*Upload Game Logo</span> <br /></div>
                                                        ) : ("")}
                                                        <span style={{fontSize: "11px"}}>Accepted file typs: JPEG and PNG Only</span> <span style={{fontSize: "11px"}}>&amp;</span> <span style={{fontSize: "11px"}}>Resolution: 512px*512px</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Body>
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className='input-file'>
                                        <Row>
                                            <Col className="mb-3" xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <label for="screenshot" className="right-label-heading">{Game_Screenshots}</label> <br />
                                                {/* <label for='ss' className="label-btn mb-2"><FontAwesomeIcon icon={faImage}/> Choose File</label> <br /> */}
                                                <DropzoneArea id='ss' canCancel={true} showPreviews={true} showPreviewsInDropzone={false} acceptedFiles={['image/*']} showAlerts={['error', 'success', 'info']}  alertSnackbarProps={{anchorOrigin:{ vertical: 'top', horizontal: 'right' }}} dropzoneText="Drop files to upload" filesLimit={50} onChange={(e)=>{changeSS(e)}}></DropzoneArea>
                                                {screenshotError ? (
                                                    <span style={{color : "red"}}>*Upload the Screenshots</span>
                                                ) : ("")}
                                                {loadingSS == true ?
                                                    <span class="flex_display">
                                                        <span className="Loading_Heading">Uploading</span>
                                                        <span className="Loading_One"></span>
                                                        <span className="Loading_Two"></span>
                                                        <span className="Loading_Three"></span>
                                                    </span>
                                                : ""}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Body>
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Row>
                                            <Col xl={12} lg={12} md={12} sm={12} xs={12} className='input-file mb-3'>
                                                <label for="video" className="right-label-heading">{Preview_Video}</label>
                                                <br />
                                                <span style={{fontSize: "11px", lineHeight: "10px"}}>Accepted file typs: MP4 Only</span> <br />
                                                <span style={{fontSize: "11px", lineHeight: "10px"}}>Resolution : Portrait: 1080x1920 &amp; Landscape: 1920x1080</span>
                                                <input type="file" id="vidooUpload" accept="video/mp4, video/avi, .mp4, .avi" className="file-input" onChange={(e)=>{uploadVideo(e)}}/>
                                                <label for='vidooUpload' className="label-btn"><FontAwesomeIcon icon={faImage}/> Choose File</label> <br /> <br />
                                                <video src={srcVideo} controls width={200}></video>
                                                {videoError ? (
                                                    <div> <span style={{color : "red"}}>*Upload the Video</span> </div>
                                                ) : ("")}
                                                {videoErrorsize === true ?  (<div> <span style={{color : "red"}}>*Upload a video less than 10 Mb</span> </div>) : ""}
                                                {loadingVideo == true ? 
                                                    <span class="flex_display">
                                                        <span className="Loading_Heading">Uploading</span>
                                                        <span className="Loading_One"></span>
                                                        <span className="Loading_Two"></span>
                                                        <span className="Loading_Three"></span>
                                                    </span>
                                                : ""}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            <CountryModal show={deleteShow} onHide={handleDeleteClose} filter={a} ExcludedState={statesShow}></CountryModal>
        </div>
    )
}

export default AddGame
