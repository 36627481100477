import { GET_VERSION, ADD_VERSION, EDIT_VERSION, GET_VERSION_BY_ID, CLEAR_VERSION } from "../actionType/actionTypes"

const initialState = {
    versions : [],
    versionsDetail : []
}
export const versionReducer = (state = initialState, action) => {
    switch(action.type){

        case GET_VERSION : {
            return {
                ...state,
                versions : action.payload
            }
        }

        case GET_VERSION_BY_ID : {
            return {
                ...state,
                versionsDetail : action.payload
            }
        }

        case ADD_VERSION : {
            return {
                ...state,
                versions : action.payload
            }
        }

        case EDIT_VERSION : {
            return {
                ...state,
                versions : action.payload
            }
        }

        case CLEAR_VERSION : {
            return {
                ...state,
                versionsDetail : []
            }
        }

        default : {
            return {
                ...state
            }
        }
    }
}
