import React, {useState, useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import propTypes from 'prop-types';
import {connect} from 'react-redux';

import Preloader from '../components/Preloader';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
    const [loaded, setLoaded] = useState(false);
  
    useEffect(() => {
      const timer = setTimeout(() => setLoaded(true), 1000);
      return () => clearTimeout(timer);
    }, []);
  
    const resize = () => {
      var resize = setInterval(() => {
        window.dispatchEvent(new Event('resize'));
      }, 10);
      setTimeout(function () {
        clearInterval(resize);
      }, 301);
    }
  
    const localStorageIsContracted = () => {
      return localStorage.getItem('sidebarContracted') === 'false' ? false : true
    }
  
    const localStorageIsSettingsVisible = () => {
      return localStorage.getItem('settingsVisible') === 'false' ? false : true
    }
  
    const [contracted, setContracted] = useState(localStorageIsContracted());
    const [contractSidebar, setContractSidebar] = useState(localStorageIsContracted());
    const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);
  
    // const toggleMouseOver = () => {
    //   if (contracted) {
    //     setContractSidebar(!contractSidebar);
    //   }
    //   resize();
    // };
  
    // const toggleContracted = () => {
    //   setContracted(!contracted);
    //   setContractSidebar(!contracted);
    //   localStorage.setItem('sidebarContracted', !contracted);
    //   resize();
    // };

    const toggleMouseOver = () => {
      if (contracted) {
        setContractSidebar(!contractSidebar);
        localStorage.setItem('sidebarContracted', !contractSidebar);
      }
      resize();
    };
  
    const toggleContracted = () => {
      setContracted(!contracted);
      setContractSidebar(!contractSidebar);
      localStorage.setItem('sidebarContracted', !contractSidebar);
      resize();
    };
  
    const toggleSettings = () => {
      setShowSettings(!showSettings);
      localStorage.setItem('settingsVisible', !showSettings);
    }
  
    return (
      <Route {...rest} render={props =>
        auth.isLoggedIn===true ? (
        <>
          <Preloader show={loaded ? false : true} />
          <Sidebar
            contracted={contractSidebar}
            onMouseEnter={toggleMouseOver}
            onMouseLeave={toggleMouseOver}
          />
  
          <main className="content">
            <Navbar toggleContracted={toggleContracted} />
            <Component {...props} />
            <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
          </main>
        </>
      ): (<Redirect to="/"/>)}
      />
    );
  }

PrivateRoute.propTypes={
    auth:propTypes.object.isRequired,
}

const mapStateToProps=state=>({
    auth:state.auth
})

export default connect(mapStateToProps)(PrivateRoute);