import React, {useState} from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import { useHistory } from "react-router-dom";
import {getallGamesAction} from '../../../redux/action/gameAction'
import {useDispatch, useSelector} from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function FilterModal({show, onHide, filterGames}) {

    const dispatch = useDispatch();
    let history = useHistory();

    const [showgameworld, setShowgameworld] = useState(false)

    const onchangeWorld = () => {
        setShowgameworld(!showgameworld)
    } 

    const [world, setWorld] = useState([])
    const [arcade, setArcade] = useState(false)
    const [board, setBoard] = useState(false)
    const [casino, setCasino] = useState(false)
    const [racing, setRacing] = useState(false)
    const [sport, setSport] = useState(false)

    const onWorld = (e) => {
        let worldList = [...world]
        if(e.target.checked){
            if(e.target.value == 1) {
                setWorld([...world, e.target.value])
                setSport(!sport)
            }else if(e.target.value == 2){
                setWorld([...world, e.target.value])
                setCasino(!casino)
            }else if(e.target.value == 3){
                setWorld([...world, e.target.value])
                setArcade(!arcade)
            }else if(e.target.value == 4){
                setWorld([...world, e.target.value])
                setRacing(!racing)
            }else if(e.target.value == 5){
                setWorld([...world, e.target.value])
                setBoard(!board)
            }
        }else{
            if(e.target.value == 1) {
                setSport(!sport)
            }else if(e.target.value == 2){
                setCasino(!casino)
            }else if(e.target.value == 3){
                setArcade(!arcade)
            }
            else if(e.target.value == 4){
                setRacing(!racing)
            }else if(e.target.value == 5){
                setBoard(!board)
            }
            worldList.splice(world.indexOf(e.target.value), 1)
            setWorld(worldList)
        }
    }

    const [Orientation, setOrientation] = useState([])
    const [showorientation, setShoworientation] = useState(false)
    const [portrait, setPortrait] = useState(false)
    const [landscape, setLandscape] = useState(false)
    
    const onchangeOrientation = () => {
        setShoworientation(!showorientation)
    }

    const onOrientation = (e) => {
        let orientationList = [...Orientation]
        if(e.target.checked){
            if(e.target.value == "true"){
                setOrientation([...Orientation, e.target.value])
                setLandscape(!landscape)
            }else if(e.target.value == "false"){
                setOrientation([...Orientation, e.target.value])
                setPortrait(!portrait)
            }
        }else{
            if(e.target.value == "true"){
                setLandscape(!landscape)
            }else if(e.target.value == "false"){
                setPortrait(!portrait)
            }
            orientationList.splice(Orientation.indexOf(e.target.value), 1)
            setOrientation(orientationList)
        }
    }

    // const [matchFormat, setMatchFormat] = useState([])
    // const [showmatchformat, setShowmatchformat] = useState(false)
    // const [singlePlayer, setSinglePlayer] = useState(false)
    // const [multiPlayer, setMultiPlayer] = useState(false)

    // const onchangeMatchformat = () => {
    //     setShowmatchformat(!showmatchformat)
    // }

    // const onMatchFormat = (e) => {
    //     let matchFormatList = [...matchFormat]
    //     if(e.target.checked){
    //         if(e.target.value == 1) {
    //             setMatchFormat([...matchFormat, e.target.value])
    //             setSinglePlayer(!singlePlayer)
    //         }else if(e.target.value == 2){
    //             setMatchFormat([...matchFormat, e.target.value])
    //             setMultiPlayer(!multiPlayer)
    //         }
    //     }else{
    //         if(e.target.value == 1) {
    //             setSinglePlayer(!singlePlayer)
    //         }else if(e.target.value == 2){
    //             setMultiPlayer(!multiPlayer)
    //         }
    //         matchFormatList.splice(matchFormat.indexOf(e.target.value), 1)
    //         setMatchFormat(matchFormatList)
    //     }
    // }

    const [platform, setPlatform] = useState([])
    const [showgameplatform, setShowgameplatform] = useState(false)
    const [ios, setIOS] = useState(false)
    const [android, setAndroid] = useState(false)

    const onchangeGameplatform = () => {
        setShowgameplatform(!showgameplatform)
    }

    const GamePlatform = (e) => {
        let platformList = [...platform]
        if(e.target.checked){
            if(e.target.value == 'android'){
                setPlatform([...platform, e.target.value])
                setAndroid(!android)
            }else if(e.target.value == 'ios'){
                setPlatform([...platform, e.target.value])
                setIOS(!ios)
            }
        }else{
            if(e.target.value == 'android'){
                setAndroid(!android)
            }else if(e.target.value == 'ios'){
                setIOS(!ios)
            }
            platformList.splice(platform.indexOf(e.target.value), 1)
            setPlatform(platformList)
        }
    }

    // const [matchOutcome, setMatchOutcome] = useState([])
    // const [showmatchoutcome, setShowmatchoutcome] = useState(false)
    // const [highScore, setHighScore] = useState(false)
    // const [timeTrail, setTimeTrail] = useState(false)
    // const [winLoss, setWinLoss] = useState(false)
    // const [podiumFinishMatch, setPodiumFinishMatch] = useState(false)
    // const [winningsMatch, setWinningMatch] = useState(false)

    // const onchangeMatchOutcome = () => {
    //     setShowmatchoutcome(!showmatchoutcome)
    // }

    // const onMatchOutcome = (e) => {
    //     let matchOutcomeList = [...matchOutcome]
    //     if(e.target.checked){
    //         if(e.target.value == 1) {
    //             setMatchOutcome([...matchOutcome, e.target.value])
    //             setHighScore(!highScore)
    //         }else if(e.target.value == 2){
    //             setMatchOutcome([...matchOutcome, e.target.value])
    //             setTimeTrail(!timeTrail)
    //         }else if(e.target.value == 3){
    //             setMatchOutcome([...matchOutcome, e.target.value])
    //             setWinLoss(!winLoss)
    //         }else if(e.target.value == 4){
    //             setMatchOutcome([...matchOutcome, e.target.value])
    //             setPodiumFinishMatch(!podiumFinishMatch)
    //         }else if(e.target.value == 5){
    //             setMatchOutcome([...matchOutcome, e.target.value])
    //             setWinningMatch(!winningsMatch)
    //         }
    //     }else{
    //         if(e.target.value == 1) {
    //             setHighScore(!highScore)
    //         }else if(e.target.value == 2){
    //             setTimeTrail(!timeTrail)
    //         }else if(e.target.value == 3){
    //             setWinLoss(!winLoss)
    //         }else if(e.target.value == 4){
    //             setPodiumFinishMatch(!podiumFinishMatch)
    //         }else if(e.target.value == 5){
    //             setWinningMatch(!winningsMatch)
    //         }
    //         matchOutcomeList.splice(matchOutcome.indexOf(e.target.value), 1)
    //         setMatchOutcome(matchOutcomeList)
    //     }
    // }

    // const [leaderboardOutcome, setLeaderboardOutcome] = useState([])
    // const [showleaderboard, setShowleaderboard] = useState(false)
    // const [highScoreLeaderboard, setHighScoreLeaderboard] = useState(false)
    // const [timeTrailLeaderboard, setTimeTrailLeaderboard] = useState(false)
    // const [winLossLeaderboard, setWinLossLeaderboard] = useState(false)
    // const [podiumFinishMatchLeaderboard, setPodiumFinishMatchLeaderboard] = useState(false)
    // const [winningsMatchLeaderboard, setWinningMatchLeaderboard] = useState(false)

    // const onchangeLeaderboard = () => {
    //     setShowleaderboard(!showleaderboard)
    // }

    // const onLeaderboardOutcome = (e) => {
    //     let leaderboardOutcomeList = [...leaderboardOutcome]
    //     if(e.target.checked){
    //         if(e.target.value == 1) {
    //             setLeaderboardOutcome([...leaderboardOutcome, e.target.value])
    //             setHighScoreLeaderboard(!highScoreLeaderboard)
    //         }else if(e.target.value == 2){
    //             setLeaderboardOutcome([...leaderboardOutcome, e.target.value])
    //             setTimeTrailLeaderboard(!timeTrailLeaderboard)
    //         }else if(e.target.value == 3){
    //             setLeaderboardOutcome([...leaderboardOutcome, e.target.value])
    //             setWinLossLeaderboard(!winLossLeaderboard)
    //         }else if(e.target.value == 4){
    //             setLeaderboardOutcome([...leaderboardOutcome, e.target.value])
    //             setPodiumFinishMatchLeaderboard(!podiumFinishMatchLeaderboard)
    //         }else if(e.target.value == 5){
    //             setLeaderboardOutcome([...leaderboardOutcome, e.target.value])
    //             setWinningMatchLeaderboard(!winningsMatchLeaderboard)
    //         }
    //     }else{
    //         if(e.target.value == 1) {
    //             setHighScoreLeaderboard(!highScoreLeaderboard)
    //         }else if(e.target.value == 2){
    //             setTimeTrailLeaderboard(!timeTrailLeaderboard)
    //         }else if(e.target.value == 3){
    //             setWinLossLeaderboard(!winLossLeaderboard)
    //         }else if(e.target.value == 4){
    //             setPodiumFinishMatchLeaderboard(!podiumFinishMatchLeaderboard)
    //         }else if(e.target.value == 5){
    //             setWinningMatchLeaderboard(!winningsMatchLeaderboard)
    //         }
    //         leaderboardOutcomeList.splice(leaderboardOutcome.indexOf(e.target.value), 1)
    //         setLeaderboardOutcome(leaderboardOutcomeList)
    //     }
    // }

    const [showgamegenre, setShowgamegenre] = useState(false)

    const onchangeGenre = () => {
        setShowgamegenre(!showgamegenre)
    }
    
    const AddFilter = () => {
        filterGames(Orientation, world, platform)
        //filterGames(Orientation, world, matchFormat, platform, matchOutcome, leaderboardOutcome)
        onHide();
    }

    const close = () => {
        filterGames([], [], [], [], [], [])


        setWorld([])
        setArcade(false)
        setBoard(false)
        setCasino(false)
        setRacing(false)
        setSport(false)


        setOrientation([])
        setPortrait(false)
        setLandscape(false)



        // setMatchFormat([])
        // setSinglePlayer(false)
        // setMultiPlayer(false)


        setPlatform([])
        setAndroid(false)
        setIOS(false)


        // setMatchOutcome([])
        // setHighScore(false)
        // setTimeTrail(false)
        // setWinLoss(false)
        // setPodiumFinishMatch(false)
        // setWinningMatch(false)


        // setLeaderboardOutcome([])
        // setHighScoreLeaderboard(false)
        // setTimeTrailLeaderboard(false)
        // setWinLossLeaderboard(false)
        // setPodiumFinishMatchLeaderboard(false)
        // setWinningMatchLeaderboard(false)


        onHide();

    }

    return (
        <React.Fragment>
            <Modal size="xl" id='filter_Modal' centered show={show} onHide={onHide} className='filter-main'>
                <Modal.Header><p className='heading-title'>Add Filter</p></Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                {/* <FormControlLabel control={<Checkbox checked={showorientation} onChange={onchangeOrientation} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} className='filter-title' label="Screen Orientation" /> */}
                                <label className='filter-title'>Screen Orientation</label>
                                <div className='my-2'>
                                    <Row>
                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel className='filter-label' control={<Checkbox checked={portrait} onChange={(e)=>{onOrientation(e)}} value="false"  sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}/>} label={<span style={{ fontSize: '14px' }}>Portrait</span>} />
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={landscape} onChange={(e)=>{onOrientation(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value= "true" />} label={<span style={{ fontSize: '14px' }}>Landscape</span>} />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                {/* <FormControlLabel className='filter-title' control={<Checkbox checked={showgameworld} onChange={onchangeWorld} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Game World" /> */}
                                <label className='filter-title'>Game World</label>
                                <div className='my-2'>
                                    <Row>
                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={arcade} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={3} />} label={<span style={{ fontSize: '14px' }}>Arcade</span>} />
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={board} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={5} />} label={<span style={{ fontSize: '14px' }}>Board</span>}/>
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={casino} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={2} />} label={<span style={{ fontSize: '14px' }}>Casino</span>}/>
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={racing} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={4} />}  label={<span style={{ fontSize: '14px' }}>Racing</span>}/>
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={sport} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={1} />} label={<span style={{ fontSize: '14px' }}>Sports</span>}/>
                                        </Col>
                                    </Row>

                                    {/* <Row>
                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={racing} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={4} />} label="Racing" />
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={sport} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={1} />} label="Sports" />
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                                
                                        </Col>
                                    </Row> */}
                                </div>
                            </Col>
                        </Row>

                        {/* <Row>
                            <Col lg={12} md={12} sm={12}>
                                <label className='filter-title'>Match Format</label>
                                <div className='my-2'>
                                    <Row>
                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={singlePlayer} onChange={(e)=>{onMatchFormat(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={1} />}  label={<span style={{ fontSize: '14px' }}>Single Player</span>}/>
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={multiPlayer} onChange={(e)=>{onMatchFormat(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={2} />}  label={<span style={{ fontSize: '14px' }}>MultiPlayer</span>}/>
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                                
                                        </Col>
                                    </Row>  
                                </div>
                            </Col>
                        </Row> */}

                        <Row  className='my-2'>
                            <Col lg={12} md={12} sm={12}>
                                <label className='filter-title'>Game Platform</label>
                                <div className='my-2'>
                                    <Row>
                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={android} onChange={(e)=>{GamePlatform(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value="android" />}  label={<span style={{ fontSize: '14px' }}>Android</span>}/>
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={ios} onChange={(e)=>{GamePlatform(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value="ios" />}  label={<span style={{ fontSize: '14px' }}>iOS</span>}/>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>

                        {/* <Row>
                            <Col lg={12} md={12} sm={12}>
                                <label className='filter-title'>Match OutcomeType</label>
                                <div className='my-2'>
                                    <Row>
                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={highScore} onChange={(e)=>{onMatchOutcome(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={1} />}  label={<span style={{ fontSize: '14px' }}>High Score</span>}/>
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={timeTrail} onChange={(e)=>{onMatchOutcome(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={2} />}  label={<span style={{ fontSize: '14px' }}>Time Trail</span>}/>
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={winLoss} onChange={(e)=>{onMatchOutcome(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={3} />}  label={<span style={{ fontSize: '14px' }}>Win/Loss/Draw</span>}/>
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={podiumFinishMatch} onChange={(e)=>{onMatchOutcome(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={4} />}  label={<span style={{ fontSize: '14px' }}>Podium Finish</span>}/>
                                        </Col>

                                        <Col lg={3} md={3} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={winningsMatch} onChange={(e)=>{onMatchOutcome(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={5} />}  label={<span style={{ fontSize: '14px' }}>Winnings Collected</span>}/>
                                        </Col>
                                    </Row>

                                </div>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <label className='filter-title'>Leaderboard OutcomeType</label>
                                <div className='my-2'>
                                    <Row>
                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={highScoreLeaderboard} onChange={(e)=>{onLeaderboardOutcome(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={1} />} label={<span style={{ fontSize: '14px' }}>High Score</span>}/>
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={timeTrailLeaderboard} onChange={(e)=>{onLeaderboardOutcome(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={2} />} label={<span style={{ fontSize: '14px' }}>Time Trail</span>}/>
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={winLossLeaderboard} onChange={(e)=>{onLeaderboardOutcome(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={3} />} label={<span style={{ fontSize: '14px' }}>Win/Loss/Draw</span>}/>
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={podiumFinishMatchLeaderboard} onChange={(e)=>{onLeaderboardOutcome(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={4} />} label={<span style={{ fontSize: '14px' }}>Podium Finish</span>}/>
                                        </Col>

                                        <Col lg={3} md={3} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={winningsMatchLeaderboard} onChange={(e)=>{onLeaderboardOutcome(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={5} />}  label={<span style={{ fontSize: '14px' }}>Winnings Collected</span>}/>
                                        </Col>
                                    </Row>

                                </div>
                            </Col>
                        </Row>   */}

                    </Form>
                </Modal.Body>
                <Modal.Footer className='filter-footer'>
                    <button className="btn btn-md btn-success" onClick={AddFilter}>Apply Filter</button>
                    <button className="btn btn-mc btn-danger" onClick={close}>Clear Filter</button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default FilterModal;
