import React, {useState, useEffect } from 'react'
import { Dropdown, Col,InputGroup, Row, Nav, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faTimesCircle, faEdit, faPlusCircle, faEllipsisH, faEye } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Breadcrumb } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAllPartnersAction, getPartnersByIdAction } from '../../redux/action/organisationAction';
import { Routes } from '../../routes';
import Pagination from '@mui/material/Pagination'
import { BASE_URL, GET_DOCUMENTS, GET_PARTNERS } from '../../resources/APIEndpoints';
import axios from '../../utlis/interceptor'
import DocumentModal from '../modals/Documents/DocumentModal';
import { getAllDocumentsAction, getDocumentDetailAction, clearDocDetailAction } from '../../redux/action/documentsAction';


function Document() {

    const dispatch = useDispatch();

    const [docDetail, setDocDetail] = useState(false)
    const [page, setPage] = useState(1)
    const [counts, setCounts] = useState()
    const [limit, setLimit] = useState(10)

    const onDocumentDetail = (id) => {
        dispatch(clearDocDetailAction())
        setDocDetail(!docDetail)
        const docId = id
        let documentDetail = {
            docFilters : {
                id : docId,
                pagination : {
                    
                }
            }
        }
        dispatch(getDocumentDetailAction(documentDetail))
    }


    const getAllDocuments = () => {
        let getDocuments = {
            docFilters : {
                pagination : {
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
        }
        dispatch(getAllDocumentsAction(getDocuments))
    }

    const getPaginate = async() => {
        let getPaginateDocs = {
            docFilters : {
                pagination : {
                    
                }
            }
        }

        let res = await axios.post(BASE_URL + GET_DOCUMENTS, getPaginateDocs)
        const pageNo = Math.ceil(res.data.docDetails.length/limit)
        setCounts(pageNo)

    }

    useEffect(()=>{
        getAllDocuments();
        getPaginate();
    }, [page])


    let allDocuments = useSelector((state) => {
        return state.documents.allDocuments
    })

    return (
        <>
        <ToastContainer />
        <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
            <Row>
                <Col lg={12}>
                    <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                        <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-dark" active>Documents</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            </div>

        <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
                <div className="me-lg-3">
                    <Button as={Link} to={Routes.createDocument.path} className="me-2 button-style">
                    <FontAwesomeIcon icon={faPlusCircle} className="me-2 icon" />
                        <span>Create Document</span>
                    </Button>
                </div>
            </Col>

            <Col xs={12} lg={6} className="px-0 mb-0 text-right">
                <ButtonGroup>
                    {/* <Button variant="outline-primary" className='export' size="sm">Export</Button> */}
                    {/* <Button className="exportBtn" variant="outline-primary" size="sm"><CSVLink {...csvReport}>Export</CSVLink></Button>     */}
                </ButtonGroup>
            </Col>
        </div>
        <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded version">
            <div className="row mb-4 px-4">
                    <div className="col col-lg-6 col-md-12 text-left">
                        <p className='page-heading'>Documents</p>
                    </div>
                    <div className="col col-lg-12 col-md-12 col-sm-12 text-right">

                    </div>
                </div>
            <div>
                <Row className="container-fluid table-responsive">
                    <Col lg={12} md={12} sm={12}>
                        <table className="table table-striped text-center table-custom">
                            <thead className='table-head'>
                            <tr>
                                <th scope='col' className='head-border-left'>Created At</th>   
                                <th scope='col'>Document Name</th>                
                                <th scope='col'>Last Updated</th>   
                                <th scope='col' className='head-border-right'>Actions</th>  
                            </tr>
                            </thead>
                            <tbody className='table-body'>
                                {allDocuments && allDocuments.map((e,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{e.createdAt.slice(0,10).split("-").reverse().join("-")}</td>
                                            <td>{e.documentName}</td>
                                            <td>{e.updatedAt.slice(0,10).split("-").reverse().join("-")}</td>
                                            <td>
                                                <Dropdown className="ms-3">
                                                    <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="icon icon-sm icon-dark">
                                                        <FontAwesomeIcon icon={faEllipsisH} />
                                                        </span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                        <Dropdown.Item className="ms-0"  onClick={()=>{onDocumentDetail(e.id)}}>
                                                            <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                                                        </Dropdown.Item>

                                                        <Dropdown.Item className="ms-0" as={Link} to={{pathname : Routes.editDocument.path, id : e.id}}>
                                                            <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Col>
                </Row> 

                <div className="d-flex justify-content-left m-4">
                    <Row>
                        <Col>
                            <Pagination className="paginate"
                            count={counts} 
                            defaultPage={page} 
                            color="info" 
                            variant="outlined" shape="rounded"
                            showFirstButton={false} 
                            showLastButton={false} 
                            onChange={(event, value)=> setPage(value)}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
            <DocumentModal show={docDetail} onHide={onDocumentDetail}/>
        </div>
        </>
  )
}

export default Document