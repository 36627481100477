import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux';
import { Dropdown, Col, InputGroup, Row, Nav, Form, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faSearch} from '@fortawesome/free-solid-svg-icons';
import TransactionFilter from '../../modals/Filters/TransactionFilter';
import TablePagination from '../../components/TablePagination'
import { getAllTransactionAction, searchTransactionAction } from '../../../redux/action/playstarzbankAction';
import Pagination from '@mui/material/Pagination'
import axios from '../../../utlis/interceptor';
import '../../css/games.css'
import { BASE_URL, GET_TRANSACTION } from '../../../resources/APIEndpoints';
import { CSVLink, CSVDownload } from "react-csv";
import { Breadcrumb } from "react-bootstrap";
import moment from 'moment';

function TransactionHistory() {
    const dispatch = useDispatch()
    const [showTransactionFilter, setTransactionFilter] = useState(false)
    
    
    const showModal = () => {
        setTransactionFilter(!showTransactionFilter)
    }

    const [sorted, setSorted] = useState({createdAt : "DESC"})
    const [page, setPage] = useState(1)
    const [counts , setCounts] = useState()
    const [limit, setLimit] = useState(20)

    useEffect(()=>{
        let data = {
            walletTransactionsFilters : {
                walletTransactionPurposeId :transPurpose,
                walletTransactionRecipientId : transRecipient,
                walletTransactionSourceId : transSource,
                walletTransactionTypeId : transType,
                transactionSort: sorted,
                pagination : {
                    offset : ((page-1)*limit),
                    limit :  limit
                }
            }
        }
        Object.keys(data.walletTransactionsFilters).forEach((key) => (data.walletTransactionsFilters[key].length === 0) && delete data.walletTransactionsFilters[key])
        dispatch(getAllTransactionAction(data))
    }, [page])

    useEffect(()=>{
        let data = data = {
            walletTransactionsFilters : {
                walletTransactionPurposeId :transPurpose,
                walletTransactionRecipientId : transRecipient,
                walletTransactionSourceId : transSource,
                walletTransactionTypeId : transType,
                transactionSort: sorted,
                pagination : {
                        offset : ((page-1)*limit),
                        limit :  limit
                }
            }
        }
        Object.keys(data.walletTransactionsFilters).forEach((key) => (data.walletTransactionsFilters[key].length === 0) && delete data.walletTransactionsFilters[key])
        dispatch(getAllTransactionAction(data))
        axios.post(`${BASE_URL}/wallet/getTransactions`, data)
        .then(res=>{
            // let pageNo = Math.ceil(res.data.transactionData.length/limit)
            if(res.data.transactionData.length > 0){
                setCounts(Math.ceil(res.data.transactionData[0].totalcount/limit))
            }
        })
    }, [])

    let transaction = useSelector((state)=>{
        return state.playstarzbank.transactions
    })

    const [allTransactions, setAllTransaction] = useState()

    useEffect(()=>{
       let trasactionAll = {
        walletTransactionsFilters : {
           pagination : {

            }
        }
    }
       axios.post(BASE_URL + GET_TRANSACTION , trasactionAll)
       .then(res=>{
        setAllTransaction(res.data.transactionData)
       })
    }, [])

    const headers = [
        { label : 'Transaction Id', key : 'id'},
        { label : 'Transaction Date', key : 'createdAt'},
        { label : 'Transaction Amount', key : 'amount'},
        { label : 'Transaction Contact', key : 'recipientTransactionContact'},
        { label : 'Transaction Type', key : 'walletTransactionType'},
        { label : 'Transaction Source', key : 'sourceWalletName'},
        { label : 'Transaction Recipient' , key : 'walletTransactionRecepientName'},
        { label : 'Transaction Purpose', key : 'walletTransactionPurposeName'},
        { label : 'Transaction Event', key : 'transactionEvent'},
        { label : 'Transaction Status', key : 'status'},
        { label : 'Transaction Mode For Debits', key : 'walletTransactionPurposeName'},
      ]
     
    const csvReport = {
        filename : `Transaction_Report_${new Date().toISOString().slice(0, 10)}.csv`,
        headers : headers,
        data : allTransactions
    }

    const sortTransactions = (e) => {
        setSorted({createdAt : e.target.value})
        let transactionSortData = {
            walletTransactionsFilters : {
                transactionSort: {createdAt: e.target.value},
                pagination : {
                    offset : ((page-1)*limit),
                    limit :  limit
                }
            }
        }
        
        dispatch(getAllTransactionAction(transactionSortData))
    }

    const[showPagination, setShowPagination] = useState(false)

    const searchTransaction = (e) => {
        if(e.target.value == ''){
            let data = data = {
                walletTransactionsFilters : {
                    walletTransactionPurposeId :transPurpose,
                    walletTransactionRecipientId : transRecipient,
                    walletTransactionSourceId : transSource,
                    walletTransactionTypeId : transType,
                    transactionSort: sorted,
                    pagination : {
                            offset : ((page-1)*limit),
                            limit :  limit
                    }
                }
            }
            Object.keys(data.walletTransactionsFilters).forEach((key) => (data.walletTransactionsFilters[key].length === 0) && delete data.walletTransactionsFilters[key])
            dispatch(getAllTransactionAction(data))
            setShowPagination(false)
        }else{
            let searchTransactionData = {
                searchString: e.target.value
            }
            dispatch(searchTransactionAction(searchTransactionData))
            setShowPagination(true)
        }
    }

    const [transPurpose, setTransPurpose] = useState([])
    const [transRecipient, setTransRecipient] = useState([])
    const [transSource, setTransSource] = useState([])
    const [transType, setTransType] = useState([])

    const filterData = (transactionPurposeType, transactionRecipient, transactionSource, transactionType) => {
        
        setTransPurpose(transactionPurposeType)
        setTransRecipient(transactionRecipient)
        setTransSource(transactionSource)
        setTransType(transactionType)

        let transFilter = {
            walletTransactionsFilters : {
                walletTransactionPurposeId :transactionPurposeType,
                walletTransactionRecipientId : transactionRecipient,
                walletTransactionSourceId : transactionSource,
                walletTransactionTypeId : transactionType,
                transactionSort: sorted,
                pagination : {
                    offset : ((page-1)*limit),
                    limit :  limit
                }
            }
        }

        Object.keys(transFilter.walletTransactionsFilters).forEach((key) => (transFilter.walletTransactionsFilters[key].length === 0) && delete transFilter.walletTransactionsFilters[key])
        dispatch(getAllTransactionAction(transFilter))

        delete transFilter.walletTransactionsFilters.pagination.offset
        delete transFilter.walletTransactionsFilters.pagination.limit

        axios.post(BASE_URL + GET_TRANSACTION, transFilter)
        .then(res=>{
            if(res.data.transactionData.length > 0){
                setCounts(Math.ceil(res.data.transactionData[0].totalcount/limit))
            }
        })
    }

    const [disableExport, setDisableExport] = useState(false)

    const exportData = () => {
        setDisableExport(true)
        setTimeout(() => {
          setDisableExport(false)
        }, 5000);
    }

    return (
        <>
        <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
            <Row>
                <Col lg={12}>
                    <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                        <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-dark" active>Transaction History</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
         </div>
        <div className="d-lg-flex justify-content-end flex-wrap flex-md-nowrap align-items-center py-4">
            <Col xs={12} lg={6} className="px-0 mb-0 text-right">
                <ButtonGroup>
                    {allTransactions ? <Button disabled={disableExport ? true : false} onClick={exportData} className="export"><CSVLink {...csvReport}>Export</CSVLink></Button> : ""} 
                </ButtonGroup>
            </Col>
        </div>
        <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded gamestarz">
            <div className="row mb-4 px-4">
                <div className="col col-lg-6 col-md-12 text-left">
                    <p className='page-heading'>Transaction History</p>
                </div>
                <div className="col col-lg-6 col-md-12 text-right">
                    <span style={{cursor: "pointer"}} onClick={()=>{showModal()}} className={transPurpose.length != 0 || transRecipient.length != 0 || transSource.length != 0 || transType.length != 0 ? 'applied-filter' : 'filter'}><i className="fas fa-filter mx-2"></i><span>Filter</span></span>
                </div>
            </div>

            <div className="row d-flex px-4">
                {/* <div className="col col-md-4"></div> */}
                <div className="col col-md-4">
                    <InputGroup>
                        <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control type="text" placeholder="Search Transaction Contacts and Event" onChange={(e)=>{searchTransaction(e)}}/>
                    </InputGroup>
                </div>
                <div className="col col-md-4">
                    <select className="form-control" onChange={(e)=>{sortTransactions(e)}}>
                        <option>Sort By</option>
                        <option value="DESC">Newest to Oldest</option>
                        <option value="ASC">Oldest to Newest</option>
                    </select>
                </div>
            </div>

            <div className="container-fluid transaction-history">
            <div className="mt-5">     
                <Row>
                    <Col style={{overflowX : "scroll"}} lg={12} md={12} sm={12}>
                        <table  className="table table-striped text-center table-custom">
                            <thead className='table-head'>
                                <tr style={{position: 'sticky !important', top : '0px'}}>
                                    <th className='head-border-left' scope="col">TRANSACTION DATE</th>
                                    <th scope='col'>Transaction Time</th>
                                    <th scope="col">Transaction Amount</th>
                                    <th scope="col">Transaction Contact</th>
                                    <th scope="col">Transaction Type</th>
                                    <th scope="col">Transaction Source</th>
                                    <th scope="col">Transaction Recipient</th>
                                    <th scope="col">Transaction Purpose</th>
                                    <th scope="col">Transaction Event</th>
                                    <th scope="col">Transaction Status</th>
                                    <th className='head-border-right' scope="col">Transaction Mode for Debits</th>
                                </tr>
                            </thead>
                            <tbody className='table-body'>
                                {transaction && transaction.map((e)=>{
                                    return(
                                        <tr className='table-body'>
                                            <td>{e.createdAt.slice(0,10).split("-").reverse().join("-")}</td>
                                            {/* <td>{e.updatedAt.slice(12,19)}</td> */}
                                            <td>{moment(e.updatedAt).tz('Asia/Kolkata').format().slice(11,19)}</td>
                                            <td>{e.amount != null ? <span>&#8377; {parseFloat(e.amount).toFixed(2)}</span> : "-"}</td>
                                            <td>{e.recipientTransactionContact == 'playstarz' ? e.sourceTransactionContact : e.recipientTransactionContact}</td>
                                            <td>{e.walletTransactionType === 'debit' ? <span style={{color: 'red'}}><b>{e.walletTransactionType}</b></span> : e.walletTransactionType === 'credit' ? <span style={{color: 'green'}}><b>{e.walletTransactionType}</b></span> : "-"}</td>
                                            <td>{e.sourceWalletName != "" ? e.sourceWalletName : '-'}</td>
                                            <td>{e.walletTransactionRecepientName != "" ? e.walletTransactionRecepientName : "-"}</td>
                                            <td>{e.walletTransactionPurposeName != "" ? e.walletTransactionPurposeName : "-"}</td>
                                            <td>{e.transactionEvent && e.transactionEvent != null && e.transactionEvent != '' ? e.transactionEvent : '-'}</td>
                                            <td>{e.status != "" ? <span className={e.status == 'processed' ? 'success' : e.status == 'processing' ? 'danger' : ''}>{e.status}</span> : "-"}</td>
                                            <td>{e.walletTransactionPurposeName != "" ? e.walletTransactionPurposeName : "-"}</td>
                                        </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    {showPagination == false ?
                    <div className="d-flex justify-content-left my-4">
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <Pagination count={counts} defaultPage={page} color="info" className='paginate'
                                variant="outlined" shape="rounded" showFirstButton={false} showLastButton={false}  
                                onChange={(event, value)=> setPage(value)} />
                            </Col>
                        </Row>
                    </div>
                    : ""}
                </div> 
            </div>
        </div>
        <TransactionFilter show={showTransactionFilter} onHide={showModal} FilterTransaction={filterData}/>
        </>
    )
}

export default TransactionHistory