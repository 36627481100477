import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import { Link} from "react-router-dom";
import { Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useDispatch, useSelector} from 'react-redux';
import QueryBuilder, { formatQuery } from "react-querybuilder";
import '../../BusinessLogic/style.scss'
import customAxios from '../../../utlis/interceptor';
import { BASE_URL, GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE, GET_ALL_OPERATOR } from '../../../resources/APIEndpoints';

export default function RuleDetailModal({show, onHide}) {

    const buildClasses = () => {
        return {
          queryBuilder: "qb-container", // Root <div> element
          ruleGroup: "rule-group notification", // <div> containing the RuleGroup
          combinators: "select select-combinator", // <select> control for combinators
          addRule: "btn-add-rule button is-info is-small is-rounded", // <button> to add a Rule
          addGroup: "btn-add-group button is-info is-small is-rounded", // <button> to add a RuleGroup
          removeGroup: "button is-danger is-small is-rounded", // <button> to remove a RuleGroup
          notToggle: "label", // <label> on the "not" toggle
          rule: "rule notification", // <div> containing the Rule
          fields: "select select-field", // <select> control for fields
          operators: "select select-operator", // <select> control for operators
          value: "input input-value is-small", // <input> for the field value
          removeRule: "button is-danger is-small is-rounded" // <button> to remove a Rule
        };
      };

    const [showD, setShowD] = useState(false)

    // const operators = [
    //     { name: "equal", label: "equal to" },
    //     { name: "notEqual", label: "not equal to" },
    //     { name: "lessThan", label: "less than" },
    //     { name: "greaterThan", label: "greater than" },
    //     { name: "lessThanEqualTo", label: "less than equal to" },
    //     { name: "greaterThanEqualTo", label: "greater than equal to" }
    // ];

    const [operators, setOperators] = useState([])

    const getAllOperator = async() => {
        let getOperator = {
            operatorTypeFilters : {
                pagination : {
                    
                }
            }
        }
        let res = await customAxios.post(BASE_URL + GET_ALL_OPERATOR, getOperator)
        let initial = []
        for(var i = 0 ; i < res.data.ruleDetails.length; i++){
            initial.push({name : res.data.ruleDetails[i].operatorSymbol , label : res.data.ruleDetails[i].operatorSymbol })
        }
        setOperators(initial)
    }

    useEffect(()=>{
        getAllOperator()
    }, [])

    const showQuery = () => {

    let result;
    if(ruleDetail[0].rule.all != undefined && ruleDetail[0].rule.any == undefined){
        result = ruleDetail[0].rule.all
    }else if(ruleDetail[0].rule.all == undefined && ruleDetail[0].rule.any != undefined){
        result = ruleDetail[0].rule.any
    }

    // let result = ruleDetail[0].rule.all

    function recusrsive(result){
        for(var i = 0; i < result.length ; i++){
            var current = result[i]
            current.field = current['fact'];
            delete current.fact
            if((current.all || current.rules || current.any)){
                if(current.all){
                    current.rules = current['all']
                    current.combinator = 'and'
                    recusrsive(current.all)
                    delete current.all
                }else{
                    current.rules = current['any']
                    current.combinator = 'or'
                    recusrsive(current.any)
                    delete current.any
                }
            }
        }
    }

    recusrsive(result)

    let initialQuery 

    if(ruleDetail[0].rule.all != undefined && ruleDetail[0].rule.any == undefined){
        initialQuery = {
            combinator: 'and',
            rules: result,
        }
    }else if(ruleDetail[0].rule.all == undefined && ruleDetail[0].rule.any != undefined){
        initialQuery = {
            combinator: 'or',
            rules: result,
        }
    }


    setQuery(initialQuery)
    setShowD(!showD)

    }

    const close = () => {
        onHide();
    }

    let ruleDetail = useSelector(state =>{
        return state.businessLogic.rulesDetail
    })

    const [fields, setFields] = useState()

    const [query, setQuery] = useState()

    useEffect(()=>{
        getAllAttributes();
    }, [])

    const getAllAttributes = async() => {
        let allAttributeNames = []
        let allAttribute = {
            conditionTypeAttributeFilters : {
                pagination : {
                    offset : 0,
                    limit : 1000
                }
            }
        }
        
        let res = await customAxios.post(BASE_URL + GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE, allAttribute)

        res.data.attributeDetails && res.data.attributeDetails.map((e, i) => {
            allAttributeNames.push({name: e.attributeName, label: e.attributeName})
        })

        setFields(allAttributeNames)
        
    }
    
    if(ruleDetail && ruleDetail.length > 0 ){
        return (
            <Modal scrollable={true} contentClassName="new_modal" size="lg" centered show={show} onHide={onHide} className='competitiondetail-modal'>
                    <Modal.Header>
                        <div className="modal_head">
                            <span className='competitiondetail-heading'>Rule Detail</span>
                        </div>
                    </Modal.Header>
        
                    <Modal.Body className='competitiondetail-body'>
                        <Row className='my-3'>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <label>Rule Id</label>
                                <input className='form-control' disabled  defaultValue={ruleDetail[0].id != "" ? ruleDetail[0].id : "-"}/>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <label>Rule Name</label>
                                <input className='form-control' disabled defaultValue={ruleDetail[0].ruleName != "" ? ruleDetail[0].ruleName : "-"}/>
                            </Col>
                        </Row>

                        <Row className='my-3'>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <label>Rule Description</label>
                                <textarea className='form-control' disabled rows={5} defaultValue={ruleDetail[0].ruleDescription != "" ? ruleDetail[0].ruleDescription : "-"}></textarea>
                            </Col>
                        </Row>
                        
                        <Row className='my-3'>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <label>Condition Type</label>
                                <input className='form-control' disabled  defaultValue={ruleDetail[0].conditionTypeId != "" ? ruleDetail[0].conditionTypeId : "-"}/>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <label>Condition Type</label>
                                <input className='form-control' disabled defaultValue={ruleDetail[0].conditionType.conditionName != "" ? ruleDetail[0].conditionType.conditionName : "-"}/>
                            </Col>
                        </Row>
                        
                        {showD ?
                        <Row>
                            <Col>
                                <QueryBuilder
                                    fields={fields} operators={operators}
                                    query={query} controlClassnames={buildClasses()}
                                    onQueryChange={q => setQuery(q)}
                                />
                            </Col>
                        </Row> : ''}

                        
                            <Row>
                                <Col>
                                    <button className='btm btn-sm btn-success' onClick={showQuery}>{showD ? 'Hide Decision' : 'Show Decision'}</button>
                                </Col>
                            </Row>
    
                    </Modal.Body>
                    <Modal.Footer className='competition-footer'>
                        <Row>
                            <Col>
                                <Button className="btn btn-danger btn -sm mx-2" onClick={close}>Cancel</Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
      )
    }else{
        return(
            <p></p>
        )
    }
    }
