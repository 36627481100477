import React, {useState, useEffect} from 'react'
import { Modal, Row, Col } from "react-bootstrap"
import axios from '../../utlis/interceptor'


function CountryModal({show, onHide, filter, ExcludedState}) {

    const [States, SetStates] = useState("")
    const [id, setId] = useState("")

    useEffect(() => {
        async function getStates(){
            await axios.get('https://staging.gamestarz.xyz/v1/static-values')
            .then(res=>{
                SetStates(res.data.states)
                setId(res.data.states.map((e)=>{
                    return e.id
                }))
            })
        }
        getStates();
    }, [])

    const [result, setResult] = useState([2,3,4,25,26,30,36])
    const [check , setCheck] = useState(true);

    const statefilter = (i, value, evt) => {
        if(evt.target.checked){
            result.push(parseInt(value))
            setResult(result);
        }
    }

        

    const save = () =>{
        var Included_States = id.filter(function(val) {
            return result.indexOf(val) == -1;
        });

        if(result.length != 0){
            filter(Included_States);
        }else if(result.length === 0){
            filter(result);
        }
        ExcludedState(result)
        onHide();
        
    }
    
    const close = () => {
        onHide();
    }


    return (
        <Modal id="myModal" scrollable={true} centered show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Exclude States</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
        <Row className="justify-content-center">
                    <Col>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <h6>Select States</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                {States && States.map((e, i)=>{
                                    return(
                                        <div key={e.id}>
                                            <input type="checkbox" defaultChecked={e.stateName == "ASSAM" || e.stateName == "ODISHA" || e.stateName == "TELANGANA" || e.stateName == "SIKKIM" || e.stateName == "ARUNACHAL PRADESH" || e.stateName == "NAGALAND" || e.stateName == "ANDHRA PRADESH" ? true : ''} key={e.id} value={e.id} className="mt-2" onClick={(evt)=>{statefilter(i, evt.target.value, evt)}}/>
                                            <label className="mx-2" key={e.id}>{e.stateName}</label>
                                        </div>
                                    )
                                })}
                            </Col>
                        </Row>
                    </Col>
                </Row>
        </Modal.Body>
        <Modal.Footer>
            <Row>
                <Col className="text-right mt-2">
                    <button className="btn btn-md btn-danger" onClick={() =>{save()}}>save</button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
    )
}

export default CountryModal