import { ADD_VERSION, BASE_URL, EDIT_VERSION, GET_VERSION } from '../resources/APIEndpoints'
import axios from '../utlis/interceptor'

function VersionServices() {
    this.getAllVersion = (body) => axios.post(BASE_URL + GET_VERSION, body)
    this.getVersionById = (body) => axios.post(BASE_URL + GET_VERSION, body)
    this.addVersion = (body) => axios.post(BASE_URL + ADD_VERSION, body)
    this.editVersion = (body) => axios.put(BASE_URL + EDIT_VERSION, body)
}

export default new VersionServices()