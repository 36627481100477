import React, { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { Col, Row, Button} from '@themesberg/react-bootstrap';
import { useHistory } from "react-router";
import { loginUser } from "../redux/action/authAction";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/login.css'

function LoginPageThree(props) {

    const dispatch = useDispatch()
    const [details, setDetails] = useState({ username: "", password: "" });
    let history = useHistory();
    
    const userlogin = (e) => {
      if(details.username === "" || details.password === ""){
        dispatch(loginUser(details, props.history))
      }else{
        dispatch(loginUser(details, props.history))
      }
    }

    return (
        <>
            <div className="main_wrapper">
                <div className="inside_wrapper">
                    <Row className="d-flex justify-content-end">
                        <Col xl={4} lg={4} md={4} sm={12} xs={12} className='Overlay_Login'>
                            <div className="model_login">
                                <div>
                                    <h2 className="text-center text-light">Login</h2>
                                </div>
                                <div>
                                    <div className="form-group my-3">
                                        <label>Username</label>
                                        <input className="input form-control p-2" type="text" name="username" onChange={(e) => setDetails({ ...details, username: e.target.value })} value={details.username}/>
                                    </div>
                                    <div className="form-group my-3">
                                        <label>Password</label>
                                        <input className="input form-control p-2" type="Password" name="password" id="last-name"onChange={(e) =>setDetails({ ...details, password: e.target.value }) } value={details.password} />
                                    </div>
                                    <div className="text-center">
                                        <Button variant="secondary" className="text-dark me-2" onClick={userlogin}>Login</Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default LoginPageThree