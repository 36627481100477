import axios from '../utlis/interceptor'
import {BASE_URL, GET_SINGLE_PAYOUT, SINGLE_PAYOUT, CREATE_COUPONS, GET_COUPONS, GET_TRANSACTION, GET_SCRATCH_CARD, ADD_SCRATCH_CARD, EDIT_COUPON, EDIT_SCRATCH_CARD, SEARCH_COUPON, SEARCH_SCRATCH_CARD, SEARCH_TRANSACTION, ADD_VERSION } from "../resources/APIEndpoints";

function PlaystarzBankServices() {
    this.getAllTransaction = (body) => axios.post(BASE_URL + GET_TRANSACTION, body)
    this.getAllCoupons = (body) => axios.post(BASE_URL + GET_COUPONS, body)
    this.createCoupons = (body) => axios.post(BASE_URL + CREATE_COUPONS, body)
    this.getAllScratchCard = (body) => axios.post(BASE_URL + GET_SCRATCH_CARD, body)
    this.createScratchCard = (body) => axios.post(BASE_URL + ADD_SCRATCH_CARD, body)
    this.getAllScratchCardById = (body) => axios.post(BASE_URL + GET_SCRATCH_CARD, body)
    this.getAllCouponsById = (body) => axios.post(BASE_URL + GET_COUPONS, body)
    this.editCoupon = (body) => axios.post(BASE_URL + EDIT_COUPON, body)
    this.editScratchCard = (body) => axios.post(BASE_URL + EDIT_SCRATCH_CARD, body)
    this.searchCoupon = (body) => axios.post(BASE_URL + SEARCH_COUPON, body)
    this.searchScratchCard = (body) => axios.post(BASE_URL + SEARCH_SCRATCH_CARD, body)
    this.searchTransaction = (body) => axios.post(BASE_URL + SEARCH_TRANSACTION, body)
    this.addSinglePayout = (body) => axios.post(BASE_URL + SINGLE_PAYOUT, body)
    this.getSinglePayout = (body) => axios.post(BASE_URL + GET_SINGLE_PAYOUT, body)
}

export default new PlaystarzBankServices()