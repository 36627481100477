
import { LOGIN, LOGIN_FAILED } from '../actionType/actionTypes'
import AuthService from '../../services/AuthServices';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const loginUser = (body, history) => {
    return (dispatch) => {
        AuthService.login(body)
            .then(res => {
                if(localStorage.getItem('type') == "Production"){
                    localStorage.setItem('type', "Production")
                }else{
                    localStorage.setItem('type', "Staging")
                }
                
                localStorage.setItem('token', res.data.accessToken)
                localStorage.setItem("orgLogo", res.data.user.thumbUrl)
                localStorage.setItem('sidebarContracted', true);
                dispatch(getLoggedInUser(res));
                history.push('/overview')
                window.location.reload()
            })
            .catch(error => {
                toast.error("Invalid Username or Password")
                dispatch(onLoginFailed(error))
            })
    }
}

export const getLoggedInUser = (data)=> {
    return{
        type: LOGIN,
        payload: data
    }
}

export const onLoginFailed = (data)=> {
    return{
        type: LOGIN_FAILED,
        payload: data
    }
}
