import React, {useState, useEffect, useRef, useMemo } from 'react'
import { Dropdown, Col,InputGroup, Row, Nav, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faTimesCircle, faEdit, faPlusCircle, faEllipsisH, faEye } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Breadcrumb } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import JoditEditor from 'jodit-react';
import { editDocumetAction, getDocumentDetailAction } from '../../redux/action/documentsAction';
import axios from '../../utlis/interceptor';
import { BASE_URL, GET_DOCUMENTS } from '../../resources/APIEndpoints';


function EditDocument(props) {
    const editor = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();

    const [content, setContent] = useState('');
    const [documentName, setDocumentName] = useState('')
    const [docId, setDocId] = useState()
    
    const getDocumentDetail = async() => {
        let docDetail = {
            docFilters : {
                id : props.location.id,
                pagination : {
        
                }
            }
        }

        let res = await axios.post(BASE_URL + GET_DOCUMENTS, docDetail)
        .then(res=>{
            setDocId(res.data.docDetails[0].id)
            setDocumentName(res.data.docDetails[0].documentName)
            setContent(res.data.docDetails[0].documentContent)
        })
    }

    useEffect(()=>{
        getDocumentDetail();
    }, [])

    let DocumentsDetail = useSelector((state) => {
        return state.documents.documentDetail
    })

    const editDocument = () => {
        let editContent = {
            id : docId,
            documentName : documentName,
            documentContent : content
        }
        dispatch(editDocumetAction(editContent, props.history))

    }

    const cancel = () => {
        
    }

    return (
            <>
                <ToastContainer />
                    <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                        <Row>
                            <Col lg={12}>
                                <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                                    <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#business-logic">Document</Breadcrumb.Item>
                                    <Breadcrumb.Item className="text-dark" active>Edit Document</Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </div>
    
                    <div className="d-lg-flex justify-content-end flex-wrap flex-md-nowrap align-items-center py-4">
                        
                    </div>
                    <div className="task-wrapper border bg-white border-light shadow-sm py-5 rounded gamestarz">
                        <div className="row mb-4 px-4">
                            <div className="col col-lg-6 col-md-12 text-left">
                                <h4>Edit Document</h4>
                            </div>
                        </div>
    
                        <div className="container-fluid payout">
    
                            <Row className='my-2'>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <label className='mb-2'>Document Name</label>
                                    <input type='text' className='form-control' defaultValue={documentName} onChange={(e)=>{setDocumentName(e.target.value)}}/>
                                </Col>
                            </Row>
    
                            <Row className='my-4'>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <label className='mb-2'>Document Content</label>
                                    <JoditEditor ref={editor} value={content} onChange={newContent => {setContent(newContent)}} />
                                </Col>
                            </Row>
    
                            <Row className='my-3'>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Button className='btn btn-success' onClick={editDocument}>Edit</Button>
                                    <Button className='btn btn-danger mx-3' onClick={cancel}>Cancel</Button>
                                </Col>
                            </Row>
    
                        </div>
                    </div>
                </>
    )
}


export default EditDocument