import BusinessLogicServices from "../../services/BusinessLogicServices";
import { GET_ALL_CONDITIONTYPE, GET_CONDITION_DETAIL, GET_ALL_ATTRIBUTE, GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE, GET_RULE, CREATE_RULE, EDIT_RULE, CREATE_CONDITIONTYPE, CREATE_ATTRIBUTE, GET_RULE_DETAIL, SEARCH_ATTRIBUTE, SEARCH_MODULE } from "../actionType/actionTypes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getAllConditionTypeAction = (body) => {
    return async (dispatch) => {
        await BusinessLogicServices.getAllConditionType(body)
        .then(res=>{
            dispatch(getAllConditionType(res.data.conditionDetails))
        })
    }
}

export const getCoditionTypeDetailAction = (body) => {
    return async (dispatch) => {
        await BusinessLogicServices.getConditionTypeDetail(body)
        .then(res=>{
            dispatch(getConditionTypeDetail(res.data.conditionDetails))
        })
    }
}

export const getAllAttributeAction = (body) => {
    return async (dispatch) => {
        await BusinessLogicServices.getAllAttribute(body)
        .then(res=> {
            dispatch(getAllAttribute(res.data.attributeDetails))
        })
    }
}

export const getAllAttributeDetailAction = (body) => {
    return async (dispatch) => {
        await BusinessLogicServices.getAllAtributeByCondtionType(body)
        .then(res=> {
            dispatch(getAllAtributeByCondtionType(res.data.attributeDetails))
        })
    }
}

export const getAllRuleByModuleAction = (body) => {
    return async (dispatch) => {
        await BusinessLogicServices.getRule(body)
        .then(res=> {
            dispatch(getRule(res.data.ruleDetails))
        })
    }
}

export const getRuleDetailAction = (body) => {
    return async (dispatch) => {
        await BusinessLogicServices.getRuleDetail(body)
        .then(res=> {
            dispatch(getRuleDetail(res.data.ruleDetails))
        })
    }
}

export const createRuleAction = (body, history) => {
    return async (dispatch) => {
        await BusinessLogicServices.creatRule(body)
        .then(res=> {
            toast.success("Rule Created Successfully")
            setTimeout(() => {
                history.push('/business-logic')
            }, 1500);
        })
    }
}

export const editRuleAction = (body, history) => {
    return async (dispatch) => {
        await BusinessLogicServices.editRule(body)
        .then(res=>{
            toast.success("Rule Updated Successfully")
        })
        setTimeout(() => {
            history.push('/business-logic')
        }, 1500);
    }
}

export const createModuleAction = (body, history) => {
    return async (dispatch) => {
        await BusinessLogicServices.createCondition(body)
        .then(res=>{
            toast.success("Module Created Successfully")
            setTimeout(() => {
                history.push('/business-logic')
            }, 1500);
        }).catch(err=>{
            console.log(err.response)
        })
    }
}

export const createAttributeAction = (body, history) => {
    return async (dispatch) => {
        await BusinessLogicServices.createAttribute(body)
        .then(res=>{
            toast.success("Attribute Created Successfully");
            setTimeout(() => {
                history.push('/business-logic')
            }, 1500);
        })
    }
}

export const searchModuleAction = (body) => {
    return async (dispatch) => {
        await BusinessLogicServices.searchModule(body)
        .then(res=>{
            dispatch(searchModule(res.data.gameData))
        })
    }
}

export const searchAttributeAction = (body) => {
    return async (dispatch) => {
        await BusinessLogicServices.searchAttribute(body)
        .then(res=>{
            dispatch(searchAttribute(res.data.gameData))
        })
    }
}

export const getAllConditionType = (data) => {
    return {
        type : GET_ALL_CONDITIONTYPE,
        payload : data
    }
}

export const getConditionTypeDetail = (data) => {
    return {
        type : GET_CONDITION_DETAIL,
        payload : data
    }
}

export const getAllAttribute = (data) => {
    return {
        type : GET_ALL_ATTRIBUTE,
        payload : data
    }
}

export const getAllAtributeByCondtionType = (data) => {
    return {
        type : GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE,
        payload : data
    }
}

export const getRule = (data) => {
    return {
        type : GET_RULE,
        payload : data
    }
}

export const createRule = (data) => {
    return {
        type : CREATE_RULE,
        payload : data
    }
}

export const editRule = (data) => {
    return {
        type : EDIT_RULE,
        payload : data
    }
}

export const getRuleDetail = (data) => {
    return {
        type : GET_RULE_DETAIL,
        payload : data
    }
}

export const createCondition = (data) => {
    return {
        type : CREATE_CONDITIONTYPE,
        payload : data
    }
}

export const createAttribute = (data) => {
    return {
        type : CREATE_ATTRIBUTE,
        payload : data
    }
}

export const searchModule = (data) => {
    return {
        type : SEARCH_MODULE,
        payload : data
    }
}

export const searchAttribute = (data) => {
    return {
        type : SEARCH_ATTRIBUTE,
        payload : data
    }
}