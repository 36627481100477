import React, { useState } from 'react'
import {Row, Col, ButtonGroup, Button, InputGroup, Form, Pagination, Nav} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import PDFilterModal from './modals/Filters/PDFilterModal'

function PDReport() {
    
    const [PDFilter, setPDFilter] = useState(false)

    const showPDFilter = () => {
        setPDFilter(!PDFilter)
    }

    return (
        <>
        <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
                <div className="me-lg-3">
                    <InputGroup>
                        <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control type="text" placeholder="Search Leaderboard" />
                    </InputGroup>
                </div>
            </Col>

            <Col xs={12} lg={6} className="px-0 mb-0 text-right">
                <ButtonGroup>
                    <Button variant="outline-primary" size="sm">Export</Button>
                </ButtonGroup>
            </Col>
        </div>
        <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5">
            <Row className="my-3">
                <Col>
                    <div className="row d-flex px-4 align-items-center">
                        <div className="col col-md-4">
                            <select className="form-control">
                                <option>Sort By</option>
                                <option>Player Rank (High-Low)</option>
                            </select>
                        </div>
                        <div className="col col-md-8 text-right">
                            <span onClick={showPDFilter} style={{cursor: "pointer", paddingRight: "10px"}}><i class="fas fa-filter mx-2"></i><span>Filter</span></span>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="container-fluid">
                <Col lg={12} md={12} sm={12}>
                    <table  className="table table-striped text-center">
                         <thead>
                            <tr>
                                <th scope="col">Winners Player ID</th>
                                <th scope="col">Player Rank</th>
                                <th scope="col">Player Score</th>
                                <th scope="col">Player Playstarz Points</th>
                                <th scope="col">Winning Amount</th>
                                <th scope="col">Total Amount Distributed</th>
                                <th scope="col">Prize Distribution Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>04</td>
                                <td>01</td>
                                <td>20000</td>
                                <td>5000</td>
                                <td>15000</td>
                                <td>1000</td>
                                <td>21 Jan 2020</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>01</td>
                                <td>4000</td>
                                <td>13000</td>
                                <td>10000</td>
                                <td>1000</td>
                                <td>21 Jan 2020</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>03</td>
                                <td>3000</td>
                                <td>10000</td>
                                <td>9000</td>
                                <td>1000</td>
                                <td>21 Jan 2020</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>02</td>
                                <td>3500</td>
                                <td>10000</td>
                                <td>1000</td>
                                <td>2000</td>
                                <td>21 Jan 2020</td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>
            <Row className="mt-4 align-items-center">
                <Col>
                    <Nav>
                        <Pagination className="mb-2 mb-lg-0">
                        <Pagination.Prev>
                            Previous
                        </Pagination.Prev>
                        <Pagination.Item active>1</Pagination.Item>
                        <Pagination.Item>2</Pagination.Item>
                        <Pagination.Item>3</Pagination.Item>
                        <Pagination.Item>4</Pagination.Item>
                        <Pagination.Item>5</Pagination.Item>
                        <Pagination.Next>
                            Next
                        </Pagination.Next>
                        </Pagination>
                    </Nav>
                </Col>
                <Col className="text-right px-4">
                    <small className="fw-bold">
                        Showing 4 out of <b>25</b> entries
                    </small>
                </Col>
            </Row>
            {PDFilter ? <PDFilterModal addOpen={PDFilter} Addtoggle={showPDFilter}/> : ""}
        </div>
    </>
    )
}

export default  PDReport