import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {GameByIdAction, EditGameAction, CreateMatchAction, getallGamesAction, createGameAction, deletePlatformAction, cleargameAction, cleargame} from '../redux/action/gameAction'
import { Col, Row, Button, Card, Image} from '@themesberg/react-bootstrap';
import placeholder from './Images/attach_img.png'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Checkbox, Select, TextField } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlus, faTrash, faArrowLeft, faEdit, faTrashAlt, faImage, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import './css/AddGame.css'
import CountryModal from './modals/CountryModal';
import {Game_Id,Game_Name, Game_Logo, Game_Description, Game_How_to, Preview_Video, Game_Screenshots, Game_Platform, Asset_Bundle_Version, Minimum_App_Version, Game_Genre, Screen_Orientation, Game_World, Matches_Created, Match_Name, Match_Format, Match_How_To, Match_Outcome_Type, Leaderboard_Outcome_Type, Included_Countries, Phase, Save, Min_Players, Max_Players, Podium_Rank_Match_Outcome, Podium_Rank_Leaderboard_Outcome, Rank, Points, Win_Points, Draw_Points, Loss_Points} from './../constants/LabelConstants'
import { DropzoneArea } from 'material-ui-dropzone';
import axios from "../utlis/interceptor";
import axiosUpload from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Routes } from "../routes";
import { Link } from "react-router-dom";
import {useDropzone} from 'react-dropzone'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { BASE_URL, EDIT_GAME, GET_GAME } from "../resources/APIEndpoints";
import { useHistory } from 'react-router-dom';
import { combineReducers } from "redux";
import Loader from "react-loader-spinner";
import { Breadcrumb } from "react-bootstrap";
import moment from "moment";

const getDatafromLS=()=>{
    const data = localStorage.getItem('books');
    if(data){
      return JSON.parse(data);
    }
    else{
      return []
    }
  }

function EditGame(props, {id}) {

    const history = useHistory();
    const dispatch = useDispatch();
    // DropZone Content
    const [files, setFiles] = useState([]);
    var { getRootProps, getInputProps } = useDropzone({
      accept: 'image/*',
      onDrop: files => setFiles(files.map(file => ({
        ...file,
        preview: URL.createObjectURL(file)
      })))
    });

    const [matchesDetail, setMatchesDetail] = useState(getDatafromLS())
    useEffect(()=>{
        localStorage.setItem('books',JSON.stringify(matchesDetail));
        const Token = localStorage.getItem('books')
      },[matchesDetail])

    const [GameworldData, setGameWorldData] = useState("")
    const [leaderboardOutcomData, setLeaderboardOutcomData] = useState("")
    const [matchOutcomeData, setMatchOutcomeData] = useState("")
    const [matchFormatData, setMatchFormatData] = useState("")
    const [statesList, setStateList] = useState("")

    useEffect(() => {
        function getStates(){
            axios.get('https://dev.gamestarz.xyz/v1/static-values')
            .then(res=>{
                setLeaderboardOutcomData(res.data.leaderboardOutcome);
                setMatchOutcomeData(res.data.matchOutcome)
                setGameWorldData(res.data.gameWorld)
                setMatchFormatData(res.data.matchFormat)
                setStateList(res.data.states)
            })
        }
        getStates();
    }, [])

    const icon = <CheckBoxOutlineBlankIcon fontSize = "small" />
    const checkedIcon = <CheckBoxIcon fontSize = "small" />
    
    const[Gamename, setGamename] = useState()
    const[GamenameError, setGamenameError] = useState(false)
    const[gameLogo, setGameLogo] = useState("")
    const[previewVideo, setPreviewvideo] = useState([])
    const[screenshot, setScreenshot] = useState([]);
    const[androidbundle, setAndroidBundle] = useState("")
    const[iOSbundle, setIosBundle] = useState("")
    const[Description, setDescription] = useState("")
    const[DescriptionError, setDescriptionError] = useState(false)
    const[detail, setDetail] = useState("")
    const[detailError, setdetailError] = useState(false)
    const[bundlevesion, setBundleversion] = useState()
    const[bundleError, setBundleError] = useState(false)
    const[version, setVersion] = useState("")
    const[versionError, setversionError] = useState(false)
    const[Orientation, setOrientation] = useState()
    const[OrientationError, setOrientationError] = useState(false)
    const[type, setType] = useState("")
    const[typeError, settypeError] = useState(false)
    const[World, setWorld] = useState("")
    const[WorldError, setWorldError] = useState(false)
    const[Genre, setGenre] = useState("")
    const[GenreError, setGenreError] = useState(false)
    const[minplayers, setMinplayers] = useState()
    const[minplayersError, setminplayersError] = useState(false)
    const[maxplayers, setMaxplayers] = useState()
    const[maxplayersError, setmaxplayersError] = useState(false)
    const[states, setStates] = useState("")
    const[phase, setPhase] = useState("")
    const[draft, setDraft] = useState("")
    const[drafterror, setdrafterror] = useState(false)
    const[{alt, src}, setLogo] = useState({ src: placeholder, alt: 'Upload an Image'}); 
    const platforms = [{platformName: "android"}, {platformName: "ios"}];
    const[platform] = useState(platforms)
    const[platformnew, setPlatformNew] = useState()
    const[platformError, setPlatformError] = useState(false)
    const Countrydata = [{CountryName: 'India', id: 1}]
    const[country, setCountry] = useState(Countrydata)
    const[countrynew, setCountryNew] = useState()
    // Match Data
    const[showAccordian, setShowAccordian] = useState(false)
    const [matchName, setMatchName] = useState("")
    const [matchNameErr, setMatchNameErr] = useState(false)
    const [matchFormat, setMatchFormat] = useState()
    const [matchFormatErr, setMatchFormatErr] = useState(false)
    const [matchHowTo, setMatchHowTo] = useState("")
    const [matchHowToErr, setMatchHowToErr] = useState(false)   
    const [matchOutcome, setMachOutcome] = useState()
    const [matchOutcomeErr, setmatchOutcomeErr] = useState(false)
    const [LeaderboardOutcome, setLeaderBoardOutcome] = useState()
    const [LeaderboardOutcomeErr, setLeaderboardOutcomeErr] = useState(false)
    const [PodiumWeigh, setPodiumWeigh] = useState("")
    const [result, setresult] = useState("")

    // Add Match
    const [matchesDraft, setMatchesDraft] = useState([])
    const onSavematch = () => {

        const obj = []
        results.forEach((e)=>{
            var em = ""
            var key_one = e.rank
            var key_two = e.points

            obj.push({[key_one] : key_two})
        })

        if(matchFormat === 1){
            if(LeaderboardOutcome === 3){
                const match_data = {
                    game_id : gamesDetail[0].id,
                    match:[    
                            {
                                match_name : matchName,
                                match_format_type_id : parseInt(matchFormat),
                                match_outcome_type_id : parseInt(matchOutcome),
                                leader_board_outcome_type_id : parseInt(LeaderboardOutcome),
                                match_min_players : 1,
                                match_max_players : 1,
                                match_how_to : matchHowTo,
                                leader_board_weighting_system: [[{win: win, loose: loss, draw: draw}]],
                            }
                            ]
                    }
                setMatchesDetail([...matchesDetail, match_data]);
                dispatch(CreateMatchAction(match_data))
            }else if(LeaderboardOutcome === 4){
                const match_data = {
                    game_id : gamesDetail[0].id,
                    match:[    
                            {
                                match_name : matchName,
                                match_format_type_id : parseInt(matchFormat),
                                match_outcome_type_id : parseInt(matchOutcome),
                                leader_board_outcome_type_id : parseInt(LeaderboardOutcome),
                                match_min_players : 1,
                                match_max_players : 1,
                                match_how_to : matchHowTo,
                                leader_board_weighting_system: obj,
                            }
                            ]
                    }
                setMatchesDetail([...matchesDetail, match_data]);
                dispatch(CreateMatchAction(match_data))
            }else if(LeaderboardOutcome === 1 || LeaderboardOutcome === 2 || LeaderboardOutcome === 5){
                const match_data = {
                    game_id : gamesDetail[0].id,
                    match:[    
                            {
                                match_name : matchName,
                                match_format_type_id : parseInt(matchFormat),
                                match_outcome_type_id : parseInt(matchOutcome),
                                leader_board_outcome_type_id : parseInt(LeaderboardOutcome),
                                match_min_players : 1,
                                match_max_players : 1,
                                match_how_to : matchHowTo,
                                leader_board_weighting_system: [],
                            }
                            ]
                    }
                setMatchesDetail([...matchesDetail, match_data]);
                dispatch(CreateMatchAction(match_data))
            }
            
        }else if(matchFormat === 2){
            if(LeaderboardOutcome === 3){
                const match_data = {
                    game_id : gamesDetail[0].id,
                    match:[    
                            {
                                match_name : matchName,
                                match_format_type_id : parseInt(matchFormat),
                                match_outcome_type_id : parseInt(matchOutcome),
                                leader_board_outcome_type_id : parseInt(LeaderboardOutcome),
                                match_min_players : parseInt(minplayers),
                                match_max_players : parseInt(maxplayers),
                                match_how_to : matchHowTo,
                                leader_board_weighting_system: [[{win: win, loose: loss, draw: draw}]],
                            }
                            ]
                    }
                setMatchesDetail([...matchesDetail, match_data]);
                dispatch(CreateMatchAction(match_data))
            }else if(LeaderboardOutcome === 4){
                const match_data = {
                        game_id : gamesDetail[0].id,
                        match : [{
                            match_name : matchName,
                            match_format_type_id : parseInt(matchFormat),
                            match_outcome_type_id : parseInt(matchOutcome),
                            leader_board_outcome_type_id : parseInt(LeaderboardOutcome),
                            match_min_players : parseInt(minplayers),
                            match_max_players : parseInt(maxplayers),
                            match_how_to : matchHowTo,
                            leader_board_weighting_system: obj,
                        }]
                }
                setMatchesDetail([...matchesDetail, match_data]);
                dispatch(CreateMatchAction(match_data))
            }else if(LeaderboardOutcome === 1 || LeaderboardOutcome === 2 || LeaderboardOutcome === 5){
                const match_data = {
                        game_id : gamesDetail[0].id,
                        match:[    
                            {
                                match_name : matchName,
                                match_format_type_id : parseInt(matchFormat),
                                match_outcome_type_id : parseInt(matchOutcome),
                                leader_board_outcome_type_id : parseInt(LeaderboardOutcome),
                                match_min_players : parseInt(minplayers),
                                match_max_players : parseInt(maxplayers),
                                match_how_to : matchHowTo,
                                leader_board_weighting_system: [],
                            }
                            ]
                    }
                setMatchesDetail([...matchesDetail, match_data]);
                dispatch(CreateMatchAction(match_data))
            }
        }else if(matchFormat === 3){
            if(LeaderboardOutcome === 3){
                let match={
                    match_name : matchName,
                    match_format_type_id : parseInt(matchFormat),
                    match_outcome_type_id : parseInt(matchOutcome),
                    leader_board_outcome_type_id : parseInt(LeaderboardOutcome),
                    match_min_players : minplayers,
                    match_max_players : maxplayers,
                    match_how_to : matchHowTo,
                    leader_board_weighting_system: [{win: win, loose:loss, draw:draw}],
                }
                setMatchesDetail([...matchesDetail, match]);
                setMatchName('')
                setMatchFormat('')
                setMatchHowTo('')
                setMinplayers('')
                setMaxplayers('')
                setLeaderBoardOutcome('')
                setMachOutcome('')
            }else if(LeaderboardOutcome === 4){
                let match = {
                    match_name : matchName,
                    match_format_type_id : parseInt(matchFormat),
                    match_outcome_type_id : parseInt(matchOutcome),
                    leader_board_outcome_type_id : parseInt(LeaderboardOutcome),
                    match_min_players : minplayers,
                    match_max_players : maxplayers,
                    match_how_to : matchHowTo,
                    leader_board_weighting_system: obj,
                }
                setMatchesDetail([...matchesDetail, match]);
                setMatchName('')
                setMatchFormat('')
                setMatchHowTo('')
                setMinplayers('')
                setMaxplayers('')
                setLeaderBoardOutcome('')
                setMachOutcome('')
                setResult([])
            }else if(LeaderboardOutcome === 1 || LeaderboardOutcome === 2 || LeaderboardOutcome === 5){
                let match = {
                    match_name : matchName,
                    match_format_type_id : parseInt(matchFormat),
                    match_outcome_type_id : parseInt(matchOutcome),
                    leader_board_outcome_type_id : parseInt(LeaderboardOutcome),
                    match_min_players : minplayers,
                    match_max_players : maxplayers,
                    match_how_to : matchHowTo,
                    leader_board_weighting_system: [],
                }
                setMatchesDetail([...matchesDetail, match]);
                setMatchName('')
                setMatchFormat('')
                setMatchHowTo('')
                setMinplayers('')
                setMaxplayers('')
                setLeaderBoardOutcome('')
                setMachOutcome('')
            }
        }

        

        // setMatchesDraft(matches)      

        setShowAccordian(false)
        setMatchName('')
        setMatchFormat('')
        setMinplayers('')
        setMaxplayers('')
        setMatchHowTo('')
        setMachOutcome('')
        setLeaderBoardOutcome('')
    }

    // Save Game
    const Onsave = () => {

        const gameId = props.location.id 
        if(value){
            let result = []
            value.map((e)=>{  
                result.push(e.platformName)
            })
            var PlatformRemove = ""
            var platformsUpdated = [];
            for(var i = 0; i<2 ; i++){
                if(result[i] === 'android'){
                    var a = {platformName: "android", gameAssetBundleUrl: androidbundle, gameAssetBundleVersion: bundlevesion}
                    platformsUpdated.push(a) 
                    PlatformRemove = 'ios'
                }else if(result[i] === 'ios'){
                    var a = {platformName: "ios", gameAssetBundleUrl: iOSbundle, gameAssetBundleVersion: bundlevesion}
                    platformsUpdated.push(a)
                    PlatformRemove = 'android'
                }
            }   
        }

        var PlatformNames = ''
        if(platformsUpdated.length === 2){
            PlatformNames = ''
        }else if(platformsUpdated.length === 1 && platformsUpdated[0].platformName === 'android'){
            PlatformNames = 'ios'
        }else if(platformsUpdated.length === 1 && platformsUpdated[0].platformName === 'ios'){
            PlatformNames = 'android'
        }

        const Removed_Platform = {
            gameId : gameId,
            platformName : PlatformNames
        }

        // Android And iOS Both Platforms are present. (We Just Need To Update One/Both Asset Bundle)
        if(platformnew.length === platformsUpdated.length && iOSbundle != "" && androidbundle != ""){
            if(gamesDetail[0].gameName == Gamename && (iOSbundle == platformnew[0].gameAssetBundleUrl || iOSbundle == platformnew[1].gameAssetBundleUrl) && (androidbundle == platformnew[0].gameAssetBundleUrl || androidbundle == platformnew[1].gameAssetBundleUrl)){
                let data ={
                    id: gameId,
                    // gameName : Gamename,
                    gameLogo : gameLogo, 
                    gameScreenShotUrl : screenshot, 
                    gamePreviewVideoUrl : previewVideo,
                    // gamePlatforms: platformsUpdated,
                    gameDescription : Description,
                    gameHowTo : detail, 
                    gameMinimumAppVersion: version,
                    gameGenre : Genre,
                    isGameScreenOrientationLandscape:  String(Orientation) == "true",
                    match : (matchapi.length != 0 ? matchapi : matchesDetail),
                    isDraft : false,
                    gameGeo: [{country_id: 1, state_id: stateData}]
                    }
                    if(data.gameLogo === "" || data.gameDescription === "" || data.gameHowTo === "" ||  data.gameMinimumAppVersion === ""  || data.gameMinimumAppVersion === null || data.isGameScreenOrientationLandscape === "" ||  data.game_genre ===""){
                        validation();
                    }else{
                        dispatch(EditGameAction(data, props.history))
                        dispatch(deletePlatformAction(Removed_Platform))
                    }
            }else if(gamesDetail[0].gameName == Gamename && (iOSbundle != platformnew[0].gameAssetBundleUrl || iOSbundle != platformnew[1].gameAssetBundleUrl) && (androidbundle != platformnew[0].gameAssetBundleUrl || androidbundle != platformnew[1].gameAssetBundleUrl)){
                let data ={
                    id: gameId,
                    // gameName : Gamename,
                    gameLogo : gameLogo, 
                    gameScreenShotUrl : screenshot, 
                    gamePreviewVideoUrl : previewVideo,
                    gameDescription : Description,
                    gameHowTo : detail, 
                    gamePlatforms: platformsUpdated,
                    gameMinimumAppVersion: version,
                    gameGenre : Genre,
                    isGameScreenOrientationLandscape:  String(Orientation) == "true",
                    match : (matchapi.length != 0 ? matchapi : matchesDetail),
                    isDraft : false,
                    gameGeo: [{country_id: 1, state_id: stateData}]
                    }
                    if(data.gameLogo === "" || data.gameDescription === "" || data.gameHowTo === "" ||  data.gameMinimumAppVersion === ""  || data.gameMinimumAppVersion === null || data.isGameScreenOrientationLandscape === "" ||  data.game_genre ===""){
                        validation();
                    }else{
                        dispatch(EditGameAction(data, props.history))
                        dispatch(deletePlatformAction(Removed_Platform))
                    }
            }else if(gamesDetail[0].gameName != Gamename && (iOSbundle == platformnew[0].gameAssetBundleUrl || iOSbundle == platformnew[1].gameAssetBundleUrl) && (androidbundle == platformnew[0].gameAssetBundleUrl || androidbundle == platformnew[1].gameAssetBundleUrl)){
                let data ={
                    id: gameId,
                    gameName : Gamename,
                    gameLogo : gameLogo, 
                    gameScreenShotUrl : screenshot, 
                    gamePreviewVideoUrl : previewVideo,
                    gameDescription : Description,
                    gameHowTo : detail, 
                    // gamePlatforms: platformsUpdated,
                    gameMinimumAppVersion: version,
                    gameGenre : Genre,
                    isGameScreenOrientationLandscape:  String(Orientation) == "true",
                    match : (matchapi.length != 0 ? matchapi : matchesDetail),
                    isDraft : false,
                    gameGeo: [{country_id: 1, state_id: stateData}]
                    }
                    if(data.gameLogo === "" || data.gameDescription === "" || data.gameHowTo === "" ||  data.gameMinimumAppVersion === ""  || data.gameMinimumAppVersion === null || data.isGameScreenOrientationLandscape === "" ||  data.game_genre ===""){
                        validation();
                    }else{
                        dispatch(EditGameAction(data, props.history))
                        dispatch(deletePlatformAction(Removed_Platform))
                    }
            }else if(gamesDetail[0].gameName != Gamename && (iOSbundle != platformnew[0].gameAssetBundleUrl || iOSbundle != platformnew[1].gameAssetBundleUrl) && (androidbundle != platformnew[0].gameAssetBundleUrl || androidbundle != platformnew[1].gameAssetBundleUrl)){
                let data ={
                    id: gameId,
                    gameName : Gamename,
                    gameLogo : gameLogo, 
                    gameScreenShotUrl : screenshot, 
                    gamePreviewVideoUrl : previewVideo,
                    gameDescription : Description,
                    gameHowTo : detail, 
                    gamePlatforms: platformsUpdated,
                    gameMinimumAppVersion: version,
                    gameGenre : Genre,
                    isGameScreenOrientationLandscape:  String(Orientation) == "true",
                    match : (matchapi.length != 0 ? matchapi : matchesDetail),
                    isDraft : false,
                    gameGeo: [{country_id: 1, state_id: stateData}]
                    }
                    if(data.gameLogo === "" || data.gameDescription === "" || data.gameHowTo === "" ||  data.gameMinimumAppVersion === ""  || data.gameMinimumAppVersion === null || data.isGameScreenOrientationLandscape === "" ||  data.game_genre ===""){
                        validation();
                    }else{
                        dispatch(EditGameAction(data, props.history))
                        dispatch(deletePlatformAction(Removed_Platform))
                    }
                }
            }
        // Android Or iOS Only One Present. (We Just Need To Update Asset Bundle Which Is Present)
        else if(platformnew.length === platformsUpdated.length && (iOSbundle != "" || androidbundle != "")){
            if(gamesDetail[0].gameName == Gamename && (iOSbundle == platformnew[0].gameAssetBundleUrl) || (androidbundle == platformnew[0].gameAssetBundleUrl)){   
                let data ={
                    id: gameId,
                    // gameName : Gamename,
                    gameLogo : gameLogo, 
                    gameScreenShotUrl : screenshot, 
                    gamePreviewVideoUrl : previewVideo,
                    // gamePlatforms: platformsUpdated,
                    gameDescription : Description,
                    gameHowTo : detail, 
                    gameMinimumAppVersion: version,
                    gameGenre : Genre,
                    isGameScreenOrientationLandscape:  String(Orientation) == "true",
                    match : (matchapi.length != 0 ? matchapi : matchesDetail),
                    isDraft : false,
                    gameGeo: [{country_id: 1, state_id: stateData}]
                    }

                    if(data.gameLogo === "" || data.gameDescription === "" || data.gameHowTo === "" ||  data.gameMinimumAppVersion === ""  || data.gameMinimumAppVersion === null || data.isGameScreenOrientationLandscape === "" ||  data.game_genre ===""){
                        validation();
                    }else{
                        dispatch(EditGameAction(data, props.history))
                        dispatch(deletePlatformAction(Removed_Platform))
                    }
            }else if(gamesDetail[0].gameName == Gamename && (iOSbundle != platformnew[0].gameAssetBundleUrl) || (androidbundle != platformnew[0].gameAssetBundleUrl)){
                platformsUpdated[0].id = platformId
                let data ={
                    id: gameId,
                    // gameName : Gamename,
                    gameLogo : gameLogo, 
                    gameScreenShotUrl : screenshot, 
                    gamePreviewVideoUrl : previewVideo,
                    gameDescription : Description,
                    gameHowTo : detail, 
                    gamePlatforms: platformsUpdated,
                    gameMinimumAppVersion: version,
                    gameGenre : Genre,
                    isGameScreenOrientationLandscape:  String(Orientation) == "true",
                    match : (matchapi.length != 0 ? matchapi : matchesDetail),
                    isDraft : false,
                    gameGeo: [{country_id: 1, state_id: stateData}]
                    }
                    if(data.gameLogo === "" || data.gameDescription === "" || data.gameHowTo === "" ||  data.gameMinimumAppVersion === ""  || data.gameMinimumAppVersion === null || data.isGameScreenOrientationLandscape === "" ||  data.game_genre ===""){
                        validation();
                    }else{
                        dispatch(EditGameAction(data, props.history))
                        dispatch(deletePlatformAction(Removed_Platform))
                    }
            }else if(gamesDetail[0].gameName != Gamename && (iOSbundle == platformnew[0].gameAssetBundleUrl) || (androidbundle == platformnew[0].gameAssetBundleUrl)){
                let data ={
                    id: gameId,
                    gameName : Gamename,
                    gameLogo : gameLogo, 
                    gameScreenShotUrl : screenshot, 
                    gamePreviewVideoUrl : previewVideo,
                    gameDescription : Description,
                    gameHowTo : detail, 
                    // gamePlatforms: platformsUpdated,
                    gameMinimumAppVersion: version,
                    gameGenre : Genre,
                    isGameScreenOrientationLandscape:  String(Orientation) == "true",
                    match : (matchapi.length != 0 ? matchapi : matchesDetail),
                    isDraft : false,
                    gameGeo: [{country_id: 1, state_id: stateData}]
                    }
                    if(data.gameLogo === "" || data.gameDescription === "" || data.gameHowTo === "" ||  data.gameMinimumAppVersion === ""  || data.gameMinimumAppVersion === null || data.isGameScreenOrientationLandscape === "" ||  data.game_genre ===""){
                        validation();
                    }else{
                        dispatch(EditGameAction(data, props.history))
                        dispatch(deletePlatformAction(Removed_Platform))
                    }
            }else if(gamesDetail[0].gameName != Gamename && (iOSbundle != platformnew[0].gameAssetBundleUrl) || (androidbundle != platformnew[0].gameAssetBundleUrl)){
                platformsUpdated[0].id = platformId
                let data ={
                    id: gameId,
                    gameName : Gamename,
                    gameLogo : gameLogo, 
                    gameScreenShotUrl : screenshot, 
                    gamePreviewVideoUrl : previewVideo,
                    gameDescription : Description,
                    gameHowTo : detail, 
                    gamePlatforms: platformsUpdated,
                    gameMinimumAppVersion: version,
                    gameGenre : Genre,
                    isGameScreenOrientationLandscape:  String(Orientation) == "true",
                    match : (matchapi.length != 0 ? matchapi : matchesDetail),
                    isDraft : false,
                    gameGeo: [{country_id: 1, state_id: stateData}]
                    }
                    if(data.gameLogo === "" || data.gameDescription === "" || data.gameHowTo === "" ||  data.gameMinimumAppVersion === ""  || data.gameMinimumAppVersion === null || data.isGameScreenOrientationLandscape === "" ||  data.game_genre ===""){
                        validation();
                    }else{
                        dispatch(EditGameAction(data, props.history))
                        dispatch(deletePlatformAction(Removed_Platform))
                    }
            }
        }

        else{
            const newPlatform = []
            if(platformnew[0].platformName === 'android'){
                newPlatform.push({platformName: "ios", gameAssetBundleUrl: iOSbundle, gameAssetBundleVersion: bundlevesion})
            }else{
                newPlatform.push({platformName: "android", gameAssetBundleUrl: androidbundle, gameAssetBundleVersion: bundlevesion})
            }
            let data ={
                id: gameId,
                // gameName : Gamename,
                gameLogo : gameLogo, 
                gameScreenShotUrl : screenshot, 
                gamePreviewVideoUrl : previewVideo,
                gameDescription : Description,
                gameHowTo : detail, 
                gamePlatforms: newPlatform,
                gameMinimumAppVersion: version,
                gameGenre : Genre,
                isGameScreenOrientationLandscape:  String(Orientation) == "true",
                match : (matchapi.length != 0 ? matchapi : matchesDetail),
                isDraft : false,
                gameGeo: [{country_id: 1, state_id: stateData}]
                }

                if(data.gameLogo === "" || data.gameDescription === "" || data.gameHowTo === "" ||  data.gameMinimumAppVersion === ""  || data.gameMinimumAppVersion === null || data.isGameScreenOrientationLandscape === "" ||  data.game_genre ===""){
                    validation();
                }else{
                    dispatch(EditGameAction(data, props.history))
                    dispatch(deletePlatformAction(Removed_Platform))
                }
        }        

    }



    const Ondraft = () => {

        let result = []
        value.map((e)=>{  
            result.push(e.platformName)
        })

        var platformsUpdated = [];
        for(var i = 0; i<2 ; i++){
            if(result[i] === 'android'){
                var a = {platformName: "android", gameAssetBundleUrl: androidbundle, gameAssetBundleVersion: bundlevesion}
                platformsUpdated.push(a) 
            }else if(result[i] === 'ios'){
                var a = {platformName: "ios", gameAssetBundleUrl: iOSbundle, gameAssetBundleVersion: bundlevesion}
                platformsUpdated.push(a)
            }
        }  
        const gameId = props.location.id
        if(gamesDetail[0].gameName === Gamename){
            platformsUpdated[0].id = platformId
            const data = {  
                id: gameId,
                gameLogo : gameLogo,
                gameScreenShotUrl : screenshot, 
                gamePreviewVideoUrl : (previewVideo === null ? "" : [previewVideo]),
                gameDescription : Description,
                gameHowTo : detail,
                gamePlatforms : platformsUpdated,
                gameMinimumAppVersion: version,
                isGameScreenOrientationLandscape:  Orientation,
                gameGenre : Genre,
                isDraft : true,
                match: (matchapi.length != 0 ? matchapi : matchesDetail),
                gameGeo: [
                    {
                      country_id : 1,
                      state_id : stateData
                    }
                  ]
            }
            if(data.gameName === ""){
                validation();
            }else{
                Object.keys(data).forEach((key) => (data[key] == null || data[key].length === 0) && delete data[key]);

                dispatch(EditGameAction(data, props.history))
            }

        }else{
            const data ={  
                id: gameId,
                gameName : Gamename,
                gameLogo : gameLogo,
                gameScreenShotUrl : screenshot, 
                gamePreviewVideoUrl : (previewVideo === null ? "" : [previewVideo]),
                gameDescription : Description,
                gameHowTo : detail,
                gamePlatforms : platforms,
                gameMinimumAppVersion: version,
                isGameScreenOrientationLandscape:  Orientation,
                gameGenre : Genre,
                isDraft : true,
                match: (matchapi.length != 0 ? matchapi : matchesDetail),
                gameGeo: [
                    {
                      country_id : 1,
                      state_id : stateData
                    }
                  ]
            }
            
            if(data.gameName === ""){
                validation();
            }else{
                Object.keys(data).forEach((key) => (data[key] == null || data[key].length === 0) && delete data[key]);
                dispatch(EditGameAction(data, props.history))
            }
        }
    }
    
    // Input Validation 

    const onChangeGamename = (e) =>{
        if(e.target.value === ""){
            validation()
        }else{
            setGamenameError(false);
            setGamename(e.target.value)
        }
    }

    const onchangeDescription = (e) =>{
        if(e.target.value === ""){
            validation()
        }else{
            setDescriptionError(false);
            setDescription(e.target.value)
        }
    }

    const onchangeDetail = (e) =>{
        if(e.target.value === ""){
            validation()
        }else{
            setdetailError(false);
            setDetail(e.target.value)
        }
    }

    const onchangeBundleversion = (e) =>{
        if(e.target.value === ""){
            validation()
        }else{
            setBundleError(false);
            setBundleversion(e.target.value)
        }
    }

    const onchangeVersion = (e) =>{
        if(e.target.value === ""){
            validation()
        }else{
            setversionError(false);
            setVersion(e.target.value)
        }
    }

    const onchangeOrientation = (e) =>{
        if(e.target.value === ""){
            validation()
        }else{
            setOrientationError(false);
            setOrientation(e.target.value)
        }
    }

    const onchangeType = (e) =>{
        if(e.target.value === ""){
            validation()
        }else{
            settypeError(false);
            setType(e.target.value)
        }
    }
    
    const onchangeGenre = (e) =>{
        if(e.target.value === ""){
            validation()
        }else{
            setGenreError(false);
            setGenre(e.target.value)
        }
    }

    const onchangeWorld = (e) =>{
        if(e.target.value === ""){
            validation()
        }else{
            setWorldError(false);
            setWorld(e.target.value)
        }
    }

    const [gameObject, setGameObject] = useState()
    const onGameObject = (e) => {
        setGameObject(parseInt(e.target.value))
    }

    const onchangeMinplayers = (e) =>{
        if(e.target.value === ""){
            validation()
        }else{
            setminplayersError(false);
            setMinplayers(e.target.value)
        }
    }

    const onchangeMaxplayers = (e) =>{
        if(e.target.value === ""){
            validation()
        }else{
            setmaxplayersError(false);
            setMaxplayers(e.target.value)
        }
    }
    
    const onchangeDraft = (e) => {
        if(e.target.value === ""){
            validation()
        }else{
            setdrafterror(false);
            setDraft(e.target.value)
        }
    }

    const onchangeMatchName = (e) => {
        if(e.target.value === ""){
            validation();
        }else{
            setMatchNameErr(false)
            setMatchName(e.target.value);
        }
    }

    const onchangeMatchFormat = (e) => {
        if(e.target.value === ""){
            validation();
        }else{
            setMatchFormatErr(false)
            setMatchFormat(e.target.value);
        }
    }

    const onchangeMatchHowTo = (e) => {
        if(e.target.value === ""){
            validation();
        }else{
            setMatchHowToErr(false)
            setMatchHowTo(e.target.value);
        }
    }

    const onchnageMatchOutcomeType = (e) => {
        if(e.target.value === ""){
            validation();
        }else{
            setmatchOutcomeErr(false)
            setMachOutcome(parseInt(e.target.value))
        }
    }

    const onchangeLeaderBoardOutcomeType = (e) => {
        if(e.target.value === ""){
            validation();
        }else{
            setLeaderBoardOutcome(parseInt(e.target.value))
            setLeaderboardOutcomeErr(false)
        }
    }

    // Validation

    const validation = (e) => {

        if(Description === "" || Description === null){
            setDescriptionError(true)
        }

        if(detail === "" || detail === null){
            setdetailError(true)
        }

        if(platform === "" || platform === null){
            setPlatformError(true)
        }

        if(bundlevesion === "" || bundlevesion === null){
            setBundleError(true)
        }

        if(version === "" || version === null){
            setversionError(true)
        }

        if(Orientation === "" || Orientation === null){
            setOrientationError(true)
        }

        if(type === "" || type === null){
            settypeError(true)
        }

        if(World === "" || World === null){
            setWorldError(true)
        }

        if(Genre === "" || Genre === null){
            setGenreError(true)
        }

        if(minplayers === "" || minplayers === null){
            setminplayersError(true)
        }

        if(maxplayers === "" || maxplayers === null){
            setmaxplayersError(true)
        }

        // if(matchName === "" || matchName === null){
        //     setMatchNameErr(true)
        // }

        if(matchFormat === "" || matchFormat === null){
            setMatchFormatErr(true);
        }

        if(matchHowTo === "" || matchHowTo === null){
            setMatchHowToErr(true)
        }


    }

    const [loadingLogo, setLoadingLogo] = useState(false)
    const uploadLogo = async(e) =>{
        setLoadingLogo(true)
        // For Logo Preview
        if(e.target.files[0]) {
            setLogo({
                src: URL.createObjectURL(e.target.files[0]),
                alt: e.target.files[0].name
            });    
        }      
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axiosUpload.get(`${BASE_URL}/upload-url?contentType=image/jpg`)
        setGameLogo(res.data.getUrl);
        var config = {
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
          }
          axiosUpload(config)
          .then(function (response) {
            setLoadingLogo(false)
            toast.success("Logo Uploaded Successfully.")
          })
          .catch(function (error) {

          });
    }
    
    const [videoErrorsize, setVideoSizeError] = useState(false)
    const [loadingVideo, setLoadingVideo] = useState(false)

    const uploadVideo = async (e) =>{
        var fileSize = Math.round((e.target.files[0].size/1024));
        if(fileSize<13000){
            setLoadingVideo(true)
            // Converting Image to base64 and then converting to binary to upload
            var file = e.target.files[0];   
            const file_binary = await convertbase64(file)
            const res = await axiosUpload.get(`${BASE_URL}/upload-url?contentType=octet-stream/mp4`)
            setPreviewvideo(res.data.getUrl)
            var config = {
                method: 'put',
                url: res.data.postUrl,
                data :convertDataURIToBinary(file_binary),
            }
            axiosUpload(config)
            .then(function (response) {
                setLoadingVideo(false)
                toast.success("Video Uploaded Successfully.")
            })
            .catch(function (error) {

            });
            setVideoSizeError(false)
        }else{
            setVideoSizeError(true)
            toast.error("File size is too large")
        }
    }

    const [loadingAndroid, setLoadingAndroid] = useState(false)
    const uploadAndroid = async (e) => {
        // Converting Image to base64 and then converting to binary to upload
        setLoadingAndroid(true)
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axiosUpload.get(`${BASE_URL}/upload-url?contentType=octet-stream`)
        setAndroidBundle(res.data.getUrl)

        var config = {
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
          }
          axiosUpload(config)
          .then(function (response) {
            setLoadingAndroid(false)
            toast.success("Files Uploaded Successfully.")
          })
          .catch(function (error) {

          });
    }

    const [loadingIOS, setLoadingIOS] = useState(false)
    const uploadiOS = async(e) => {
        // Converting Image to base64 and then converting to binary to upload
        setLoadingIOS(true)
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)

        const res = await axiosUpload.get(`${BASE_URL}/upload-url?contentType=octet-stream`)
        setIosBundle(res.data.getUrl)

        var config = {
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
          }
          axiosUpload(config)
          .then(function (response) {
            toast.success("Files Uploaded Successfully.")
            setLoadingIOS(false)
          })
          .catch(function (error) {

          });  
    }


        // To Convert Base64 to Binary
        function convertDataURIToBinary(dataURI) {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));
          
            for(var i = 0; i < rawLength; i++) {
              array[i] = raw.charCodeAt(i);
            }
            return array;
        }
    
    
        // To Convert file into base64 format
        const convertbase64 = (file) => {
            return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
            })
        }

    const toggleAccordian = () => {
        setShowbtn(false);
       if(showAccordian == true){
            setShowAccordian(false)
       }else{
            setShowAccordian(true)
       }
    }

    const onChangePodiumRanks = (e) => {
        setPodiumWeigh(e.target.value); 
        const result = [];
        for(var i = 1; i<=e.target.value;i++){
            result.push(i)
        }
        setresult(result)
    }

    const [statemodel, setStateModel] = useState(false);

    const showModel = () => {
        setStateModel(!statemodel)
    }

  let result_ss = screenshot
  const [loadingSS, setLoadingSS] = useState(false)
  
  const changeSS = (e) => {
      e.forEach(async (element) => {
        setLoadingSS(true)
        const file_binary = await convertbase64(element)
        const res = await axiosUpload.get(`${BASE_URL}/upload-url?contentType=image/jpeg`)
        let k = res.data.getUrl
        result_ss.push(k)
        var config = {
          method: 'put',
          url: res.data.postUrl,
          data :convertDataURIToBinary(file_binary),
        }
        axiosUpload(config)
        .then(function (response) {
            setLoadingSS(false)
        })
        .catch(function (error) {
            
        });
      });
      setScreenshot(result_ss);
}
  
  useEffect(() => {
    const data = {
        gameFilters : {
            id : [props.location.id],
            pagination : {

            }
        }
      }
    //   dispatch(GameByIdAction(data));
    dispatch(GameByIdAction(data));

  }, []);


  const[matchapi, setMatchApi] = useState([])
  const [platformnewdata, setPlatformNewData] = useState();
  const [stateNew, setStateNew] = useState('')
  const [platformId, setPlatformId] = useState()
  useEffect(() => {
      if(props.location.id){
          const id = props.location.id;
          const data = {
            gameFilters : {
                id : [id],
                pagination : {

                }
            }
          }
           axios.post(BASE_URL + GET_GAME, data)
          .then(res=>{
              if(res.data.gameData[0].gamePlatforms.length != 0){
                setGamename(res.data.gameData[0].gameName)
                setGameLogo(res.data.gameData[0].gameLogo);
                setScreenshot(res.data.gameData[0].gameScreenShotUrl)
                setPreviewvideo(res.data.gameData[0].gamePreviewVideoUrl)
                setDescription(res.data.gameData[0].gameDescription);
                setDetail(res.data.gameData[0].gameHowTo);
                setPlatformNew(res.data.gameData[0].gamePlatforms)
                setValue(res.data.gameData[0].gamePlatforms)
                setPlatformNewData(res.data.gameData[0].gamePlatforms)
                setVersion(res.data.gameData[0].gameMinimumAppVersion)
                setOrientation(res.data.gameData[0].isGameScreenOrientationLandscape)
                setWorld(res.data.gameData[0].gamesWorld[0].id)
                setGenre(res.data.gameData[0].gameGenre)
                setMatchApi(res.data.gameData[0].matches)
                setBundleversion(res.data.gameData[0].gamePlatforms[0].gameAssetBundleVersion)
                setCountryNew(res.data.gameData[0].gameGeo)
                setStateNew(res.data.gameData[0].gameGeo)
                
                if(res.data.gameData[0].gamePlatforms.length > 1){
                    if(res.data.gameData[0].gamePlatforms[0].platformName === "android"){
                        setAndroidBundle(res.data.gameData[0].gamePlatforms[0].gameAssetBundleUrl)
                    }else if(res.data.gameData[0].gamePlatforms[1].platformName === "android"){
                        setAndroidBundle(res.data.gameData[0].gamePlatforms[1].gameAssetBundleUrl)
                    }
    
                    if(res.data.gameData[0].gamePlatforms[0].platformName === "ios"){
                        setIosBundle(res.data.gameData[0].gamePlatforms[0].gameAssetBundleUrl)
                    }else if(res.data.gameData[0].gamePlatforms[1].platformName === "ios"){
                        setIosBundle(res.data.gameData[0].gamePlatforms[1].gameAssetBundleUrl)
                    }
                }else{
                    if(res.data.gameData[0].gamePlatforms[0].platformName === "android"){
                        setAndroidBundle(res.data.gameData[0].gamePlatforms[0].gameAssetBundleUrl)
                    }else if(res.data.gameData[0].gamePlatforms[0].platformName === "ios"){
                        setIosBundle(res.data.gameData[0].gamePlatforms[0].gameAssetBundleUrl)
                    }
                }
                setPlatformId(res.data.gameData[0].gamePlatforms[0].id)

              }else{
                setGamename(res.data.gameData[0].gameName)
                setGameLogo(res.data.gameData[0].gameLogo);
                setScreenshot(res.data.gameData[0].gameScreenShotUrl)
                setPreviewvideo(res.data.gameData[0].gamePreviewVideoUrl)
                setDescription(res.data.gameData[0].gameDescription);
                setPlatformId(res.data.gameData[0].gamePlatforms[0].id)
                setDetail(res.data.gameData[0].gameHowTo);
                setVersion(res.data.gameData[0].gameMinimumAppVersion)
                setOrientation(res.data.gameData[0].isGameScreenOrientationLandscape)
                // setWorld(res.data.gameData[0].gamesWorld[0].id)
                setPlatformNew(res.data.gameData[0].gamePlatforms)
                setValue(res.data.gameData[0].gamePlatforms)
                setGenre(res.data.gameData[0].gameGenre)
                setMatchApi(res.data.gameData[0].matches)
              }
          }).catch(err=>{
              
          })
      }
  }, [])


    let gamesDetail = useSelector((state)=>{ 
        return state.game.gameDetail
    })
    
    const onState = (e) => {
        alert(e.target.value)
    }

    const [deleteShow, setDeleteShow] = useState(false);

    const handleDeleteClose = () => {
      setDeleteShow(false)
    }
  
    const handleDeleteShow = () => {
      setDeleteShow(true)
    }
    
    const [stateData, setStateData] = useState([])

    const a = (data) =>{
      setStateData(data)
    }

    const Redirect = () => {
        history.push('/games');
        window.location.reload();
    }

    const back = () => {
        history.push('/games');
        window.location.reload();
    }

    const[win, setWin] = useState("")
    const WinPoints = (e) =>{
        setWin(parseInt(e.target.value))
    }
    const[draw, setDraw] = useState("")
    const DrawPoints = (e) =>{
        setDraw(parseInt(e.target.value))
    }
    const[loss, setLoss] = useState("")
    const LossPoints = (e) =>{
        setLoss(parseInt(e.target.value))
    }

    const [state, setState] = useState({rank: "", points: ""})
    const [results, setResult] = useState([])
    const [point, setPoint] = useState("")
    
    const onChangePPSALL = (e) => {
        const ranks = [];
        for(var i = 1; i<= e.target.value;i++){
            ranks.push({rank: i, points:null})
        }
        setResult(ranks)
    }
    const handleChange = (i, value) => {
        const points = parseInt(value)
        results[i].points = points
        setResult(results);
    }

    const [matchIdnew, setMatchId] = useState("")
    const [showbtn, setShowbtn] = useState(false)

    const editMatch = (id) => {
        setShowbtn(true);
        setMatchId(id);
        setShowAccordian(true);
        const data = {
            matchId : id
        }
        axios.post(`${BASE_URL}/matches/getMatch`, data)
        .then(res=>{
            setMatchName(res.data.matchObj.matchName)
            setMatchFormat(res.data.matchObj.matchFormatTypeMasterId)
            setMinplayers(res.data.matchObj.matchMinPlayers)
            setMaxplayers(res.data.matchObj.matchMaxPlayers)
            setMatchHowTo(res.data.matchObj.matchHowTo)
            setMachOutcome(res.data.matchObj.matchOutcomeTypeMasterId)
            setLeaderBoardOutcome(res.data.matchObj.leaderboardOutcomeTypeMasterId)
        })
    }

    const onEditmatch = () => {
        setShowbtn(true);
        const match = {
                id:  gamesDetail[0].id,
                match : [
                  {
                    id: matchIdnew,
                    matchName: matchName,
                    matchFormatTypeMasterId: matchFormat,
                    matchOutcomeTypeMasterId: matchOutcome,
                    leaderboardOutcomeTypeMasterId: LeaderboardOutcome,
                    matchMinPlayers: minplayers,
                    matchMaxPlayers: maxplayers,
                    matchHowTo: matchHowTo,
                    leaderBoardWeightingSystem: [],
                  }
            ]
        }
        dispatch(EditGameAction(match))
        setShowAccordian(false)
        setMatchName('')
        setMatchFormat('')
        setMinplayers('')
        setMaxplayers('')
        setMatchHowTo('')
        setMachOutcome('')
        setLeaderBoardOutcome('')
    }

    const onCancel = () => {
        setShowAccordian(false)
        setShowbtn(false)
        setMatchName('')
        setMatchFormat('')
        setMinplayers('')
        setMaxplayers('')
        setMatchHowTo('')
        setMachOutcome('')
        setLeaderBoardOutcome('')
    }

    const deleteMatch = (id) => {
        const matchesDetail = {
            id: gamesDetail[0].id,
            match: [
                    {
                        id: id ,
                        archive:true
                    }
                ]
        }
        dispatch(EditGameAction(matchesDetail))
    }

    const fixedOptions = [];
    const [value, setValue] = useState([...fixedOptions, platforms[0]]);
    // const [value, setValue] = useState([...fixedOptions, platforms[1]]);

    const [exclude, setExclude] = useState([])
    const statesShow = (data) => {
        setExclude(data)
    }
    
    const onDraftSave = () => {
        let result = []
        value.map((e)=>{  
            result.push(e.platformName)
        })
        var platforms = [];
        for(var i = 0; i< result.length; i++){
            if(result[i] === "android"){
                var a = {platformName: "android", gameAssetBundleUrl: androidbundle, gameAssetBundleVersion: bundlevesion}
                platforms.push(a)
            }else if(result[i] === "ios"){
                var a = {platformName: "ios", gameAssetBundleUrl: iOSbundle, gameAssetBundleVersion: bundlevesion}
                platforms.push(a)
            }
        }

        if(gamesDetail[0].gameName == Gamename){
            platforms[0].id = platformId
            let data ={
                id: props.location.id,
                gameLogo : gameLogo, 
                gameScreenShotUrl : screenshot, 
                gamePreviewVideoUrl : [previewVideo],
                gameDescription : Description,
                gameHowTo : detail, 
                gamePlatforms: platforms,
                gameMinimumAppVersion: version,
                gameGenre : Genre,
                isGameScreenOrientationLandscape:  String(Orientation) == "true",
                match : (matchapi.length != 0 ? matchapi : matchesDetail),
                isDraft : false,
                gameGeo: [{country_id: 1, state_id: stateData}]
            }
            dispatch(EditGameAction(data, props.history))
        }else{
            platforms[0].id = platformId
            let data ={
                id: props.location.id,
                gameName : Gamename,
                gameLogo : gameLogo, 
                gameScreenShotUrl : screenshot, 
                gamePreviewVideoUrl : [previewVideo],
                gameDescription : Description,
                gameHowTo : detail, 
                gamePlatforms: platforms,
                gameMinimumAppVersion: version,
                gameGenre : Genre,
                isGameScreenOrientationLandscape:  String(Orientation) == "true",
                match : (matchapi.length != 0 ? matchapi : matchesDetail),
                isDraft : false,
                gameGeo: [{country_id: 1, state_id: stateData}]
            }
            dispatch(EditGameAction(data, props.history))
        }

    }

    const CancelPage = () => {
        localStorage.removeItem('books')
        history.push('/games')
    }

    if(gamesDetail && gamesDetail.length && platformnew){
        return (
            <div>
                <ToastContainer />
                
                <div className="container-fluid mt-3">
                    <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                        <Row>
                            <Col lg={12}>
                            <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                                <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                                <Breadcrumb.Item href='#games'>Games</Breadcrumb.Item>
                                <Breadcrumb.Item>Edit Game</Breadcrumb.Item>
                                <Breadcrumb.Item className="text-dark" active>{Gamename}</Breadcrumb.Item>
                            </Breadcrumb>
                            </Col>
                        </Row>
                    </div>
                    <Row className="edit-game">
                        <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                            <Card  border="light" className="bg-white shadow-sm mb-4">
                            <Card.Body>
                                <Row className="p-3">
                                    <Col>
                                        <Row>
                                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                    <Row className="mb-3" xl={12} lg={12} md={12} sm={12} xs={12}>
                                                        <Col md={10}>
                                                            <p className="game-heading">Edit Game</p>
                                                        </Col>
                                                    </Row>
                                                <Row className="mb-3">
                                                    <Col>
                                                        <h6>{Game_Id}: {gamesDetail[0].id}</h6>
                                                    </Col>
                                                </Row>
                                                <form>
                                                    <Row>
                                                        <Col className="mb-3" lg={12} md={12} sm={12}>
                                                            <label for="Name">{Game_Name}</label>
                                                            <input type="text" className="form-control input_bg" value={Gamename} onChange={(e)=>{setGamename(e.target.value)}}></input>
                                                            {GamenameError ? (
                                                                <span style={{color : "red"}}>*Game Name is Required</span>
                                                            ) : ("")}
                                                        </Col>
                                                        {/* <Col className="mb-3" lg={6} md={6} sm={12}>
                                                            <Row>
                                                                <label for="logo">{Game_Logo}</label>
                                                            </Row>
                                                            <Row>
                                                                <Col lg={2} md={2} sm={12} className="mx-2">
                                                                    <Row className="align-items-center">
                                                                        <Col><img className="img-fluid" src={gamesDetail[0].gameLogo != "" ? gamesDetail[0].gameLogo : ""} alt={alt} style={{maxWidth: "70px", maxHeight: "70px"}}></img></Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col lg={8} md={8} sm={12} className="mx-1">
                                                                    <input type="file" className="form-control" onChange={(e)=>{uploadLogo(e)}}/>
                                                                    {loadingLogo == true ? 
                                                                    <span class="flex_display">
                                                                        <span className="Loading_Heading">Uploading</span>
                                                                        <span className="Loading_One"></span>
                                                                        <span className="Loading_Two"></span>
                                                                        <span className="Loading_Three"></span>
                                                                    </span>
                                                                    : ""}
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-2">
                                                                <Col>
                                                                    <span style={{fontSize: "11px"}}>Accepted file typs: JPEG and PNG Only</span> <span style={{fontSize: "11px"}}>&amp;</span> <span style={{fontSize: "11px"}}>Resolution: 512px*512px</span>
                                                                </Col>
                                                            </Row>
                                                        </Col> */}
                                                    </Row>
                                                    
                                                    <Row>
                                                        <Col className="mb-3" lg={12} md={12} sm={12}>
                                                            <label for="Description">{Game_Description}</label>
                                                            <textarea className="form-control input_bg" defaultValue={gamesDetail[0].gameDescription!= "" ? gamesDetail[0].gameDescription : ""} onChange={(e)=>{setDescription(e.target.value)}} rows="4"/>
                                                            {DescriptionError ? (
                                                                <span style={{color : "red"}}>*Game Description is Required</span>
                                                            ) : ("")}
                                                        </Col>
                                                    </Row>
        
                                                    <Row>
                                                        <Col className="mb-3" lg={12} md={12} sm={12}>
                                                            <label for="How_to">{Game_How_to}</label>
                                                            <textarea type="text" className="form-control input_bg" defaultValue={gamesDetail[0].gameHowTo != "" ? gamesDetail[0].gameHowTo : ""} onChange={(e)=>{setDetail(e.target.value)}} rows="4"/>
                                                            {detailError ? (
                                                                <span style={{color : "red"}}>*Game Detail is Required</span>
                                                            ) : ("")}
                                                        </Col>
                                                    </Row>
        
                                                    {/* <Row>
                                                        <Col className="mb-3" lg={6} md={6} sm={12}>
                                                            <label for="video">{Preview_Video}</label> <br />
                                                            <span>{gamesDetail[0].gamePreviewVideoUrl && gamesDetail[0].gamePreviewVideoUrl.length !=0 ? gamesDetail[0].gamePreviewVideoUrl && gamesDetail[0].gamePreviewVideoUrl.map((e)=>{return(<a href={e} style={{fontSize: '10px'}}>check video</a>)}) : ""}</span>
                                                            <input type="file" accept="video/*" className="form-control" onChange={(e)=>{uploadVideo(e)}}/>
                                                            {videoErrorsize === true ?  (<div> <span style={{color : "red"}}>*Upload a video less than 10 Mb</span> </div>) : ""}
                                                            {loadingVideo == true ? 
                                                            <span class="flex_display">
                                                                <span className="Loading_Heading">Uploading</span>
                                                                <span className="Loading_One"></span>
                                                                <span className="Loading_Two"></span>
                                                                <span className="Loading_Three"></span>
                                                            </span>
                                                            : ""}
                                                            <span style={{fontSize: "11px", lineHeight: "5px"}}>Accepted file typs: MP4 Only</span> <br />
                                                            <span style={{fontSize: "11px", lineHeight: "5px"}}>Resolution : Portrait: 1080x1920 &amp; Landscape: 1920x1080</span>
                                                        </Col>
                                                        <Col className="mb-3" lg={6} md={6} sm={12}>
                                                            <label for="">{Game_Screenshots}</label> <br />
                                                            <span className="mr-2" style={{fontSize: '10px', fontWeight: 'bold'}}>Previosly Uploaded Images</span>
                                                            <span>{gamesDetail[0].gameScreenShotUrl && gamesDetail[0].gameScreenShotUrl.length !=0 ? gamesDetail[0].gameScreenShotUrl && gamesDetail[0].gameScreenShotUrl.map((e, i)=>{return(<a href={e} style={{fontSize: '10px'}}>&nbsp; {i}&#93; check image &nbsp;</a>)}) : ""}</span>
                                                            <DropzoneArea canCancel={true} acceptedFiles={['image/*']} showAlerts={['error', 'success', 'info']}  alertSnackbarProps={{anchorOrigin:{ vertical: 'top', horizontal: 'right' }}} dropzoneText="Drop files here to upload" filesLimit={50} onChange={(e)=>{changeSS(e)}}></DropzoneArea>
                                                            {loadingSS == true ?
                                                            <span class="flex_display">
                                                                <span className="Loading_Heading">Uploading</span>
                                                                <span className="Loading_One"></span>
                                                                <span className="Loading_Two"></span>
                                                                <span className="Loading_Three"></span>
                                                            </span>
                                                            : ""}
                                                        </Col>
                                                    </Row> */}
        
                                                    <Row className="align-items-center">
                                                        {platformnew && platformnew.length != 0 ?
                                                        <Col className="mb-3" lg={6} md={6} sm={12}>
                                                            <label for="platform" className="mb-2">{Game_Platform}</label>
                                                            <Autocomplete multiple disableCloseOnSelect 
                                                            getOptionSelected={(option, value) => option.platformName === value.platformName}
                                                            filterSelectedOptions
                                                            options={platforms} getOptionLabel={(option)=> option.platformName}
                                                            // value={value}
                                                            defaultValue={platformnew.length === 1 && platformnew[0].platformName === 'android' ? [platforms[0]] : platformnew.length === 1 && platformnew[0].platformName === 'ios' ?  [platforms[1]] : platformnew.length === 2 ? [platforms[0], platforms[1]]  : ""}
                                                            onChange={(event, newValue) => {
                                                                setValue([
                                                                ...fixedOptions,
                                                                ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
                                                                ]);
                                                            }}
                                                            renderOption={(option, {selected})=>(
                                                                <React.Fragment>
                                                            <Checkbox icon={icon}
                                                                checkedIcon={checkedIcon} style={{marginLeft: "10px"}}
                                                                checked={selected}
                                                            />
                                                            {option.platformName}
                                                        </React.Fragment>  
                                                            )}
                                                            renderInput={(params)=>(<TextField {...params} variant="outlined" />)} />
                                                                                            
                                                            {platformError ? (
                                                                <span style={{color : "red"}}>*Platform is Required</span>
                                                            ) : ("")}
                                                        </Col> :
                                                        !platformnew || platformnew.length === 0 ?
                                                        <Col className="mb-3" lg={6} md={6} sm={12}>
                                                            <label for="platform" className="mb-2">{Game_Platform}</label>
                                                            <Autocomplete multiple disableCloseOnSelect 
                                                            filterSelectedOptions id="tags-outlined"
                                                            options={platforms} getOptionLabel={(option)=> option.platformName}
                                                            onChange={(event, newValue) => {
                                                                setValue([
                                                                ...fixedOptions,
                                                                ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
                                                                ]);
                                                            }}
                                                            renderOption={(option, {selected})=>(
                                                                    <React.Fragment>
                                                                        {option.platformName}
                                                                    </React.Fragment>  
                                                            )}
                                                            renderInput={(params)=>(<TextField {...params} variant="outlined" />)} />
                                                                                                    
                                                            {platformError ? (
                                                                <span style={{color : "red"}}>*Platform is Required</span>
                                                            ) : ("")}
                                                        </Col> : ""}
                                                        <Col className="pt-2" lg={6} md={6} sm={12}>
                                                            <label for="Orientation">{Screen_Orientation}</label>
                                                            <input class="form-check-input mx-2 input_bg" value="true" type="radio" name="orientation" onChange={(e)=>{onchangeOrientation(e)}}/>
                                                            <label>Landscape</label>
                                                            <input class="form-check-input mx-2 input_bg" value="false" type="radio" name="orientation" onChange={(e)=>{onchangeOrientation(e)}}/>
                                                            <label>Potrait</label>   
                                                            {OrientationError ? (
                                                                <div><span style={{color : "red"}}>*Orientation is Required</span></div>
                                                            ) : ("")}
                                                        </Col>
                                                    </Row>
                                            
                                                    <Row>
                                                            {value.length === 1 && value[0].platformName === 'android' ?
                                                            <Col lg={6} md={6}>
                                                                <label>Android Asset Bundle</label> <br />
                                                                <input type="file" className="form-control input_bg" onChange={(e)=>{uploadAndroid(e)}} />
                                                                {loadingAndroid == true ? 
                                                                <span class="flex_display">
                                                                    <span className="Loading_Heading">Uploading</span>
                                                                    <span className="Loading_One"></span>
                                                                    <span className="Loading_Two"></span>
                                                                    <span className="Loading_Three"></span>
                                                                </span>
                                                                : ""}
                                                            </Col>
                                                            : ""}
                                                            {/* <span><a>Download File</a></span> */}
                                                            {value.length === 1 && value[0].platformName === 'ios' ? 
                                                            <Col lg={6} md={6}>
                                                                <label>iOS Asset Bundle</label> <br />
                                                                <input type="file" className="form-control input_bg" onChange={(e)=>{uploadiOS(e)}} />
                                                            {loadingIOS == true ? 
                                                            <span class="flex_display">
                                                                <span className="Loading_Heading">Uploading</span>
                                                                <span className="Loading_One"></span>
                                                                <span className="Loading_Two"></span>
                                                                <span className="Loading_Three"></span>
                                                            </span>
                                                            : ""}
                                                            </Col>
                                                            : ""}
                                                            {/* <span><a>Download File</a></span> */}
                                                            {value.length === 2 && [value[0].platformName === 'android' || value[0].platformName === 'android' ] ?
                                                            <Col lg={6} md={6}>
                                                                <label>Android Asset Bundle</label> <br />
                                                                <input type="file" className="form-control input_bg" onChange={(e)=>{uploadAndroid(e)}} />
                                                                {loadingAndroid == true ? 
                                                                <span class="flex_display">
                                                                    <span className="Loading_Heading">Uploading</span>
                                                                    <span className="Loading_One"></span>
                                                                    <span className="Loading_Two"></span>
                                                                    <span className="Loading_Three"></span>
                                                                </span>
                                                                : ""}
                                                            </Col>
                                                            : ""}
                                                            {/* <span><a>Download File</a></span> */}
                                                            {value.length === 2 && [value[0].platformName === 'ios' || value[0].platformName === 'ios'] ? 
                                                            <Col lg={6} md={6}>
                                                                <label>iOS Asset Bundle</label> <br />
                                                                <input type="file" className="form-control input_bg" onChange={(e)=>{uploadiOS(e)}} />
                                                                {loadingIOS == true ? 
                                                                <span class="flex_display">
                                                                    <span className="Loading_Heading">Uploading</span>
                                                                    <span className="Loading_One"></span>
                                                                    <span className="Loading_Two"></span>
                                                                    <span className="Loading_Three"></span>
                                                                </span>
                                                                : ""}
                                                            </Col>
                                                        : ""}
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <Row>
                                                                <Col>
                                                                    <Row>
                                                                        {platformnew && platformnew.length === 1 && platformnew[0].platformName === 'android' ? <div><span style={{fontSize : "11px"}}><a href={platformnew[0].gameAssetBundleUrl}>Download File (android)</a></span><br /> <span style={{fontSize : "11px", fontStyle: "italic", fontWeight: "bold"}}>Last Updated On : {moment(gamesDetail[0].updatedAt).tz('Asia/Kolkata').format().slice(0,16)}</span></div> : platformnew && platformnew.length === 1 && platformnew[0].platformName === 'ios' ? <div><span style={{fontSize : "11px"}}><a href={platformnew[0].gameAssetBundleUrl}>Download File (ios)</a></span> <br /> <span style={{fontSize : "11px", fontStyle: "italic", fontWeight: "bold"}}>Last Updated On : {moment(gamesDetail[0].updatedAt).tz('Asia/Kolkata').format().slice(0,16)}</span></div>  : ""}
                                                                    </Row>
                                                                    
                                                                    <Row>
                                                                        <Col>
                                                                            {platformnew && platformnew.length === 2 && [platformnew[0].platformName === 'android' || platformnew[1].platformName === 'android'] ? <div><span style={{fontSize : "11px"}}><a href={platformnew[0].platformName === 'android' ? platformnew[0].gameAssetBundleUrl : platformnew[1].platformName === 'android' ? platformnew[1].gameAssetBundleUrl: ""}>Download File (Android)</a></span> <br /> <span style={{fontSize : "11px", fontStyle: "italic", fontWeight: "bold"}}>Last Updated On : {moment(gamesDetail[0].updatedAt).tz('Asia/Kolkata').format().slice(0,16)}</span></div> : ""}
                                                                        </Col>
                                                                        <Col>
                                                                            {platformnew && platformnew.length === 2 && [platformnew[0].platformName === 'ios' || platformnew[1].platformName === "ios"] ? <div><span style={{fontSize : "11px"}}><a href={platformnew[0].platformName === 'ios' ? platformnew[0].gameAssetBundleUrl : platformnew[1].platformName === 'ios' ? platformnew[1].gameAssetBundleUrl: ""}>Download File (ios)</a></span> <br /> <span style={{fontSize : "11px", fontStyle: "italic", fontWeight: "bold"}}>Last Updated On: {moment(gamesDetail[0].updatedAt).tz('Asia/Kolkata').format().slice(0,16)}</span></div> : ""}
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                    <Row className='mt-2'>
                                                        <Col className="mb-3" lg={6} md={6} sm={12}>
                                                            <label for="BundleVersion">{Asset_Bundle_Version}</label>
                                                            <input type="text" className="form-control input_bg" defaultValue={gamesDetail[0].gamePlatforms.length != 0 ? gamesDetail[0].gamePlatforms[0].gameAssetBundleVersion : null} onChange={(e)=>{setBundleversion(e.target.value)}}/>
                                                            {bundleError ? (
                                                                <span style={{color : "red"}}>*Bundle Version is Required</span>
                                                            ) : ("")}
                                                        </Col>
                                                        <Col className="mb-3" lg={6} md={6} sm={12}>
                                                            <label for="AppVersion">{Minimum_App_Version}</label>
                                                            <input type="text" className="form-control input_bg" defaultValue={gamesDetail[0].gameMinimumAppVersion != null ? gamesDetail[0].gameMinimumAppVersion : "-"} onChange={(e)=>{setVersion(e.target.value)}}/>
                                                            {versionError ? (
                                                                <span style={{color : "red"}}>*App Version is Required</span>
                                                            ) : ("")}
                                                        </Col>
                                                    </Row>
        
                                                    <Row>
                                                        <Col className="mb-3" lg={6} md={6} sm={12}>
                                                            <label for="Genre">{Game_Genre}</label>
                                                            <input type="text" className="form-control input_bg" defaultValue={gamesDetail[0].gameGenre != 0 ? gamesDetail[0].gameGenre : "-"} onChange={(e)=>{setGenre(e.target.value)}}/>
                                                            {GenreError ? (
                                                                <span style={{color : "red"}}>*Genre is Required</span>
                                                            ) : ("")}
                                                        </Col>
                                                        <Col className="mb-3" lg={6} md={6} sm={12}>
                                                            <label for="World">{Game_World}</label>
                                                            <select className="form-control input_bg" onChange={(e)=>{onchangeWorld(e)}}>
                                                                <option selected="selected">{gamesDetail[0].gamesWorld.length !=0 ? gamesDetail[0].gamesWorld[0].gameWorldName : ""}</option>
                                                                {GameworldData && GameworldData.map((e)=>{
                                                                return(
                                                                    <option key={e.id} value={e.id}>{e.gameWorldName}</option>
                                                                )
                                                            })}
                                                            </select>
                                                            {WorldError ? (
                                                                <span style={{color : "red"}}>*World is Required</span>
                                                            ) : ("")}
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col lg={6} md={6} sm={12}>
                                                            <label>Game Object Id</label>
                                                            <input type='text' disabled className="form-control input_bg" defaultValue={gamesDetail[0].gameObjectId != null ? gamesDetail[0].gameObjectId  : 'null'} onChange={(e)=>{onGameObject(e)}} />
                                                        </Col>
                                                    </Row>
                                                    
                                                    {gamesDetail[0].matches.length !=0 ? 
                                                        <Row className="mt-3 mb-1 container">
                                                            <Col sm={12} md={12} lg={12}>
                                                                <h6>{Matches_Created}</h6>
                                                            </Col>
                                                        </Row>
                                                    :""}
                                                    {gamesDetail[0].matches.length !=0 ? 
                                                    <Row className="mb-3">
                                                        <Col style={{overflowX : "scroll"}}>
                                                            <table class="table table-hover table-custom">
                                                                <thead className="table-head">
                                                                    <tr>
                                                                        <th className="head-border-left" scope="col">Match Name</th>
                                                                        <th scope="col">Match Format</th>
                                                                        <th scope="col">Min Players</th>
                                                                        <th scope="col">Max Players</th>
                                                                        <th scope="col">Match Outcome</th>
                                                                        <th scope="col">Leaderboard Outcome</th>
                                                                        <th className="head-border-right" scope="col">Actions </th>
                                                                    </tr>
                                                                </thead>
                                                                {gamesDetail && gamesDetail[0].matches.length !=0 && gamesDetail[0].matches.map((i)=>{
                                                                    if(i.archive === false){
                                                                        return(
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th scope="row">{i.matchName}</th>
                                                                                    <td>{i.matchFormatTypeMasterId === 1 ? "Single Player" : i.matchFormatTypeMasterId === 2 ? "Multiplayer" : i.matchFormatTypeMasterId === 3 ? "Multiplayer Team" : ""}</td>
                                                                                    <td>{i.matchMinPlayers}</td>
                                                                                    <td>{i.matchMaxPlayers}</td>
                                                                                    <td>{i.matchOutcomeTypeMasterId === 1 ? "High Score" : i.matchOutcomeTypeMasterId === 2 ? "Time Trail" :  i.matchOutcomeTypeMasterId === 3 ? "Win/Loss/Draw" :  i.matchOutcomeTypeMasterId === 4 ? "Podium Finish" :  i.matchOutcomeTypeMasterId === 5 ? "Winnings Collected"  : ""}</td>
                                                                                    <td>{i.leaderboardOutcomeTypeMasterId === 1 ? "High Score" : i.leaderboardOutcomeTypeMasterId === 2 ? "Time Trail" : i.leaderboardOutcomeTypeMasterId === 3 ? "Win/Loss/Draw" : i.leaderboardOutcomeTypeMasterId === 4 ? "Podium Finish Weighting System" : i.leaderboardOutcomeTypeMasterId === 5 ? "Winnings Collected"  : ""}</td>
                                                                                    <td>
                                                                                        <div class="form-check form-switch align-items-center">
                                                                                            <input className="form-check-input mt-1" type="checkbox" id="flexSwitchCheckDefault" />
                                                                                            <FontAwesomeIcon className="mx-2" icon={faEdit} style={{color: "grey", cursor: "pointer", fontSize: "20px"}} onClick={()=>{editMatch(i.id)}}/>
                                                                                            <FontAwesomeIcon className="mx-2" icon={faTrashAlt} style={{color: "red", cursor: "pointer", fontSize: "20px"}} onClick={()=>{deleteMatch(i.id)}}/>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                            )
                                                                    }else{
                                                                        
                                                                    }
                                                                })}
                                                            </table>
                                                        </Col>
                                                    </Row> : ""}
        
                                                    <Row className="mb-3 match">
                                                        <Col lg={12} md={6} sm={6}>
                                                            <Row>
                                                                <Col lg={12} md={12} sm={12}>
                                                                    <div className="me-lg-3">
                                                                        <Button className="me-2 accordian-btn add-match"  onClick={()=> toggleAccordian()}>
                                                                            <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                                                                            <span>Create Match</span>
                                                                        </Button>
                                                                        
                                                                    </div>
                                                                </Col>
                                                            </Row>
        
                                                            { showAccordian ? 
                                                                <div>
        
                                                                    <Row className="my-3">
                                                                        <Col lg={6} md={12} sm={12}>
                                                                            <label>{Match_Name}</label>
                                                                            <input type = "text" className="form-control input_bg" defaultValue={matchName} onChange={(e)=>{setMatchName(e.target.value)}}></input>
                                                                            {matchNameErr ? <span style={{color : "red"}}>Match Name is Require</span> : ""}
                                                                        </Col>
                                                                        <Col lg={6} md={12} sm={12}>
                                                                            <label>Select Match Format</label>
                                                                            <select className="form-control input_bg" onChange={(e)=>{setMatchFormat(parseInt(e.target.value))}}>
                                                                                <option value={matchFormat} selected hidden >{matchFormat === 1 ? "Single Player" : matchFormat === 2 ? "Multiplayer" : matchFormat === 3 ? "Multiplayer Team" : "Select Match Format"}</option>
                                                                                {matchFormatData && matchFormatData.map((e)=>{
                                                                                return(
                                                                                    <option key={e.id} value={e.id}>{e.matchFormatName === "single_player" ? "Single Player" : e.matchFormatName === "multi_player" ? "Multiplayer" : e.matchFormatName === "multi_player_team" ? "Multiplayer Team" : "No Data"}</option>
                                                                                )
                                                                            })}
                                                                            </select>
                                                                            {matchFormatErr ? <span style={{color : "red"}}>Match Format is Require</span> : ""}
                                                                        </Col>
                                                                    </Row>
        
                                                                    {matchFormat  && matchFormat === 2 || matchFormat === 3 ?
                                                                        <div>
                                                                            <Row>
                                                                                <Col className="mb-3" lg={6} md={12} sm={12}>
                                                                                    <label for="MinPlayers">{Min_Players}</label>
                                                                                    <input type="number" min="1" className="form-control input_bg" defaultValue={minplayers} onChange={(e)=>{setMinplayers(parseInt(e.target.value))}} />
                                                                                    {minplayersError ? (
                                                                                        <span style={{color : "red"}}>*Minmum Players is Required</span>
                                                                                    ) : ("")}
                                                                                </Col>
                                                                                <Col className="mb-3" lg={6} md={12} sm={12}>
                                                                                    <label for="MaxPlayers">{Max_Players}</label>
                                                                                    <input type="number" min="1" className="form-control input_bg" defaultValue={maxplayers} onChange={(e)=>{setMaxplayers(parseInt(e.target.value))}} />
                                                                                    {maxplayersError ? (
                                                                                        <span style={{color : "red"}}>*Maximum Players is Required</span>
                                                                                    ) : ("")}
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    : ""}
        
                                                                    <Row className="mb-3">
                                                                        <Col lg={12} md={12} sm={12}>
                                                                            <label>{Match_How_To}</label>
                                                                            <textarea className="form-control input_bg" rows="5" defaultValue={matchHowTo} onChange={(e)=>{setMatchHowTo(e.target.value)}}></textarea>
                                                                            {matchHowToErr ? <span style={{color : "red"}}>Match How To is Require</span> : ""}
                                                                        </Col>
                                                                    </Row>
        
                                                                    <Row className="mb-3">
                                                                        <Col lg={6} md={12} sm={12}>
                                                                            <label>{Match_Outcome_Type}</label>
                                                                            <select className="form-control input_bg" onChange={(e)=>{onchnageMatchOutcomeType(e)}}>
                                                                                <option selected hidden value={matchOutcome}>{matchOutcome === 1 ? "High Score" : matchOutcome === 2 ? "Time Trail" : matchOutcome === 4 ? "Podium Finish" : matchOutcome === 5 ? "Winning Collected" : matchOutcome === 3 ? "Win/Loss/Draw" : ""}</option>
                                                                                {matchOutcomeData && matchOutcomeData.map((e)=>{
                                                                                return(
                                                                                    <option key={e.id} value={e.id}>{e.matchOutcomeName === "high_score" ? "High Score" : e.matchOutcomeName === "time_trail" ? "Time Trail" : e.matchOutcomeName === "podium_finish" ? "Podium Finish" : e.matchOutcomeName === "winnings_collected" ? "Winning Collected" : e.matchOutcomeName === "win_loss_draw" ? "Win/Loss/Draw" : "Select Match Outcome"}</option>
                                                                                )
                                                                            })}
                                                                            </select>
                                                                            {matchOutcomeErr ? <span style={{color : "red"}}>Match Outcome is Require</span> : ""}
                                                                        </Col>
                                                                        <Col lg={6} md={12} sm={12}>
                                                                            <label>{Leaderboard_Outcome_Type}</label>
                                                                            <select className="form-control input_bg" onChange={(e)=>{onchangeLeaderBoardOutcomeType(e)}}>
                                                                                <option value={LeaderboardOutcome} selected hidden>{LeaderboardOutcome=== 1 ? "High Score" : LeaderboardOutcome === 2 ? "Time Trail" : LeaderboardOutcome === 4 ? "Podium Finish Weighting System" : LeaderboardOutcome === 5 ? "Winning Collected" : LeaderboardOutcome === 3 ? "Win/Loss/Draw" : ""}</option>
                                                                                {leaderboardOutcomData && leaderboardOutcomData.map((e)=>{
                                                                                return(
                                                                                    <option key={e.id} value={e.id}>{e.leaderBoardOutComeName === "high_score" ? "High Score" : e.leaderBoardOutComeName === "time_trail" ? "Time Trail" : e.leaderBoardOutComeName === "podium_finish_weighting_system" ? "Podium Finish Weighting System" : e.leaderBoardOutComeName === "winnings_collected" ? "Winning Collected" : e.leaderBoardOutComeName === "win_draw_loss_weighting_system" ? "Win/Loss/Draw" : "Select Leaderboard Outcome"}</option>
                                                                                )
                                                                            })}
                                                                            </select>
                                                                            {LeaderboardOutcomeErr ? <span style={{color : "red"}}>Leaderboard Outcome is Require</span> : ""}
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                    <Row>
                                                                    {matchOutcome === 4 ?
                                                                            <Col lg={6} md={6} sm={6}>
                                                                                <label>{Podium_Rank_Match_Outcome}</label>
                                                                                <input type="number" className="form-control input_bg"></input>
                                                                            </Col>
                                                                    : "" }
                                                                    {LeaderboardOutcome === 4 ?
                                                                            <Col lg={6} md={6} sm={6}>
                                                                                <label>{Podium_Rank_Leaderboard_Outcome}</label>
                                                                                <input type="number" className="form-control input_bg" onChange={(e)=>{onChangePPSALL(e)}}></input>
                                                                            </Col>
                                                                    : "" }
                                                                    {LeaderboardOutcome === 3 ?
                                                                        <Row>
                                                                            <Col>
                                                                                <label>{Win_Points}</label>
                                                                                <input type="number" min = "0" className="form-control input_bg" onChange={(e)=>{WinPoints(e)}} />
                                                                            </Col>
                                                                            <Col>
                                                                                <label>{Draw_Points}</label>
                                                                                <input type="number" min = "0" className="form-control input_bg" onChange={(e)=>{DrawPoints(e)}} />
                                                                            </Col>
                                                                            <Col>
                                                                                <label>{Loss_Points}</label>
                                                                                <input type="number" min = "0" className="form-control input_bg" onChange={(e)=>{LossPoints(e)}} />
                                                                            </Col>
                                                                        </Row>
                                                                    : ""}
                                                                    </Row>
                                                                    
                                                                    {/* {LeaderboardOutcome === 4 && results ? 
                                                                    <Row className="mt-3">
                                                                    <Col lg={6} md={6} sm={6}></Col>
                                                                        <Col lg={6} md={6} sm={6}>
                                                                            <Row>
                                                                                <Col lg={2} md={6} sm={6}><h6>{Rank}</h6></Col>
                                                                                <Col lg={4} md={6} sm={6}><h6>{Points}</h6></Col>
                                                                            </Row>
                                                                        </Col>
                                                                        </Row> : ""}

                                                                    {LeaderboardOutcome === 4 && results ? results.map((e,i)=>{
                                                                    return (
                                                                        <Row className="mt-3">
                                                                            <Col lg={6} md={6} sm={6}></Col>
                                                                            <Col lg={6} md={6} sm={6}>
                                                                                <Row>
                                                                                    <Col lg={2} md={6} sm={6}>{e}</Col>
                                                                                    <Col lg={4} md={6} sm={6}><input type="number" className="form-control"  min="0" onChange={(evt)=>{handleChange(i,evt.target.value)}}/></Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                    }) 
                                                                    : ""} */}

                                                                    {LeaderboardOutcome === 4 && results && results !="" && results != null ? 
                                                                        <Row className="mt-3">
                                                                            <Col lg={12} md={12} sm={12}>
                                                                                <Row>
                                                                                    <Col lg={4} md={6} sm={6}><h6>Starting Rank</h6></Col>
                                                                                    <Col lg={8} md={6} sm={6}><h6>Amount</h6></Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row> : ""}
                                                                        {LeaderboardOutcome === 4 && results ? results.map((e, i)=>{
                                                                            return (
                                                                                <Row key={i} className="mt-3">
                                                                                    <Col lg={12} md={12} sm={12}>
                                                                                        <Row>
                                                                                            <Col lg={4} md={6} sm={6}>{e.rank}</Col>
                                                                                            <Col lg={8} md={6} sm={6}><input type="number" className="form-control input_bg" min="0" onChange={(evt)=>{handleChange(i,evt.target.value)}}/></Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>
                                                                                )
                                                                            }) 
                                                                        : ""}
        
                                                                    <Row className ="my-3">
                                                                        <Col sm={12} md={12} lg={12}>
                                                                            {showbtn === false ? <button type="button" className="btn btn-outline-success" onClick={onSavematch}>Save Match</button>
                                                                            : <button type="button" className= "btn btn-outline-warning" onClick={onEditmatch}>Edit Match</button>}
                                                                            <button type="button" className="btn btn-outline-danger mx-3" onClick={onCancel}>Cancel</button>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </div>
                                                            : ""}
                                                            
                                                        </Col>
                                                    </Row>
        
                                                    <Row>
                                                        <Col lg={6}>
                                                        <label className="mb-1" for="IncludeCountry">{Included_Countries}</label>
                                                            <Autocomplete multiple disableCloseOnSelect     
                                                                options={Countrydata} getOptionLabel={(option)=> option.CountryName}
                                                                defaultValue={[Countrydata[0]]} filterSelectedOptions
                                                                getOptionSelected={(option, value) => option.CountryName === value.CountryName}
                                                                renderOption={(option, {selected})=>(
                                                                <React.Fragment>
                                                                    <Checkbox icon={icon}
                                                                        checkedIcon={checkedIcon} style={{marginLeft: "10px"}}
                                                                        checked={selected}  allowSelectAll={true} onClick={() => handleDeleteShow()}/>
                                                                        {option.CountryName}
                                                                </React.Fragment>  
                                                                
                                                                )}
                                                                renderInput={(params)=>(<TextField {...params} variant="outlined" />)}
                                                            />
                                                            <Row className="my-2">
                                                                {/* {gamesDetail[0].gameGeo.length != 0 ? gamesDetail[0].gameGeo.map((e)=>{return(<Col lg={6} md={6} sm={6} style={{fontSize : "12px"}}>{e.states.stateName}</Col> )}) : ""} */}
                                                                
                                                                {statesList && statesList.map((e)=>{
                                                                    return(
                                                                    stateNew && stateNew.map((i, x)=>{
                                                                        if(e.id === i){
                                                                        return(
                                                                            <Col lg={6} md={6} sm={6} style={{fontSize : "12px"}}>{e.stateName}</Col>
                                                                        )
                                                                        }
                                                                    })
                                                                    )
                                                                })}
                                                                {/* {Object.keys(statesList).filter(index => statesList[index].id != stateNew[index].states.id).map(index=> statesList[index])} */}
                                                                
                                                            </Row>
                                                        </Col>
                                                    </Row>   
                                                    
                                                    <Row>
                                                        <Col className="mb-3 mt-3">
                                                            {gamesDetail[0].isDraft === false ? <button type="button" className="btn btn-success" onClick={Onsave}>Update</button> : gamesDetail[0].isDraft === true ? <button type="button" className="btn btn-success" onClick={onDraftSave}>Update</button> : ""}
                                                            <button className="btn btn-danger mx-4" onClick={CancelPage}>Cancel</button>
                                                            <button type="button" className="btn btn-outline-warning" onClick={Ondraft}>Save As Draft</button>
                                                        </Col>
                                                    </Row>
        
                                                </form>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Body>
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Row className="my-2">
                                            <Col className="mb-3" xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Row className="align-items-center">
                                                    <Col xl={3} lg={3} md={12} sm={12} xs={12} className="mx-2">
                                                        <Row>
                                                            <Col><img className="img-fluid" src={gamesDetail[0].gameLogo != "" ? gamesDetail[0].gameLogo : ""} alt={alt} style={{maxWidth: "70px", maxHeight: "70px"}}></img></Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xl={8} lg={8} md={12} sm={12} xs={12} className="mx-1">
                                                        <Row>
                                                            <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                                                                <label className="right-label-heading">{Game_Logo}</label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl={8} lg={8} md={8} sm={12} xs={12} className='input-file'>
                                                                <input type="file" className='file-input' id='logo' accept="image/jpeg, image/png, .jpeg, .jpg, .png" onChange={(e)=>{uploadLogo(e)}}/>
                                                                <label for='logo' className="label-btn"><FontAwesomeIcon icon={faImage}/> Choose File</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {loadingLogo == true ? 
                                                        <span class="flex_display">
                                                            <span className="Loading_Heading">Uploading</span>
                                                            <span className="Loading_One"></span>
                                                            <span className="Loading_Two"></span>
                                                            <span className="Loading_Three"></span>
                                                        </span>
                                                    : ""}
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col>
                                                        {/* {GameLogoError ? (
                                                            <div><span style={{color : "red"}}>*Upload Game Logo</span> <br /></div>
                                                        ) : ("")} */}
                                                        <span style={{fontSize: "11px"}}>Accepted file typs: JPEG and PNG Only</span> <span style={{fontSize: "11px"}}>&amp;</span> <span style={{fontSize: "11px"}}>Resolution: 512px*512px</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Body>
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className='input-file'>
                                        <Row>
                                            <Col className="mb-3" xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <label for="screenshot" className="right-label-heading">{Game_Screenshots}</label> <br />
                                                <Row className="mb-2">
                                                    {gamesDetail[0].gameScreenShotUrl !="" && gamesDetail[0].gameScreenShotUrl && gamesDetail[0].gameScreenShotUrl.map((e,i)=>{
                                                        return(
                                                        <Col xl={3} lg={3} md={3} sm={4}>
                                                            <img src={e} className='img-responsive my-2' alt='Screenshot'/>
                                                        </Col>
                                                        )
                                                    })}
                                                </Row>
                                                {/* <label for='ss' className="label-btn mb-2"><FontAwesomeIcon icon={faImage}/> Choose File</label> <br /> */}
                                                <DropzoneArea id='ss' canCancel={true} showPreviews={true} showPreviewsInDropzone={false} acceptedFiles={['image/*']} showAlerts={['error', 'success', 'info']}  alertSnackbarProps={{anchorOrigin:{ vertical: 'top', horizontal: 'right' }}} dropzoneText="Drop files to upload" filesLimit={50} onChange={(e)=>{changeSS(e)}}></DropzoneArea>
                                                {/* {screenshotError ? (
                                                    <span style={{color : "red"}}>*Upload the Screenshots</span>
                                                ) : ("")} */}
                                                {loadingSS == true ?
                                                    <span class="flex_display">
                                                        <span className="Loading_Heading">Uploading</span>
                                                        <span className="Loading_One"></span>
                                                        <span className="Loading_Two"></span>
                                                        <span className="Loading_Three"></span>
                                                    </span>
                                                : ""}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Body>
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Row>
                                            <Col xl={12} lg={12} md={12} sm={12} xs={12} className='input-file mb-3'>
                                                <label for="video" className="right-label-heading">{Preview_Video}</label>
                                                <br />
                                                <span style={{fontSize: "11px", lineHeight: "10px"}}>Accepted file typs: MP4 Only</span> <br />
                                                <span style={{fontSize: "11px", lineHeight: "10px"}}>Resolution : Portrait: 1080x1920 &amp; Landscape: 1920x1080</span>
                                                <video src={gamesDetail[0].gamePreviewVideoUrl && gamesDetail[0].gamePreviewVideoUrl != '' ? gamesDetail[0].gamePreviewVideoUrl : ""} controls width={200}/> <br />
                                                <input type="file" id="vidooUpload" accept="video/mp4, video/avi, .mp4, .avi" className="file-input form-control" onChange={(e)=>{uploadVideo(e)}}/>
                                                <label for='vidooUpload' className="label-btn"><FontAwesomeIcon icon={faImage}/> Choose File</label>
                                                {videoErrorsize === true ?  (<div> <span style={{color : "red"}}>*Upload a video less than 10 Mb</span> </div>) : ""}
                                                {loadingVideo == true ? 
                                                    <span class="flex_display">
                                                        <span className="Loading_Heading">Uploading</span>
                                                        <span className="Loading_One"></span>
                                                        <span className="Loading_Two"></span>
                                                        <span className="Loading_Three"></span>
                                                    </span>
                                                : ""}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    </Row>
                </div>
                {/* {statemodel ? <CountryModal addOpen={statemodel} Addtoggle={showModel}/> : ""} */}
                <CountryModal show={deleteShow} onHide={handleDeleteClose} filter={a} ExcludedState={statesShow}></CountryModal>
            </div>
        )
    }
    else{
        return(
            <div className="text-center">
                <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
                {/* <p className="my-5"><p><button className="text-center" type="button" className="btn btn-success mx-4" onClick={Redirect}>To Main Page</button></p></p> */}
            </div>
        )
    }
}

export default EditGame
