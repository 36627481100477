import React from 'react'
import {Row, Col, ButtonGroup, Button, InputGroup, Form, Nav} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretLeft, faCaretRight, faRetweet, faSearch} from '@fortawesome/free-solid-svg-icons';

export default function Bracket(data) {

    return (
            <div className='container'>
                <div>
                    <Row>
                        <Col>
                            
                        </Col>
                    </Row>
                </div>
            </div>
    )
}
