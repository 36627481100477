import React, { useEffect, useState, useRef } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import {Game_Details, Game_Id,Game_Name, Game_Description, Game_How_to, Game_Platform, Asset_Bundle_Version, Minimum_App_Version, Game_Genre, Screen_Orientation, Game_World, Match_Name, Match_Format, Match_How_To, Match_Outcome_Type, Leaderboard_Outcome_Type, Included_Countries, Phase, States, Match_Details, Game_Screenshots} from '../../../constants/LabelConstants'
import '../../css/games.css'
import { Link} from "react-router-dom";
import { Routes } from "../../../routes";
import { Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { clearDocDetailAction } from '../../../redux/action/documentsAction';
import JoditEditor from 'jodit-react';

function DocumentModal({show, onHide}) {

    const editor = useRef(null);
    const dispatch = useDispatch();

    const config = {
        readonly : true,
        activeButtonsInReadOnly : false
    }

    const close = () => {
        dispatch(clearDocDetailAction())
        onHide();
    }

    let DocumentsDetail = useSelector((state) => {
        return state.documents.documentDetail
    })

    if(DocumentsDetail && DocumentsDetail.length && DocumentsDetail.length > 0){
        return (
            <Modal scrollable={true} contentClassName="new_modal" size="lg" centered show={show} onHide={onHide} className='competitiondetail-modal'>
                <Modal.Header>
                    <div className="modal_head">
                        <span className='competitiondetail-heading'>Document Detail</span>
                    </div>
                </Modal.Header>
    
                <Modal.Body className='competitiondetail-body'>
                    <Row className='my-3'>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <label className='my-2'>Created At</label>
                            <input className='form-control' disabled defaultValue={DocumentsDetail[0].createdAt.slice(0,10).split("-").reverse().join("-")} />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <label className='my-2'>Document Name</label>
                            <input className='form-control' disabled defaultValue={DocumentsDetail[0].documentName} />
                        </Col>
                    </Row>

                    <Row className='my-3'>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <label className='my-2'>Updated At</label>
                            <input className='form-control' disabled defaultValue={DocumentsDetail[0].updatedAt.slice(0,10).split("-").reverse().join("-")} />
                        </Col>
                    </Row>

                    <Row className='my-3'>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <label className='my-2'>Document Content</label>
                            <JoditEditor config={config} ref={editor} value={DocumentsDetail[0].documentContent} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='competition-footer'>
                    <Row>
                        <Col>
                            <Button className="btn btn-danger btn -sm mx-2" onClick={close}>Cancel</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }else{
        return(
            <span></span>
        )
    }
    
}

export default DocumentModal