import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faSearch, faPlusCircle, faTrash, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col,InputGroup, Row, Nav, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import { Breadcrumb } from "react-bootstrap";
import { Link , useHistory} from "react-router-dom";
import { Routes } from "../../../routes";
import {useDispatch, useSelector} from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QueryBuilder, { formatQuery } from "react-querybuilder";
import "../style.scss";
import axios from '../../../utlis/interceptor'
import { BASE_URL, GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE, GET_RULE, GET_ALL_OPERATOR } from '../../../resources/APIEndpoints';
import { editRuleAction, getRuleDetailAction } from '../../../redux/action/businessLogicAction';
import customAxios from '../../../utlis/interceptor';

export default function EditDecision(props) {

    const dispatch = useDispatch()
    const history = useHistory()

    const [ruleDetails, setRuleDetails] = useState('')
    const [ruleName, setRuleName] = useState('')
    const [ruleDescription, setRuleDescription] = useState('')
    const [newRule, setNewRule] = useState('')
    const [fields, setFields] = useState('')
    const [showDecision, setShowDecision] = useState(false)
    const [showBtns, setShowBtns] = useState(false)
    const [query, setQuery] = useState()
    const [rules, setRules] = useState("")
    const [initialOperator, setInitialOperatore] = useState()
    const [results, setResults] = useState([])

    // const operators = [
    //     { name: "equal", label: "equal to" },
    //     { name: "notEqual", label: "not equal to" },
    //     { name: "lessThan", label: "less than" },
    //     { name: "greaterThan", label: "greater than" },
    //     { name: "lessThanEqualTo", label: "less than equal to" },
    //     { name: "greaterThanEqualTo", label: "greater than equal to" }
    // ];

    const [operators, setOperators] = useState([])

    const getAllOperator = async() => {
        let getOperator = {
            operatorTypeFilters : {
                pagination : {
                    
                }
            }
        }
        let res = await axios.post(BASE_URL + GET_ALL_OPERATOR, getOperator)
        let initial = []
        for(var i = 0 ; i < res.data.ruleDetails.length; i++){
            initial.push({name : res.data.ruleDetails[i].operatorSymbol , label : res.data.ruleDetails[i].operatorSymbol })
        }
        setOperators(initial)
    }

    const buildClasses = () => {
        return {
          queryBuilder: "qb-container", // Root <div> element
          ruleGroup: "rule-group notification", // <div> containing the RuleGroup
          combinators: "select select-combinator", // <select> control for combinators
          addRule: "btn-add-rule button is-info is-small is-rounded", // <button> to add a Rule
          addGroup: "btn-add-group button is-info is-small is-rounded", // <button> to add a RuleGroup
          removeGroup: "button is-danger is-small is-rounded", // <button> to remove a RuleGroup
          notToggle: "label", // <label> on the "not" toggle
          rule: "rule notification", // <div> containing the Rule
          fields: "select select-field", // <select> control for fields
          operators: "select select-operator", // <select> control for operators
          value: "input input-value is-small", // <input> for the field value
          removeRule: "button is-danger is-small is-rounded" // <button> to remove a Rule
        };
      };


    useEffect(()=>{
        getRuleDetail();
        getAllAttributes();
        getAllOperator();
    }, [])

    const getRuleDetail = async() => {

        let getAllRule = {
            ruleFilters : {
                id : [props.location.id],
                pagination : {
                    offset : 0,
                    limit : 10
                }
            }
        }
        
        let res = await axios.post(BASE_URL + GET_RULE, getAllRule)

        if(res.data.ruleDetails[0].rule.and != undefined && res.data.ruleDetails[0].rule.any == undefined){
            setNewRule(res.data.ruleDetails[0].rule.all)
            setInitialOperatore('and') 
        }else if(res.data.ruleDetails[0].rule.and == undefined && res.data.ruleDetails[0].rule.any != undefined){
            setNewRule(res.data.ruleDetails[0].rule.any)
            setInitialOperatore('any') 
        }
        if(res.data.ruleDetails[0].rule.event){
            setResults(res.data.ruleDetails[0].rule.event.params)
        }
        setRuleDetails(res.data.ruleDetails[0])
        setRuleName(res.data.ruleDetails[0].ruleName)
        setRuleDescription(res.data.ruleDetails[0].ruleDescription)
    }

    const decisionShow = () => {

    setShowBtns(!showBtns)

    let result;
    if(ruleDetails.rule.all != undefined && ruleDetails.rule.any == undefined){
        result = JSON.parse(JSON.stringify(ruleDetails.rule.all))
    }else if(ruleDetails.rule.all == undefined && ruleDetails.rule.any != undefined){
        result = JSON.parse(JSON.stringify(ruleDetails.rule.any))
    }
    // let result = ruleDetail[0].rule.all
    function recusrsive(result){
        for(var i = 0; i < result.length ; i++){
            var current = result[i]
            current.field = current['fact'];
            delete current.fact
            if((current.all || current.rules || current.any)){
                if(current.all){
                    current.rules = current['all']
                    current.combinator = 'and'
                    recusrsive(current.all)
                    delete current.all
                }else{
                    current.rules = current['any']
                    current.combinator = 'or'
                    recusrsive(current.any)
                    delete current.any
                }
            }
        }
    }

    recusrsive(result)

    let initialQuery 

    if(ruleDetails.rule.all != undefined && ruleDetails.rule.any == undefined){
        initialQuery = {
            combinator: 'and',
            rules: result,
        }
    }else if(ruleDetails.rule.all == undefined && ruleDetails.rule.any != undefined){
        initialQuery = {
            combinator: 'or',
            rules: result,
        }
    }

    setQuery(initialQuery)
    setShowDecision(!showDecision)

    // let result = JSON.parse(JSON.stringify(ruleDetails.rule.all))

    // function recusrsive(result){
    //     for(var i = 0; i < result.length ; i++){
    //         var current = result[i]
    //         current.field = current['fact'];
    //         delete current.fact
    //         if((current.all || current.rules || current.any)){
    //             if(current.all){
    //                 current.rules = current['all']
    //                 current.combinator = 'and'
    //                 recusrsive(current.all)
    //                 delete current.all
    //             }else{
    //                 current.rules = current['any']
    //                 current.combinator = 'or'
    //                 recusrsive(current.any)
    //                 delete current.any
    //             }
    //         }
    //     }
    // }

    // recusrsive(result)

    // const initialQuery = {
    //     combinator: 'and',
    //     rules: result,
    // };

    // setQuery(initialQuery)
    // setShowDecision(!showDecision)

    }

    const hideDecision = () => {
        setShowDecision(false)
    }


    const saveDecision = () => {

        if(query.combinator == 'and'){
            setInitialOperatore('and') 
        }else if(query.combinator == 'or'){
            setInitialOperatore('any') 
        }

        var result = JSON.parse(JSON.stringify(query));

        function recursive(result){
            for(var i = 0 ; i < result.length ; i++){
                var current = result[i]
                current.fact = current['field'];
                delete current.field;
                delete current.valueSource
                delete current.id
                if((current.rules) && (current.rules.length) > 0){
                    if(current.combinator == 'and'){
                        current.all = current['rules'];
                        delete current.fact;
                        recursive(current.rules)
                        delete current.rules
                        delete current.combinator
                        delete current.not
                    }else{
                        current.any = current['rules'];
                        delete current.fact;
                        recursive(current.rules)
                        delete current.rules
                        delete current.combinator
                        delete current.not
                    }
                    
                }
            }
        }
        recursive(result.rules)

        setNewRule(result.rules)

        toast.success("Decision Updated Successfully")
    }

    const getAllAttributes = async() => {

        let attributeDetail = {
            conditionTypeAttributeFilters : {
                conditionTypeId : null,
                pagination : {
 
                }
            }
        }
        
        let result = []

        let res = await axios.post(BASE_URL + GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE, attributeDetail)
        for(var i = 0; i < res.data.attributeDetails.length ; i++){
            let eachOperator = []
            for(var j = 0 ; j < res.data.attributeDetails[i].dataTypeMaster.operator.length ; j++){
                eachOperator.push({name : res.data.attributeDetails[i].dataTypeMaster.operator[j].operatorSymbol, label : res.data.attributeDetails[i].dataTypeMaster.operator[j].operatorSymbol})
            }
            let type = ''
            if(res.data.attributeDetails[i].dataTypeMaster.dataTypeName == 'string'){
                type = 'text'
            }else if(res.data.attributeDetails[i].dataTypeMaster.dataTypeName == 'integer'){
                type = 'number'
            }else if(res.data.attributeDetails[i].dataTypeMaster.dataTypeName == 'boolean'){
                type = 'text'
            }else if(res.data.attributeDetails[i].dataTypeMaster.dataTypeName == 'float'){
                type = 'number'
            }
            result.push({name : res.data.attributeDetails[i].attributeName, label : res.data.attributeDetails[i].attributeName, inputType : type, operators: eachOperator})
        }

        setFields(result)
        
    }

    const [showOutcome, setShowOutcome] = useState(false)
    const [outcome, setOutcome] = useState()


    const viewOutcome = () => {
        setShowOutcome(!showOutcome)
    }

    const editRule = () => {
        if(ruleName == ruleDetails.ruleName){
            if(initialOperator == 'and'){
                let editRule = {
                    id : props.location.id,
                    ruleDescription : ruleDescription,
                    rule: {
                        all: newRule,
                        event : {
                            type : true,
                            params : results
                        }
                    }
                }
                dispatch(editRuleAction(editRule, props.history))
            }else if(initialOperator == 'any'){
                let editRule = {
                    id : props.location.id,
                    ruleDescription : ruleDescription,
                    rule: {
                        any: newRule,
                        event : {
                            type : true,
                            params : results
                        }
                    }
                }
                dispatch(editRuleAction(editRule, props.history))
            }
        }else{
            if(initialOperator == 'and'){
                let editRule = {
                    id : props.location.id,
                    ruleName : ruleName,
                    ruleDescription : ruleDescription,
                    rule: {
                        all: newRule,
                        event : {
                            type : true,
                            params : results
                        }
                    }
                }
                dispatch(editRuleAction(editRule, props.history))
            }else if(initialOperator == 'any'){
                let editRule = {
                    id : props.location.id,
                    ruleName : ruleName,
                    ruleDescription : ruleDescription,
                    rule: {
                        any: newRule,
                        event : {
                            type : true,
                            params : results
                        }
                    }
                }
                dispatch(editRuleAction(editRule, props.history))
            }
        }
        
    }


    const cancel = () => {
        history.push(Routes.BusinessLogic.path)
    }

    if(ruleDetails){
        return(
            <>
            <ToastContainer />
                
                <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                    <Row>
                        <Col lg={12}>
                            <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                                <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                                <Breadcrumb.Item href="#business-logic">Business Logic</Breadcrumb.Item>
                                <Breadcrumb.Item className="text-dark" active>Edit Module</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </div>
        
                <div className="d-lg-flex justify-content-end flex-wrap flex-md-nowrap align-items-center py-4">
                    
                </div>
                <div className="task-wrapper border bg-white border-light shadow-sm py-5 rounded gamestarz">
                    <div className="row mb-4 px-4">
                        <div className="col col-lg-6 col-md-12 text-left">
                            <h4>Edit Decision</h4>
                        </div>
                        <div className="col col-lg-6 col-md-12 col-sm-12 text-right">
                  
                        </div>
                    </div>

                    <div className="container-fluid payout">
                        <Row className='my-3'>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <label className='my-2'>Rule Name</label>
                                <input className='form-control' defaultValue={ruleName} rows={5} onChange={(e)=>{setRuleName(e.target.value)}} />
                            </Col>
                        </Row>

                        <Row className='my-3'>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <label className='my-2'>Rule Description</label>
                                <textarea className='form-control' defaultValue={ruleDescription} rows={5} onChange={(e)=>{setRuleDescription(e.target.value)}}></textarea>
                            </Col>
                        </Row>

                        <Row className='my-4'>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                <Button onClick={decisionShow} className="button-style me-3" style={{width: "200px", display: "Inline !important"}}>
                                    <FontAwesomeIcon icon={faEye} className="me-2" />
                                    <span>Show Decision</span>
                                </Button>
                            </Col>
                        </Row>

                        {showDecision ?
                            <>
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <div className="payout">
                                
                                            <Row className='my-3'>
                                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <QueryBuilder
                                                    fields={fields} operators={operators}
                                                    query={query} controlClassnames={buildClasses()}
                                                    onQueryChange={q => setQuery(q)}
                                                />
                                                </Col>
                                            </Row>
                                            {showBtns ? 
                                            <Row className='my-2'>
                                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                    <button className='btn btn-outline-success mx-3' onClick={saveDecision}>Save</button>
                                                    <button className='btn btn-outline-danger' onClick={hideDecision}>Cancel</button>
                                                </Col>
                                            </Row>
                                            : ''}
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        : ""}

                        <Row className='my-4'>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                <Button disabled={results && results.length == 0 ? true : false} onClick={viewOutcome} className="button-style me-3" style={{width: "200px", display: "Inline !important"}}>
                                    <FontAwesomeIcon icon={faEye} className="me-2" />
                                    <span>Show Outcome</span>
                                </Button>
                            </Col>
                        </Row>

                        {showOutcome ?
                            (ruleDetails && ruleDetails.rule.event.params && ruleDetails.rule.event.params.map((e,i)=>{
                                return(
                                    <Row className='my-3'>
                                        <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                            <input type="text" className='form-control' defaultValue={e.key} placeholder='Key' />
                                        </Col>

                                        <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                            <input type="number" className='form-control' defaultValue={e.value} placeholder='Value' />
                                        </Col>
                                    </Row>
                                )
                            }))
                        : ''}

                        <Row className='my-2'>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Button className='btn btn-success mx-3' onClick={editRule}>Save</Button>
                                <Button className='btn btn-danger' onClick={cancel}>Cancel</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }else{
        return(
            <p></p>
        )
    }
}
