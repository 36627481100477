import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faSearch, faPlusCircle, faTrash, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col,InputGroup, Row, Nav, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import { Breadcrumb } from "react-bootstrap";
import { Link , useHistory} from "react-router-dom";
import { Routes } from "../../routes";
import {useDispatch, useSelector} from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllAttributeAction, getAllConditionTypeAction, getCoditionTypeDetailAction , getAllAttributeDetailAction, searchAttributeAction, searchModuleAction} from '../../redux/action/businessLogicAction';
import ModulesDetailModal from '../modals/BusinessLogicModal/ModulesDetailModal';
import AttributeDetailModal from '../modals/BusinessLogicModal/AttributeDetailModal';
import axios from "../../utlis/interceptor";
import { BASE_URL, GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE, GET_ALL_CONDITIONTYPE } from '../../resources/APIEndpoints';
import Pagination from '@mui/material/Pagination'

export default function BusinessLogic(){

    const dispatch = useDispatch();

    const [showToggleModule, setShowToggleModule] = useState(false)

    const onToggleModule = (id) => {

        setShowToggleModule(!showToggleModule)

        let moduleDetail = {
            conditionTypeFilters : {
                id : [id],
                pagination : {
                    offset : 0,
                    limit : 100
                }
            }
        }

        dispatch(getCoditionTypeDetailAction(moduleDetail))

    }

    const [showToggleAttribute, setShowToggleAttribute] = useState(false)

    const onToggleAttribute = (id) => {
        setShowToggleAttribute(!showToggleAttribute)

        let attributeDetail = {
            conditionTypeAttributeFilters : {
                id : [id],
                pagination : {
                    offset : 0,
                    limit : 100
                }
            }
        }

        dispatch(getAllAttributeDetailAction(attributeDetail))
    }


    const getAllConditions = () => {
        let allConditionType = {
            conditionTypeFilters : {
                id : "",
                pagination : {
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
        }

        dispatch(getAllConditionTypeAction(allConditionType))
    }


    /* Get Call For All Modules, Attribute and Pagination */

    const getAllAttributes = () => {
        let allAtribute = {
            conditionTypeAttributeFilters : {
                pagination : {
                    offset : ((attributePage-1)*attributeLimit),
                    limit : attributeLimit
                }
            }
        }
        
        dispatch(getAllAttributeAction(allAtribute))
    }

    const [page, setPage] = useState(1)
    const [counts , setCounts] = useState()
    const [limit, setLimit] = useState(10)
    

    const paginateModule = async() => {
        let paginateCount = {
            conditionTypeFilters : {
                pagination : {}
            }
        }

        let res = await axios.post(BASE_URL + GET_ALL_CONDITIONTYPE, paginateCount)
        const pageNo = Math.ceil(res.data.conditionDetails.length/limit)
        setCounts(pageNo)

    }

    const [attributePage, setAttributePage] = useState(1)
    const [attributeCounts, setAttributeCounts] = useState()
    const [attributeLimit, setAttributeLimit] = useState(10)

    const paginateAttributes = async() => {
        
        let paginateCount = {
            conditionTypeAttributeFilters : {
                pagination : {}
            }
        }

        let res = await axios.post(BASE_URL + GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE, paginateCount)

        const pageNoAttribute = Math.ceil(res.data.attributeDetails.length/attributeLimit)
        setAttributeCounts(pageNoAttribute)
        
    }

    useEffect(()=> {
        getAllConditions();
        getAllAttributes();
        paginateModule();
        paginateAttributes();
    }, [page,attributePage])

    let allConditionType = useSelector((state)=>{
        return state.businessLogic.modules
    })

    let allAttribute = useSelector((state)=>{
        return state.businessLogic.attributes
    })

    const searchAttribute = (e) => {
        if(e.target.value == ""){
            let allAtribute = {
                conditionTypeAttributeFilters : {
                    pagination : {
                        offset : ((attributePage-1)*attributeLimit),
                        limit : attributeLimit
                    }
                }
            }
            
            dispatch(getAllAttributeAction(allAtribute))
        }else{
            let searchAttribute = {
                attributeName : e.target.value
            }
            dispatch(searchAttributeAction(searchAttribute))
        }
    }

    const searchModule = (e) => {
        if(e.target.value == ""){
            let allConditionType = {
                conditionTypeFilters : {
                    id : "",
                    pagination : {
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
            }
    
            dispatch(getAllConditionTypeAction(allConditionType))
        }else{
            let searchModule = {
                moduleName : e.target.value
            }
            dispatch(searchModuleAction(searchModule))
        }
    }


    return (
        <>
            <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                        <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                            <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                            <Breadcrumb.Item className="text-dark" active>Business Logic</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
    
            <div className="d-lg-flex justify-content-end flex-wrap flex-md-nowrap align-items-center py-4">
                <Col xs={12} sm={12} md={12} lg={6}>
                    <Row className="px-3">
                        <Button as={Link} to={Routes.Module.path} className="button-style me-3" style={{width: "200px", display: "Inline !important"}}>
                            <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                            <span>Module</span>
                        </Button>
                        <Button as={Link} to={Routes.Attribute.path} className="button-style me-3" style={{width: "200px", display: "Inline !important"}}>
                            <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                            <span>Attribute</span>
                        </Button>
                    </Row>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className="px-0 mb-0 text-right">
                    {/* <ButtonGroup>
                        <Button variant="outline-primary" className="export" size="sm">Export</Button>
                    </ButtonGroup> */}
                </Col>
            </div>
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded gamestarz">
                <div className="row mb-4 px-4">
                    <div className="col col-lg-6 col-md-12 text-left">
                        <h4>Modules</h4>
                    </div>
                    <div className="col col-lg-6 col-md-12 col-sm-12 text-right">
                        {/* <span className='filter' style={{cursor: "pointer"}}><i className="fas fa-filter mx-2"></i><span>Filter</span></span> */}
                    </div>
                </div>

                <Row className="d-flex px-4">
                    {/* <Col lg={4} md={4} sm={12} xs={12}></Col> */}
                    <Col lg={4} md={4} sm={12} xs={12}>
                        <InputGroup>
                            <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                            <input className="form-control" type="text" placeholder="Search Modules" onChange={(e)=>{searchModule(e)}}/>
                        </InputGroup>
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12} className="mb-sm-4 pb-sm-4">
                        {/* <select className="form-control">
                            <option>Sort By</option>
                            <option>Newest to Oldest</option>
                            <option>Oldest to Newest</option>
                        </select> */}
                    </Col>
                </Row>

                <div className="container-fluid payout">
                    <div className="my-3">
                        <Row className='table-responsive'>
                            <Col style={{overflowX : "scroll"}} lg={12} md={12} sm={12}>
                                <table className="table table-striped text-center table-custom">
                                    <thead className='table-head'>
                                        <tr>
                                            <th className='head-border-left' scope="col">Module Name</th>
                                            <th scope='col'>Created At</th>
                                            <th className='head-border-right' scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className='table-body'>
                                        {allConditionType && allConditionType.map((e,i)=>{
                                            return(
                                                <tr key={i}>
                                                    <td>{e.conditionName != null ? e.conditionName : "-"}</td>
                                                    <td>{e.createdAt.slice(0,10).split("-").reverse().join("-")}</td>
                                                    <td>
                                                        <Dropdown className="ms-3">
                                                            <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="icon icon-sm icon-dark">
                                                                <FontAwesomeIcon icon={faEllipsisH} />
                                                                </span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dropdown-menu-right">
                                                                <Dropdown.Item as={Link}  className="ms-0" onClick={()=>{onToggleModule(e.id)}}>
                                                                    <FontAwesomeIcon icon={faEye} className="me-2"/> View
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className="text-warning ms-0" as={Link} to={{pathname:Routes.EditModule.path, id:e.id}}>
                                                                    <FontAwesomeIcon icon={faEdit} className="me-2"/> Edit
                                                                </Dropdown.Item>
                                                                {/* <Dropdown.Item as={Link}  className="text-danger ms-0">
                                                                    <FontAwesomeIcon icon={faTrash} className="me-2"/> Delete
                                                                </Dropdown.Item> */}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>

                        <div className="d-flex justify-content-left m-4">
                            <Row>
                                <Col>
                                    <Pagination className="paginate"
                                    count={counts} 
                                    defaultPage={page} 
                                    color="info" 
                                    variant="outlined" shape="rounded"
                                    showFirstButton={false} 
                                    showLastButton={false} 
                                    onChange={(event, value)=> setPage(value)}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div> 


                    <div className="my-5">
                        <div className="row mb-4">
                            <div className="col col-lg-6 col-md-12 text-left">
                                <h4>Attribute</h4>
                            </div>
                            <div className="col col-lg-6 col-md-12 col-sm-12 text-right">
                                {/* <span className='filter' style={{cursor: "pointer"}}><i className="fas fa-filter mx-2"></i><span>Filter</span></span> */}
                            </div>
                        </div>

                        <div>
                            <Row className="d-flex">
                                <Col lg={4} md={4} sm={12} xs={12}>
                                    <InputGroup>
                                        <InputGroup.Text>
                                        <FontAwesomeIcon icon={faSearch} />
                                        </InputGroup.Text>
                                        <input className="form-control" type="text" placeholder="Search Attribute" onChange={(e)=>{searchAttribute(e)}}/>
                                    </InputGroup>
                                </Col>
                                <Col lg={4} md={4} sm={12} xs={12} className="mb-sm-4 pb-sm-4">
                                    {/* <select className="form-control">
                                        <option>Sort By</option>
                                        <option>Newest to Oldest</option>
                                        <option>Oldest to Newest</option>
                                    </select> */}
                                </Col>
                            </Row>
                        </div>

                        <Row className='table-responsive my-3'>
                            <Col style={{overflowX : "scroll"}} lg={12} md={12} sm={12}>
                                <table className="table table-striped text-center table-custom">
                                    <thead className='table-head'>
                                        <tr>
                                            <th className='head-border-left' scope="col">Attribute Name</th>
                                            <th scope='col'>Created At</th>
                                            <th className='head-border-right' scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className='table-body'>
                                        {allAttribute && allAttribute.map((e,i)=>{
                                            return(
                                                <tr key={i}>
                                                    <td>{e.attributeName != null ? e.attributeName : "-"}</td>
                                                    <td>{e.createdAt.slice(0,10).split("-").reverse().join("-")}</td>
                                                    <td>
                                                        <Dropdown className="ms-3">
                                                            <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="icon icon-sm icon-dark">
                                                                    <FontAwesomeIcon icon={faEllipsisH} />
                                                                </span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dropdown-menu-right">
                                                                <Dropdown.Item as={Link}  className="ms-0" onClick={()=>{onToggleAttribute(e.id)}}>
                                                                    <FontAwesomeIcon icon={faEye} className="me-2"/> View
                                                                </Dropdown.Item>
                                                                <Dropdown.Item as={Link} to={{pathname : Routes.editAttribute.path, id: e.id}}  className="text-warning ms-0">
                                                                    <FontAwesomeIcon icon={faEdit} className="me-2"/> Edit
                                                                </Dropdown.Item>
                                                                {/* <Dropdown.Item as={Link}  className="text-danger ms-0">
                                                                    <FontAwesomeIcon icon={faTrash} className="me-2"/> Delete
                                                                </Dropdown.Item> */}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>

                        <div className="d-flex justify-content-left m-4">
                            <Row>
                                <Col>
                                    <Pagination className="paginate"
                                    count={attributeCounts} 
                                    defaultPage={attributePage} 
                                    color="info" 
                                    variant="outlined" shape="rounded"
                                    showFirstButton={false} 
                                    showLastButton={false} 
                                    onChange={(event, value)=> setAttributePage(value)}
                                    />
                                </Col>
                            </Row>
                        </div>

                    </div> 

                </div>
            </div>

            <ModulesDetailModal show={showToggleModule} onHide={onToggleModule}/>
            <AttributeDetailModal show={showToggleAttribute} onHide={onToggleAttribute}/>
        </>
    )
}
