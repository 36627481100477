import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faSearch, faPlusCircle, faTrash, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col,InputGroup, Row, Nav, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import { Breadcrumb } from "react-bootstrap";
import { Link , useHistory} from "react-router-dom";
import { Routes } from "../../../routes";
import {useDispatch, useSelector} from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QueryBuilder, { formatQuery } from "react-querybuilder";
import "../style.scss";
import axios from '../../../utlis/interceptor'
import { BASE_URL, GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE } from '../../../resources/APIEndpoints';
import { createRuleAction } from '../../../redux/action/businessLogicAction';
// import { FieldSelector } from "./FieldSelector";
// import Builder from '../Builder'

export default function Decision(props){

    const dispatch = useDispatch();
    const history = useHistory();

    const [fields, setFields] = useState([{name: "Attribute One", label: "Attribute One"},{name: "Attribute Two", label: "Attribute Two"},
    {name: "Attribute Three", label: "Attribute Four"},{name: "Attribute Four", label: "Attribute Four"}])
  
    const operators = [
      { name: "equal", label: "equal to" },
      { name: "notEqual", label: "not equal to" },
      { name: "lessThan", label: "less than" },
      { name: "greaterThan", label: "greater than" },
      { name: "lessThanEqualTo", label: "less than equal to" },
      { name: "greaterThanEqualTo", label: "greater than equal to" }
    ];
  
    const combinators = [{ name: "and", label: "AND" },{ name: "or", label: "OR" }];
  
    const existingQuery = {
      id: "g-qwjn8HiAtZ4-Z9i5Bi2br",
      rules: [
        {
          id: "r-wHzK6u-5a0YBETrkTFj3B",
          field: "firstName",
          value: "Steve",
          operator: "="
        },
        {
          id: "r-ISUlfhfzTULS5LTKjeKUH",
          field: "lastName",
          value: "Vai",
          operator: "="
        }
      ],
      combinator: "and",
      not: false
    };
  
    const [rules, setRules] = useState()
  
     const onQueryChange = queryJSON => {
      setRules(queryJSON)
    };
  
    const buildClasses = () => {
      return {
        queryBuilder: "qb-container", // Root <div> element
        ruleGroup: "rule-group notification", // <div> containing the RuleGroup
        combinators: "select select-combinator", // <select> control for combinators
        addRule: "btn-add-rule button is-info is-small is-rounded", // <button> to add a Rule
        addGroup: "btn-add-group button is-info is-small is-rounded", // <button> to add a RuleGroup
        removeGroup: "button is-danger is-small is-rounded", // <button> to remove a RuleGroup
        notToggle: "label", // <label> on the "not" toggle
        rule: "rule notification", // <div> containing the Rule
        fields: "select select-field", // <select> control for fields
        operators: "select select-operator", // <select> control for operators
        value: "input input-value is-small", // <input> for the field value
        removeRule: "button is-danger is-small is-rounded" // <button> to remove a Rule
      };
    };



    const getAttributeDetail = async() => {
        let attributeDetail = {
            conditionTypeAttributeFilters : {
                conditionTypeId : [props.location.id],
                pagination : {
                    offset : 0,
                    limit : 10
                }
            }
        }

        let result = []

        let res = await axios.post(BASE_URL + GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE, attributeDetail)
        for(var i = 0; i < res.data.attributeDetails.length ; i++){
            result.push({name : res.data.attributeDetails[i].attributeName, label : res.data.attributeDetails[i].attributeName})
        }
        setFields(result)
        
    }
  
    useEffect(()=> {
        getAttributeDetail();  
    }, [])
  
    
  
    const saveRule = () => {
        let rule = []
        for(var i = 0; i < rules.rules.length ; i++){
            rule.push({fact : rules.rules[i].field, value: parseInt(rules.rules[i].value), operator: rules.rules[i].operator})
        }

        let createRule = {
            ruleName : props.location.ruleName,
            ruleDescription : props.location.ruleDescription,
            rule: {
                all: rule
            },
            conditionTypeId: props.location.id
        }

        dispatch(createRuleAction(createRule))
    }

    const cancel = () => {
        history.push(Routes.BusinessLogic.path)
    }
 

    return (
        <>
            <ToastContainer />
                <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                    <Row>
                        <Col lg={12}>
                            <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                                <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                                <Breadcrumb.Item href="#business-logic">Business Logic</Breadcrumb.Item>
                                <Breadcrumb.Item className="text-dark" active>Decision</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </div>
        
                <div className="d-lg-flex justify-content-end flex-wrap flex-md-nowrap align-items-center py-4">
                    
                </div>
                <div className="task-wrapper border bg-white border-light shadow-sm py-5 rounded gamestarz">
                    <div className="row mb-4 px-4">
                        <div className="col col-lg-6 col-md-12 text-left">
                            <h4>Create Decision</h4>
                        </div>
                        <div className="col col-lg-6 col-md-12 col-sm-12 text-right">
                  
                        </div>
                    </div>

                    <div className="container-fluid payout">
                        {/* <Builder /> */}
                        <Row className='my-2'>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <QueryBuilder
                                fields={fields}
                                operators={operators}
                                combinators={combinators}
                                onQueryChange={onQueryChange}
                                controlClassnames={buildClasses()}
                                showCombinatorsBetweenRules={false}
                            />
                            </Col>
                        </Row>

                        <Row className='my-2'>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Button className='btn btn-success mx-3' onClick={saveRule}>Save</Button>
                                <Button className='btn btn-danger' onClick={cancel}>Cancel</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
      </>
    )
    }
