import React, {useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Routes } from '../../../routes';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faPlusCircle, faSearch, faToggleOn, faToggleOff} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col, InputGroup, Row, Nav, Form, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import ActivateSCModal from '../../modals/PlaystazBankModels/ScratchCardModals/ActivateSCModal';
import DeactivateSCModal from '../../modals/PlaystazBankModels/ScratchCardModals/DeactivateSCModal';
import ScratchCardDetailModal from '../../modals/PlaystazBankModels/ScratchCardModals/ScratchCardDetailModal'
import ScratchCardFilter from '../../modals/Filters/ScratchCardFilter';
import {useDispatch, useSelector} from 'react-redux';
import { clearSCAction, getAllScratchCardAction, getScratchCardByIdAction, searchScratchCardAction } from '../../../redux/action/playstarzbankAction';
import Pagination from '@mui/material/Pagination'
import axios from '../../../utlis/interceptor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL, GET_SCRATCH_CARD } from '../../../resources/APIEndpoints';
import { CSVLink, CSVDownload } from "react-csv";
import { Breadcrumb } from "react-bootstrap";

function ScratchCard(props) {
    const dispatch = useDispatch();
    const [showActivate, setActivate] = useState(false);

    const onActivate = () => {
        setActivate(!showActivate)
    }

    const [showDeactivate, setDeactivate] = useState(false);

    const onDeactivate = () => {
        setDeactivate(!showDeactivate)
    }

    const [showDetail, setShowDetail] = useState(false);

    const onDetail = (scratchCardId) => {
        setShowDetail(!showDetail)
        let scToggle = !showDetail
        let scratchCardDetail = {
            scratchCardFilters : {
                id : scratchCardId,
                pagination : {

                }
            }
        }
        if(scToggle === true){
            dispatch(getScratchCardByIdAction(scratchCardDetail))
        }
    }

    const [filterCoupon, setFilterCoupon] = useState(false)
    const onFilterCoupon = () => {
        setFilterCoupon(!filterCoupon)
    }

    const [page, setPage] = useState(1)
    const [counts , setCounts] = useState()
    const [posts, setPosts] = useState([])
    const [limit, setLimit] = useState(10)

    useEffect(()=>{
        let ScratchCardData = {
            scratchCardFilters : {
                referralRewardsTypeId : filterReward,
                scratchCardStatus : filterStatus, 
                pagination : {
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
        }
        Object.keys(ScratchCardData.scratchCardFilters).forEach((key) => (ScratchCardData.scratchCardFilters[key].length === 0) && delete ScratchCardData.scratchCardFilters[key])
        dispatch(getAllScratchCardAction(ScratchCardData))
    }, [page])

    useEffect(()=>{
        let ScratchCardData = {
            scratchCardFilters : { 
                pagination : {
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
        }
        axios.post(`${BASE_URL}/scratch-card/getScratchCard`, ScratchCardData)
        .then(res=>{
            const pageNo = Math.ceil(res.data.scratchCard.length/limit)
            setCounts(pageNo)
        })
    }, [])

    const searchScratchCard = (e) => {
        if(e.target.value === ""){
            let ScratchCardData = {
                scratchCardFilters : { 
                    pagination : {
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
            }
            dispatch(getAllScratchCardAction(ScratchCardData))
        }else{
            let searchScratchCard = {
                scratchCardName : e.target.value
            }
            dispatch(searchScratchCardAction(searchScratchCard))
        }
    }

    
    let scratchCardData = useSelector((state)=>{ 
        return state.playstarzbank.scratchCard
    })

    const sortScratchCard = (e) => {
        dispatch(clearSCAction())
        let scratchCardSort = {}
        if(e.target.value === 'latest'){
            scratchCardSort = {
                scratchCardFilters : {
                    scratchCardSort : {
                        createdAt : "ASC"
                    },
                    pagination :{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
            }
        }else if(e.target.value === 'oldest'){
            scratchCardSort = {
                scratchCardFilters : {
                    scratchCardSort : {
                        createdAt : "DESC"
                    },
                    pagination :{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
            }
        }else if(e.target.value === 'startDate'){
            scratchCardSort = {
                scratchCardFilters : {
                    scratchCardSort : {
                        createdAt : "ASC"
                    },
                    pagination :{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
            }
        }else if(e.target.value === 'endDate'){
            scratchCardSort = {
                scratchCardFilters : {
                    scratchCardSort : {
                        createdAt : "ASC"
                    },
                    pagination :{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
            }
        }
        dispatch(getAllScratchCardAction(scratchCardSort))
    }

    const [allScratchCard, setAllScratchCard] = useState()

    useEffect(()=>{
        let scratchCardAll = {
            scratchCardFilters : { 
                pagination : {
                    
                }
            }
        }
        axios.post(BASE_URL + GET_SCRATCH_CARD, scratchCardAll)
        .then(res=>{
            setAllScratchCard(res.data.scratchCard)
        })
    }, [])

    const headers = [
        { label : 'SC Id', key : 'id'},
        { label : 'SC Name', key : 'scratchCardName'},
        { label : 'SC Description', key : 'scratchCardDescription'},
        { label : 'SC Cashback Type', key : 'scratchCardCashbackType'},
        { label : 'Max Cashback', key : 'scratchCardMaxCashback'},
        { label : 'SC Total Limit', key : 'scratchCardTotalLimitCount'},
        { label : 'Total Limit Per User', key : 'scratchCardTotalLimitCountPerUser'},
        { label : 'Validity Period', key : 'scratchCardValidityPeriod'},
        { label : 'Coupon Start Date', key : 'startDate'},
        { label : 'Coupon End Date', key : 'endDate'},
    ]
     
      const csvReport = {
        filename : `ScratchCarad_Report_${new Date().toISOString().slice(0, 10)}.csv`,
        headers : headers,
        data : allScratchCard
      }

    const [filterStatus, setFilterStatus] = useState([])
    const [filterReward, setFilterReward] = useState([])

    const filterData = (scReward, scStatus) => {

        setFilterStatus(scStatus)
        setFilterReward(scReward)
        
        let filterDataSc = {
            scratchCardFilters : {
                referralRewardsTypeId : scReward,
                scratchCardStatus : scStatus,
                pagination : {
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
        }
        Object.keys(filterDataSc.scratchCardFilters).forEach((key) => (filterDataSc.scratchCardFilters[key].length === 0) && delete filterDataSc.scratchCardFilters[key])
        dispatch(getAllScratchCardAction(filterDataSc))

        axios.post(BASE_URL + GET_SCRATCH_CARD, filterDataSc)
        .then(res=>{
            const pageNo = Math.ceil(res.data.scratchCard.length/limit)
            setCounts(pageNo)
        })
    }

    const [disableExport, setDisableExport] = useState(false)

    const exportData = () => {
        setDisableExport(true)
        setTimeout(() => {
          setDisableExport(false)
        }, 5000);
    }

    return (
        <>
        <ToastContainer />
        <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
            <Row>
                <Col lg={12}>
                    <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                        <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-dark" active>Scratch Card</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        <div className="d-lg-flex justify-content-end flex-wrap flex-md-nowrap align-items-center py-4">
            <Col xs={12} sm={12} md={12} lg={6}>
                <Row className="px-3">
                    <Button as={Link} to={Routes.CreateSC.path} className="button-style me-3" style={{width: "200px", display: "Inline !important"}}>
                    <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                    <span>Create Scratch Card</span>
                    </Button>
                </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} className="px-0 mb-0 text-right">
                <ButtonGroup>
                    {allScratchCard ? <Button disabled={disableExport ? true : false} onClick={exportData} className="export"><CSVLink {...csvReport}>Export</CSVLink></Button> : ""}
                </ButtonGroup>
            </Col>
        </div>
        <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded gamestarz">
            <div className="row mb-4 px-4">
                <div className="col col-lg-6 col-md-12 text-left">
                    <p className='page-heading'>Scratch Card</p>
                </div>
                <div className="col col-lg-6 col-md-12 col-sm-12 text-right">
                    <span className={filterStatus.length != 0 || filterReward.length != 0 ? 'applied-filter' : 'filter'} style={{cursor: "pointer"}} onClick={onFilterCoupon}><i className="fas fa-filter mx-2"></i><span>Filter</span></span>
                </div>
            </div>

            <Row className="d-flex px-4">
                <Col lg={4} md={4} sm={12} xs={12}>
                    <InputGroup>
                        <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control type="text" placeholder="Search Scratch Card Name" onChange={(e)=>{searchScratchCard(e)}}/>
                    </InputGroup>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12} className="mb-sm-4 pb-sm-4">
                    {/* <label>Sort By</label> */}
                    <select className="form-control" onChange={(e)=>{sortScratchCard(e)}}>
                        <option>Sort By</option>
                        <option value="latest">Created At (latest)</option>
                        <option value="oldest">Created At (oldest)</option>
                        <option value="startDate">Start Date/Time</option>
                        <option value="endDate">End Date/Time</option>
                        <option value="status">Scratch Card Status</option>
                    </select>
                </Col>
            </Row>

            <div className="container-fluid scratch-card">
            <div className="mt-5">
                <Row>
                    <Col style={{overflowX : "scroll"}} lg={12} md={12} sm={12}>
                        <table className="table table-striped text-center table-custom">
                            <thead className='table-head'>
                                <tr>
                                    <th className='head-border-left' scope="col">Scratch Card Name</th>
                                    <th scope='col'>Reward Type</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">End Date</th>
                                    <th scope="col">Validity Period</th>
                                    <th scope="col">On Deposits 'Between' Amounts </th>
                                    <th scope="col">Max Scratch Cards Available</th>
                                    <th scope="col">Max Scratch Card Limit per User</th>
                                    <th scope="col">Scratch Card Status</th>
                                    <th className='head-border-right' scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody className='table-body'>
                            {scratchCardData && scratchCardData.map((e)=>{
                                    return(
                                        <tr className='table-body'>
                                            <td><b>{e.scratchCardName}</b></td>
                                            <td>{e.referralRewardsType && e.referralRewardsType.id === 1 ? "bonus cash" : e.referralRewardsType && e.referralRewardsType.id === 2 ? "virtual currency" : e.referralRewardsType && e.referralRewardsType.id === 3 ? "inventory rewards" : e.referralRewardsTypeId && e.referralRewardsTypeId === 1 ? "bonus cash" : e.referralRewardsTypeId && e.referralRewardsTypeId === 2 ? "virtual currency" : e.referralRewardsTypeId && e.referralRewardsTypeId === 3 ? "inventory rewards" : "-"}</td>
                                            <td>{e.startDate.slice(0, 10)}</td>
                                            <td>{e.endDate.slice(0, 10)}</td>
                                            <td>{e.scratchCardValidityPeriod}</td>
                                            <td>{e.scratchCardRule.all.length === 2 ?  <div>{e.scratchCardRule.all[0].value} - {e.scratchCardRule.all[1].value}</div> : e.scratchCardRule.all.length === 1 ? e.scratchCardRule.all[0].value : "-"}</td>
                                            <td>{e.scratchCardTotalLimitCount}</td>
                                            <td>{e.scratchCardTotalLimitCountPerUser}</td>
                                            <td>{e.scratchCardStatus != "" ? <span className={e.scratchCardStatus == 'active' ? 'success' : e.scratchCardStatus == 'inactive' ? 'danger' : ''}>{e.scratchCardStatus}</span> : "-"}</td>
                                            <td>
                                                <Dropdown className="ms-3">
                                                    <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span className="icon icon-sm icon-dark">
                                                        <FontAwesomeIcon icon={faEllipsisH} />
                                                    </span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                    <Dropdown.Item className="ms-0" onClick={()=>{onDetail(e.id)}}>
                                                        <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={Link} to={{pathname:Routes.EditScratchCard.path, id: e.id}} className="ms-0">
                                                        <FontAwesomeIcon icon={faEdit} className="me-2"/> Edit
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={Link} className="text-success ms-0" onClick={onActivate}>
                                                    <FontAwesomeIcon icon={faToggleOn} className="me-2" /> Activate
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={Link} className="text-danger ms-0" onClick={onDeactivate}>
                                                    <FontAwesomeIcon icon={faToggleOff} className="me-2" /> Deactivate
                                                    </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </Col>
                </Row>

                <div className="d-flex justify-content-left m-4">
                    <Row>
                        <Col>
                            <Pagination 
                            count={counts} 
                            defaultPage={page}
                            color="info" className='paginate'
                            variant="outlined" shape="rounded"
                            showFirstButton={false} 
                            showLastButton={false}  
                            onChange={(event, value)=> setPage(value)}
                            />
                        </Col>
                    </Row>
                </div>

            </div> 
        </div>
        <ActivateSCModal show={showActivate} onHide={onActivate} />
        <DeactivateSCModal show={showDeactivate} onHide={onDeactivate} />
        <ScratchCardDetailModal show={showDetail} onHide={onDetail} />
        <ScratchCardFilter show={filterCoupon} onHide={onFilterCoupon} filterScratchCard={filterData}/>
    </div>
    </>
    )
}


export default ScratchCard