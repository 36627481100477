import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons'

function VerifyOrgModal(props) {

    return (
        <React.Fragment>
            <Modal isOpen={props.addOpen} toggle={props.Addtoggle} detail={props.Detail} centered>
                <ModalHeader><h5>{props.Detail[0].organisationName != null ? props.Detail[0].organisationName : props.Detail[0].organisationName == null ? props.Detail[0].firstName + "_" +  props.Detail[0].lastName  : ""}</h5></ModalHeader>
                <ModalBody>
                    <Row className="text-center mt-3">
                            <Col>
                                <FontAwesomeIcon icon={faCheckCircle} className="me-2 fa-3x text-success" />
                            </Col>
                    </Row>
                    <Row className="text-center mt-3">
                            <Col>
                                <h6>Verify this partner?</h6>
                            </Col>
                    </Row>
                    <Row className="justify-content-center text-center my-3">
                            <Col lg={12} md={12} sm={12}>
                                <button className="btn btn-success mx-2" onClick={props.Verify}>Verify</button>
                                <button className="btn btn-danger mx-2" onClick={props.Addtoggle}>Cancel</button>
                            </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default VerifyOrgModal