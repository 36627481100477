import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {getallGamesAction, GameByIdAction, cleargameAction, searchGamesAction, EditGameAction} from '../redux/action/gameAction'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faSearch, faPlusCircle, faTrash, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col,InputGroup, Row, Nav, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import { TaskCardWidget } from "../components/Widgets";
import { Routes } from "../routes";
import TASKS_DATA from "../data/tasks";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Field, Form } from 'formik';
import Documentation from "../components/Documentation";
import './css/games.css'
import Pagination from '@mui/material/Pagination'
import LivetoggleModal from "./modals/LivetoggleModal";
import FilterModal from "./modals/Filters/FilterModal";
import GamedetailModal from "./modals/GamedetailModal";
import DeleteModal from "./modals/DeleteModal";
import axios from "../utlis/interceptor";
import Loader from "react-js-loader";
import { Select } from "@mui/material";
import Switch from "react-switch";
import StopGameModal from "./modals/GamesModels/StopGameModal";
import { BASE_URL, GET_GAME } from "../resources/APIEndpoints";
import { CSVLink, CSVDownload } from "react-csv";
import { Breadcrumb } from "react-bootstrap";
import { dark } from "@mui/material/styles/createPalette";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));


function Games(props){
  const dispatch = useDispatch();
  
  // const [isLoading, setLoading] = useState(false)
  const [showallgame, setShowallgame] = useState(true);
  const [showdraftgame, setShowdraftgame] = useState(false);
  const [showarchievedgame, setShowarchievedgame] = useState(false)

  const [addfilter, setAddfilter] = useState(false)
  const [livetoggle, setLivetoggle] = useState(false)

  const [page, setPage] = useState(1)
  const [counts , setCounts] = useState()
  const [limit, setLimit] = useState(10)

  const [gameIdLive, setGameIdLive] = useState("")
  const [toggleState, setToggleState] = useState()

  const [showall, setShowall] = useState(true);
  const [showarchive, setShowarchieve] = useState(false);
  const [showdraft, setShowdraft] = useState(false)

  /* For CSV Data */
  const [allGames, setAllGames] = useState()

  const onAddFilter = () => {
    setAddfilter(!addfilter)
    // setisShow(!isShow)
  }

  const onToggleLive = (evt, gameId) => {
    setToggleState(evt)
    setGameIdLive(gameId)
    if(evt === true){
      setLivetoggle(!livetoggle)
    }else if(evt === false){
      setShowStopModel(!showStopModel)
    }
  }

  const [showdeletemodal, setShowdeletemodal] = useState(false)

  const onToggleDelete = () => {
    setShowdeletemodal(!showdeletemodal)
  }

  const [showStopModel, setShowStopModel] = useState(false)

  const savelive = () => {
    if(toggleState === true){
      const liveGameData = {
        id : gameIdLive,
        isLive : true,
      }
      dispatch(EditGameAction(liveGameData, props.history))
      setLivetoggle(false)
    }else if(toggleState === false){
      const liveGameData = {
        id : gameIdLive,
        isLive : false,
      }
      dispatch(EditGameAction(liveGameData, props.history))
      setShowStopModel(false)
    }
    setTimeout(()=>{
      const afterEditGame = {
        gameFilters : {
          isDraft : [false],
          archive : [false],
          gameSort : {createdAt : "DESC"},
          pagination : {offset : 0, limit : 10}
        }
      }
      dispatch(getallGamesAction(afterEditGame, props.history))
    }, 1000)
  }

  const showAllGames = () => {
    setPage(1)
    dispatch(cleargameAction())
    setShowall(true)
    setShowarchieve(false)
    setShowdraft(false)
    const allGame = {
      gameFilters : {
        isDraft : [false],
        archive : [false],
        gameSort : sorted,
        pagination : {
          offset : ((page-1)*limit),
          limit : limit
        }
      }
    }
    dispatch(getallGamesAction(allGame))
  }

  const showArchivedGames = () => {
    setPage(1)
    dispatch(cleargameAction())
    setShowall(false)
    setShowarchieve(true)
    setShowdraft(false)
    const Archive = {
      gameFilters : {
        archive : true,
        pagination : {
          offset : ((page-1)*limit),
          limit : limit
      }
      }
    }
    dispatch(getallGamesAction(Archive))
    
  }

  const ShowDraftsGame = () => {
    setPage(1)
    dispatch(cleargameAction())
    setShowall(false)
    setShowarchieve(false)
    setShowdraft(true)
    const DraftData = {
      gameFilters : {
        isDraft : [true],
        pagination : {
          offset : ((page-1)*limit),
          limit : 10
      }
      }
    }
    dispatch(getallGamesAction(DraftData))

  }

  const [gamedetail, setGamedetail] = useState(false)

  useEffect(() => {
    getAllGames()
    getAllGamesCSV()
    paginateGames();
  }, [page, showall, showdraft, showarchive]);

 
  const getAllGamesCSV = () => {
     let allGames = {
       gameFilters : {
         pagination : {
 
         }
       }
     }
     axios.post(BASE_URL + GET_GAME , allGames)
     .then(res=>{
       setAllGames(res.data.gameData)
     })
  }

  const getAllGames = () => {
    const data = {
      gameFilters : {
        isGameScreenOrientationLandscape : filterorientation,
        gameWorldId : filterWorld,
        matchFormatTypeId : filtermatchFormat,
        gamePlatforms : filterplatform,
        matchOutcomeTypeId : filtermatchOutcome,
        // isDraft : [false],
        // archive : [false],
        isDraft : [showdraft],
        archive : [showarchive],
        gameSort : sorted,
        pagination : {
          offset : ((page-1)*limit),
          limit : limit
        }
      }
    }
    Object.keys(data.gameFilters).forEach((key) => (data.gameFilters[key].length === 0) && delete data.gameFilters[key])
    dispatch(getallGamesAction(data))
  }

  // Calculating No Of Games Entries

  const paginateGames = async() => {
    let gamesData = {
      gameFilters:{
        // isDraft : [false],
        // archive : [false],
        isDraft : [showdraft],
        archive : [showarchive],
        pagination : {}
      },
      entities:[{
          value: "Match",
          attributes : ["id","matchName"]
      }]
    }

    let noOfGames = await axios.post(BASE_URL + GET_GAME, gamesData)
    const pageNo = Math.ceil(noOfGames.data.gameData.length/limit)
    setCounts(pageNo)

  }


  const [GameId, setGameId] = useState("")
  
  const onToggleGameDetail = (gameId) => {
    setGamedetail(!gamedetail) 
    const toggleGameDetail = !gamedetail
    const game_id = gameId;
    let gameDetailById = {
      gameFilters : {
          id : gameId,
          pagination : {

        }
      }
    }
    if(toggleGameDetail === true){
        dispatch(GameByIdAction(gameDetailById))
    }
    setGameId(game_id);
  }

  let games = useSelector((state)=>{ 
    return state.game.games
  })

  let gamesDetail = useSelector((state)=>{ 
    return state.game.gameDetail
  })

  let isLoading = useSelector((state)=>{ 
    return state.game.isLoading
  })
  
  const onArcheive = (data) => {
      const arch = {
          id : data,
          archive : true
        }
        axios.post(`${BASE_URL}/game/editGame`, arch, props.history)
        .then(res=>{
          toast.success("Moved to archive")
          const data = {
            gameFilters : {
              archive : [false],
              isDraft : [false],
              gameSort : sorted,
              pagination : {
                offset : ((page-1)*limit),
                limit : limit
            }
            }
          }
          dispatch(getallGamesAction(data));
        })

  }

  const onUnarchive = (data) => {
    const unarch = {
        id : data,
        archive : false
    }
      axios.post(`${BASE_URL}/game/editGame`, unarch, props.history)
      .then(res=>{
        toast.success("Unarchived")
        const data = {
          gameFilters : {
            archive : [false],
            isDraft : [false],
            gameSort : sorted,
            pagination : {
              offset : ((page-1)*limit),
              limit : limit
          }
          }
        }
        dispatch(getallGamesAction(data));
      })
      setShowall(true)
      setShowarchieve(false)
      setShowdraft(false)
  }


  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState("")
  const [sorted, setSorted] = useState({createdAt : "DESC"})

  const onChangeSort = (e) => {
    dispatch(cleargameAction())
      let data ={}
      if(e.target.value === "Name_ASC"){
          setSorted({gameName : "ASC"})
          data = {
            // isDraft : [false],
            // archive : [false],
            isDraft : [showdraft],
            archive : [showarchive],
            gameSort : {
              gameName : "ASC",
            },
            pagination : {
              offset : ((page-1)*limit),
              limit : limit
            }
          } 
      }else if(e.target.value === "Name_DESC"){
        setSorted({gameName : "DESC"})
        data = {
          // isDraft : [false],
          // archive : [false],
          isDraft : [showdraft],
          archive : [showarchive],
          gameSort : {
            gameName : "DESC"
          },
          pagination : {
            offset : ((page-1)*limit),
            limit : limit
          }
        }
      }else if(e.target.value === "App_ASC"){
        setSorted({gameMinimumAppVersion : "ASC"})
        data = {
          // isDraft : [false],
          // archive : [false],
          isDraft : [showdraft],
          archive : [showarchive],
          gameSort : {
            gameMinimumAppVersion : "ASC"
          },
          pagination : {
            offset : ((page-1)*limit),
            limit : limit
          }
        }
      }else if(e.target.value === "App_DESC"){
        setSorted({gameMinimumAppVersion :"DESC"})
        data = {
          // isDraft : [false],
          // archive : [false],
          isDraft : [showdraft],
          archive : [showarchive],
          gameSort : {
            gameMinimumAppVersion :"DESC"
          },
          pagination : {
            offset : ((page-1)*limit),
            limit : limit
          }
        }
      }else if(e.target.value === "Date_ASC"){
        setSorted({createdAt :"DESC"})
        data = {
          // isDraft : [false],
          // archive : [false],
          isDraft : [showdraft],
          archive : [showarchive],
          gameSort : {
            createdAt :"DESC"
          },
          pagination : {
            offset : ((page-1)*limit),
            limit : limit
          }
        }
      }else if(e.target.value === "Date_DESC"){
        setSorted({createdAt :"ASC"})
        data = {
          // isDraft : [false],
          // archive : [false],
          isDraft : [showdraft],
          archive : [showarchive],
          gameSort : {
            createdAt :"ASC"
          },
          pagination : {
            offset : ((page-1)*limit),
            limit : limit
          }
        }
      }
      let getSortedGames = {
        gameFilters  : data
      }
      dispatch(getallGamesAction(getSortedGames));
  }

    const [filterorientation, setFilterOrientation] = useState([])
    const [filtermatchFormat, setFilterMatchformat] = useState([])
    const [filterplatform, setFilterPlatform] = useState([])
    const [filtermatchOutcome, setFilterMatchOutcome] = useState([])
    const [filterleaderboardOutcome, setFilterLeaderboardOutcome] = useState([])
    const [filterWorld, setFilterWorld] = useState([])

    const gamesFilterData = (Orientation, world, matchFormat, platform, matchOutcome, leaderboardOutcome) => {


      setFilterOrientation(Orientation)
      setFilterWorld(world)
      setFilterMatchformat(matchFormat)
      setFilterPlatform(platform)
      setFilterMatchOutcome(matchOutcome)
      setFilterLeaderboardOutcome(leaderboardOutcome)

      let gamesFilter = {
          gameFilters : {
            isGameScreenOrientationLandscape : Orientation,
            gameWorldId : world,
            matchFormatTypeId : matchFormat,
            gamePlatforms : platform,
            matchOutcomeTypeId : matchOutcome,
            // isDraft : [false],
            // archive : [false],
            isDraft : [showdraft],
            archive : [showarchive],
            pagination : {
              offset : ((page-1)*limit),
              limit : limit
            }
          }
      }
      Object.keys(gamesFilter.gameFilters).forEach((key) => (gamesFilter.gameFilters[key].length === 0) && delete gamesFilter.gameFilters[key])
      dispatch(getallGamesAction(gamesFilter))

      delete gamesFilter.gameFilters.pagination.offset
      delete gamesFilter.gameFilters.pagination.limit
      
      axios.post(BASE_URL + GET_GAME, gamesFilter)
      .then(resNew=>{
        const pageNo = Math.ceil(resNew.data.gameData.length/limit)
        setCounts(pageNo)
      })
    }

    const SearchGames = (e) => {
        if(e.target.value === ""){
          dispatch(cleargameAction())
          const data = {
            gameFilters : {
              isDraft : [false],
              archive : [false],
              gameSort : {
                createdAt :"DESC"
              },
              pagination :{
                offset : ((page-1)*limit),
                limit : limit
              }
           }
        }
        dispatch(getallGamesAction(data))
        }else{
          let gameSerachData = {
            gameName : e.target.value
          }
          dispatch(searchGamesAction(gameSerachData))
        }
  }

  const [isShow, setisShow] = useState(false)

  const handleAddPointSubmit = (value) => {
    const data = {
          isGameScreenOrientationLandscape : value.isGameScreenOrientationLandscape,
          gameWorldId : value.gameWorldId.map((e)=>{return parseInt(e)}),
          gamePlatforms : value.gamePlatforms,
          matchFormatTypeId : value.matchFormatTypeId.map((e)=>{return parseInt(e)}),
          matchOutcomeTypeId : value.matchOutcomeTypeId.map((e)=>{return parseInt(e)}),
          leaderboardOutcomeId : value.leaderboardOutcomeId.map((e)=>{return parseInt(e)})
    }

    Object.keys(data).forEach(key => {
      if (data[key] === '' || data[key].length === 0) {
        delete data[key];
      }
    });

    const newData = {
        gameFilters : data
    }

    dispatch(cleargameAction())
    dispatch(getallGamesAction(newData))
 }

 const close = () => {
     setisShow(false);
 }

 const cancellive = () => {
    setLivetoggle(false);
    setShowStopModel(false);
 }

 const headers = [
   { label : 'Game Id', key : 'id'},
   { label : 'Game Name', key : 'gameName'},
   { label : 'Game Description', key : 'gameDescription'},
   { label : 'Game How To', key : 'gameHowTo'},
   { label : 'Game Logo', key : 'gameLogo'},
   { label : 'Game ScreenShot', key : 'gameScreenShotUrl'},
   { label : 'Game Video' , key : 'gamePreviewVideoUrl'},
   { label : 'Game App Version', key : 'gameMinimumAppVersion'},
   { label : 'Game Orientation', key : 'isGameScreenOrientationLandscape'},
   { label : 'Game Genre', key : 'gameGenre'},
   { label : 'No Of Matches', key : 'numberOfMatchesCreated'},
   { label : 'Game Platform', key : 'gamePlatforms[0].platformName'},
   { label : 'Game Platform', key : 'gamePlatforms[1].platformName'},
   { label : 'Game Asset Bundle', key: 'gamePlatforms[0].gameAssetBundleUrl'},
   { label : 'Game Asset Bundle', key: 'gamePlatforms[1].gameAssetBundleUrl'},
   { label : 'Game World', key:'gamesWorld[0].gameWorldName'},
   { label : 'Draft', key : 'isDraft'},
   { label : 'Live', key : 'isLive'}
 ]

 const csvReport = {
   filename : `Game_Report_${new Date().toISOString().slice(0, 10)}.csv`,
   headers : headers,
   data : allGames
 }

 const [disableExport, setDisableExport] = useState(false)

 const exportData = () => {
    setDisableExport(true)
    setTimeout(() => {
      setDisableExport(false)
    }, 5000);
 }

  return (
    <>
    <ToastContainer />
    <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
      <Row>
        <Col lg={12}>
          <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
            <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
            <Breadcrumb.Item className="text-dark" active>Game</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
    </div>

      <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
          <div className="me-lg-3">
            <Button as={Link} to={Routes.AddGame.path} className="me-2 button-style">
              <FontAwesomeIcon icon={faPlusCircle} className="me-2 icon" />
              <span>Add Games</span>
            </Button>
          </div>
        </Col>

        <Col xs={12} lg={6} className="px-0 mb-0 text-right">
            <ButtonGroup>
                {allGames ? <Button disabled={disableExport ? true : false} onClick={exportData} className="export"><CSVLink {...csvReport}>Export</CSVLink></Button> : ""}   
            </ButtonGroup>
        </Col>
      </div>
      <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded game-page">
          
          <div className="row mb-4 px-4 align-items-center">
              <div className="col col-lg-6 col-md-12 text-left">
                  <p className="page-heading">Games</p>
                  {/* <div className="Bg-Color" style={{width: '30px', height: '2px'}}></div> */}
              </div>
              <div className="col col-lg-6 col-md-12 text-right">
                <span className={filterorientation.length != 0 || filtermatchFormat.length != 0 || filterplatform.length != 0 || filtermatchOutcome.length != 0 || filterleaderboardOutcome.length != 0 || filterWorld.length != 0 ? 'applied-filter' : 'filter'} style={{cursor: "pointer"}} onClick={onAddFilter}><i className="fas fa-filter mx-2"></i><span>Filter</span></span>
              </div>
          </div>

          <div className="row d-flex px-4 mt-2">
            {/* <div className="col col-md-4"></div> */}
            <div className="col col-md-4">
                <InputGroup>
                    <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <input className="form-control" type="text" placeholder="Search Games" onChange={(e)=>{SearchGames(e)}}/>
                </InputGroup>
            </div>
            <div className="col col-md-4">
                {/* <label>Sort By</label> */}
                <select className="form-control" onChange={(e)=>{onChangeSort(e)}}>
                    <option>Sort By</option>
                    <option value="Name_ASC">Name : (A-Z)</option>
                    <option value="Name_DESC">Name : (Z-A)</option>
                    <option value="App_ASC">Minimum App Version (low-high)</option>
                    <option value="App_DESC">Minimum App Version (high-low)</option>
                    <option value="Date_ASC">Created at (latest)</option>
                    <option value="Date_DESC">Created at (oldest)</option>
                </select>
            </div>
          </div>

        <div className="px-4">
          <Row className="my-5">
            <Col lg={12} md={12} sm={12}>
              <Row className="justify-content-left text-center">
                <Col style={{cursor: "pointer"}} onClick={showAllGames} lg={3} md={3} sm={12}>
                  <Nav fill variant="pills" className="flex-column flex-sm-row nav-accordian">
                    <Nav.Item>
                      <Nav.Link className={showall === true ? "accordian-pills pills mb-sm-3 mb-md-0" : "mb-sm-3 mb-md-0 pills"}>All Games</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col style={{cursor: "pointer"}} onClick={ShowDraftsGame} lg={3} md={3} sm={12}>
                  <Nav fill variant="pills" className="flex-column flex-sm-row nav-accordian">
                    <Nav.Item>
                        <Nav.Link className={showdraft === true ? "accordian-pills mb-sm-3 mb-md-0 pills" : "mb-sm-3 mb-md-0 pills"}>Drafts</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col style={{cursor: "pointer"}} onClick={showArchivedGames} lg={3} md={3} sm={12}>
                    <Nav fill variant="pills" className="flex-column flex-sm-row nav-accordian">
                      <Nav.Item>
                          <Nav.Link className={showarchive === true ? "accordian-pills pills mb-sm-3 mb-md-0" : "mb-sm-3 pills mb-md-0"}>Archived Games</Nav.Link>
                      </Nav.Item>
                  </Nav>
                </Col>
              </Row>
            </Col>
          </Row>
          
          <Row className="table-responsive">
              <Col lg={12} md={12} sm={12}>
                {showall && games && games.length !=0 ? 
                <table className="table table-striped text-center table-custom align-middle">
                  <thead className="table-head">
                    <tr>
                      <th scope="col" className="head-border-left">Game Name</th>
                      <th scope="col">Organization Name</th>
                      <th scope="col">App Version</th>
                      <th scope="col">Game World</th>
                      <th scope="col">Game Platform</th>
                      <th scope="col">No Of Matches</th>
                      <th scope="col">Set Live Toggle</th>
                      <th scope="col" className="head-border-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>  
                    {games && games.map((e)=>{
                        if(e.archive === false && e.isDraft === false){
                          return(
                            <tr key={e.id} className="table-body">
                            <td><b>{e.gameName}</b></td>
                            <td><b>{e.organisation != null ? e.organisation.organisationName : "-"}</b></td>
                            <td>{e.gameMinimumAppVersion !== null ? e.gameMinimumAppVersion : "-"}</td>
                            <td>{e.gamesWorld.length !== 0 ? e.gamesWorld[0].gameWorldName : "-"}</td>
                            <td>{e.gamePlatforms.length !=0 ? e.gamePlatforms && e.gamePlatforms.map((p,i)=>{return(<span>{(i ? ', ' : '') + p.platformName}</span>)}) : "-"}</td>
                            <td>{e.numberOfMatchesCreated != null || e.numberOfMatchesCreated != undefined ? e.numberOfMatchesCreated : ''}</td>
                            {/* <td><div className="form-switch"><input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={e.isLive === true ? true : e.isLive === false ? false : ""} onChange={(evt)=>{onToggleLive(evt, e.id)}}/></div></td> */}
                            <td><Switch checked={e.isLive === true ? true : e.isLive === false ? false : ""} onChange={(evt)=>{onToggleLive(evt, e.id)}}/></td>
                            <td>
                              <Dropdown className="ms-3">
                                <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <span className="icon icon-sm icon-dark">
                                    <FontAwesomeIcon icon={faEllipsisH} />
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu-right actions">
                                  <Dropdown.Item className="ms-0" onClick={()=>{onToggleGameDetail(e.id)}}>
                                    <FontAwesomeIcon icon={faEye} className="me-2 icon-color" /> View Details
                                  </Dropdown.Item>
                                  <Dropdown.Item className="ms-0" as={Link} to={{pathname:Routes.EditGame.path, id:e.id}}>
                                    <FontAwesomeIcon icon={faEdit} className="me-2 icon-color"/> Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item as={Link} className="ms-0 archive"  onClick={()=>{onArcheive(e.id)}}>
                                  <FontAwesomeIcon icon={faTrashAlt} className="me-2"/> Move to Archive
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                          )
                        }
                    })}
                    </tbody>
                  </table>
                  : isLoading === true && showall == true && games.length > 0 ? <Loader type="spinner-circle" bgColor={"#61DAFB"} title={"spinner-circle"} color={'#FFFFFF'} size={50} /> : isLoading == false && showall == true &&  games.length == 0 ? "No Data Available" : ""}
                  {showarchive === true && games && games.length !=0  ? 
                  <table className="table table-striped text-center table-custom align-middle">
                    <thead className="table-head">
                      <tr>
                        <th scope="col" className="head-border-left">Game Name</th>
                        <th scope="col">App Version</th>
                        <th scope="col">Game World</th>
                        <th scope="col">Game Platform</th>
                        {/* <th scope="col">Phase</th> */}
                        <th scope="col" className="head-border-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    {games && games.map((e)=>{
                        return(
                          <tr key={e.id} className="table-body">
                          <td><b>{e.gameName}</b></td>
                          <td>{e.gameMinimumAppVersion}</td>
                          <td>{e.gamesWorld.length !== 0 ? e.gamesWorld[0].gameWorldName : "-"}</td>
                          <td>{e.gamePlatforms.length !=0 ? e.gamePlatforms && e.gamePlatforms.map((p)=>{return(<span>{p.platformName}, </span>)}) : "-"}</td>
                          {/* <td>-</td> */}
                          <td>
                            <Dropdown className="ms-3">
                              <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="icon icon-sm icon-dark">
                                  <FontAwesomeIcon icon={faEllipsisH} />
                                </span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu-right">
                                <Dropdown.Item className="ms-0" onClick={()=>{onToggleGameDetail(e.id)}}>
                                  <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to={{pathname:Routes.EditGame.path, id:e.id}} className="ms-0">
                                  <FontAwesomeIcon icon={faEdit} className="me-2"/> Edit
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} className="text-danger ms-0"  onClick={()=>{onUnarchive(e.id)}}>
                                <FontAwesomeIcon icon={faArchive} className="me-2"/> Unarchive
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                        )
   
                    })}
                    </tbody>
                  </table>
                  : isLoading == true && showarchive == true && games.length > 0  ? <Loader type="spinner-circle" bgColor={"#61DAFB"} title={"spinner-circle"} color={'#FFFFFF'} size={50} /> : isLoading == false && showarchive == true && games.length === 0 ? "No Data Available" : ""}
                  {showdraft === true && games && games.length !=0  ? 
                  <table className="table table-striped text-center table-custom align-middle">
                    <thead className="table-head">
                      <tr>
                        <th scope="col" className="head-border-left">Game Name</th>
                        <th scope="col">App Version</th>
                        <th scope="col">Game World</th>
                        <th scope="col">Game Platform</th>
                        {/* <th scope="col">Phase</th> */}
                        <th scope="col" className="head-border-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    {games && games.map((e)=>{
                      if(e.isDraft === true){
                        return(
                          <tr key={e.id} className="table-body">
                          <td><b>{e.gameName}</b></td>
                          <td>{e.gameMinimumAppVersion != null ? e.gameMinimumAppVersion : "-"}</td>
                          <td>{e.gamesWorld.length !== 0 ? e.gamesWorld[0].gameWorldName : "-"}</td>
                          <td>{e.gamePlatforms.length !=0 ? e.gamePlatforms && e.gamePlatforms.map((p)=>{return(<span>{p.platformName}, </span>)}) : "-"}</td>
                          {/* <td>-</td> */}
                          <td>
                            <Dropdown className="ms-3">
                              <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="icon icon-sm icon-dark">
                                  <FontAwesomeIcon icon={faEllipsisH} />
                                </span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu-right">
                                <Dropdown.Item className="ms-0" onClick={()=>{onToggleGameDetail(e.id)}}>
                                  <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to={{pathname:Routes.EditGame.path, id:e.id}} className="ms-0">
                                  <FontAwesomeIcon icon={faEdit} className="me-2"/> Edit
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                        )
                      }
                        
                    })}
                    </tbody>
                  </table>
                  : isLoading == true && showdraft == true && games.length > 0 ? <Loader type="spinner-circle" bgColor={"#61DAFB"} title={"spinner-circle"} color={'#FFFFFF'} size={50} /> : showdraft == true && isLoading == false && games.length === 0 ? "No Data Available"  : ""}
              </Col>
          </Row>
        </div>

        <div className="d-flex justify-content-left m-4">
          <Row>
            <Col>
                <Pagination className="paginate"
                count={counts} 
                defaultPage={page} 
                color="info" 
                variant="outlined" shape="rounded"
                showFirstButton={false} 
                showLastButton={false} 
                onChange={(event, value)=> setPage(value)}
                />
            </Col>
          </Row>
        </div>
        {/* {showall ? 
        <div className="d-flex justify-content-left m-4">
          <Row>
            <Col>
                <Pagination className="paginate"
                count={counts} 
                defaultPage={page} 
                color="info" 
                variant="outlined" shape="rounded"
                showFirstButton={false} 
                showLastButton={false} 
                onChange={(event, value)=> setPage(value)}
                />
            </Col>
          </Row>
        </div> : ""} */}
      <FilterModal show={addfilter} onHide={onAddFilter} filterGames={gamesFilterData}/>
      <GamedetailModal id ={GameId}  show={gamedetail} onHide={onToggleGameDetail} />
      {showdeletemodal ? <DeleteModal addOpen={showdeletemodal} Addtoggle={onToggleDelete} /> : ""}
      {livetoggle ? <LivetoggleModal addOpen={livetoggle} Addtoggle={onToggleLive} save={savelive} cancel={cancellive} /> : ""}
      {showStopModel ? <StopGameModal addOpen={showStopModel} Addtoggle={onToggleLive} save={savelive} cancel={cancellive}/> : ""}
    </div>
    </>
  )
};

export default Games; 


