import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faSearch, faPlusCircle, faTrash, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col,InputGroup, Row, Nav, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import { Breadcrumb } from "react-bootstrap";
import { Link , useHistory} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import customAxios from '../../../utlis/interceptor';
import { BASE_URL, GET_ALL_CONDITIONTYPE, GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE } from '../../../resources/APIEndpoints';
import axios from 'axios';
import { createAttributeAction, getAllAttributeDetailAction } from '../../../redux/action/businessLogicAction';
import { Routes } from '../../../routes';

export default function EditAttribute(props) {
    const dispatch = useDispatch();
    const history = useHistory()

    const [allDataType, setAllDataType] = useState()
    const [attributeName, setAttributeName] = useState("")
    const [dataType, setDataType] = useState()
    const [attribute, setAttribute] = useState()

    const getDataType = async() => {
        let res = await axios.get('https://dev.gamestarz.xyz/v1/static-values')
        setAllDataType(res.data.dataTypeIds)
    }

    const getAttributeById = () => {
        let attributeDetail = {
            conditionTypeAttributeFilters : {
                id : [props.location.id],
                pagination : {
                    offset : 0,
                    limit : 100
                }
            }
        }

        customAxios.post(BASE_URL + GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE, attributeDetail)
        .then(res=>{
            setAttribute(res.data.attributeDetails)
            setAttributeName(res.data.attributeDetails[0].attributeName)
            setDataType(res.data.attributeDetails[0].dataTypeMaster.id)
        })
        
    }

    useEffect(()=> {
        getDataType();
        getAttributeById();
    }, [])

    const editAttribute = () => {
        let editAttribute = {
            attributeName : attributeName,
            dataTypeId : dataType
        }

        dispatch(createAttributeAction(editAttribute, props.history))
    }

    const cancel = () => {
        history.push(Routes.BusinessLogic.path)
    }


    if(attribute && attribute.length > 0){
        return (
            <>
                <ToastContainer />
                <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                    <Row>
                        <Col lg={12}>
                            <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                                <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                                <Breadcrumb.Item href="#business-logic">Business Logic</Breadcrumb.Item>
                                <Breadcrumb.Item className="text-dark" active>Edit Attribute</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </div>
        
                <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded gamestarz">
    
                    <div className="container-fluid payout">
                        <div className="my-3">
                            <div className='container'>
                                <Row className='my-3'>
                                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                      <h4>Edit Attribute</h4>
                                  </Col>
                                </Row>
                                <Row className='my-3'>
                                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                        <label>Attribute Name</label>
                                        <input type='text' className='form-control' defaultValue={attributeName} onChange={(e)=>{setAttributeName(e.target.value)}} />
                                    </Col>
                                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                        <label>Data Type</label>
                                        <select className='form-select' onChange={(e)=>{setDataType(e.target.value)}}>
                                            <option value={attribute[0].dataTypeMaster.id}>{attribute[0].dataTypeMaster.dataTypeName}</option>
                                            {allDataType && allDataType.map((e,i)=>{
                                                return(
                                                    <option key={i} value={e.id}>{e.dataTypeName}</option>
                                                )
                                            })}
                                        </select>
                                    </Col>
                                </Row>
    
                                <Row className='my-3'>
                                    <Col>
                                        {/* <button className='btn btn-success' onClick={editAttribute}>Edit</button> */}
                                        <button className='btn btn-danger mx-3' onClick={cancel}>Cancel</button>
                                    </Col>
                                </Row>
                            </div>
                        </div> 
                    </div>
                </div>
            </>
        )
    }else{
        return(
            <p></p>
        )
    }
}