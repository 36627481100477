import React, {useEffect, useState} from 'react'
import {Dropdown, Row, Col, ButtonGroup, Button, InputGroup, Form, Nav} from '@themesberg/react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheckCircle, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../utlis/interceptor';
import {getPartnersByIdAction, verifyPartnerAction} from '../../redux/action/organisationAction.js'
import VerifyOrgModal from '../modals/OrganizationModal/VerifyOrgModal';

function ViewOrganisation(props) {

  const dispatch = useDispatch()
  const history = useHistory()

  const [partnersName, setPartnersName] = useState("")

  useEffect(()=> {
    getParntnerById();
  }, [])
  
  const getParntnerById = () => {
    let partnersDetail = {
      gameAdminSubmissionFilters : {
        id : [props.location.id],
        pagination : {
          offset : 0,
          limit : 100
        }
    }
    }
    dispatch(getPartnersByIdAction(partnersDetail))
  }

  let partnersDetail = useSelector((state)=>{ 
    return state.organization.partnersDetail
  })

  const [showVerifymodal, setShowVerifyModal] = useState(false)

  const onToggleVerify = (Detail) => {
    setPartnersName(Detail)
    setShowVerifyModal(!showVerifymodal)
  }

  const verify = () => {

    let verifyOrganization = {
      id : props.location.id,
      isOrgVerified : true
    }
    dispatch(verifyPartnerAction(verifyOrganization, props.location.id, setShowVerifyModal))

  }


  if(partnersDetail && partnersDetail.length){
    return (
        <>
        <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                    <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                        <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                        <Breadcrumb.Item href='#partners'>partners</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-dark" active>View Partners</Breadcrumb.Item>
                    </Breadcrumb>
                    </Col>
                </Row>
            </div>
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0"></Col>
            </div>
                        
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5 gamedetail-model">
                <div className="container gamedetail-body">
                  <div>
                  <Row className='my-2 d-flex justify-content-around'>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        <h5>Personal Detail</h5>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6} className='text-right'>
                        {partnersDetail[0].isOrgVerified == false ?
                          <button className='btn btn-sm btn-outline-success' onClick={(e)=>{onToggleVerify(partnersDetail)}}>Verify</button>
                        : partnersDetail[0].isOrgVerified == true ?
                          <div className='text-success font-weight-bold text-italic'>
                            <span><FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon></span> <span><b>Verified</b></span>
                          </div>
                        : ""}
                    </Col>
                  </Row>
                  </div>
                  <Row className="mt-3">
                    <Col lg={6} md={12} sm={12}>
                      <label>Organization id</label>
                      <input type='text' disabled className='form-control' defaultValue={partnersDetail[0].id != null ? partnersDetail[0].id : "-"}/> 
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <label>Organization Name</label>
                      <input type='text' disabled className='form-control' defaultValue={partnersDetail[0].organisationName != null ? partnersDetail[0].organisationName : partnersDetail[0].organisationName == null ? partnersDetail[0].firstName + "_" +  partnersDetail[0].lastName  : "-"}/> 
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col lg={6} md={12} sm={12}>
                      <label>First Name</label>
                      <input type='text' disabled className='form-control' defaultValue={partnersDetail[0].firstName != null ? partnersDetail[0].firstName : "-"}/> 
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <label>Last Name</label>
                      <input type='text' disabled className='form-control' defaultValue={partnersDetail[0].lastName != null ? partnersDetail[0].lastName  : "-"}/> 
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col lg={6} md={12} sm={12}>
                      <label>Contact</label>
                      <input type='text' disabled className='form-control' defaultValue={partnersDetail[0].contactNo != null ? partnersDetail[0].contactNo : "-"}/> 
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <label>Email</label>
                      <input type='text' disabled className='form-control' defaultValue={partnersDetail[0].email != null ? partnersDetail[0].email : "-"}/> 
                    </Col>
                  </Row>

                  <Row className='mt-5'>
                    <Col>
                        <h5>Organization Detail</h5>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col lg={6} md={12} sm={12}>
                      <label>Organization Name</label>
                      <input type='text' disabled className='form-control' defaultValue={partnersDetail[0].organisationName != null ? partnersDetail[0].organisationName : partnersDetail[0].organisationName == null ? partnersDetail[0].firstName + " " +  partnersDetail[0].lastName  : "-"}/> 
                    </Col>

                    <Col lg={6} md={12} sm={12}>
                      <label>Organisation Registration No</label>
                      <input type='text' disabled className='form-control' defaultValue={partnersDetail[0].organizationRegistrationNo != null ? partnersDetail[0].organizationRegistrationNo : "-"}/> 
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col lg={6} md={12} sm={12}>
                      <label>Organisation PAN No</label>
                      <input type='text' disabled className='form-control' defaultValue={partnersDetail[0].organizationPanNo != null ? partnersDetail[0].organizationPanNo : "-"}/> 
                    </Col>
                    <Col lg={6} md={12} sm={12}>  
                      <label>Organisation Gst No</label>
                      <input type='text' disabled className='form-control' defaultValue={partnersDetail[0].organizationGstNo != null ? partnersDetail[0].organizationGstNo : "-"}/> 
                    </Col>

                  </Row>

                  <Row className="mt-3">
                    <Col lg={6} md={12} sm={12}>
                      <label>Playstore Link</label>
                      <input type='text' disabled className='form-control' defaultValue={partnersDetail[0].organizationPlaystoreUrl != null ? partnersDetail[0].organizationPlaystoreUrl : "-"}/> 
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <label>Appstore Link</label>
                      <input type='text' disabled className='form-control' defaultValue={partnersDetail[0].organizationAppstoreUrl != null ? partnersDetail[0].organizationAppstoreUrl : "-"}/> 
                    </Col>
                  </Row>

                  <Row className='my-3'>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Row>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                          <h5>Registered Address</h5>
                        </Col>
                      </Row>
                      <Row className='my-1 d-flex justify-content-center'>
                          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                              <label className='mb-2'>Address Line 1</label><span className='text-danger'>*</span>
                              <input type='text' disabled placeholder="Address Line 1" defaultValue={partnersDetail[0].organizationAddressMapping.length == 0 ? "" : partnersDetail[0].organizationAddressMapping && partnersDetail[0].organizationAddressMapping.length > 0 && partnersDetail[0].organizationAddressMapping[0].addressTypeMasterId == 1 ? partnersDetail[0].organizationAddressMapping[0].address.addressLineOne : partnersDetail[0].organizationAddressMapping  && partnersDetail[0].organizationAddressMapping.length > 0 && partnersDetail[0].organizationAddressMapping[1].addressTypeMasterId == 1 ? partnersDetail[0].organizationAddressMapping[1].address.addressLineOne : ''} className='form-control' />
                          </Col>
                          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                              <label className='mb-2'>Address Line 2</label><span className='text-danger'>*</span>
                              <input type='text' disabled placeholder="Address Line 2" defaultValue={partnersDetail[0].organizationAddressMapping.length == 0 ? "" : partnersDetail[0].organizationAddressMapping && partnersDetail[0].organizationAddressMapping.length > 0 && partnersDetail[0].organizationAddressMapping[0].addressTypeMasterId == 1 ? partnersDetail[0].organizationAddressMapping[0].address.addressLineTwo : partnersDetail[0].organizationAddressMapping  && partnersDetail[0].organizationAddressMapping.length > 0 && partnersDetail[0].organizationAddressMapping[1].addressTypeMasterId == 1 ? partnersDetail[0].organizationAddressMapping[1].address.addressLineTwo : ''}  className='form-control' />
                          </Col>
                      </Row>
                      <Row className='my-3 d-flex justify-content-center'>
                          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                              <label className='mb-2'>Area name/ flat no / apartment no</label>
                              <input type='text' disabled placeholder="Area name/ flat no / apartment no" defaultValue={partnersDetail[0].organizationAddressMapping.length == 0 ? "" : partnersDetail[0].organizationAddressMapping && partnersDetail[0].organizationAddressMapping.length > 0 && partnersDetail[0].organizationAddressMapping[0].addressTypeMasterId == 1 ? partnersDetail[0].organizationAddressMapping[0].address.areaName : partnersDetail[0].organizationAddressMapping  && partnersDetail[0].organizationAddressMapping.length > 0 && partnersDetail[0].organizationAddressMapping[1].addressTypeMasterId == 1 ? partnersDetail[0].organizationAddressMapping[1].address.areaName : ''}  className='form-control' />
                          </Col>
                          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                              <label className='mb-2'>Country</label><span className='text-danger'>*</span>
                              <input type='text' disabled placeholder="Country" className='form-control' />
                          </Col>
                      </Row>
                      <Row className='my-3 d-flex justify-content-center'>
                          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <label className='mb-2'>State</label><span className='text-danger'>*</span>   
                            <input type='text' disabled placeholder="State"  className='form-control' />                     
                          </Col>
                          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                              <label className='mb-2'>City</label><span className='text-danger'>*</span>
                              <input type='text' disabled placeholder="City" defaultValue={partnersDetail[0].organizationAddressMapping.length == 0 ? "" : partnersDetail[0].organizationAddressMapping && partnersDetail[0].organizationAddressMapping.length > 0 && partnersDetail[0].organizationAddressMapping[0].addressTypeMasterId == 1 ? partnersDetail[0].organizationAddressMapping[0].address.city : partnersDetail[0].organizationAddressMapping  && partnersDetail[0].organizationAddressMapping.length > 0 && partnersDetail[0].organizationAddressMapping[1].addressTypeMasterId == 1 ? partnersDetail[0].organizationAddressMapping[1].address.city : ''} className='form-control' />
                          </Col>
                      </Row>
                      <Row className='mt-3 d-flex justify-content-center'>
                          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                              <label className='mb-2'>Pincode</label><span className='text-danger'>*</span>
                              <input disabled placeholder='Pincode' defaultValue={partnersDetail[0].organizationAddressMapping.length == 0 ? "" : partnersDetail[0].organizationAddressMapping && partnersDetail[0].organizationAddressMapping.length > 0 && partnersDetail[0].organizationAddressMapping[0].addressTypeMasterId == 1 ? partnersDetail[0].organizationAddressMapping[0].address.pinCode : partnersDetail[0].organizationAddressMapping  && partnersDetail[0].organizationAddressMapping.length > 0 && partnersDetail[0].organizationAddressMapping[1].addressTypeMasterId == 1 ? partnersDetail[0].organizationAddressMapping[1].address.pinCode : ''} type='text' className='form-control'/>
                          </Col>
                          <Col>

                          </Col>
                      </Row>
                  </Col>
              </Row>

                  <Row className='mt-5'>
                    <Col>
                        <h5>Account Detail</h5>
                    </Col>
                  </Row>

                  <Row className='my-3 d-flex justify-content-center'>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                          <label className='mb-2'>Bank Name</label>
                          <input type='text' disabled placeholder='Bank Name' defaultValue={partnersDetail[0].organizationBankDetailsMapping.length == 0 ? "" :  partnersDetail[0].organizationBankDetailsMapping && partnersDetail[0].organizationBankDetailsMapping.length > 0 ? partnersDetail[0].organizationBankDetailsMapping[0].bankName : ''} name='bankName' className='form-control input-bg' />
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                          <label className='mb-2'>Account Number</label>
                          <input type='text' disabled placeholder='Account Number' defaultValue={partnersDetail[0].organizationBankDetailsMapping.length == 0 ? "" :  partnersDetail[0].organizationBankDetailsMapping && partnersDetail[0].organizationBankDetailsMapping.length > 0 ? partnersDetail[0].organizationBankDetailsMapping[0].accountNumber : ''} name='accountNumber' className='form-control input-bg' />
                      </Col>
                  </Row>


                  <Row className='my-3 d-flex justify-content-center'>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                          <label className='mb-2'>Account Holder Name</label>
                          <input type='text' placeholder='Account Holder Name' disabled defaultValue={partnersDetail[0].organizationBankDetailsMapping.length == 0 ? "" :  partnersDetail[0].organizationBankDetailsMapping && partnersDetail[0].organizationBankDetailsMapping.length > 0 ? partnersDetail[0].organizationBankDetailsMapping[0].bankAccountName : ''}  name='accountName' className='form-control input-bg' />
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                          <label className='mb-2'>IFSC Code</label>
                          <input type='text' placeholder='IFSC Code' disabled defaultValue={partnersDetail[0].organizationBankDetailsMapping.length == 0 ? "" :  partnersDetail[0].organizationBankDetailsMapping && partnersDetail[0].organizationBankDetailsMapping.length > 0 ? partnersDetail[0].organizationBankDetailsMapping[0].ifscCode : ''} name='ifsc' className='form-control input-bg' />
                      </Col>
                  </Row>
                  
                </div>
                {showVerifymodal ? <VerifyOrgModal Verify={verify} Detail={partnersName} addOpen={showVerifymodal} Addtoggle={onToggleVerify}/> : ""}
            </div>
        </>
    )
    }else{
        return(<p></p>)
    }
}

export default ViewOrganisation