import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {Row, Col, ButtonGroup, Button, InputGroup, Form, Pagination, Nav} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch, faPlus} from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../routes";
import { Link } from 'react-router-dom';
import {Game_Id,Game_Name, Game_Logo, Game_Description, Game_How_to, Preview_Video, Game_Screenshots, Game_Platform, Asset_Bundle_Version, Minimum_App_Version, Game_Genre, Screen_Orientation, Game_World, Matches_Created, Match_Name, Match_Format, Match_How_To, Match_Outcome_Type, Leaderboard_Outcome_Type, Included_Countries, Phase, Save, Save_Match, Save_As_Draft, Cancel, Min_Players, Max_Players, Podium_Rank_Match_Outcome, Podium_Rank_Leaderboard_Outcome, Rank, Points, Win_Points, Draw_Points, Loss_Points} from './../constants/LabelConstants'
import XLSX from 'xlsx'
import MaterialTable from "material-table";
import  TablePagination  from './components/TablePagination';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Checkbox, Select, TextField } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CountryModal from './modals/CountryModal';
import { getallCompetitionAction, editCompetitionAction } from '../redux/action/competitionAction';
import { getallGamesAction, GameByIdAction } from '../redux/action/gameAction';
import axios from '../utlis/interceptor';
import { BASE_URL, GET_COMPETITION } from '../resources/APIEndpoints';
import { useHistory } from 'react-router-dom';
import { ToastContainer, Toast, toast } from 'react-toastify';
import { Breadcrumb } from "react-bootstrap";
import moment from 'moment';

function EditCompetition(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const Countrydata = [{CountryName: 'India', id:1}]
    const[country, setCountry] = useState(Countrydata)
    const[countrynew, setCountryNew] = useState()

    const icon = <CheckBoxOutlineBlankIcon fontSize = "small" />
    const checkedIcon = <CheckBoxIcon fontSize = "small" />

    const [Competition, setCompetition] = useState('')


    // Competition Detail By Id
    const [CompId, setCompId] = useState("")
    useEffect(() => {
        setCompId(props.location.id)

        const competitionById = {
            competitionFilters : {
                id: props.location.id,
                pagination:{

                }
             }
          }
        dispatch(getallCompetitionAction(competitionById));
          
    }, [])

    const [GameName, setGameName] = useState("")
    const [competitionName, setCompetitioName] = useState("")
    const [attempts, setAttempts] = useState("")
    const [entryFeeTotal, setEntryFeeTotal] = useState("")
    const [bonusWallet, setBonusWallet] = useState("")
    const [minPlayer, setMinPlayer] = useState("")
    const [maxPlayer, setMaxPlayer] = useState("")
    const [PDRule, setPDRule] = useState("")
    const [NoEntry, setNoEntry] = useState("")
    const [NoWinner, setNoWinner] = useState("")
    const [HostingFee, setHostingFee] = useState("")
    const [HostingType, setHostingType] = useState("")
    const [matchName, setMatchName] = useState("")
    const [matchId, setMatchId] = useState("")
    const [GameId, setGameId] = useState("")
    const [PDMode, setPDMode] = useState("")
    const [DateTime, setDateTime] = useState("")
    const [startDate, setStartDate] = useState("")
    const [startTime, setStartTime] = useState("")
    const [endDate, setEndDate] = useState('')
    const [endTime, setEndTime] = useState('')
    const [prizeDistributionOffset, setPrizeDistributionOffset] = useState()
    const [compEvent, setCompEvent] = useState("")
    const [totalAmount, setTotalAmount] = useState("")

    useEffect(() => {
        if(props.location.id){
        const competitionById = {
            competitionFilters : {
                id: [props.location.id],
                pagination: {}
             }
          }

        axios.post(BASE_URL + GET_COMPETITION, competitionById)
        .then(res => {
            setCompetition(res.data.competitionData[0].competitionFormatTypeMasterId)
            setGameName(res.data.competitionData[0].game.gameName)
            setGameId(res.data.competitionData[0].game.id)
            setCompetitioName(res.data.competitionData[0].competitionName)
            setMatchName(res.data.competitionData[0].match.matchName)
            setMatchId(res.data.competitionData[0].match.id)
            setEntryFeeTotal(res.data.competitionData[0].entryFee)
            setBonusWallet(res.data.competitionData[0].maxBonusLimit)
            setMinPlayer(res.data.competitionData[0].minPlayers)
            setMaxPlayer(res.data.competitionData[0].maxPlayers/res.data.competitionData[0].totalNumberOfSlot)
            setPDRule(res.data.competitionData[0].prizeDistributionRule)
            setNoEntry(res.data.competitionData[0].maxEntryAllowed)
            setNoWinner(res.data.competitionData[0].numberOfWinner)
            setStartDate(moment(res.data.competitionData[0].startDate).tz('Asia/Kolkata').format().slice(0,10))
            setEndDate(moment(res.data.competitionData[0].endDate).tz('Asia/Kolkata').format().slice(0,10))
            setStartTime(moment(res.data.competitionData[0].startDate).tz('Asia/Kolkata').format().slice(11,19))
            setEndTime(moment(res.data.competitionData[0].endDate).tz('Asia/Kolkata').format().slice(11,19))
            setHostingFee(res.data.competitionData[0].hostingFee)
            setHostingType(res.data.competitionData[0].hostingFeeType)
            setPDMode(res.data.competitionData[0].prizeDistributionMode)
            setPrizeDistributionOffset(res.data.competitionData[0].prizeDistributionOffset)
            setSlot(res.data.competitionData[0].totalNumberOfSlot)
            setHours(Math.floor((res.data.competitionData[0].prizeDistributionOffset % (3600 * 24))/ 3600))
            setMinutes(Math.floor((res.data.competitionData[0].prizeDistributionOffset % 3600)/60))
            setPDSchedule(res.data.competitionData[0].prizeDistributionScheduled)
            setAttempts(res.data.competitionData[0].maxAttemptAllowed)
            setTotalAmount(res.data.competitionData[0].totalAmount)
            if(res.data.competitionData[0].competitionFormatTypeMasterId == 2){
                setCompEvent('on competition end')
            }else if(res.data.competitionData[0].competitionFormatTypeMasterId == 3){
                setCompEvent('on match end')
            }
        })
    }
    }, [])
    



    // Game Detail
    useEffect(() => {
        let gamesNew = {
            gameFilters:{
        
            },
            entities:[{
                value: "Match",
                attributes : ["id","matchName"]
            }]
        }
        dispatch(getallGamesAction(gamesNew))
    }, [])  

    let games = useSelector((state)=>{ 
        return state.game.games
    })

    const onCompetitionChange = (e) => {
        setCompetition(parseInt(e.target.value))
    }

    let competition = useSelector((state)=>{ 
        return state.competition.competitions
    })


    const[colTitle, setColTitle] = useState()
    const[data, setData] = useState([])
    const convertToJson = (headers, data) =>{
        const rows = []
        data.forEach(row=>{
            let rowData = {}
            row.forEach((ele, index)=>{
                rowData[headers[index]] = ele;
            })
            rows.push(rowData)
        })
        return rows
    }

    const onCSV = (e) => {
        var file = e.target.files[0];

        const reader = new FileReader()
        reader.onload=(event)=>{
            const bstr = event.target.result
            const workBook = XLSX.read(bstr, {type:"binary"})

            const worksheetName = workBook.SheetNames[0]
            const workSheet =workBook.Sheets[worksheetName]

            const fileData = XLSX.utils.sheet_to_json(workSheet, {header:1})

            const headers =fileData[0]
            fileData.splice(0,1)

            setData(convertToJson(headers, fileData))
            setPDRule(convertToJson(headers, fileData))

            const heads = headers.map(head=>({title:head, field: head}))
            setColTitle(heads)
        }
        reader.readAsBinaryString(file)  
    }

    const onGameName = (e) => {
        setGameName(e.target.value)
        let GameNameFilter = {
            gameFilters: {
                id: [e.target.value],
                pagination : {}
            }
        }
        dispatch(GameByIdAction(GameNameFilter))
        setMatchId("")
        setMatchName("")
    }

    let gamesDetail = useSelector((state)=>{ 
        return state.game.gameDetail
    })

    const [currentPage, setCurrentPage] = useState(1);
    const [entryPerPage, setEntryPerPage] = useState(10)


    const indexOfLastEntry = currentPage * entryPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entryPerPage;
    const currentEntry = data.slice(indexOfFirstEntry, indexOfLastEntry)
 
    const Paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const changePageEntry = (e) => {
        const chanegedPage = parseInt(e.target.value)
        setEntryPerPage(chanegedPage)
    }

    const [deleteShow, setDeleteShow] = useState(false);

    const handleDeleteShow = () => {
        setDeleteShow(true)
    }

    const handleDeleteClose = () => {
        setDeleteShow(false)
    }

    const [stateData, setStateData] = useState([])

    const a = (data) =>{
        setStateData(data)
    }

    const Redirect = () => {
        history.push('/competition');
        window.location.reload();
    }

    const [specialEvents, setSpecialEvents] = useState(false)
    const setSpecialEvent = () => {
        setSpecialEvents(!specialEvents)
    }

    const [Hours, setHours] = useState("")
    const [HoursError, setHoursError] = useState(false)
    const onHours = (e) =>{
        setHours(parseInt(e.target.value))
        setHoursError(false)
    }
    const [minutes, setMinutes] = useState("")
    const [minutesError, setMinutesError] = useState(false)
    const onMinutes = (e) =>{
        setMinutes(parseInt(e.target.value));
        setMinutesError(false);
    }


    const updateCompetition = () => {

        let startDateData = startDate.concat(" ", startTime)
        let endDateData = endDate.concat(" ", endTime)

        let utcStartDate = moment.utc(moment(startDateData)).format("YYYY-MM-DD HH:mm:ss")
        let utcEndDate = moment.utc(moment(endDateData)).format("YYYY-MM-DD HH:mm:ss")

        let seconds = (Hours * 60 * 60) + (minutes * 60)
        let maximumPlayers = (maxPlayer*slot)

        if(competition[0].competitionName == competitionName){
            const editCompetitionData = {
                id : props.location.id,
                competitionFormatTypeMasterId : Competition,
                gameId : GameId,
                matchId : matchId,
                // competitionName : competitionName,
                minPlayers : minPlayer,
                maxPlayers : maximumPlayers,
                maxEntryAllowed : NoEntry,
                maxAttemptAllowed : attempts,
                entryFee : entryFeeTotal,
                maxBonusLimit : bonusWallet,
                numberOfWinner : NoWinner,
                hostingFee : HostingFee,
                hostingFeeType : HostingType,
                prizeDistributionMode: PDMode,
                prizeDistributionRule : PDRule, 
                startDate: utcStartDate.concat(" ",  "+00:00"),
                endDate: utcEndDate.concat(" ",  "+00:00"),
                isSpecialEvent : specialEvents,
                prizeDistributionOffset : parseInt(seconds),
                totalNumberOfSlot : slot,
                prizeDistributionScheduled : pdSchdule,
                competitionCompletionEvent : pdSchdule,
                totalAmount: totalAmount,                
            }
            dispatch(editCompetitionAction(editCompetitionData, props.history))
        }else{
            const editCompetitionData = {

                id : props.location.id,
                gameId : GameId,
                matchId : matchId,
                competitionFormatTypeMasterId : Competition,
                competitionName : competitionName,
                minPlayers : minPlayer,
                maxPlayers : maximumPlayers,
                maxEntryAllowed : NoEntry,
                maxAttemptAllowed : attempts,
                entryFee : entryFeeTotal,
                maxBonusLimit : bonusWallet,
                numberOfWinner : NoWinner,
                hostingFee : HostingFee,
                hostingFeeType : HostingType,
                prizeDistributionMode: PDMode,
                prizeDistributionRule : PDRule, 
                startDate: utcStartDate.concat(" ",  "+00:00"),
                endDate: utcEndDate.concat(" ",  "+00:00"),
                isSpecialEvent : specialEvents,
                prizeDistributionOffset : parseInt(seconds),
                totalNumberOfSlot : slot,
                prizeDistributionScheduled : pdSchdule,
                competitionCompletionEvent : pdSchdule,
                totalAmount: totalAmount,
            }
            dispatch(editCompetitionAction(editCompetitionData, props.history))
        }
    }


    const cancel = () => {
        history.push('/competition')
    }

    const[slot, setSlot] = useState("")

    const onSlot = (e) => {
        setSlot(parseInt(e.target.value))
    }

    const[pdSchdule, setPDSchedule] = useState("")

    const onPDSchedule = (e) => {
        setPDSchedule(e.target.value)
    }


    const GenratePD = async() => {
        let getPD = {
            playersNumber: maxPlayer,
            entryFee: entryFeeTotal,
            hostingFee: (HostingFee/100)
        }
        
        if(getPD.playersNumber == '' || getPD.playersNumber == undefined || getPD.playersNumber == null || getPD.entryFee == '' || getPD.entryFee == undefined || getPD.entryFee == null || getPD.hostingFee == 0 || getPD.hostingFee == undefined || getPD.hostingFee == null){
            toast.error(`Maximum Players, Entry Fee and Hosting Fee is required`)
        }else{
            let res = await axios.post('https://staging.gamestarz.xyz/v1/admin/generatePrizeDistribution', getPD)
            setData(res.data.prizeDistributionData)
        }
    }

    if(competition && competition.length != 0){
    return (
        <>
        <ToastContainer />
        <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
            <Row>
                <Col lg={12}>
                <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                    <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                    <Breadcrumb.Item href='#competition'>Competition</Breadcrumb.Item>
                    <Breadcrumb.Item>Edit Competition</Breadcrumb.Item>
                    <Breadcrumb.Item className="text-dark" active>{competitionName}</Breadcrumb.Item>
                </Breadcrumb>
                </Col>
            </Row>
        </div>
        <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
                    
                </Col>
            </div>
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5 edit-competition">
                <div className="container">
                    <Row className="mb-3">
                        <Col lg={12} md={12}>
                            <p className='page-title'>Edit Competition</p>
                        </Col>
                    </Row>

                    <Row className="my-3">
                        <Col lg={6} md={6}>
                            <label>Select Game</label>
                            <select className="form-control input_bg" onChange={(e)=>{onGameName(e)}}>
                                <option value={GameId !="" ? GameId : ""}>{GameName}</option>
                                {games && games.map((e, index)=>{
                                    return(<option key={index} value={e.id}>{e.gameName}</option>)
                                })}
                            </select>
                        </Col>
                        <Col lg={6} md={6}>
                            <label>Select Matches</label>
                            <select className="form-select input_bg" onChange={(e)=>{setMatchId(e.target.value)}}>
                                <option value={matchId !="" ? matchId : ""}>{matchName != "" ? matchName : "Select Match"}</option>
                                {gamesDetail.length === 1 ? 
                                    <option value={gamesDetail[0].matches[0].id}>{gamesDetail[0].matches[0].matchName}</option>
                                : gamesDetail.length > 1 ?
                                gamesDetail && gamesDetail.matches.map((e)=>{
                                    return(<option value={e.matchName}>{e.matchName}</option>)
                                })
                                : ""}
                            </select>
                        </Col>
                    </Row>

                    <Row className="my-3">
                        <Col lg={6} md={6} sm={6}>
                            <label>Competition Format</label>
                            <select disabled className="form-select input_bg" onChange={(e)=>{onCompetitionChange(e)}}>
                                <option value={Competition}>{Competition == 2 ? "Leaderboard" : Competition == 3 ? "Instant Battle" : Competition == 5 ? "Bracket" : ""}</option>
                                <option value="2">Leaderboard</option>
                                <option value="3">Instant Battle</option>
                                <option value="5">Bracket</option>
                            </select>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <label>Competition Name</label>
                            <input type="text" className="form-control input_bg" defaultValue={competitionName} onChange={(e)=>{setCompetitioName(e.target.value)}}/>
                         </Col>
                    </Row>
                    {Competition == 2 || Competition == 3 ? 
                        <div>
                            <Row className="my-3">
                                <Col lg={6} md={6} sm={12}>
                                    <label>Entry Fee Total</label>
                                    <input type="number" className="form-control input_bg" defaultValue={entryFeeTotal} onChange={(e)=>{setEntryFeeTotal(parseInt(e.target.value))}}/>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <label>Entry Fee Bonus Wallet Limit</label>
                                    <input type="number" className="form-control input_bg" defaultValue={bonusWallet}  onChange={(e)=>{setBonusWallet(parseInt(e.target.value))}}/>
                                </Col>
                            </Row>

                            <Row className="my-3">
                                <Col lg={6} md={6} sm={12}>
                                    <label>Minimum Players</label>
                                    <input type="number" min="1" className="form-control input_bg" defaultValue={minPlayer} onChange={(e)=>{setMinPlayer(parseInt(e.target.value))}}/>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <label>Maximum Players</label>
                                    <input type="number" min="1" className="form-control input_bg" defaultValue={maxPlayer} onChange={(e)=>{setMaxPlayer(parseInt(e.target.value))}}/>
                                </Col>
                            </Row>

                            <Row className="my-3">
                                <Col lg={6} md={6} sm={12}>
                                    <label>Number Of Attempts</label>
                                    <input type="number" min="1" className="form-control input_bg" defaultValue={NoEntry} onChange={(e)=>{setNoEntry(parseInt(e.target.value))}} />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <label>Number of Winners</label>
                                    <input type="number" min="1" className="form-control input_bg" defaultValue={NoWinner} onChange={(e)=>{setNoWinner(parseInt(e.target.value))}} />
                                </Col>
                            </Row>
                            <Row className="my-3">
                                <Col md={6} className='input-file'>
                                    <label>Prize Distribution</label> <br />
                                    <div className='d-flex'>
                                        <input id="PD" type="file" accept=".csv, .xlsx" className="form-control" onChange={(e)=>{onCSV(e)}}/>
                                        <label for="PD" className='label-btn'>Choose File</label>
                                        {/* <Button className='label-btn mx-3' onClick={GenratePD}>Genrate PD</Button>  */}
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <label>Prize Distribution Mode</label>
                                    <select className="form-select input_bg">
                                        <option value={PDMode != "" ? PDMode : ""}>{PDMode != "" ? PDMode : "Select PD Mode"}</option>
                                        <option value="Guaranteed">Guaranteed</option>
                                        <option value="Dynamic">Dynamic</option>
                                    </select>
                                </Col>
                            </Row>
                    {data && data !="" && data[0].startRank ? 
                        <Row>
                            <Col md={12}>
                                {/* <MaterialTable title="Prize Distribution" data={data} columns={colTitle} /> */}
                                <table className="table table-striped text-center table-custome">
                                <thead className='table-head'>
                                    <tr>
                                        <th className='head-border-left' scope="col">Rank</th>
                                        <th className='head-border-right' scope="col">Prize</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentEntry && currentEntry.map((e)=>{
                                        return(
                                            <tr className='table-body'>
                                                <td>{e.startRank} {e.endRank ? "-" : ""} {e.endRank}</td>
                                                <td>{e.amount}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                </table>
                            </Col>
                        </Row>
                    : ""}
                    {data && data != "" && data[0].startRank && data[0].amount ?
                        <Row>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <Col>
                                        <label style={{paddingRight: "10px"}}>Rows Per Page</label>
                                        <select className='entries' onChange={(e)=>{changePageEntry(e)}}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                        </select>
                                    </Col>
                                </div>
                                <div>
                                    <Col>
                                        <TablePagination entryPerPage={entryPerPage} totalEntry={data.length} Paginate={Paginate}/>
                                    </Col>
                                </div>
                            </div>
                        </Row>
                    : data && data != ""  ? <div><p className="text-danger font-weight-bold">Please upload valid file.</p></div> : ""}

                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <Row>
                                        <Col lg={6} md={6} sm={12}>
                                            <label>Start Date</label>
                                            <input type="date" className="form-control input_bg" min={new Date().toISOString().split('T')[0]} defaultValue={startDate} onChange={(e)=>{setStartDate(e.target.value)}}/>
                                            
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <label>Start Time</label>
                                            <input type="time" className="form-control input_bg" defaultValue={startTime}  onChange={(e)=>{setStartTime(e.target.value)}}/>
                                            
                                        </Col>
                                    </Row>
                                </Col>

                                <Col lg={6} md={6} sm={12}>
                                    <Row>
                                        <Col lg={6} md={6} sm={12}>
                                            <label>End Date</label>
                                            <input type="date" className="form-control input_bg" min={new Date().toISOString().split('T')[0]} defaultValue={endDate}  onChange={(e)=>{setEndDate(e.target.value)}} />
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <label>End Time</label>
                                            <input type="time" className="form-control input_bg" defaultValue={endTime} onChange={(e)=>{setEndTime(e.target.value)}}/>
                                            
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="my-3">
                                <Col lg={6} md={6} sm={12}>
                                    <label className="mb-1" for="IncludeCountry">Included Countries</label>
                                    <Autocomplete multiple disableCloseOnSelect filterSelectedOptions
                                    options={Countrydata} getOptionLabel={(option)=> option.CountryName}
                                    defaultValue={[Countrydata[0]]} onChange={(e,newvalue)=>{setCountryNew(newvalue);}}
                                    getOptionSelected={(option, value) => option.CountryName === value.CountryName}
                                    renderOption={(option, {selected})=>(
                                    <React.Fragment>
                                        <Checkbox icon={icon}
                                            checkedIcon={checkedIcon} style={{marginLeft: "10px"}}
                                            checked={selected}  allowSelectAll={true} onClick={() => handleDeleteShow()}/>
                                            {option.CountryName}
                                    </React.Fragment>  
                                    
                                    )}
                                    renderInput={(params)=>(<TextField {...params} variant="outlined" />)}
                                    />
                                </Col>

                                <Col lg={6} md={6} sm={12}>
                                    <label>Hosting Fee per User</label>
                                    <select className="form-select input_bg" onChange={(e)=>{setHostingType(e.target.value)}}>
                                        <option selected>{HostingType !="" ? HostingType : ""}</option>
                                        <option value="percentage">Percentage</option>
                                        <option value="flat">Flat</option>
                                    </select>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <div className="form-group mt-md-5">
                                        <label className="mb-1">Set Prize Distribution Schedule</label>
                                        {Competition == 2 ?
                                        <select value="on competition end" disabled className='form-select input_bg' onChange={(e)=>{onPDSchedule(e)}}>
                                            <option selected>{pdSchdule}</option>
                                            <option value="on match end">Per Slot</option>
                                            <option value="on competition end" selected>End of Competition</option>
                                        </select>
                                        : Competition == 3 ?
                                        <select className='form-select input_bg' onChange={(e)=>{onPDSchedule(e)}}>
                                            <option disabled value={pdSchdule != "" ? pdSchdule : ""}>{pdSchdule != "" && pdSchdule == "on match end" ? "Per Slot" : pdSchdule != "" && pdSchdule == "on competition end" ? "End of Competition" : "Select PD Schedule"}</option>
                                            <option value="on match end">Per Slot</option>
                                            <option value="on competition end">End of Competition</option>
                                        </select>
                                        : ""}
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <div className="form-group my-2">
                                        <label className="pr-3">Set Prize Distribution Date</label>
                                        <Row>
                                            <Col>
                                                <label className="my-2">Hours</label>
                                                <input type="number" min="0" className="form-control input_bg"  defaultValue={pdSchdule == 'on match end' ? 0 : Hours} disabled={pdSchdule == 'on match end' ? true : false}onChange={(e)=>{onHours(e)}}/>
                                            </Col>
                                            
                                            <Col>
                                                <label className="my-2">Minutes</label>
                                                <input type="number" min="0" max="60" className="form-control input_bg" defaultValue={pdSchdule == 'on match end' ? 0 : minutes} disabled={pdSchdule == 'on match end' ? true : false}onChange={(e)=>{onMinutes(e)}}/>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                
                            </Row>

                            <Row className='my-3'>
                                <Col lg={6} md={6} sm={12}>
                                    <Row>
                                        {HostingType === "percentage" ? 
                                        <Col lg={12} md={12} sm={12}>
                                            <label>Hosting Fee Per User (in %)</label>
                                            <input type = "number" className="form-control input_bg" defaultValue={HostingFee} onChange={(e)=>{setHostingFee(e.target.value)}} />
                                        </Col>
                                        : HostingType === "flat" ?
                                        <Col lg={12} md={12} sm={12}>
                                            <label>Hosting Fee Per User (for flat)</label>
                                            <input type = "number" className="form-control input_bg" defaultValue={HostingFee} onChange={(e)=>{setHostingFee(e.target.value)}} />
                                        </Col>: ""}
                                        
                                    </Row>
                                </Col>

                                <Col lg={6} md={6} sm={12}>
                                    <label>No of slots</label>
                                    <input type='number' min="1" defaultValue={slot} className='form-control input_bg' onChange={(e)=>{setSlot(e.target.value)}}/>
                                </Col>
                            </Row>

                            <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <label className='mb-1' for="noofEntry">Number Of Entries</label>
                                    <input className='form-control input_bg' type='number' defaultValue={attempts} disabled={(Competition == 3 || Competition == 2) ? true : false} onChange={(e)=>{setAttempts(parseInt(e.target.value))}}/>
                                </Col>
                            </Row>

                            <Row className='my-3'>
                                <Col lg={6} md={6} sm={12}>
                                    <label>Is Special Event</label> <span>&nbsp;&nbsp;</span>
                                    <input type='checkbox' onChange={(e)=>{setSpecialEvent(e)}} className='input_bg'/>
                                </Col>
                            </Row>

                        </div>
                    : ""}  
                     
                    <Row>
                        <Col>
                            <button onClick={updateCompetition} className="btn btn-success mx-3">Update</button>
                            <button className="btn btn-danger mx-3" onClick={cancel}>Cancel</button>
                        </Col>
                    </Row>
                </div>
            </div>
            <CountryModal show={deleteShow} onHide={handleDeleteClose} filter={a}></CountryModal>
        </>
    )
    }else{
        return(
            <div className="text-center">
                <p><p><button type="button" className="btn btn-success mx-4 text-center" onClick={Redirect}>To Main Page</button></p></p>
            </div>
        )
    }
}

export default EditCompetition