/* Games */
export const Game_Id = "Game ID"
export const Game_Name = "Game Name"
export const Game_Logo = "Game Logo"
export const Game_Description = "Game Description"
export const Game_How_to = "Game How-to"
export const Preview_Video = "Preview Video"
export const Game_Screenshots = "Game Screenshots"
export const Game_Platform = "Select Game Platform"
export const Asset_Bundle_Version = "Asset Bundle Version"
export const Minimum_App_Version = "Minimum App Version"
export const Game_Genre = "Game Genre"
export const Screen_Orientation = "Screen Orientation"
export const Game_World = "Game World"
export const Matches_Created = "Matches Created"
export const Match_Name = "Match Name"
export const Match_Format = "Select Match Format"
export const Min_Players = "Minimum Players"
export const Max_Players = "Maximum Players"
export const Match_How_To = "Match How-To"
export const Match_Outcome_Type = "Match Outcome Type"
export const Leaderboard_Outcome_Type = "Leaderboard Outcome Type"
export const Included_Countries = "Included Countries"
export const Phase = "Set Phase"
export const Save = "Save"
export const Save_Match = "Save Match"
export const Save_As_Draft = "Save As Draft"
export const Cancel = "Cancel"
export const Podium_Rank_Match_Outcome = "No of Podium Ranks (Match Outcome)"
export const Podium_Rank_Leaderboard_Outcome = "No of Podium Ranks (Leaderboard Outcome)"
export const Rank = "Rank"
export const Points = "Points"
export const Win_Points = "Win Points"
export const Draw_Points = "Draw Points"
export const Loss_Points = "Loss Points"
export const Game_Details = "Game Details"
export const States = "States"
export const Match_Details = "Match Details"

/* LeaderBoard */

export const Leaderboards = {
    Created_At : "Created At",
    Game_Name : "Game Name",
    Game_World : "Game World",
    Match_Name : "Match Name",
    Match_Format : "Match Format",
    Leaderboard_Outcome_Type : "Leaderboard Outcome Type",
    Leaderboard_Status : "Leaderboard Status",
    Match_Outcome_Type : "Match Outcome Type",
    Leaderboard_ID : "Leaderboard ID",
    PD : "Prize Distribution",
    Leaderboard_Name :"Leaderboard Name",
    Leaderboard_Class : "Leaderboard Class",
    Leaderboard_Status : "Leaderboard Status",
    Min_Playes : "Min_Playes",
    No_Of_Winners : "No of Winners",
    Team : "Team",
    Start_Date : "Start Date",
    End_Date : "End Date",
    Start_Time : "Start Time",
    End_Time : "End Time",
    Schedule_Type : "Schedule Type",
    PD_Date : "PD Date",
    Create_PD_Leaderboard : "Create PD Leadboard",
    Select_Leaderboard : "Select Leaderboard",
    Prize_Distribution : "Prize Distribution",
    Min_Players_PD : "Minimum Players for Prize",
    Choose_Schedule : "Choose Schedule",
    Times_Recurring : "No of Times Recurring",
    Set_PD_Date : "Set Prize Distribution Date",
    Hours : "Hours",
    Minutes : "Minutes",
    Player_Rank : "Player Rank",
    Player_ID : "PLAYER ID",
    Player_Score : "Player Score",
    Playstarz_Points : "Player's Playstaz Points",
}

export const Competitions = {
    Competition : "Competition",
    Competition_Name : "Competition Name",
    Create_Competition : "Create Competition",
    Competition_Format : "Competition Format",
    Entree_Fee : "Entree Fee",
    PD_Mode : "PD Mode",
    HOSTING_FEE_PER_USER : "Hosting Fee Per User",
    Status : "Status",
    Select_Game : "Select Game",
    Select_Match : "Select Match",
    Entry_Fee_Total : "Entry Fee Total",
    Entry_Fee_Bonus_Wallet_Limit : "Entry Fee Bonus Wallet Limit",
    No_Of_Entries : "Number of Entries",
    Winnings_Cut : "Winnings Cut",
}
