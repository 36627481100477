import {GET_DOCUMENTS, GET_DOCUMENTS_DETAIL, CREATE_DOCUMENTS, EDIT_DOCUMENTS, CLEAR_DOCUMENT_DETAIL} from '../actionType/actionTypes'

const initialState = {
    allDocuments : [],
    documentDetail : [],
    isLoading : false
}

export const documentsReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_DOCUMENTS : {
            return {
                ...state,
                allDocuments : action.payload
            }
        }

        case GET_DOCUMENTS_DETAIL : {
            return {
                ...state,
                documentDetail : action.payload
            }
        }

        case CREATE_DOCUMENTS : {
            return {
                ...state,
                allDocuments : action.payload
            }
        }

        case EDIT_DOCUMENTS : {
            return {
                ...state,
                allDocuments : action.payload
            }
        }

        case CLEAR_DOCUMENT_DETAIL : {
            return {
                ...state,
                documentDetail : []
            }
        }

        default : {
            return {
                ...state,
                documents : action.payload
            }
        }
    }
}
  
