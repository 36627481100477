import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {getExternalGamesAction, ExternalGameByIdAction, searchExternalGamesAction, EditGameAction} from '../../redux/action/gameAction'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faSearch, faPlusCircle, faTrash, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col,InputGroup, Row, Nav, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Field, Form } from 'formik';
import '../css/games.css'
import Pagination from '@mui/material/Pagination'
import ExternalFilter from "../modals/Filters/ExternalFilter";
import ExternalGameDetailModal from "../modals/GamesModels/ExternalGameDetailModal";
import axios from "../../utlis/interceptor";
import Loader from "react-js-loader";
import { Select } from "@mui/material";
import Switch from "react-switch";
import { BASE_URL, EXTERNAL_GET_GAME, GET_GAME } from "../../resources/APIEndpoints";
import { CSVLink, CSVDownload } from "react-csv";
import { Breadcrumb } from "react-bootstrap";
import { dark } from "@mui/material/styles/createPalette";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));


function Games(props){
  const dispatch = useDispatch();

  const[showAllGames, setShowAllGames] = useState(true)
  const[showDraftGames, setShowDraftGame] = useState(false)
  const[addfilter, setAddfilter] = useState(false)
  const[gamedetail, setGamedetail] = useState(false)

  const [page, setPage] = useState(1)
  const [counts , setCounts] = useState()
  const [limit, setLimit] = useState(10)

  const [isDraft, setIsDraft] = useState(false)

  const onAddFilter = () => {
    setAddfilter(!addfilter)
  }

  const onToggleGameDetail = (gameId) => {
    let gameDetail = {
      gameSubmissionFilters : {
        id : [gameId],
        pagination : {

        }
      }
    }
    dispatch(ExternalGameByIdAction(gameDetail))
    setGamedetail(!gamedetail) 
  }

  const showAll = () => {
    setShowAllGames(true)
    setShowDraftGame(false)
  }

  const showDraft = () => {
    setShowAllGames(false)
    setShowDraftGame(true)
  }
  

  let games = useSelector((state)=>{ 
    return state.game.externalGames
  })

  let gamesDetail = useSelector((state)=>{ 
    return state.game.externalGameDetail
  })

  let isLoading = useSelector((state)=>{ 
    return state.game.isLoading
  })

  useEffect(()=>{
    getAllGames();
    paginationCount();
  }, [page])


  const getAllGames = () => {
      let gameData = {
        gameSubmissionFilters : {
          isGameScreenOrientationLandscape : Orientation,
          gameGenreMasterId : world,
          gamePlatforms : platform,
          gameSubmissionStatus : gameStatus,
          isDraft : [isDraft],
          gameSort : sorted,
          pagination : {
            offset : ((page-1)*limit),
            limit : limit
          }
        }
      }
      Object.keys(gameData.gameSubmissionFilters).forEach((key) => (gameData.gameSubmissionFilters[key].length === 0) && delete gameData.gameSubmissionFilters[key]) 
      dispatch(getExternalGamesAction(gameData))
  }

  const paginationCount = async() => {
    let paginateGames = {
      gameSubmissionFilters : {
        isDraft : [isDraft],
        pagination : {

        }
      }
    }
    
    let noOfGames = await axios.post(BASE_URL + EXTERNAL_GET_GAME, paginateGames)
    const pageNo = Math.ceil(noOfGames.data.gameData.length/limit)
    setCounts(pageNo)
  }

  const searchGame = (e) => {
    if(e.target.value == ""){
      let gameData = {
        gameSubmissionFilters : {
          isGameScreenOrientationLandscape : Orientation,
          gameGenreMasterId : world,
          gamePlatforms : platform,
          gameSubmissionStatus : gameStatus,
          isDraft : [isDraft],
          gameSort : sorted,
          pagination : {
            offset : ((page-1)*limit),
            limit : limit
          }
        }
      }
      Object.keys(gameData.gameSubmissionFilters).forEach((key) => (gameData.gameSubmissionFilters[key].length === 0) && delete gameData.gameSubmissionFilters[key]) 
      dispatch(getExternalGamesAction(gameData))
    }else{
      let searchGamesData = {
        gameName : e.target.value
      }
       dispatch(searchExternalGamesAction(searchGamesData))
    }
  }

  const [sorted, setSorted] = useState({createdAt : "ASC"})

  const onSortChange = (e) => {
      let data ={}
      if(e.target.value === "Name_ASC"){
          setSorted({gameName : "ASC"})
          data = {
            isDraft : [isDraft],
            gameSort : {gameName : "ASC"},
            pagination : {
              offset : ((page-1)*limit),
              limit : limit
            }
          } 
      }else if(e.target.value === "Name_DESC"){
        setSorted({gameName : "DESC"})
        data = {
          isDraft : [isDraft],
          gameSort : { gameName : "DESC" },
          pagination : {
            offset : ((page-1)*limit),
            limit : limit
          }
        }
      }else if(e.target.value === "Date_ASC"){
        setSorted({createdAt :"DESC"})
        data = {
          isDraft : [isDraft],
          gameSort : {createdAt :"DESC"},
          pagination : {
            offset : ((page-1)*limit),
            limit : limit
          }
        }
      }else if(e.target.value === "Date_DESC"){
        setSorted({createdAt :"ASC"})
        data = {
          isDraft : [isDraft],
          gameSort : { createdAt :"ASC" },
          pagination : {
            offset : ((page-1)*limit),
            limit : limit
          }
        }
      }

      let getSortedGames = {
        gameSubmissionFilters  : data
      }
      dispatch(getExternalGamesAction(getSortedGames));
  }

  const [Orientation, setOrientation] = useState([])
  const [world, setWorld] = useState([])
  const [gameStatus, setGameStatus] = useState([])
  const [platform, setPlatform] = useState([])

  const onFilter = (Orientation, world, gameStatus, platform) => {

    setPlatform(platform)
    setGameStatus(gameStatus)
    setWorld(world)
    setOrientation(Orientation)

      let paginateFilter = {
        gameSubmissionFilters : {
          isDraft : [isDraft],
          isGameScreenOrientationLandscape : Orientation,
          gameGenreMasterId : world,
          gamePlatforms : platform,
          gameSubmissionStatus : gameStatus,
          pagination : {
            offset : 0,
            limit : 10
          }
        }
    }
    
    Object.keys(paginateFilter.gameSubmissionFilters).forEach((key) => (paginateFilter.gameSubmissionFilters[key].length === 0) && delete paginateFilter.gameSubmissionFilters[key]) 
    dispatch(getExternalGamesAction(paginateFilter))

    delete paginateFilter.gameSubmissionFilters.pagination.offset
    delete paginateFilter.gameSubmissionFilters.pagination.limit   

    axios.post(BASE_URL + EXTERNAL_GET_GAME, paginateFilter)
    .then(res=>{
      const pageNo = Math.ceil(res.data.gameData.length/limit)
      setCounts(pageNo)
    })

  }

  return (
    <>
    <ToastContainer />
    <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
      <Row>
        <Col lg={12}>
          <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
            <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
            <Breadcrumb.Item href='#games'>Games</Breadcrumb.Item>
            <Breadcrumb.Item className="text-dark" active>External Game</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
    </div>

      <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
          <div className="me-lg-3">

          </div>
        </Col>
      </div>
      <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded game-page">
          
          <div className="row mb-4 px-4 align-items-center">
              <div className="col col-lg-6 col-md-12 text-left">
                  <p className="page-heading">External Games</p>
                  {/* <div className="Bg-Color" style={{width: '30px', height: '2px'}}></div> */}
              </div>
              <div className="col col-lg-6 col-md-12 text-right">
                <span className='filter' style={{cursor: "pointer"}} onClick={onAddFilter}><i className="fas fa-filter mx-2"></i><span>Filter</span></span>
              </div>
          </div>

          <div className="row d-flex px-4 mt-2">
            {/* <div className="col col-md-4"></div> */}
            <div className="col col-md-4">
                <InputGroup>
                    <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <input className="form-control" type="text" placeholder="Search Games" onChange={(e)=>{searchGame(e)}}/>
                </InputGroup>
            </div>
            <div className="col col-md-4">
                {/* <label>Sort By</label> */}
                <select className="form-control" onChange={(e)=>{onSortChange(e)}}>
                    <option>Sort By</option>
                    <option value="Name_ASC">Name : (A-Z)</option>
                    <option value="Name_DESC">Name : (Z-A)</option>
                    <option value="Date_ASC">Created at (latest)</option>
                    <option value="Date_DESC">Created at (oldest)</option>
                </select>
            </div>
          </div>

        <div className="p-4">   
          <Row className="table-responsive">
              <Col lg={12} md={12} sm={12}>
                  <div>
                      <table className="table table-striped text-center table-custom align-middle mb-5">
                    <thead className="table-head">
                      <tr>
                        <th scope="col" className="head-border-left">Game Name</th>
                        <th scope="col">Created At</th>
                        <th scope="col">Game Platform</th>
                        <th scope="col">Game Genre</th>
                        <th scope="col">Game Status</th>
                        <th scope="col" className="head-border-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody>  
                        {games && games.map((e,i)=>{
                            return(
                              <tr className="table-body">
                                <td><b>{e.gameName}</b></td>
                                <td>{e.createdAt.slice(0,10).split("-").reverse().join("-")}</td>
                                <td>{e.gameSubmissionPlatforms.length !=0 ? e.gameSubmissionPlatforms && e.gameSubmissionPlatforms.map((p,i)=>{return(<span>{(i ? ', ' : '') + p.gamePlatformMaster.platformName}</span>)}) : "-"}</td>
                                <td>{e.gameGenreMaster.genreName}</td>
                                <td><span className={e.gameSubmissionStatus == null ? "-" : e.gameSubmissionStatus == 'approved' ? 'text-success' :  e.gameSubmissionStatus == 'rejected' ? 'text-danger' :  e.gameSubmissionStatus == 'in review' ? 'text-info' : ""}><b>{e.gameSubmissionStatus}</b></span></td>
                                <td>
                                  <Dropdown className="ms-3">
                                    <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <span className="icon icon-sm icon-dark">
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                      </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-right actions">
                                      <Dropdown.Item className="ms-0" as={Link} to={{pathname: Routes.ViewExternalGame.path, id: e.id}}>
                                        <FontAwesomeIcon icon={faEye} className="me-2 icon-color"/> View Details
                                      </Dropdown.Item>
                                      {/* <Dropdown.Item className="ms-0" as={Link} to={{pathname: Routes.EditGame.path, id:e.id}}>
                                        <FontAwesomeIcon icon={faEdit} className="me-2 icon-color"/> Edit
                                      </Dropdown.Item> */}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            )
                        })}
                      </tbody>
                    </table>
                </div>

                <div className="d-flex justify-content-left m-4">
                  <Row>
                    <Col>
                        <Pagination className="paginate"
                        count={counts} 
                        defaultPage={page} 
                        color="info" 
                        variant="outlined" shape="rounded"
                        showFirstButton={false} 
                        showLastButton={false} 
                        onChange={(event, value)=> setPage(value)}
                        />
                    </Col>
                  </Row>
                </div>

              </Col>
          </Row>
        </div>
        <ExternalFilter show={addfilter} onHide={onAddFilter} filterGames={onFilter}/>
        <ExternalGameDetailModal show={gamedetail} onHide={onToggleGameDetail} />
    </div>
    </>
  )
};

export default Games; 


