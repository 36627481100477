import React, {useEffect, useState} from 'react'
import {Row, Col, Button, Pagination, Toast} from '@themesberg/react-bootstrap'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import { Checkbox,TextField } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import XLSX from 'xlsx'
import MaterialTable from "material-table";
import  TablePagination  from './components/TablePagination';
import { useDispatch , useSelector} from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Leaderboards } from '../constants/LabelConstants';
import { getAllLeaderboardAction, createPDLeaderboardAction } from '../redux/action/leaderboardAction';
import { useHistory } from 'react-router-dom';
import { Breadcrumb } from "react-bootstrap";

export default function PointDistribution() {
    const history = useHistory();
    const icon = <CheckBoxOutlineBlankIcon fontSize = "small" />
    const checkedIcon = <CheckBoxIcon fontSize = "small" />

    const Countrydata = [
        {CountryName: 'India', id: 1}
    ]

    const dispatch = useDispatch();
    useEffect(() => {
        const data = {
            "getLiderboardFilters" : {
              
            }
        }
        function getallGames(){
            dispatch(getAllLeaderboardAction(data));
        }
        getallGames();
    }, [])

    let leaderboard = useSelector((state)=>{ 
        return state.leaderboard.leaderboards
    })

    const[country, setCountry] = useState(Countrydata)
    const[countrynew, setCountryNew] = useState([])
    const[countryError, setCountryError] = useState(false)
    const[rank, setRank] = useState("")

    const [leaderboardId, setLeaderboardId] = useState('')
    const onLeaderboard = (e) => {
        setLeaderboardId(e.target.value)
        setLeaderboardIdError(false)
    }

    const [winners, setWinners] = useState("")
    const onNoWinner = (e) =>{
        setWinners(parseInt(e.target.value))
        setWinnersError(false)
    }

    const [minPlayerPrize, setMinPlayerPrize] = useState("")
    const onMinPrize = (e) => {
        setMinPlayerPrize(parseInt(e.target.value))
        setMinPlayerPrizeError(false)
    }

    const onchangeRank = (e) => {
        setRank(e.target.value)
    }
    const[schedule, setSchedule] = useState("")
    const selectSchedule = (e) => {
        setSchedule(parseInt(e.target.value))
        setScheduleError(false)
    }
    const[team, setTeam] = useState("")
    const [teamError, setTeamError] = useState(false)
    const selectTeam = (e) => {
        setTeam(e.target.value)
        setTeamError(false)
    }
    const[startDate, setStartDate] = useState("")
    const onStartDate = (e) => {
        setStartDate(e.target.value)
        setStartDateError(false)
    }
    const[startTime, setStartTime] = useState("")
    const onStartTime = (e) => {
        setStartTime(e.target.value)
        setStartTimeError(false)
    }
    const[timeRecure, setTimeRecure] = useState("")
    const onTimeRecurring = (e) => {
        setTimeRecure(parseInt(e.target.value))
        setTimeRecureError(false)
    }
    
    const [endDate, setEndDate] = useState("")
    const [endDateError, setEndDateError] = useState(false)
    const onEndDate = (e) =>{
        setEndDate(e.target.value)
        setEndDateError(false)
    }


    const[endTime, setEndTime] = useState("")
    const [endTimeError, setEndTimeError] = useState(false)
    const onEndTime = (e) => {
        setEndTime(e.target.value)
        setEndTimeError(false)
    }
    const[distributionDate, setDistribution] = useState("")
    const onDistributionDate = (e) => {
        setDistribution(e.target.value)
    }
    const[minutes, setMinutes] = useState("")
    const[Hours, setHours] = useState("")

    const onHours = (e) => {
        setHours(e.target.value)
        setHoursError(false)
    }

    const onMinute = (e) => {
        if(e.target.value<=60){
            setMinutes(e.target.value)
            setMinutesError(false)
        }else{
            alert("Enter valid input")
        }
    }


    const[colTitle, setColTitle] = useState()
    const[data, setData] = useState([])
    const convertToJson = (headers, data) =>{
        const rows = []
        data.forEach(row=>{
            let rowData = {}
            row.forEach((ele, index)=>{
                rowData[headers[index]] = ele;
            })
            rows.push(rowData)
        })
        return rows
    }
    const [PrizeError, setPrizeError] = useState(false)
    const onCSV = (e) => {
        var file = e.target.files[0];
        const reader = new FileReader()
        reader.onload=(event)=>{
            const bstr = event.target.result
            const workBook = XLSX.read(bstr, {type:"binary"})

            const worksheetName = workBook.SheetNames[0]
            const workSheet =workBook.Sheets[worksheetName]

            const fileData = XLSX.utils.sheet_to_json(workSheet, {header:1})

            const headers =fileData[0]
            fileData.splice(0,1)

            setData(convertToJson(headers, fileData))

            const heads = headers.map(head=>({title:head, field: head}))
            setColTitle(heads)
        }
        reader.readAsBinaryString(file) 
        setPrizeError(false); 
    }


    

    const [currentPage, setCurrentPage] = useState(1);
    const [entryPerPage, setEntryPerPage] = useState(10)


    const indexOfLastEntry = currentPage * entryPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entryPerPage;
    const currentEntry = data.slice(indexOfFirstEntry, indexOfLastEntry)
 
    const Paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const changePageEntry = (e) => {
        const chanegedPage = parseInt(e.target.value)
        setEntryPerPage(chanegedPage)
    }

    const save = () => {
        const Seconds = (Hours * 60 * 60) + (minutes * 60)
        const PDleaderboard = {
            prizeDistribution : data,
            leaderboardId : leaderboardId,
            numberOfWinners : winners,
            minimumPlayers : minPlayerPrize,
            startDate : startDate.concat(" ",startTime),
            numberOfTimeRecurring : timeRecure,
            prizeDistributionOffset : Seconds.toString(),
            leaderboardIntervalId : schedule
        }

        if(PDleaderboard.prizeDistribution === "" || PDleaderboard.leaderboardId === "" || PDleaderboard.leaderboardId === null || PDleaderboard.numberOfWinners === null || PDleaderboard.minimumPlayers === null || PDleaderboard.startDate === "" || PDleaderboard.numberOfTimeRecurring === "" || PDleaderboard.prizeDistributionOffset === "" || PDleaderboard.leaderboardIntervalId === ""){
            validation();
        }else{
            dispatch(createPDLeaderboardAction(PDleaderboard))

            function redirectToGamePage () {
                history.push('/main-leaderboard')
            }
            setTimeout(() => {
                redirectToGamePage();
            }, 1300);
        }
    }
    const [leaderboardIDError, setLeaderboardIdError] = useState(false)
    const [winnersError, setWinnersError] = useState(false)
    const [minPlayerPrizeError, setMinPlayerPrizeError] = useState(false)
    const [startDateError, setStartDateError] = useState(false)
    const [startTimeError, setStartTimeError] = useState(false)
    const [timeRecureError, setTimeRecureError] = useState(false)
    const [HoursError, setHoursError] = useState(false)
    const [minutesError, setMinutesError] = useState(false)
    const [scheduleError, setScheduleError] = useState(false)

    const validation = () => {
        if(leaderboardId === "" || leaderboardId === null){
            setLeaderboardIdError(true)
        }
        if(winners === "" || winners === null){
            setWinnersError(true)
        }
        if(minPlayerPrize === "" || minPlayerPrize === null){
            setMinPlayerPrizeError(true)
        }
        if(startDate === "" || startDate === null){
            setStartDateError(true)
        }
        if(startTime === "" || startTime === null){
            setStartTimeError(true)
        }
        if(timeRecure === "" || timeRecure === null){
            setTimeRecureError(true)
        }
        if(Hours === "" || Hours === null){
            setHoursError(true)
        }
        if(minutes === "" || minutes === null){
            setMinutesError(true)
        }
        if(schedule === "" || schedule === null){
            setScheduleError(true)
        }

        if(data === "" || data.length === 0){
            setPrizeError(true)
        }

        if(countrynew === "" || countrynew.length === 0){
            setCountryError(true)
        }

        if(team === "" || team === null){
            setTeamError(true)
        }

        if(endDate === "" || endDate === null){
            setEndDateError(true)
        }

        if(endTime === "" || endTime === null){
            setEndTimeError(true)
        }

    }

    const cancel = () => {
        history.push('/main-leaderboard')
    }

    return (
        <>
        <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
                    
                </Col>
            </div>
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5">
                <div className="container">
                    <Row>
                        <Col lg={12} md={12} >
                            <h5>{Leaderboards.Prize_Distribution}</h5>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col lg={6} md={12} sm={12}>
                            <div className="form-group">
                                <label className="my-2">{Leaderboards.Select_Leaderboard}</label>
                                <select className="form-control" onChange={(e)=>{onLeaderboard(e)}}>
                                    <option>Select Leaderboard</option>
                                    {/* <option value="PlayStarz_Leaderboard">PlayStarz Leaderboard</option>
                                    <option value="GameWorld_Leaderboard">Game World Leaderboard</option> */}
                                    {leaderboard && leaderboard.map((e,index)=>{
                                        return(
                                            <option key={index} value={e.id}>{e.leaderboardName}</option>
                                        )
                                    })}
                                </select>
                                {leaderboardIDError ?<span style={{color : "red"}}>*Leaderboard is Required</span> : ""}
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                            <div className="form-group">
                                <label className="my-2">{Leaderboards.No_Of_Winners}</label>
                                <input type="number" min="1" className="form-control" onChange={(e)=>{onNoWinner(e)}}/>
                                {winnersError ?<span style={{color : "red"}}>*No of Winners are Required</span> : ""}
                            </div>
                        </Col>
                    </Row>

                    <Row className="my-3">
                        <Col md={6}>
                            <label>{Leaderboards.Prize_Distribution}</label>
                            <input type="file" accept=".csv, .xlsx" className="form-control" onChange={(e)=>{onCSV(e)}}/>
                            {PrizeError === true ? <span style={{color: "red"}}>*PD is Required</span> : ""}
                        </Col>
                    </Row>
                    {data && data !="" && data[0].startRank ? 
                        <Row>
                            <Col md={12}>
                                {/* <MaterialTable title="Prize Distribution" data={data} columns={colTitle} /> */}
                                <table className="table table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Rank</th>
                                        <th scope="col">Prize</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentEntry && currentEntry.map((e)=>{
                                        return(
                                            <tr>
                                                <td>{e.startRank} {e.endRank ? "-" : ""} {e.endRank}</td>
                                                <td>{e.amount}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                </table>
                            </Col>
                        </Row>
                    : ""}
                    {data && data != "" && data[0].startRank && data[0].amount ?
                        <Row>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <Col>
                                        <label style={{paddingRight: "10px"}}>Rows Per Page</label>
                                        <select onChange={(e)=>{changePageEntry(e)}}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                        </select>
                                    </Col>
                                </div>
                                <div>
                                    <Col>
                                        <TablePagination entryPerPage={entryPerPage} totalEntry={data.length} Paginate={Paginate}/>
                                    </Col>
                                </div>
                            </div>
                        </Row>
                    : data && data != ""  ? <div><p className="text-danger font-weight-bold">Please upload valid file.</p></div> : ""}

                    <Row className="my-3">
                        <Col lg={6} md={12} sm={12}>
                            <div className="form-group">
                                <label className="my-2">{Leaderboards.Min_Players_PD}</label>
                                <input type="number" min="1" className="form-control" onChange={(e)=>{onMinPrize(e)}}/>
                                {minPlayerPrizeError ?<span style={{color : "red"}}>*Min Players is Required</span> : ""}
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                            <div className="form-group">
                                <label className="my-2">{Leaderboards.Choose_Schedule}</label>
                                <select className="form-control" onChange={(e)=>{selectSchedule(e)}}>
                                    <option>Choose Schedule</option>
                                    <option value="1">Daily</option>
                                    <option value="2">Weekly</option>
                                    <option value="3">Monthly</option>
                                </select>
                            </div>
                            {scheduleError ?<span style={{color : "red"}}>*Schedule is Required</span> : ""}
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <label for="IncludeCountry"  className="my-2">Country</label>
                                <Autocomplete multiple disableCloseOnSelect  
                                options={country} getOptionLabel={(option)=> option.CountryName}
                                onChange={(e,newvalue)=>{setCountryNew(newvalue); setCountryError(false)}}
                                renderOption={(option, {selected})=>(
                                    <React.Fragment>
                                        <Checkbox icon={icon}
                                        checkedIcon={checkedIcon} style={{marginLeft: "10px"}}
                                        checked={selected}  allowSelectAll={true}/>
                                        {option.CountryName} 
                                    </React.Fragment>  
                                )}
                                renderInput={(params)=>(<TextField {...params} variant="outlined" />)}/>
                                 {countryError ? <div><span style={{color : "red"}}>Country is Required</span></div> : ""}
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label className="my-1">{Leaderboards.Team}</label>
                                    <select className="form-control" onChange={(e)=>{selectTeam(e)}}>
                                        <option>Choose Team</option>
                                        <option value="Daily">Team_01</option>
                                        <option value="Weekly">Team_02</option>
                                        <option value="Monthly">Team_03</option>
                                    </select>
                                    {teamError ? <div><span style={{color : "red"}}>Team is Required</span></div> : ""}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <div className="form-group">
                                <label className="my-2">{Leaderboards.Start_Date}</label>
                                <input type="date" min={new Date().toISOString().split('T')[0]} className="form-control" onChange={(e)=>{onStartDate(e)}}/>
                            </div>
                            {startDateError ?<span style={{color : "red"}}>*Start Date is Required</span> : ""}
                        </Col>
                        <Col lg={6}>
                            <div className="form-group">
                                <label className="my-2">{Leaderboards.Start_Time}</label>
                                <input type="time" className="form-control" onChange={(e)=>{onStartTime(e)}}/>
                                {startTimeError ?<span style={{color : "red"}}>*Start Time is Required</span> : ""}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <div className="form-group">
                                <label className="my-2">{Leaderboards.End_Date}</label>
                                <input type="date" className="form-control" min={new Date().toISOString().split('T')[0]} onChange={(e)=>{onEndDate(e)}}/>
                                {endDateError === true ? <span style={{color: "red"}}>*Date is Required</span> : ""}
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="form-group">
                                <label className="my-2">{Leaderboards.End_Time}</label>
                                <input type="time" className="form-control" onChange={(e)=>{onEndTime(e)}}/>
                                {endTimeError === true ? <span style={{color: "red"}}>*Time is Required</span> : ""}
                            </div>
                        </Col>
                    </Row>
                    <Row className="my-2">
                        <Col lg={6} className="my-4">
                            <div className="form-group my-2">
                                <label className="my-2">{Leaderboards.Times_Recurring}</label>
                                <input type="number" className="form-control" onChange={(e)=>{onTimeRecurring(e)}}/>
                                {timeRecureError ?<span style={{color : "red"}}>*No of Time Recurring is Required</span> : ""}
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="form-group my-2">
                                <label className="pr-3">{Leaderboards.Set_PD_Date}</label>
                                <Row>
                                    <Col>
                                        <label className="my-2">{Leaderboards.Hours}</label>
                                        <input type="number" min="0" className="form-control" onChange={(e)=>{onHours(e)}}/>
                                        {HoursError ?<span style={{color : "red"}}>*Hours are Required</span> : ""}
                                    </Col>
                                    
                                    <Col>
                                        <label className="my-2">{Leaderboards.Minutes}</label>
                                        <input type="number" min="0" max="60" className="form-control" onChange={(e)=>{onMinute(e)}} />
                                        {minutesError ?<span style={{color : "red"}}>*Hours are Required</span> : ""}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <button type="button" className="btn btn-success" onClick={save}>Save</button>
                            <button type="button" className="btn btn-danger mx-2" onClick={cancel}>Cancel</button>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
