import React, {useState, useEffect} from 'react'
import {Row, Col, ButtonGroup, Button, InputGroup, Form, Nav} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch,faCaretLeft, faCaretRight} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { getCompetitionDetailAction } from '../../redux/action/competitionAction';
import Pagination from '@mui/material/Pagination'
import axios from '../../utlis/interceptor'
import { BASE_URL } from '../../resources/APIEndpoints';
import { Breadcrumb } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CompetitionResult(props) {
    
    const [page, setPage] = useState(1)
    const [counts , setCounts] = useState()
    const [posts, setPosts] = useState([])
    const [limit, setLimit] = useState(10)
    const dispatch = useDispatch()
    
    const [resultsCompetition, setCompetitionResult] = useState()
    const [amountDistributed, setAmountDistributed] = useState()
    const [hostingFee, setHostingFee] = useState()

    const [initialSlot, setInitialSlot] = useState(0)

    useEffect(() => {
        setCompetitionResult(props.location.result)
        setAmountDistributed(props.location.amountDistributed)
        setHostingFee(props.location.hostingFee)
    }, [page])

    let competition = useSelector((state)=>{ 
        return state.competition.competitionDetail
    })

    const previosResult = () => {
        setInitialSlot(initialSlot-1)
    }

    const nextResult = () => {
        setInitialSlot(initialSlot+1)
    }

    const onPage = (e) => {
        if(e.target.value > (resultsCompetition.length-1)){
            toast.warning(`There are only ${resultsCompetition.length} slots`)
        }else if(e.target.value < 0){
            toast.warning(`Slots must be positive`)
        }else if(e.target.value == null || e.target.value == undefined || e.target.value == ''){
            setInitialSlot(0)
        }
        else if((e.target.value <= (resultsCompetition.length-1)) && (e.target.value >= 0)){
            setInitialSlot(parseInt(e.target.value))
        }
    }

    if(resultsCompetition && resultsCompetition.length !=0){
        return (
            <>
            <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                    <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                        <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                        <Breadcrumb.Item href="#competition">Competition</Breadcrumb.Item>
                        <Breadcrumb.Item className='text-dark' active>{props.location.competitionName}</Breadcrumb.Item>
                    </Breadcrumb>
                    </Col>
                </Row>
            </div>
            
            <div className="d-lg-flex justify-content-around align-items-center flex-wrap flex-md-nowrap my-3">
                <div className="col col-1"></div>
                <div className="col col-md-4 col-sm-12">
                    <Row className="bg-white px-4 py-4 border rounded">
                        <Col>
                            <Row>
                                <Col lg={3} md={3} sm={4} className="d-flex align-items-center">
                                    <i style={{color: "#fff", backgroundColor: "#FFAB00", padding:"15px"}} class="fas fa-hand-holding-usd fa-3x rounded"></i>
                                </Col>
                                <Col lg={9} md={9} sm={8}>
                                    <Row>
                                        <Col>
                                            <h5>Total Amount Distributed</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h4>&#8377; {parseFloat(resultsCompetition[initialSlot].totalAmountDistributed).toFixed(2)}</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span>Prize Distribution Date: {props.location.date.slice(0,10)}</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="col col-md-4 col-sm-12">
                    <Row className="border rounded bg-white px-4 py-4">
                        <Col style={{padding: "8px 0px 8px 10px"}}>
                            <Row className="d-flex align-items-center">
                                <Col lg={3} md={3} sm={4}>
                                    <i style={{color: "#fff", backgroundColor: "#FFAB00", padding:"15px"}} class="fas fa-cash-register fa-3x rounded"></i>
                                </Col>
                                <Col lg={9} md={9} sm={8} >
                                    <Row>
                                        <Col>
                                            <h5>Total Hosting Fee Collected</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h4>&#8377; {parseFloat(resultsCompetition[initialSlot].totalHostingFeeCollected).toFixed(2)}</h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="col col-1"></div>
            </div>

            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 competition-main">
                <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0"></Col>
                <Col xs={12} lg={6} className="px-0 mb-0 text-right">
                    <ButtonGroup>
                        <Button className='export' size="sm">Export</Button>
                    </ButtonGroup>
                </Col>
            </div>
    
    
            <div className="task-wrapper border bg-white border-light shadow-sm pt-3 rounded pb-5 competition-main">
                <Row className="mt-3 mb-5">
                    <Col>
                        <div className="row d-flex px-5 align-items-center">
                            <div className="col col-md-4">
                            </div>
                            <div className="col col-md-4"></div>
                            <div className="col col-md-4 text-right">
                                <span className='mx-2'>
                                    <button className={initialSlot == 0 ? 'disabled-refresh' : 'refresh'} disabled={initialSlot == 0 ? true : false} onClick={previosResult}>
                                        <span><FontAwesomeIcon icon={faCaretLeft}></FontAwesomeIcon></span>
                                    </button>
                                </span>
                                <span>
                                    <input style={{width : '45px'}} min={0} max={resultsCompetition.length} type='number' onChange={(e)=>{onPage(e)}}/>
                                </span>
                                <span className='mx-2'>
                                    <button className={initialSlot == (resultsCompetition.length-1) ? 'disabled-refresh' : 'refresh'}  disabled={initialSlot == (resultsCompetition.length-1) ? true : false} onClick={nextResult}>
                                        <span><FontAwesomeIcon icon={faCaretRight}></FontAwesomeIcon></span>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className='px-5'>
                    <Col lg={8} md={8} sm={12}>
                        <h4 className='page-heading'>{props.location.competitionName}</h4>
                    </Col>
                    <Col></Col>
                    <Col lg={3} md={3} sm={12} className='text-right'>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                            <Form.Control type="text" placeholder="Search PlayerId and Player Rank" />
                        </InputGroup>
                    </Col>
                </Row>

                <Row className='px-5'>
                    <Col lg={12} md={12} sm={12}>
                        <h6>Slot Id : {resultsCompetition[initialSlot].id}</h6>
                    </Col>
                </Row>
                
                <Row className="container-fluid mt-3">
                    <Col lg={12} md={12} sm={12}>
                        <table  className="table table-striped text-center table-custom">
                             <thead className='table-head'>
                                <tr>
                                    <th className='head-border-left' scope="col">Player ID</th>
                                    <th scope="col">Player Rank</th>
                                    <th scope='col'>Username</th>
                                    <th scope="col">Points</th>
                                    <th className='head-border-right' scope="col">Winning Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {resultsCompetition[initialSlot].competitionsResult && resultsCompetition[initialSlot].competitionsResult.map((e)=>{
                                    return(
                                        <tr className='table-body'>
                                            <td>{e.userId}</td>
                                            <td>{e.ranks}</td>
                                            <td>{e["users.username"]}</td>
                                            <td>{e.score}</td>
                                            <td>{e.amount.toFixed(2)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            
                <div className="d-flex justify-content-center my-4">
                    {/* <Row>
                        <Col lg={12} md={12} sm={12}>
                            <Pagination 
                            count={counts} 
                            defaultPage={page} 
                            color="primary" 
                            showFirstButton={true} 
                            showLastButton={true} 
                            onChange={(event, value)=> setPage(value)}
                            />
                        </Col>
                    </Row> */}
                </div>
            </div>
        </>
        )
    }else{
        return(
            <p>No Data Found</p>
        )
    }
}

export default CompetitionResult