import teamServices from '../../services/teamServices'
import { GET_TEAM, GET_TEAM_BY_ID, CREATE_TEAM, EDIT_TEAM } from '../actionType/actionTypes'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getTeamsAction = (body) => {
    return async (dispatch) => {
        await teamServices.getAllTeams(body)
        .then(res=>{
            dispatch(getAllTeams(res.data.teams))
        })
    }
}

export const getTeamByIdAction = (body) => {
    return async (dispatch) => {
        await teamServices.getTeamsById(body)
        .then(res=>{
            dispatch(getTeamById(res.data.teams))
        })
    }
}

export const createTeamAction = (body, history) => {
    return async (dispatch) => {
        await teamServices.createTeam(body)
        .then(res=>{
            toast.success(res.data.message)
            setTimeout(()=>{
                history.push('/teams')
            }, 1500)
        })
    }
}

export const editTeamAction = (body, history) => {
    return async (dispatch) => {
        await teamServices.editTeam(body)
        .then(res=>{
            toast.success(res.data.message)
            setTimeout(()=>{
                history.push('/teams')
            }, 1500)
        })
    }
}

export const getAllTeams  = (data) => {
    return {
        type : GET_TEAM,
        payload : data
    }
}

export const createTeam  = (data) => {
    return {
        type : CREATE_TEAM,
        payload : data
    }
}

export const editTeam  = (data) => {
    return {
        type : EDIT_TEAM,
        payload : data
    }
}

export const getTeamById  = (data) => {
    return {
        type : GET_TEAM_BY_ID,
        payload : data
    }
}