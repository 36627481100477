import { GET_REFERRAL, ADD_REFERRAL, GET_REFERRAL_BY_ID, EDIT_REFERRAL } from "../actionType/actionTypes";

const initialState = {
    referrals : [],
    referralDetail : []
}

export const referralReducer = (state = initialState, action) => {

    switch(action.type){
        case GET_REFERRAL : {
            return{
                ...state,
                referrals : action.payload
            }
        }
        case ADD_REFERRAL : {
            return{
                ...state,
                referrals : action.payload
            }
        }
        case GET_REFERRAL_BY_ID : {
            return{
                ...state,
                referralDetail : action.payload
            }
        }
        case EDIT_REFERRAL : {
            return{
                ...state,
                referrals : action.payload
            }
        }
        default : {
            return {
                ...state
            }
        }
    }

}
