import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import {Game_Details, Game_Id,Game_Name, Game_Description, Game_How_to, Game_Platform, Asset_Bundle_Version, Minimum_App_Version, Game_Genre, Screen_Orientation, Game_World, Match_Name, Match_Format, Match_How_To, Match_Outcome_Type, Leaderboard_Outcome_Type, Included_Countries, Phase, States, Match_Details, Game_Screenshots} from './../../constants/LabelConstants'
import '../css/games.css'
import { Link} from "react-router-dom";
import { Routes } from "../../routes";
import { Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faPen} from '@fortawesome/free-solid-svg-icons';
import { cleargamedetailsAction } from '../../redux/action/gameAction';
import axios from 'axios';

export default function GamedetailModal({show, onHide, filter, id}) {

    const dispatch = useDispatch();
    const [states, setStates] = useState()
    useEffect(()=>{
        axios.get('https://dev.gamestarz.xyz/v1/static-values')
        .then(res=>{
            setStates(res.data.states)
        })
    }, [])

    let gamesDetail = useSelector((state)=>{ 
        return state.game.gameDetail
    })

    const close= () => {
        dispatch(cleargamedetailsAction())
        onHide();
    }


    if(gamesDetail && gamesDetail.length){
        return(
        <Modal className='gamedetail-model' scrollable={true} contentClassName="new_modal"  centered show={show} onHide={onHide}>
        <Modal.Header>
            <div className="modal_head">
                <Row className="d-flex align-items-center">
                    <Col lg={2}>
                        <img alt="Game_Logo" src={gamesDetail[0].gameLogo} width="300" className='img-responsive'></img>
                    </Col>
                    <Col lg={10}>
                        <span className="gamedetail-heading">{gamesDetail[0].gameName}</span>
                    </Col>
                </Row>
            </div>
        </Modal.Header>
        <Modal.Body className='gamedetail-body'>
            <Row>
                <Col lg={12} md={12} sm={12}>
                    <Row>
                        <Col lg={12}>
                            <h6 className='body-title'>{Game_Details}</h6>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col lg={6} md={12} sm={12}>
                            <label>{Game_Name}</label>
                            <input type='text' disabled className='form-control' defaultValue={gamesDetail[0].gameName != "" ? gamesDetail[0].gameName : "No info"} /> 
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                            <span>{Game_Id}</span> 
                            <input type='text' disabled className='form-control' defaultValue={gamesDetail[0].id != "" ? gamesDetail[0].id : "No info"}/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col lg={12} md={12} sm={12}>
                            <span>{Game_Description}</span>
                            <textarea rows={5} type='text' disabled className='form-control' defaultValue={gamesDetail[0].gameDescription !="" ? gamesDetail[0].gameDescription : "No info"}></textarea> 
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col lg={12} md={12} sm={12}>
                            <span>{Game_How_to} </span>
                            <textarea rows={5} type='text' disabled className='form-control' defaultValue={gamesDetail[0].gameHowTo !="" ? gamesDetail[0].gameHowTo :"No info"}></textarea> 
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col lg={6} md={12} sm={12}>
                            <span>{Game_Platform}</span>
                            <p className='input-bg'>{gamesDetail[0].gamePlatforms.length != 0 ? gamesDetail[0] && gamesDetail[0].gamePlatforms.map((e,i)=>{return(<span>{(i ? ', ' : '') + e.platformName}</span>)}) : "No info"}</p>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                            <span>{Asset_Bundle_Version}</span> 
                            <input type='text' disabled className='form-control' defaultValue={gamesDetail[0].gamePlatforms.length != 0  ? gamesDetail[0].gamePlatforms[0].gameAssetBundleVersion : "No info"}/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col lg={6} md={12} sm={12}>
                            <div className="mb-2">Android Asset Bundle</div> 
                            {gamesDetail[0].gamePlatforms.length != 0 &&  gamesDetail[0].gamePlatforms[0].platformName === 'android' ? <a className='download-btn' href={gamesDetail[0].gamePlatforms[0].gameAssetBundleUrl}>Download file</a> : gamesDetail[0].gamePlatforms.length > 1 &&  gamesDetail[0].gamePlatforms[1].platformName === 'android' ? <a className='download-btn' href={gamesDetail[0].gamePlatforms[1].gameAssetBundleUrl}>Download file</a>  : "No File"}
                            
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                            <div className="mb-2">iOS Asset Bundle</div> 
                            {gamesDetail[0].gamePlatforms.length != 0 &&  gamesDetail[0].gamePlatforms[0].platformName === 'ios' ? <a className='download-btn' href={gamesDetail[0].gamePlatforms[0].gameAssetBundleUrl}>Download file</a> : gamesDetail[0].gamePlatforms.length > 1 &&  gamesDetail[0].gamePlatforms[1].platformName === 'ios' ? <a className='download-btn' href={gamesDetail[0].gamePlatforms[1].gameAssetBundleUrl}>Download file</a>  :"No File"}
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col lg={6} md={12} sm={12}>
                            <span>{Minimum_App_Version}</span> 
                            <input type='text' disabled className='form-control' defaultValue={gamesDetail[0].gameMinimumAppVersion != null ? gamesDetail[0].gameMinimumAppVersion : "No info"}/>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                            <span>{Screen_Orientation}</span> 
                            <input type='text' disabled className='form-control' defaultValue= {gamesDetail[0].isGameScreenOrientationLandscape === true ? "Landscape" : gamesDetail[0].isGameScreenOrientationLandscape === false ? "Portrait" : "No info"}/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col lg={6} md={12} sm={12}>
                            <span>{Game_Genre}</span>
                            <input type='text' disabled className='form-control' defaultValue={gamesDetail[0].gameGenre != 0 ? gamesDetail[0].gameGenre : "No info"}/> 
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                            <span>{Game_World}</span> 
                            <input type='text' disabled className='form-control' defaultValue={gamesDetail[0].gamesWorld.length !=0 ? gamesDetail[0].gamesWorld[0].gameWorldName : "No info"}/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col lg={6} md={12} sm={12}>
                            <span>{Included_Countries}</span> 
                            <input type='text' disabled className='form-control' defaultValue={gamesDetail[0].gameGeo.length !=0 ? gamesDetail[0].gameGeo[0].countries.countryName : "No info"}/>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                            <span>Game Object Id</span> 
                            <input type='text' disabled className='form-control' defaultValue={gamesDetail[0].gameObjectId != null ? gamesDetail[0].gameObjectId  : 'null'}/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col lg={12} md={12} sm={12}>
                            <span>{States}</span>
                            <p className='input-bg'>{gamesDetail[0].gameGeo.length === 0 ? "No data available" : gamesDetail[0].gameGeo && gamesDetail[0].gameGeo[0].length !=0 && gamesDetail[0].gameGeo[0].states === null ? states && states.map((e, i)=>{return <span style={{fontSize : "12px"}}>{(i ? ', ' : '') + e.stateName}</span>}) : gamesDetail[0].gameGeo && gamesDetail[0].gameGeo[0].length !=0 && gamesDetail[0].gameGeo[0].states != null ? gamesDetail[0].gameGeo.map((e, i)=>{return <span style={{fontSize : "12px"}}>{i}&#93; &nbsp; {e.states.stateName} &nbsp;</span>}) : "No Info"}</p> 
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <span>{Game_Screenshots}</span>
                            <p className='input-bg'>
                                <Row>
                                    {gamesDetail[0].gameScreenShotUrl !="" && gamesDetail[0].gameScreenShotUrl && gamesDetail[0].gameScreenShotUrl.map((e,i)=>{
                                        return(
                                        <Col xl={2} lg={2} md={3} sm={4}>
                                                <img src={e} className='img-responsive my-2' alt='Screenshot'/>
                                        </Col>
                                        )
                                    })}
                                </Row>
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <span>Preview Video</span>
                            <p className='input-bg'>
                                <Row>
                                   <Col lg={4}>
                                        {gamesDetail[0].gamePreviewVideoUrl ? <video width="200" controls src={gamesDetail[0].gamePreviewVideoUrl !='' ? gamesDetail[0].gamePreviewVideoUrl  : ''} type="video/mp4"/> : '.'}
                                   </Col>
                                </Row>
                            </p>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col lg={12}>
                            <p className='body-title'>{Match_Details}</p>
                        </Col>
                    </Row>
                    <Row  className="mt-3 container game-page table-responsive">
                        {gamesDetail[0].matches.length != 0 ?
                        <table className='table table table-striped text-center table-custom'>
                            <thead className='table-head'>
                                <tr>
                                    <th scope="col" className='head-border-left'>Match Name</th>
                                    <th scope="col">Match Format</th>
                                    <th scope="col">Minimum Players</th>
                                    <th scope="col">Maximum Players</th>
                                    <th scope="col">MatchOutcome Type</th>
                                    <th scope="col" className='head-border-right'>LeaderboardOutcome Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {gamesDetail[0].matches.length != 0 ? 
                                    gamesDetail[0] && gamesDetail[0].matches.map((e, i)=>{
                                        if(e.archive === false){
                                        return(
                                            <tr key={e.id}>
                                                <td scope="col">{e.matchName}</td>
                                                <td scope="col">{e.matchFormatTypeMasterId === 1 ? "Single Player"  : e.matchFormatTypeMasterId === 2 ? "Multiplayer" : "-"}</td>
                                                <td scope="col">{e.matchMinPlayers}</td>
                                                <td scope="col">{e.matchMaxPlayers}</td>
                                                <td scope="col">{e.matchOutcomeTypeMasterId === 1 ? "High Score" : e.matchOutcomeTypeMasterId === 2 ? "Time Trail" : e.matchOutcomeTypeMasterId === 3 ? "Win/Loss/Draw" : e.matchOutcomeTypeMasterId ? "Podium Finish" : e.matchOutcomeTypeMasterId ? "Winning  Collected" : ""}</td>
                                                <td scope="col">{e.leaderboardOutcomeTypeMasterId === 1 ? "High Score" : e.leaderboardOutcomeTypeMasterId === 2 ? "Time Trail" : e.leaderboardOutcomeTypeMasterId === 3 ? "Win/Loss/Draw" : e.leaderboardOutcomeTypeMasterId === 4 ? "Podium Finish" : e.leaderboardOutcomeTypeMasterId === 5 ? "Winning  Collected" : ""}</td>
                                            </tr>
                                        )
                                        }else{

                                        }
                                    })
                                : ""}
                            </tbody>
                        </table> : "No Matches Available."}
                    </Row>
                </Col>
            </Row>
        </Modal.Body>
       <Modal.Footer className='gamedetail-footer'>
           <Row>
               <Col>
                    <Dropdown.Item  className="btn btn-md" style={{backgroundColor: "green", display: "inline", color: '#fff'}} as={Link} to={{pathname:Routes.EditGame.path, id:gamesDetail[0].id}}>
                        <FontAwesomeIcon icon={faPen} className="me-2"/> Edit
                    </Dropdown.Item>
                    <Button className="btn btn-danger btn-md mx-2" onClick={close}>Cancel</Button>
               </Col>
           </Row>
       </Modal.Footer>
   </Modal>
   )
}else{return(<p></p>)}

}
