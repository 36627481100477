import { GET_PARTNERS, GET_PARTNERS_BY_ID, VERIFY_PARTNER } from '../actionType/actionTypes'

const initialState = {
    partners : [],
    partnersDetail : []
}

export const organisationReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_PARTNERS : {
            return {
                ...state,
                partners : action.payload
            }
        }

        case GET_PARTNERS_BY_ID : {
            return {
                ...state,
                partnersDetail : action.payload
            }
        }

        case VERIFY_PARTNER : {
            return {
                ...state,
                partners : action.payload
            }
        }

        default : {
            return {
                ...state,
                partners : action.payload
            }
        }
    }
}
