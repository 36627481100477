import axios from '../utlis/interceptor'
import {BASE_URL, GET_ALL_CONDITIONTYPE, GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE, GET_RULE, CREATE_RULE, EDIT_RULE, CREATE_CONDITIONTYPE, CREATE_ATTRIBUTE, SEARCH_MODULE, SEARCH_ATTRIBUTE} from '../resources/APIEndpoints'

function BusinessLogicServices() {
    this.getAllConditionType = (body) => axios.post(BASE_URL + GET_ALL_CONDITIONTYPE, body)
    this.getConditionTypeDetail = (body) => axios.post(BASE_URL + GET_ALL_CONDITIONTYPE, body)
    this.getAllAttribute = (body) => axios.post(BASE_URL + GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE, body)
    this.getAllAtributeByCondtionType = (body) => axios.post(BASE_URL + GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE, body)

    this.getRule = (body) => axios.post(BASE_URL + GET_RULE, body)
    this.getRuleDetail = (body) => axios.post(BASE_URL + GET_RULE, body)
    this.creatRule = (body) => axios.post(BASE_URL + CREATE_RULE, body)
    this.editRule = (body) => axios.post(BASE_URL + EDIT_RULE, body)

    this.createCondition = (body) => axios.post(BASE_URL + CREATE_CONDITIONTYPE, body)
    this.createAttribute = (body) => axios.post(BASE_URL + CREATE_ATTRIBUTE, body)

    this.searchModule = (body) => axios.post(BASE_URL + SEARCH_MODULE, body)
    this.searchAttribute = (body) => axios.post(BASE_URL + SEARCH_ATTRIBUTE, body)
}

export default new BusinessLogicServices()