import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col, Dropdown} from '@themesberg/react-bootstrap'

function AllPayoutDetail({show, onHide}) {
    
    const close = () => {
        onHide();
    }

    return (
        <div>
            <Modal scrollable={true} size="lg" centered show={show} onHide={onHide}>
                <Modal.Header>
                    <div className="modal_head">
                        <h6>Payout Details</h6>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={6}>
                            <p>Transaction Source : Gamestarz Bank</p>
                        </Col>
                        <Col lg={6}>
                            <p>Transaction Contact : 214352</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <p>Transaction Recipient : Player Deposit Wallet</p>
                        </Col>
                        <Col lg={6}>
                            <p>Transaction Amount : 10000</p>	
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <p>Transaction Purpose Type : Bonus</p>
                        </Col>
                        <Col lg={6}>
                            <p>Transaction Purpose : Referral Bonus</p>	
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <p>Transaction Event : Deposit</p>
                        </Col>
                        <Col lg={6}>
                            <p>Transaction Date/Time : 23 Nov 2021</p>	
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <p>Transaction Status : Processing</p>
                        </Col>
                        <Col lg={6}>
                            <p>Transaction Mode For Debits : Automatic</p>	
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <p>Transaction Note : In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col>
                            <Button className="btn btn-danger btn -sm mx-2" onClick={close}>Cancel</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AllPayoutDetail