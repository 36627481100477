import axios from '../utlis/interceptor'
import { BASE_URL,CREATE_CUSTOM_LEADERBOARD, GET_LEADERBOARD, GET_PLAYER_DISTRIBUTION, SEARCH_LEADERBOARD, CREATE_PD_LEADERBOARD,CREATE_PLAYSTARZ_SCORING, GET_PLAYSTARZ_SCORING, EDIT_PLAYSTARZ_SCORING, GET_UNIVERSAL_LEADERBOARD, GET_LEADERBOARD_DETAIL, SEARCH_LEADERBOARD_DETAILS, SEARCH_UNIVERSAL_LEADERBOARD } from "../resources/APIEndpoints";

function LeaderboardServices() {
    this.getallLeaderboard = (body) => axios.post(BASE_URL + GET_LEADERBOARD, body)
    this.getLeaderboardDetails = (body) => axios.post(BASE_URL + GET_LEADERBOARD_DETAIL, body)
    this.createCustomLeaderboard = (body) => axios.post(BASE_URL + CREATE_CUSTOM_LEADERBOARD, body)
    this.getUniversalLeaderboard = (body) => axios.post(BASE_URL + GET_UNIVERSAL_LEADERBOARD, body)
    this.searchLeaderBoard = (body) => axios.post(BASE_URL + SEARCH_LEADERBOARD, body)
    this.createPDLeaderboard = (body) => axios.post(BASE_URL + CREATE_PD_LEADERBOARD, body)
    this.getallplayerdistribution = (body) => axios.post(BASE_URL + GET_PLAYER_DISTRIBUTION , body)
    this.createPlaystarz = (body) => axios.post(BASE_URL + CREATE_PLAYSTARZ_SCORING, body)
    this.getPPSALL = (body) => axios.post(BASE_URL + GET_PLAYSTARZ_SCORING, body)
    this.editPPSALL = (body) => axios.post(BASE_URL + EDIT_PLAYSTARZ_SCORING, body)
    this.searchUniversalLeaderboardDetail = (body) => axios.post(BASE_URL + SEARCH_UNIVERSAL_LEADERBOARD, body)
    this.searchLeaderboardDetails = (body) => axios.post(BASE_URL + SEARCH_LEADERBOARD_DETAILS, body)
}

export default new LeaderboardServices()