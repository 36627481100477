import React, {useState, useEffect, useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faSearch, faPlusCircle, faTrash, faTrashAlt, faBars, faCheckCircle, faClock, faStopCircle, faCross, faTimesCircle, faHourglassHalf, faHourglassStart, faHourglassEnd} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col,InputGroup, Row, Nav, Button, ButtonGroup, Card, ListGroup, ListGroupItem} from '@themesberg/react-bootstrap';
import { Breadcrumb } from "react-bootstrap";
import { Link , useHistory} from "react-router-dom";
import { Routes } from "../../routes";
import {useDispatch, useSelector} from 'react-redux';
import { getallGamesAction } from '../../redux/action/gameAction';
import {editOrganizedCompetitionAction} from '../../redux/action/competitionAction'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../utlis/interceptor'
import { BASE_URL, GET_GAME, GET_COMPETITION_BY_GAME, GET_ORGANIZED_COMPETITION, GET_COMPETITION } from '../../resources/APIEndpoints';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import '../css/games.css'

function Gamewithcompetition(props) {

    const dispatch = useDispatch()
    const history = useHistory()

    // const dispatch = useDispatch();

    // const getAllCompetition = () => {
    //     const data = {
    //         gameFilters : {

    //           pagination : {

    //           }
    //         }
    //       }
    //       dispatch(getallGamesAction(data))
    // }

    // useEffect(()=>{
    //     getAllCompetition()
    // }, [])

    const [competitions, setCompetitions] = useState([])

    const handleRLDDChange= (item) => {
        setCompetitions(item)
    }

    const getCompetitionByGameId = async() => {
        let gameId = {
            gameId : props.location.id
        }
        let res = await axios.post(BASE_URL + GET_ORGANIZED_COMPETITION, gameId)
        for(var i = 0; i < res.data.competitions.length ; i++){
            res.data.competitions[i].compid = res.data.competitions[i]['id']
            res.data.competitions[i].id = i
        }
        setCompetitions(res.data.competitions)
    }

    useEffect(()=>{
        getCompetitionByGameId()
    }, [])

    const onSave = () => {
        let Organization = []
        competitions && competitions.forEach((e,i)=>{
            Organization.push(e.compid)
        })

        let organizeCompetition = {
            gameId : props.location.id,
            competitionsIds : Organization
        }

        dispatch(editOrganizedCompetitionAction(organizeCompetition, props.history))

    }

    const cancel = () => {
        history.push('/competition-games')
    }

    return (
        <>
            <ToastContainer />
                <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                    <Row>
                        <Col lg={12}>
                            <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                                <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                                <Breadcrumb.Item href="competition-games">Competition</Breadcrumb.Item>
                                <Breadcrumb.Item className="text-dark" active>Organize Competition</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </div>
        
                <div className="task-wrapper gamestarz">
                    <div className="row mb-4 px-4">
                        {/* <div className="col col-lg-6 col-md-12 text-left">
                            <h4>Organize Competition</h4>
                        </div> */}
                        {/* <div className="col col-lg-6 col-md-12 col-sm-12 text-right">
                            <span className='filter' style={{cursor: "pointer"}}><i className="fas fa-filter mx-2"></i><span><b>Filter</b></span></b></span>
                        </div> */}
                    </div>

                    <div className="container payout">
                        <div className="col col-lg-6 col-md-12 text-left mx-3">
                            <h4>Organize Competition</h4>
                        </div>


                    {competitions.length == 0 ? <p className=' mx-3'>No Competition Available.</p> :

                        competitions.length > 0 ?

                        <div className="mt-3 mb-5">
                            <RLDD cssClasses='example' items={competitions} itemRenderer={(competitions) => {
                                return (
                                <Card className='card' style={{cursor: 'pointer' , width: '100%', border : '1px solid #eee', padding : '10px 0px', margin : '20px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'}}>
                                    <Card.Header className='card-header'>
                                        <Row>
                                            <Col className='card-head-info'>
                                                <div>
                                                    <ListGroup>
                                                        <ListGroupItem><h5><b>{competitions.competition_name}</b></h5></ListGroupItem>
                                                    </ListGroup>
                                                </div>
                                                <div>
                                                    <ListGroup>
                                                        <ListGroupItem><b><span className={competitions.competition_status == 'completed' ? 'text-success' : competitions.competition_status == 'in progress' ? 'text-danger' : competitions.competition_status == 'yet to start' ? 'text-warning' : competitions.competition_status == 'in review' ? 'text-info' : competitions.competition_status == 'stopped' ? 'text-danger' : competitions.competition_status == 'failed' ? 'text-danger' : ''}><FontAwesomeIcon className='me-2' icon={competitions.competition_status == 'completed' ? faCheckCircle : competitions.competition_status == 'in progress' ? faClock : competitions.competition_status == 'yet to start' ? faHourglassStart : competitions.competition_status == 'in review' ? faHourglassEnd : competitions.competition_status == 'stopped' ? faStopCircle : competitions.competition_status == 'failed' ? faTimesCircle : ''} />{competitions.competition_status}</span></b></ListGroupItem>
                                                    </ListGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body className='card-body'>
                                        <Row className='my-2'>
                                            <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                                                <span><b>Competition Format : {competitions.competition_format_type_master_id == 2 ? 'Tournament' : competitions.competition_format_type_master_id == 3 ? 'Instant Battle' : competitions.competition_format_type_master_id == 5 ? 'Bracket' : ''}</b></span>
                                            </Col>
                                            <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                                                <span><b>Entry Fee : {competitions.entry_fee}</b></span>
                                            </Col>
                                            <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                                                <span><b>Hosting Fee : {competitions.hosting_fee}</b></span>
                                            </Col>
                                        </Row>

                                    </Card.Body>
                                    <Card.Footer className='card-footer'>
                                        <Row>
                                            <Col className='card-footer-info'>
                                                <div><span><b>Start Date : {(competitions.start_date != null || competitions.start_date != undefined)  ? competitions.start_date.slice(0,11) : '-'}</b></span></div>
                                                <div><span><b>End Date : {(competitions.end_date != null || competitions.end_date != undefined) ? competitions.end_date.slice(0,11) : '-'}</b></span></div>
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </Card>
                                );
                            }}  onChange={(item)=>handleRLDDChange(item)} />
                        </div>
                    : ''}
                    
                    {competitions.length > 0 ? 
                        <div className='mb-5 mx-3'>
                            <Button className='btn btn-success' onClick={onSave}>Save</Button>
                            <Button className='btn btn-danger mx-3' onClick={cancel}>Cancel</Button>
                        </div>
                    : ''}
                </div>
            </div>
        </>
    )
}

export default Gamewithcompetition