import React, { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { Col, Row, Button} from '@themesberg/react-bootstrap';
import { useHistory } from "react-router";
import { loginUser } from "../redux/action/authAction";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/login.css'

function LoginPageTwo(props) {
    const dispatch = useDispatch()
    const [details, setDetails] = useState({ username: "", password: "" });
    let history = useHistory();
    
    const userlogin = (e) => {
      if(details.username === "" || details.password === ""){
        dispatch(loginUser(details, props.history))
      }else{
        dispatch(loginUser(details, props.history))
      }
    }
    return (
    <>
        <div className="main">
            <Row>
                <Col xl={4} lg={4} md={6} sm={12} className="login">
                    <Row className="main-row">
                        <Col className="main-col">
                            <div className="login_model row justify-content-center">
                                <div className="login_title">
                                    <h2 className="text-center">Login</h2>
                                </div>
                                <div className="col-md-10">
                                    <div className="form-group my-4">
                                        <label>Username</label>
                                        <input className="input form-control p-2" type="text" name="username" onChange={(e) => setDetails({ ...details, username: e.target.value })} value={details.username}/>
                                    </div>
                                    <div className="form-groups my-4">
                                        <label>Password</label>
                                        <input className="input form-control p-2" type="Password" name="password" id="last-name"onChange={(e) =>setDetails({ ...details, password: e.target.value }) } value={details.password} />
                                    </div>
                                    <div className="text-center my-4">
                                        <Button variant="secondary" className="text-dark me-2" onClick={userlogin}>Login</Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xl={8} lg={8} md={6} sm={12} className='left_bg_image'>
                    <div className="overlay-col"></div>
                </Col>
            </Row>
        </div>
    </>
  )
}

export default LoginPageTwo