import React, {useState} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import {Row, Col} from '@themesberg/react-bootstrap'

function PDFilterModal(props) {
    
    const [showgameworld, setShowgameworld] = useState(false)
    const [showmatchoutcome, setShowmatchoutcome] = useState(false)
    const [showleaderboard, setShowleaderboard] = useState(false) 

    const onchangeWorld = () => {
        setShowgameworld(!showgameworld)
    }

    const onchangeMatchOutcome = () => {
        setShowmatchoutcome(!showmatchoutcome)
    }

    const onchangeLeaderboard = () => {
        setShowleaderboard(!showleaderboard)
    }

    return (
        <React.Fragment>
            <Modal isOpen={props.addOpen} toggle={props.Addtoggle} centered className='filter-main'>
                <ModalHeader><p className='heading-title'>Add Filter</p></ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <input type="checkbox" name="Game_Name" />
                                <labe for="Game_Name" className="mx-2">Game Name</labe>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <input type="checkbox" name="Leaderboard_Name" />
                                <labe for="Leaderboard_Name" className="mx-2">Match Name</labe>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <input type="checkbox" name="Game_World" onClick={()=>{onchangeWorld()}}/>
                                <labe for="Game_World" className="mx-2">Game World</labe>
                            </Col>
                        </Row>
                        {showgameworld ? 
                                <div>
                                    <Row className="my-2 px-5">
                                        <Col>
                                            <input type="checkbox" name="arcade" />
                                            <label for="arcade" className="mx-2">arcade</label>
                                        </Col>
                                        <Col>
                                            <input type="checkbox" name="board" />
                                            <label for="board" className="mx-2">board</label>
                                        </Col>
                                        <Col>
                                            <input type="checkbox" name="casino" />
                                            <label for="casino" className="mx-2">casino</label>
                                        </Col>
                                    </Row> 
                                    <Row className="my-2 px-5">
                                        <Col>
                                            <input type="checkbox" name="racing" />
                                            <label for="racing" className="mx-2">racing</label>
                                        </Col>
                                        <Col>
                                            <input type="checkbox" name="sports" />
                                            <label for="sports" className="mx-2">sports</label>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </div>
                                : ""}
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <input type="checkbox" name="Leaderboard_PD_Status" />
                                <labe for="Leaderboard_PD_Status" className="mx-2">Leaderboard PD Status</labe>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <input type="checkbox" name="Team" />
                                <labe for="Team" className="mx-2">Team</labe>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <input type="checkbox" name="Match_Outcome_Type" value="1" onChange={()=>{onchangeMatchOutcome()}}/>
                                <labe for="Match_Outcome_Type" className="mx-2">Match Outcome Type</labe>
                            </Col>
                        </Row>
                        {showmatchoutcome ? 
                                <div>
                                    <Row className="my-2 px-5">
                                        <Col>
                                            <input type="checkbox" name="High_Score" />
                                            <label for="High_Score" className="mx-2">High Score</label>
                                        </Col>
                                        <Col>
                                            <input type="checkbox" name="Podium_Finsish" />
                                            <label for="Podium_Finsish" className="mx-2">Podium Finsish</label>
                                        </Col>
                                    </Row> 
                                    <Row className="my-2 px-5">
                                        <Col>
                                            <input type="checkbox" name="Time_Trial" />
                                            <label for="Time_Trial" className="mx-2">Time Trial</label>
                                        </Col>
                                        <Col>
                                            <input type="checkbox" name="Win/Draw/Loss" />
                                            <label for="Win/Draw/Loss" className="mx-2">Win/Draw/Loss</label>
                                        </Col>
                                    </Row>
                                    <Row className="my-2 px-5">
                                        <Col>
                                            <input type="checkbox" name="Winning_Collected" />
                                            <label for="Winning_Collected" className="mx-2">Winning Collected</label>
                                        </Col>
                                    </Row>
                                </div>
                                : ""}
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <input type="checkbox" name="Leaderboard_Outcome_Type" value="1" onChange={()=>{onchangeLeaderboard()}}/>
                                    <labe for="Leaderboard_Outcome_Type" className="mx-2">Leaderboard Outcome Type</labe>
                                </Col>
                                {showleaderboard ? 
                                <div>
                                    <Row className="my-2 px-5">
                                        <Col lg={6}>
                                            <input type="checkbox" name="High_Score" />
                                            <label for="High_Score" className="mx-2">High Score</label>
                                        </Col>
                                        <Col>
                                            <input type="checkbox" name="Time_Trial" />
                                            <label for="Time_Trial" className="mx-2">Time Trial</label>
                                        </Col>
                                    </Row> 
                                    <Row className="my-2 px-5">
                                        <Col>
                                            <input type="checkbox" name="Win/Draw/Loss" />
                                            <label for="Win/Draw/Loss" className="mx-2">Win/Draw/Loss</label>
                                        </Col>
                                        <Col>
                                            <input type="checkbox" name="Winning_Collected" />
                                            <label for="Winning_Collected" className="mx-2">Winning Collected</label>
                                        </Col>
                                    </Row>
                                </div>
                                : ""}
                            </Row>
                    </Form>
                </ModalBody>
                <ModalFooter className='filter-footer'>
                    <button className="btn btn-sm btn-success">Apply Filter</button>
                    <button className="btn btn-sm btn-danger" onClick={props.Addtoggle}>Cancel</button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default PDFilterModal