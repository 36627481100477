import { GET_DOCUMENTS, GET_DOCUMENTS_DETAIL, CREATE_DOCUMENTS, EDIT_DOCUMENTS, CLEAR_DOCUMENT_DETAIL } from "../actionType/actionTypes";
import DocumentServices from "../../services/DocumentServices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getAllDocumentsAction = (body) => {
    return async(dispatch) => {
        await DocumentServices.getAllDocuments(body)
        .then(res=>{
            dispatch(getAllDocuments(res.data.docDetails))
        })
    }
}

export const getDocumentDetailAction = (body) => {
    return async (dispatch) => {
        await DocumentServices.getDocumentDetail(body)
        .then(res=>{
            dispatch(getDocumentDetail(res.data.docDetails))
        })
    }
}

export const createDocumentsAction = (body, history) => {
    return async(dispatch) => {
        await DocumentServices.createDocuments(body)
        .then(res=>{
            toast.success("Document Created Successfully")
            setTimeout(()=>{
                history.push('/documents')
            }, 1500)
        })
    }
}

export const editDocumetAction = (body, history) => {
    return async(dispatch) => {
        await DocumentServices.editDocuments(body)
        .then(res=>{
            toast.success("Document Updated Successfully")
            setTimeout(()=>{
                history.push('/documents')
            }, 1500)
        })
    }
}

export const clearDocDetailAction = () => {
    return async(dispatch) => {
        dispatch(clearDocDetail())
    }
}

export const getAllDocuments = (data) => {
    return{
        type : GET_DOCUMENTS,
        payload : data
    }
}

export const getDocumentDetail = (data) => {
    return{
        type : GET_DOCUMENTS_DETAIL,
        payload : data
    }
}

export const createDocuments = (data) => {
    return{
        type : CREATE_DOCUMENTS,
        payload : data
    }
}

export const editDocuments = (data) => {
    return{
        type : EDIT_DOCUMENTS,
        payload : data
    }
}

export const clearDocDetail = (data) => {
    return{
        type : CLEAR_DOCUMENT_DETAIL,
        payload : data
    }
}