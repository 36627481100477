import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col, Dropdown} from '@themesberg/react-bootstrap'

function DeactivateCoupan({show, onHide}) {

    const close = (e) => {
        onHide();
    }

    return (
        <div>
            <Modal size="md" centered show={show} onHide={onHide}>
                <Modal.Header>
                    <h5>Coupon Name</h5>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Row className="my-2">
                        <Col lg={12} md={12} sm={12}>
                            <i class="fas fa-times-circle fa-3x text-danger"></i>
                        </Col>
                    </Row>
                    <Row className="my-2">
                        <Col lg={12} md={12} sm={12}>
                            <h6>Do you want to Deactivate this Coupon?</h6>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-success mx-2">Deactivate</button>
                    <button className="btn btn-danger" onClick={close}>Close</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeactivateCoupan