import React, {useState, useEffect} from 'react'
import {Dropdown, Row, Col, ButtonGroup, Button, InputGroup, Form, Nav} from '@themesberg/react-bootstrap'
import XLSX from 'xlsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CountryModal from '../modals/CountryModal'
import  TablePagination  from '../components/TablePagination';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createTemplateAction } from '../../redux/action/competitionAction';
import customAxios from '../../utlis/interceptor';
import axios from 'axios';

function Templates(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const [Competition, setCompetition] = useState("")
    const [templateName, setTemplateName] = useState("")
    const [entryFeeTotal, setEntryFeeTotal] = useState("")
    const [bonusEntryFee, setBonusEntryFee] = useState("")
    const [minPlayers, setMinPlayer] = useState("")
    const [maxPlayers, setMaxPlayer] = useState("")
    const [attempts, setAttempts] = useState(1)
    const [entries, setEntries] = useState(1)
    const [winners, setWinners] = useState("")
    const [userAmount, setUserAmount] = useState("")
    const [pdRule, setPDRule] = useState([])
    const [PDMode, setPDMode] = useState("")
    const [hostingFeeType, setHostingFeeType] = useState("")
    const [UserFee, setUserFee] = useState("")
    const [flatHost, setFlatHost] = useState("")
    const[colTitle, setColTitle] = useState()
    const[data, setData] = useState([])
    const[checkAmount, setCheckAmount] = useState()
    const [fileName, setFilename] = useState("")

    const [templateNameErr,setTemplateErr ] = useState(false)
    const [attemptErrr, setAttemptsErr] = useState(false)
    const [entryErr, setEntryErr] = useState(false)
    const [totalEntryErr, setTotalEntryErr] = useState(false)
    const [bonusErr, setBonusErr] = useState(false)
    const [minErr, setMinError] = useState(false)
    const [maxError, setMaxError] = useState(false)
    const [winnerErr, setWinnerErr] = useState(false)
    const [flatErr, setFlatErr] = useState(false)
    const [userFeeErr, setUserFeeErr] = useState(false)

    const [GPD, setGPD] = useState()

    
    const onCompetitionChange = (e) => {
        setCompetition(parseInt(e.target.value))
    }

    const onTemplateName = (e) => {
        if(e.target.value == ''){
            setTemplateErr(true)
        }else{
            setTemplateName(e.target.value)
            setTemplateErr(false)
        }
    }

    const onEntryFeeTotal = (e) => {
        if(e.target.value == ''){
            setTotalEntryErr(true)
        }else{
            setEntryFeeTotal(parseInt(e.target.value))
            setTotalEntryErr(false)
        }   
    }

    const onBonusEntryFee = (e) => {
        let checkBonus = ((flatHost/100)*entryFeeTotal)
        if(e.target.value == ''){
            setBonusErr(true)
        }else if(checkBonus <= e.target.value){
            toast.error(`Entry Fee Bonus Wallet Limit Should Be Less Than ${checkBonus}`)
            setBonusEntryFee(parseInt(e.target.value))
        }
        else{
            setBonusEntryFee(parseInt(e.target.value))
            setBonusErr(false)
        }
    }

    const onMinPlayer = (e) => {
        if(e.target.value == ''){
            setMinError(true)
        }else{
            setMinPlayer(parseInt(e.target.value))
            setMinError(false)
        }
        
    }

    const onMaxPlayer = (e) => {
        if(e.target.value == ''){
            setMaxError(true)
        }else{
            setMaxPlayer(parseInt(e.target.value))
            setMaxError(false)
        }
        
    }

    const onAttempt = (e) => {
        if(e.target.value == ''){
            setAttemptsErr(true)
        }else{
            setAttemptsErr(false)
            setAttempts(parseInt(e.target.value))
        }
        
    }

    const onWinners = (e) => {
        if(e.target.value == ''){
            setWinnerErr(true)
        }else{
            setWinners(parseInt(e.target.value))
            setWinnerErr(false)
        }
        
    }

    const onPrizePerUser = (e) => {
        setUserAmount(parseInt(e.target.value))        
    }

    const onEntries = (e) => {
        if(e.target.value == ''){
            setEntryErr(true)
        }else{
            setEntries(parseInt(e.target.value))
            setEntryErr(false)
        }
    }

    const onPDMode = (e) => {
        setPDMode(e.target.value)
    }

    const [teams, setTeams] = useState("")

    const onTeams = (e) => {
        setTeams(parseInt(e.target.value))
    }

    const onHostingFeeUser = (e) => {
        if(e.target.value == ''){
            setUserFeeErr(false)
        }else{
            setUserFee(e.target.value)
            setUserFeeErr(false)
        }
    }

    const onHostFlat = (e) =>{
        if(e.target.value == ''){
            setFlatErr(true)
        }else{
            setFlatHost(parseInt(e.target.value))
            setFlatErr(false)
        }
        
    }
    const onHostPercentage = (e) =>{
        if(e.target.value == ''){
            setFlatErr(true)
        }else{
            setFlatHost(parseInt(e.target.value))
            setFlatErr(false)
        }       
    }

    const convertToJson = (headers, data) =>{
        const rows = []
        data && data.forEach(row=>{
            if(row.length != 0){
                let rowData = {}
                row.forEach((ele, index)=>{
                    rowData[headers[index]] = ele;
                })
                rows.push(rowData)
            }
        })
        setCheckAmount(rows)
        return rows

    }

    const onPD = (e) => {
        setGPD(false)
        var file = e.target.files[0];
        setFilename(e.target.files[0].name)
        const reader = new FileReader()
        reader.onload=(event)=>{
            const bstr = event.target.result
            const workBook = XLSX.read(bstr, {type:"binary"})

            const worksheetName = workBook.SheetNames[0]
            const workSheet =workBook.Sheets[worksheetName]

            const fileData = XLSX.utils.sheet_to_json(workSheet, {header:1})

            const headers = fileData[0]
            fileData.splice(0,1)
            
            setData(convertToJson(headers, fileData))

            const heads = headers.map(head=>({title:head, field: head}))
            setColTitle(heads)
        }
        reader.readAsBinaryString(file) 
    }

    /*   Front End Pagination to view xlsx or csv data  */
    const [currentPage, setCurrentPage] = useState(1);
    const [entryPerPage, setEntryPerPage] = useState(10)


    const indexOfLastEntry = currentPage * entryPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entryPerPage;
    const currentEntry = data.slice(indexOfFirstEntry, indexOfLastEntry)
    


    const Paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const changePageEntry = (e) => {
        const chanegedPage = parseInt(e.target.value)
        setEntryPerPage(chanegedPage)
    }

    const [compErr, setCompErr] = useState(false)

    const validation = () => {
        if(templateName == '' || templateName == null){
            setTemplateErr(true)
        }

        if(Competition == '' || Competition == null){
            setCompErr(true)
        }
        
        if(minPlayers == '' || minPlayers == null){
            setMinError(true)
        }

        if(maxPlayers == '' || maxPlayers == null){
            setMaxError(true)
        }

        if(entries == '' || entries == null){
            setEntryErr(true)
        }
        
        if(entryFeeTotal == '' || entryFeeTotal == null){
            setTotalEntryErr(true)
        }

        if(bonusEntryFee == '' || bonusEntryFee == null ){
            setBonusErr(true)
        }
        
        if(winners == '' || winners == null){
            setWinnerErr(true)
        }
    
        if(flatHost == '' || flatHost == null){
            setFlatErr(true)
        }
        
        if(UserFee == '' || UserFee == null){
            setUserFeeErr(true)
        }
        
    }

    const saveTemplates = () => {

        if(GPD == false && (data[0].TotalAmount == entryFeeTotal * maxPlayers * (1-(flatHost/100)) || data[0].TotalAmount == entryFeeTotal * maxPlayers - (maxPlayers*flatHost))){
            let PD_RULE = data
            let saveTemplate = {
                competitionFormatTypeMasterId: Competition,
                templateName: templateName,
                minPlayers: minPlayers,
                maxPlayers: maxPlayers,
                maxEntryAllowed: entries,
                maxAttemptAllowed : attempts,
                entryFee: entryFeeTotal,
                maxBonusLimit: bonusEntryFee,
                numberOfWinner: winners,
                hostingFee: flatHost,
                hostingFeeType: UserFee,
                hostingFeeprizeDistributionMode: PDMode,
                prizeDistributionRule : PD_RULE,
                totalAmount : data[0].TotalAmount
            }

            if(templateNameErr == true || minErr == true || maxError == true || entryErr == true || totalEntryErr == true || bonusErr == true ||
                winnerErr == true || flatErr == true || userFeeErr == true || compErr == true){
                validation();
            }else{
                let checkBonus = ((flatHost/100)*entryFeeTotal)
                if(bonusEntryFee >= checkBonus){
                    toast.error(`Entry Fee Bonus Wallet Limit Should Be Less Than ${checkBonus}`)
                }else{
                    delete saveTemplate.prizeDistributionRule[0].TotalAmount
                    dispatch(createTemplateAction(saveTemplate, props.history))
                }
            }
        }else if(GPD == true){
            let saveTemplate = {
                competitionFormatTypeMasterId: Competition,
                templateName: templateName,
                minPlayers: minPlayers,
                maxPlayers: maxPlayers,
                maxEntryAllowed: entries,
                maxAttemptAllowed : attempts,
                entryFee: entryFeeTotal,
                maxBonusLimit: bonusEntryFee,
                numberOfWinner: winners,
                hostingFee: flatHost,
                hostingFeeType: UserFee,
                hostingFeeprizeDistributionMode: PDMode,
                prizeDistributionRule : data,
                totalAmount : (UserFee == 'flat' ? (entryFeeTotal * maxPlayers - (maxPlayers*flatHost)) : UserFee == 'percentage' ? (entryFeeTotal * maxPlayers * (1-(flatHost/100))) : '')
            }

            if(templateNameErr == true || minErr == true || maxError == true || entryErr == true || totalEntryErr == true || bonusErr == true ||
                winnerErr == true || flatErr == true || userFeeErr == true || compErr == true){
                validation();
            }else{
                let checkBonus = ((flatHost/100)*entryFeeTotal)
                if(bonusEntryFee >= checkBonus){
                    toast.error(`Entry Fee Bonus Wallet Limit Should Be Less Than ${checkBonus}`)
                }else{
                    dispatch(createTemplateAction(saveTemplate, props.history))
                }
            }

        }else{
            toast.error("Total prize money available does no match")
        }

    }

    const saveBracketTemplates = () => {

        if(teams % 2 != 0){
            setTeams('')
            toast.error("No Of Teams must be even")
        }else{
            let bracketTemplate = {
                competitionFormatTypeMasterId: Competition,
                templateName: templateName,
                maxEntryAllowed: entries,
                entryFee: entryFeeTotal,
                maxBonusLimit: bonusEntryFee,
                hostingFee: flatHost,
                hostingFeeType: UserFee,
                numberOfTeams: teams
            }
    
            if(templateNameErr == true || entryErr == true || totalEntryErr == true || bonusErr == true || flatErr == true || userFeeErr == true){
                validation();
            }else{
                dispatch(createTemplateAction(bracketTemplate, props.history))
            }
        }

    }

    const GenratePD = async() => {
        setGPD(true)
        let getPD = {
            playersNumber: maxPlayers,
            entryFee: entryFeeTotal,
            hostingFee: (flatHost/100)
        }
        if(getPD.playersNumber == '' || getPD.playersNumber == undefined || getPD.playersNumber == null || getPD.entryFee == '' || getPD.entryFee == undefined || getPD.entryFee == null || getPD.hostingFee == 0 || getPD.hostingFee == undefined || getPD.hostingFee == null){
            toast.error(`Maximum Players, Entry Fee and Hosting Fee is required`)
        }else{
            let res = await customAxios.post('https://staging.gamestarz.xyz/v1/admin/generatePrizeDistribution', getPD)
            setData(res.data.prizeDistributionData)
        }
    }

    const cancel = () => {
        history.push('/competition') 
    }

    return (
        <>
        <ToastContainer />
        <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
            <Row>
                <Col lg={12}>
                <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                    <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                    <Breadcrumb.Item href='#competition'>Competition</Breadcrumb.Item>
                    <Breadcrumb.Item className="text-dark" active>Create Template</Breadcrumb.Item>
                </Breadcrumb>
                </Col>
            </Row>
        </div>
        <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
                    
                </Col>
            </div>
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5 create-competition">
                <div className="container">
                    <Row className="mb-3">
                        <Col lg={12} md={12}>
                            <p className='page-title'>Create Template</p>
                        </Col>
                    </Row>

                    <Row className="my-3">
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <label className='mb-1' for="competitionFormat">Choose Competition Format</label>
                            <select className="form-select input_bg" onChange={(e)=>{onCompetitionChange(e)}}>
                                <option>Select Competition Format</option>
                                <option value="2">Tournament</option>
                                <option value="3">Instant Battle</option>
                                <option value="5">Bracket</option>
                            </select>
                        </Col>
                        {Competition == 2 || Competition == 3 || Competition == 5 ?
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <label className='mb-1' for="templateName">Template Name</label>
                            <input className='form-control input_bg' type='text' onChange={(e)=>{onTemplateName(e)}}/>
                            {templateNameErr ? <span className='text-danger'>Template Name is required.</span> : ""}
                        </Col> 
                        : ""}
                    </Row>
                    {Competition == 2 || Competition == 3 || Competition == 5 ?
                        <div>
                            <Row className="my-3">
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <label className='mb-1' for="entryFee">Entry Fee Total</label>
                                    <input className='form-control input_bg' type='number' onChange={(e)=>{onEntryFeeTotal(e)}}/>
                                    {totalEntryErr ? <span className='text-danger'>Entry Fee is required.</span> : ""}
                                </Col>

                                {/* <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <label className='mb-1' for="bonusWallet">Entry Fee Bonus Wallet Limit</label>
                                    <input className='form-control input_bg' type='number' max={19} onChange={(e)=>{onBonusEntryFee(e)}}/>
                                    {bonusErr ? <span className='text-danger'>Entry fee bonus wallet limit is required.</span> : ""}
                                </Col> */}
                            </Row>
                            { Competition == 2 || Competition == 3 ?
                            <Row className="my-3">
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <label className='mb-1' for="minimumPlayer">Minimum Player</label>
                                    <input className='form-control input_bg' type='number' onChange={(e)=>{onMinPlayer(e)}}/>
                                    {minErr ? <span className='text-danger'>Minimum Player is required.</span> : ""}
                                </Col>

                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <label className='mb-1' for="maximumPlayer">Maximum Player</label>
                                    <input className='form-control input_bg' type='number' onChange={(e)=>{onMaxPlayer(e)}}/>
                                    {maxError ? <span className='text-danger'>Maximum Player is required.</span> : ""}
                                </Col>
                            </Row>
                            : ""}
                            <Row className="my-3">
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <label className='mb-1' for="noOfEntries">Number Of Attempts</label>
                                    <input className='form-control input_bg' defaultValue={Competition == 3 ? entries : ''} disabled={Competition == 2 ? false : Competition == 3 ? true : false} type='number' onChange={(e)=>{onEntries(e)}}/>
                                </Col>
                                { Competition == 2 || Competition == 3 ?
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <label className='mb-1' for="noOfWinners">Number Of Winners</label>
                                    <input className='form-control input_bg' type='text' onChange={(e)=>{onWinners(e)}}/>
                                    {winnerErr ? <span className='text-danger'>Number of winner is required.</span> : ""}
                                </Col>
                                : Competition == 5 ?
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <label className='mb-1' for="noOfEntries">Number Of Entries</label>
                                    <input className='form-control input_bg' defaultValue={(Competition == 3 || Competition == 2) ? entries : ''} disabled={(Competition == 3 || Competition == 2) ? true : false} type='number' onChange={(e)=>{onAttempt(e)}}/>
                                    {entryErr ? <span className='text-danger'>Number of entries is required.</span> : ""}
                                </Col>
                                :""}
                            </Row>

                            { Competition == 2 || Competition == 3 ?
                            <Row className="my-3">
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <label className='mb-1' for="noOfEntries">Number Of Entries</label>
                                    <input className='form-control input_bg' defaultValue={(Competition == 3 || Competition == 2) ? entries : ''} disabled={(Competition == 3 || Competition == 2) ? true : false} type='number' onChange={(e)=>{onAttempt(e)}}/>
                                    {entryErr ? <span className='text-danger'>Number of entries is required.</span> : ""}
                                </Col>
                                
                                <Col xl={6} lg={6} md={6} sm={12} xs={12} className='input-file'>
                                    <label className='mb-1' for="noOfWinners">Prize Distribution</label> <br />
                                    <div className='d-flex'>
                                    <input disabled={maxPlayers != "" && entryFeeTotal != "" ? false : true} id='PD' type="file" accept=".csv, .xlsx" className="form-control" onChange={(e)=>{onPD(e)}}/>
                                    <label for='PD' className='label-btn'><FontAwesomeIcon icon={faPlusCircle}/> Choose File</label> <br />
                                    <Button className='label-btn mx-3' onClick={GenratePD}>Genrate PD</Button>
                                    </div>
                                </Col>

                            </Row>
                            :""}

                            {/* View Uploaded xlsx or csv File */}

                            {data && data !="" && data[0].startRank ? 
                                <div>
                                    <Row className="mt-3">
                                        <Col>
                                            <p style={{marginBottom : "7px"}}><b>Batch Name : {fileName}</b></p>
                                            <p style={{marginBottom : "7px"}}><b>Total Amount : &#8377; {data[0].TotalAmount}</b></p>
                                        </Col>
                                    </Row>
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <table className="table table-striped text-center table-custom">
                                        <thead className='table-head'>
                                            <tr>
                                                <th className='head-border-left' scope="col">Rank</th>
                                                <th className='head-border-right' scope="col">Prize</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentEntry && currentEntry.map((e)=>{
                                                return(
                                                    <tr className='table-body'>
                                                        <td>{e.startRank} {e.endRank ? "-" : ""} {e.endRank}</td>
                                                        <td>{e.amount}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        </table>
                                    </Col>
                                </Row>
                        </div>
                    : ""}
                    {data && data != "" && data[0].startRank && data[0].amount ?
                        <Row>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <Col>
                                        <TablePagination entryPerPage={entryPerPage} totalEntry={data.length} Paginate={Paginate}/>
                                    </Col>
                                </div>
                                <div>
                                    <Col>
                                        <label style={{paddingRight: "10px"}}>Rows Per Page</label>
                                        <select className='entries' onChange={(e)=>{changePageEntry(e)}}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                        </select>
                                    </Col>
                                </div>
                            </div>
                        </Row>
                    : data && data != ""  ? <div><p className="text-danger font-weight-bold">Please upload valid file.</p></div> : ""}

                            <Row className="my-3">
                                {Competition == 2 || Competition == 3 ?
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <label className='mb-1' for="prizeUser">Prize Distribution Mode</label>
                                    <select className='form-select input_bg' onChange={(e)=>{onPDMode(e)}}>
                                        <option>Select Prize Distribution Mode</option>
                                        <option value="dynamic">Dynamic</option>
                                        <option value='guaranteed'>Guaranteed</option>
                                    </select>
                                </Col>
                                : Competition == 5 ?
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <label>No of teams</label>
                                    <input className='form-control input_bg' value={teams} type='number' step="2" min="0" onChange={(e)=>{onTeams(e)}}/>
                                </Col>
                                : ""}
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <label className='mb-1' for="prizeUser">Hosting Fee Per User</label>
                                    <select className='form-select input_bg' onChange={(e)=>{onHostingFeeUser(e)}}>
                                        <option>Select Hosting Fee Per User</option>
                                        <option value="percentage">Percentage</option>
                                        <option value='flat'>Flat</option>
                                    </select>
                                    {userFeeErr ? <span className='text-danger'>Hosting Fee Per User is required.</span> : ""}
                                </Col>
                            </Row>

                            <Row className='my-3'>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12} className='input-file'>
                                    {(Competition == 2 || Competition == 3 || Competition == 5) && UserFee === "flat" ? 
                                        <div>
                                            <label>Hosting Fee Per User (flat)</label>
                                            <input className='form-control input_bg' type='text' onChange={(e)=>{onHostPercentage(e)}}/>
                                            {flatErr ? <span className='text-danger'>Hosting Fee Per User is required.</span> : ""}
                                        </div>
                                    : (Competition == 2 || Competition == 3 || Competition == 5) && UserFee === "percentage" ?
                                    <div>
                                        <label>Hosting Fee Per User (%)</label>
                                        <input className='form-control input_bg' type='text' onChange={(e)=>{onHostFlat(e)}}/>
                                        {flatErr ? <span className='text-danger'>Hosting Fee Per User is required.</span> : ""}
                                    </div>
                                    : ""}
                                </Col>
                                {(Competition == 2 || Competition == 3) && UserFee === "percentage" ? 
                                    <Col lg={6} md={6} sm={12}>
                                        <label>Total Prize Money Available (for Percentage)</label>
                                        <input type = "number" className='form-control input_bg' disabled value = {entryFeeTotal * maxPlayers * (1-(flatHost/100))}/>
                                    </Col>
                                    :(Competition == 2 || Competition == 3) && UserFee === "flat" ?
                                    <Col lg={6} md={6} sm={12}>
                                    <label>Total Prize Money Available (for Flat)</label>
                                    <input type = "number" className='form-control input_bg' disabled value = {entryFeeTotal * maxPlayers - (maxPlayers*flatHost)} />
                                </Col>: ""}
                            </Row>

                            <Row className='my-3'>
                                <Col lg={6} md={6} sm={12}>
                                    <label className='mb-1' for="bonusWallet">Entry Fee Bonus Wallet Limit</label>
                                    <input className='form-control input_bg' type='number' max={(flatHost/100)*entryFeeTotal} onChange={(e)=>{onBonusEntryFee(e)}}/>
                                    {bonusErr ? <span className='text-danger'>Entry fee bonus wallet limit is required.</span> : ""}
                                </Col>
                            </Row>

                            <Row className='my-3'>
                                <Col xl={12} lg={12} md={12} sm={12}>
                                    {Competition == 2 || Competition == 3 ? <button className='btn btn-success' disabled={data.length != 0  ? false : true} onClick={saveTemplates}>Save Template</button> : ""}
                                    {Competition == 5 ? <button className='btn btn-success' onClick={saveBracketTemplates}>Save Template</button> : ""}
                                    <button className='btn btn-danger mx-2' onClick={cancel}>Cancel</button>
                                </Col>
                            </Row>

                        </div>
                    :""}
                </div>
            </div>
        </>
  )
}


export default Templates