
import React from 'react';
import { Image } from '@themesberg/react-bootstrap';

import ReactLogo from "../assets/img/technologies/react-logo-transparent.svg";

export default (props) => {

  const { show } = props;

  return (
    <div className={`preloader bg-white flex-column justify-content-center align-items-center ${show ? "" : "show"}`}>
      {/* <Image className="loader-element animate__animated animate__jackInTheBox" src={ReactLogo} height={40} /> */}
      <div>
            <svg>
                <defs>
                    <linearGradient id="grad1">
                        <stop offset="0%" stop-color="#7A4BFF"/>
                        <stop offset="100%" stop-color="#7A4BFF" stop-opacity="0.2" />
                    </linearGradient>
                </defs>
                <circle cx="75" cy="75" r="30" stroke="url(#grad1)" stroke-width="8" fill="none"></circle>
            </svg>
        </div>
    </div>
  );
};
