import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import {Game_Details, Game_Id,Game_Name, Game_Description, Game_How_to, Game_Platform, Asset_Bundle_Version, Minimum_App_Version, Game_Genre, Screen_Orientation, Game_World, Match_Name, Match_Format, Match_How_To, Match_Outcome_Type, Leaderboard_Outcome_Type, Included_Countries, Phase, States, Match_Details, Game_Screenshots} from '../../../constants/LabelConstants'
import '../../css/games.css'
import { Link} from "react-router-dom";
import { Routes } from "../../../routes";
import { Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faPen} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { EditExternalGameAction } from '../../../redux/action/gameAction';
import { useHistory } from 'react-router-dom';

export default function ExternalGameDetailModal({show, onHide, filter, id}) {

    const history = useHistory();
    const dispatch = useDispatch();

    const accept = () => {
        
        let acceptedGame = {
            id :  gamesDetail[0].id,
            gameSubmissionStatus : "approved"
        }
        dispatch(EditExternalGameAction(acceptedGame))
        onHide();
    }

    const reject = () => {
        let rejectedGames = {
            id :  gamesDetail[0].id,
            gameSubmissionStatus : "rejected"
        }
        dispatch(EditExternalGameAction(rejectedGames))
        onHide();
    }
    
    let gamesDetail = useSelector((state)=>{ 
        return state.game.externalGameDetail
    })

    if(gamesDetail && gamesDetail.length){
        return(
            <Modal className='gamedetail-model' scrollable={true} contentClassName="new_modal"  centered show={show} onHide={onHide}>
            <Modal.Header style={{display: 'block'}}>
                <div className="modal_head">
                    <Row className="d-flex align-items-center">
                        {gamesDetail[0].gameLogo != null ?
                        <Col xl={1} lg={1} md={1} sm={4}>
                            <img src={gamesDetail[0].gameLogo} width='200' className='img-fluid' alt='gameLogo' />
                        </Col>
                        : ''}
                        <Col xl={11} lg={11} md={11} sm={8}>
                            <span className="gamedetail-heading">{gamesDetail[0].gameName}</span>
                        </Col>
                    </Row>
                </div>
            </Modal.Header>
            <Modal.Body className='gamedetail-body'>
                <Row>
                    <Col lg={12} md={12} sm={12}>
                        <Row>
                            <Col lg={12}>
                                <h6 className='body-title'>{Game_Details}</h6>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col lg={6} md={12} sm={12}>
                                <label>{Game_Name}</label>
                                <input type='text' disabled className='form-control' defaultValue={gamesDetail[0].gameName != "" ? gamesDetail[0].gameName : "No info"} /> 
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <span>{Game_Id}</span> 
                                <input type='text' disabled className='form-control' defaultValue={gamesDetail[0].id != "" ? gamesDetail[0].id : "No info"}/>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col lg={12} md={12} sm={12}>
                                <span>{Game_Description}</span>
                                <textarea rows={5} type='text' disabled className='form-control' defaultValue={gamesDetail[0].gameDescription =="" || gamesDetail[0].gameDescription == null  ? "No info" : gamesDetail[0].gameDescription !="" ? gamesDetail[0].gameDescription : ""}></textarea> 
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col lg={12} md={12} sm={12}>
                                <span>{Game_How_to} </span>
                                <textarea rows={5} type='text' disabled className='form-control' defaultValue={gamesDetail[0].gameHowTo =="" || gamesDetail[0].gameHowTo == null  ? "No info" : gamesDetail[0].gameHowTo !="" ? gamesDetail[0].gameHowTo : ""}></textarea> 
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col lg={6} md={12} sm={12}>
                                <span>Game Platforms</span>
                                <p className='input-bg'>{gamesDetail[0].gameSubmissionPlatforms.length !=0 ? gamesDetail[0].gameSubmissionPlatforms && gamesDetail[0].gameSubmissionPlatforms.map((p,i)=>{return(<span>{(i ? ', ' : '') + p.gamePlatformMaster.platformName}</span>)}) : "-"}</p>
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <span>{Screen_Orientation}</span> 
                                <input type='text' disabled className='form-control' defaultValue= {gamesDetail[0].isGameScreenOrientationLandscape === true ? "Landscape" : gamesDetail[0].isGameScreenOrientationLandscape === false ? "Portrait" : "No info"}/>
                            </Col>
                        </Row>

                        {gamesDetail[0].gameSubmissionPlatforms[0].gameUrl == null  ?
                        <Row className="mt-3">
                            <Col lg={6} md={12} sm={12}>
                                <div className="mb-2">Android Asset Bundle</div> 
                                {gamesDetail[0].gameSubmissionPlatforms.length != 0 &&  gamesDetail[0].gameSubmissionPlatforms[0].gamePlatformMaster.platformName === 'android' ? <a className='download-btn' href={gamesDetail[0].gameSubmissionPlatforms[0].gameAssetBundleUrl}>Download file</a> : gamesDetail[0].gameSubmissionPlatforms.length > 1 &&  gamesDetail[0].gameSubmissionPlatforms[1].gamePlatformMaster.platformName === 'android' ? <a className='download-btn' href={gamesDetail[0].gameSubmissionPlatforms[1].gameAssetBundleUrl}>Download file</a>  : "No info"}
                                
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <div className="mb-2">iOS Asset Bundle</div> 
                                {gamesDetail[0].gameSubmissionPlatforms.length != 0 &&  gamesDetail[0].gameSubmissionPlatforms[0].gamePlatformMaster.platformName === 'ios' ? <a className='download-btn' href={gamesDetail[0].gameSubmissionPlatforms[0].gameAssetBundleUrl}>Download file</a> : gamesDetail[0].gameSubmissionPlatforms.length > 1 &&  gamesDetail[0].gameSubmissionPlatforms[1].gamePlatformMaster.platformName === 'ios' ? <a className='download-btn' href={gamesDetail[0].gameSubmissionPlatforms[1].gameAssetBundleUrl}>Download file</a>  :"No info"}
                            </Col>
                        </Row>
                        :gamesDetail[0].gameSubmissionPlatforms[0].gameAssetBundleUrl == null?
                        
                        <Row className="mt-3">
                            <Col lg={6} md={12} sm={12}>
                                <div className="mb-2">Playstore Link</div> 
                                {gamesDetail[0].gameSubmissionPlatforms.length != 0 &&  gamesDetail[0].gameSubmissionPlatforms[0].gamePlatformMaster.platformName === 'android' ? <a target="_blank" className='download-btn' href={gamesDetail[0].gameSubmissionPlatforms[0].gameUrl}>View</a> : gamesDetail[0].gameSubmissionPlatforms.length > 1 &&  gamesDetail[0].gameSubmissionPlatforms[1].gamePlatformMaster.platformName === 'android' ? <a target="_blank" className='download-btn'  href={gamesDetail[0].gameSubmissionPlatforms[1].gameUrl}>View</a> : "No info"}
                                
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <div className="mb-2">Appstore Link</div> 
                                {gamesDetail[0].gameSubmissionPlatforms.length != 0 &&  gamesDetail[0].gameSubmissionPlatforms[0].gamePlatformMaster.platformName === 'ios' ? <a target="_blank" className='download-btn' href={gamesDetail[0].gameSubmissionPlatforms[0].gameUrl}>View</a> : gamesDetail[0].gameSubmissionPlatforms.length > 1 &&  gamesDetail[0].gameSubmissionPlatforms[1].gamePlatformMaster.platformName === 'ios' ? <a target="_blank" className='download-btn' href={gamesDetail[0].gameSubmissionPlatforms[1].gameUrl}>View</a> : "No info"}
                            </Col>
                        </Row>
                        :''}

                        <Row className="mt-3">
                            <Col lg={6} md={12} sm={12}>
                                <span>{Game_Genre}</span>
                                <input type='text' disabled className='form-control' defaultValue={gamesDetail[0].gameGenreMaster == null ? "-" : gamesDetail[0].gameGenreMaster != null ? gamesDetail[0].gameGenreMaster.genreName : ""} /> 
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <span>{Game_World}</span> 
                                <input type='text' disabled className='form-control' defaultValue="Sports"/>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <span>{Game_Screenshots}</span>
                                <p className='input-bg'>
                                    <Row>
                                        {gamesDetail[0].gameScreenShotUrl !="" && gamesDetail[0].gameScreenShotUrl != null ? 
                                        gamesDetail[0].gameScreenShotUrl !="" && gamesDetail[0].gameScreenShotUrl != null && gamesDetail[0].gameScreenShotUrl && gamesDetail[0].gameScreenShotUrl.map((e,i)=>{
                                            return(
                                            <Col xl={2} lg={2} md={3} sm={4}>
                                                    <img src={e} className='img-responsive my-2' alt='Screenshot'/>
                                            </Col>
                                            )
                                        })  
                                        : <p>No Info</p>}
                                    </Row>
                                </p>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <span>Preview Video</span>
                                <p className='input-bg'>
                                    <Row>
                                    <Col lg={4}>
                                        {gamesDetail[0].gamePreviewVideoUrl && gamesDetail[0].gamePreviewVideoUrl != null ? <video width="200" controls src={gamesDetail[0].gamePreviewVideoUrl !='' ? gamesDetail[0].gamePreviewVideoUrl  : ''} type="video/mp4"/> : 'No Info'}
                                    </Col>
                                    </Row>
                                </p>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Modal.Body>
        <Modal.Footer className='gamedetail-footer'>
            <Row>
                <Col>
                    <Button className="btn btn-success btn-md mx-2" onClick={accept}>Approve</Button>
                    <Button className="btn btn-danger btn-md mx-2" onClick={reject}>Reject</Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
    )
    }else{
        return(<p></p>)
    }
}
