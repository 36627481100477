import React, {useEffect, useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Checkbox, Select, TextField } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import '../css/AddGame.css'
import { Breadcrumb } from "react-bootstrap";
import {useDispatch} from 'react-redux'
import { addSinglePayoutAction } from '../../redux/action/playstarzbankAction';
import {useHistory} from 'react-router-dom'
import axios from '../../utlis/interceptor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../../resources/APIEndpoints';

function SinglePayout(props) {
    const dispatch = useDispatch()
    const history = useHistory()

    const [contactErr, setContactError] = useState(false)
    const [recipientErr, setRecipientError] = useState(false)
    const [amountErr, setAmountErr] = useState(false)
    const [purposeErr, setPurposeErr] = useState(false)
    const [noteErr, setNoteErr] = useState(false)

    const [schedule, setSchedule] = useState()
    const onSchedule = (e) => {
        setSchedule(e.target.value)
    }

    const [amount, setAmount] = useState()
    const onAmount = (e) => {
        setAmount(parseInt(e.target.value))
        setAmountErr(false)
    }

    const [transactionPurposeType, setTransactionPurposeType] = useState()
    const [payoutCompetition, setPayoutCompetition] = useState([])

    const onTransactionPurpose = (e) => {
        setTransactionPurposeType(parseInt(e.target.value))
        // let contactCompe={
        //     userId : contactId
        // }
        // axios.post(`https://dev.gamestarz.xyz/v1/client/competition/me`, contactCompe)
        // .then(res=>{
        //     setPayoutCompetition(res.data.data)
        // })
        // setPurposeErr(false)
    }

    const [transactionRecepient, setTransactionRecepient] = useState()
    const onTransactionRecepient = (e) => {
        setTransactionRecepient(parseInt(e.target.value))
        setRecipientError(false)
    }

    const [note, setNote] = useState()

    const onNote = (e) => {
        setNote(e.target.value)
        setNoteErr(false)
    }

    const [contactId , setContactID] = useState()
    const CreatePayout = () => {
        let singlePayoutData = {
            userId : contactId,
            amount : amount,
            recipientId : transactionRecepient,
            purposeId : transactionPurposeType ,
            note : note
        } 
        if(singlePayoutData.amount == undefined || singlePayoutData.recipientId == undefined || singlePayoutData.purposeId == undefined || singlePayoutData.note == undefined){
            validation()
        }else{
            dispatch(addSinglePayoutAction(singlePayoutData, props.history))
        }
    }

    const [recepient, setRecepient] = useState()
    const [transactionPurpose, setTransactionPurpose] = useState()
    const [contacts, setContacts] = useState([])

    useEffect(()=>{
        axios.get('https://dev.gamestarz.xyz/v1/static-values')
        
        .then(res=>{
            setRecepient(res.data.walletTransactionRecipientMasterIds)
            setTransactionPurpose(res.data.walletTransactionPurposeMasterIds)
        })

    }, [contactId])

    // const contactsData = async() => {
    //     let userName = {
    //         username : ""
    //     }

    //     let res = await axios.post(`${BASE_URL}/user/search`, userName)
    //     setContacts(res.data.data)
    // }

    const Countrydata = [{CountryName: 'India', id:1}]
    const[country, setCountry] = useState(Countrydata)

    const defaultProps = {
        options: contacts,
        getOptionLabel: (option) => option.username,
    };

    const validation = () => {
        if(contactId == null || contactId == "" || contactId == undefined){
            setContactError(true)
        }
        if(transactionRecepient == null || transactionRecepient == "" || transactionRecepient == undefined){
            setRecipientError(true)
        }
        if(amount == null || amount == "" || amount == undefined){
            setAmountErr(true)
        }
        if(transactionPurposeType == null || transactionPurposeType == "" || transactionPurposeType == undefined){
            setPurposeErr(true)
        }
        if(note == null || note == "" || note == undefined){
            setNoteErr(true)
        }
    }

    const onConatct = (e, i) => {
        setContactID(i.id);
        let contactCompe={
            userId : i.id
        }
        axios.post(`https://dev.gamestarz.xyz/v1/client/competition/me`, contactCompe)
        .then(res=>{
            setPayoutCompetition(res.data.data)
        })
        setPurposeErr(false)
    }

    const oninput = async(e) => {
        let userName = {
            username : e.target.value
        }

        let res = await axios.post(`${BASE_URL}/user/search`, userName)
        setContacts(res.data.data)
    }


    return (
        <>
            <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                        <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                            <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                            <Breadcrumb.Item href='#payout'>Payout</Breadcrumb.Item>
                            <Breadcrumb.Item className="text-dark" active>Single Payout</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5 gamestarz">
                <div className="container payout">
                    <Row className="my-3">
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <h4>Single Payout</h4>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <label className="mb-2">Transaction Source</label>
                            <input type="text" disabled value="Playstarz Bank" className="form-control input_bg" />
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <label className="mb-2">Search Transaction Contact</label>
                            <Autocomplete disableClearable {...defaultProps} onChange={(e,i)=>{onConatct(e,i)}} onInputChange={(e)=>{oninput(e)}} options={contacts} id="auto-complete" autoComplete includeInputInList
                            renderInput={(params) => (<TextField {...params} variant="standard" />)} />     
                            {/* {contactErr == true ? ( <span style={{color : "red"}}>*Transaction Conatct is Required</span>) : ""} */}
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col lg={6} md={6} sm={12}>
                            <label className="mb-2">Choose Transaction Recipient</label>
                            <select className="form-select input_bg" onChange={(e)=>{onTransactionRecepient(e)}}>
                                <option>Select Transaction Recipient</option>
                                {recepient && recepient.map((e)=>{
                                    return(
                                        <option key={e.id} value={e.id}>{e.name}</option>
                                    )
                                })}
                            </select>
                            {recipientErr == true ? ( <span style={{color : "red"}}>*Transaction Recipient is Required</span>) : ""}
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <label className="mb-2">Set Transaction Amount</label>
                            <input type="text" className="form-control input_bg" onChange={(e)=>{onAmount(e)}}/>
                            {amountErr == true ? ( <span style={{color : "red"}}>*Transaction Amount is Required</span>) : ""}
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col lg={6} md={6} sm={12}>
                            <label className="mb-2">Select Transaction Purpose Type</label>
                            <select className="form-select input_bg" onChange={(e)=>{onTransactionPurpose(e)}}>
                                <option>Select Transaction Purpose Recipient</option>
                                {transactionPurpose && transactionPurpose.map((e)=>{
                                    return(
                                        <option key={e.id} value={e.id}>{e.name}</option>
                                    )
                                })}
                            </select>
                            {purposeErr == true ? ( <span style={{color : "red"}}>*Transaction Purpose is Required</span>) : ""}
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <label className="mb-2">Select Transaction Event</label>
                            {transactionPurposeType == 2 || transactionPurposeType == 3 || transactionPurposeType == 12 ?
                            <select className="form-select input_bg" >
                               {payoutCompetition && payoutCompetition.length>0 ?
                                payoutCompetition && payoutCompetition.map((e)=>{
                                   return(
                                       <option key={e.competition.id}>{e.competition.competitionName}</option>
                                   )
                               })
                            :<option>No Competition Available</option>}
                            </select>
                            :  <input type='text' disabled className='form-control input_bg' value={transactionPurposeType == 4 ? 'leaderboard prize' : transactionPurposeType == 5 ? 'store purchase' : transactionPurposeType == 6 ? 'coupon cashback' : transactionPurposeType == 7 ? 'scratch card bonus' : transactionPurposeType == 8 ? 'sign up bonus' : transactionPurposeType == 9 ? 'referral bonus' : transactionPurposeType == 10 ? 'withdrawal' : transactionPurposeType == 11 ? 'deposit' : transactionPurposeType == 13 ? 'store purchase refund' : transactionPurposeType == 14 ? 'withdrawal refund' : ''} />}    
                        </Col>
                    </Row>
                    <Row className="my-3 d-flex align-items-center">
                        <Col lg={6} md={6} sm={12} className="mt-4">
                            <label className="mb-2 mx-1">Payout Scheduling</label>
                            <input className="form-check-input mx-3" type="radio" value="Immediate" name="schedule" onChange={(e)=>{onSchedule(e)}}/>
                            <label className="form-check-label" for="flexRadioDefault1">Immediate</label>
                            <input className="form-check-input mx-3" type="radio" value="Scheduled" name="schedule" onChange={(e)=>{onSchedule(e)}}/>
                            <label className="form-check-label" for="flexRadioDefault1">Set Date/Time</label>
                        </Col>
                    </Row>
                    {schedule === "Scheduled" ? 
                    <Row className="my-3 d-flex align-items-center">
                        <Col lg={6} md={6} sm={12}>
                            <label className="mb-2">Set Date</label>
                            <input type="date" className="form-control input_bg" min={new Date().toISOString().split('T')[0]}/>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <label className="mb-2">Set Time</label>
                            <input type="time" className="form-control input_bg" />
                        </Col>
                    </Row> : ""}
                    <Row className="my-3">
                        <Col lg={12} md={6} sm={12}>
                            <label className="mb-2">Transaction Notes</label>
                            <textarea className="form-control input_bg" rows="4" onChange={(e)=>{onNote(e)}}></textarea>
                        </Col>
                        {noteErr == true ? ( <span style={{color : "red"}}>*Transaction Note is Required</span>) : ""}
                    </Row>
                    <Row className="my-4">
                        <Col lg={12} md={6} sm={12}>
                            <button className="btn btn-success" onClick={CreatePayout}>Create Payout</button>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default SinglePayout