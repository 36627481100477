import { GET_VERSION, ADD_VERSION, EDIT_VERSION , CLEAR_VERSION, GET_VERSION_BY_ID} from "../actionType/actionTypes";
import VersionServices from "../../services/VersionServices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getAllVersionAction = (body, history) => {
    return async(dispatch) => {
        await VersionServices.getAllVersion(body)
        .then(res=>{
            dispatch(getAllVersion(res.data))
        })
    }
}

export const getAllVesionByIdAction = (body) => {
    return async(dispatch) => {
        await VersionServices.getVersionById(body)
        .then(res=>{
            dispatch(getVersionById(res.data))
        })
    }
}

export const addVersionAction = (body, history) => {
    return async(dispatch) => {
        await VersionServices.addVersion(body)
        .then(res=>{
            toast.success('Version Added Successfully.')
            dispatch(getAllVersionAction())
        })
    }
}

export const editVersionAction = (body, history) => {
    return async(dispatch) => {
        await VersionServices.editVersion(body)
        .then(res=>{
            toast.success('Version Updated Successfully.')
            dispatch(getAllVersionAction())
        })
    }
}

export const clearVersionAction = () => {
    return async (dispatch) => {
        dispatch(clearVersion())
    }
}

export const getAllVersion = (data) => {
    return {
        type : GET_VERSION,
        payload : data
    }
}

export const getVersionById = (data) => {
    return {
        type : GET_VERSION_BY_ID,
        payload : data
    }
}

export const addVersion = (data) => {
    return {
        type : ADD_VERSION,
        payload : data
    }
}

export const editVersion = (data) => {
    return {
        type : EDIT_VERSION,
        payload : data
    }
}

export const clearVersion = (data) => {
    return {
        type : CLEAR_VERSION,
        payload : data
    }
}