import { TOTAL_MONEY_COLLECTED, TOTAL_LIVE_COMPETITIONS, TOTAL_CHALLENGES, TOTAL_PAID_CHALLENGES } from "../actionType/actionTypes";

const initialState = {
    moneyCollected : [],
    liveCompetition : null,
    totalChallenges : null,
    totalPaidChallenges : null
}

export const analyticsReducer = (state = initialState , action) => {

    switch(action.type){

        case TOTAL_MONEY_COLLECTED : {
            return {
                ...state,
                moneyCollected : action.payload
            }
        }

        case TOTAL_LIVE_COMPETITIONS : {
            return {
                ...state,
                liveCompetition : action.payload
            }
        }

        case TOTAL_CHALLENGES : {
            return {
                ...state,
                totalChallenges : action.payload
            }
        }

        case TOTAL_PAID_CHALLENGES : {
            return {
                ...state,
                totalPaidChallenges : action.payload
            }
        }

        default : {
            return {
                ...state
            }
        }

    }

}