export const Routes = {
    // pages
    // Presentation: { path: "/" },
    DashboardOverview: { path: "/overview" },

    /* New Routes */
    Login : {path: "/"},
    Games : {path: "/games"},
    ExternalGames : {path : "/external-games"},
    ViewExternalGame : {path : "/view-externalGames"},
    AddGame : {path: "/add-game"},
    EditGame : {path: "/edit-game"},
    Leaderboard : {path: "/main-leaderboard"},
    CreateCustomLeaderboard : {path: "/custom-leaderboard"},
    PPSALL : {path : "/ppsall"},
    LeaderboardDetail : {path: `/selected-leaderboard`},
    PointDistribution : {path: '/point-distribution'},
    PDReport : {path : '/PDReport'},
    Competition : {path : '/competition'},
    CreateTemplates : {path : '/create-template'},
    EditTemplates : {path : '/edit-template'},
    CopyTemplates : {path : '/copy-template'},
    CreateCompetition : {path : '/create-competition'},
    EditCompetition : {path : '/edit-compettion'},
    CompetitionDetail : {path : '/competition-detail'},
    CompetitionResult : {path : '/competition-result'},
    TransactionsHistory : {path: '/transaction-history'},
    Payout : {path: '/payout'},
    SinglePayout : {path: '/single-payout'},
    BulkPayout : {path: '/bulk-payout'},
    Coupans: {path: '/coupons'},
    CreateCoupan: {path: '/create-coupan'},
    ScratchCard: {path: '/scratch-card'},
    CreateSC : {path: '/create-scratchcard'},
    Referrals : {path: '/referrals'},
    EditCoupon : {path: '/editCoupon'},
    EditScratchCard : {path: '/editScratchCard'},
    Version : {path: '/version'},
    Organisation : {path: '/partners'},
    ViewOrganisation : {path : '/view-partners'},
    Teams : {path : '/teams'},
    CreateTeams : {path : '/create-team'},
    EditTeams : {path : '/edit-team'},
    BusinessLogic : {path : '/business-logic'},
    Rules : {path : '/rules'},
    Attribute : {path : '/attribute'},
    Module : {path : '/module'},
    EditModule : {path : '/edit-module'},
    Decision : {path : '/decision'},
    EditRule : {path : '/edit-rule'},
    Outcome : {path : '/outcome'},
    EditOutcome : {path : '/edit-outcome'},
    editAttribute : {path : '/edit-attribute'},
    document : {path : '/documents'},
    createDocument : {path : '/create-document'},
    editDocument : {path : '/edit-document'},
    gamesInComp : {path : '/competition-games'},
    gamesWithComp : {path : '/game-info'},

    /*End New Routes */

    DashboardTraffic: { path: "/dashboard/traffic-and-engagement" },
    DashboardProductAnalysis: { path: "/dashboard/product-analysis" },
    Messages: { path: "/messages" },
    SingleMessage: { path: "/message" },
    Users: { path: "/users" },
    Transactions: { path: "/transactions" },
    Tasks: { path: "/tasks" },
    Settings: { path: "/settings" },
    Calendar: { path: "/calendar" },
    Map: { path: "/map" },
    Datatables: { path: "/tables/datatables" },
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    Pricing: { path: "/examples/pricing" },
    Billing: { path: "/examples/billing" },
    Invoice: { path: "/examples/invoice" },
    Signin: { path: "/examples/sign-in" },
    Signup: { path: "/examples/sign-up" },
    ForgotPassword: { path: "/examples/forgot-password" },
    ResetPassword: { path: "/examples/reset-password" },
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },

    // docs
    DocsOverview: { path: "/documentation/overview" },
    DocsDownload: { path: "/documentation/download" },
    DocsQuickStart: { path: "/documentation/quick-start" },
    DocsLicense: { path: "/documentation/license" },
    DocsFolderStructure: { path: "/documentation/folder-structure" },
    DocsBuild: { path: "/documentation/build-tools" },
    DocsChangelog: { path: "/documentation/changelog" },

    // plugins
    PluginCharts: { path: "/plugins/charts" },
    PluginCalendar: { path: "/plugins/calendar" },
    PluginDatatable: { path: "/plugins/datatable" },
    PluginMap: { path: "/plugins/map" },
    PluginDropzone: { path: "/plugins/dropzone" },
    PluginSweetAlert: { path: "/plugins/sweet-alert" },

    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" }
};