import axios from "../utlis/interceptor";
import { BASE_URL, GET_DOCUMENTS, CREATE_DOCUMENTS, EDIT_DOCUMENTS} from "../resources/APIEndpoints";

function DocumentServices() {
    this.getAllDocuments = (body) => axios.post(BASE_URL + GET_DOCUMENTS, body)
    this.getDocumentDetail = (body) => axios.post(BASE_URL + GET_DOCUMENTS, body)
    this.createDocuments = (body) => axios.post(BASE_URL + CREATE_DOCUMENTS, body)
    this.editDocuments = (body) => axios.post(BASE_URL + EDIT_DOCUMENTS, body)
}

export default new DocumentServices()