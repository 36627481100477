import axios from "../utlis/interceptor";
import { BASE_URL, GET_TEAM, GET_TEAM_BY_ID, CREATE_TEAM, EDIT_TEAM} from "../resources/APIEndpoints";

function teamServices() {
    this.getAllTeams = (body) => axios.post(BASE_URL + GET_TEAM, body)
    this.getTeamsById = (body) => axios.post(BASE_URL + GET_TEAM_BY_ID, body)
    this.createTeam = (body) => axios.post(BASE_URL + CREATE_TEAM, body)
    this.editTeam = (body) => axios.post(BASE_URL + EDIT_TEAM, body)
}

export default new teamServices()