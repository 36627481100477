import { GET_ALL_CONDITIONTYPE, GET_ALL_ATTRIBUTE, GET_CONDITION_DETAIL, GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE, GET_RULE, CREATE_RULE, CREATE_CONDITIONTYPE, CREATE_ATTRIBUTE, GET_RULE_DETAIL, EDIT_RULE, SEARCH_ATTRIBUTE, SEARCH_MODULE } from "../actionType/actionTypes";

const initialState = {
    modules : [],
    attributes : [],
    moduleDetails : [],
    attributeDetails : [],
    rules : [],
    rulesDetail : []
}

export const businessLogicReducer = (state = initialState , action) => {

    switch(action.type){

        case GET_ALL_CONDITIONTYPE : {
            return {
                ...state,
                modules : action.payload
            }
        }

        case GET_CONDITION_DETAIL : {
            return {
                ...state,
                moduleDetails : action.payload
            }
        }

        case CREATE_CONDITIONTYPE : {
            return {
                ...state,
                modules : action.payload
            }
        }

        case GET_ALL_ATTRIBUTE : {
            return {
                ...state,
                attributes : action.payload
            }
        }

        case CREATE_ATTRIBUTE : {
            return {
                ...state,
                attributes : action.payload
            }
        }

        case GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE : {
            return {
                ...state,
                attributeDetails : action.payload
            }
        }

        case GET_RULE : {
            return {
                ...state,
                rules : action.payload
            }
        }

        case CREATE_RULE : {
            return {
                ...state,
                rules : action.payload
            }
        }

        case EDIT_RULE : {
            return {
                ...state,
                rules : action.payload
            }
        }

        case GET_RULE_DETAIL : {
            return {
                ...state,
                rulesDetail : action.payload
            }
        }

        case SEARCH_MODULE : {
            return {
                ...state,
                modules : action.payload
            }
        }

        case SEARCH_ATTRIBUTE : {
            return {
                ...state,
                attributes : action.payload
            }
        }

        default : {
            return {
                ...state
            }
        }

    }

}
