import React, {useState, useEffect} from 'react'
// import { Form, Row, Col, Button, ButtonGroup, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TransactionFilter from '../modals/Filters/TransactionFilter';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faSearch, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col, InputGroup, Row, Nav, Form, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import BulkPayoutDetail from '../modals/PlaystazBankModels/BulkPayoutDetail';
import AllPayoutDetail from '../modals/PlaystazBankModels/AllPayoutDetail';
import { Breadcrumb } from "react-bootstrap";
import { getSinglePayoutAction } from '../../redux/action/playstarzbankAction';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination'
import axios from 'axios';
import { BASE_URL, GET_SINGLE_PAYOUT } from '../../resources/APIEndpoints';
import moment from 'moment';

function Payout() {

    const dispatch = useDispatch()

    const [showTransactionFilter, setTransactionFilter] = useState(false)
    
    const showModal = () => {
        setTransactionFilter(!showTransactionFilter)
    }

    const [showBulkPayout, setShowBulkPayout] = useState(false)
    
    const showBulkPayoutModal = () => {
        setShowBulkPayout(!showBulkPayout)
    }

    const [showAllPayout, setShowAllPayoutModal] = useState(false)

    const showAllPayoutModal = () => {
        setShowAllPayoutModal(!showAllPayout)
    }

    const [page, setPage] = useState(1)
    const [counts , setCounts] = useState()
    const [limit, setLimit] = useState(10)


    const getSinglePayouts = () => {
        let singlePayoutData = {
            walletTransactionsFilters:{
               pagination: {
                    offset : ((page-1)*limit),
                    limit :  limit
                }   
            }
        }

         dispatch(getSinglePayoutAction(singlePayoutData))
    }

    const getSinglePayoutPagination = () => {
        let singlePayoutPagination = {
            walletTransactionsFilters:{
               pagination: {
                    offset : ((page-1)*limit),
                    limit :  limit
                }   
            }
        }
        axios.post(BASE_URL + GET_SINGLE_PAYOUT, singlePayoutPagination)
        .then(res=>{
            if(res.data.transactionData.length > 0){
                setCounts(Math.ceil(res.data.transactionData[0].totalcount/limit))
            }
        })
    }

    useEffect(() => {
        getSinglePayouts();
        getSinglePayoutPagination();
    }, [page])
    

    let singlePayout = useSelector((state)=>{
        return state.playstarzbank.singlePayout
    })

    return (
        <>
        <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
            <Row>
                <Col lg={12}>
                    <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                        <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-dark" active>Payouts</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
  
        <div className="d-lg-flex justify-content-end flex-wrap flex-md-nowrap align-items-center py-4">
            <Col xs={12} sm={12} md={12} lg={6}>
                <Row className="px-3">
                    <Button as={Link} to={Routes.SinglePayout.path} className="button-style me-3" style={{width: "200px", display: "Inline !important"}}>
                    <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                    <span>Single Payout</span>
                    </Button>

                    {/* <Button as={Link} to={Routes.BulkPayout.path} variant="secondary" className="text-dark me-3" style={{width: "200px", display: "Inline !important"}}>
                    <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                    <span>Bulk Payout</span>
                    </Button> */}
                </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} className="px-0 mb-0 text-right">
                <ButtonGroup>
                    <Button variant="outline-primary" className="export" size="sm">Export</Button>
                </ButtonGroup>
            </Col>
        </div>
        <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded gamestarz">
            <div className="row mb-4 px-4">
                <div className="col col-lg-6 col-md-12 text-left">
                    <h4>Payouts</h4>
                </div>
                <div className="col col-lg-6 col-md-12 col-sm-12 text-right">
                    <span className='filter' style={{cursor: "pointer"}} onClick={()=>{showModal()}}><i className="fas fa-filter mx-2"></i><span>Filter</span></span>
                </div>
            </div>

            <Row className="d-flex px-4">
                {/* <Col lg={4} md={4} sm={12} xs={12}></Col> */}
                <Col lg={4} md={4} sm={12} xs={12}>
                    <InputGroup>
                        <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control type="text" placeholder="Search Transaction Contacts, Event and Batch Name"/>
                    </InputGroup>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12} className="mb-sm-4 pb-sm-4">
                    {/* <label>Sort By</label> */}
                    <select className="form-control">
                        <option>Sort By</option>
                        <option>Newest to Oldest</option>
                        <option>Oldest to Newest</option>
                    </select>
                </Col>
            </Row>

            <div className="container-fluid payout">
            <div className="my-5">
                <Row className='table-responsive'>
                    <Col style={{overflowX : "scroll"}} lg={12} md={12} sm={12}>
                        <table className="table table-striped text-center table-custom">
                            <thead className='table-head'>
                                <tr>
                                    <th className='head-border-left' scope="col">Transaction Date</th>
                                    <th scope="col">Transaction Time</th>
                                    <th scope="col">Transaction Source</th>
                                    <th scope="col">Transaction Contact</th>
                                    <th scope="col">Transaction Recipient</th>
                                    <th scope="col">Transaction Amount</th>
                                    <th scope="col">Transaction Purpose</th>
                                    <th scope="col">Transaction Event</th>
                                    <th scope="col">Transaction Status</th>
                                    <th className='head-border-right' scope="col">Transaction Mode for Debits</th>
                                </tr>
                            </thead>
                            <tbody className='table-body'>
                                {singlePayout && singlePayout.map((e)=>{
                                    return(
                                    <tr>
                                        <td>{e.createdAt.slice(0,10).split("-").reverse().join("-")}</td>
                                        <td>{moment(e.updatedAt).tz('Asia/Kolkata').format().slice(11,19)}</td>
                                        {/* <td>{new Date(e.createdAt).toLocaleString()}</td> */}
                                        <td>{e.sourceWalletName != "" ? e.sourceWalletName : '-'}</td>
                                        <td>{e.recipientTransactionContact == 'playstarz' ? e.sourceTransactionContact : e.recipientTransactionContact}</td>
                                        <td>{e.walletTransactionRecepientName != "" ? e.walletTransactionRecepientName : "-"}</td>
                                        <td>{e.amount != null ? <span>&#8377; {parseFloat(e.amount).toFixed(2)}</span> : "-"}</td>
                                        <td>{e.walletTransactionPurposeName != "" ? e.walletTransactionPurposeName : "-"}</td>
                                        <td>{e.transactionEvent && e.transactionEvent != null && e.transactionEvent != '' ? e.transactionEvent : '-'}</td>
                                        <td>{e.status != "" ? <span className={e.status == 'processed' ? 'success' : e.status == 'processing' ? 'danger' : ''}>{e.status}</span> : "-"}</td>
                                        <td>{e.walletTransactionPurposeName != "" ? e.walletTransactionPurposeName : "-"}</td>
                                    </tr>
                                )})}    
                            </tbody>
                        </table>
                    </Col>
                </Row>

                {/* <Row className="mt-5">
                    <Col>
                        <Row className="my-2">
                            <Col lg={12} md={12} sm={12}>
                                <h5>Bulk Payout</h5>
                            </Col>
                        </Row>
                        <table className="table table-striped text-center">
                            <thead>
                                <tr>
                                    <th scope="col">Created At</th>
                                    <th scope="col">Batch Name</th>
                                    <th scope="col">Transaction Status</th>
                                    <th scope="col">Payout Count</th>
                                    <th scope="col">Payout Total Amount</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>21/11/20121</td>
                                    <td><a href="#" target="_blank" style={{textDecoration: "underline"}}>BulkPayout</a></td>
                                    <td>Scheduled</td>
                                    <td>11</td>
                                    <td>34000</td>
                                    <td>
                                        <Dropdown className="ms-3">
                                            <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="icon icon-sm icon-dark">
                                                <FontAwesomeIcon icon={faEllipsisH} />
                                            </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu-right">
                                            <Dropdown.Item className="ms-0" onClick={()=>{showBulkPayoutModal()}}>
                                                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                                            </Dropdown.Item>
                                            <Dropdown.Item as={Link} to={{pathname:Routes.BulkPayout.path}} className="ms-0">
                                                <FontAwesomeIcon icon={faEdit} className="me-2"/> Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item as={Link} className="text-danger ms-0">
                                            <FontAwesomeIcon icon={faArchive} className="me-2"/> Cancel
                                            </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                                <tr>
                                    <td>01/11/20121</td>
                                    <td><a href="#" target="_blank" style={{textDecoration: "underline"}}>BulkPayout_One</a></td>
                                    <td>Scheduled</td>
                                    <td>21</td>
                                    <td>60000</td>
                                    <td>
                                        <Dropdown className="ms-3">
                                            <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="icon icon-sm icon-dark">
                                                <FontAwesomeIcon icon={faEllipsisH} />
                                            </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu-right">
                                            <Dropdown.Item className="ms-0" onClick={()=>{showBulkPayoutModal()}}>
                                                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                                            </Dropdown.Item>
                                            <Dropdown.Item as={Link} to={{pathname:Routes.BulkPayout.path}} className="ms-0">
                                                <FontAwesomeIcon icon={faEdit} className="me-2"/> Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item as={Link} className="text-danger ms-0">
                                            <FontAwesomeIcon icon={faArchive} className="me-2"/> Cancel
                                            </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                                <tr>
                                    <td>21/01/20121</td>
                                    <td><a href="#" target="_blank" style={{textDecoration: "underline"}}>BulkPayout</a></td>
                                    <td>Scheduled</td>
                                    <td>100</td>
                                    <td>300000</td>
                                    <td>
                                        <Dropdown className="ms-3">
                                            <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="icon icon-sm icon-dark">
                                                <FontAwesomeIcon icon={faEllipsisH} />
                                            </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu-right">
                                            <Dropdown.Item className="ms-0" onClick={()=>{showBulkPayoutModal()}}>
                                                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                                            </Dropdown.Item>
                                            <Dropdown.Item as={Link} to={{pathname:Routes.BulkPayout.path}} className="ms-0">
                                                <FontAwesomeIcon icon={faEdit} className="me-2"/> Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item as={Link} className="text-danger ms-0">
                                            <FontAwesomeIcon icon={faArchive} className="me-2"/> Cancel
                                            </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row> */}

                <div className="d-flex justify-content-left my-4">
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <Pagination count={counts} defaultPage={page} color="info" className='paginate'
                            variant="outlined" shape="rounded" showFirstButton={false} showLastButton={false}  
                            onChange={(event, value)=> setPage(value)} />
                        </Col>
                    </Row>
                </div>

            </div> 
        </div>
    </div>
    <TransactionFilter show={showTransactionFilter} onHide={showModal} />
    <BulkPayoutDetail show={showBulkPayout} onHide={showBulkPayoutModal} />
    <AllPayoutDetail show={showAllPayout} onHide={showAllPayoutModal}/>
    </>
    )
}

export default Payout