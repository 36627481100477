import axios from '../utlis/interceptor'
import {BASE_URL, CREATE_GAME, GET_GAME, EDIT_GAME, MATCH_BY_GAME_ID, SORT, SEARCH_GAMES, DELETE_PLATFORM, EXTERNAL_GAMES_BASE_URL, EXTERNAL_GET_GAME, EXTERNAL_EDIT_GAME, EXTERNAL_SEARCH_GAMES} from '../resources/APIEndpoints'


function GameServices() {
    
    this.getallGame = (body) => axios.post(BASE_URL + GET_GAME, body)
    this.createGame = (body) => axios.post(BASE_URL + CREATE_GAME, body)
    this.getGameById = (body) => axios.post(BASE_URL + GET_GAME, body)
    this.EditGame = (body) => axios.post(BASE_URL + EDIT_GAME, body)
    this.CreateMatch = (body) => axios.post(BASE_URL +  MATCH_BY_GAME_ID, body)
    this.sortGame = (body) => axios.post(BASE_URL + SORT, body)
    this.searchGame = (body) => axios.post(BASE_URL + SEARCH_GAMES, body)
    this.deletePlatform = (body) => axios.post(BASE_URL + DELETE_PLATFORM, body)

    this.getExternalGames = (body) => axios.post(BASE_URL + EXTERNAL_GET_GAME, body)
    this.editExternalGames = (body) => axios.post(BASE_URL + EXTERNAL_EDIT_GAME, body)
    this.searchExternalGames = (body) => axios.post(BASE_URL + EXTERNAL_SEARCH_GAMES, body)
    this.getExternalGamesById = (body) => axios.post(BASE_URL + EXTERNAL_GET_GAME, body)

}

export default new GameServices();