import {EDIT_EXTERNAL_GAMES,GET_EXTERNAL_GAMES_BY_ID,SEARCH_EXTERNAL_GAMES, CREATE_GAME, ALL_GAMES, GAME_BY_ID, EDIT_GAME, MATCH_BY_GAME_ID, SORT, SEARCH_GAMES, DELETE_PLATFORM, CLEAR_GAME_DETAIL, GET_EXTERNAL_GAMES} from '../actionType/actionTypes'

const initialState = {
    games : [],
    gameDetail: [],
    externalGames: [],
    externalGameDetail : [],
    sort: [],
    isLoading : true
}

export const gameReducer = (state = initialState, action) => {

    switch(action.type){

        case ALL_GAMES : {
            return {
               ...state,
               games: action.payload,
               isLoading : false
            }
        }

        case GET_EXTERNAL_GAMES : {
            return {
                ...state,
                externalGames: action.payload
            }
        }

        case CREATE_GAME : {
            return {
                ...state,
                games: action.payload,
            }
        }

        case GAME_BY_ID  : {
            return {
                ...state,
                gameDetail: action.payload
            }
        }

        case GET_EXTERNAL_GAMES_BY_ID : {
            return {
                ...state,
                externalGameDetail: action.payload
            }
        }

        case EDIT_GAME : {
            return {
                ...state,
                games:action.payload
            }
        }

        case EDIT_EXTERNAL_GAMES : {
            return {
                ...state,
                externalGames : action.payload
            }
        }

        case MATCH_BY_GAME_ID : {
            return {
                ...state,
                games:action.payload
            }
        }

        case SORT : {
            return {
                ...state,
                games:[],
                isLoading : true
            }
        }

        case CLEAR_GAME_DETAIL : {
            return {
                ...state,
                gameDetail: []
            }
        }

        case SEARCH_GAMES : {
            return {
                ...state,
                games:action.payload
            }
        }

        case SEARCH_EXTERNAL_GAMES : {
            return {
                ...state,
                externalGames: action.payload
            }
        }

        case DELETE_PLATFORM : {
            return {
                ...state,
                games: action.payload
            }
        }

        default : {
            return {
                ...state
            }
        }

    }

}
