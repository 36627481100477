import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faSearch, faPlusCircle, faTrash, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col,InputGroup, Row, Nav, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import { Breadcrumb } from "react-bootstrap";
import { Link , useHistory} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import customAxios from '../../../utlis/interceptor';
import { BASE_URL, GET_ALL_CONDITIONTYPE } from '../../../resources/APIEndpoints';
import axios from 'axios';
import { createAttributeAction } from '../../../redux/action/businessLogicAction';
import { Routes } from '../../../routes';

export default function Attribute(props){

    const dispatch = useDispatch();
    const history = useHistory()

    const [attributeName, setAttributeName] = useState("")

    const onAttributeName = (e) => {
        setAttributeName(e.target.value)
    }

    const [allDataType, setAllDataType] = useState()
    const [dataType, setDataType] = useState()

    const onDataType = (e) => {
        setDataType(parseInt(e.target.value))
    }

    const getDataType = async() => {
        let res = await axios.get('https://dev.gamestarz.xyz/v1/static-values')
        setAllDataType(res.data.dataTypeIds)
    }

    const [allModule, setAllModule] = useState("")
    const [moduleType, setModuleType] = useState()

    const getAllModule = async() => {

        let allModule = {
            conditionTypeFilters : {
                pagination : {
                    offset : 0,
                    limit : 1000
                }
            }
        }

        let res = await customAxios.post(`${BASE_URL}` + `${GET_ALL_CONDITIONTYPE}`, allModule)
        setAllModule(res.data.conditionDetails)
    }

    const onModuleType = (e) => {
        setModuleType(parseInt(e.target.value))
    }

    useEffect(()=> {
        getDataType();
        getAllModule();
    }, [])

    const saveAttribute= () => {
        let createAttribute = {
            attributeName : attributeName,
            dataTypeId : dataType
        }
        dispatch(createAttributeAction(createAttribute, props.history))
    }

    const cancel = () => {
        history.push(Routes.BusinessLogic.path)
    }

    return (
    <>
            <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                        <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                            <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                            <Breadcrumb.Item href="#business-logic">Business Logic</Breadcrumb.Item>
                            <Breadcrumb.Item className="text-dark" active>Attribute</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
    
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded gamestarz">

                <div className="container-fluid payout">
                    <div className="my-3">
                        <div className='container'>
                            <Row className='my-3'>
                              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                  <h4>Create Attribute</h4>
                              </Col>
                            </Row>
                            <Row className='my-3'>
                                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                    <label>Attribute Name</label>
                                    <input type='text' className='form-control' onChange={(e)=>{onAttributeName(e)}}/>
                                </Col>
                                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                    <label>Data Type</label>
                                    <select className='form-select' onChange={(e)=>{onDataType(e)}}>
                                        <option>Select Data Type</option>
                                        {allDataType && allDataType.map((e,i)=>{
                                            return(
                                                <option key={i} value={e.id}>{e.dataTypeName}</option>
                                            )
                                        })}
                                    </select>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                    <label>Condition Type</label>
                                    <select className='form-select' onChange={(e)=>{onModuleType(e)}}>
                                        <option>Select Module</option>
                                        {allModule && allModule.map((e,i)=>{
                                            return(
                                                <option key={i} value={e.id}>{e.conditionName}</option>
                                            )
                                        })}
                                    </select>
                                </Col>
                            </Row> */}

                            <Row className='my-3'>
                                <Col>
                                    <button className='btn btn-success' onClick={saveAttribute}>Save</button>
                                    <button className='btn btn-danger mx-3' onClick={cancel}>Cancel</button>
                                </Col>
                            </Row>
                        </div>
                    </div> 
                </div>
            </div>
        </>
  )
}
