import React, {useState} from 'react'
import { Modal, Row, Col } from "react-bootstrap"
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


function TemplateFilter({show, onHide, filtersTemplate}) {

    const [showCompFormat, setShowCompFormat] = useState(false)
    const [competitionFormat, setCompetitionFormat] = useState([])
    const [tournamentLeaderboard, setTournamentLeaderboard] = useState(false)
    const [instantBattle, setInstantBattle] = useState(false)

    const onCompFormat = (e) => {
        let competitionList = [...competitionFormat]
        if(e.target.checked){
            if(e.target.value == 2) {
                setCompetitionFormat([...competitionFormat, e.target.value])
                setTournamentLeaderboard(!tournamentLeaderboard)
            }else if(e.target.value == 3){
                setCompetitionFormat([...competitionFormat, e.target.value])
                setInstantBattle(!instantBattle)
            }
        }else{
            if(e.target.value == 2) {
                setTournamentLeaderboard(!tournamentLeaderboard)
            }else if(e.target.value == 3) {
                setInstantBattle(!instantBattle)
            }
            competitionList.splice(competitionFormat.indexOf(e.target.value), 1)
            setCompetitionFormat(competitionList)
        }
    }

    const [showPDMode, setShowPDMode] = useState(false)
    const [pdMode, setPDMode] = useState([])
    const [gurantee, setGurantee] = useState(false)
    const [dynamic, setDynamic] = useState(false)

    const onPDMode = (e) => {
        let pdModeList = [...pdMode]
        if(e.target.checked){
            if(e.target.value == "guaranteed") {
                setPDMode([...pdMode, e.target.value])
                setGurantee(!gurantee)
            }else if(e.target.value == "dynamic"){
                setPDMode([...pdMode, e.target.value])
                setDynamic(!dynamic)
            }
        }else{
            if(e.target.value == 'guaranteed') {
                setGurantee(!gurantee)
            }else if(e.target.value == 'dynamic') {
                setDynamic(!dynamic)
            }
            pdModeList.splice(competitionFormat.indexOf(e.target.value), 1)
            setPDMode(pdModeList)
        }
    }

    const filterTemplate = () => {
        filtersTemplate(competitionFormat, pdMode)
        onHide()
    }

    const close = () => {
        filtersTemplate([], [])
        onHide();
    }

    return (
        <div>
            <Modal centered show={show} onHide={onHide} size="xl" aria-labelledby="contained-modal-title-vcenter" className='filter-main'>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <p className='heading-title'>Add Filter</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='my-2'>
                        <Col>
                            <label>Competition Format</label>
                            <div  className='my-2'>
                                <Row>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <FormControlLabel control={<Checkbox checked={tournamentLeaderboard} onChange={(e)=>{onCompFormat(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={2} />} label={<span style={{ fontSize: '14px' }}>Tournament (Leaderboard)</span>}/>
                                    </Col>

                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <FormControlLabel control={<Checkbox checked={instantBattle} onChange={(e)=>{onCompFormat(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={3} />} label={<span style={{ fontSize: '14px' }}>Instant Battle</span>}/>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Row  className='my-2'>
                        <Col>
                            <label>Prize Distribution Mode</label>
                            <div className='my-2'>
                                <Row>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <FormControlLabel control={<Checkbox checked={gurantee} onChange={(e)=>{onPDMode(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value="guaranteed" />} label={<span style={{ fontSize: '14px' }}>Guaranteed</span>}/>
                                    </Col>

                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <FormControlLabel control={<Checkbox checked={dynamic} onChange={(e)=>{onPDMode(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value="dynamic" />} label={<span style={{ fontSize: '14px' }}>Dynamic</span>}/>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='filter-footer'>
                    <Row>
                        <Col className="text-right mt-2">
                            <button className="btn btn-danger mx-2" onClick={filterTemplate}>Apply Filter</button>
                            <button className="btn btn-success" onClick={close}>Cancel</button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default TemplateFilter