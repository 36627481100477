import GameServices from "../../services/GameServices";
import {GET_EXTERNAL_GAMES_BY_ID, CREATE_GAME,ALL_GAMES,GAME_BY_ID,EDIT_GAME,MATCH_BY_GAME_ID, SORT, SEARCH_GAMES, DELETE_PLATFORM,CLEAR_GAME_DETAIL, GET_EXTERNAL_GAMES, SEARCH_EXTERNAL_GAMES, EDIT_EXTERNAL_GAMES} from "../actionType/actionTypes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from "../../resources/APIEndpoints";

export const getallGamesAction = (body) => {
    return async (dispatch) => {
        await GameServices.getallGame(body)
        .then(res=>{
            dispatch(getallGame(res.data.gameData))
        })
    }
}

export const getExternalGamesAction = (body) => {
    return async (dispatch) => {
        await GameServices.getExternalGames(body)
        .then(res=>{
            dispatch(getExternalGames(res.data.gameData))
        })
    }
}

export const createGameAction = (body, history) => { 
    return async (dispatch) => {
        await GameServices.createGame(body)
        .then(res=>{
            toast.success(res.data.message);
            localStorage.removeItem('books')
            setTimeout(()=>{
                history.push('/games');
            }, 2000)
        }).catch((err)=>{ 

        })
    }
}

export const GameByIdAction = (body) => {
    return async (dispatch) => {
        await GameServices.getGameById(body)
        .then(res=>{
            dispatch(getGameById(res.data.gameData))
        })
    }
}

export const ExternalGameByIdAction = (body) => {
    return async (dispatch) => {
        await GameServices.getExternalGamesById(body)
        .then(res=>{
            dispatch(getExternalGamesById(res.data.gameData))
        })
    }
}

export const EditGameAction = (body, history) => {
    return async (dispatch) => {
        await GameServices.EditGame(body)
        .then(res=>{
            toast.success("Game Updated Successfully");
            localStorage.removeItem('books')
            setTimeout(()=>{
                history.push('/games');
                window.location.reload()
            }, 2000)
        }).catch((err)=>{ 
            
        })
    }
}

export const EditExternalGameAction = (body, history) => {
    return async (dispatch) => {
        await GameServices.editExternalGames(body)
        .then(res=>{
            toast.success(res.data.message)
            setTimeout(()=>{
                history.push('/external-games')
                window.location.reload();
            }, 1500)
        }).catch(err=>{

        })
    }
}

export const CreateMatchAction = (body, id) => {
    return async (dispatch) => {
        await GameServices.CreateMatch(body)
        .then(res=>{
            toast.success(res.data.message);
        }).catch(err=>{
            toast.error(err.response.data.message)
        })
    }
}

export const searchGamesAction = (body) => {
    return async (dispatch) => {
        await GameServices.searchGame(body)
        .then(res=>{
            dispatch(searchGame(res.data.gameData))
        })
    }
}

export const searchExternalGamesAction = (body) => {
    return async (dispatch) => {
        await GameServices.searchExternalGames(body)
        .then(res=>{
            dispatch(searchExternalGames(res.data.gameData))
        })
    }
}

export const deletePlatformAction = (body) => {
    return async (dispatch) => {
        await GameServices.deletePlatform(body)
        .then(res=>{
            toast.success(res.data.message)
        })
    }
}

export const cleargameAction = (body) => {
    return async (dispatch) => {
        dispatch(cleargame())
    }
}

export const cleargamedetailsAction = (body) => {
    return async(dispatch) => {
        dispatch(cleargamedetails())
    }
}

export const getallGame = (data) => {
    return{
        type : ALL_GAMES,
        payload : data
    }
}

export const getExternalGames = (data) => {
    return{
        type : GET_EXTERNAL_GAMES,
        payload : data
    }
}

export const createGame = (data)=> {
    return{
        type: CREATE_GAME,
        payload: data
    }
}

export const getGameById = (data) => {
    return{
        type: GAME_BY_ID ,
        payload: data
    }
}

export const getExternalGamesById = (data) => {
    return{
        type: GET_EXTERNAL_GAMES_BY_ID,
        payload: data
    }
}

export const EditGame = (data) => {
    return{
        type: EDIT_GAME ,
        payload: data
    }
}

export const editExternalGames = (data) => {
    return{
        type: EDIT_EXTERNAL_GAMES,
        payload: data
    }
}

export const CreateMatch = (data) => {
    return{
        type: MATCH_BY_GAME_ID,
        payload: data,
    }
}

export const cleargame = (data) => {
    return{
        type: SORT,
        payload: data,
    }
}

export const cleargamedetails = (data) => {
    return{
        type : CLEAR_GAME_DETAIL,
        payload: data
    }
}

export const searchGame = (data) => {
    return{
        type: SEARCH_GAMES,
        payload: data,
    }
}

export const searchExternalGames = (data) => {
    return{
        type: SEARCH_EXTERNAL_GAMES,
        payload: data,
    }
}

export const deletePlatform = (data) => {
    return{
        type: DELETE_PLATFORM,
        payload : data
    }
}