import React, {useState, useEffect} from 'react'
import {Row, Col, ButtonGroup, Button, InputGroup, Form, Nav} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretLeft, faCaretRight, faRetweet, faSearch} from '@fortawesome/free-solid-svg-icons';
import FilterModal from './modals/Filters/FilterLeaderboard';
import { useDispatch, useSelector } from 'react-redux';
import { getCompetitionDetailAction } from '../redux/action/competitionAction';
import Pagination from '@mui/material/Pagination'
import axios from '../utlis/interceptor';
import { BASE_URL } from '../resources/APIEndpoints';
import { Breadcrumb } from "react-bootstrap";
import refreshIcon from './Images/refresh.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bracket, RoundProps } from 'react-brackets';
import Brackets from '../components/Bracket';

function CompetitionDetail(props) {

    const [page, setPage] = useState(1)
    const [counts , setCounts] = useState()
    const [limit, setLimit] = useState(10)
    const dispatch = useDispatch()

    const [competitionFormat, setCompetitionFormat] = useState()
    
    useEffect(() => {
        
        getCompetitionDetail();
        getPageCount();
        competitionSlotDetail();

        setCompetitionFormat(props.location.compId)

    }, [page, initialSlot])

    const getCompetitionDetail = () => {
        if(props.location.competitionSlots != undefined && props.location.competitionSlots.length > 0){
            let compDetail = {
                leaderboardId : props.location.id,
                slotId : props.location.competitionSlots[0].id,
                pagination:{
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
            dispatch(getCompetitionDetailAction(compDetail))
        }else{
            let compDetail = {
                leaderboardId : props.location.id,
                pagination:{
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
            dispatch(getCompetitionDetailAction(compDetail))
        }
    }

    const getPageCount = async() => {
        if(props.location.competitionSlots != undefined && props.location.competitionSlots.length > 0){
            let competitionPagination = {
                leaderboardId : props.location.id,
                slotId : props.location.competitionSlots[0].id,
                pagination:{

                }
            }
            
            await axios.post(`${BASE_URL}/leaderboard/getCompetitionLeaderboardDetails`, competitionPagination)
            .then(res=>{      
                let pageNo = Math.ceil(res.data.competitionLeaderboardData.length/limit)
                setCounts(pageNo);
            })
            
        }else{
            let competitionPagination = {
                leaderboardId : props.location.id,
                pagination:{
    
                }
            }
    
            await axios.post(`${BASE_URL}/leaderboard/getCompetitionLeaderboardDetails`, competitionPagination)
            .then(res=>{      
                let pageNo = Math.ceil(res.data.competitionLeaderboardData.length/limit)
                setCounts(pageNo);
            })
        }

    }

    const [compSlots, setCompSlot] = useState([])
    const [initialSlot, setInitialSlot] = useState(0)

    const competitionSlotDetail = () => {
        let slotsCompetition = []
        props.location.competitionSlots && props.location.competitionSlots.forEach((slots) => {
            slotsCompetition.push(slots.id)
        });
        setCompSlot(slotsCompetition)
    }


    const previousCompetitionSlot = () => {
        setInitialSlot(initialSlot-1)

        let previousCompetitionDetail = {
            leaderboardId : props.location.id,
            slotId :compSlots[initialSlot-1],
            pagination:{
                offset : ((page-1)*limit),
                limit : limit
            }
        }
        dispatch(getCompetitionDetailAction(previousCompetitionDetail))
    }

    const nextCompetitionSlot = () => {
        setInitialSlot(initialSlot+1)
        let nextCompetitionDetail = {
            leaderboardId : props.location.id,
            slotId : compSlots[initialSlot+1],
            pagination:{
                offset : ((page-1)*limit),
                limit : limit
            }
        }
        dispatch(getCompetitionDetailAction(nextCompetitionDetail))
    }

    const onRefresh = () => {
        const compDetail = {
            leaderboardId : props.location.id,
            slotId : compSlots[initialSlot],
            pagination:{
                offset : ((page-1)*limit),
                limit : limit
            }
        }
        dispatch(getCompetitionDetailAction(compDetail))
    }

    let competition = useSelector((state)=>{ 
        return state.competition.competitionDetail
    })

    const onPage = (e) => {
        if(e.target.value > (compSlots.length-1)){
            toast.warning(`There are only ${compSlots.length} slots`)
        }else if(e.target.value < 0){
            toast.warning(`Slots must be positive`)
        }else if(e.target.value == null || e.target.value == undefined || e.target.value == ''){
            setInitialSlot(0)
            let nextCompetitionDetail = {
                leaderboardId : props.location.id,
                slotId : compSlots[0],
                pagination:{
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
            dispatch(getCompetitionDetailAction(nextCompetitionDetail))
        }else if((e.target.value <= (compSlots.length-1)) && (e.target.value >= 0 )){
            setInitialSlot(parseInt(e.target.value))
            let nextCompetitionDetail = {
                leaderboardId : props.location.id,
                slotId : compSlots[parseInt(e.target.value-1)],
                pagination:{
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
            dispatch(getCompetitionDetailAction(nextCompetitionDetail))
        }
    }

    const [firstRound, setFirstRound] = useState(true)
    const [secondRound, setSecondRound] = useState(false)
    const [finalRound, setFinalRound] = useState(false)

    const [round, setRound] = useState([
        {
          title: 'Round one',
          seeds: [
            {
              id: 1,
              date: new Date().toDateString(),
              teams: [{ name: 'Team 1' }, { name: 'Team 2' }],
            },
            {
              id: 2,
              date: new Date().toDateString(),
              teams: [{ name: 'Team 3' }, { name: 'Team 4' }],
            },
            {
                id: 3,
                date: new Date().toDateString(),
                teams: [{ name: 'Team 5' }, { name: 'Team 6' }],
            },
            {
                id: 4,
                date: new Date().toDateString(),
                teams: [{ name: 'Team 7' }, { name: 'Team 8' }],
            }
          ],
        }
    ])

    const showFirstRound = () => {
        setFirstRound(true)
        setSecondRound(false)
        setFinalRound(false)

        let rounds = [
            {
              title: 'Round one',
              seeds: [
                {
                  id: 1,
                  date: new Date().toDateString(),
                  teams: [{ name: 'Team 1' }, { name: 'Team 2' }],
                },
                {
                  id: 2,
                  date: new Date().toDateString(),
                  teams: [{ name: 'Team 3' }, { name: 'Team 4' }],
                },
                {
                    id: 3,
                    date: new Date().toDateString(),
                    teams: [{ name: 'Team 5' }, { name: 'Team 6' }],
                },
                {
                    id: 4,
                    date: new Date().toDateString(),
                    teams: [{ name: 'Team 7' }, { name: 'Team 8' }],
                }
              ],
            }
        ];

        setRound(rounds)
    }

    const showSecondRound = () => {
        setFirstRound(false)
        setSecondRound(true)
        setFinalRound(false)

        let rounds = [
            {
                title: 'Round Two',
                seeds: [
                  {
                    id: 1,
                    date: new Date().toDateString(),
                    teams: [{ name: 'Team 1' }, { name: 'Team 4' }],
                  },
                  {
                    id: 2,
                    date: new Date().toDateString(),
                    teams: [{ name: 'Team 6' }, { name: 'Team 7' }],
                  }
                ],
            }
        ];

        setRound(rounds)
    }

    const showFinalRound = () => {
        setFirstRound(false)
        setSecondRound(false)
        setFinalRound(true)

        let rounds = [
            {
                title: 'Final',
                seeds: [
                  {
                    id: 1,
                    date: new Date().toDateString(),
                    teams: [{ name: 'Team 1' }, { name: 'Team 7' }],
                  }
                ],
            }
        ];

        setRound(rounds)
    }


    let matchup = [
        {
          title: 'Round one',
          matches: [
            {
              id: 1,
              date: new Date().toDateString(),
              teamOne : "Team One",
              teamTwo : "Team Two"
            },
            {
              id: 2,
              date: new Date().toDateString(),
              teamOne : "Team Three",
              teamTwo : "Team Four"
            },
            {
                id: 3,
                date: new Date().toDateString(),
                teamOne : "Team Five",
                teamTwo : "Team Six"
            },
            {
                id: 4,
                date: new Date().toDateString(),
                teamOne : "Team Seven",
                teamTwo : "Team Eight"
            },
            {
                id: 5,
                date: new Date().toDateString(),
                teamOne : "Team Nine",
                teamTwo : "Team Ten"
            }
          ],
        },
        {
          title: 'Round Two',
          matches: [
            {
              id: 6,
              date: new Date().toDateString(),
              teamOne : "Team One",
              teamTwo : "Team Four"
            },
            {
                id: 7,
                date: new Date().toDateString(),
                teamOne : "Team Six",
                teamTwo : "Team Seven"
            },
            {
                id: 8,
                date: new Date().toDateString(),
                teamOne : "Team Ten",
                teamTwo : "null"
            },
          ],
        },
        {
            title: 'Round Three',
            matches: [
              {
                id: 6,
                date: new Date().toDateString(),
                teamOne : "Team One",
                teamTwo : "Team Seven"
              },
              {
                  id: 7,
                  date: new Date().toDateString(),
                  teamOne : "Team Ten",
                  teamTwo : "null"
              } 
            ],
        },
        {
            title: 'Final',
            matches: [
              {
                id: 9,
                date: new Date().toDateString(),
                teamOne : "Team Seven",
                teamTwo : "Team Ten"
              }
            ],
          }
    ];
    

    if(competition && (competitionFormat != null || competitionFormat != undefined) && competitionFormat == 2 || competitionFormat == 3){
        return (
            <>
                <ToastContainer />
                <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                    <Row>
                        <Col lg={12}>
                        <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                            <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                            <Breadcrumb.Item href="#competition">Competition</Breadcrumb.Item>
                            <Breadcrumb.Item className="text-dark" active>{props.location.competitionName}</Breadcrumb.Item>
                        </Breadcrumb>
                        </Col>
                    </Row>
            </div>
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0"></Col>
                <Col xs={12} lg={6} className="px-0 mb-0 text-right">
                    <ButtonGroup>
                        <Button className='export' size="sm">Export</Button>
                    </ButtonGroup>
                </Col>
            </div>
    
            <div className="task-wrapper border bg-white border-light shadow-sm pt-3 rounded pb-5 competition-main">
                <Row className="mt-3 mb-5">
                    <Col>
                        <div className="row d-flex px-4 align-items-center">
                            <div className="col col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <select className="form-control">
                                    <option>Sort By</option>
                                    <option>Player Rank (High-Low)</option>
                                </select>
                            </div>
                            {/* <div className="col col-md-8 text-right">
                                <span style={{cursor: "pointer", paddingRight:  "10px"}}><i class="fas fa-filter mx-2"></i><span>Filter</span></span>
                            </div> */}
                            {/* <div className="col col-md-4"></div> */}
                            <div className="col col-xl-8 col-lg-8 col-md-8 col-sm-12 text-right">
                                {/* <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                    <Form.Control type="text" placeholder="Search PlayerId and Player Rank" />
                                </InputGroup> */}
                                <span className='mx-2'>
                                    <button className='refresh' onClick={onRefresh}>
                                        <span><img src={refreshIcon} className='img-responsive' width={20} height={20} /></span>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
                
                <Row className='px-4 d-flex justify-content-between align-items-center'>
                    <Col lg={6} md={6} sm={12}>
                        <h4 className='page-heading'>{props.location.competitionName}</h4>
                    </Col>
                    <Col lg={4} md={4} sm={12} className='text-right'>
                        {/* <span className='mx-2'>
                            <button className='refresh'  onClick={onRefresh}>
                                <span><img src={refreshIcon} className='img-responsive' width={20} height={20} /></span>
                            </button>
                        </span> */}
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                            <Form.Control type="text" placeholder="Search PlayerId and Player Rank" />
                        </InputGroup>
                    </Col>
                </Row>

                <Row className='px-4 my-3 d-flex justify-content-between align-items-center'>
                    <Col lg={6} md={6} sm={6} className='text-left'>
                        {compSlots.length > 0 ? <h6>Slot Id : {compSlots[initialSlot]}</h6> : ""}
                    </Col>
                    <Col lg={6} md={6} sm={6} className='text-right'>
                    {compSlots.length > 0 ?
                        <span className='mx-2'>
                            <button className={initialSlot == 0 ? 'disabled-refresh' : 'refresh'} disabled={initialSlot == 0 ? true : false} onClick={previousCompetitionSlot}>
                                <span><FontAwesomeIcon icon={faCaretLeft}></FontAwesomeIcon></span>
                            </button>
                        </span>
                    : ""}
                    <span>
                        <input style={{width : '45px'}} type='number' min={0} max={compSlots.length-1} onChange={(e)=>{onPage(e)}}/>
                    </span>
                    {compSlots.length > 0 ?
                        <span className='mx-2'>
                            <button className={initialSlot == (compSlots.length-1) ? 'disabled-refresh' : 'refresh'} disabled={initialSlot == (compSlots.length-1) ? true : false} onClick={nextCompetitionSlot}>
                                <span><FontAwesomeIcon icon={faCaretRight}></FontAwesomeIcon></span>
                            </button>
                        </span>
                    : ""}
                    </Col>
                </Row>

                <Row className="px-4 mt-3 table-responsive">
                    <Col lg={12} md={12} sm={12}>
                        <table className="table table-striped text-center table-custom">
                             <thead className='table-head'>
                                <tr>
                                    <th className='head-border-left' scope="col">Player ID</th>
                                    <th scope="col">Player Rank</th>
                                    <th scope="col">User Name</th>
                                    <th className='head-border-right' scope="col">Points</th>
                                </tr>
                            </thead>
                            <tbody>
                                {competition && competition.map((e)=>{
                                    return(
                                        <tr className='table-body'>
                                            <td>{e.users.id}</td>
                                            <td>{e.ranks}</td>
                                            <td>{e.users.username}</td>
                                            <td>{e.score}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Col>
                </Row>

                <div className="d-flex justify-content-left m-4">
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <Pagination
                            count={counts} 
                            defaultPage={page} 
                            color="info" className='paginate'
                            variant="outlined" shape="rounded"
                            showFirstButton={false} 
                            showLastButton={false}  
                            onChange={(event, value)=> setPage(value)}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
        )
    }else if(competition && competitionFormat == 5){
        return(
            <>
            <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                    <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                        <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                        <Breadcrumb.Item href="#competition">Competition</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-dark" active>{props.location.competitionName}</Breadcrumb.Item>
                    </Breadcrumb>
                    </Col>
                </Row>
        </div>
        {competitionFormat == 5 ?
        <div className="d-lg-flex justify-content-around align-items-center flex-wrap flex-md-nowrap my-3">
                <div className="col col-1"></div>
                <div className="col col-md-4 col-sm-12">
                    <Row className="bg-white px-4 py-4 border rounded">
                        <Col>
                            <Row>
                                <Col lg={3} md={3} sm={4} className="d-flex align-items-center">
                                    <i style={{color: "#fff", backgroundColor: "#FFAB00", padding:"15px"}} class="fas fa-hand-holding-usd fa-3x rounded"></i>
                                </Col>
                                <Col lg={9} md={9} sm={8}>
                                    <Row>
                                        <Col>
                                            <h5>Total Amount Distributed</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h4>&#8377; 2300</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {/* <span>Prize Distribution Date: {props.location.date.slice(0,10)}</span> */}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="col col-md-4 col-sm-12">
                    <Row className="border rounded bg-white px-4 py-4">
                        <Col style={{padding: "8px 0px 8px 10px"}}>
                            <Row className="d-flex align-items-center">
                                <Col lg={3} md={3} sm={4}>
                                    <i style={{color: "#fff", backgroundColor: "#FFAB00", padding:"15px"}} class="fas fa-cash-register fa-3x rounded"></i>
                                </Col>
                                <Col lg={9} md={9} sm={8} >
                                    <Row>
                                        <Col>
                                            <h5>Total Hosting Fee Collected</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h4>&#8377; 3500</h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="col col-1"></div>
            </div> : ""}

        <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0"></Col>
            <Col xs={12} lg={6} className="px-0 mb-0 text-right">
                <ButtonGroup>
                    <Button className='export' size="sm">Export</Button>
                </ButtonGroup>
            </Col>
        </div>

        <div className="task-wrapper border bg-white border-light shadow-sm pt-3 rounded pb-5 competition-main">
            <Row className="mt-3 mb-5">
                <Col>
                    <div className="row d-flex px-4 align-items-center">
                        <div className="col col-xl-4 col-lg-4 col-md-4 col-sm-12">
                            <select className="form-control">
                                <option>Sort By</option>
                                <option>Player Rank (High-Low)</option>
                            </select>
                        </div>
                        <div className="col col-xl-8 col-lg-8 col-md-8 col-sm-12 text-right">
                            <span className='mx-2'>
                                <button className='refresh' onClick={onRefresh}>
                                    <span><img src={refreshIcon} className='img-responsive' width={20} height={20} /></span>
                                </button>
                            </span>
                        </div>
                    </div>
                </Col>
            </Row>
            
            <Row className='px-4 d-flex justify-content-between align-items-center'>
                <Col lg={6} md={6} sm={12}>
                    <h4 className='page-heading'>{props.location.competitionName}</h4>
                </Col>
                <Col lg={4} md={4} sm={12} className='text-right'>
                    <InputGroup>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control type="text" placeholder="Search PlayerId and Player Rank" />
                    </InputGroup>
                </Col>
            </Row>

            <Row className="px-4 my-5">
                <Col lg={12} md={12} sm={12}>
                    <Row className="justify-content-left text-center">
                        <Col style={{cursor: "pointer"}} lg={2} md={2} sm={12} onClick={showFirstRound}>
                            <Nav fill variant="pills" className="flex-column flex-sm-row nav-accordian">
                                <Nav.Item>
                                <Nav.Link className={firstRound ? "accordian-pills pills mb-sm-3 mb-md-0" : "pills mb-sm-3 mb-md-0"}>Round One</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col style={{cursor: "pointer"}} lg={2} md={2} sm={12} onClick={showSecondRound}>
                            <Nav fill variant="pills" className="flex-column flex-sm-row nav-accordian">
                                <Nav.Item>
                                    <Nav.Link className={secondRound ? "accordian-pills pills mb-sm-3 mb-md-0" : "pills mb-sm-3 mb-md-0"}>Round Two</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col style={{cursor: "pointer"}} lg={2} md={2} sm={12} onClick={showFinalRound}>
                            <Nav fill variant="pills" className="flex-column flex-sm-row nav-accordian">
                                <Nav.Item>
                                    <Nav.Link className={finalRound ? "accordian-pills pills mb-sm-3 mb-md-0" : "pills mb-sm-3 mb-md-0"}>Final</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                </Col>
            </Row> 

            {/* <Row className='px-4 my-3 d-flex justify-content-between align-items-center'>
                <Col lg={6} md={6} sm={6} className='text-left'>
                {compSlots.length > 0 ? <h6>Slot Id : {compSlots[initialSlot]}</h6> : ""}
                </Col>
                <Col lg={6} md={6} sm={6} className='text-right'>
                {compSlots.length > 0 ?
                    <span className='mx-2'>
                        <button className={initialSlot == 0 ? 'disabled-refresh' : 'refresh'} disabled={initialSlot == 0 ? true : false} onClick={previousCompetitionSlot}>
                            <span><FontAwesomeIcon icon={faCaretLeft}></FontAwesomeIcon></span>
                        </button>
                    </span>
                : ""}
                <span>
                    <input style={{width : '45px'}} type='number' min={0} max={compSlots.length-1} onChange={(e)=>{onPage(e)}}/>
                </span>
                {compSlots.length > 0 ?
                    <span className='mx-2'>
                        <button className={initialSlot == (compSlots.length-1) ? 'disabled-refresh' : 'refresh'} disabled={initialSlot == (compSlots.length-1) ? true : false} onClick={nextCompetitionSlot}>
                            <span><FontAwesomeIcon icon={faCaretRight}></FontAwesomeIcon></span>
                        </button>
                    </span>
                : ""}
                </Col>
            </Row> */}

            {competitionFormat == 5 ? 
                <Row className='my-5'>
                    <Col xl={5} lg={5} md={5} sm={5} xs={5}></Col>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        <Row className='bracket'>
                            <Col>
                                {/* <Bracket rounds={round} /> */}
                                <Brackets data={matchup} />
                            </Col>
                        </Row>
                    </Col>
                    <Col></Col>
                </Row>
            : ""} 

           
            <div className="d-flex justify-content-left m-4">
                <Row>
                    <Col lg={12} md={12} sm={12}>
                        <Pagination
                        count={counts} 
                        defaultPage={page} 
                        color="info" className='paginate'
                        variant="outlined" shape="rounded"
                        showFirstButton={false} 
                        showLastButton={false}  
                        onChange={(event, value)=> setPage(value)}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    </>
        )
    }
    else{
        return(
            <p></p>
        )
    }
}

export default CompetitionDetail