import React, {useState} from 'react'
import { Modal, Row, Col } from "react-bootstrap"
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function CompetitionFilter({show, onHide, filtersCoupons}) {

    const close = () => {
        filtersCoupons([], [], [], [], [])

        setArcade(false)
        setBoard(false)
        setCasino(false)
        setRacing(false)
        setSport(false)

        setSinglePlayer(false)
        setMultiPlayer(false)

        setHighScore(false)
        setTimeTrail(false)
        setWinLoss(false)
        setPodiumFinishMatch(false)
        setWinningMatch(false)

        setHighScoreLeaderboard(false)
        setTimeTrailLeaderboard(false)
        setWinLossLeaderboard(false)
        setPodiumFinishMatchLeaderboard(false)
        setWinningMatchLeaderboard(false)
        
        setYetStart(false)
        setOnGoing(false)
        setCompleted(false)
        setFailed(false)

        onHide();
    }

    const filterCoupon = () => {
        filtersCoupons(world, matchFormat, matchOutcome, leaderboardOutcome, status)
        onHide()
    }

    
    const [country, setCountry] = useState(false)
    const onCountry = () => {
        setCountry(!country)
    }

    const [showgameworld, setShowgameworld] = useState(false)
    const [world, setWorld] = useState([])
    const [arcade, setArcade] = useState(false)
    const [board, setBoard] = useState(false)
    const [casino, setCasino] = useState(false)
    const [racing, setRacing] = useState(false)
    const [sport, setSport] = useState(false)

    const onchangeWorld = () => {
        setShowgameworld(!showgameworld)
    }

    const onWorld = (e) => {
        let worldList = [...world]
        if(e.target.checked){
            if(e.target.value == 1) {
                setWorld([...world, e.target.value])
                setSport(!sport)
            }else if(e.target.value == 2){
                setWorld([...world, e.target.value])
                setCasino(!casino)
            }else if(e.target.value == 3){
                setWorld([...world, e.target.value])
                setArcade(!arcade)
            }else if(e.target.value == 4){
                setWorld([...world, e.target.value])
                setRacing(!racing)
            }else if(e.target.value == 5){
                setWorld([...world, e.target.value])
                setBoard(!board)
            }
        }else{
            if(e.target.value == 1) {
                setSport(!sport)
            }else if(e.target.value == 2){
                setCasino(!casino)
            }else if(e.target.value == 3){
                setArcade(!arcade)
            }
            else if(e.target.value == 4){
                setRacing(!racing)
            }else if(e.target.value == 5){
                setBoard(!board)
            }
            worldList.splice(world.indexOf(e.target.value), 1)
            setWorld(worldList)
        }
    }

    const [showmatchformat, setShowmatchformat] = useState(false)
    const [matchFormat, setMatchFormat] = useState([])
    const [singlePlayer, setSinglePlayer] = useState(false)
    const [multiPlayer, setMultiPlayer] = useState(false)

    const onchangeMatchformat = () => {
        setShowmatchformat(!showmatchformat)
    }

    const onMatchFormat = (e) => {
        let matchFormatList = [...matchFormat]
        if(e.target.checked){
            if(e.target.value == 1) {
                setMatchFormat([...matchFormat, e.target.value])
                setSinglePlayer(!singlePlayer)
            }else if(e.target.value == 2){
                setMatchFormat([...matchFormat, e.target.value])
                setMultiPlayer(!multiPlayer)
            }
        }else{
            if(e.target.value == 1) {
                setSinglePlayer(!singlePlayer)
            }else if(e.target.value == 2){
                setMultiPlayer(!multiPlayer)
            }
            matchFormatList.splice(matchFormat.indexOf(e.target.value), 1)
            setMatchFormat(matchFormatList)
        }
    }

    const [showmatchoutcome, setShowmatchoutcome] = useState(false)
    const [matchOutcome, setMatchOutcome] = useState([])
    const [highScore, setHighScore] = useState(false)
    const [timeTrail, setTimeTrail] = useState(false)
    const [winLoss, setWinLoss] = useState(false)
    const [podiumFinishMatch, setPodiumFinishMatch] = useState(false)
    const [winningsMatch, setWinningMatch] = useState(false)

    const onchangeMatchOutcome = () => {
        setShowmatchoutcome(!showmatchoutcome)
    }

    const onMatchOutcome = (e) => {
        let matchOutcomeList = [...matchOutcome]
        if(e.target.checked){
            if(e.target.value == 1) {
                setMatchOutcome([...matchOutcome, e.target.value])
                setHighScore(!highScore)
            }else if(e.target.value == 2){
                setMatchOutcome([...matchOutcome, e.target.value])
                setTimeTrail(!timeTrail)
            }else if(e.target.value == 3){
                setMatchOutcome([...matchOutcome, e.target.value])
                setWinLoss(!winLoss)
            }else if(e.target.value == 4){
                setMatchOutcome([...matchOutcome, e.target.value])
                setPodiumFinishMatch(!podiumFinishMatch)
            }else if(e.target.value == 5){
                setMatchOutcome([...matchOutcome, e.target.value])
                setWinningMatch(!winningsMatch)
            }
        }else{
            if(e.target.value == 1) {
                setHighScore(!highScore)
            }else if(e.target.value == 2){
                setTimeTrail(!timeTrail)
            }else if(e.target.value == 3){
                setWinLoss(!winLoss)
            }else if(e.target.value == 4){
                setPodiumFinishMatch(!podiumFinishMatch)
            }else if(e.target.value == 5){
                setWinningMatch(!winningsMatch)
            }
            matchOutcomeList.splice(matchOutcome.indexOf(e.target.value), 1)
            setMatchOutcome(matchOutcomeList)
        }
    }

    const [showleaderboard, setShowleaderboard] = useState(false)
    const [leaderboardOutcome, setLeaderboardOutcome] = useState([])
    const [highScoreLeaderboard, setHighScoreLeaderboard] = useState(false)
    const [timeTrailLeaderboard, setTimeTrailLeaderboard] = useState(false)
    const [winLossLeaderboard, setWinLossLeaderboard] = useState(false)
    const [podiumFinishMatchLeaderboard, setPodiumFinishMatchLeaderboard] = useState(false)
    const [winningsMatchLeaderboard, setWinningMatchLeaderboard] = useState(false)

    const onchangeLeaderboard = () => {
        setShowleaderboard(!showleaderboard)
    }

    const onLeaderboard = (e) => {
        let leaderboardOutcomeList = [...leaderboardOutcome]
        if(e.target.checked){
            if(e.target.value == 1) {
                setLeaderboardOutcome([...leaderboardOutcome, e.target.value])
                setHighScoreLeaderboard(!highScoreLeaderboard)
            }else if(e.target.value == 2){
                setLeaderboardOutcome([...leaderboardOutcome, e.target.value])
                setTimeTrailLeaderboard(!timeTrailLeaderboard)
            }else if(e.target.value == 3){
                setLeaderboardOutcome([...leaderboardOutcome, e.target.value])
                setWinLossLeaderboard(!winLossLeaderboard)
            }else if(e.target.value == 4){
                setLeaderboardOutcome([...leaderboardOutcome, e.target.value])
                setPodiumFinishMatchLeaderboard(!podiumFinishMatchLeaderboard)
            }else if(e.target.value == 5){
                setLeaderboardOutcome([...leaderboardOutcome, e.target.value])
                setWinningMatchLeaderboard(!winningsMatchLeaderboard)
            }
        }else{
            if(e.target.value == 1) {
                setHighScoreLeaderboard(!highScoreLeaderboard)
            }else if(e.target.value == 2){
                setTimeTrailLeaderboard(!timeTrailLeaderboard)
            }else if(e.target.value == 3){
                setWinLossLeaderboard(!winLossLeaderboard)
            }else if(e.target.value == 4){
                setPodiumFinishMatchLeaderboard(!podiumFinishMatchLeaderboard)
            }else if(e.target.value == 5){
                setWinningMatchLeaderboard(!winningsMatchLeaderboard)
            }
            leaderboardOutcomeList.splice(leaderboardOutcome.indexOf(e.target.value), 1)
            setLeaderboardOutcome(leaderboardOutcomeList)
        }
    }



    const [status, setStatus] = useState([])
    const [yetStart, setYetStart] = useState(false)
    const [onGoing, setOnGoing] = useState(false)
    const [completed, setCompleted] = useState(false)
    const [failed, setFailed] = useState(false)


    const onStatus = (e) => {
        let statusList = [...status]
        if(e.target.checked){
            if(e.target.value == 'yet to start') {
                setStatus([...status, e.target.value])
                setYetStart(!yetStart)
            }else if(e.target.value == 'in progress'){
                setStatus([...status, e.target.value])
                setOnGoing(!onGoing)
            }else if(e.target.value == 'completed'){
                setStatus([...status, e.target.value])
                setCompleted(!completed)
            }else if(e.target.value == 'failed'){
                setStatus([...status, e.target.value])
                setFailed(!failed)
            }
        }else{
            if(e.target.value == 'yet to start') {
                setYetStart(!yetStart)
            }else if(e.target.value == 'ongoing'){
                setOnGoing(!onGoing)
            }else if(e.target.value == 'completed'){
                setCompleted(!completed)
            }else if(e.target.value == 'failed'){
                setFailed(!failed)
            }
            statusList.splice(status.indexOf(e.target.value), 1)
            setStatus(statusList)
        }
    }


    return (
        <div>
            <Modal centered show={show} onHide={onHide} size="xl" aria-labelledby="contained-modal-title-vcenter" className='filter-main'>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <p className='heading-title'>Add Filter</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <label className='filter-title'>Game World</label>
                            {/* <FormControlLabel control={<Checkbox checked={showgameworld} onChange={onchangeWorld} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Game World" /> */}
                            <div className='my-2'>
                                <Row>
                                    <Col lg={2} md={2} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={arcade} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={3} />} label={<span style={{ fontSize: '14px' }}>Arcade</span>}/>
                                    </Col>

                                    <Col lg={2} md={2} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={board} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={5} />} label={<span style={{ fontSize: '14px' }}>Board</span>}/>
                                    </Col>

                                    <Col lg={2} md={2} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={casino} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={2} />} label={<span style={{ fontSize: '14px' }}>Casino</span>}/>
                                    </Col>

                                    <Col lg={2} md={2} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={racing} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={4} />} label={<span style={{ fontSize: '14px' }}>Racing</span>}/>
                                    </Col>

                                    <Col lg={2} md={2} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={sport} onChange={(e)=>{onWorld(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={1} />} label={<span style={{ fontSize: '14px' }}>Sports</span>}/>
                                    </Col>
                                </Row>

                            </div>

                        </Col>
                    </Row>
                    
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <label className='filter-title'>Match Format</label>
                            {/* <FormControlLabel control={<Checkbox checked={showmatchformat} onChange={onchangeMatchformat} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Match Format" /> */}
                                <div className='my-2'>
                                    <Row>
                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={singlePlayer} onChange={(e)=>{onMatchFormat(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={1} />} label={<span style={{ fontSize: '14px' }}>Single Player</span>}/>
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={multiPlayer} onChange={(e)=>{onMatchFormat(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={2} />} label={<span style={{ fontSize: '14px' }}>MultiPlayer</span>}/>
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                                
                                        </Col>
                                    </Row>  
                                </div>
                        </Col>
                    </Row>
                        
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            {/* <FormControlLabel control={<Checkbox checked={showmatchoutcome} onChange={onchangeMatchOutcome} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Match Outcome Type" /> */}
                                <label className='filter-title'>Match OutcomeType</label>
                                <div className='my-2'>
                                    <Row>
                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={highScore} onChange={(e)=>{onMatchOutcome(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={1} />} label={<span style={{ fontSize: '14px' }}>High Score</span>}/>
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={timeTrail} onChange={(e)=>{onMatchOutcome(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={2} />} label={<span style={{ fontSize: '14px' }}>Time Trail</span>}/>
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={winLoss} onChange={(e)=>{onMatchOutcome(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={3} />} label={<span style={{ fontSize: '14px' }}>Win/Loss/Draw</span>}/>
                                        </Col>

                                        <Col lg={2} md={2} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={podiumFinishMatch} onChange={(e)=>{onMatchOutcome(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={4} />} label={<span style={{ fontSize: '14px' }}>Podium Finish</span>}/>
                                        </Col>

                                        <Col lg={3} md={3} sm={12}>
                                            <FormControlLabel control={<Checkbox checked={winningsMatch} onChange={(e)=>{onMatchOutcome(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={5} />} label={<span style={{ fontSize: '14px' }}>Winnings Collected</span>}/>
                                        </Col>
                                    </Row>

                                </div>
                        </Col>
                    </Row>
                        
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            {/* <FormControlLabel control={<Checkbox checked={showleaderboard} onChange={onchangeLeaderboard} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label="Leaderboard Outcome Type" /> */}
                            <label className='filter-title'>Leaderboard OutcomeType</label>
                            <div className='my-2'>
                                <Row>
                                    <Col lg={2} md={2} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={highScoreLeaderboard} onChange={(e)=>{onLeaderboard(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={1} />} label={<span style={{ fontSize: '14px' }}>High Score</span>}/>
                                    </Col>

                                    <Col lg={2} md={2} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={timeTrailLeaderboard} onChange={(e)=>{onLeaderboard(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={2} />} label={<span style={{ fontSize: '14px' }}>Time Trail</span>}/>
                                    </Col>

                                    <Col lg={2} md={2} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={winLossLeaderboard} onChange={(e)=>{onLeaderboard(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={3} />} label={<span style={{ fontSize: '14px' }}>Win/Loss/Draw</span>}/>
                                    </Col>

                                    <Col lg={2} md={2} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={podiumFinishMatchLeaderboard} onChange={(e)=>{onLeaderboard(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={4} />} label={<span style={{ fontSize: '14px' }}>Podium Finish</span>}/>
                                    </Col>

                                    <Col lg={3} md={3} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={winningsMatchLeaderboard} onChange={(e)=>{onLeaderboard(e)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value={5} />} label={<span style={{ fontSize: '14px' }}>Winnings Collected</span>}/>
                                    </Col>
                                </Row>

                            </div>

                        </Col>
                    </Row>
                    

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <label className='filter-title'>Geography</label>
                            <div className='my-2'>
                                <Row>
                                    <Col lg={2} md={2} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={country} onChange={onCountry} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />} label={<span style={{ fontSize: '14px' }}>India</span>}/>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <label className='filter-title'>Status</label>
                            <div className='my-2'>
                                <Row>
                                    <Col lg={2} md={2} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={yetStart} onChange={onStatus} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value="yet to start" />} label={<span style={{ fontSize: '14px' }}>Yet To Start</span>}/>
                                    </Col>
                                    <Col lg={2} md={2} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={onGoing} onChange={onStatus} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value="in progress" />} label={<span style={{ fontSize: '14px' }}>Ongoing</span>}/>
                                    </Col>
                                    <Col lg={2} md={2} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={completed} onChange={onStatus} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value="completed" />} label={<span style={{ fontSize: '14px' }}>Completed</span>}/>
                                    </Col>
                                    <Col lg={2} md={2} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={failed} onChange={onStatus} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} value="failed"/>} label={<span style={{ fontSize: '14px' }}>Failed</span>}/>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer className='filter-footer'>
                    <Row>
                        <Col className="text-right mt-2">
                            <button className="btn btn-danger mx-2" onClick={filterCoupon}>Apply Filter</button>
                            <button className="btn btn-success" onClick={close}>Clear Filter</button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CompetitionFilter