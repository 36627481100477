import LeaderboardServices from '../../services/LeaderboardServices';
import {CLEAR_LEADERBOARD, CREATE_CUSTOM_LEADERBOARD, ALL_LEADERBOARD, PPSALL_ID,GET_LINK, GET_PLAYER_DISTRIBUTION, SEARCH_LEADERBOARD, CREATE_PD_LEADERBOARD, CREATE_PLAYSTARZ_SCORING, GET_PLAYSTARZ_SCORING, LEADERBOARD_BY_ID, GET_UNIVERSAL_LEADERBOARD, GET_LEADERBOARD_DETAIL, SEARCH_LEADERBOARD_DETAILS, EDIT_PLAYSTARZ_SCORING, SEARCH_UNIVERSAL_LEADERBOARD} from '../actionType/actionTypes'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PPSALL from '../../pages/PPSALL';

// import { searchGame } from './gameAction';

export const getAllLeaderboardAction = (body) => {
    return async (dispatch) => {
        await LeaderboardServices.getallLeaderboard(body)
        .then(res=>{
            dispatch(getallLeaderboard(res.data.leaderboardData))
        })
    }
}

export const getLeaderboardByIdAction = (body) => {
    return async (dispatch) => {
        await LeaderboardServices.getallLeaderboard(body)
        .then(res=>{
            dispatch(getLeaderboardById(res.data.leaderboardData))
        })
    }
}

export const createCustomLeaderboardAction = (body, history) => {
    return async (dispatch) => {
        await LeaderboardServices.createCustomLeaderboard(body)
        .then(res=>{
            toast.success(res.data.message)
            setTimeout(()=>{
                history.push('/main-leaderboard')
            }, 1500)
        })
    }
}

export const searchLeaderBoardAction = (body) => {
    return async (dispatch) => {
        await LeaderboardServices.searchLeaderBoard(body)
        .then(res=>{
            dispatch(searchLeaderBoard(res.data.leaderboardData))
        })
    }
}

export const getLeaderboardDetailsAction = (body) => {
    return async (dispatch) => {
        await LeaderboardServices.getLeaderboardDetails(body)
        .then(res=>{
            dispatch(getLeaderboardDetails(res.data.leaderboardData))
        })
    }
}

export const getUniversalLeaderboardAction = (body) => {
    return async (dispatch) => {
        await LeaderboardServices.getUniversalLeaderboard(body)
        .then(res=>{
            dispatch(getUniversalLeaderboard(res.data.universalLeaderboardData))
        }).catch(err=>{

        })
    }
}

export const clearLeaderboardAction = (body) => {
    return async (dispatch) => {
        dispatch(clearLeaderboard())
    }
}

export const createPDLeaderboardAction = (body) => {
    return async (dispatch) => {
        await LeaderboardServices.createPDLeaderboard(body)
        .then(res=>{
            toast.success(res.data.message)
        })
    }
}

export const getAllPDAction = (body) => {
    return async (dispatch) => {
        await LeaderboardServices.getallplayerdistribution(body)
        .then(res=>{
            dispatch(getallplayerdistribution(res.data.leaderboardData))
        })
        }
}


export const createPlaystarzAction = (body, history) => {
    return async (dispatch) => {
        await LeaderboardServices.createPlaystarz(body)
        .then(res=>{
            toast.success(res.data.message)
        })
    }
}

export const getPPSALLAction = (body) => {
    return async (dispatch) =>{
        await LeaderboardServices.getPPSALL(body)
        .then(res=>{
            if(res.data.data.length == 0){
                
            }else{
                dispatch(getPPSALL(res.data.data[0].playstarzScoring))
                dispatch(getPPSALLID(res.data.data[0].id))
                dispatch(getLink(res.data.data[0].playstarzScoringFileUrl))
            }
        })
    }
}

export const editPPSALLAction = (body, history) => {
    return async (dispatch) => {
        await LeaderboardServices.editPPSALL(body)
        .then(res=>{
            toast.success(res.data.message)
            history.push('/ppsall')
            dispatch(getPPSALLAction())
        }).catch((err)=>{    
            // toast.error(err.response.data.message)
        })
    }
}


export const searchUniLeaderboardAction = (body) => {
    return async (dispatch) => {
        await LeaderboardServices.searchUniversalLeaderboardDetail(body)
        .then(res=>{
            dispatch(searchUniversalLeaderboardDetail(res.data.leaderboardData))
        })
    }
}

export const searchLeaderBoardDetailsAction = (body) => {
    return async (dispatch) => {
        await LeaderboardServices.searchLeaderboardDetails(body)
        .then(res=>{
            dispatch(searchLeaderboardDetails(res.data.leaderboardData))
        })
    }
}

export const getallLeaderboard = (data) => {
    return {
        type : ALL_LEADERBOARD,
        payload : data
    }
}

export const searchUniversalLeaderboardDetail = (data) => {
    return {
        type : SEARCH_UNIVERSAL_LEADERBOARD,
        payload : data
    }
}

export const searchLeaderboardDetails = (data) => {
    return {
        type : SEARCH_LEADERBOARD_DETAILS,
        payload : data
    }
}

export const createPDLeaderboard = (data) => {
    return {
        type: CREATE_PD_LEADERBOARD,
        payload: data
    }
}

export const getUniversalLeaderboard = (data) => {
    return {
        type : GET_UNIVERSAL_LEADERBOARD,
        payload : data
    }
}

export const getLeaderboardDetails = (data) => {
    return {
        type : LEADERBOARD_BY_ID,
        payload : data
    }
}

export const getLeaderboardById = (data) => {
    return {
        type : GET_LEADERBOARD_DETAIL,
        payload : data
    }
}

export const searchLeaderBoard = (data) => {
    return {
        type: SEARCH_LEADERBOARD,
        payload: data,
    }
}

export const clearLeaderboard = (data) => {
    return{
        type: CLEAR_LEADERBOARD,
        payload: data,
    }
}

export const editPPSALL = (data) => {
    return {
        type: EDIT_PLAYSTARZ_SCORING,
        payload: data
    }
}

export const getLink = (data) => {
    return {
        type: GET_LINK,
        payload: data
    }
}

export const getPPSALLID = (data) => {
    return {
        type: PPSALL_ID,
        payload: data
    }
}

export const createPlaystarz = (data) => {
    return {
        type: CREATE_PLAYSTARZ_SCORING,
        payload: data
    }
}

export const getPPSALL = (data) => {
    return {
        type: GET_PLAYSTARZ_SCORING,
        payload: data
    }
}

export const getallplayerdistribution = (data) => {
    return {
        type: GET_PLAYER_DISTRIBUTION,
        payload: data
    }
}

export const createCustomLeaderboard = (data) => {
    return {
        type : CREATE_CUSTOM_LEADERBOARD,
        payload : data
    }
}