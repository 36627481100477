import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import {Game_Details, Game_Id,Game_Name, Game_Description, Game_How_to, Game_Platform, Asset_Bundle_Version, Minimum_App_Version, Game_Genre, Screen_Orientation, Game_World, Match_Name, Match_Format, Match_How_To, Match_Outcome_Type, Leaderboard_Outcome_Type, Included_Countries, Phase, States, Match_Details, Game_Screenshots} from '../../../constants/LabelConstants'
import '../../css/games.css'
import { Link} from "react-router-dom";
import { Routes } from "../../../routes";
import { Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export default function EnvironmentChangeModal({show, onHide}) {

    const history = useHistory()

    const [dev, setDev] = useState(false)
    const [staging, setStaging] = useState(false)
    const [production, setProduction] = useState(false)

    const getEnvType = () => {
        
        const env = localStorage.getItem('type')

        if(env == 'Development'){
            setDev(true)
            setStaging(false)
            setProduction(false)
        }else if(env == 'Staging'){
            setDev(false)
            setStaging(true)
            setProduction(false)
        }else if(env == 'Production'){
            setDev(false)
            setStaging(false)
            setProduction(true)
        }

    }

    useEffect(()=>{
        getEnvType();
    }, [])

    const [environmentVariable, setEnvironmentVariable] = useState()

    const changeEnvironment = (type) => {
        if(type == 'Development'){
            setDev(true)
            setStaging(false)
            setProduction(false)
        }else if(type == 'Staging'){
            setDev(false)
            setStaging(true)
            setProduction(false)
        }else if(type == 'Production'){
            setDev(false)
            setStaging(false)
            setProduction(true)
            localStorage.removeItem('token')
        }
        setEnvironmentVariable(type)
    }

    const saveEnvironment = () => {
        if(localStorage.getItem('type') == "Production"){
            localStorage.removeItem('token') 
            localStorage.setItem('type', environmentVariable)
            window.location.reload();   
        }else{
            localStorage.setItem('type', environmentVariable)
            setTimeout(()=>{
            history.push('/overview')
            window.location.reload()
            }, 1000)
        }
    }

    const close = () => {
        getEnvType();
        onHide();
    }


    return (
        <Modal className='modal' size="md" contentClassName='env-modal' scrollable={true} centered show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <h5>Switch Environment</h5>
                    </Col>
                </Row>
            </Modal.Header>

            <Modal.Body className='modal-body'>

                <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        
                        <Row className='main-dev' onClick={()=>{changeEnvironment('Development')}}>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Row className={dev ? 'env-outer-dev-selected' : 'env-outer-dev'}>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Row className='development'>
                                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <h6>{localStorage.getItem('type') == 'Development' ? <span><FontAwesomeIcon icon={faCheck} className="me-2 icon" /></span>: ''}<b>Development</b></h6>
                                                <p>Set up campaign and debug. Only campaigns within this environment can be pushed to Live</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className='main-staging' onClick={()=>{changeEnvironment('Staging')}}>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Row className={staging ? 'env-outer-staging-selected' : 'env-outer-staging'}>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Row className='staging'>
                                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <h6>{localStorage.getItem('type') == 'Staging' ? <span><FontAwesomeIcon icon={faCheck} className="me-2 icon" /></span>: ''}<b>Staging</b></h6>
                                                <p>Test the ins and outs of campaign. Find and report bugs to Deve Team & then fixed them before prod.</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className='main-production' onClick={()=>{changeEnvironment('Production')}}>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Row className={production ? 'env-outer-production-selected' : 'env-outer-production'}>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Row className='production'>
                                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <h6>{localStorage.getItem('type') == 'Production' ? <span><FontAwesomeIcon icon={faCheck} className="me-2 icon" /></span>: ''}<b>Production</b></h6>
                                                <p>Campaigns that are published live. Live campaigns cannot be directly modified on this environment</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer className='modal-footer'>
                <Row>
                    <Col>
                        <Button className='btn Button-custom' onClick={saveEnvironment}>Done</Button>
                        <Button className='btn mx-2 Button-custom' onClick={close}>Cancel</Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}
