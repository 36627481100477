import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col, Dropdown} from '@themesberg/react-bootstrap'

function BulkPayoutDetail({show, onHide}) {

    const close = () => {
        onHide();
    }

    return (
        <div>
            <Modal scrollable={true} size="md" centered show={show} onHide={onHide}>
                <Modal.Header>
                    <div className="modal_head">
                        <h6>Payout Details</h6>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={6}>
                            <p>Batch Name : <a href="#" target="_blank" style={{textDecoration: "underline"}}>BulkPayout</a></p>
                        </Col>
                        <Col lg={6}>
                            <p>Transaction Status : Scheduled</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <p>Payout Count : PUBG</p>
                        </Col>
                        <Col lg={6}>
                            <p>Payout Total Amount : 34000</p>	
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col>
                            <Button className="btn btn-danger btn -sm mx-2" onClick={close}>Cancel</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default  BulkPayoutDetail