
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBell, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield, faSort, faCross, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Nav, Form, Image, Button, Navbar, Dropdown, Container, ListGroup, InputGroup } from '@themesberg/react-bootstrap';
import SidebarImg from '../pages/Images/Sidebar_Logo.jpg'
import NOTIFICATIONS_DATA from "../data/notifications";
import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import { useHistory } from "react-router-dom";
import '../pages/css/games.css'
import EnvironmentChangeModal from "../pages/modals/Environment/EnvironmentChangeModal";

export default (props) => {

  const history = useHistory()

  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);

  const markNotificationsAsRead = () => { 
    setTimeout(() => {
      setNotifications(notifications.map(n => ({ ...n, read: true })));
    }, 300);
  };

  const SignOut = () => {
    localStorage.removeItem('token');
    window.location.assign('/')
  }

  const toggleContracted = () => props.toggleContracted && props.toggleContracted();


  const Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light" >
        <Row className="align-items-center">
          <Col className="col-auto">
            <Image src={image} className="user-avatar lg-avatar rounded-circle" />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  const onChangeNew = (e) => {
    // localStorage.setItem('type', e.target.value)
    // var env = document.getElementById('env');
    // if(env == 'Development'){
    //   env.style.backgroundColor = "blue"
    // }else if(env == 'Staging'){
    //   env.style.backgroundColor = "red"
    // }else if(env == 'Production'){
    //   env.style.backgroundColor = "green"
    // }
    // setTimeout(()=>{
    //   history.push('/overview')
    //   window.location.reload()
    // }, 1000)
  }

  useEffect(()=>{
    let environmentVariable = localStorage.getItem('type')
    if(environmentVariable == 'Development'){
      var env = document.getElementById('env');
      env.style.backgroundColor = "#7762f6"
    }else if(environmentVariable == 'Staging'){
      var env = document.getElementById('env');
      env.style.backgroundColor = "#e5734c"
    }else if(environmentVariable == 'Production'){
      var env = document.getElementById('env');
      env.style.backgroundColor = "#74d068"
    }
  }, [])

  const [showModal, setShowModal] = useState(false)

  const onShowModal = () => {
    setShowModal(!showModal)
  }

  return (
    
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0 fixed">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Button id="sidebar-toggle" variant="icon-only" size="lg" className="sidebar-toggle btn-circle d-none d-md-inline-block me-3" onClick={toggleContracted}>
              {localStorage.getItem('sidebarContracted') == 'false' ? <FontAwesomeIcon icon={faTimesCircle} /> : localStorage.getItem('sidebarContracted') == 'true' ? <FontAwesomeIcon icon={faBars} /> : ''}
            </Button>
            <Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                  <Form.Control type="text" placeholder="Search" />
                </InputGroup>
              </Form.Group>
            </Form>
          </div>
          <Nav className="align-items-center">
            {/* <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} >
              <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
                    Notifications
                  </Nav.Link>

                  {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}

                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown> */}

              <select id='env' style={{color: '#fff', textAlignLast: 'center', padding: '0px !important', margin: '0px !important'}} className="form-control" onClick={onShowModal}>
                  <option selected disabled>{localStorage.getItem('type')}</option>
                  {/* {localStorage.getItem('type') === "Development" ? "" : <option value="Development">Development</option>}
                  {localStorage.getItem('type') === "Staging" ? "" : <option value="Staging">Staging</option>}
                  {localStorage.getItem('type') === "Production" ? "" : <option value="Production">Production</option>} */}
              </select>


            <Dropdown as={Nav.Item} className='pl-5'>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-5">
                <div className="media d-flex align-items-center">
                  <Image src={localStorage.getItem('orgLogo') == null ? SidebarImg : localStorage.getItem('orgLogo') != '' || localStorage.getItem('orgLogo') != null ? localStorage.getItem('orgLogo') : ""} className="user-avatar md-avatar rounded-circle" />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">Admin</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold" onClick={SignOut}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
      <EnvironmentChangeModal show={showModal} onHide={onShowModal} />
    </Navbar>
  );
};
