import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faSearch, faPlusCircle, faTrash, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col,InputGroup, Row, Nav, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import { Breadcrumb } from "react-bootstrap";
import { Link , useHistory} from "react-router-dom";
import { Routes } from "../../routes";
import {useDispatch, useSelector} from 'react-redux';
import { getTeamsAction } from '../../redux/action/teamsAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Teams(props) {

    const history = useHistory();
    const dispatch = useDispatch();

    const getAllTeams = () => {

        let allTeam = {
            teamsFilters: {
                pagination : {
                    offset : 0,
                    limit : 10
                }
            }
        }

        dispatch(getTeamsAction(allTeam))

    }

    useEffect(()=>{
        getAllTeams();
    }, [])

    const allTeams = useSelector((state)=>{ 
        return state.team.teams
    })

    const loadingTeam = useSelector((state)=>{
        return state.team.isLoading
    })

    return (
        <>
            <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                        <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                            <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                            <Breadcrumb.Item className="text-dark" active>Teams</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
    
            <div className="d-lg-flex justify-content-end flex-wrap flex-md-nowrap align-items-center py-4">
                <Col xs={12} sm={12} md={12} lg={6}>
                    <Row className="px-3">
                        <Button as={Link} to={Routes.CreateTeams.path} className="button-style me-3" style={{width: "200px", display: "Inline !important"}}>
                            <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                            <span>Create Team</span>
                        </Button>
                    </Row>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className="px-0 mb-0 text-right">
                    {/* <ButtonGroup>
                        <Button variant="outline-primary" className="export" size="sm">Export</Button>
                    </ButtonGroup> */}
                </Col>
            </div>
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded gamestarz">
                <div className="row mb-4 px-4">
                    <div className="col col-lg-6 col-md-12 text-left">
                        <h4>Teams</h4>
                    </div>
                    <div className="col col-lg-6 col-md-12 col-sm-12 text-right">
                        <span className='filter' style={{cursor: "pointer"}}><i className="fas fa-filter mx-2"></i><span>Filter</span></span>
                    </div>
                </div>

                <Row className="d-flex px-4">
                    {/* <Col lg={4} md={4} sm={12} xs={12}></Col> */}
                    <Col lg={4} md={4} sm={12} xs={12}>
                        <InputGroup>
                            <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                            <input className="form-control" type="text" placeholder="Search Teams" />
                        </InputGroup>
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12} className="mb-sm-4 pb-sm-4">
                        <select className="form-control">
                            <option>Sort By</option>
                            <option>Newest to Oldest</option>
                            <option>Oldest to Newest</option>
                        </select>
                    </Col>
                </Row>

                <div className="container-fluid payout">
                <div className="my-5">
                    <Row className='table-responsive'>
                        <Col style={{overflowX : "scroll"}} lg={12} md={12} sm={12}>
                            <table className="table table-striped text-center table-custom">
                                <thead className='table-head'>
                                    <tr>
                                        <th className='head-border-left' scope="col">Created At</th>
                                        <th scope='col'>Team Id</th>
                                        <th scope='col'>Team Name</th>
                                        <th className='head-border-right' scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className='table-body'>
                                    {allTeams && allTeams.map((e,i)=>{
                                        return(
                                            <tr>
                                                <td>{e.createdAt.slice(0,10).split("-").reverse().join("-")}</td>
                                                <td>{e.id}</td>
                                                <td>{e.teamName}</td>
                                                <td>
                                                    <Dropdown className="ms-3">
                                                    <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="icon icon-sm icon-dark">
                                                        <FontAwesomeIcon icon={faEllipsisH} />
                                                        </span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                        <Dropdown.Item as={Link} to={{pathname:Routes.EditTeams.path, id:e.id}} className="ms-0">
                                                            <FontAwesomeIcon icon={faEdit} className="me-2"/> Edit
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </Col>
                    </Row>

                </div> 
            </div>
        </div>

        </>
    )
}

export default Teams