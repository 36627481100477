import { CREATE_COUPONS, GET_SINGLE_PAYOUT, GET_COUPONS, GET_TRANSACTION, GET_SCRATCH_CARD, ADD_SCRATCH_CARD, GET_COUPON_BY_ID, GET_SCRATCH_CARD_BY_ID, CLEAR_COUPON_DETAIL, CLEAR_SCRATCH_CARD_DETAIL, EDIT_COUPON, EDIT_SCRATCH_CARD, SEARCH_COUPON, SEARCH_SERATCH_CARD, SEARCH_TRANSACTION } from "../actionType/actionTypes";

const initialState = {
    transactions : [],
    coupons : [],
    scratchCard : [],
    CouponDetail : [],
    ScratchCardDetail : [],
    singlePayout : []
}

export const playstarzbankReducer = (state = initialState, action) => {

    switch(action.type){
        case GET_TRANSACTION : {
            return{
                ...state,
                transactions: action.payload
            }
        }

        case GET_COUPONS : {
            return{
                ...state,
                coupons : action.payload
            }
        }

        case CREATE_COUPONS : {
            return {
                ...state,
                coupons : action.payload
            }
        }

        case GET_SCRATCH_CARD : {
            return {
                ...state,
                scratchCard : action.payload
            }
        }

        case ADD_SCRATCH_CARD : {
            return {
                ...state,
                scratchCard : action.payload
            }
        }

        case GET_COUPON_BY_ID : {
            return {
                ...state,
                CouponDetail: action.payload
            }
        }

        case GET_SCRATCH_CARD_BY_ID : {
            return {
                ...state,
                ScratchCardDetail : action.payload
            }
        }
        
        case CLEAR_COUPON_DETAIL : {
            return {
                ...state,
                CouponDetail : [],
                coupons : []
            }
        }

        case CLEAR_SCRATCH_CARD_DETAIL : {
            return {
                ...state,
                ScratchCardDetail : []
            }
        }

        case EDIT_COUPON : {
            return {
                ...state,
                coupons : action.payload
            }
        }

        case EDIT_SCRATCH_CARD : {
            return {
                ...state,
                scratchCard : action.payload
            }
        }

        case SEARCH_COUPON : {
            return {
                ...state,
                coupons : action.payload
            }
        }

        case SEARCH_SERATCH_CARD : {
            return {
                ...state,
                scratchCard : action.payload
            }
        }

        case SEARCH_TRANSACTION : {
            return {
                ...state,
                transactions : action.payload
            }
        }

        case GET_SINGLE_PAYOUT : {
            return {
                ...state,
                singlePayout : action.payload
            }
        }

        default : {
            return {
                ...state
            }
        }
    }

}