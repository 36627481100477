import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faSearch, faPlusCircle, faTrash, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col,InputGroup, Row, Nav, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import { Breadcrumb } from "react-bootstrap";
import { Link , useHistory} from "react-router-dom";
import { Routes } from "../../../routes";
import {useDispatch, useSelector} from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Outcome(){

    const [outcome, setOutcome] = useState({key: "", value: ""})
    const [results, setResult] = useState([])

    const addOutcome = () => {
        let tempOutcome = []
        tempOutcome.push(...results, {key: "", value: ""})
        setResult(tempOutcome)
    }

    const cancel = (i) => {
        const values = [...results];
        values.splice(i, 1)
        setResult(values)
    }

    const [rules, setRules] = useState([])

    const addRule = (type) => {
        let addRule = []
        if(type == 'rule'){
            addRule.push(...rules, {key : '', operator : '', value: ''})
            setRules(addRule)
        }else{
            addRule.push(...rules, {any : [{key : '', operator : '', value: ''}]})
            setRules(addRule)
        }
        
    }
    
    const saveRule = () => {
        
    }


    return (
    <>
            <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                        <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                            <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                            <Breadcrumb.Item href="#business-logic">Business Logic</Breadcrumb.Item>
                            <Breadcrumb.Item className="text-dark" active>Outcomes</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
    
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded gamestarz">
                <div className="row mb-4 px-4">
                    {/* <div className="col col-lg-6 col-md-12 text-left">
                        <h4>Outcome</h4>
                    </div> */}
                </div>

                <div className="container-fluid payout mb-5">

                    <Row className='d-flex align-items-center my-5'>

                        <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                            <select className='form-select'>
                                <option>AND</option>
                                <option>OR</option>
                            </select>
                        </Col>

                        <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                           <button className='btn btn-sm btn-success mx-2' onClick={()=>{addRule('rule')}}>+ Add Rule</button>
                           <button className='btn btn-sm btn-success mx-2' onClick={()=>{addRule('group')}}>+ Add Group</button>
                        </Col>

                    </Row>  

                    {/* <Row id='rule-builder' className='container-rule-Builder'>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Row id='rule-container' className='rules'>
                                
                            </Row>
                        </Col>
                    </Row> */}

                    {rules && rules.map((e, i) => {
                        return(
                            <>
                                <Row key={i} className='my-2'>
                                    <Col>

                                        <Row>
                                            <Col>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <select className='form-select'>
                                                    <option>Attribute One</option>
                                                    <option>Attribute Two</option>
                                                    <option>Attribute Three</option>
                                                </select>
                                            </Col>

                                            <Col>
                                                <select className='form-select'>
                                                    <option>operator One</option>
                                                    <option>operator Two</option>
                                                    <option>operator Three</option>
                                                </select>
                                            </Col>

                                            <Col>
                                                <input type='text' className='form-control' />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                    )} 

                    

                    <Row className='my-2'>
                        <Col>
                            <button className='btn btn-success' onClick={saveRule}>Save</button>
                        </Col>
                    </Row>
                              

                </div>
            </div>
        </>
  )
}
