import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import { Link} from "react-router-dom";
import { Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from 'react-redux';
import '../../css/games.css'

function ViewLeaderboardDetail({show, onHide}) {
    
    const close = () => {
        onHide();
    }

    let LeaderboardData = useSelector((state)=>{ 
        return state.leaderboard.leaderboardNew
    })

 
    
    if(LeaderboardData.length){
        return (
            <div>
                <Modal scrollable={true} size="xl" centered show={show} onHide={onHide} className='mainLeaderboard'>
                <Modal.Header>
                    <div className="modal_head">
                        <Row className="align-items-center">
                            {LeaderboardData[0].game === null || LeaderboardData[0].game === "" ?
                            "" : 
                            <Col lg={2}>
                                <img alt="Game_Logo" src={LeaderboardData[0].game.gameLogo && LeaderboardData[0].game.gameLogo != "" ? LeaderboardData[0].game.gameLogo : ""} width="300"></img>
                            </Col>
                            }
                            <Col lg={10}>
                                <h6 className="pt-2">{LeaderboardData[0].leaderboardName}</h6>
                            </Col>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12}>
                            <h6 className='body-title'>Leaderboard Details</h6>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col lg={6} md={12} sm={12}>
                            <p>Leaderboard Id</p>
                            <input disabled className='form-control' defaultValue={LeaderboardData[0].id} />
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                            <p>Leaderboard Name</p>
                            <input disabled className='form-control' defaultValue={LeaderboardData[0].leaderboardName} />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col lg={6} md={12} sm={12}>
                            <p>Game Id</p>
                            <input disabled className='form-control' defaultValue={LeaderboardData[0].gameId && LeaderboardData[0].gameId != "" || LeaderboardData[0].gameId != null ? LeaderboardData[0].gameId  : "No Info"}/>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                            <p>Game Name</p>	
                            <input disabled className='form-control' defaultValue={LeaderboardData[0].game === "" || LeaderboardData[0].game === null ?  "No Info" : LeaderboardData[0].game.gameName} />
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col lg={6} md={12} sm={12}>
                            <p>Game World</p>
                            <input disabled className='form-control' defaultValue={LeaderboardData[0].gameworld === null || LeaderboardData[0].gameworld === "" ? "No Info" : LeaderboardData[0].gameworld.gameWorldName}/>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                            <p>Game Genre</p>
                            <input disabled className='form-control' defaultValue={LeaderboardData[0].game === null || LeaderboardData[0].game === "" ? "No Info" : LeaderboardData[0].game.gameGenre}/>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col lg={12}>
                            <h6 className='body-title'>Match Details</h6>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col lg={12} md={12} sm={12}>
                        {LeaderboardData[0] ?
                        <table className="table table-striped text-center table-custom">
                            <thead className='table-head'>
                                <tr>
                                    <th className='head-border-left' scope="col">Match Name</th>
                                    <th scope="col">Match Format</th>
                                    <th scope="col">Minimum Players</th>
                                    <th scope="col">Maximum Players</th>
                                    <th scope="col">MatchOutcome Type</th>
                                    <th className='head-border-right' scope="col">LeaderboardOutcome Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='table-body'>
                                    <td scope="col">{LeaderboardData[0].match != null ? LeaderboardData[0].match.matchName : "-"}</td>
                                    <td scope="col">{LeaderboardData[0].match != null && LeaderboardData[0].match.matchFormatTypeMasterId === 1 ? "Single Player"  : LeaderboardData[0].match != null && LeaderboardData[0].match.matchFormatTypeMasterId === 2 ? "Multiplayer" : "-"}</td>
                                    <td scope="col">{LeaderboardData[0].match != null ? LeaderboardData[0].match.matchMinPlayers : "-"}</td>
                                    <td scope="col">{LeaderboardData[0].match != null ? LeaderboardData[0].match.matchMaxPlayers : "-"}</td>
                                    <td scope="col">{LeaderboardData[0].match != null && LeaderboardData[0].match.matchOutcomeTypeMasterId === 1 ? "High Score" : LeaderboardData[0].match != null && LeaderboardData[0].match.matchOutcomeTypeMasterId === 2 ? "Time Trail" : LeaderboardData[0].match != null && LeaderboardData[0].match.matchOutcomeTypeMasterId === 3 ? "Win/Loss/Draw" : LeaderboardData[0].match != null && LeaderboardData[0].match.matchOutcomeTypeMasterId ? "Podium Finish" : LeaderboardData[0].match != null && LeaderboardData[0].match.matchOutcomeTypeMasterId ? "Winning  Collected" : "-"}</td>
                                    <td scope="col">{LeaderboardData[0].match != null && LeaderboardData[0].match.leaderboardOutcomeTypeMasterId === 1 ? "High Score" : LeaderboardData[0].match != null && LeaderboardData[0].match.leaderboardOutcomeTypeMasterId === 2 ? "Time Trail" : LeaderboardData[0].match != null && LeaderboardData[0].match.leaderboardOutcomeTypeMasterId === 3 ? "Win/Loss/Draw" : LeaderboardData[0].match != null && LeaderboardData[0].match.leaderboardOutcomeTypeMasterId === 4 ? "Podium Finish" : LeaderboardData[0].match != null && LeaderboardData[0].match.leaderboardOutcomeTypeMasterId === 5 ? "Winning  Collected" : "-"}</td>
                                </tr>
                            </tbody>
                        </table> : "No Matches Available."}
                        </Col>
                    </Row>

                </Modal.Body>
            <Modal.Footer className='leadeboard-footer'>
                <Row>
                    <Col>
                        <Button className="btn btn-danger btn -sm mx-2" onClick={close}>Cancel</Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal> 
        </div>
    )
    }else{
        return(
            <p></p>
        )
    }
}

export default ViewLeaderboardDetail