import React, {useEffect, useState} from 'react'
import {Row, Col, ButtonGroup, Button, InputGroup, Form, Nav} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch, faRetweet} from '@fortawesome/free-solid-svg-icons';
import FilterModal from './modals/Filters/FilterLeaderboard';
import axios from '../utlis/interceptor';
import { getAllLeaderboardAction, getLeaderboardDetailsAction, getUniversalLeaderboardAction, clearLeaderboardAction, searchLeaderBoardDetailsAction, searchUniLeaderboardAction } from '../redux/action/leaderboardAction';
import { useDispatch , useSelector} from 'react-redux';
import { DateRangePicker } from "react-date-range";
import { addDays } from 'date-fns';
import Pagination from '@mui/material/Pagination'
import {Leaderboards} from '../constants/LabelConstants'
import './css/games.css'
import moment from 'moment';
import { BASE_URL } from '../resources/APIEndpoints';
import { CSVLink, CSVDownload } from "react-csv";
import { Breadcrumb } from "react-bootstrap";
import './css/games.css'
import refreshIcon from './Images/refresh.png'

function LeaderboardDetail(props) {

    const dispatch = useDispatch();
    const [state, setState] = useState({
        selection1: {
          startDate: null,
          endDate: null,
          key: 'selection1'
        },
    });

    const [page, setPage] = useState(1)
    const [counts , setCounts] = useState()
    const [posts, setPosts] = useState([])
    const [limit, setLimit] = useState(50)
    const [dateStart, setDate] = useState(new Date().toISOString().slice(0, 10))

    const [activeClass, setActiveClass] = useState(true)

    const [initailStartDate, setStartDate] = useState("2020-12-31")
    const [intervalId, setIntervalId] = useState(5)

    useEffect(() => {
        
        if(props.location.id && props.location.matchId != undefined){
            const data = {
                leaderboardId : props.location.id,
                getLeaderboardDetailsFilters : {
                    intervalId : intervalId,
                    startsFrom : initailStartDate
                },
                pagination : {
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }

            dispatch(getLeaderboardDetailsAction(data))

            const newData = {
                leaderboardId : props.location.id,
                getLeaderboardDetailsFilters : {
                    intervalId : intervalId,
                    startsFrom : initailStartDate
                },
                pagination : {

                }
            }
            axios.post(`${BASE_URL}/leaderboard/getLeaderboardDetails`, newData)
            .then(res=>{        
                let pageNo = Math.ceil(res.data.leaderboardData.length/limit)
                setCounts(pageNo);
            })

        }else if(props.location.matchId === undefined){
            const data = {
                leaderboardId : props.location.id,
                getUniversalLeaderboardDetailsFilters : {
                    intervalId : intervalId,
                    startsFrom : initailStartDate
                },
                pagination : {
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }

            dispatch(getUniversalLeaderboardAction(data))

            const newData = {
                leaderboardId : props.location.id,
                getUniversalLeaderboardDetailsFilters : {
                    intervalId : intervalId,
                    startsFrom : initailStartDate
                },
                pagination : {

                }
            }
            axios.post(`${BASE_URL}/leaderboard/getUniversalLeaderboardDetails`, newData)
            .then(res=>{        
                let pageNo = Math.ceil(res.data.universalLeaderboardData.length/limit)
                setCounts(pageNo);
            })
        }
        
    }, [page, dateStart, limit, intervalId])

    
    let leaderboard = useSelector((state)=>{ 
        return state.leaderboard.leaderboardDetail
    })

    let universalLeaderboard = useSelector((state)=>{ 
        return state.leaderboard.UniversalLeaderboardDetail
    })

    const allTime = (range) => {
        setActiveClass(true)
        setIntervalId(5)
        setState({ ...state, ...range })

        if(props.location.id && props.location.matchId != undefined){
            let filterData = {
                leaderboardId : props.location.id,
                getLeaderboardDetailsFilters : {
                    intervalId : intervalId,
                    startsFrom : "2020-12-31",
                },
                pagination : {
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
            dispatch(getLeaderboardDetailsAction(filterData))
        }else if(props.location.matchId === undefined){
            let filterData = {
                leaderboardId : props.location.id,
                getUniversalLeaderboardDetailsFilters : {
                    intervalId : intervalId,
                    startsFrom : "2020-12-31",
                },
                pagination : {
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
            dispatch(getUniversalLeaderboardAction(filterData))
        }
    }
    
    const onhandleChange = (range) => {

        setActiveClass(false)
        setState({ ...state, ...range })

        let startDate = range.selection1.startDate
        let endDate = range.selection1.endDate

        var date1 = range.selection1.startDate;
        var date2 = range.selection1.endDate;

        var Difference_In_Time = date2.getTime() - date1.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        const initialDate = new Date(startDate.getTime()+startDate.getTimezoneOffset() * - 60000).toISOString().slice(0, 10);
        setStartDate(initialDate)

        if(Math.ceil(Difference_In_Days) <= 1){
            if(props.location.id && props.location.matchId != undefined){
                setIntervalId(1)
                let filterData = {
                    leaderboardId : props.location.id,
                    getLeaderboardDetailsFilters : {
                        intervalId : 1,
                        startsFrom : initialDate,
                    },
                    pagination : {
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
                dispatch(getLeaderboardDetailsAction(filterData))
            }else{
                let filterData = {
                    leaderboardId : props.location.id,
                    getUniversalLeaderboardDetailsFilters : {
                        intervalId : 1,
                        startsFrom : initialDate,
                    },
                    pagination : {
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
                dispatch(getUniversalLeaderboardAction(filterData))
            }


        }else if(Math.ceil(Difference_In_Days) > 1 && Math.ceil(Difference_In_Days) <= 7){
            setIntervalId(2)
            if(props.location.id && props.location.matchId != undefined){
                let filterData = {
                    leaderboardId : props.location.id,
                    getLeaderboardDetailsFilters : {
                        intervalId : 2,
                        startsFrom : initialDate,
                    },
                    pagination : {
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
                dispatch(getLeaderboardDetailsAction(filterData))
            }else{
                let filterData = {
                    leaderboardId : props.location.id,
                    getUniversalLeaderboardDetailsFilters : {
                        intervalId : 2,
                        startsFrom : initialDate,
                    },
                    pagination : {
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
                dispatch(getUniversalLeaderboardAction(filterData))
            }
        }else if(Math.ceil(Difference_In_Days) > 7 && Math.ceil(Difference_In_Days) <= 31){
            setIntervalId(3)
            if(props.location.id && props.location.matchId != undefined){
                let filterData = {
                    leaderboardId : props.location.id,
                    getLeaderboardDetailsFilters : {
                        intervalId : 3,
                        startsFrom : initialDate,
                    },
                    pagination : {
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
                dispatch(getLeaderboardDetailsAction(filterData))
            }else{
                let filterData = {
                    leaderboardId : props.location.id,
                    getUniversalLeaderboardDetailsFilters : {
                        intervalId : 3,
                        startsFrom : initialDate,
                    },
                    pagination : {
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
                dispatch(getUniversalLeaderboardAction(filterData))
            }
        }
       
    }

    const [isShow, setIsshow] = useState(false)

    const showFilter = () => {
        setIsshow(!isShow)
    }

    const searchUnileaderboard = (e) => {
        if(e.target.value == ""){
            let data = {
                leaderboardId : props.location.id,
                getUniversalLeaderboardDetailsFilters : {
                    intervalId : intervalId,
                    startsFrom : initailStartDate
                },
                pagination : {
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
            dispatch(getUniversalLeaderboardAction(data))
        }
        else{
            let playerId = e.target.value
            let search = {
                leaderboardId: props.location.id,
                getUniversalLeaderboardDetailsFilters: {
                    intervalId: intervalId,
                    startsFrom: initailStartDate
                },
                playerId: e.target.value
            }
        dispatch(searchUniLeaderboardAction(search))
        }
    }

    const searchLeaderboardDetails = (e) => {
        if(e.target.value == ""){
            let data = {
                leaderboardId : props.location.id,
                getLeaderboardDetailsFilters : {
                    intervalId : intervalId,
                    startsFrom : initailStartDate
                },
                pagination : {
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }

            dispatch(getLeaderboardDetailsAction(data))
        }else{
            let playerId = e.target.value
            let search = {
                leaderboardId: props.location.id,
                getLeaderboardDetailsFilters: {
                    intervalId: intervalId,
                    startsFrom: initailStartDate
                },
                playerId: playerId
            }

        dispatch(searchLeaderBoardDetailsAction(search))
        }
    }

    const setPageLimit = (e) => {
        setPage(1)
        setLimit(parseInt(e.target.value))
    }

    const csvReport = {
        filename : `Leaderboard_Report_${new Date().toISOString().slice(0, 10)}.csv`,
        headers : "",
        data : ""
    }

    const onRefresh = () => {
        if(props.location.id && props.location.matchId != undefined){
            const data = {
                leaderboardId : props.location.id,
                getLeaderboardDetailsFilters : {
                    intervalId : intervalId,
                    startsFrom : initailStartDate
                },
                pagination : {
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }

            dispatch(getLeaderboardDetailsAction(data))

            let newData = {
                leaderboardId : props.location.id,
                getLeaderboardDetailsFilters : {
                    intervalId : intervalId,
                    startsFrom : initailStartDate
                },
                pagination : {

                }
            }
            axios.post(`${BASE_URL}/leaderboard/getLeaderboardDetails`, newData)
            .then(res=>{        
                let pageNo = Math.ceil(res.data.leaderboardData.length/limit)
                setCounts(pageNo);
            })

        }else if(props.location.matchId === undefined){
            const data = {
                leaderboardId : props.location.id,
                getUniversalLeaderboardDetailsFilters : {
                    intervalId : intervalId,
                    startsFrom : initailStartDate
                },
                pagination : {
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }

            dispatch(getUniversalLeaderboardAction(data))

            let newData = {
                leaderboardId : props.location.id,
                getUniversalLeaderboardDetailsFilters : {
                    intervalId : intervalId,
                    startsFrom : initailStartDate
                },
                pagination : {

                }
            }
            axios.post(`${BASE_URL}/leaderboard/getUniversalLeaderboardDetails`, newData)
            .then(res=>{        
                let pageNo = Math.ceil(res.data.universalLeaderboardData.length/limit)
                setCounts(pageNo);
            })
        }
    }

    const [disableExport, setDisableExport] = useState(false)

    const exportData = () => {
        setDisableExport(true)
        setTimeout(() => {
          setDisableExport(false)
        }, 5000);
     }

    if(leaderboard && props.location.matchId != undefined){
        return (
            <>
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>

                    </Col>
                </Row>
            </div>
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
                    <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                        <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                        <Breadcrumb.Item href='#main-leaderboard'>Leaderboard</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-dark" active>{props.location.leaderboardName}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col xs={12} lg={6} className="px-0 mb-0 text-right">
                    <Button disabled={disableExport ? true : false} onClick={exportData} className="export"><CSVLink {...csvReport}>Export</CSVLink></Button>
                </Col>
            </div>
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5 mainLeaderboard">
                <Row className="container-fluid m-auto">
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="row d-flex align-items-center">
                            <div className="col col-md-4">
                                <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                    <Form.Control type="text" placeholder="Search By PlayerId" onChange={(e)=>{searchLeaderboardDetails(e)}}/>
                                </InputGroup>
                            </div>
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="col col-md-12 text-right mb-2">
                            <span className='filter' style={{cursor: "pointer", paddingRight: "10px"}} onClick={showFilter}><i class="fas fa-filter mx-2"></i><span>Filter</span></span>
                        </div>
                    </Col>
                </Row>
                <Row className='container-fluid d-flex align-items-center justify-content-between m-auto py-4'>
                    <Col lg={8} md={8} sm={10}>
                        <h4 className='page-heading'>{props.location.leaderboardName}</h4>
                    </Col>
                    <Col lg={4} md={4} sm={2} className='text-right'>
                        <span>
                            <button className='refresh'  onClick={onRefresh}>
                                <span><img src={refreshIcon} className='img-responsive' width={20} height={20} /></span>
                            </button>
                        </span>
                    </Col>
                </Row>
                {isShow === true ?
                <div>
                    <Row className="d-flex justify-content-left text-left">
                        <Col lg={4} md={4} sm={4}>
                        </Col>
                        <Col lg={8} md={8} sm={8}>
                            <Button className={activeClass === true ? "btn-sm btn-light mb-1 btn-active"  : activeClass === false ? "btn-sm btn-light mb-1" : ""}  style={{color: "#000 !important"}} onClick={(e)=>{allTime(e)}}>All Time</Button>
                        </Col>
                    </Row>
                    <Row className="d-flex">
                        <Col lg={4} md={4} sm={4}>
                        </Col>
                        <Col lg={8} md={8} sm={8}>
                            <DateRangePicker onChange={(e)=>{onhandleChange(e)}}
                            ranges={[state.selection1]} />
                        </Col>
                    </Row>
                </div>
                : ""}
                {leaderboard && leaderboard.length != 0 ?
                    <Row className='container-fluid table-responsive m-auto'>
                        <Col lg={12} md={12} sm={12}>
                            <table className="table table-striped text-center table-custom">
                                <thead className='table-head'>
                                    <tr>
                                        <th className='head-border-left' scope="col">{Leaderboards.Player_Rank}</th>
                                        <th scope="col">{Leaderboards.Player_ID}</th>
                                        <th scope='col'>USER NAME</th>
                                        <th className='head-border-right' scope="col">{Leaderboards.Player_Score}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leaderboard && leaderboard[0] != null && leaderboard.length > 0 && leaderboard.map((e, i)=>{
                                        return(
                                            <tr className='table-body'>
                                                <td>{e.ranks && e.ranks != null ? e.ranks : ''}</td>
                                                <td>{e.user && e.user != null ? e.user.id : ''}</td>
                                                <td>{e.user && e.user != null ? e.user.username : ''}</td>
                                                <td>{e.score && e.score != null ? e.score : ''}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                : <p className="container-fluid">No Data Available</p>}
                <div className="d-flex justify-content-between m-4">
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Pagination count={counts} 
                            defaultPage={1} 
                            color="info" className='paginate'
                            variant="outlined" shape="rounded"
                            showFirstButton={false} 
                            showLastButton={false}  
                            onChange={(event, value)=> setPage(value)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12} className='text-right'>
                            <label>No Of Entries Per Page</label> <span>&nbsp; &nbsp;</span>
                            <select onChange={(e)=>{setPageLimit(e)}} className='entries'>
                                <option value='10'>10</option>
                                <option value='50' selected>50</option>
                                <option value='100'>100</option>
                            </select>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
        )
    }else if(universalLeaderboard && props.location.matchId === undefined){
        return (
            <>
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>

                    </Col>
                </Row>
            </div>
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                        <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                        <Breadcrumb.Item href='#main-leaderboard'>Leaderboard</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-dark" active>{props.location.leaderboardName}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
    
                <Col xl={6} lg={6} md={6} sm={12} xs={12} className="px-0 mb-0 text-right">
                    <Button disabled={disableExport ? true : false} onClick={exportData} className="export"><CSVLink {...csvReport}>Export</CSVLink></Button>
                </Col>
            </div>
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5 mainLeaderboard">
                <Row className="container-fluid m-auto mb-5">
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="row d-flex align-items-center">
                            <div className="col col-md-6">
                                <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                    <Form.Control type="text" placeholder="Search By PlayerId" onChange={(e)=>{searchUnileaderboard(e)}}/>
                                </InputGroup>
                            </div>
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="col col-md-12 text-right mb-2">
                            <span className='filter' style={{cursor: "pointer", paddingRight: "10px"}} onClick={showFilter}><i class="fas fa-filter mx-2"></i><span>Filter</span></span>
                        </div>
                    </Col>
                </Row>
                <Row className='container-fluid d-flex align-items-center justify-content-between m-auto py-3'>
                    <Col lg={8} md={8} sm={10}>
                        <h4 className='page-heading'>{props.location.leaderboardName}</h4>
                    </Col>
                    <Col lg={4} md={4} sm={2} className='text-right'>
                        <span>
                            <button className='refresh'  onClick={onRefresh}>
                                <span><img src={refreshIcon} className='img-responsive' width={20} height={20} /></span>
                            </button>
                        </span>
                    </Col>
                </Row>
                {isShow === true ?
                <div>
                    <Row className="d-flex justify-content-left text-left">
                        <Col lg={4} md={4} sm={4}>
                        </Col>
                        <Col lg={8} md={8} sm={8}>
                            <Button className={activeClass === true ? "btn-sm btn-white mb-1 btn-active"  : activeClass === false ? "btn-sm btn-white mb-1 btn-font" : ""} style={{color: "#000 !important"}} onClick={(e)=>{allTime(e)}}>All Time</Button>
                        </Col>
                    </Row>
                    <Row className="d-flex">
                        <Col lg={4} md={4} sm={4}>
                        </Col>
                        <Col lg={8} md={8} sm={8}>
                            <DateRangePicker onChange={(e)=>{onhandleChange(e)}}
                            ranges={[state.selection1]} />
                        </Col>
                    </Row>
                </div>
                :""}
                { universalLeaderboard && universalLeaderboard.length != 0 ?
                <Row className="container-fluid table-responsive m-auto">
                    <Col lg={12} md={12} sm={12}>
                        <table className="table table-striped text-center table-custom">
                             <thead className='table-head'>
                                <tr>
                                    <th className='head-border-left' scope="col">{Leaderboards.Player_Rank}</th>
                                    <th scope="col">{Leaderboards.Player_ID}</th>
                                    <th scope='col'>USER NAME</th>
                                    <th className='head-border-right' scope="col">{Leaderboards.Playstarz_Points}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {universalLeaderboard && universalLeaderboard[0] != null && universalLeaderboard.map((e, i)=>{
                                return(
                                    <tr className='table-body'>
                                        <td>{e.ranks && e.ranks != null && e.ranks != '' ? e.ranks : ''}</td>
                                        <td>{e.users && e.users != null ? e.users.id : ''}</td>
                                        <td>{e.users && e.users != null ? e.users.username : ''}</td>
                                        <td>{e.playstarz_point && e.playstarz_point != null ? e.playstarz_point : ''}</td>
                                    </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Col>
                </Row>
                :-universalLeaderboard[0] == null ? <p className="container-fluid">No Data Available</p> : ""}
                <div className="d-flex justify-content-between m-4">
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12} className='text-left'>
                            <Pagination count={counts} 
                            defaultPage={1} 
                            color="info" className='paginate'
                            variant="outlined" shape="rounded"
                            showFirstButton={false} 
                            showLastButton={false}  
                            onChange={(event, value)=> setPage(value)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12} className='text-right'>
                            <label>No Of Entries Per Page</label> <span>&nbsp; &nbsp;</span>
                            <select onChange={(e)=>{setPageLimit(e)}} className='entries'>
                                <option value='10'>10</option>
                                <option value='50' selected>50</option>
                                <option value='100'>100</option>
                            </select>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
        )
    }
    else{
        return(
            <p></p>
        )
    }

}

export default LeaderboardDetail;