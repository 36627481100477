import React, { useEffect, useState } from 'react'
import {Row, Col, ButtonGroup, Button, InputGroup, Form, Nav, Dropdown} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch, faPlus, faPlusCircle, faEdit, faEllipsisH, faEye, faArchive, faTrash, faBan, faArrowAltCircleUp} from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../routes";
import FilterModal from './modals/Filters/FilterLeaderboard';
import PDFilterModal from './modals/Filters/PDFilterModal';
import { Link, Route } from 'react-router-dom';
import { getAllLeaderboardAction , getAllPDAction, getLeaderboardByIdAction, searchLeaderBoardAction} from '../redux/action/leaderboardAction';
import { useDispatch , useSelector} from 'react-redux';
import { Leaderboards } from '../constants/LabelConstants';
import ViewLeaderboardDetail from './modals/LeaderboardsModal/ViewLeaderboardDetail';
import StopPDModal from './modals/LeaderboardsModal/stopPDModal';
import DeletePD from './modals/LeaderboardsModal/DeletePD';
import ViewPDDetails from './modals/LeaderboardsModal/viewPDDetails';
import Pagination from '@mui/material/Pagination'
import axios from '../utlis/interceptor';
import './css/games.css'
import { BASE_URL, GET_LEADERBOARD } from '../resources/APIEndpoints';
import { CSVLink, CSVDownload } from "react-csv";
import { Breadcrumb } from "react-bootstrap";

export default function LeaderboardMain(props) {
    const [filter, setFilter] = useState(false)
    const [pdfilter, setPDFilter] = useState(false)
    const dispatch = useDispatch();
    const showFilter = () => {
        setFilter(!filter)
    }
    
    const showPDFilter = () => {
        setPDFilter(!pdfilter)
    }

    const [page, setPage] = useState(1)
    const [counts , setCounts] = useState()
    const [posts, setPosts] = useState([])
    const [limit, setLimit] = useState(30)

    const [isCore, setIsCore] = useState(true)
    const [isLeaderboard, setIsLeaderboard] = useState(false)
    const [sortLeaderboard, setSortLeaderboard] = useState({createdAt : "ASC"})
    
    const oncoreLeaderboard = () => {
        setIsCore(true)
        setIsLeaderboard(false)
        setIsCustomeLeaderboard(false)
        let data = {
            getLeaderboardFilters : {
                leaderboardSort : sortLeaderboard,
                isDefault : [true],
                pagination : {
                  offset : 0,
                  limit : limit
                }
            }
        }
        dispatch(getAllLeaderboardAction(data));

        let dataNew = {
            getLeaderboardFilters : {
                isDefault : [true],
                pagination : {
        
                }
            }
        }
        axios.post(`${BASE_URL}/leaderboard/getLeaderboard`, dataNew)
        .then(res=>{
          const pageNo = Math.ceil(res.data.leaderboardData.length/limit)
          setCounts(pageNo)
        })
    }
    
    const ondefaultLeaderboard = () => {
        setIsCore(false)
        setIsLeaderboard(true)
        setIsCustomeLeaderboard(false)
        let data = {
            getLeaderboardFilters : {
                leaderboardSort : sortLeaderboard,
                isDefault : [false],
                pagination : {
                  offset : 0,
                  limit : limit
                }
            }
        }
        dispatch(getAllLeaderboardAction(data));

        let dataNew = {
            getLeaderboardFilters : {
                isDefault : [false],
                pagination : {
        
                }
            }
        }
        axios.post(`${BASE_URL}/leaderboard/getLeaderboard`, dataNew)
        .then(res=>{
          const pageNo = Math.ceil(res.data.leaderboardData.length/limit)
          setCounts(pageNo)
        })
    }

    const[isCustomeLeaderboard, setIsCustomeLeaderboard] = useState(false)

    const onCustomLeaderboard = () => {
        setIsCore(false)
        setIsLeaderboard(false)
        setIsCustomeLeaderboard(true)

        let data = {
            getLeaderboardFilters : {
                leaderboardSort : sortLeaderboard,
                isDefault : [false],
                pagination : {
                  offset : 0,
                  limit : 10000
                }
            }
        }
        dispatch(getAllLeaderboardAction(data));

        let dataNew = {
            getLeaderboardFilters : {
                isDefault : [false],
                pagination : {
        
                }
            }
        }
        axios.post(`${BASE_URL}/leaderboard/getLeaderboard`, dataNew)
        .then(res=>{
          const pageNo = Math.ceil(res.data.leaderboardData.length/limit)
          setCounts(pageNo)
        })
    }

    useEffect(() => {
        let data = {
          getLeaderboardFilters : {
            isDefault : [isCore],
            leaderboardSort : sortLeaderboard,
            pagination : {
                offset : ((page-1)*limit),
                limit : limit
            }
          }
        }

        const PD = {
            getPrizeDistrubutedLeaderboardFilters : {
                pagination : {

                }
            }
        }

        async function getAllLeaderboard() {
          dispatch(getAllLeaderboardAction(data));
          dispatch(getAllPDAction(PD))
        }
        getAllLeaderboard();
      }, [page]);

      useEffect(()=>{
        let dataNew = {
            getLeaderboardFilters : {
                isDefault : [true],
                pagination : {
        
                }
            }
        }
        axios.post(`${BASE_URL}/leaderboard/getLeaderboard`, dataNew)
        .then(res=>{
          const pageNo = Math.ceil(res.data.leaderboardData.length/limit)
          setCounts(pageNo)
        })
        
      }, [])

      let Leaderboard = useSelector((state)=>{ 
            return state.leaderboard.leaderboards
      })

      let PrizeDistribution = useSelector((state)=>{
          return state.leaderboard.playerDistribution
      })
      
      const leaderboardFilters = (Orientation, world, matchFormat, platform, matchOutcome, leaderboardOutcome) => {
        const fileredData = {
            leaderboardFilters : {
              isGameScreenOrientationLandscape : Orientation,
              gameWorldId : world,
              matchFormatTypeId : matchFormat,
              gamePlatforms : platform,
              matchOutcomeTypeId : matchOutcome,
              pagination : {}
            }
        };
        dispatch(getAllLeaderboardAction(fileredData))
      }

      const SearchLeaderboard = (e) => {
          if(e.target.value === ""){
            const data = {
                getLeaderboardFilters : {
                    leaderboardSort : sortLeaderboard,
                    isDefault : [isCore],
                    pagination : {
                      offset : ((page-1)*limit),
                      limit : limit
                    }
                }
              }
             dispatch(getAllLeaderboardAction(data));
          }else{
            let searchLeaderboard = {
                leaderboardName : e.target.value
            }
            dispatch(searchLeaderBoardAction(searchLeaderboard))
          }
      }

    const [gamedetail, setGamedetail] = useState(false)
    
    const onToggleGameDetail = (leaderboardId) => { 
        setGamedetail(!gamedetail)
        const leaderboardData = {
            getLeaderboardFilters : {
                id : [leaderboardId],
                pagination : {

                }
            }
        }
        dispatch(getLeaderboardByIdAction(leaderboardData))
    }


    const [pdDetail, setPdDetail] = useState(false)
    const onPDDetail = () => {
        setPdDetail(!pdDetail)
    }

    const [showdstopmodal, setShowstopmodel] = useState(false)
    const onToggleStop = () => {
        setShowstopmodel(!showdstopmodal)
    }

    const [showdeletemodal, setShowdeletemodal] = useState(false)
    const onToggleDelete = () => {
      setShowdeletemodal(!showdeletemodal)
    }

    const onLeaderboardSort = (e) => {
        let leaderboardData = {}
        if(e.target.value === 'AtoZ'){
            setSortLeaderboard({leaderboardName : "ASC"});
            leaderboardData = {
                getLeaderboardFilters : {
                    isDefault : [isCore],
                    leaderboardSort : {leaderboardName : "ASC"},
                    pagination :{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
            }
        }else if(e.target.value === 'ZtoA'){
            setSortLeaderboard({leaderboardName : "DESC"});
            leaderboardData = {
                getLeaderboardFilters : {
                    isDefault : [isCore],
                    leaderboardSort : {leaderboardName : "DESC"},
                    pagination :{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
            } 
        }else if(e.target.value === 'latest'){
            setSortLeaderboard({createdAt : "ASC"})
            leaderboardData = {
                getLeaderboardFilters : {
                    isDefault : [isCore],
                    leaderboardSort : {createdAt : "ASC"},
                    pagination :{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
            } 
        }else if(e.target.value === 'oldest'){
            setSortLeaderboard({createdAt : "DESC"})
            leaderboardData = {
                getLeaderboardFilters : {
                    isDefault : [isCore],
                    leaderboardSort : {createdAt : "DESC"},
                    pagination :{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
            }
        }
        // else if(e.target.value === 'gameName'){
        //     setSortLeaderboard({gameName : "ASC"})
        //     leaderboardData = {
        //         getLeaderboardFilters : {
        //             isDefault : [isCore],
        //             leaderboardSort : {gameName : "ASC"},
        //             pagination :{
        //                 offset : ((page-1)*limit),
        //                 limit : limit
        //             }
        //         }
        //     }
        // }else if(e.target.value === 'matchName'){
        //     setSortLeaderboard({matchName : "ASC"})
        //     leaderboardData = {
        //         getLeaderboardFilters : {
        //             isDefault : [isCore],
        //             leaderboardSort : {matchName : "ASC"},
        //             pagination :{
        //                 offset : ((page-1)*limit),
        //                 limit : limit
        //             }
        //         }
        //     }
        // }else if(e.target.value === 'status'){
        //     setSortLeaderboard({status : "ASC"})
        //     leaderboardData = {
        //         getLeaderboardFilters : {
        //             isDefault : [isCore],
        //             leaderboardSort : {status : "ASC"},
        //             pagination :{
        //                 offset : ((page-1)*limit),
        //                 limit : limit
        //             }
        //         }
        //     }
        // }
        dispatch(getAllLeaderboardAction(leaderboardData))
    }

    const [showPD, setShowPD] = useState(false)

    const showPDLeaderboard = () =>{
        setShowPD(!showPD)
    }

    const [allLeaderboard, setAllLeaderboard] = useState()

    useEffect(()=>{
        let leaderboardAll = {
            getLeaderboardFilters: {
                pagination:{

                }
            }
        }
        axios.post(BASE_URL + GET_LEADERBOARD, leaderboardAll)
        .then(res=>{
            setAllLeaderboard(res.data.leaderboardData)
        })
    }, [])

    const headers = [
        { label : 'Leaderboard Id', key : 'id'},
        { label : 'Leaderboard Name', key : 'leaderboardName'},
        { label : 'Game Id', key : 'gameId'},
        { label : 'Game Name', key : 'game.gameName'},
        { label : 'Match Id', key : 'match.id'},
        { label : 'Match Name', key : 'match.matchName'},
        { label : 'Minimum Player', key : 'match.matchMinPlayers'},
        { label : 'Maximum Player', key : 'match.matchMaxPlayers'},
        { label : 'Status', key : 'active'},
    ]
     
    const csvReport = {
        filename : `Leaderboard_Report_${new Date().toISOString().slice(0, 10)}.csv`,
        headers : headers,
        data : allLeaderboard
    }

    const [disableExport, setDisableExport] = useState(false)

    const exportData = () => {
        setDisableExport(true)
        setTimeout(() => {
          setDisableExport(false)
        }, 5000);
    }

    return (
        <>
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                        <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                        <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-dark" active>Leaderboard</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
                    
                </Col>

                <Col xs={12} lg={6} className="px-0 mb-0 text-right">
                    <ButtonGroup>
                        {/* <Button variant="outline-primary" size="sm">Export</Button> */}
                        {allLeaderboard ? <Button disabled={disableExport ? true : false} onClick={exportData} className="export"><CSVLink {...csvReport}>Export</CSVLink></Button> : ""}
                    </ButtonGroup>
                </Col>
            </div>
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5 mainLeaderboard">
                <Row className="px-4">
                    <Col className="col col-lg-6 col-md-12 text-left">
                        <p className='page-heading'>Leaderboards</p>
                    </Col>
                    <Col>
                        <div className="col col-md-12 text-right">
                            <span className='filter' style={{cursor: "pointer", paddingRight: "10px"}} onClick={showFilter}><i class="fas fa-filter mx-2"></i><span>Filter</span></span>
                        </div>
                    </Col>
                </Row>
                <Row className="my-2 px-4">
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <InputGroup>
                            <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                            <Form.Control type="text" placeholder="Search Leaderboard" onChange={(e)=>{SearchLeaderboard(e)}}/>
                        </InputGroup>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <select className="form-control" onChange={(e)=>{onLeaderboardSort(e)}}>
                            <option>Sort By</option>
                            <option value='AtoZ'>Leaderboard Name (A-Z)</option>
                            <option value='ZtoA'>Leaderboard Name (Z-A)</option>
                            <option value='latest'>Created At (latest)</option>
                            <option value='oldest'>Created At (oldest)</option>
                            {/* <option value='gameName'>Game Name</option>
                            <option value='matchName'>Match Name</option>
                            <option value='status'>Leaderboard Status</option> */}
                        </select>
                    </Col>
                </Row>
                <Row className="my-5 px-4">
                    <Col lg={12} md={12} sm={12}>
                    <Row className='nav-accordian'>
                        <Col style={{cursor: "pointer"}} xl={3} lg={3} md={3} sm={12} xs={12}>
                            <Nav fill variant="pills" className="flex-column flex-sm-row" onClick={oncoreLeaderboard}>
                                <Nav.Item>
                                    <Nav.Link className={isCore == true ? "accordian-pills pills mb-sm-3 mb-md-0" : "mb-sm-3 mb-md-0 pills"}>Core Leaderboards</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col style={{cursor: "pointer"}} xl={3} lg={3} md={3} sm={12} xs={12}>
                            <Nav fill variant="pills" className="flex-column flex-sm-row" onClick={ondefaultLeaderboard}>
                                <Nav.Item>
                                    <Nav.Link className={isLeaderboard == true ? "accordian-pills pills mb-sm-3 mb-md-0" : "mb-sm-3 mb-md-0 pills"}>Leaderboards</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col style={{cursor: "pointer"}} xl={3} lg={3} md={3} sm={12} xs={12}>
                            <Nav fill variant="pills" className="flex-column flex-sm-row" onClick={onCustomLeaderboard}>
                                <Nav.Item>
                                    <Nav.Link className={isCustomeLeaderboard == true ? "accordian-pills pills mb-sm-3 mb-md-0" : "mb-sm-3 mb-md-0 pills"}>Custom Leaderboards</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                    </Col>
                </Row>
                {isCore == true && Leaderboard.length > 0 ? 
                    <Row className="table-responsive px-4">
                        <Col lg={12} md={12} sm={12}>
                            <table className="table table-striped text-center table-custom">
                                <thead className='table-head'>
                                    <tr>
                                        <th className='head-border-left' scope="col">{Leaderboards.Leaderboard_Name}</th>
                                        <th scope="col">{Leaderboards.Created_At}</th>
                                        <th scope="col">{Leaderboards.Game_Name}</th>
                                        <th scope="col">{Leaderboards.Game_World}</th>
                                        <th scope="col">{Leaderboards.Leaderboard_Status}</th>
                                        <th className='head-border-right' scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Leaderboard && Leaderboard.map((e, i)=>{
                                            return(   
                                                <tr key={e.id} className='table-body'>
                                                    <td>{e.match != null ? <Dropdown.Item as={Link} className='table-body' to={{pathname:Routes.LeaderboardDetail.path, id:e.id, matchId: e.match.id, leaderboardName : e.leaderboardName}}><b>{e.leaderboardName}</b></Dropdown.Item> : <Dropdown.Item className='table-body' key={e.id} as={Link} to={{pathname:Routes.LeaderboardDetail.path, id:e.id, leaderboardName : e.leaderboardName}}><b>{e.leaderboardName}</b></Dropdown.Item>}</td>
                                                    <td>{e.createdAt.slice(0,10).split("-").reverse().join("-")}</td>
                                                    <td>{e.game != null ? e.game.gameName : "-"}</td>
                                                    <td>{e.gameworld != null ? e.gameworld.gameWorldName : "-"}</td>
                                                    <td>{e.active === true ? "Active" : "Inactive"}</td>
                                                    <td>
                                                        <Dropdown className="ms-3">
                                                            <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="icon icon-sm icon-dark">
                                                                    <FontAwesomeIcon icon={faEllipsisH} />
                                                                </span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dropdown-menu-right actions">
                                                                <Dropdown.Item key={e.id} className="ms-0" onClick={()=>{onToggleGameDetail(e.id)}}>
                                                                    <FontAwesomeIcon icon={faEye} className="me-2 icon-color" /> View Details
                                                                </Dropdown.Item>
                                                                {e.match != null ?
                                                                <Dropdown.Item key={e.id} as={Link} to={{pathname:Routes.LeaderboardDetail.path, id:e.id, matchId: e.match.id, leaderboardName : e.leaderboardName}} className="ms-0">
                                                                    {/* <FontAwesomeIcon icon={faArrowAltCircleUp} className="me-2 icon-color"/> */}
                                                                    <svg className="me-2 icon-color" width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99992 2.5C9.7347 2.5 9.48035 2.39464 9.29281 2.20711C9.10528 2.01957 8.99992 1.76522 8.99992 1.5C8.99992 1.23478 9.10528 0.98043 9.29281 0.792893C9.48035 0.605357 9.7347 0.5 9.99992 0.5H14.9999C15.2651 0.5 15.5195 0.605357 15.707 0.792893C15.8946 0.98043 15.9999 1.23478 15.9999 1.5V6.5C15.9999 6.76522 15.8946 7.01957 15.707 7.20711C15.5195 7.39464 15.2651 7.5 14.9999 7.5C14.7347 7.5 14.4803 7.39464 14.2928 7.20711C14.1053 7.01957 13.9999 6.76522 13.9999 6.5V3.914L9.70692 8.207C9.51939 8.39447 9.26508 8.49979 8.99992 8.49979C8.73475 8.49979 8.48045 8.39447 8.29292 8.207L5.99992 5.914L1.70692 10.207C1.51832 10.3892 1.26571 10.49 1.00352 10.4877C0.741321 10.4854 0.490508 10.3802 0.3051 10.1948C0.119692 10.0094 0.0145233 9.7586 0.0122448 9.4964C0.00996641 9.2342 0.110761 8.9816 0.292919 8.793L5.29292 3.793C5.48045 3.60553 5.73475 3.50021 5.99992 3.50021C6.26508 3.50021 6.51939 3.60553 6.70692 3.793L8.99992 6.086L12.5859 2.5H9.99992Z" fill="black"/>
                                                                    </svg>
                                                                     View Leaderboard
                                                                </Dropdown.Item>
                                                                : 
                                                                <Dropdown.Item key={e.id} as={Link} to={{pathname:Routes.LeaderboardDetail.path, id:e.id, leaderboardName : e.leaderboardName}} className="ms-0">
                                                                    {/* <FontAwesomeIcon icon={faArrowAltCircleUp} className="me-2 icon-color"/>  */}
                                                                    <svg className="me-2 icon-color" width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99992 2.5C9.7347 2.5 9.48035 2.39464 9.29281 2.20711C9.10528 2.01957 8.99992 1.76522 8.99992 1.5C8.99992 1.23478 9.10528 0.98043 9.29281 0.792893C9.48035 0.605357 9.7347 0.5 9.99992 0.5H14.9999C15.2651 0.5 15.5195 0.605357 15.707 0.792893C15.8946 0.98043 15.9999 1.23478 15.9999 1.5V6.5C15.9999 6.76522 15.8946 7.01957 15.707 7.20711C15.5195 7.39464 15.2651 7.5 14.9999 7.5C14.7347 7.5 14.4803 7.39464 14.2928 7.20711C14.1053 7.01957 13.9999 6.76522 13.9999 6.5V3.914L9.70692 8.207C9.51939 8.39447 9.26508 8.49979 8.99992 8.49979C8.73475 8.49979 8.48045 8.39447 8.29292 8.207L5.99992 5.914L1.70692 10.207C1.51832 10.3892 1.26571 10.49 1.00352 10.4877C0.741321 10.4854 0.490508 10.3802 0.3051 10.1948C0.119692 10.0094 0.0145233 9.7586 0.0122448 9.4964C0.00996641 9.2342 0.110761 8.9816 0.292919 8.793L5.29292 3.793C5.48045 3.60553 5.73475 3.50021 5.99992 3.50021C6.26508 3.50021 6.51939 3.60553 6.70692 3.793L8.99992 6.086L12.5859 2.5H9.99992Z" fill="black"/>
                                                                    </svg>
                                                                    View Leaderboard
                                                                </Dropdown.Item>}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                                )
                                        })}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                : isCore == true && Leaderboard.length == 0 ? <p className='mx-4'>No Data Available</p>
                : isLeaderboard == true && Leaderboard.length > 0 ?
                <Row className="mx-auto container-fluid">
                <Col lg={12} md={12} sm={12} style={{overflowX : "scroll"}}>
                    <table className="table table-striped text-center table-custom">
                        <thead className='table-head'>
                            <tr>
                                <th className='head-border-left' scope="col">{Leaderboards.Leaderboard_Name}</th>
                                <th scope="col">{Leaderboards.Created_At}</th>
                                <th scope="col">{Leaderboards.Game_Name}</th>
                                <th scope="col">{Leaderboards.Game_World}</th>
                                <th scope="col">{Leaderboards.Match_Name}</th>
                                <th scope="col">{Leaderboards.Match_Format}</th>
                                <th scope="col">{Leaderboards.Leaderboard_Outcome_Type}</th>
                                <th scope="col">{Leaderboards.Leaderboard_Status}</th>
                                <th className='head-border-right' scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Leaderboard && Leaderboard.map((e, i)=>{
                                return(    
                                    <tr key={e.id} className='table-body'>
                                        <td>{e.match != null ? <Dropdown.Item className='table-body' as={Link} to={{pathname:Routes.LeaderboardDetail.path, id:e.id, matchId: e.match.id, leaderboardName : e.leaderboardName}}><b>{e.leaderboardName}</b></Dropdown.Item> : <Dropdown.Item className='table-body' key={e.id} as={Link} to={{pathname:Routes.LeaderboardDetail.path, id:e.id, leaderboardName : e.leaderboardName}}><b>{e.leaderboardName}</b></Dropdown.Item>}</td>
                                        <td>{e.createdAt.slice(0,10).split("-").reverse().join("-")}</td>
                                        <td>{e.game != null ? e.game.gameName : "-"}</td>
                                        <td>{e.gameworld != null ? e.gameworld.gameWorldName : "-"}</td>
                                        <td>{e.match != null ? e.match.matchName : "-"}</td>
                                        <td>{e.match != null && e.match.matchFormatTypeMasterId === 1 ? "Single Player" : e.match != null && e.match.matchFormatTypeMasterId === 2 ? "Multiplayer" : "-"}</td>
                                        <td>{e.match != null && e.leaderboardOutcomeTypeMasterId === 1 ? "High Score" : e.match != null && e.leaderboardOutcomeTypeMasterId === 2 ? "Time Trail" : e.match != null && e.leaderboardOutcomeTypeMasterId === 3 ? "Win/Loss/Draw" : e.match != null && e.leaderboardOutcomeTypeMasterId === 4 ? "Podium Finish" : e.match != null && e.leaderboardOutcomeTypeMasterId === 5 ? "Winning  Collected" : "-"}</td>
                                        <td>{e.active === true ? "Active" : "Inactive"}</td>
                                        <td>
                                            <Dropdown className="ms-3">
                                                <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span className="icon icon-sm icon-dark">
                                                        <FontAwesomeIcon icon={faEllipsisH} />
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu-right actions">
                                                    <Dropdown.Item key={e.id} className="ms-0" onClick={()=>{onToggleGameDetail(e.id)}}>
                                                            <FontAwesomeIcon icon={faEye} className="me-2 icon-color" /> View Details
                                                    </Dropdown.Item>
                                                    {e.match != null ?
                                                    <Dropdown.Item key={e.id} as={Link} to={{pathname:Routes.LeaderboardDetail.path, id:e.id, matchId: e.match.id, leaderboardName : e.leaderboardName}} className="ms-0">
                                                        {/* <FontAwesomeIcon icon={faEdit} className="me-2 icon-color"/> */}
                                                        <svg className="me-2 icon-color" width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99992 2.5C9.7347 2.5 9.48035 2.39464 9.29281 2.20711C9.10528 2.01957 8.99992 1.76522 8.99992 1.5C8.99992 1.23478 9.10528 0.98043 9.29281 0.792893C9.48035 0.605357 9.7347 0.5 9.99992 0.5H14.9999C15.2651 0.5 15.5195 0.605357 15.707 0.792893C15.8946 0.98043 15.9999 1.23478 15.9999 1.5V6.5C15.9999 6.76522 15.8946 7.01957 15.707 7.20711C15.5195 7.39464 15.2651 7.5 14.9999 7.5C14.7347 7.5 14.4803 7.39464 14.2928 7.20711C14.1053 7.01957 13.9999 6.76522 13.9999 6.5V3.914L9.70692 8.207C9.51939 8.39447 9.26508 8.49979 8.99992 8.49979C8.73475 8.49979 8.48045 8.39447 8.29292 8.207L5.99992 5.914L1.70692 10.207C1.51832 10.3892 1.26571 10.49 1.00352 10.4877C0.741321 10.4854 0.490508 10.3802 0.3051 10.1948C0.119692 10.0094 0.0145233 9.7586 0.0122448 9.4964C0.00996641 9.2342 0.110761 8.9816 0.292919 8.793L5.29292 3.793C5.48045 3.60553 5.73475 3.50021 5.99992 3.50021C6.26508 3.50021 6.51939 3.60553 6.70692 3.793L8.99992 6.086L12.5859 2.5H9.99992Z" fill="black"/>
                                                        </svg> 
                                                        View Leaderboard
                                                    </Dropdown.Item>
                                                    : 
                                                    <Dropdown.Item key={e.id} as={Link} to={{pathname:Routes.LeaderboardDetail.path, id:e.id, leaderboardName : e.leaderboardName}} className="ms-0">
                                                        {/* <FontAwesomeIcon icon={faEdit} className="me-2 icon-color"/>  */}
                                                        <svg className="me-2 icon-color" width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99992 2.5C9.7347 2.5 9.48035 2.39464 9.29281 2.20711C9.10528 2.01957 8.99992 1.76522 8.99992 1.5C8.99992 1.23478 9.10528 0.98043 9.29281 0.792893C9.48035 0.605357 9.7347 0.5 9.99992 0.5H14.9999C15.2651 0.5 15.5195 0.605357 15.707 0.792893C15.8946 0.98043 15.9999 1.23478 15.9999 1.5V6.5C15.9999 6.76522 15.8946 7.01957 15.707 7.20711C15.5195 7.39464 15.2651 7.5 14.9999 7.5C14.7347 7.5 14.4803 7.39464 14.2928 7.20711C14.1053 7.01957 13.9999 6.76522 13.9999 6.5V3.914L9.70692 8.207C9.51939 8.39447 9.26508 8.49979 8.99992 8.49979C8.73475 8.49979 8.48045 8.39447 8.29292 8.207L5.99992 5.914L1.70692 10.207C1.51832 10.3892 1.26571 10.49 1.00352 10.4877C0.741321 10.4854 0.490508 10.3802 0.3051 10.1948C0.119692 10.0094 0.0145233 9.7586 0.0122448 9.4964C0.00996641 9.2342 0.110761 8.9816 0.292919 8.793L5.29292 3.793C5.48045 3.60553 5.73475 3.50021 5.99992 3.50021C6.26508 3.50021 6.51939 3.60553 6.70692 3.793L8.99992 6.086L12.5859 2.5H9.99992Z" fill="black"/>
                                                        </svg>
                                                        View Leaderboard
                                                    </Dropdown.Item>}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </Col>
            </Row>
            : isLeaderboard == true && Leaderboard.length == 0 ? <p className='mx-4'>No Data Available</p>
            : isCustomeLeaderboard == true ?
            <div className="mx-auto container-fluid">
                <Row className='my-3'>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Button as={Link} to={Routes.CreateCustomLeaderboard.path} className="me-2 button-style">
                            <FontAwesomeIcon icon={faPlusCircle} className="me-2 icon" />
                            <span>Add Custom Leaderboard</span>
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12} sm={12} style={{overflowX : "scroll"}}>
                        <table className="table table-striped text-center table-custom">
                            <thead className='table-head'>
                                <tr>
                                    <th className='head-border-left' scope="col">{Leaderboards.Leaderboard_Name}</th>
                                    <th scope="col">{Leaderboards.Created_At}</th>
                                    <th scope="col">{Leaderboards.Leaderboard_Outcome_Type}</th>
                                    <th scope="col">{Leaderboards.Leaderboard_Status}</th>
                                    <th className='head-border-right' scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>   
                                {Leaderboard && Leaderboard.map((e, i)=>{
                                    if(e.externalLeaderboardObjectId != null){
                                        return(    
                                            <tr key={e.id} className='table-body'>
                                                <td>{e.leaderboardName != "" || e.leaderboardName != null ? e.leaderboardName : "-"}</td>
                                                <td>{e.createdAt.slice(0,10).split("-").reverse().join("-")}</td>
                                                <td>{e.leaderboardOutcomeTypeMasterId === 1 ? "High Score" : e.leaderboardOutcomeTypeMasterId === 2 ? "Time Trail" : e.leaderboardOutcomeTypeMasterId === 3 ? "Win/Loss/Draw" : e.leaderboardOutcomeTypeMasterId === 4 ? "Podium Finish" : e.leaderboardOutcomeTypeMasterId === 5 ? "Winning  Collected" : "-"}</td>
                                                <td>{e.active === true ? "Active" : "Inactive"}</td>
                                                <td>
                                                    <Dropdown className="ms-3">
                                                        <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <span className="icon icon-sm icon-dark">
                                                                <FontAwesomeIcon icon={faEllipsisH} />
                                                            </span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="dropdown-menu-right actions">
                                                            <Dropdown.Item key={e.id} className="ms-0" onClick={()=>{onToggleGameDetail(e.id)}}>
                                                                    <FontAwesomeIcon icon={faEye} className="me-2 icon-color" /> View Details
                                                            </Dropdown.Item>
                                                            {e.match != null ?
                                                            <Dropdown.Item key={e.id} as={Link} to={{pathname:Routes.LeaderboardDetail.path, id:e.id, matchId: e.match.id, leaderboardName : e.leaderboardName}} className="ms-0">
                                                                {/* <FontAwesomeIcon icon={faEdit} className="me-2 icon-color"/> */}
                                                                <svg className="me-2 icon-color" width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99992 2.5C9.7347 2.5 9.48035 2.39464 9.29281 2.20711C9.10528 2.01957 8.99992 1.76522 8.99992 1.5C8.99992 1.23478 9.10528 0.98043 9.29281 0.792893C9.48035 0.605357 9.7347 0.5 9.99992 0.5H14.9999C15.2651 0.5 15.5195 0.605357 15.707 0.792893C15.8946 0.98043 15.9999 1.23478 15.9999 1.5V6.5C15.9999 6.76522 15.8946 7.01957 15.707 7.20711C15.5195 7.39464 15.2651 7.5 14.9999 7.5C14.7347 7.5 14.4803 7.39464 14.2928 7.20711C14.1053 7.01957 13.9999 6.76522 13.9999 6.5V3.914L9.70692 8.207C9.51939 8.39447 9.26508 8.49979 8.99992 8.49979C8.73475 8.49979 8.48045 8.39447 8.29292 8.207L5.99992 5.914L1.70692 10.207C1.51832 10.3892 1.26571 10.49 1.00352 10.4877C0.741321 10.4854 0.490508 10.3802 0.3051 10.1948C0.119692 10.0094 0.0145233 9.7586 0.0122448 9.4964C0.00996641 9.2342 0.110761 8.9816 0.292919 8.793L5.29292 3.793C5.48045 3.60553 5.73475 3.50021 5.99992 3.50021C6.26508 3.50021 6.51939 3.60553 6.70692 3.793L8.99992 6.086L12.5859 2.5H9.99992Z" fill="black"/>
                                                                </svg> 
                                                                View Leaderboard
                                                            </Dropdown.Item>
                                                            : 
                                                            <Dropdown.Item key={e.id} as={Link} to={{pathname:Routes.LeaderboardDetail.path, id:e.id, leaderboardName : e.leaderboardName}} className="ms-0">
                                                                {/* <FontAwesomeIcon icon={faEdit} className="me-2 icon-color"/>  */}
                                                                <svg className="me-2 icon-color" width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99992 2.5C9.7347 2.5 9.48035 2.39464 9.29281 2.20711C9.10528 2.01957 8.99992 1.76522 8.99992 1.5C8.99992 1.23478 9.10528 0.98043 9.29281 0.792893C9.48035 0.605357 9.7347 0.5 9.99992 0.5H14.9999C15.2651 0.5 15.5195 0.605357 15.707 0.792893C15.8946 0.98043 15.9999 1.23478 15.9999 1.5V6.5C15.9999 6.76522 15.8946 7.01957 15.707 7.20711C15.5195 7.39464 15.2651 7.5 14.9999 7.5C14.7347 7.5 14.4803 7.39464 14.2928 7.20711C14.1053 7.01957 13.9999 6.76522 13.9999 6.5V3.914L9.70692 8.207C9.51939 8.39447 9.26508 8.49979 8.99992 8.49979C8.73475 8.49979 8.48045 8.39447 8.29292 8.207L5.99992 5.914L1.70692 10.207C1.51832 10.3892 1.26571 10.49 1.00352 10.4877C0.741321 10.4854 0.490508 10.3802 0.3051 10.1948C0.119692 10.0094 0.0145233 9.7586 0.0122448 9.4964C0.00996641 9.2342 0.110761 8.9816 0.292919 8.793L5.29292 3.793C5.48045 3.60553 5.73475 3.50021 5.99992 3.50021C6.26508 3.50021 6.51939 3.60553 6.70692 3.793L8.99992 6.086L12.5859 2.5H9.99992Z" fill="black"/>
                                                                </svg>
                                                                View Leaderboard
                                                            </Dropdown.Item>}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </div>
            : ""}
            {isCustomeLeaderboard == false ?
                <div className="d-flex justify-content-left m-4">
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <Pagination className="paginate"
                            count={counts} 
                            defaultPage={page} 
                            color="info" 
                            variant="outlined" shape="rounded"
                            showFirstButton={false} 
                            showLastButton={false} 
                            onChange={(event, value)=> setPage(value)}
                            />
                        </Col>
                    </Row>
                </div>
                : ""}
                {/* <div className="mt-5 mx-4">
                    <Row>
                        <Col>

                        </Col>

                        <Col className='text-right'>
                            <Button onClick={showPDLeaderboard} variant="secondary" className="text-dark me-2">
                                <span>Show PD Leaderboard</span>
                            </Button>
                        </Col>
                    </Row>
                </div> */}
                {showPD === true ?
                    <div className="mt-4">
                    <Row className="mt-5 mx-4">
                        <Col>
                            <Button as={Link} to={Routes.PointDistribution.path} variant="secondary" className="text-dark me-2">
                                <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                                <span>Create PD Leaderboard</span>
                            </Button>
                        </Col>
                    </Row>

                    <Row className="my-3">
                        <Col>
                            <div className="row d-flex px-4 align-items-center">
                                <div className="col col-md-4">
                                    <select className="form-control" onChange={(e)=>{onLeaderboardSort(e)}}>
                                        <option>Sort By</option>
                                        <option value="ASC">Leaderboard Name</option>
                                        <option value="DESC">Game Name</option>
                                        <option value="ASC">Created At</option>
                                        <option>Leaderboard PD Status</option>
                                        {/* <option value="ASC">Start Date/Time</option>
                                        <option value="DESC">End Date/ Time</option> */}
                                        <option>Prize Distribution Date</option>
                                        <option>Match Name</option>
                                    </select>
                                </div>
                                <div className="col col-md-8 text-right">
                                    <span style={{cursor: "pointer", paddingRight: "10px"}} onClick={showPDFilter}><i class="fas fa-filter mx-2"></i><span>Filter</span></span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="container-fluid">
                        <Col style={{overflowX : "scroll"}} lg={12} md={12} sm={12}>
                            <table className="table table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col" >{Leaderboards.Leaderboard_Name}</th>
                                        <th scope="col">{Leaderboards.Leaderboard_Class}</th>
                                        <th scope="col">Game ID</th>
                                        <th scope="col">{Leaderboards.Game_Name}</th>
                                        <th scope="col">{Leaderboards.Match_Name}</th>
                                        <th scope="col">{Leaderboards.Match_Format}</th>
                                        <th scope="col">{Leaderboards.Match_Outcome_Type}</th>
                                        <th scope="col">{Leaderboards.Leaderboard_Outcome_Type}</th>
                                        <th scope="col">{Leaderboards.Game_World}</th>
                                        <th scope="col">{Leaderboards.Created_At}</th>
                                        <th scope="col" >{Leaderboards.No_Of_Winners}</th>
                                        <th scope="col">{Leaderboards.Min_Playes}</th>
                                        <th scope="col">{Leaderboards.Team}</th>
                                        <th scope="col">{Leaderboards.Start_Date}</th>
                                        <th scope="col">{Leaderboards.End_Date}</th>
                                        <th scope="col">{Leaderboards.Schedule_Type}</th>
                                        <th scope="col">{Leaderboards.PD_Date}</th>
                                        <th scope="col">{Leaderboards.Leaderboard_Status}</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {PrizeDistribution && PrizeDistribution.map((e)=>{
                                        return(
                                            <tr>
                                                <td>{e.leaderboard.leaderboardName}</td>
                                                <td></td>
                                                <td>{e.leaderboard.gameId}</td>
                                                <td>{e.leaderboard.game.gameName}</td>
                                                <td>{e.leaderboard.match.matchName}</td>
                                                <td>{e.leaderboard.match.matchFormatTypeMasterId === 1 ? "Single Player" : "Multiplayer"}</td>
                                                <td>{e.leaderboard.match.matchOutcomeTypeMasterId === 1 ? "High Score" : e.leaderboard.match.matchOutcomeTypeMasterId === 2 ? "Time Trail" : e.leaderboard.match.matchOutcomeTypeMasterId === 3 ? "Win/Loss/Draw" : e.leaderboard.match.matchOutcomeTypeMasterId === 4 ? "Podium Finish" : e.leaderboard.match.matchOutcomeTypeMasterId === 5 ? "Winning  Collected" : ""}</td>
                                                <td>{e.leaderboard.leaderboardOutcomeTypeMasterId === 1 ? "High Score" : e.leaderboard.leaderboardOutcomeTypeMasterId === 2 ? "Time Trail" : e.leaderboard.leaderboardOutcomeTypeMasterId === 3 ? "Win/Loss/Draw" : e.leaderboard.leaderboardOutcomeTypeMasterId === 4 ? "Podium Finish" : e.leaderboard.leaderboardOutcomeTypeMasterId === 5 ? "Winning  Collected" : ""}</td>
                                                <td>{e.leaderboard.gameworld.gameWorldName}</td>
                                                <td>{e.createdAt.slice(0,10)}</td>
                                                <td>{e.numberOfWinners}</td>
                                                <td>{e.minimumPlayers}</td>
                                                <td></td>
                                                <td>{e.startDate.slice(0,10)}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>{e.leaderBoardPrizeDistributionStatus === "yet to start" ? "Yet to Start" : ""}</td>
                                                <td>
                                                    <Dropdown className="ms-3">
                                                        <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <span className="icon icon-sm icon-dark">
                                                                <FontAwesomeIcon icon={faEllipsisH} />
                                                            </span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="dropdown-menu-right">
                                                            <Dropdown.Item key={e.id} className="ms-0" onClick={()=>{onPDDetail()}}>
                                                                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                                                            </Dropdown.Item>
                                                            <Dropdown.Item key={e.id} as={Link} to={{pathname:Routes.LeaderboardDetail.path, id:e.id}} className="ms-0">
                                                                <FontAwesomeIcon icon={faEdit} className="me-2"/> View results
                                                            </Dropdown.Item>
                                                            <Dropdown.Item key={e.id} className="ms-0" onClick={onToggleDelete}>
                                                                <FontAwesomeIcon icon={faBan} className="me-2" /> Stop
                                                            </Dropdown.Item>
                                                            <Dropdown.Item key={e.id} className="ms-0" onClick={onToggleStop}>
                                                                <FontAwesomeIcon icon={faTrash} className="me-2" /> Delete
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown> 
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </div>
                : ""}
                {pdfilter ? <PDFilterModal addOpen={pdfilter} Addtoggle={showPDFilter}/> : ""}
                <FilterModal show={filter} onHide={showFilter} filterLeaderboard={leaderboardFilters}/>
                <ViewLeaderboardDetail show={gamedetail} onHide={onToggleGameDetail} />
                <ViewPDDetails show={pdDetail} onHide={onPDDetail} />
                {showdstopmodal ? <DeletePD addOpen={showdstopmodal} Addtoggle={onToggleStop} /> : ""}
                {showdeletemodal ? <StopPDModal addOpen={showdeletemodal} Addtoggle={onToggleDelete} /> : ""}
            </div>
        </>
    )
}

