import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {Dropdown, Row, Col, ButtonGroup, Button, InputGroup, Form, Nav} from '@themesberg/react-bootstrap'
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faSearch, faTrash, faBan, faPlusCircle, faCopy} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Routes } from "../routes";
import { Link, useHistory } from 'react-router-dom';
import DeleteCompetition from './modals/CompetitionModels/DeleteCompetition';
import StopCompetition from './modals/CompetitionModels/StopCompetition';
import CompetitionDetail from './modals/CompetitionModels/CompetitionDetail'
import { Competitions } from '../constants/LabelConstants';
import { getallCompetitionAction, searchCompetitionAction, getCompetitionDetailAction, getCompetitionByIdAction, getallCompetition, editCompetitionAction, stopCompetitionAction, getAllTemplatesAction, getTemplateByIdAction, searchTemplateAction, createTemplateAction, clearTemplateAction } from '../redux/action/competitionAction';
import Pagination from '@mui/material/Pagination'
import axios from '../utlis/interceptor';
import CompetitionFilter from './modals/Filters/CompetitionFilter';
import { BASE_URL, EDIT_COMPETITION, GET_COMPETITION, GET_TEMPLATES, STOP_COMPETITION } from '../resources/APIEndpoints';
import { CSVLink, CSVDownload } from "react-csv";
import moment from 'moment'
import { Breadcrumb } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-js-loader";
import TemplateDetail from './modals/CompetitionModels/TemplateDetail';
import TemplateFilter from './modals/Filters/TemplateFilter';
import Switch from "react-switch";
import CompetitionLiveModal from './modals/CompetitionModels/CompetitionLiveModal';
import EndLiveCompetitionModal from './modals/CompetitionModels/EndLiveCompetitionModal';

export default function CompetitionMain(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    
    const [showdeletemodal, setShowdeletemodal] = useState(false)
    const [competitionDetail, setCompetitiondetail] = useState(false)
    
    const onToggleCompetitionDetail = (data) => {

        setCompetitiondetail(!competitionDetail)

        const competitionById = {
            competitionFilters : {
                id : [data],
                pagination : {
                    offset : 0,
                    limit : 1
                }
            }
        }
        dispatch(getCompetitionByIdAction(competitionById))
    }

    const [competitionFilter, setCompetitionFilter] = useState(false)

    const onToggleCompetitionFilter = () =>{
        setCompetitionFilter(!competitionFilter)
    }

    const [templateFilter, setTemplateFilter] = useState(false)

    const onToggleTemplateFilter = () =>{
        setTemplateFilter(!templateFilter)
    }

    const [page, setPage] = useState(1)
    const [counts , setCounts] = useState()
    const [limit, setLimit] = useState(10)

    const [pageTemplates, setPageTemplates] = useState(1)
    const [countsTemplates , setCountTemplates] = useState()
    const [limitTemplates, setLimitTemplates] = useState(10)

    useEffect(() => {
        getallCompetitionData();
        paginatCompetition();
        getallTemplates();
        paginatTemplates();
    }, [page, pageTemplates])

    const [compFormat , setCompFormat] = useState([2])

    const getallCompetitionData = () => {
        
        let allCompetition = {
            competitionFilters: {
                competitionSort : competitionSort,
                matchFormatTypeMasterId : filterMatchFormat,
                matchOutcomeTypeMasterId : filterMatchOutcome,
                leaderboardOutcomeTypeMasterId : filterLeaderboardOutcome,
                competitionStatus : filterStatus,
                competitionFormatTypeMasterId : compFormat,
                archive: "false",
                pagination : {
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
        }

        Object.keys(allCompetition.competitionFilters).forEach((key) => (allCompetition.competitionFilters[key].length === 0) && delete allCompetition.competitionFilters[key]) 
        dispatch(getallCompetitionAction(allCompetition));
    }

    const getallTemplates = () => {
        let templateData = {
            templatesFilters : {
                templateSort : templateSort,
                pagination : {
                    offset : ((pageTemplates-1)*limitTemplates),
                    limit : limitTemplates
                }
            }
        }

        dispatch(getAllTemplatesAction(templateData))
    }

    const paginatTemplates = () => {
        let templatePaginate = {
            templatesFilters : {
                pagination : {

                }
            }
        }

        axios.post(BASE_URL + GET_TEMPLATES, templatePaginate)
        .then(res=>{
            const tempPageNo = Math.ceil(res.data.templateData.length/limitTemplates)
            setCountTemplates(tempPageNo)
        })
    }

    const paginatCompetition = () => {
        let competitionPagination = {
            competitionFilters : {
                competitionSort : competitionSort,
                matchFormatTypeMasterId : filterMatchFormat,
                matchOutcomeTypeMasterId : filterMatchOutcome,
                leaderboardOutcomeTypeMasterId : filterLeaderboardOutcome,
                competitionStatus : filterStatus,
                competitionFormatTypeMasterId : compFormat,
                archive: "false",
                pagination : {
        
                }
            }
        }
        Object.keys(competitionPagination.competitionFilters).forEach((key) => (competitionPagination.competitionFilters[key].length === 0) && delete competitionPagination.competitionFilters[key]) 
        axios.post(`${BASE_URL}/competition/getCompetition`, competitionPagination)
        .then(res=>{
          const compPageNo = Math.ceil(res.data.competitionData.length/limit)
          setCounts(compPageNo)
        })
    }

    const searchCompetition = (e) => {
        if(e.target.value === ""){
            let allCompetition = {
                competitionFilters: {
                    archive: "false",
                    pagination : {
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
            }
            dispatch(getallCompetitionAction(allCompetition))
        }else{
            let searchCompetition = {
                competitionName : e.target.value,
                
            }
            dispatch(searchCompetitionAction(searchCompetition))
        }
    }

    const searchTemplates = (e) => {
        if(e.target.value === ""){
            let allTemplates = {
                templatesFilters : {
                    pagination : {
                        offset : ((pageTemplates-1)*limitTemplates),
                        limit : limitTemplates
                    }
                }
            }
            dispatch(getAllTemplatesAction(allTemplates))
        }else{
            let searchTemplatesData = {
                searchString : e.target.value
            }
            dispatch(searchTemplateAction(searchTemplatesData))
        }
    }

    let competition = useSelector((state)=>{ 
        return state.competition.competitions
    })

    let templates = useSelector((state)=>{ 
        return state.competition.templates
    })

    let isLoad = useSelector((state)=>{ 
        return state.competition.isLoading
    })

    const [competitionSort, setCompetitionSort] = useState({createdAt : "DESC"})
    const [templateSort, setTemplateSort] = useState({createdAt : "DESC"})

    const sortCompetition = (e) => {
        let sortdataCompetition = {}
        if(e.target.value === 'AtoZ'){
            setCompetitionSort({ competitionName : "ASC"})
            sortdataCompetition = {
                competitionFilters : {
                    competitionFormatTypeMasterId : compFormat,
                    competitionSort : {
                        competitionName : "ASC"
                    },
                    pagination :{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                } 
            }
        }else if(e.target.value === 'ZtoA'){
            setCompetitionSort({ competitionName : "DESC" })
            sortdataCompetition = {
                competitionFilters : {
                    competitionFormatTypeMasterId : compFormat,
                    competitionSort : {
                        competitionName : "DESC"
                    },
                    pagination :{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                } 
            }  
        }else if(e.target.value === 'latest'){
            setCompetitionSort({ createdAt : "DESC" })
            sortdataCompetition = {
                competitionFilters : {
                    competitionFormatTypeMasterId : compFormat,
                    competitionSort : {
                        createdAt : "DESC"
                    },
                    pagination :{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                } 
            }  
        }else if(e.target.value === 'oldest'){
            setCompetitionSort({ createdAt : "ASC" })
            sortdataCompetition = {
                competitionFilters : {
                    competitionFormatTypeMasterId : compFormat,
                    competitionSort : {
                        createdAt : "ASC"
                    },
                    pagination :{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                } 
            }  
        }
        else if(e.target.value === 'lowHigh'){
            setCompetitionSort({ entryFee : "ASC" })
            sortdataCompetition = {
                competitionFilters : {
                    competitionFormatTypeMasterId : compFormat,
                    competitionSort : {
                        entryFee : "ASC"
                    },
                    pagination :{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                } 
            }  
        }else if(e.target.value === 'Highlow'){
            setCompetitionSort({ entryFee : "DESC" })
            sortdataCompetition = {
                competitionFilters : {
                    competitionFormatTypeMasterId : compFormat,
                    competitionSort : {
                        entryFee : "DESC"
                    },
                    pagination :{
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                } 
            }  
        }
        dispatch(getallCompetitionAction(sortdataCompetition))
    }

    const sortTemplates = (e) => {
        let sortTemplates = {}
        if(e.target.value === 'AtoZ'){
            setTemplateSort({ templateName : "ASC" })
            sortTemplates = {
                templatesFilters : {
                    templateSort : {
                        templateName : "ASC"
                    },
                    pagination :{
                        offset : ((pageTemplates-1)*limitTemplates),
                        limit : limitTemplates
                    }
                } 
            }
        }else if(e.target.value === 'ZtoA'){
            setTemplateSort({ templateName : "DESC" })
            sortTemplates = {
                templatesFilters : {
                    templateSort : {
                        templateName : "DESC"
                    },
                    pagination :{
                        offset : ((pageTemplates-1)*limitTemplates),
                        limit : limitTemplates
                    }
                } 
            }  
        }else if(e.target.value === 'latest'){
            setTemplateSort({ createdAt : "DESC" })
            sortTemplates = {
                templatesFilters : {
                    templateSort : {
                        createdAt : "DESC"
                    },
                    pagination :{
                        offset : ((pageTemplates-1)*limitTemplates),
                        limit : limitTemplates
                    }
                } 
            }  
        }else if(e.target.value === 'oldest'){
            setTemplateSort({ templateName : "ASC" })
            sortTemplates = {
                templatesFilters : {
                    templateSort : {
                        createdAt : "ASC"
                    },
                    pagination :{
                        offset : ((pageTemplates-1)*limitTemplates),
                        limit : limitTemplates
                    }
                } 
            }  
        }
        dispatch(getAllTemplatesAction(sortTemplates))
    }

    const [allCompetitions, setAllCompetitions] = useState()

    useEffect(()=>{
        getallCompetitionExport();
        getAllTemplateExport();
    }, [])

    const getallCompetitionExport = () => {
        const exportCompetition = {
            competitionFilters : {
                pagination : {
        
                }
            }
        }
        axios.post(BASE_URL + GET_COMPETITION, exportCompetition) 
        .then(res=>{
            setAllCompetitions(res.data.competitionData)
        })   
    }

    const getAllTemplateExport = () => {
        const exportTemplate = {
            templatesFilters : {
                pagination : {
        
                }
            }
        }
        axios.post(BASE_URL + GET_TEMPLATES, exportTemplate) 
        .then(res=>{
            setAllTemplates(res.data.templateData)
        })   
    }


    const headersNew = [
        { label : 'Competition Id', key : 'id'},
        { label : 'Competition Name', key : 'competitionName'},
        { label : 'Minimum Player', key : 'minPlayers'},
        { label : 'Maximum Player', key : 'maxPlayers'},
        { label : 'Maximum Entry', key : 'maxEntryAllowed'},
        { label : 'Entry Fee', key : 'entryFee'},
        { label : 'Maximum Bonus', key : 'maxBonusLimit'},
        { label : 'No Of Winners', key : 'numberOfWinner'},
        { label : 'Hosting Fee', key : 'hostingFee'},
        { label : 'Hosting Fee Type', key : 'hostingFeeType'},
        { label : 'PD Mode', key : 'prizeDistributionMode'},
        { label : 'Start Date', key : 'startDate'},
        { label : 'End Date', key : 'endDate'},
        { label : 'Game Id', key : 'gameId'},
        { label : 'Game Name', key : 'game.gameName'},
        { label : 'Match Id', key : 'match.id'},
        { label : 'Match Name', key : 'match.matchName'},
      ]
     
    const csvReportNew = {
        filename : `Competition_Report_${new Date().toISOString().slice(0, 10)}.csv`,
        headers : headersNew,
        data : allCompetitions
    }

    const[allTemplates, setAllTemplates] = useState([])

    const headers = [
        { label : 'Template Id', key : 'id'},
        { label : 'Template Name', key : 'templatesName'},
        { label : 'Competition Format', key : 'competitionFormatTypeMaster.leaderBoardOutComeName'},
        { label : 'Minimum Player', key : 'minPlayers'},
        { label : 'Maximum Player', key : 'maxPlayers'},
        { label : 'Number of winners', key : 'numberOfWinner'},
        { label : 'Number of attempts', key : 'maxAttemptAllowed'},
        { label : 'Number of entries', key : 'maxEntryAllowed'},
        { label : 'Hosting fee per user', key : 'hostingFee'},
        { label : 'Hosting fee type', key : 'hostingFeeType'},
        { label : 'PD Mode', key : 'prizeDistributionMode'},
      ]
     
    const csvReport = {
        filename : `Templates_Report_${new Date().toISOString().slice(0, 10)}.csv`,
        headers : headers,
        data : allTemplates
    }


    const [competitionId , setCompetitionId] = useState('')
    const onToggleDelete = (competitionId) => {
        setCompetitionId(competitionId)
        setShowdeletemodal(!showdeletemodal)
    }

    const archiveCompetition = () => {
        const deleteCompetition = {
            id : competitionId,
            isSpecialEvent : false,
            archive: true
        }
        axios.post(BASE_URL + EDIT_COMPETITION , deleteCompetition)
        .then(res=>{
            toast.success(res.data.message)
            let allCompetition = {
                competitionFilters: {
                    competitionSort : competitionSort,
                    competitionFormatTypeMasterId : compFormat,
                    archive: "false",
                    pagination : {
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
            }
            dispatch(getallCompetitionAction(allCompetition))
        })
        setShowdeletemodal(false)
    }

    const [stopCompetitionId, setStopCompetition] = useState('')
    const [showdstopmodal, setShowstopmodel] = useState(false)
    
    const onToggleStop = (id) => {
        setStopCompetition(id)
        setShowstopmodel(!showdstopmodal)
    }

    const stopCompetitions = () => {
        // let stopCompetitionData = {
        //     competitionId : stopCompetitionId
        // }
        // dispatch(stopCompetitionAction(stopCompetitionData))
        let stopCompetitionData = {
            competitionId : stopCompetitionId
        }
        axios.post(BASE_URL + STOP_COMPETITION, stopCompetitionData)
        .then(res=>{
            toast.success("Competition Stopped Successfully")
            let allCompetition = {
                competitionFilters: {
                    competitionSort : competitionSort,
                    competitionFormatTypeMasterId : compFormat,
                    archive: "false",
                    pagination : {
                        offset : ((page-1)*limit),
                        limit : limit
                    }
                }
            }
            dispatch(getallCompetitionAction(allCompetition))
        })
        setShowstopmodel(false)
    }

    const [filterWorld, setFilterWorld] = useState([])
    const [filterMatchFormat, setFilterMatchFormat] = useState([])
    const [filterMatchOutcome, setFilterMatchOutcome] = useState([])
    const [filterLeaderboardOutcome, setFilterLeaderboardOutcome] = useState([])
    const [filterStatus, setFilterStatus] = useState([])

    const filterData = (world, matchFormat, matchOutcome, leaderboardOutcome, status) => {
        setFilterWorld(world)
        setFilterMatchFormat(matchFormat)
        setFilterMatchOutcome(matchOutcome)
        setFilterLeaderboardOutcome(leaderboardOutcome)
        setFilterStatus(status)

        let filterCompetition = {
            competitionFilters : {
                gameWorldId : world,
                matchFormatTypeMasterId : matchFormat,
                matchOutcomeTypeMasterId : matchOutcome,
                leaderboardOutcomeTypeMasterId : leaderboardOutcome,
                competitionStatus : status,
                competitionSort : competitionSort,
                competitionFormatTypeMasterId : compFormat,
                pagination :{
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
        }

        Object.keys(filterCompetition.competitionFilters).forEach((key) => (filterCompetition.competitionFilters[key].length === 0) && delete filterCompetition.competitionFilters[key])
        dispatch(getallCompetitionAction(filterCompetition))

        delete filterCompetition.competitionFilters.pagination.offset
        delete filterCompetition.competitionFilters.pagination.limit

        axios.post(BASE_URL + GET_COMPETITION, filterCompetition)
        .then(res=> {
            const pageNo = Math.ceil(res.data.competitionData.length/limit)
            setCounts(pageNo)
        })
    }

    const [tempCompetitionFormat, setTempCompetitionFormat] = useState([])
    const [tempPDMode, setTempPDMode] = useState([])

    const filterTemplateData = (competitionFormat, pdMode) => {
        
        setTempCompetitionFormat(competitionFormat)
        setTempPDMode(pdMode)
        
        let templateFilter = {
            templatesFilters : {
                competitionFormatTypeMasterId : competitionFormat,
                prizeDistributionMode : pdMode,
                pagination :{
                    offset : ((page-1)*limit),
                    limit : limit
                }
             }
        }

        Object.keys(templateFilter.templatesFilters).forEach((key) => (templateFilter.templatesFilters[key].length === 0) && delete templateFilter.templatesFilters[key])
        dispatch(getAllTemplatesAction(templateFilter))

        delete templateFilter.templatesFilters.pagination.offset
        delete templateFilter.templatesFilters.pagination.limit

        axios.post(BASE_URL + GET_TEMPLATES, templateFilter)
        .then(res=> {
            // const pageNo = Math.ceil(res.data.competitionData.length/limit)
            // setCounts(pageNo)
        })
        
    }

    const [showTemplate, setShowTemplate] = useState(false) 

    const onTemplateDetail = (id) => {
        dispatch(clearTemplateAction())
        setShowTemplate(!showTemplate)
        const templateById = {
            templatesFilters : {
                id : id,
                pagination :{
                    offset : ((pageTemplates-1)*limitTemplates),
                    limit : limitTemplates
                }
            }
        }
        dispatch(getTemplateByIdAction(templateById))
    }

    const onCopyTemplate = (e) => {
        let saveTemplate = {
            competitionFormatTypeMasterId: e.competitionFormatTypeMaster.id ,
            templateName: e.templatesName.concat("(1)"),
            minPlayers: e.minPlayers,
            maxPlayers: e.maxPlayers,
            maxEntryAllowed: e.maxEntryAllowed,
            entryFee: e.entryFee,
            maxBonusLimit: e.maxBonusLimit,
            numberOfWinner: e.numberOfWinner,
            hostingFee: e.hostingFee,
            hostingFeeType: e.hostingFeeType,
            hostingFeeprizeDistributionMode: e.prizeDistributionMode,
            prizeDistributionRule : e.prizeDistributionRule
        }
        dispatch(createTemplateAction(saveTemplate, props.history))
    }

    const [showLeaderboard, setShowLeaderboard] = useState(true)

    const onShowLeaderboard = () => {
        setPage(1)
        setCompFormat([2])
        setShowLeaderboard(true)
        setShowInstantBattle(false)
        setShowBracket(false)

        let allCompetition = {
            competitionFilters: {
                competitionSort : competitionSort,
                matchFormatTypeMasterId : filterMatchFormat,
                matchOutcomeTypeMasterId : filterMatchOutcome,
                leaderboardOutcomeTypeMasterId : filterLeaderboardOutcome,
                competitionFormatTypeMasterId : [2],
                archive: "false",
                pagination : {
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
        }
        Object.keys(allCompetition.competitionFilters).forEach((key) => (allCompetition.competitionFilters[key].length === 0) && delete allCompetition.competitionFilters[key]) 

        dispatch(getallCompetitionAction(allCompetition));


        let competitionPagination = {
            competitionFilters : {
                archive: "false",
                competitionFormatTypeMasterId : [2],
                pagination : {
        
                }
            }
        }
        axios.post(`${BASE_URL}/competition/getCompetition`, competitionPagination)
        .then(res=>{
          const compPageNo = Math.ceil(res.data.competitionData.length/limit)
          setCounts(compPageNo)
        })

    }

    const [showInstatBattle, setShowInstantBattle] = useState(false)

    const onShowInstatBattle = () => {
        setPage(1)
        setCompFormat([3])
        setShowInstantBattle(true)
        setShowLeaderboard(false)
        setShowBracket(false)

        let allCompetition = {
            competitionFilters: {
                competitionSort : competitionSort,
                matchFormatTypeMasterId : filterMatchFormat,
                matchOutcomeTypeMasterId : filterMatchOutcome,
                leaderboardOutcomeTypeMasterId : filterLeaderboardOutcome,
                competitionFormatTypeMasterId : [3],
                archive: "false",
                pagination : {
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
        }
        Object.keys(allCompetition.competitionFilters).forEach((key) => (allCompetition.competitionFilters[key].length === 0) && delete allCompetition.competitionFilters[key]) 

        dispatch(getallCompetitionAction(allCompetition));

        let competitionPagination = {
            competitionFilters : {
                archive: "false",
                competitionFormatTypeMasterId : [3],
                pagination : {
        
                }
            }
        }
        axios.post(`${BASE_URL}/competition/getCompetition`, competitionPagination)
        .then(res=>{
          const compPageNo = Math.ceil(res.data.competitionData.length/limit)
          setCounts(compPageNo)
        })
    }

    const [showBracket, setShowBracket] = useState(false)

    const onShowBracket = () => {
        setPage(1)
        setCompFormat([5])
        setShowBracket(true)
        setShowInstantBattle(false)
        setShowLeaderboard(false)


        let allCompetition = {
            competitionFilters: {
                competitionSort : competitionSort,
                matchFormatTypeMasterId : filterMatchFormat,
                matchOutcomeTypeMasterId : filterMatchOutcome,
                leaderboardOutcomeTypeMasterId : filterLeaderboardOutcome,
                competitionFormatTypeMasterId : [5],
                archive: "false",
                pagination : {
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
        }
        Object.keys(allCompetition.competitionFilters).forEach((key) => (allCompetition.competitionFilters[key].length === 0) && delete allCompetition.competitionFilters[key]) 

        dispatch(getallCompetitionAction(allCompetition));

        let competitionPagination = {
            competitionFilters : {
                archive: "false",
                competitionFormatTypeMasterId : [5],
                pagination : {
        
                }
            }
        }
        axios.post(`${BASE_URL}/competition/getCompetition`, competitionPagination)
        .then(res=>{
          const compPageNo = Math.ceil(res.data.competitionData.length/limit)
          setCounts(compPageNo)
        })

    }


    const [livetoggle, setLivetoggle] = useState(false)
    const [showStopModal, setShowStopModel] = useState(false)
    const [toggleState, setToggleState] = useState()
    const [compId, setCompId] = useState("")

    const onToggleLive = (evt, compId) => {
        setCompId(compId)
        if(evt == true){
            setLivetoggle(!livetoggle)
        }else if(evt == false){
            setShowStopModel(!showStopModal)
        }else if(evt == undefined){
            setLivetoggle(false)
            setShowStopModel(false)
        }
    }


    const saveLive = (date, time) => {
        let startDateData = date.concat(" ", time)
        let utcDate = moment.utc(moment(startDateData)).format("YYYY-MM-DD HH:mm:ss")
        
        let activeCompetition = {
            id : compId,
            startDate : utcDate.concat("+00:00")
        }
        
        dispatch(editCompetitionAction(activeCompetition, props.history))
    }

    const stopLive = (date, time) => {
        let stopDate = date.concat(" ", time)
        let utcDate = moment.utc(moment(stopDate)).format("YYYY-MM-DD HH:mm:ss")
        
        let stopCompetition = {
            id : compId,
            endDate : utcDate.concat("+00:00")
        }
        
        dispatch(editCompetitionAction(stopCompetition, props.history))
    }

    const [disableExport, setDisableExport] = useState(false)

    const exportData = () => {
        setDisableExport(true)
        setTimeout(() => {
          setDisableExport(false)
        }, 5000);
    }


    return (
        <>
            <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                        <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                        <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-dark" active>Competitions</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
                    <Row>
                        <Col>
                            <Button as={Link} to={Routes.CreateTemplates.path} className="me-2 button-style">
                                <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                                <span>Create Template</span>
                            </Button>
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} lg={6} className="px-0 mb-0 text-right"> 
                    {allCompetitions != undefined ? <Button disabled={disableExport ? true : false} onClick={exportData} className="export"><CSVLink {...csvReport}>Export</CSVLink></Button> : ""}
                </Col>
            </div>
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5 competition-main">
                {/* Template Section */}

                <div className="row mb-4 px-4">
                    <div className="col col-lg-6 col-md-12 text-left">
                        <p className='page-heading'>Templates</p>
                    </div>
                    <div className="col col-lg-6 col-md-12 text-right">
                        <span style={{cursor: "pointer"}} className={filterWorld.length != 0 || filterMatchFormat.length != 0 || filterMatchOutcome.length != 0 || filterLeaderboardOutcome.length != 0 ? 'applied-filter' : 'filter'} onClick={onToggleTemplateFilter}><i className="fas fa-filter mx-2"></i><span>Filter</span></span>
                    </div>
                </div>
                <Row className="my-3">
                    <Col>
                        <Row className="row d-flex px-4 align-items-center">
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                    <Form.Control type="text" placeholder="Search Templates" onChange={(e)=>{searchTemplates(e)}}/>
                                </InputGroup>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                <select className="form-control" onChange={(e)=>{sortTemplates(e)}}>
                                    <option>Sort By</option>
                                    <option value="AtoZ">Template Name (A-Z)</option>
                                    <option value="ZtoA">Template Name (Z-A)</option>
                                    <option value="latest">Created At (latest)</option>
                                    <option value="oldest">Created At (older)</option>
                                </select>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="mt-5 px-4">  
                    <Row className='table-responsive'>
                        <Col style={{overflowX : "scroll"}} lg={12} md={12} sm={12}>
                            <table className="table table-striped text-center table-custom">
                                <thead className='table-head'>
                                    <tr>
                                        <th className='head-border-left' scope="col">Created At</th>
                                        <th scope='col'>Template Name</th>
                                        <th scope='col'>Competition Format</th>
                                        <th scope='col'>Entry Fee</th>
                                        <th scope='col'>Minimum Player</th>
                                        <th scope='col'>Maximum Player</th>
                                        <th scope='col'>Number of Winners</th>
                                        <th scope='col'>Number of Attempts</th>
                                        <th scope='col'>Number of Entries</th>
                                        <th scope='col'>Hosting Fee Per User</th>
                                        <th scope='col'>PD Mode</th>
                                        <th className='head-border-right' scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className='table-body'>
                                    {templates && templates.map((e)=>{
                                        return(
                                            <tr key={e.id}>
                                                <td>{e.createdAt.slice(0,10).split("-").reverse().join("-")}</td>
                                                <td>{e.templatesName}</td>
                                                <td>{e.competitionFormatTypeMaster.leaderBoardOutComeName}</td>
                                                <td>{e.entryFee}</td>
                                                <td>{e.minPlayers}</td>
                                                <td>{e.maxPlayers}</td>
                                                <td>{e.numberOfWinner}</td>
                                                <td>{e.maxAttemptAllowed ? e.maxAttemptAllowed : "-"}</td>
                                                <td>{e.maxEntryAllowed}</td>
                                                <td>{e.hostingFee}</td>
                                                <td>{e.prizeDistributionMode}</td>
                                                <td>
                                                    <Dropdown className="ms-3">
                                                        <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <span className="icon icon-sm icon-dark">
                                                                <FontAwesomeIcon icon={faEllipsisH} />
                                                            </span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="dropdown-menu-right actions">
                                                            <Dropdown.Item className="ms-0" onClick={()=>{onTemplateDetail(e.id)}}>
                                                                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as={Link} to={{pathname: Routes.CreateCompetition.path, id: e.id}} className="ms-0">
                                                                <FontAwesomeIcon icon={faPlusCircle} className="me-2"/> Create Competition
                                                            </Dropdown.Item>
                                                            <Dropdown.Item disabled={e.competitionFormatTypeMaster.id == 5 ? true : false} as={Link} to={{pathname: Routes.EditTemplates.path, id: e.id}} className="ms-0">
                                                                <FontAwesomeIcon icon={faEdit} className="me-2"/> Edit Template
                                                            </Dropdown.Item>
                                                            <Dropdown.Item disabled={e.competitionFormatTypeMaster.id == 5 ? true : false} as={Link} className="ms-0" onClick={()=>{onCopyTemplate(e)}}>
                                                            <FontAwesomeIcon icon={faCopy} className="me-2"/> Copy Template
                                                            </Dropdown.Item>    
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )
                                    })}                                 
                                </tbody>
                            </table>
                        </Col>
                    </Row> 
                    <div className="d-flex justify-content-left my-5">
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <Pagination className="paginate" 
                                count={countsTemplates} 
                                defaultPage={pageTemplates}
                                color="info"  
                                variant="outlined" shape="rounded"
                                showFirstButton={false} 
                                showLastButton={false} 
                                onChange={(event, value)=> setPageTemplates(value)}
                                />
                            </Col>
                        </Row>
                    </div> 
                </div>  

                <div className='px-4 mb-5'>

                    <Row className='mb-5'>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <hr></hr>
                        </Col>
                    </Row>

                    <Row className='text-right'>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            {allCompetitions != undefined ? <Button disabled={disableExport ? true : false} onClick={exportData} className="export"><CSVLink {...csvReportNew}>Export</CSVLink></Button> : ""}
                        </Col>
                    </Row>

                </div>   

                {/* Competition Section */}
                <div className="row my-4 px-4">
                    <div className="col col-lg-6 col-md-12 text-left">
                        <p className='page-heading'>Competitions</p>
                    </div>
                    <div className="col col-lg-6 col-md-12 text-right">
                        <span style={{cursor: "pointer"}} className={filterWorld.length != 0 || filterMatchFormat.length != 0 || filterMatchOutcome.length != 0 || filterLeaderboardOutcome.length != 0 ? 'applied-filter' : 'filter'} onClick={onToggleCompetitionFilter}><i className="fas fa-filter mx-2"></i><span>Filter</span></span>
                    </div>
                </div>
                <Row className="my-4">
                    <Col>
                        <Row className="row d-flex px-4 align-items-center">
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                    <Form.Control type="text" placeholder="Search Competition" onChange={(e)=>{searchCompetition(e)}}/>
                                </InputGroup>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                <select className="form-control" onChange={(e)=>{sortCompetition(e)}}>
                                    <option>Sort By</option>
                                    <option value="AtoZ">Competition Name (A-Z)</option>
                                    <option value="ZtoA">Competition Name (Z-A)</option>
                                    <option value="lowHigh">Entry Fee (Low To High)</option>
                                    <option value="Highlow">Entry Fee (High To Low)</option>
                                    <option value="latest">Created At (latest)</option>
                                    <option value="oldest">Created At (older)</option>
                                </select>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div className="px-4">  

                    <Row className="my-5">
                        <Col lg={12} md={12} sm={12}>
                            <Row className="justify-content-left text-center">
                                <Col onClick={onShowLeaderboard} style={{cursor: "pointer"}} lg={3} md={3} sm={12}>
                                    <Nav fill variant="pills" className="flex-column flex-sm-row nav-accordian">
                                        <Nav.Item>
                                        <Nav.Link className={showLeaderboard ? "accordian-pills pills mb-sm-3 mb-md-0" : "pills mb-sm-3 mb-md-0"}>Tournament</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col onClick={onShowInstatBattle} style={{cursor: "pointer"}} lg={3} md={3} sm={12}>
                                    <Nav fill variant="pills" className="flex-column flex-sm-row nav-accordian">
                                        <Nav.Item>
                                            <Nav.Link className={showInstatBattle ? "accordian-pills pills mb-sm-3 mb-md-0" : "pills mb-sm-3 mb-md-0"}>Instant Battle</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col onClick={onShowBracket} style={{cursor: "pointer"}} lg={3} md={3} sm={12}>
                                    <Nav fill variant="pills" className="flex-column flex-sm-row nav-accordian">
                                        <Nav.Item>
                                            <Nav.Link className={showBracket ? "accordian-pills pills mb-sm-3 mb-md-0" : "pills mb-sm-3 mb-md-0"}>Tournament (Bracket)</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                            </Row>
                        </Col>
                    </Row> 


                    <Row className='table-responsive'>
                        <Col style={{overflowX : "scroll"}} lg={12} md={12} sm={12}>
                            {showLeaderboard == true ?
                                <table className="table table-striped text-center table-custom">
                                <thead className='table-head'>
                                    <tr>
                                        <th className='head-border-left' scope="col">{Competitions.Competition_Name}</th>
                                        <th scope="col">Competition Format</th>
                                        <th scope="col">Game Name</th>
                                        <th scope="col">Match Name</th>
                                        <th scope="col">Match Format</th>
                                        <th scope="col">Leaderboard Outcome Type</th>
                                        <th scope="col">Match Outcome Type</th>
                                        <th scope="col">Entry Fee</th>
                                        <th scope="col">Minimum Players</th>
                                        <th scope="col">Maximum Players</th>
                                        <th scope="col">PD Mode</th>
                                        <th scope="col">Hosting Fee Per User</th>
                                        <th scope="col">Start Date</th>
                                        <th scope="col">Start Time</th>
                                        <th scope="col">End Date</th>
                                        <th scope="col">End Time</th>
                                        <th scope='col'>No of slots</th>
                                        <th scope="col">Status</th>
                                        <th className='head-border-right' scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {isLoad === true && competition.length > 0 ? <Loader type="spinner-circle" bgColor={"#61DAFB"} title={"spinner-circle"} color={'#FFFFFF'} size={50} />
                                : isLoad === false && competition.length > 0 ? 
                                    competition && competition.map((e, i)=>{    
                                            return(
                                                <tr className='table-body'>
                                                    <td className='table-body'><b>{e.competitionName}</b></td>
                                                    <td>{e.competitionFormatTypeMasterId != null && e.competitionFormatTypeMasterId === 2 ? "Tournament" : e.competitionFormatTypeMasterId != null && e.competitionFormatTypeMasterId === 3 ? "Instant Battle"  :  e.competitionFormatTypeMasterId != null && e.competitionFormatTypeMasterId === 5 ? "Bracket" : "-"}</td>
                                                    <td>{e.match != null ? e.game.gameName : e.match == null ? "-" : "-"}</td>
                                                    <td>{e.match != null ? e.match.matchName : e.match == null ? "-" : "-"}</td>
                                                    <td>{e.match != null && e.match.matchFormatTypeMasterId === 1 ? "Single Player" : e.match != null && e.match.matchFormatTypeMasterId === 2 ? "Multiplayer" : ""}</td>
                                                    <td>{e.match != null && e.match.leaderboardOutcomeTypeMasterId === 1 ? "High Score" : e.match != null && e.match.leaderboardOutcomeTypeMasterId === 2 ? "Time Trail" : e.match != null && e.match.leaderboardOutcomeTypeMasterId === 3 ? "Win/Loss/Draw" : e.match != null && e.match.leaderboardOutcomeTypeMasterId === 4 ? "Podium Finish" : e.match != null && e.match.leaderboardOutcomeTypeMasterId === 5 ? "Winning  Collected" : e.match == null ? "-" : ""}</td>
                                                    <td>{e.match != null && e.match.matchOutcomeTypeMasterId === 1 ? "High Score" : e.match != null && e.match.matchOutcomeTypeMasterId === 2 ? "Time Trail" : e.match != null && e.match.matchOutcomeTypeMasterId === 3 ? "Win/Loss/Draw" : e.match != null && e.match.matchOutcomeTypeMasterId === 4 ? "Podium Finish" : e.match != null && e.match.matchOutcomeTypeMasterId === 5 ? "Winning  Collected" : e.match == null ? "-" : ""}</td>
                                                    <td>{e.entryFee}</td>
                                                    <td>{e.minPlayers}</td>
                                                    <td>{e.maxPlayers}</td>
                                                    <td>{e.prizeDistributionMode}</td>
                                                    <td>{e.hostingFee}</td>
                                                    {/* <td>{e.startDate.slice(0,10)}</td> */}
                                                    <td>{moment(e.startDate).local().format('DD-MM-YYYY')}</td>
                                                    <td>{moment(e.startDate).tz('Asia/Kolkata').format().slice(11,16)}</td>
                                                    <td>{moment(e.endDate).local().format('DD-MM-YYYY')}</td>
                                                    {/* <td>{e.endDate.slice(0,10)}</td> */}
                                                    <td>{moment(e.endDate).tz('Asia/Kolkata').format().slice(11,16)}</td>
                                                    <td>{e.totalNumberOfSlot != null  ? e.totalNumberOfSlot : "-"}</td>
                                                    <td>{e.competitionStatus === null ? "-" : e.competitionStatus != null ? <span className={e.competitionStatus == 'completed' ? 'success' : e.competitionStatus == 'in progress' ? 'danger' : e.competitionStatus == 'yet to start' ? 'warning' : e.competitionStatus == 'in review' ? 'warning' : e.competitionStatus == 'stopped' ? 'danger' : e.competitionStatus == 'failed' ? 'danger' : ''}>{e.competitionStatus}</span> : ""}</td>
                                                    <td>
                                                        <Dropdown className="ms-3">
                                                            <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <span className="icon icon-sm icon-dark">
                                                                <FontAwesomeIcon icon={faEllipsisH} />
                                                            </span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dropdown-menu-right actions">
                                                            <Dropdown.Item className="ms-0" onClick={()=>{onToggleCompetitionDetail(e.id)}}>
                                                                <FontAwesomeIcon icon={faEye} className="me-2 icon-color" /> View Details
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as={Link} to={{pathname:Routes.EditCompetition.path, id: e.id}} className="ms-0">
                                                                <FontAwesomeIcon icon={faEdit} className="me-2 icon-color"/> Edit
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as={Link} className="text-warning ms-0" onClick={()=>{onToggleStop(e.id)}}>
                                                            <FontAwesomeIcon icon={faBan} className="me-2 icon-color"/> Stop
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as={Link} className="text-danger ms-0" onClick={()=>{onToggleDelete(e.id)}}>
                                                            <FontAwesomeIcon icon={faTrash} className="me-2"/> Delete
                                                            </Dropdown.Item>
                                                                
                                                            {e.competitionsSlots && e.competitionsSlots.length > 0 ?
                                                                <div>
                                                                    <Dropdown.Item className="ms-0" as={Link} to={{pathname:Routes.CompetitionDetail.path, compId : e.competitionFormatTypeMasterId, id:e.leaderboard[0].id, competitionName: e.competitionName, competitionSlots : e.competitionsSlots}}>
                                                                    <FontAwesomeIcon icon={faEye} className="me-2 icon-color" /> View Current Status
                                                                    </Dropdown.Item>
                                                                    
                                                                    <Dropdown.Item as={Link} disabled={e.competitionStatus == 'completed' ? false : true} className="text-danger ms-0" to={{pathname:Routes.CompetitionResult.path, compId : e.competitionFormatTypeMasterId, result: e.competitionsSlots, competitionName: e.competitionName, amountDistributed: e.totalAmountDistributed, hostingFee: e.totalHostingFeeCollected, date: e.startDate, competitionSlots : e.competitionsSlots}}>
                                                                        <FontAwesomeIcon icon={faEye} className="me-2 icon-color"/> View Result
                                                                    </Dropdown.Item>
                                                                </div>
                                                            : 
                                                            <div>
                                                                <Dropdown.Item className="ms-0" as={Link} to={{pathname:Routes.CompetitionDetail.path, competitionName: e.competitionName, competitionSlots : [], compId : e.competitionFormatTypeMasterId}}>
                                                                <FontAwesomeIcon icon={faEye} className="me-2 icon-color" /> View Current Status
                                                                </Dropdown.Item>
                                                                
                                                                <Dropdown.Item as={Link} disabled={e.competitionStatus == 'completed' ? false : true} className="text-danger ms-0" to={{pathname:Routes.CompetitionResult.path, compId : e.competitionFormatTypeMasterId, competitionSlots : [], result: e.competitionsSlots, competitionName: e.competitionName, amountDistributed: e.totalAmountDistributed, hostingFee: e.totalHostingFeeCollected, date: e.startDate}}>
                                                                    <FontAwesomeIcon icon={faEye} className="me-2 icon-color"/> View Result
                                                                </Dropdown.Item>
                                                            </div> 
                                                            }
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    : competition.length == 0 ? "No Data Available"
                                    : ""}
                                </tbody>
                            </table>
                            : showInstatBattle == true ?
                            <table className="table table-striped text-center table-custom">
                                <thead className='table-head'>
                                    <tr>
                                        <th className='head-border-left' scope="col">{Competitions.Competition_Name}</th>
                                        <th scope="col">Competition Format</th>
                                        <th scope="col">Game Name</th>
                                        <th scope="col">Match Name</th>
                                        <th scope="col">Match Format</th>
                                        <th scope="col">Leaderboard Outcome Type</th>
                                        <th scope="col">Match Outcome Type</th>
                                        <th scope="col">Entry Fee</th>
                                        <th scope="col">Minimum Players</th>
                                        <th scope="col">Maximum Players</th>
                                        <th scope="col">PD Mode</th>
                                        <th scope="col">Hosting Fee Per User</th>
                                        {/* <th scope="col">Start Date</th>
                                        <th scope="col">Start Time</th>
                                        <th scope="col">End Date</th>
                                        <th scope="col">End Time</th> */}
                                        <th scope='col'>No of slots</th>
                                        <th scope="col">Live Toggle</th>
                                        <th scope="col">Status</th>
                                        <th className='head-border-right' scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {isLoad === true && competition.length > 0 ? <Loader type="spinner-circle" bgColor={"#61DAFB"} title={"spinner-circle"} color={'#FFFFFF'} size={50} />
                                : isLoad === false && competition.length > 0 ? 
                                    competition && competition.map((e, i)=>{    
                                            return(
                                                <tr className='table-body'>
                                                    <td className='table-body'><b>{e.competitionName}</b></td>
                                                    <td>{e.competitionFormatTypeMasterId != null && e.competitionFormatTypeMasterId === 2 ? "Tournament" : e.competitionFormatTypeMasterId != null && e.competitionFormatTypeMasterId === 3 ? "Instant Battle" : e.competitionFormatTypeMasterId != null && e.competitionFormatTypeMasterId === 5 ? "Bracket"  :"-"}</td>
                                                    <td>{e.match != null ? e.game.gameName : e.match == null ? "-" : "-"}</td>
                                                    <td>{e.match != null ? e.match.matchName : e.match == null ? "-" : "-"}</td>
                                                    <td>{e.match != null && e.match.matchFormatTypeMasterId === 1 ? "Single Player" : e.match != null && e.match.matchFormatTypeMasterId === 2 ? "Multiplayer" : ""}</td>
                                                    <td>{e.match != null && e.match.leaderboardOutcomeTypeMasterId === 1 ? "High Score" : e.match != null && e.match.leaderboardOutcomeTypeMasterId === 2 ? "Time Trail" : e.match != null && e.match.leaderboardOutcomeTypeMasterId === 3 ? "Win/Loss/Draw" : e.match != null && e.match.leaderboardOutcomeTypeMasterId === 4 ? "Podium Finish" : e.match != null && e.match.leaderboardOutcomeTypeMasterId === 5 ? "Winning  Collected" : e.match == null ? "-" : ""}</td>
                                                    <td>{e.match != null && e.match.matchOutcomeTypeMasterId === 1 ? "High Score" : e.match != null && e.match.matchOutcomeTypeMasterId === 2 ? "Time Trail" : e.match != null && e.match.matchOutcomeTypeMasterId === 3 ? "Win/Loss/Draw" : e.match != null && e.match.matchOutcomeTypeMasterId === 4 ? "Podium Finish" : e.match != null && e.match.matchOutcomeTypeMasterId === 5 ? "Winning  Collected" : e.match == null ? "-" : ""}</td>
                                                    <td>{e.entryFee}</td>
                                                    <td>{e.minPlayers}</td>
                                                    <td>{e.maxPlayers}</td>
                                                    <td>{e.prizeDistributionMode}</td>
                                                    <td>{e.hostingFee}</td>
                                                    {/* <td>{moment(e.startDate).local().format('YYYY-MM-DD')}</td>
                                                    <td>{moment(e.startDate).tz('Asia/Kolkata').format().slice(11,16)}</td>
                                                    <td>{moment(e.endDate).local().format('YYYY-MM-DD')}</td>
                                                    <td>{moment(e.endDate).tz('Asia/Kolkata').format().slice(11,16)}</td> */}
                                                    <td>{e.totalNumberOfSlot == null  ? "Unlimited" : e.totalNumberOfSlot != null  ? e.totalNumberOfSlot : "-"}</td>
                                                    <td><Switch disabled={e.competitionStatus == 'completed' ? true : false} checked={e.competitionStatus == 'yet to start' ? false : e.competitionStatus == 'in progress' ? true : ""} onChange={(evt)=>{onToggleLive(evt, e.id)}}/></td>
                                                    <td>{e.competitionStatus === null ? "-" : e.competitionStatus != null ? <span className={e.competitionStatus == 'completed' ? 'success' : e.competitionStatus == 'in progress' ? 'danger' : e.competitionStatus == 'yet to start' ? 'warning' : e.competitionStatus == 'in review' ? 'warning' : e.competitionStatus == 'stopped' ? 'danger' : e.competitionStatus == 'failed' ? 'danger' : ''}>{e.competitionStatus}</span> : ""}</td>
                                                    <td>
                                                        <Dropdown className="ms-3">
                                                            <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <span className="icon icon-sm icon-dark">
                                                                <FontAwesomeIcon icon={faEllipsisH} />
                                                            </span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dropdown-menu-right actions">
                                                            <Dropdown.Item className="ms-0" onClick={()=>{onToggleCompetitionDetail(e.id)}}>
                                                                <FontAwesomeIcon icon={faEye} className="me-2 icon-color" /> View Details
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as={Link} to={{pathname:Routes.EditCompetition.path, id: e.id}} className="ms-0">
                                                                <FontAwesomeIcon icon={faEdit} className="me-2 icon-color"/> Edit
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as={Link} className="text-warning ms-0" onClick={()=>{onToggleStop(e.id)}}>
                                                            <FontAwesomeIcon icon={faBan} className="me-2 icon-color"/> Stop
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as={Link} className="text-danger ms-0" onClick={()=>{onToggleDelete(e.id)}}>
                                                            <FontAwesomeIcon icon={faTrash} className="me-2"/> Delete
                                                            </Dropdown.Item>
                                                            {e.competitionsSlots && e.competitionsSlots.length > 0 ?
                                                                <div>
                                                                    <Dropdown.Item className="ms-0" as={Link} to={{pathname:Routes.CompetitionDetail.path, compId : e.competitionFormatTypeMasterId , id:e.leaderboard[0].id, competitionName: e.competitionName, competitionSlots : e.competitionsSlots}}>
                                                                    <FontAwesomeIcon icon={faEye} className="me-2 icon-color" /> View Current Status
                                                                    </Dropdown.Item>
                                                                    
                                                                    <Dropdown.Item as={Link} className="text-danger ms-0" to={{pathname:Routes.CompetitionResult.path, compId : e.competitionFormatTypeMasterId, result: e.competitionsSlots, competitionName: e.competitionName, amountDistributed: e.totalAmountDistributed, hostingFee: e.totalHostingFeeCollected, date: e.startDate, competitionSlots : e.competitionsSlots}}>
                                                                        <FontAwesomeIcon icon={faEye} className="me-2 icon-color"/> View Result
                                                                    </Dropdown.Item>
                                                                </div>
                                                            : 
                                                            <div>
                                                                <Dropdown.Item className="ms-0" as={Link} to={{pathname:Routes.CompetitionDetail.path, id:e.leaderboard[0].id, compId : e.competitionFormatTypeMasterId, competitionName: e.competitionName, competitionSlots : []}}>
                                                                <FontAwesomeIcon icon={faEye} className="me-2 icon-color" /> View Current Status
                                                                </Dropdown.Item>
                                                                
                                                                <Dropdown.Item as={Link} className="text-danger ms-0" to={{pathname:Routes.CompetitionResult.path, compId : e.competitionFormatTypeMasterId, competitionSlots : [], result: e.competitionsSlots, competitionName: e.competitionName, amountDistributed: e.totalAmountDistributed, hostingFee: e.totalHostingFeeCollected, date: e.startDate}}>
                                                                    <FontAwesomeIcon icon={faEye} className="me-2 icon-color"/> View Result
                                                                </Dropdown.Item>
                                                            </div> }
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    : competition.length == 0 ? "No Data Available": ""}
                                </tbody>
                            </table>
                            : showBracket == true ?
                            // <h5>Tournament(Bracket)</h5>
                            <table className="table table-striped text-center table-custom">
                                <thead className='table-head'>
                                    <tr>
                                        <th className='head-border-left' scope="col">{Competitions.Competition_Name}</th>
                                        <th scope="col">Competition Format</th>
                                        <th scope="col">Game Name</th>
                                        <th scope="col">Match Name</th>
                                        <th scope="col">Match Format</th>
                                        <th scope="col">Leaderboard Outcome Type</th>
                                        <th scope="col">Match Outcome Type</th>
                                        <th scope="col">Entry Fee</th>
                                        <th scope="col">Hosting Fee Per User</th>
                                        <th scope="col">Live Toggle</th>
                                        <th scope="col">Status</th>
                                        <th className='head-border-right' scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {isLoad === true && competition.length > 0 ? <Loader type="spinner-circle" bgColor={"#61DAFB"} title={"spinner-circle"} color={'#FFFFFF'} size={50} />
                                : isLoad === false && competition.length > 0 ? 
                                    competition && competition.map((e, i)=>{    
                                            return(
                                                <tr className='table-body'>
                                                    <td className='table-body'><b>{e.competitionName}</b></td>
                                                    <td>{e.competitionFormatTypeMasterId != null && e.competitionFormatTypeMasterId === 2 ? "Tournament" : e.competitionFormatTypeMasterId != null && e.competitionFormatTypeMasterId === 3 ? "Instant Battle" : e.competitionFormatTypeMasterId != null && e.competitionFormatTypeMasterId === 5 ? "Bracket"  :"-"}</td>
                                                    <td>{e.match != null ? e.game.gameName : e.match == null ? "-" : "-"}</td>
                                                    <td>{e.match != null ? e.match.matchName : e.match == null ? "-" : "-"}</td>
                                                    <td>{e.match != null && e.match.matchFormatTypeMasterId === 1 ? "Single Player" : e.match != null && e.match.matchFormatTypeMasterId === 2 ? "Multiplayer" : ""}</td>
                                                    <td>{e.match != null && e.match.leaderboardOutcomeTypeMasterId === 1 ? "High Score" : e.match != null && e.match.leaderboardOutcomeTypeMasterId === 2 ? "Time Trail" : e.match != null && e.match.leaderboardOutcomeTypeMasterId === 3 ? "Win/Loss/Draw" : e.match != null && e.match.leaderboardOutcomeTypeMasterId === 4 ? "Podium Finish" : e.match != null && e.match.leaderboardOutcomeTypeMasterId === 5 ? "Winning  Collected" : e.match == null ? "-" : ""}</td>
                                                    <td>{e.match != null && e.match.matchOutcomeTypeMasterId === 1 ? "High Score" : e.match != null && e.match.matchOutcomeTypeMasterId === 2 ? "Time Trail" : e.match != null && e.match.matchOutcomeTypeMasterId === 3 ? "Win/Loss/Draw" : e.match != null && e.match.matchOutcomeTypeMasterId === 4 ? "Podium Finish" : e.match != null && e.match.matchOutcomeTypeMasterId === 5 ? "Winning  Collected" : e.match == null ? "-" : ""}</td>
                                                    <td>{e.entryFee}</td>
                                                    <td>{e.hostingFee}</td>
                                                    <td><Switch disabled={e.competitionStatus == 'completed' ? true : false} checked={e.competitionStatus == 'yet to start' ? false : e.competitionStatus == 'in progress' ? true : ""} onChange={(evt)=>{onToggleLive(evt, e.id)}}/></td>
                                                    <td>{e.competitionStatus === null ? "-" : e.competitionStatus != null ? <span className={e.competitionStatus == 'completed' ? 'success' : e.competitionStatus == 'in progress' ? 'danger' : e.competitionStatus == 'yet to start' ? 'warning' : e.competitionStatus == 'in review' ? 'warning' : e.competitionStatus == 'stopped' ? 'danger' : e.competitionStatus == 'failed' ? 'danger' : ''}>{e.competitionStatus}</span> : ""}</td>
                                                    <td>
                                                        <Dropdown className="ms-3">
                                                            <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <span className="icon icon-sm icon-dark">
                                                                <FontAwesomeIcon icon={faEllipsisH} />
                                                            </span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dropdown-menu-right actions">
                                                            <Dropdown.Item className="ms-0" onClick={()=>{onToggleCompetitionDetail(e.id)}}>
                                                                <FontAwesomeIcon icon={faEye} className="me-2 icon-color" /> View Details
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as={Link} to={{pathname:Routes.EditCompetition.path, id: e.id}} className="ms-0">
                                                                <FontAwesomeIcon icon={faEdit} className="me-2 icon-color"/> Edit
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as={Link} className="text-warning ms-0" onClick={()=>{onToggleStop(e.id)}}>
                                                            <FontAwesomeIcon icon={faBan} className="me-2 icon-color"/> Stop
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as={Link} className="text-danger ms-0" onClick={()=>{onToggleDelete(e.id)}}>
                                                            <FontAwesomeIcon icon={faTrash} className="me-2"/> Delete
                                                            </Dropdown.Item>
                                                            {e.competitionsSlots && e.competitionsSlots.length > 0 ?
                                                                <div>
                                                                    <Dropdown.Item className="ms-0" as={Link} to={{pathname:Routes.CompetitionDetail.path, compId : e.competitionFormatTypeMasterId, id:e.leaderboard[0].id, competitionName: e.competitionName, competitionSlots : e.competitionsSlots}}>
                                                                    <FontAwesomeIcon icon={faEye} className="me-2 icon-color" /> View Current Status
                                                                    </Dropdown.Item>
                                                                    
                                                                    <Dropdown.Item as={Link} className="text-danger ms-0" to={{pathname:Routes.CompetitionResult.path, compId : e.competitionFormatTypeMasterId, result: e.competitionsSlots, competitionName: e.competitionName, amountDistributed: e.totalAmountDistributed, hostingFee: e.totalHostingFeeCollected, date: e.startDate, competitionSlots : e.competitionsSlots}}>
                                                                        <FontAwesomeIcon icon={faEye} className="me-2 icon-color"/> View Result
                                                                    </Dropdown.Item>
                                                                </div>
                                                            : 
                                                            <div>
                                                                <Dropdown.Item className="ms-0" as={Link} to={{pathname:Routes.CompetitionDetail.path, id:e.leaderboard[0].id, compId : e.competitionFormatTypeMasterId, competitionName: e.competitionName, competitionSlots : []}}>
                                                                <FontAwesomeIcon icon={faEye} className="me-2 icon-color" /> View Current Status
                                                                </Dropdown.Item>
                                                                
                                                                <Dropdown.Item as={Link} className="text-danger ms-0" to={{pathname:Routes.CompetitionResult.path, competitionSlots : [], compId : e.competitionFormatTypeMasterId,  result: e.competitionsSlots, competitionName: e.competitionName, amountDistributed: e.totalAmountDistributed, hostingFee: e.totalHostingFeeCollected, date: e.startDate}}>
                                                                    <FontAwesomeIcon icon={faEye} className="me-2 icon-color"/> View Result
                                                                </Dropdown.Item>
                                                            </div> }
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    : competition.length == 0 ? "No Data Available": ""}
                                </tbody>
                            </table>
                            : ""}
                        </Col>
                    </Row> 
                    <div className="d-flex justify-content-left my-4">
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <Pagination className="paginate" 
                                count={counts} 
                                defaultPage={page}
                                color="info"  
                                variant="outlined" shape="rounded"
                                showFirstButton={false} 
                                showLastButton={false} 
                                onChange={(event, value)=> setPage(value)}
                                />
                            </Col>
                        </Row>
                    </div>
                    
                </div>        
            </div>
            {showdeletemodal ? <DeleteCompetition Delete={archiveCompetition} addOpen={showdeletemodal} Addtoggle={onToggleDelete} /> : ""}
            {showdstopmodal ? <StopCompetition Stop={stopCompetitions} addOpen={showdstopmodal} Addtoggle={onToggleStop} /> : ""}
            <CompetitionDetail show={competitionDetail} onHide={onToggleCompetitionDetail}/>
            <TemplateDetail show={showTemplate} onHide={onTemplateDetail}/>
            <CompetitionFilter show={competitionFilter} onHide={onToggleCompetitionFilter} filtersCoupons={filterData} />
            <TemplateFilter show={templateFilter} onHide={onToggleTemplateFilter} filtersTemplate={filterTemplateData} />
            <CompetitionLiveModal show={livetoggle} onHide={onToggleLive} saveLive={saveLive}/>
            <EndLiveCompetitionModal show={showStopModal} onHide={onToggleLive} Stop={stopLive}/>
        </>
    )
}
