import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import { Link} from "react-router-dom";
import { Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from 'react-redux';
import { clearTemplateAction, getallCompetitionAction } from '../../../redux/action/competitionAction';

function TemplateDetail({show, onHide}) {

    const dispatch = useDispatch()

    let templates = useSelector((state)=>{ 
        return state.competition.templatesDetail
    })


    const close = () => {
        dispatch(clearTemplateAction())
        onHide();
    }

  
    if(templates && templates.length != 0){
        return(
            <Modal scrollable={true} contentClassName="new_modal" size="lg" centered show={show} onHide={onHide} className='competitiondetail-modal'>
            <Modal.Header>
                <div className="modal_head">
                    <span className='competitiondetail-heading'>Template Detail</span>
                </div>
            </Modal.Header>

            <Modal.Body className='competitiondetail-body'>
                <Row className='my-2'>
                    <Col lg={6}>
                        <label>Template Id</label>
                        <input className='form-control' defaultValue={templates[0].id != null ? templates[0].id : ''} disabled />
                    </Col>
                    <Col lg={6}>
                        <label>Template Name</label>
                        <input className='form-control' defaultValue={templates[0].templatesName} disabled  />
                    </Col>
                </Row>

                <Row className='my-2'>
                    <Col lg={6}>
                        <label>Entry Fee Total</label>
                        <input className='form-control' defaultValue={templates[0].entryFee} disabled />
                    </Col>
                    <Col lg={6}>
                        <label>Entry Fee Bonus Wallet Limit</label>
                        <input className='form-control' defaultValue={templates[0].maxBonusLimit} disabled />
                    </Col>
                </Row>
                <Row className='my-2'>
                    <Col lg={6}>
                        <label>Minimum Player</label>
                        <input className='form-control' defaultValue={templates[0].minPlayers} disabled />
                    </Col>
                    <Col lg={6}>
                        <label>Maximum Player</label>
                        <input className='form-control' defaultValue={templates[0].maxPlayers} disabled />
                    </Col>
                </Row>

                <Row className='my-2'>
                    <Col lg={6}>
                        <label>Number Of Attempts</label>
                        <input className='form-control' defaultValue={templates[0].maxAttemptAllowed} disabled />
                    </Col>
                    <Col lg={6}>
                        <label>Number Of Winners</label>
                        <input className='form-control' defaultValue={templates[0].numberOfWinner} disabled />
                    </Col>
                </Row>
                <Row className='my-2'>
                    <Col lg={6}>
                        <label>PD Mode</label>
                        <input className='form-control' defaultValue={templates[0].prizeDistributionMode} disabled />
                    </Col>
                    <Col lg={6}>
                        <label>Hosting Fee Per User</label>
                        <input className='form-control' defaultValue={templates[0].hostingFeeType} disabled />
                    </Col>
                </Row>

                <Row className='competition-main'>
                    <Col>
                        <label className='my-2'>Prize Distribution Rule : </label>
                        {templates[0] && templates[0].prizeDistributionRule.length > 0 ?
                            <table className='table table table-striped text-center table-custom'>
                                <thead className='table-head'>
                                    <tr>
                                        <th scope="col" className='head-border-left'>Start Rank</th>
                                        <th scope="col">End Rank</th>
                                        <th scope="col" className='head-border-right'>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {templates[0].prizeDistributionRule && templates[0].prizeDistributionRule.map((e,i)=>{
                                        return(
                                                <tr className='table-body' key={i}>
                                                    <td>{e.startRank}</td>
                                                    <td>{e.endRank}</td>
                                                    <td>{e.amount}</td>
                                                </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        : templates[0].prizeDistributionRule == true ? <p>No Data Available</p>
                        : "No Data Available."}
                    </Col>
                </Row>

            </Modal.Body>
        <Modal.Footer>
            <Row>
                <Col>
                        <Button className="btn btn-danger btn -sm mx-2" onClick={close}>Cancel</Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
    )
    }else{
        return(<span></span>)
    }
}

export default TemplateDetail