import ReferralServices from "../../services/ReferralServices";
import { GET_REFERRAL, ADD_REFERRAL, GET_REFERRAL_BY_ID, EDIT_REFERRAL } from "../actionType/actionTypes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getAllReferralAction = (body) => {
    return async(dispatch) => {
        await ReferralServices.getReferral(body)
        .then(res=>{
            dispatch(getReferral(res.data.data))
        })
    }
}

export const getReferralByIdAction = (body) =>{
    return async(dispatch) => {
        await ReferralServices.getReferralById(body)
        .then(res=>{
            dispatch(getReferralById(res.data.data))
        })
    }
}

export const AddReferralAction = (body) => {
    return async(dispatch) => {
        await ReferralServices.addReferral(body)
        .then(res=>{
            toast.success(res.data.message)
            // if(res.data.status === 201){
            //     window.scrollTo({ 
            //         top: document.documentElement.scrollHeight, 
            //         behavior: 'smooth'
            //     })
            // }
        })
        const refresh = {active : true}
        dispatch(getAllReferralAction(refresh))
    }
}

export const editReferralAction = (body) =>{
    return async(dispatch) => {
        await ReferralServices.editReferral(body)
        .then(res=>{
            toast.success(res.data.message)
            // if(res.data.status === 201){
            //     window.scrollTo({ 
            //         top: document.documentElement.scrollHeight, 
            //         behavior: 'smooth'
            //     });
            // }
            const refresh = {active : true}
            dispatch(getAllReferralAction(refresh))
        })
    }
}

export const getReferral = (data) => {
    return{
        type : GET_REFERRAL, 
        payload : data
    }
}

export const getReferralById = (data) => {
    return{
        type: GET_REFERRAL_BY_ID,
        payload:data
    }
}

export const addReferral = (data) => {
    return{
        type : ADD_REFERRAL, 
        payload : data
    }
}

export const editReferral = (data) => {
    return{
        type: EDIT_REFERRAL,
        payload: data
    }
}