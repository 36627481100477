import React from 'react'

const TablePagination = ({entryPerPage, totalEntry, Paginate, PaginatePrev, PaginateNext}) => {
    
    const pageNumber = [];
    for(var i= 1; i<=Math.ceil(totalEntry/entryPerPage); i++){
        pageNumber.push(i)
    }

    //pageNumberData(pageNumber)

    return (
        <div>
            <ul className="pagination">  
                {/* {pageNumber && pageNumber.map((e)=>{
                    return e <= 1 ? <li key={e} className="page-item"><a onClick={()=>{PaginatePrev()}} className="page-link">Prev</a></li> : ""
                })}   */}
                {pageNumber && pageNumber.map((e)=>{
                    return(
                        <li key={e} className="page-item">
                            <a onClick={()=>{Paginate(e)}} className="page-link">{e}</a>
                        </li>
                    )
                })}
                {/* {pageNumber && pageNumber.map((e)=>{
                    return e <= 1 ? <li key={e} className="page-item"><a onClick={()=>{PaginateNext()}} className="page-link">Next</a></li> : ""
                })}   */}
            </ul>
        </div>
    )
}

export default TablePagination