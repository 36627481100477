import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faSearch, faPlusCircle, faTrash, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col,InputGroup, Row, Nav, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import { Breadcrumb } from "react-bootstrap";
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Checkbox, Select, TextField } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CountryModal from '../modals/CountryModal';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createTeamAction } from '../../redux/action/teamsAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateTeams (props) {

    const dispatch = useDispatch();
    const history = useHistory();
    
    const icon = <CheckBoxOutlineBlankIcon fontSize = "small" />
    const checkedIcon = <CheckBoxIcon fontSize = "small" />

    const Countrydata = [{CountryName: 'India', id:1}]
    const[country, setCountry] = useState(Countrydata)
    const[countrynew, setCountryNew] = useState([])

    const [states, SetStates] = useState("")

    const [deleteShow, setDeleteShow] = useState(false);

    const handleDeleteClose = () => {
      setDeleteShow(false)
    }
  
    const handleDeleteShow = () => {
      setDeleteShow(true)
    }

    /* Data to DB */
    const [stateData, setStateData] = useState([])
    const a = (data) =>{

        setStateData(data)
    }

    /* Data for Mapping */
    const [exclude, setExclude] = useState([])
    const statesShow = (data) => {
        setExclude(data)
    }

    useEffect(() => {
        getStates();
    }, [])

    const getStates = async() => {
        await axios.get('https://dev.gamestarz.xyz/v1/static-values')
        .then(res=>{
            SetStates(res.data.states)
        })
    }

    const [teamName, setTeamName] = useState("")
    const [teamNameErr, setTeamNameErr] = useState(false)

    const onTeamName = (e) => {
        if(e.target.value == '' || e.target.value == null){
            setTeamNameErr(true)
        }else{
            setTeamName(e.target.value)
            setTeamNameErr(false)
        }
    }

    const [teamType, setTeamType] = useState('')

    const onTeamType = (e) => {
        setTeamType(e.target.value)
    }

    const validation = () => {
        if(teamName == '' || teamName == null){
            setTeamNameErr(true)
        }
    }

    const createTeam = () => {
        
        let newTeam = {
            teamName : teamName
        }

        if(newTeam.teamName == ""){
            validation();
        }else{
            dispatch(createTeamAction(newTeam, props.history))
        }

    }

    return (
            <>
                <ToastContainer />
                <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                    <Row>
                        <Col lg={12}>
                            <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                                <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                                <Breadcrumb.Item href='#teams'>Teams</Breadcrumb.Item>
                                <Breadcrumb.Item className="text-dark" active>Create Teams</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </div>
                <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5 create-competition">
                    <div className="container">
                        <Row className="mb-3">
                            <Col lg={12} md={12}>
                                <p className='page-title'>Create Team</p>
                            </Col>
                        </Row>

                        <Row className='my-3'>

                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <label className='my-2'>Team Name</label>
                                <input type ='text' className='form-control input_bg' onChange={(e)=>{onTeamName(e)}}/>
                                {teamNameErr == true ? <span className='text-danger'>*Team name is required.</span> : ''}
                            </Col>

                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <label className='my-2'>Add Parameter</label>
                                <select className='form-select input_bg' onChange={(e)=>{onTeamType(e)}}>
                                    <option value=''>select team type</option>
                                    <option value='location'>Location</option>
                                    <option value='house'>House</option>
                                </select>
                            </Col>
                            
                        </Row>
                        {teamType == 'location' ?
                        <Row className='my-3'>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                <label className='my-2'>Country</label>
                                {/* <input type ='text' className='form-control input_bg' /> */}
                                <Autocomplete className="input-no_bg" multiple disableCloseOnSelect filterSelectedOptions 
                                options={country} getOptionLabel={(option)=> option.CountryName}
                                onChange={(e,newvalue)=>{setCountryNew(newvalue)}}
                                renderOption={(option, {selected})=>(
                                <React.Fragment>
                                    <Checkbox icon={icon}
                                        checkedIcon={checkedIcon} style={{marginLeft: "10px"}}
                                        checked={selected}  allowSelectAll={true} onClick={() => handleDeleteShow()}
                                        />
                                        {option.CountryName}
                                </React.Fragment>  
                                    
                                )}
                                renderInput={(params)=>(<TextField {...params} variant="outlined" />)}/>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                <label className='my-2'>State</label>
                                {/* <input type ='text' className='form-control input_bg' /> */}
                                <Autocomplete disabled={countrynew.length > 0 ? true : false} className="input-no_bg" multiple disableCloseOnSelect filterSelectedOptions 
                                options={states} getOptionLabel={(option)=> option.stateName}
                                renderOption={(option, {selected})=>(
                                <React.Fragment>
                                    <Checkbox icon={icon}
                                        checkedIcon={checkedIcon} style={{marginLeft: "10px"}}
                                        checked={selected}  allowSelectAll={true}
                                        />
                                        {option.stateName}
                                </React.Fragment>  
                                    
                                )}
                                renderInput={(params)=>(<TextField {...params} variant="outlined" />)}/>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                <label className='my-2'>City</label>
                                {/* <input type ='text' className='form-control input_bg' /> */}
                                <Autocomplete disabled={countrynew.length > 0 ? true : false} className="input-no_bg" multiple disableCloseOnSelect filterSelectedOptions 
                                options={country} getOptionLabel={(option)=> option.CountryName}
                                onChange={(e,newvalue)=>{setCountryNew(newvalue)}}
                                renderOption={(option, {selected})=>(
                                <React.Fragment>
                                    <Checkbox icon={icon}
                                        checkedIcon={checkedIcon} style={{marginLeft: "10px"}}
                                        checked={selected}  allowSelectAll={true} onClick={() => handleDeleteShow()}
                                        />
                                        {option.CountryName}
                                </React.Fragment>  
                                    
                                )}
                                renderInput={(params)=>(<TextField {...params} variant="outlined" />)}/>
                            </Col>
                        </Row> : ''}

                        <Row className='my-3'>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Button className='btn btn-success' onClick={createTeam}>Create Team</Button>
                                <Button className='btn btn-danger mx-4'>Cancel</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
                <CountryModal show={deleteShow} onHide={handleDeleteClose} filter={a} ExcludedState={statesShow}></CountryModal>
            </>
    )
}

export default CreateTeams
