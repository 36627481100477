import { GET_TEAM, GET_TEAM_BY_ID, CREATE_TEAM, EDIT_TEAM } from "../actionType/actionTypes";

const initialState = {
    teams : [],
    isLoading : false
}

export const teamsReducer = (state = initialState, action) => {

    switch(action.type){
        
        case GET_TEAM : {
            return {
                ...state,
                teams : action.payload,
                isLoading : true
            }
        }

        case GET_TEAM_BY_ID : {
            return {
                ...state,
                teams : action.payload,
                isLoading : true
            }
        }

        case CREATE_TEAM : {
            return {
                ...state,
                teams : action.payload,
                isLoading : true
            }
        }

        case EDIT_TEAM : {
            return {
                ...state,
                teams : action.payload,
                isLoading : true
            }
        }

        default : {
            return {
                ...state
            }
        }

    }

}
