import React, {useState} from 'react';
import {Modal, ModalHeader, ModalBody } from 'reactstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import '../css/AddGame.css'

function LivetoggleModal(props) {
    
    const[showLive, setShowLive] = useState()
    
    const setLiveNow = (e) => {
        setShowLive(false)
    }

    const setLiveLater = (e) => {
        setShowLive(true)
    }

    return (
        <React.Fragment>
            <Modal isOpen={props.addOpen} toggle={props.Addtoggle} centered>
                <ModalHeader><h6>Set Live Toggle</h6></ModalHeader>
                <ModalBody>
                <Row className="justify-content-center">
                    <Col>
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <h6>Set Live Toggle</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <input type="radio" name="live" onClick={(e)=>{setLiveNow(e)}}/>
                                <label className="mx-2" for="live">Live Now</label>
                            </Col>
                        </Row>
                         <Row>
                             <Col xs={12} md={12} lg={12}>
                                <input type="radio" name="live" onClick={(e)=>{setLiveLater(e)}}/>
                                <label className="mx-2" for="live">Schedule Live Time</label>
                             </Col>
                         </Row>
                         {showLive ? 
                            <div>
                                <Row className="my-2">
                                    <Col>
                                        <div className="form-group">
                                            <label>Set Date</label>
                                            <input type="date" className="form-control"></input>
                                        </div>
                                     </Col>
                                 </Row>
                                 <Row className="my-2">
                                    <Col>
                                        <div className="form-group">
                                            <label>Set Time</label>
                                            <input type="time" className="form-control"></input>
                                        </div>
                                    </Col>
                                 </Row>
                            </div>
                         : ""}
                         <Row className="text-right">
                             <Col>
                                 <button className="btn btn-success m-2" disabled={showLive === false ? false : true} onClick={props.save}>Save</button>
                                 <button className="btn btn-danger m-2"  onClick={props.cancel}>Cancel</button>
                             </Col>
                         </Row>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default LivetoggleModal
