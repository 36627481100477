import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faSearch, faPlusCircle, faTrash, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col,InputGroup, Row, Nav, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import { Breadcrumb } from "react-bootstrap";
import { Link , useHistory} from "react-router-dom";
import { Routes } from "../../../routes";
import {useDispatch, useSelector} from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import axios from '../../../utlis/interceptor'
import { BASE_URL, GET_ALL_CONDITIONTYPE, GET_RULE } from '../../../resources/APIEndpoints';
import { getAllRuleByModuleAction, getRuleDetailAction } from '../../../redux/action/businessLogicAction';
import AttributeDetailModal from '../../modals/BusinessLogicModal/AttributeDetailModal';
import RuleDetailModal from '../../modals/BusinessLogicModal/RuleDetailModal';
import Pagination from '@mui/material/Pagination'

export default function EditModule(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const [moduleName, setModuleName] = useState("")

    const getModuleById = async() => {

        let moduleDetail = {
            conditionTypeFilters : {
                id : [props.location.id],
                pagination : {
                    offset : 0,
                    limit : 10
                }
            }
        }

        let res = await axios.post(BASE_URL + GET_ALL_CONDITIONTYPE, moduleDetail)
        setModuleName(res.data.conditionDetails[0].conditionName)
    }

    const [showToggleRule, setShowToggleRule] = useState(false)

    const onToggleRule = (id) => {
        setShowToggleRule(!showToggleRule)

        let getAllRule = {
            ruleFilters : {
                id : [id],
                conditionTypeId : [props.location.id],
                pagination : {
                    offset : 0,
                    limit : 10
                }
            }
        }

        dispatch(getRuleDetailAction(getAllRule))

    }

    /* Get All Rules and Pagination */

    const getRuleByModule = () => {

        let getAllRule = {
            ruleFilters : {
                conditionTypeId : [props.location.id],
                pagination : {
                    offset : ((page-1)*limit),
                    limit : limit
                }
            }
        }

        dispatch(getAllRuleByModuleAction(getAllRule))

    }

    let rule = useSelector((state)=>{
        return state.businessLogic.rules
    })

    const [page, setPage] = useState(1)
    const [counts, setCounts] = useState()
    const [limit, setLimit] = useState(10)

    const paginateRules = async() => {

        let paginateRule = {
            ruleFilters : {
                conditionTypeId : [props.location.id],
                pagination : {}
            }
        }

        let res = await axios.post(BASE_URL + GET_RULE, paginateRule)

        const pageNo = Math.ceil(res.data.ruleDetails.length/limit)
        setCounts(pageNo)

    }

    const cancel = () => {
        history.push(Routes.BusinessLogic.path)
    }

    useEffect(()=>{
        getModuleById();
        getRuleByModule();
        paginateRules();
    }, [page])

    return (
        <>
        <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                        <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                            <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                            <Breadcrumb.Item href="#business-logic">Business Logic</Breadcrumb.Item>
                            <Breadcrumb.Item className="text-dark" active>Decision</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>

            <div className="d-lg-flex justify-content-end flex-wrap flex-md-nowrap align-items-center py-4">
                
            </div>
            <div className="task-wrapper border bg-white border-light shadow-sm py-5 rounded gamestarz">
                <div className="row mb-4 px-4">
                    <div className="col col-lg-6 col-md-12 text-left">
                        <h4>Edit Module</h4>
                    </div>
                    <div className="col col-lg-6 col-md-12 col-sm-12 text-right">

                    </div>
                </div>

                <div className="container-fluid payout">

                    <Row className='my-2'>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <label className='my-2'>Module Name</label>
                            <input type='text' className='form-control' disabled defaultValue={moduleName} onChange={(e)=>{setModuleName(e.target.value)}}/>
                        </Col>
                    </Row>

                    {/* <Row className='my-3'>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <Button className='btn btn-danger' onClick={cancel}>Cancel</Button>
                        </Col>
                    </Row> */}

                    <Row className='my-4'>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <Button as={Link} to={{pathname : Routes.Rules.path, id: props.location.id}} className="button-style me-3" style={{width: "200px", display: "Inline !important"}}>
                                <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                                <span>Rule</span>
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <table className="table table-striped text-center table-custom">
                                <thead className='table-head'>
                                    <tr>
                                        <th className='head-border-left' scope="col">Rule Name</th>
                                        <th scope='col'>Condition Type</th>
                                        <th scope='col'>Created At</th>
                                        <th className='head-border-right' scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className='table-body'>
                                    {rule && rule.map((e, i)=>{
                                        return(
                                            <tr key={i}>
                                                <td>{e.ruleName}</td>
                                                <td>{e.conditionType != null ? e.conditionType.conditionName : ''}</td>
                                                <td>{e.createdAt.slice(0,10).split("-").reverse().join("-")}</td>
                                                <td>
                                                    <Dropdown className="ms-3">
                                                        <Dropdown.Toggle as={Button} variant="link" className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <span className="icon icon-sm icon-dark">
                                                                <FontAwesomeIcon icon={faEllipsisH} />
                                                            </span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="dropdown-menu-right">
                                                            <Dropdown.Item as={Link}  className="ms-0" onClick={(   )=>{onToggleRule(e.id)}}>
                                                                <FontAwesomeIcon icon={faEye} className="me-2"/> View
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as={Link} to={{pathname : Routes.EditRule.path, id: e.id}} className="text-warning ms-0">
                                                                <FontAwesomeIcon icon={faEdit} className="me-2"/> Edit
                                                            </Dropdown.Item>
                                                            {/* <Dropdown.Item as={Link}  className="text-danger ms-0">
                                                                <FontAwesomeIcon icon={faTrash} className="me-2"/> Delete
                                                            </Dropdown.Item> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>   
                                                </td>
                                            </tr>
                                        )
                                    })} 
                                </tbody>
                            </table>
                        </Col>
                    </Row>

                    <div className="d-flex justify-content-left m-4">
                        <Row>
                            <Col>
                                <Pagination className="paginate"
                                count={counts} 
                                defaultPage={page} 
                                color="info" 
                                variant="outlined" shape="rounded"
                                showFirstButton={false} 
                                showLastButton={false} 
                                onChange={(event, value)=> setPage(value)}
                                />
                            </Col>
                        </Row>
                    </div>

                    <Row className='my-3'>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <Button className='btn btn-danger' onClick={cancel}>Cancel</Button>
                        </Col>
                    </Row>

                </div>
            </div>

            <RuleDetailModal show={showToggleRule} onHide={onToggleRule}/>
        </>
    )
}
