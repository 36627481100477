import axios from '../utlis/interceptor'
import { BASE_URL, GET_REFERRAL, ADD_REFERRAL, EDIT_REFERRAL } from "../resources/APIEndpoints";

function ReferralServices() {
    this.getReferral = (body) => axios.post(BASE_URL + GET_REFERRAL, body)
    this.getReferralById = (body) => axios.post(BASE_URL + GET_REFERRAL, body)
    this.addReferral = (body) => axios.post(BASE_URL + ADD_REFERRAL, body)
    this.editReferral = (body) => axios.post(BASE_URL + EDIT_REFERRAL, body)
}

export default new ReferralServices()