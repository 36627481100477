import React, {useState, useEffect} from 'react'
import {Row, Col, ButtonGroup, Button, InputGroup, Form, Pagination, Nav} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch, faPlus, faPlusCircle, faArrowCircleDown, faAngleDown} from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../routes";
import { Link } from 'react-router-dom';
import {Game_Id,Game_Name, Game_Logo, Game_Description, Game_How_to, Preview_Video, Game_Screenshots, Game_Platform, Asset_Bundle_Version, Minimum_App_Version, Game_Genre, Screen_Orientation, Game_World, Matches_Created, Match_Name, Match_Format, Match_How_To, Match_Outcome_Type, Leaderboard_Outcome_Type, Included_Countries, Phase, Save, Save_Match, Save_As_Draft, Cancel, Min_Players, Max_Players, Podium_Rank_Match_Outcome, Podium_Rank_Leaderboard_Outcome, Rank, Points, Win_Points, Draw_Points, Loss_Points} from './../constants/LabelConstants'
import XLSX from 'xlsx'
import MaterialTable from "material-table";
import  TablePagination  from './components/TablePagination';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Checkbox, Select } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CountryModal from './modals/CountryModal';
import {useDispatch, useSelector} from 'react-redux';
import { getallGamesAction, GameByIdAction } from '../redux/action/gameAction';
import { createCompetitionAction, createTemplateAction } from '../redux/action/competitionAction';
import axios from '../utlis/interceptor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import moment from 'moment'
import { Breadcrumb } from "react-bootstrap";
import { BASE_URL, GET_TEAM, GET_TEMPLATES } from '../resources/APIEndpoints';
import TextField from '@mui/material/TextField';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Bracket, RoundProps } from 'react-brackets';
import customAxios from '../utlis/interceptor';
import { getTeamsAction } from '../redux/action/teamsAction';

export default function CreateCompetition(props) {

    const history = useHistory();
    const dispatch = useDispatch();
    
    const Countrydata = [{CountryName: 'India', id:1}]
    const[country, setCountry] = useState(Countrydata)
    const[countrynew, setCountryNew] = useState([])
    const[countryError, setCountryError] = useState(false);

    const icon = <CheckBoxOutlineBlankIcon fontSize = "small" />
    const checkedIcon = <CheckBoxIcon fontSize = "small" />

    const[colTitle, setColTitle] = useState()
    const[data, setData] = useState([])
    const[checkAmount, setCheckAmount] = useState()

    const convertToJson = (headers, data) =>{
        const rows = []
        data && data.forEach(row=>{
            if(row.length != 0){
                let rowData = {}
                row.forEach((ele, index)=>{
                    rowData[headers[index]] = ele;
                })
                rows.push(rowData)
            }
        })
        setCheckAmount(rows)
        return rows

    }

    const [fileName, setFilename] = useState("")

    const onCSV = (e) => {
        var file = e.target.files[0];
        setFilename(e.target.files[0].name)
        const reader = new FileReader()
        reader.onload=(event)=>{
            const bstr = event.target.result
            const workBook = XLSX.read(bstr, {type:"binary"})

            const worksheetName = workBook.SheetNames[0]
            const workSheet = workBook.Sheets[worksheetName]

            const fileData = XLSX.utils.sheet_to_json(workSheet, {header:1})

            const headers = fileData[0]
            fileData.splice(0,1)
            
            setData(convertToJson(headers, fileData))

            const heads = headers.map(head=>({title:head, field: head}))
            setColTitle(heads)
        }
        reader.readAsBinaryString(file)  
        setPrizeError(false);
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [entryPerPage, setEntryPerPage] = useState(10)


    const indexOfLastEntry = currentPage * entryPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entryPerPage;
    const currentEntry = data.slice(indexOfFirstEntry, indexOfLastEntry)
 
    const Paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const changePageEntry = (e) => {
        const chanegedPage = parseInt(e.target.value)
        setEntryPerPage(chanegedPage)
    }

    const [deleteShow, setDeleteShow] = useState(false);

    const handleDeleteShow = () => {
        setDeleteShow(true)
    }

    const handleDeleteClose = () => {
        setDeleteShow(false)
    }

    /* Data to DB */
    const [stateData, setStateData] = useState([])
    const a = (data) =>{
        setStateData(data)
    }

    /* Data for Mapping */
    const [exclude, setExclude] = useState([])
    const statesShow = (data) => {
        setExclude(data)
    }

    const [PrizeError, setPrizeError] = useState(false)
    /* Data Set */
    const [GameName, setGameName] = useState("")
    const [GameError, setGameError] = useState(false)
    const onGameName = (e) => {
        setGameName(e.target.value)
        setGameError(false)
        let GameNameFilter = {
            gameFilters : {
                id : [e.target.value],
                pagination : {

                }
            }
        }
        dispatch(GameByIdAction(GameNameFilter))
    }

    let gamesDetail = useSelector((state)=>{ 
        return state.game.gameDetail
    })


    const [winningCut, setWiningCut] = useState("")
    const onWinningCut = (e) => {
        setWiningCut(e.target.value)
    }

    const [Competition, setCompetition] = useState('')
    const [CompetitionError , setCompetitionError] = useState(false)
    
    const onCompetitionChange = (e) => {
        setCompetition(parseInt(e.target.value))
        setCompetitionError(false)

        setcompetitionNameError(false)
        setEntryFeeError(false)
        setBonusError(false)
        setMinError(false)
        setMaxError(false)
        setEntryError(false)
        setPDError(false)
        setWinnerError(false)
        setStartDateError(false)
        setEndDateError(false)
        setStartTimeError(false)
        setEndTimeError(false)
        setUserFeeError(false)
        setHostingError(false)
        setCountryError(false)
        setPrizeError(false)
        setHoursError(false)
        setMinutesError(false)
        setHostError(false)
        setTeamError(false)


    }

    
    
    const [compEvent, setCompEvent] = useState("")
    const [MatcheName, setMatchName] = useState('')
    const [MatchError, setMatchError] = useState(false);
    const onMatches = (e) => {
        setMatchName(e.target.value)
        setMatchError(false)
    }

    const [UserFee, setUserFee] = useState("")
    const [userFeeError, setUserFeeError] = useState(false);
    const onHostingFeeUser = (e) => {
        setUserFee(e.target.value)
        setUserFeeError(false)
    }  
    
    const [competitioName, setCompetitionName] = useState("")
    const [competitionNameError, setcompetitionNameError] = useState(false);
    const onCompetitionName = (e) =>{
        setCompetitionName(e.target.value)
        setcompetitionNameError(false)
    }
    
    const [entryFee, setEntryFee] = useState("")
    const [entryFeeError, setEntryFeeError] = useState(false)
    const onEntryFee = (e) =>{
        setEntryFee(parseInt(e.target.value))
        setEntryFeeError(false)
    }

    const [bonusWallet, setBonusWallet] = useState("")
    const [BonusError, setBonusError] = useState(false)
    const onBonusWallet = (e) =>{
        setBonusWallet(parseInt(e.target.value))
        setBonusError(false)
    }

    const [minPlayer, setMinPlayer] = useState("")
    const [minError, setMinError] = useState(false)
    const onMinPlayer = (e) =>{
        setMinPlayer(parseInt(e.target.value))
        setMinError(false)
    }

    const [maxPlayer, setMaxPlayer] = useState("")
    const [maxError, setMaxError] = useState(false)
    const onMaxPlayer = (e) =>{
        setMaxPlayer(parseInt(e.target.value))
        setMaxError(false)
    }

    const [NumberEntry, setNumberEntry] = useState("")
    const [attempts, setAttempts] = useState("")
    const [EntryError, setEntryError] = useState(false)
    const onNumberEntry = (e) =>{
        setNumberEntry(parseInt(e.target.value))
        setEntryError(false)
    }

    const [winners, setWinners] = useState("")
    const [winnerError, setWinnerError] = useState(false)
    const onWinners = (e) =>{
        setWinners(parseInt(e.target.value))
        setWinnerError(false)
    }

    const [startDate, setStartDate] = useState("null")
    const [startDateError, setStartDateError] = useState(false)

    const onStartDate = (e) =>{
        setStartDate(e.target.value)
        setStartDateError(false)

        let compStartDate = e.target.value

        let compStart = compStartDate.concat(" ", startTime)
        let compEnd = endDate.concat(" ", endTime)

        let start  = moment(compStart)
        let end = moment(compEnd)

        console.log(end.diff(start, 'hours'), "Start Date")

        if(compMode == 2 && compInterval == 1){
            if(end.diff(start, 'hours') < 24){
                console.log("Inside Daily --> Set Start Date")
                setStartDate(e.target.value)
            }else{
                if(startDate == 'null'){
                    setStartDate(e.target.value)
                }else{
                    toast.error('Competition time must be less than 24 hrs')
                }
            }
        }else if(compMode == 2 && compInterval == 2){
            if(end.diff(start, 'hours') > 24){
                console.log("Inside Weekly --> Set Start Date")
                setStartDate(e.target.value)
            }else{
                if(startDate == 'null'){
                    setStartDate(e.target.value)
                }else{
                    toast.error('Competition time must be greater than 24 hrs')
                }
            }
        }else{
            setStartDate(e.target.value)
            setStartDateError(false)
        }

    }

    const [startTime, setStartTime] = useState("null")
    const [startTimeError, setStartTimeError] = useState(false)

    const onStartTime = (e) =>{
        setStartTime(e.target.value)
        setStartTimeError(false)

        let compStartTime = e.target.value

        let compStart = startDate.concat(" ", compStartTime)
        let compEnd = endDate.concat(" ", endTime)

        let start  = moment(compStart)
        let end = moment(compEnd)

        console.log(end.diff(start, 'hours'), "Start Time")

        if(compMode == 2 && compInterval == 1){
            if(end.diff(start, 'hours') < 24){
                console.log("Inside Daily --> Set Start Time")
                setStartTime(e.target.value)
            }else{
                if(startTime == 'null'){
                    setStartTime(e.target.value)
                }else{
                    toast.error('Competition time must be less than 24 hrs')
                }
            }
        }else if(compMode == 2 && compInterval == 2){
            if(end.diff(start, 'hours') > 24){
                console.log("Inside Weekly --> Set Start Time")
                setStartTime(e.target.value)
            }else{
                if(startTime == 'null'){
                    setStartTime(e.target.value)
                }else{
                    toast.error('Competition time must be greater than 24 hrs')
                }
            }
        }else{
            setStartTime(e.target.value)
            setStartTimeError(false)
        }
        // setStartTime(e.target.value)
        // setStartTimeError(false)
    }

    const [endDate, setEndDate] = useState("null")
    const [endDateError, setEndDateError] = useState(false)

    const onEndDate = (e) =>{
        setEndDate(e.target.value)
        setStartTimeError(false)

        let compEndDate = e.target.value

        let compStart = startDate.concat(" ", startTime)
        let compEnd = compEndDate.concat(" ", endTime)

        let start  = moment(compStart)
        let end = moment(compEnd)

        console.log(end.diff(start, 'hours'), "End Date")

        if(compMode == 2 && compInterval == 1){
            if(end.diff(start, 'hours') < 24){
                console.log("Inside Daily --> Set End Date")
                setEndDate(e.target.value)
            }else{
                if(endDate == 'null'){
                    setEndDate(e.target.value)
                }else{
                    toast.error('Competition time must be less than 24 hrs')
                }
            }
        }else if(compMode == 2 && compInterval == 2){
            if(end.diff(start, 'hours') > 24){
                console.log("Inside Weekly --> Set End Date")
                setEndDate(e.target.value)
            }else{
                if(endDate == 'null'){
                    setEndDate(e.target.value)
                }else{
                    toast.error('Competition time must be greater than 24 hrs')
                }
            }
        }else{
            setEndDate(e.target.value)
            setEndDateError(false)
        }
        // setEndDate(e.target.value)
        // setEndDateError(false)
    }

    const [endTime, setEndTime] = useState("null")
    const [endTimeError, setEndTimeError] = useState(false)

    const onEndTime = (e) =>{

        setEndTime(e.target.value)
        setStartTimeError(false)

        let compEndTime = e.target.value

        let compStart = startDate.concat(" ", startTime)
        let compEnd = endDate.concat(" ", compEndTime)

        let start  = moment(compStart)
        let end = moment(compEnd)

        console.log(end.diff(start, 'hours'), "End Time")

        if(compMode == 2 && compInterval == 1){
            if(end.diff(start, 'hours') < 24){
                console.log("Inside Daily --> Set End Time")
                setEndTime(e.target.value)
            }else{
                if(endDate == 'null'){
                    setEndTime(e.target.value)
                }else{
                    toast.error('Competition time must be less than 24 hrs')
                }
            }
        }else if(compMode == 2 && compInterval == 2){
            if(end.diff(start, 'hours') > 24){
                console.log("Inside Weekly --> Set End Time")
                setEndTime(e.target.value)
            }else{
                if(endDate == 'null'){
                    setEndTime(e.target.value)
                }else{
                    toast.error('Competition time must be greater than 24 hrs')
                }
            }
        }else{
            setEndTime(e.target.value)
            setEndTimeError(false)
        }

        // let compEndTime = e.target.value

        // let compStart = startDate.concat(" ", startTime)
        // let compEnd = endDate.concat(" ", compEndTime)

        // let start  = moment(compStart)
        // let end = moment(compEnd)

    }
    
    const [Hours, setHours] = useState(0)
    const [HoursError, setHoursError] = useState(false)
    const onHours = (e) =>{
        setHours(parseInt(e.target.value))
        setHoursError(false)
    }
    const [minutes, setMinutes] = useState(0)
    const [minutesError, setMinutesError] = useState(false)
    const onMinutes = (e) =>{
        setMinutes(parseInt(e.target.value));
        setMinutesError(false);
    }

    const [HostingType, setHostingType] = useState("")
    const [hostingError, setHostingError] = useState(false)
    const [flatHost, setFlatHost] = useState("")
    const [HostError, setHostError] = useState(false)
    const onHostFlat = (e) =>{
        setFlatHost(parseInt(e.target.value))
        setHostError(false)
    }
    const onHostPercentage = (e) =>{
        setFlatHost(parseInt(e.target.value))
        setHostError(false)
    }

    const [PDMode, setPDMode] = useState("")
    const [PDError, setPDError] = useState(false)
    const onPDMode = (e) => {
        setPDMode(e.target.value)
        setPDError(false)
    }

    const [team, setTeam] = useState("")
    const [teamError, setTeamError] = useState(false)
    const onTeam = (e) => {
        setTeam(e.target.value)
        setTeamError(false);
    }

    const validation = () => {
        if(GameName === "" || GameName === null){
            setGameError(true);
        }

        if(MatcheName === "" || MatcheName === null){
            setMatchError(true);
        }

        if(Competition === "" || Competition === null){
            setCompetitionError(true)
        }

        if(competitioName === "" || competitioName === null){
            setcompetitionNameError(true)
        }

        if(entryFee === "" || entryFee === null){
            setEntryFeeError(true)
        }

        if(bonusWallet === "" || bonusWallet === null){
            setBonusError(true)
        }

        if(minPlayer === "" || minPlayer === null){
            setMinError(true)
        }

        if(maxPlayer === "" || maxPlayer === null){
            setMaxError(true)
        }

        if(NumberEntry === "" || NumberEntry === null){
            setEntryError(true)
        }

        if(PDMode === "" || PDMode === null){
            setPDError(true)
        }

        if(winners === "" || winners === null){
            setWinnerError(true)
        }

        if(startDate === "" || startDate === null){
            setStartDateError(true)
        }

        if(endDate === "" || endDate === null){
            setEndDateError(true)
        }

        if(startTime === "" || startTime === null){
            setStartTimeError(true)
        }

        if(endTime === "" || endTime === null){
            setEndTimeError(true)
        }

        if(UserFee === "" || UserFee === null){
            setUserFeeError(true)
        }

        if(HostingType === "" || HostingType === null){
            setHostingError(true)
        }

        if(countrynew === "" || countrynew.length === 0){
            setCountryError(true)
        }

        if(data === "" || data.length === 0){
            setPrizeError(true)
        }

        if(Hours === "" || Hours === null){
            setHoursError(true);
        }

        if(minutes === "" || minutes === null){
            setMinutesError(true)
        }

        if(flatHost === "" || flatHost === null){
            setHostError(true)
        }

        if(team === "" || team === null){
            setTeamError(true)
        }
    }

    /* Instant Battle */
    const [state, setState] = useState({startRank: "", endRank: "null", amount: ""})
    const [result, setResult] = useState([])
    const [point, setPoint] = useState("")
    
    const onChangePPSALL = (e) => {
        setWinners(parseInt(e.target.value))
        setEntryError(false)
        const ranks = [];
        for(var i = 1; i<=e.target.value;i++){
            ranks.push({startRank: i, endRank: "null", amount:null})
        }
        setResult(ranks)
    }

    const handleChange = (i, value) => {
        const amounts = parseInt(value)
        result[i].amount = amounts
        setResult(result);
        setData(result)   
    }

    const [specialEvents, setSpecialEvents] = useState(false)
    const setSpecialEvent = () => {
        setSpecialEvents(!specialEvents)
    }


    useEffect(() => {
        getallGames();
        getTemplateById();
        getStates();
    }, [getStates])

    const getallGames = () => {
        let gamesNew = {
            gameFilters:{
        
            },
            entities:[{
                value: "Match",
                attributes : ["id","matchName"]
            }]
        }
        dispatch(getallGamesAction(gamesNew))
    }

    const [competitionFormat, setCompetitionFormat] = useState('')
    const [templateName, setTemplateName] = useState('')
    const [totalAmount, setTotalAmount] = useState("")

    const getTemplateById = async() => {

        let templateResponse = {
            templatesFilters : {
                id : props.location.id,
                pagination : {
                    offset : 0,
                    limit : 10
                }
            }
        }

        let res = await axios.post(BASE_URL + GET_TEMPLATES, templateResponse)

        setTemplateName(res.data.templateData[0].templatesName)
        setCompetition(res.data.templateData[0].competitionFormatTypeMaster.id)
        setCompetitionFormat(res.data.templateData[0].competitionFormatTypeMaster.leaderBoardOutComeName)
        setEntryFee(res.data.templateData[0].entryFee)
        setBonusWallet(res.data.templateData[0].maxBonusLimit)
        setMinPlayer(res.data.templateData[0].minPlayers)
        setMaxPlayer(res.data.templateData[0].maxPlayers)
        setNumberEntry(res.data.templateData[0].maxEntryAllowed)
        setWinners(res.data.templateData[0].numberOfWinner)
        // setData(res.data.templateData[0].prizeDistributionRule)
        setPDMode(res.data.templateData[0].prizeDistributionMode)
        setUserFee(res.data.templateData[0].hostingFeeType)
        setFlatHost(res.data.templateData[0].hostingFee)
        setAttempts(res.data.templateData[0].maxAttemptAllowed)
        setTotalAmount(res.data.templateData[0].totalAmount)
        
        if(res.data.templateData[0].competitionFormatTypeMaster.id == 2){
            setCompEvent('on competition end')
            setData(res.data.templateData[0].prizeDistributionRule)
        }else if(res.data.templateData[0].competitionFormatTypeMaster.id == 3){
            setCompEvent('on match end')
            setData(res.data.templateData[0].prizeDistributionRule)
        }else if(res.data.templateData[0].competitionFormatTypeMaster.id == 5){
            setData([])
            setNoOfTeam(res.data.templateData[0].numberOfTeams)
        }
    }

    let games = useSelector((state)=>{ 
        return state.game.games
    })

    const cancelCompetition = () => {
        history.push('/competition')
    }

    const [statesList, setStateList] = useState("")
    const getStates = async() => {
        await axios.get('https://dev.gamestarz.xyz/v1/static-values')
        .then(res=>{
            setStateList(res.data.states)
        })
    }

    const[slot, setSlot] = useState(1)

    const[pdSchdule, setPDSchedule] = useState("on competition end")
    const[pdInstantBattle, setInstantBattle] = useState("on match end")

    const onPDSchedule = (e) => {
        setPDSchedule(e.target.value)
        setHours('')
    }

    const [slotType, setSlotType] = useState("")

    const onSlotType = (e) => {
        setSlotType(e.target.value)
        setInstantBattleSlot(null)
    }

    const [instantBattleSlot, setInstantBattleSlot] = useState(null)

    const onSlot = (e) => {
        setInstantBattleSlot(parseInt(e.target.value))
    }

    const saveInstantBattle = () => {

        var seconds = 0;
        
        if(pdSchdule == 'on competition end'){
            seconds = (Hours * 60 * 60) + (minutes * 60)
        }else{
            seconds = 0
        }

        const competition = {
            gameId : GameName,
            matchId : MatcheName,
            competitionFormatTypeMasterId : Competition,
            competitionName : competitioName,
            minPlayers : minPlayer,
            maxPlayers : maxPlayer,
            maxEntryAllowed : NumberEntry,
            entryFee : entryFee,
            maxBonusLimit : bonusWallet,
            numberOfWinner : winners,
            hostingFee : flatHost,
            hostingFeeType : UserFee,
            hostingFeeprizeDistributionMode: PDMode,
            prizeDistributionRule : data,
            isSpecialEvent : specialEvents,
            prizeDistributionOffset : parseInt(seconds),
            prizeDistributionScheduled : pdInstantBattle,
            competitionCompletionEvent : compEvent,
            maxAttemptAllowed : attempts,
            competitionGeo : [
                {
                    countryId : 1,
                    stateId : stateData
                }
            ],
            totalNumberOfSlot : instantBattleSlot,
            totalAmount : totalAmount,
        }

        dispatch(createCompetitionAction(competition, props.history))

    }


    const saveCompetition = () =>{
        
        var seconds = 0;

        let startDateData = startDate.concat(" ", startTime)
        let endDateData = endDate.concat(" ", endTime)

        let utcStartDate = moment.utc(moment(startDateData)).format("YYYY-MM-DD HH:mm:ss")
        let utcEndDate = moment.utc(moment(endDateData)).format("YYYY-MM-DD HH:mm:ss")

        if(pdSchdule == 'on competition end'){
            seconds = (Hours * 60 * 60) + (minutes * 60)
        }else{
            seconds = 0
        }
            
        const competition = {
            gameId : GameName,
            matchId : MatcheName,
            competitionFormatTypeMasterId : Competition,
            competitionName : competitioName,
            startDate: utcStartDate.concat("+00:00"),
            endDate: utcEndDate.concat("+00:00"),
            minPlayers : minPlayer,
            maxPlayers : maxPlayer,
            maxEntryAllowed : NumberEntry,
            entryFee : entryFee,
            maxBonusLimit : bonusWallet,
            numberOfWinner : winners,
            hostingFee : flatHost,
            hostingFeeType : UserFee,
            hostingFeeprizeDistributionMode: PDMode,
            prizeDistributionRule : data,
            isSpecialEvent : specialEvents,
            prizeDistributionOffset : parseInt(seconds),
            prizeDistributionScheduled : pdSchdule,
            competitionCompletionEvent : compEvent,
            maxAttemptAllowed : attempts,
            competitionGeo : [
                {
                    countryId : 1,
                    stateId : stateData
                }
            ],
            totalNumberOfSlot : slot,
            totalAmount : totalAmount,
            competitionModeId : compMode,
            competitionModeIntervalId : compInterval
        }


        if(startDate == "" || endDate == "" || startTime == "" || endTime == ""){
            validation();
        }else{
            dispatch(createCompetitionAction(competition, props.history))
        }
    }

    /* Tournament (Bracket) Actual Data */

    useEffect(()=>{
        getAllTeams();
    }, [])

    const getAllTeams = async() => {

        let allTeam = {
            teamsFilters : {
                pagination : {}
            }
        }

        let res = await customAxios.post(BASE_URL + GET_TEAM, allTeam)

        setTeams(res.data.teams)

    }

    const [teams, setTeams] = useState([])
    const [selectedTeams, setSelectedTeams] = useState([])
    const [byMatch, setByMatch] = useState([])
    const [showMatchup, setShowMatchUp] = useState(false)

    const [fixtures, setFixtures] = useState([])

    const [ab, setAB] = useState(false)

    const showByMatch = (value, i) => {
        setAB(!ab)
        byMatch[i].status = value.target.checked
        setByMatch(byMatch)
    }

    const showRound = (value, i) => {
        setAB(!ab)
        byMatch[i].status = false
        setByMatch(byMatch)
    }

    useEffect(() => {

    }, [byMatch, ab])

    const [fixtureStart, setFixtureStart] = useState()

    const onFixtureStartDate = (i,e) => {
        const fixtureStartDate = e;
        fixtureStart[i].startDate = fixtureStartDate
    }

    const onFixtureStartTime = (i,e) => {
        const fixtureStartTime = e
        fixtureStart[i].startTime = fixtureStartTime
    }

    const [fixtureEnd, setFixtureEnd] = useState()

    const onFixtureEndDate = (i,e) => {
        const fixtureEndDate = e
        fixtureEnd[i].endDate = fixtureEndDate
    }

    const onFixtureEndTime = (i,e) => {
        const fixtureEndTime = e
        fixtureEnd[i].endTime = fixtureEndTime
    }

    const [matchUpApi, setMatchUpApi] = useState()
    const [test, setTest] = useState([])

    const genrateMatchUp = async() => {

            const status = []
            const teamId = []

            let tempStartFormat = []
            let tempEndFormat = []  

            if(selectedTeams.length != 1 && (Math.log(selectedTeams.length)/Math.log(2)) % 1 === 0){ 
                
                for(var i = 1 ; i<= Math.log(selectedTeams.length)/Math.log(2) ; i++){
                    /* Status For Checkbox (By Match or By Round) */
                    status.push({status : false})
                    tempStartFormat.push({startDate : '', startTime : ''})
                    tempEndFormat.push({endDate : '', endTime : ''})
                    setByMatch(status)
                }

                setFixtureStart(tempStartFormat)
                setFixtureEnd(tempEndFormat)

                setShowMatchUp(true)
                
                selectedTeams && selectedTeams.forEach((e)=>{
                    teamId.push(e.id)
                })

                // Axios Post Request To Genrate Match Ups Of Selected Teams
                let url = `${BASE_URL}/competition/get-fixture`
                let teams = {
                    teamIds : teamId
                }

                let matchups = await axios.post(url, teams)
                setMatchUpApi(matchups.data.data)
                setTest(matchups.data.data)
                let fixture = matchups.data.data
                

                for(var i = 0; i < fixture.length; i++){
                    fixture[i].title = fixture[i]['round'];
                    fixture[i].seeds = fixture[i]['fixture'];
                    delete fixture[i].fixture;
                    delete fixture[i].round;
                }

                setFixtures(fixture)

            }else{
                setShowMatchUp(false)
                toast.error("No of teams must be in power of 2")
            }
    }

    const saveMatchUps = () => {

        for(var i = 0 ; i < (Math.log(selectedTeams.length)/Math.log(2)) ; i++){
            for(var j = 0 ; j < matchUpApi[i].seeds.length ; j++){

                let startDateData = fixtureStart[i].startDate.concat(" ", fixtureStart[i].startTime)
                let endDateData = fixtureEnd[i].endDate.concat(" ", fixtureEnd[i].endTime)

                let utcStartDate = moment.utc(moment(startDateData)).format("YYYY-MM-DD HH:mm:ss").concat("+00:00")
                let utcEndDate = moment.utc(moment(endDateData)).format("YYYY-MM-DD HH:mm:ss").concat("+00:00")

                matchUpApi[i].seeds[j].startTime = utcStartDate
                matchUpApi[i].seeds[j].endTime = utcEndDate
            }
        }

    }

    const [fixtureType, setFixtureType] = useState()
    const [noOfTeams, setNoOfTeam] = useState()
    const [celMatric, setCelMatric] = useState()

    const onFixtureType = (e) => {
        setFixtureType(parseInt(e.target.value))
    }

    const onNoOfTeam = (e) => {
        setNoOfTeam(parseInt(e.target.value))
    }

    const onCelMatric = (e) => {
        setCelMatric(parseInt(e.target.value))
    }

    const saveTournamentBracket = () => {
        
        for(var i = 0; i < matchUpApi.length; i++){
            matchUpApi[i].round = matchUpApi[i]['title'];
            matchUpApi[i].fixture = matchUpApi[i]['seeds'];
            delete matchUpApi[i].title;
            delete matchUpApi[i].seeds;
        }

        const bracket = {
            gameId : GameName,
            matchId : MatcheName,
            competitionFormatTypeMasterId : Competition,
            competitionName : competitioName,
            entryFee : entryFee,
            maxBonusLimit : bonusWallet,
            maxAttemptAllowed : attempts,
            numberOfWinner : winners,
            fixtureType : fixtureType,
            numberOfTeams : noOfTeams,
            leaderboardCalMatrix : celMatric,
            hostingFee : flatHost,
            hostingFeeType : UserFee,
            isSpecialEvent : specialEvents,
            fixtureDetails : matchUpApi,
            competitionGeo : [
                {
                    countryId : 1,
                    stateId : stateData
                }
            ]
        }

        axios.post(BASE_URL + '/competition/createCompetition/bracket', bracket)
        .then(res=>{
            toast.success("Competition Created Successfully")
            setTimeout(() => {
                history.push('/competition')
            }, 1500);
        }).catch(err=>{
            console.log(err.response)
        })
    }


    const GenratePD = async() => {
        let getPD = {
            playersNumber: maxPlayer,
            entryFee: entryFee,
            hostingFee: (flatHost/100)
        }

        if(getPD.playersNumber == '' || getPD.playersNumber == undefined || getPD.playersNumber == null || getPD.entryFee == '' || getPD.entryFee == undefined || getPD.entryFee == null || getPD.hostingFee == 0 || getPD.hostingFee == undefined || getPD.hostingFee == null){
            toast.error(`Maximum Players, Entry Fee and Hosting Fee is required`)
        }else{
            let res = await customAxios.post('https://staging.gamestarz.xyz/v1/admin/generatePrizeDistribution', getPD)
            setData(res.data.prizeDistributionData)
        }
    }

    const [compMode, setCompMode] = useState()

    const onCompMode = (e) => {
        setCompMode(parseInt(e.target.value))
    }

    const [compInterval, setCompInterval] = useState()

    const onCompInterval = (e) => {
        setCompInterval(parseInt(e.target.value))
    }


    return (
        <>
        <ToastContainer />
        <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
            <Row>
                <Col lg={12}>
                <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                    <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                    <Breadcrumb.Item href='#competition'>Competition</Breadcrumb.Item>
                    <Breadcrumb.Item className="text-dark" active>Create Competition</Breadcrumb.Item>
                </Breadcrumb>
                </Col>
            </Row>
        </div>
        <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
                    
                </Col>
            </div>
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5 create-competition">
                <div className="container">

                    <Row className="mb-3">
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <p className='page-title'>Create Competition</p>
                        </Col>
                    </Row>

                    <Row className="my-3">
                        <Col lg={6} md={6}>
                            <label>Select Game</label>
                            <select className="form-select input_bg" onChange={(e)=>{onGameName(e)}}>
                                <option value="">Select Games</option>
                                {games && games.map((e,index)=>{
                                    return(<option key={index} value={e.id}>{e.gameName}</option>)
                                })}
                            </select>
                            {GameError === true ? <span style={{color: "red"}}>*Game is Required</span> : ""}
                        </Col>
                        <Col lg={6} md={6}>
                            <label>Select Matches</label>
                            {Competition == 5 || Competition == 3 ?
                                <select className="form-select input_bg" onChange={(e)=>{onMatches(e)}}>
                                <option value="">Select Matches</option>
                                {gamesDetail.length === 1 && gamesDetail[0].matches.length === 1 && gamesDetail[0].matches[0].matchFormatTypeMasterId == 2 ? 
                                    <option value={gamesDetail[0].matches[0].id}>{gamesDetail[0].matches[0].matchName}</option>
                                : gamesDetail.length === 1 && gamesDetail[0].matches.length > 1 ?
                                gamesDetail[0].matches && gamesDetail[0].matches.map((e)=>{
                                    if(e.matchFormatTypeMasterId == 2){
                                        return(<option value={e.id}>{e.matchName}</option>)
                                    }
                                })
                                : ""}
                                </select>  
                            : Competition == 2 ?
                                <select className="form-select input_bg" onChange={(e)=>{onMatches(e)}}>
                                    <option value="">Select Matches</option>
                                    {gamesDetail.length === 1 && gamesDetail[0].matches.length === 1 ? 
                                        <option value={gamesDetail[0].matches[0].id}>{gamesDetail[0].matches[0].matchName}</option>
                                    : gamesDetail.length === 1 && gamesDetail[0].matches.length > 1 ?
                                    gamesDetail[0].matches && gamesDetail[0].matches.map((e)=>{
                                        return(<option value={e.id}>{e.matchName}</option>)
                                    })
                                    : ""}
                                </select>  
                            : ""}
                            {MatchError === true ? <span style={{color: "red"}}>*Match is Required</span> : ""}  
                        </Col>
                        
                    </Row>

                    <Row className="my-3">
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <label>Competition Format</label>
                            <select disabled className="form-select input_bg" onChange={(e)=>{onCompetitionChange(e)}}>
                                <option value={Competition}>{competitionFormat}</option>
                                <option value="2">Leaderboard</option>
                                <option value="3">Instant Battle</option>
                                <option value="5">Bracket</option>
                            </select>
                            {CompetitionError === true ? <span style={{color: "red"}}>*Competition is Required</span> : ""} 
                        </Col>  
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <label>Competition Name</label>
                            <input type="text" className="form-control input_bg" onChange={(e)=>{onCompetitionName(e)}}/>
                            {competitionNameError === true ? <span style={{color: "red"}}>*Competition Name is Required</span> : ""}
                        </Col>
                    </Row>

                        <div>
                            <Row className="my-3">
                                <Col lg={6} md={6} sm={12}>
                                    <label>Entry Fee Total</label>
                                    <input type="number" className="form-control input_bg" defaultValue={entryFee} onChange={(e)=>{onEntryFee(e)}}/>
                                    {entryFeeError === true ? <span style={{color: "red"}}>*Entry Fee is Required</span> : ""}
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <label>Entry Fee Bonus Wallet Limit</label>
                                    <input type="number" className="form-control input_bg" defaultValue={bonusWallet} onChange={(e)=>{onBonusWallet(e)}}/>
                                    {BonusError === true ? <span style={{color: "red"}}>*Limit is Required</span> : ""}
                                </Col>
                            </Row>
                            {Competition == 2 || Competition == 3 ?
                            <Row className="my-3">
                                <Col lg={6} md={6} sm={12}>
                                    <label>Minimum Players</label>
                                    <input type="number" min="1" className="form-control input_bg" defaultValue={minPlayer} onChange={(e)=>{onMinPlayer(e)}}/>
                                    {minError === true ? <span style={{color: "red"}}>*Minimum Players are Required</span> : ""}
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <label>Maximum Players</label>
                                    <input type="number" min="1" className="form-control input_bg" defaultValue={maxPlayer} onChange={(e)=>{onMaxPlayer(e)}}/>
                                    {maxError === true ? <span style={{color: "red"}}>*Maximum Players are Required</span> : ""}
                                </Col>
                            </Row>
                            : ""}

                            <Row className="my-3">
                                <Col lg={6} md={6} sm={12}>
                                    <label>Number Of Attempts</label>
                                    <input type="number" min="1" className="form-control input_bg" defaultValue={NumberEntry} disabled={Competition == 2 ? false : Competition == 3 ? true : false} onChange={(e)=>{onNumberEntry(e)}}/>
                                    {EntryError === true ? <span style={{color: "red"}}>*No Of Attempts are Required</span> : ""}
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <label>Number of Winners</label>
                                    <input type="number" min="1" className="form-control input_bg" defaultValue={winners} onChange={(e)=>{onWinners(e)}}/>
                                    {winnerError === true ? <span style={{color: "red"}}>*No Of Winners are Required</span> : ""}
                                </Col>
                            </Row>
                            {Competition == 5 ?
                            <Row  className="my-3">
                                <Col lg={6} md={6} sm={12}>
                                    <label>Team Selection Class</label>
                                    <select className='form-select input_bg'>
                                        <option>Select Team Selection Class</option>
                                        <option>Playstarz Teams</option>
                                        <option>Geography</option>
                                    </select>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <label>Fixture Type</label>
                                    <select className='form-select input_bg' onChange={(e)=>{onFixtureType(e)}}>
                                        <option>Select Fixture Type</option>
                                        <option selected value="1">Single Elimination</option>
                                        <option>Double Elimination</option>
                                        <option>Round Robin</option>
                                    </select>
                                </Col>
                            </Row> : ""}

                            <Row className="my-3">
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <label className='mb-1' for="noofEntry">Number Of Entries</label>
                                    <input className='form-control input_bg' type='number' defaultValue={attempts} disabled={(Competition == 3 || Competition == 2) ? true : false} onChange={(e)=>{setAttempts(parseInt(e.target.value))}}/>
                                </Col>
                                {Competition == 2 || Competition == 3 ?
                                    <Col md={6} className='input-file'>
                                        <label>Prize Distributions</label> <br />
                                        <div className='d-flex'>
                                            <input id='PD' type="file" accept=".csv, .xlsx" className="form-control" onChange={(e)=>{onCSV(e)}}/>
                                            <label for='PD' className='label-btn'><FontAwesomeIcon icon={faPlusCircle}/> Choose File</label>
                                            {/* <Button className='label-btn mx-3' onClick={GenratePD}>Genrate PD</Button> */}
                                        </div>
                                        {PrizeError === true ? <span style={{color: "red"}}>*Prize Distribution are Required</span> : ""}
                                    </Col>
                                : Competition == 5 ?
                                    <Col>
                                        <label className="mb-1">Select Teams</label>
                                        <Autocomplete multiple disableCloseOnSelect limitTags={2} filterSelectedOptions 
                                            options={teams} getOptionLabel={(option)=> option.teamName} onChange={(e,newvalue)=>{setSelectedTeams(newvalue)}}
                                            renderOption={(option, {selected})=>(
                                                <React.Fragment>
                                                    <Checkbox icon={icon}
                                                    checkedIcon={checkedIcon} style={{marginLeft: "10px"}}
                                                    checked={selected}  allowSelectAll={true}/>
                                                    {option.teamName}
                                                </React.Fragment>  
                                            )}
                                            renderInput={(params)=>(<TextField {...params} variant="outlined" />)} />
                                    </Col>
                                : ""}
                            </Row>
                            
                            {Competition == 5 && selectedTeams.length > 0 ?
                                <Row className='my-3 text-end'>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Button className='btn btn-success' onClick={genrateMatchUp}>Genrate</Button>
                                    </Col>
                                </Row>
                            : ""}


                            {Competition == 5 && selectedTeams.length > 0 && showMatchup == true ? 
                                <Row className='my-5'>
                                    <Col xl={2} lg={2} md={2} sm={2} xs={2}></Col>
                                    <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                        <Row>
                                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Bracket rounds={fixtures} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xl={2} lg={2} md={2} sm={2} xs={2}></Col>
                                </Row>
                            : ''}


                            {Competition == 5  && selectedTeams.length > 0 && showMatchup == true ?
                            <>
                                <Row className="my-5">
                                    <Col>
                                        {fixtures && fixtures.map((e,i)=>{
                                            return(
                                                <>  
                                                {byMatch[i].status == false ?
                                                <>
                                                    <Row className='mt-5 mb-3 d-flex justify-content-around align-items-center'>
                                                        <Col>
                                                            <h6><span className='round-title'>Round {i+1}</span></h6>
                                                        </Col>
                                                        <Col className='text-right'>
                                                            <input type='checkbox' className='form-check-input' checked={byMatch[i].status} onClick={(value)=>{showByMatch(value, i)}}/>
                                                            <label className='mx-2 form-check-label'>Show By Match</label>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mb-3'>
                                                        <Col lg={6} md={6} sm={12}>
                                                            <Row>
                                                                <Col>
                                                                    <label>Start Date</label>
                                                                    <input type="date" className="form-control input_bg" min={new Date().toISOString().split('T')[0]} onChange={(evt)=>{onFixtureStartDate(i, evt.target.value)}}/>
                                                                </Col>
                                                                <Col>
                                                                    <label>Start Time</label>
                                                                    <input type="time" className="form-control input_bg" step="1" onChange={(evt)=>{onFixtureStartTime(i, evt.target.value)}}/>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col lg={6} md={6} sm={12}>
                                                            <Row>
                                                                <Col>
                                                                    <label>End Date</label>
                                                                    <input type="date" className="form-control input_bg" min={new Date().toISOString().split('T')[0]} onChange={(evt)=>{onFixtureEndDate(i, evt.target.value)}}/>
                                                                </Col>
                                                                <Col>
                                                                    <label>End Time</label>
                                                                    <input type="time" className="form-control input_bg" step="1" onChange={(evt)=>{onFixtureEndTime(i, evt.target.value)}}/>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </>
                                                : ""}
                                                {byMatch[i].status == true ?
                                                   <>
                                                    <Row className='my-4 d-flex justify-content-around'>
                                                        <Col>
                                                            <h5>Round {i+1}</h5>
                                                        </Col>
                                                        <Col className='text-right'>
                                                            <input type='checkbox' className='form-check-input' checked={byMatch[i].status} onClick={(value)=>{showByMatch(value, i)}}/>
                                                            <label className='mx-2 form-check-label'>Show By Match</label>
                                                        </Col>
                                                    </Row>
                                                    {fixtures[i].seeds && fixtures[i].seeds.map((e, index) => {
                                                         return(
                                                            <>
                                                                <Row className='my-3'>
                                                                    <Col>
                                                                        <h6><span className='round-title'>Round {i+1} Match {index+1}</span></h6>
                                                                    </Col>
                                                                    <Col className='text-right'>
                                                                        
                                                                    </Col>
                                                                </Row>
                                                                <Row className='mb-3'>
                                                                    <Col lg={6} md={6} sm={12}>
                                                                        <Row>
                                                                            <Col>
                                                                                <label>Start Date</label>
                                                                                <input type="date" className="form-control input_bg" min={new Date().toISOString().split('T')[0]} />
                                                                            </Col>
                                                                            <Col>
                                                                                <label>Start Time</label>
                                                                                <input type="time" className="form-control input_bg" step="1" />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={6} md={6} sm={12}>
                                                                        <Row>
                                                                            <Col>
                                                                                <label>End Date</label>
                                                                                <input type="date" className="form-control input_bg" min={new Date().toISOString().split('T')[0]} />
                                                                            </Col>
                                                                            <Col>
                                                                                <label>End Time</label>
                                                                                <input type="time" className="form-control input_bg" step="1" />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={12} md={12} sm={12}>
                                                                        <hr />
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                         )
                                                    })}
                                                   </>
                                                : ''}
                                            </>
                                            )
                                        })}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <button className='btn btn-success' onClick={saveMatchUps}>Save</button>
                                    </Col>
                                </Row>
                            </>         
                            : ''}
                            


                            {Competition == 5 ?
                                <Row className='my-3'>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12} className='input-file'>
                                        <label className='mb-1' for="LeaderboardMetric">No Of Teams</label> <br />
                                        <input type='number' disabled className='form-control input_bg' defaultValue={noOfTeams} onChange={(e)=>{onNoOfTeam(e)}}/>
                                    </Col>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12} className='input-file'>
                                         <label className='mb-1' for="LeaderboardMetric">Leaderboard Cal Metric</label> <br />
                                         <select className='form-select input_bg' onChange={(e)=>{onCelMatric(e)}}>
                                             <option>Select Leaderboard Cal Metric</option>
                                             <option value="1">High Score</option>
                                             <option value="2">All Score</option>
                                         </select>
                                    </Col>
                                </Row>
                            : ''}

                            {(Competition == 2 || Competition == 3) && data && data !="" && data[0].startRank ? 
                                <div>
                                    <Row>
                                        <Col md={12}>
                                            <table className="table table-striped text-center table-custom">
                                            <thead className='table-head'>
                                                <tr>
                                                    <th className='head-border-left' scope="col">Rank</th>
                                                    <th className='head-border-right' scope="col">Prize</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentEntry && currentEntry.map((e)=>{
                                                    return(
                                                        <tr className='table-body'>
                                                            <td>{e.startRank} {e.endRank ? "-" : ""} {e.endRank}</td>
                                                            <td>{e.amount}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                </div>
                            : ""}
                            {(Competition == 2 || Competition == 3) && data && data != "" && data[0].startRank && data[0].amount ?
                                <Row>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <Col>
                                                <TablePagination entryPerPage={entryPerPage} totalEntry={data.length} Paginate={Paginate}/>
                                            </Col>
                                        </div>
                                        <div>
                                            <Col>
                                                <label style={{paddingRight: "10px"}}>Rows Per Page</label>
                                                <select className='entries' onChange={(e)=>{changePageEntry(e)}}>
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="30">30</option>
                                                </select>
                                            </Col>
                                        </div>
                                    </div>
                                </Row>
                            :(Competition == 2 || Competition == 3) && data && data != ""  ? <div><p className="text-danger font-weight-bold">Please upload valid file.</p></div> : ""}
                            
                            <Row className="my-3">
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <label>Competition Mode</label>
                                    <select className='form-select input_bg' onChange={(e)=>{onCompMode(e)}}>
                                        <option value=''>Select Competition Mode</option>
                                        <option value={1}>Normal</option>
                                        <option value={2}>Recurring</option>
                                    </select>
                                </Col>
                                {compMode == 2 ? 
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <label>Competition Interval</label>
                                        <select className='form-select input_bg' onChange={(e)=>{onCompInterval(e)}}>
                                            <option value=''>Select Competition Interval</option>
                                            <option value={1}>Daily</option>
                                            <option value={2}>Weekly</option>
                                        </select>
                                    </Col>
                                : ''}
                            </Row>

                            {compMode == 2 && compInterval == 1 ?
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12}>
                                        <span style={{fontSize : '14px'}} className='text-danger'><b>*Daily Tournament Time Must Be Less Than 24 Hours</b></span>
                                    </Col>
                                </Row>
                            :compMode == 2 && compInterval == 2 ?
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12}>
                                        <span style={{fontSize : '14px'}}  className='text-danger'><b>*Weekly Tournament Time Must Be Greater Than 24 Hours</b></span>
                                    </Col>
                                </Row>
                            : compMode == 1 ?
                                <span></span>
                            : ''}

                            {Competition == 2 ?
                                <Row className="mt-2 mb-3">
                                    <Col lg={6} md={6} sm={12}>
                                        <Row>
                                            <Col>
                                                <label>Start Date</label>
                                                <input type="date" className="form-control input_bg" min={new Date().toISOString().split('T')[0]} onChange={(e)=>{onStartDate(e)}}/>
                                                {startDateError == true ? <span style={{color: "red"}}>*Date is Required</span> : ""}
                                            </Col>
                                            <Col>
                                                <label>Start Time</label>
                                                <input type="time" className="form-control input_bg" step="1" onChange={(e)=>{onStartTime(e)}}/>
                                                {startTimeError == true ? <span style={{color: "red"}}>*Time is Required</span> : ""}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <Row>
                                            <Col>
                                                <label>End Date</label>
                                                <input type="date" className="form-control input_bg" min={new Date().toISOString().split('T')[0]} onChange={(e)=>{onEndDate(e)}}/>
                                                {endDateError == true ? <span style={{color: "red"}}>*Date is Required</span> : ""}
                                            </Col>
                                            <Col>
                                                <label>End Time</label>
                                                <input type="time" className="form-control input_bg"step="1" onChange={(e)=>{onEndTime(e)}}/>
                                                {endTimeError == true ? <span style={{color: "red"}}>*Time is Required</span> : ""}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            : ""}

                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <label>Hosting Fee per User</label>
                                    <select className="form-select input_bg" onChange={(e)=>{onHostingFeeUser(e)}}>
                                        <option>{UserFee}</option>
                                        <option value="percentage">Percentage</option>
                                        <option value="flat">Flat</option>
                                    </select>
                                    {userFeeError === true ? <span style={{color: "red"}}>*Hosting Fee is Required</span> : ""}
                                </Col>
                                {UserFee === "percentage" ? 
                                    <Col lg={6} md={6} sm={12}>
                                        <label>Hosting Fee Per User (in %)</label>
                                        <input type = "number" className="form-control input_bg" defaultValue={flatHost} onChange={(e)=>{onHostPercentage(e)}}/>
                                    </Col>
                                    : UserFee === "flat" ?
                                    <Col lg={6} md={6} sm={12}>
                                        <label>Hosting Fee Per User (for flat)</label>
                                        <input type = "number" className="form-control input_bg" defaultValue={flatHost} onChange={(e)=>{onHostFlat(e)}}/>
                                    </Col>: ""}
                            </Row>

                            <Row className="my-3 d-flex">
                                <Col lg={6} md={6} sm={12}>
                                    <div className="form-group mt-md-5">
                                        <label className="mb-1">Set Prize Distribution Schedule</label>
                                        {Competition == 5 ?
                                        <select value="on competition end" disabled className='form-select input_bg'>
                                            <option>Select Prize Distribution Schedule</option>
                                            <option value="on match end">Per Slot</option>
                                            <option value="on competition end" selected>End of Competition</option>
                                        </select>
                                        : Competition == 2 ?
                                        <select value="on competition end" disabled className='form-select input_bg'>
                                            <option>Select Prize Distribution Schedule</option>
                                            <option value="on match end">Per Slot</option>
                                            <option value="on competition end" selected>End of Competition</option>
                                        </select>
                                        : Competition == 3 ?
                                        <select value="on match end" disabled className='form-select input_bg' onChange={(e)=>{onPDSchedule(e)}}>
                                            <option>Select Prize Distribution Schedule</option>
                                            <option value="on match end">Per Slot</option>
                                            <option value="on competition end">End of Competition</option>
                                        </select>
                                        : ""}
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <div className="form-group my-2">
                                        <label className="pr-3">Set Prize Distribution Offset</label>
                                        {Competition == 5 ?
                                        <Row>
                                            <Col>
                                                <label className="my-2">Hours</label>
                                                <input type="number" min="0" className="form-control input_bg" onChange={(e)=>{onHours(e)}}/>
                                                {HoursError === true ? <span style={{color: "red"}}>*Hours are Required</span> : ""}
                                            </Col>
                                            
                                            <Col>
                                                <label className="my-2">Minutes</label>
                                                <input type="number" min="0" max="60" className="form-control input_bg" onChange={(e)=>{onMinutes(e)}}/>
                                                {minutesError === true ? <span style={{color: "red"}}>*Minutes are Required</span> : ""}
                                            </Col>
                                        </Row>
                                        : Competition == 2 ?
                                            <Row>
                                                <Col>
                                                    <label className="my-2">Hours</label>
                                                    <input type="number" min="0" className="form-control input_bg" onChange={(e)=>{onHours(e)}}/>
                                                    {HoursError === true ? <span style={{color: "red"}}>*Hours are Required</span> : ""}
                                                </Col>
                                                
                                                <Col>
                                                    <label className="my-2">Minutes</label>
                                                    <input type="number" min="0" max="60" className="form-control input_bg" onChange={(e)=>{onMinutes(e)}}/>
                                                    {minutesError === true ? <span style={{color: "red"}}>*Minutes are Required</span> : ""}
                                                </Col>
                                            </Row>
                                        : Competition == 3 ?
                                            <Row>
                                                <Col>
                                                    <label className="my-2">Hours</label>
                                                    <input type="number" min="0" defaultValue={0} disabled className="form-control input_bg" onChange={(e)=>{onHours(e)}}/>
                                                    {HoursError === true ? <span style={{color: "red"}}>*Hours are Required</span> : ""}
                                                </Col>
                                                
                                                <Col>
                                                    <label className="my-2">Minutes</label>
                                                    <input type="number" min="0" max="60" defaultValue={0} disabled className="form-control input_bg" onChange={(e)=>{onMinutes(e)}}/>
                                                    {minutesError === true ? <span style={{color: "red"}}>*Minutes are Required</span> : ""}
                                                </Col>
                                            </Row>
                                        : ""}
                                    </div>
                                </Col>
                            </Row>

                            <Row className='my-3'>
                                <Col lg={6} md={6} sm={12}>
                                    <label>Prize Distribution Mode</label>
                                    <select className="form-select input_bg" onChange={(e)=>{onPDMode(e)}}>
                                        <option>{PDMode}</option>
                                        <option value="Guaranteed">Guaranteed</option>
                                        <option value="Dynamic">Dynamic</option>
                                    </select>
                                    {PDError === true ? <span style={{color: "red"}}>*PD Mode is Required</span> : ""}
                                </Col>

                                <Col lg={6} md={6} sm={12}>
                                    <div className="form-group">
                                        <label className="mb-1" for="IncludeCountry">Included Countries</label>
                                        <Autocomplete multiple disableCloseOnSelect filterSelectedOptions 
                                        options={country} getOptionLabel={(option)=> option.CountryName}
                                        onChange={(e,newvalue)=>{setCountryNew(newvalue); setCountryError(false)}}
                                        renderOption={(option, {selected})=>(
                                            <React.Fragment>
                                                <Checkbox icon={icon}
                                                checkedIcon={checkedIcon} style={{marginLeft: "10px"}}
                                                checked={selected}  allowSelectAll={true} onClick={() => handleDeleteShow()}

                                                />
                                                {option.CountryName}
                                            </React.Fragment>  
                                        )}
                                        renderInput={(params)=>(<TextField {...params} variant="outlined" />)} />
                                            {countryError ? <div><span style={{color : "red"}}>Country is Required</span></div> : ""}
                                    </div>

                                    <Row className='my-1'>
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <Row>
                                                {countrynew.length != 0 && exclude && exclude.length != 0 ? <span style={{fontSize : "12px"}}>Excluded States : </span> : ""}
                                                {countrynew.length != 0 && statesList && statesList.map((e)=>{
                                                    return(
                                                        (exclude.map((i, x)=>{
                                                            if(i === e.id){
                                                                return(
                                                                    <Col xl={6} lg={6} md={6} sm={6} style={{fontSize : "12px"}} className='text-danger mb-1'>{e.stateName} </Col>
                                                                )
                                                            }
                                                        }))
                                                    )
                                                })}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>

                            {Competition == 2 || Competition == 3 ?
                            <Row className='my-3'>
                                {UserFee === "percentage" ? 
                                    <Col lg={6} md={6} sm={12}>
                                        <label>Total Prize Money Available (for Percentage)</label>
                                        <input type = "number" className='form-control input_bg' disabled value = {entryFee * maxPlayer * (1-(flatHost/100))} />
                                    </Col>
                                    : UserFee === "flat" ?
                                    <Col lg={6} md={6} sm={12}>
                                    <label>Total Prize Money Available (for Flat)</label>
                                    <input type = "number" className='form-control input_bg' disabled value = {entryFee * maxPlayer - (maxPlayer*flatHost)} />
                                </Col>: ""}
                                {Competition == 3 ?  
                                    <Col lg={6} md={6} sm={12}>
                                        <label>No of slot</label>
                                        <select className='form-select input_bg' onChange={(e)=>{onSlotType(e)}}>
                                            <option>select no of slot type</option>
                                            <option value='unlimited'>Unlimited</option>
                                            <option value='variable'>Specify no of slots</option>
                                        </select>
                                    </Col> 
                                : ""}
                            </Row>
                            : ''}
                            
                            {Competition == 3 && slotType == 'variable' ?  
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <label>No of slots</label>
                                    <input type='number' min="1" className='form-control input_bg' onChange={(e)=>{onSlot(e)}}/>
                                </Col> 
                            </Row>
                            : ""}

                            <Row className='my-3'>
                                {/* <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <label className='mb-1' for="noofEntry">Number Of Entries</label>
                                    <input className='form-control input_bg' type='number' defaultValue={attempts} disabled={Competition == 2 ? false : Competition == 3 ? true : false} onChange={(e)=>{setAttempts(parseInt(e.target.value))}}/>
                                </Col> */}
                                
                            </Row>

                            <Row className='mb-3'>
                                <Col lg={6} md={6} sm={12}>
                                    <label>Is Special Event</label> <span>&nbsp;&nbsp;</span>
                                    <input type='checkbox' className='input_bg' onChange={(e)=>{setSpecialEvent(e)}}/>
                                </Col>
                            </Row>
                        </div>


                    <Row>
                        <Col>
                            {Competition == 5 ? <button type="button" className="btn btn-success" onClick={saveTournamentBracket}>Save</button> : ""}
                            {Competition == 2 ? <button type="button" disabled={data.length != 0  ? false : true} className="btn btn-success" onClick={saveCompetition}>Save</button> : ""}
                            {Competition == 3 ? <button type="button" disabled={data.length != 0  ? false : true} className="btn btn-success" onClick={saveInstantBattle}>Save Instant Battle</button> : ""}
                            <button className="btn btn-danger mx-4" onClick={cancelCompetition}>Cancel</button>
                        </Col>
                    </Row>
                </div>
            </div>
            <CountryModal show={deleteShow} onHide={handleDeleteClose} filter={a} ExcludedState={statesShow}></CountryModal>
        </>
    )
}
