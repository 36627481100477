import React from 'react'
import {useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col, Dropdown} from '@themesberg/react-bootstrap'
import { clearCouponAction } from '../../../../redux/action/playstarzbankAction';


function CoupanDetailsModal({show, onHide}) {

    const dispatch = useDispatch();
    const close = () => {
        // dispatch(clearCouponAction())
        onHide();
    }

    let couponDetails = useSelector((state)=>{ 
        return state.playstarzbank.CouponDetail
    })

    if(couponDetails && couponDetails.length != 0){
    return (
        <div>
            <Modal size="lg" centered show={show} onHide={onHide} className='gamestarz-modal'>
                <Modal.Header>
                    <div className='coupondetail-modal'>
                        <span className='coupon-head'>{couponDetails[0].couponName}</span>
                    </div>
                </Modal.Header>
                <Modal.Body className='coupondetail-modal'>
                    <div className='coupon-body'>
                        <Row className="my-2">
                            <Col lg={6} md={6} sm={12}>
                                <span className='coupondetail-heading'>Coupon Name</span>
                                <input disabled type='text' className='form-control input-bg' defaultValue={couponDetails[0].couponName && couponDetails[0].couponName != "" ? couponDetails[0].couponName : "No Info"}/>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <span>Reward Type</span>
                                <input disabled type='text' className='form-control input-bg' defaultValue={couponDetails[0].referralRewardsType && couponDetails[0].referralRewardsType.name != ''  ? couponDetails[0].referralRewardsType.name : "No Info"}/>
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col lg={6} md={6} sm={12}>
                                <span>Coupon Description</span>
                                <input disabled type='text' className='form-control input-bg' defaultValue={couponDetails[0].couponDescription && couponDetails[0].couponDescription != "" ? couponDetails[0].couponDescription : "No Info"}/>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <span>On Deposit Greater than "Amount' : </span>
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col lg={6} md={6} sm={12}>
                                <span>Max Coupons Available</span>
                                <input disabled type='text' className='form-control input-bg' defaultValue={couponDetails[0].couponTotalLimitCount && (couponDetails[0].couponTotalLimitCount != "" || couponDetails[0].couponTotalLimitCount != null) ? couponDetails[0].couponTotalLimitCount : "No Info"}/>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <span>Max Coupon Limit per User</span>
                                <input disabled type='text' className='form-control input-bg' defaultValue={couponDetails[0].couponTotalLimitCountPerUser && (couponDetails[0].couponTotalLimitCountPerUser != "" || couponDetails[0].couponTotalLimitCountPerUser != null) ? couponDetails[0].couponTotalLimitCountPerUser : "No Info"}/>
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col lg={6} md={6} sm={12}>
                                <span>Geography</span>
                                <input disabled type='text' className='form-control input-bg' defaultValue={couponDetails[0].couponName != "" ? "India" : "No Info"}/>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <span>Coupon Status</span>
                                <input disabled type='text' className='form-control input-bg' defaultValue={couponDetails[0].couponStatus && couponDetails[0].couponStatus != "" ? couponDetails[0].couponStatus : "No Info"}/>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={close}>Close</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
    }else{
        return(
            <span></span>
        )
    }
}

export default CoupanDetailsModal