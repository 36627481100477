import React, {useState, useEffect} from 'react'
import {Row, Col} from 'react-bootstrap'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Checkbox, Select, TextField } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CountryModal from '../../modals/CountryModal';
import { useDispatch, useSelector } from 'react-redux';
import { clearSCAction, createScratchCardAction, editScratchCardAction, getScratchCardByIdAction } from '../../../redux/action/playstarzbankAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import Loader from "react-loader-spinner";
import axios from '../../../utlis/interceptor';
import { BASE_URL, GET_SCRATCH_CARD } from '../../../resources/APIEndpoints';
import { Breadcrumb } from "react-bootstrap";

const getDatafromLS=()=>{
  const data = localStorage.getItem('scartchCard');
  if(data){
    return JSON.parse(data);
  }
  else{
    return []
  }
}

function EditScratchCard(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const Countrydata = [{CountryName: 'India', id:1}]
    const[country, setCountry] = useState(Countrydata)
    const[countrynew, setCountryNew] = useState()

    const icon = <CheckBoxOutlineBlankIcon fontSize = "small" />
    const checkedIcon = <CheckBoxIcon fontSize = "small" />

    const [deleteShow, setDeleteShow] = useState(false);
    
    const handleDeleteClose = () => {
        setDeleteShow(false)
    }
    const handleDeleteShow = () => {
        setDeleteShow(true)
    }

    useEffect(()=>{
      dispatch(clearSCAction())
      if(props.location.id){
        let ScratchCardData = {
          scratchCardFilters : { 
            id : props.location.id,
            pagination : {

            }
          }
      }
      dispatch(getScratchCardByIdAction(ScratchCardData))
      }
    }, [])

    let scratchCardData = useSelector((state)=>{ 
      return state.playstarzbank.ScratchCardDetail
    })


    const [cardName, setCardName] = useState()
    const [cardNameErr, setCardNameErr] = useState(false)
    const onScratchCard = (e) => {
        setCardName(e.target.value)
        setCardNameErr(false)
    }
    
    const [validity, setValidity] = useState()
    const [ValidityErr, setValidityError] = useState(false)
    const onValidity = (e) => {
        setValidity(parseInt(e.target.value))
        setValidityError(false)
    }

    const [cardDescription, setCardDescription] = useState()
    const [cardDescriptionErr, setCardDescriptionError] = useState(false)
    const onCardDescription = (e) => {
        setCardDescription(e.target.value)
        setCardDescriptionError(false)
    }

    const [rewardType, setRewardType] = useState("")
    const [rewardTypeErr, setRewardTypeError] = useState(false)
    const onRewardType = (e) => {
        setRewardType(parseInt(e.target.value))
        setRewardTypeError(false)
    }

    const [maxCBAmount, setCBAmount] = useState()
    const [maxCBAmountErr, setCBAmountError] = useState(false)
    const onCBAmount = (e) => {
        setCBAmount(parseInt(e.target.value))
        setCBAmountError(false)
    }

    const [startDate, setStartDate] = useState()
    const [startDateErr, setStartDateError] = useState(false)
    const onStartDate = (e) => {
        setStartDate(e.target.value)
        setStartDateError(false)
    }
  
    const [endDate, setEndDate] = useState()
    const [endDateErr, setEndDateError] = useState(false)
    const onEndDate = (e) => {
        setEndDate(e.target.value)
        setEndDateError(false)
    }

    const [startTime, setStartTime] = useState()
    const [startTimeErr, setStartTimeError] = useState(false)
    const onStartTime = (e) => {
        setStartTime(e.target.value)
        setStartTimeError(false)
    }

    const [endTime, setEndTime] = useState()
    const [endTimeError, setEndTimeError] = useState(false)
    const onEndTime = (e) => {
        setEndTime(e.target.value)
        setEndTimeError(false)
    }

    const [maxScratchCard, setMaxScratchCard] = useState()
    const [maxCardErr, setMaxCardError] = useState(false)
    const onMaxScratchCard = (e) => {
        setMaxScratchCard(parseInt(e.target.value))
        setMaxCardError(false)
    }

    const [cardLimit, setCardLimit] = useState()
    const [cardLimitErr, setCardLimitError] = useState(false)
    const onScratchCardLimit = (e) => {
        setCardLimit(parseInt(e.target.value))
        setCardLimitError(false);
    }

    const [Rules, setRules] = useState(getDatafromLS())

    useEffect(()=>{
        localStorage.setItem('scartchCard',JSON.stringify(Rules));
        const Token = localStorage.getItem('scartchCard')
    },[Rules])

    useEffect(()=>{
      if(props.location.id){
        let ScratchCardData = {
          scratchCardFilters : { 
            id : props.location.id,
            pagination : {

            }
          }
      }

      axios.post(BASE_URL + GET_SCRATCH_CARD, ScratchCardData)
      .then(res=>{
        setCardName(res.data.scratchCard[0].scratchCardName)
        setValidity(parseInt(res.data.scratchCard[0].scratchCardValidityPeriod))
        setCardDescription(res.data.scratchCard[0].scratchCardDescription)
        setRewardType(res.data.scratchCard[0].referralRewardsType.id)
        setCBAmount(res.data.scratchCard[0].scratchCardMaxCashback)
        setStartDate(res.data.scratchCard[0].startDate.slice(0,10))
        setStartTime(res.data.scratchCard[0].startDate.slice(11, 19))
        setEndDate(res.data.scratchCard[0].endDate.slice(0,10))
        setEndTime(res.data.scratchCard[0].endDate.slice(11, 19))
        setMaxScratchCard(res.data.scratchCard[0].scratchCardTotalLimitCount)
        setCardLimit(res.data.scratchCard[0].scratchCardTotalLimitCountPerUser)
        setRules(res.data.scratchCard[0].scratchCardRule.all)
      })
      }
    }, [])

    const [factName, setFactname] = useState()
    const [operator, setOperator] = useState()
    const [value, setValue] = useState()
    const [showFact, setShowFact] = useState(false)
    const [dataType, setDataType] = useState()

    const [factNameErr, setFactError] = useState(false)
    const [operatorErr, setOperatorError] = useState(false)
    const [valueErr, setValueError] = useState(false)
    const [dataTypeErr, setDataTypeError] = useState(false)

    const onFactName = (e) => {
        setFactname(e.target.value)
        setFactError(false)
    }

    const onOperator = (e) => {
        setOperator(e.target.value)
        setOperatorError(false)
    }

    const onValue = (e) => {
        setValue(parseInt(e.target.value))
        setValueError(false)
    }

    const onDataChange = (e) => {
        setDataType(parseInt(e.target.value))
        setDataTypeError(false)
    }

    const Showfact = () => {
        setShowFact(true)
    }

    
    const validation = () => {
        if(cardName === "" || cardName === null || cardName === undefined){
            setCardNameErr(true)
        }
        if(validity === "" || validity === null || validity === undefined){
            setValidityError(true)
        }
        if(cardDescription === "" || cardDescription === null || cardDescription === undefined){
            setCardDescriptionError(true)
        }
        if(rewardType === "" || rewardType === null || rewardType === undefined){
           setRewardTypeError(true)
        }
        if(maxCBAmount === "" || maxCBAmount === null || maxCBAmount === undefined){
            setCBAmountError(true)
        }
        if(startDate === "" || startDate === null || startDate === undefined){
            setStartDateError(true)
        }
        if(endDate === "" || endDate === null || endDate === undefined){
            setEndDateError(true)
        }
        if(startTime === "" || startTime === null || startTime === undefined){
            setStartTimeError(true)
        }
        if(endTime === "" || endTime === null || endTime === undefined){
            setEndTimeError(true)
        }
        if(maxScratchCard === "" || maxScratchCard === null || maxScratchCard === undefined){
            setMaxCardError(true)
        }
        if(cardLimit === "" || cardLimit === null || cardLimit === undefined){
            setCardLimitError(true)
        }
    }

    const RuleValidation = () => {
        if(factName === "" || factName === null || factName === undefined){
            setFactError(true)
        }
        if(operator === "" || operator === null || operator === undefined){
            setOperatorError(true)
        }
        if(value === "" || value === null || value === undefined){
            setValueError(true)
        }
        if(dataType === "" || dataType === null || dataType === undefined){
            setDataTypeError(true)
        }
    }

    const onsave = () => {
        RuleValidation();
        let newRules = {
                fact: factName,
                operator: operator,
                value: value
        }
        if(newRules.factName === "" || newRules.operator === "" || newRules.value === undefined){
            RuleValidation();
        }else{
            setRules([...Rules, newRules])
        }  
    }

    const CreateScratchCard = () => {
        if(scratchCardData[0].scratchCardName === cardName){
          let newScratchCard = {
            id : props.location.id,
            scratchCardDescription : cardDescription,
            scratchCardCashbackType : "flat",
            scratchCardMaxCashback : maxCBAmount,
            scratchCardTotalLimitCount : maxScratchCard,
            scratchCardTotalLimitCountPerUser : cardLimit,
            scratchCardValidityPeriod : validity,
            startDate : startDate.concat(" ", startTime),
            endDate : endDate.concat(" ", endTime),
            scratchCardRule : {
                all : Rules
            },
            referralRewardsTypeId : rewardType
        }
        if(newScratchCard.scratchCardMaxCashback === undefined || newScratchCard.scratchCardTotalLimitCount === undefined || newScratchCard.scratchCardTotalLimitCountPerUser === undefined || newScratchCard.scratchCardValidityPeriod === undefined || newScratchCard.startDate === "" || newScratchCard.startDate === undefined || newScratchCard.endDate === "" || newScratchCard.endDate === undefined || newScratchCard.referralRewardsTypeId === "" || newScratchCard.referralRewardsTypeId === undefined){
            validation();
        }else{
            dispatch(editScratchCardAction(newScratchCard, props.history))
        }
        }else{
        let newScratchCard = {
            id : props.location.id,
            scratchCardName : cardName,
            scratchCardDescription : cardDescription,
            scratchCardCashbackType : "flat",
            scratchCardMaxCashback : maxCBAmount,
            scratchCardTotalLimitCount : maxScratchCard,
            scratchCardTotalLimitCountPerUser : cardLimit,
            scratchCardValidityPeriod : validity,
            startDate : startDate.concat(" ", startTime),
            endDate : endDate.concat(" ", endTime),
            scratchCardRule : {
                all : Rules
            },
            referralRewardsTypeId : rewardType
        }
        if(newScratchCard.scratchCardName === undefined || newScratchCard.scratchCardName === undefined || newScratchCard.scratchCardMaxCashback === undefined || newScratchCard.scratchCardTotalLimitCount === undefined || newScratchCard.scratchCardTotalLimitCountPerUser === undefined || newScratchCard.scratchCardValidityPeriod === undefined || newScratchCard.startDate === "" || newScratchCard.startDate === undefined || newScratchCard.endDate === "" || newScratchCard.endDate === undefined || newScratchCard.referralRewardsTypeId === "" || newScratchCard.referralRewardsTypeId === undefined){
            validation();
        }else{
            dispatch(editScratchCardAction(newScratchCard, props.history))
        }
        }
    }


    if(scratchCardData && scratchCardData.length && props.location.id){
    return (
      <>
      <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                        <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                            <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                            <Breadcrumb.Item href="#scratch-card">Scratch Card</Breadcrumb.Item>
                            <Breadcrumb.Item>Edit Scratch Card</Breadcrumb.Item>
                            <Breadcrumb.Item className="text-dark" active>{scratchCardData[0].scratchCardName}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
          <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5 gamestarz">
              <div className="container scratch-card">
                  <Row className="my-3">
                      <Col lg={12} md={12} sm={12} xs={12}>
                          <h4>Edit Scratch Card</h4>
                      </Col>
                  </Row>

                  <Row className="my-3">
                      <Col lg={6} md={6} sm={12} xs={12}>
                          <label className="mb-2">Scratch Card Name</label>
                          <input type="text" className="form-control input_bg" defaultValue={scratchCardData[0].scratchCardName} onChange={(e)=>{setCardName(e.target.value)}}/>
                          {cardNameErr == true ? <span className='text-danger'>Scratch Card Name is required.</span> : ""}
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12}>
                          <label className="mb-2">Validity Period (in Days)</label>
                          <input type="number" className="form-control input_bg" defaultValue={scratchCardData[0].scratchCardValidityPeriod} onChange={(e)=>{setValidity(parseInt(e.target.value))}}/>
                          {ValidityErr ==true ? <span className='text-danger'>Validation is required.</span> : ""}
                      </Col>
                  </Row>

                  <Row className="my-3">
                      <Col lg={12} md={12} sm={12} xs={12}>
                          <label className="mb-2">Scratch Card Description</label>
                          <textarea rows={5} className="form-control input_bg" defaultValue={scratchCardData[0].scratchCardDescription} onChange={(e)=>{setCardDescription(e.target.value)}}></textarea>
                          {cardDescriptionErr == true ? <span className='text-danger'>Scratch Card Description is required.</span> : ""}
                      </Col>
                  </Row>

                  <Row className="my-3">
                      <Col lg={12} md={6} sm={12} xs={12}>
                      <Row className="my-3">
                              <Col lg={6} md={6} sm={12}>
                                  <Row>
                                      <Col lg={6} md={6} sm={12}>
                                          <label className="mb-2">Start Date</label>
                                          <input type="date" className="form-control input_bg" min={new Date().toISOString().split('T')[0]} defaultValue={scratchCardData[0].startDate.slice(0,10)} onChange={(e)=>{setStartDate(e.target.value)}} />
                                          {startDateErr ==true ? <span className='text-danger'>Start Date is required.</span> : ""}
                                      </Col>
                                      <Col lg={6} md={6} sm={12}>
                                          <label className="mb-2">Start Time</label>
                                          <input type="time" className="form-control input_bg" defaultValue={scratchCardData[0].startDate.slice(11, 19)} onChange={(e)=>{setStartTime(e.target.value)}} />
                                          {startTimeErr ==true ? <span className='text-danger'>Start Time is required.</span> : ""}
                                      </Col>
                                  </Row>
                              </Col>

                              <Col lg={6} md={6} sm={12}>
                                  <Row>
                                      <Col lg={6} md={6} sm={12}>
                                          <label className="mb-2">End Date</label>
                                          <input type="date" className="form-control input_bg" min={new Date().toISOString().split('T')[0]} defaultValue={scratchCardData[0].endDate.slice(0,10)} onChange={(e)=>{setEndDate(e.target.value)}}/>
                                          {endDateErr ==true ? <span className='text-danger'>End Date is required.</span> : ""}
                                      </Col>
                                      <Col lg={6} md={6} sm={12}>
                                          <label className="mb-2">End Time</label>
                                          <input type="time" className="form-control input_bg" defaultValue={scratchCardData[0].endDate.slice(11, 19)} onChange={(e)=>{setEndTime(e.target.value)}}/>
                                          {endTimeError ==true ? <span className='text-danger'>End Time is required.</span> : ""}
                                      </Col>
                                  </Row>
                              </Col>
                          </Row>
                          <Row className="my-2">
                              <Col lg={6} md={6} sm={12} xs={12}>
                                  <label className="mb-2">Reward Type</label>
                                  <select className="form-select input_bg" onChange={(e)=>{setRewardType(parseInt(e.target.value))}}>
                                      <option value={scratchCardData[0].referralRewardsType.id}>{scratchCardData[0].referralRewardsType.id === 1 ? "Fixed Cashback Amount" : scratchCardData[0].referralRewardsType.id === 2 ? "Voucher" : scratchCardData[0].referralRewardsType.id === 3 ? "Virtual Currency" : scratchCardData[0].referralRewardsType.id === 4 ? "Inventory Reward" : ""}</option>
                                      <option value="1">Fixed Cashback Amount</option>
                                      <option value="2" disabled>Voucher</option>
                                      <option value="3" disabled>Virtual Currency</option>
                                      <option value="4" disabled>Inventory Reward</option>
                                  </select>
                                  {rewardTypeErr ==true ? <span className='text-danger'>Reward type is required.</span> : ""}
                              </Col>
                              {rewardType === 1 ?
                                  <Col lg={6} md={6} sm={12} xs={12}>
                                      <label className="mb-2">Cashback Amount</label>
                                      <input className="form-control input_bg" type="number" min="1" defaultValue={scratchCardData[0].scratchCardMaxCashback} onChange={(e)=>{setCBAmount(parseInt(e.target.value))}}/>
                                      {maxCBAmountErr ==true ? <span className='text-danger'>End Time is required.</span> : ""}
                                  </Col>
                              : ""}
                          </Row>
                      </Col>

                  </Row>

                  
                  <Row className="my-3">
                      <Col lg={6} md={6} sm={12} xs={12}>
                          <label className="mb-2">User Level</label>
                          <input type="number" min="1" className="form-control input_bg" disabled/>
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12}>
                          <label className="mb-2">User Season Pass Tier</label>
                          <input type="text" className="form-control input_bg" disabled/>
                      </Col>
                  </Row>

                  {/* {Rules && Rules.length !=0 ? 
                      <div>
                          <Row className="mt-2 mb-1 container">
                              <Col sm={12} md={12} lg={12}>
                                  <h6>Rules Created</h6>
                              </Col>
                          </Row>
                                                      
                          <Row className="mb-3">
                              <Col>
                                  <table class="table table-hover text-centered">
                                      <thead>
                                          <tr className='text-center'>
                                              <th scope="col">Fact Name</th>
                                              <th scope="col">Operator</th>
                                              <th scope="col">Value</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                      {Rules && Rules.map((e, i)=>{
                                          return(
                                              <tr className='text-center'>
                                                  <td>{e.fact}</td>
                                                  <td>{e.operator}</td>
                                                  <td>{e.value}</td>
                                              </tr>
                                          )
                                      })}
                                      </tbody>
                                  </table>
                              </Col>  
                          </Row>
                      </div>
                  : ""} */}

                      {scratchCardData[0].scratchCardRule.all && scratchCardData[0].scratchCardRule.all.length !=0 ? 
                        <div>
                            <Row className="mt-2 mb-1 container">
                                <Col sm={12} md={12} lg={12}>
                                    <h6>Rules Created</h6>
                                </Col>
                            </Row>
                                                        
                            <Row className="mb-3">
                                <Col>
                                    <table class="table table-hover text-centered">
                                        <thead>
                                            <tr className='text-center'>
                                                <th scope="col">Fact Name</th>
                                                <th scope="col">Operator</th>
                                                <th scope="col">Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {scratchCardData[0].scratchCardRule.all && scratchCardData[0].scratchCardRule.all.map((e, i)=>{
                                            return(
                                                <tr className='text-center'>
                                                    <td>{e.fact}</td>
                                                    <td>{e.operator}</td>
                                                    <td>{e.value}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </Col>  
                            </Row>
                        </div>
                    : ""}

                    <Row className='my-2'>
                        <Col lg={4} md={4} sm={12}>
                            <label className="mb-2">Select Top Level</label>
                            <select className='form-select input_bg'>
                                <option>Select Top Level</option>
                                <option>All</option>
                                <option>Any</option>
                            </select>
                        </Col>
                        <Col lg={4} md={4} sm={12} className="mt-4 pt-2">
                            <button className='btn btn-success' onClick={Showfact}>Add Facts</button>
                        </Col>
                    </Row>

                    {showFact == true ?
                        <Row className='my-3'>
                            <Col lg={3} md={3} sm={12}>
                                <label className="mb-2">Fact Name</label>
                                <select className='form-select input_bg' onChange={(e)=>{onFactName(e)}}>
                                    <option>Select FactName</option>
                                    <option value="deposit">Deposit</option>
                                </select>
                                {factNameErr === true ? <span className='text-danger'>Fact Name is required</span> : ""}
                            </Col>

                            <Col lg={3} md={3} sm={12}>
                                <label className="mb-2">Data Type</label>
                                <select className='form-select input_bg' onChange={(e)=>{onDataChange(e)}}>
                                    <option>Select Data Type</option>
                                    <option value="1">number</option>
                                    <option value="2">string</option>
                                    <option value="3">array</option>
                                </select>
                                {dataTypeErr === true ? <span className='text-danger'>Data Type is required</span> : ""}
                            </Col>
                        {dataType === 1 ?
                            <Col lg={3} md={3} sm={12}>
                                <label className="mb-2">Operator</label>
                                <select className='form-select input_bg' onChange={(e)=>{onOperator(e)}}>
                                    <option>Select Operator</option>
                                    <option value="lessThan">Less Than</option>
                                    <option value="lessThanInclusive">Less Than Inclusive</option>
                                    <option value="greaterThan">Greater Than</option>
                                    <option value="greaterThanInclusive">Greater Than Inclusive</option>
                                </select>
                                {operatorErr === true ? <span className='text-danger'>Operator is required</span> : ""}
                            </Col>
                            : dataType === 2 ? 
                            <Col lg={3} md={3} sm={12}>
                                <label className="mb-2">Operator</label>
                                <select className='form-select input_bg' onChange={(e)=>{onOperator(e)}}>
                                    <option>Select Operator</option>
                                    <option value="equal">Equal</option>
                                    <option value="notEqual">Not Equal</option>
                                </select>
                                {operatorErr === true ? <span className='text-danger'>Operator is required</span> : ""}
                            </Col> : dataType === 3 ?
                            <Col lg={3} md={3} sm={12}>
                                <label className="mb-2">Operator</label>
                                <select className='form-select input_bg' onChange={(e)=>{onOperator(e)}}>
                                    <option>Select Operator</option>
                                    <option value="in">In</option>
                                    <option value="notIn">Not In</option>
                                    <option value="contains">Contains</option>
                                    <option value="doesNotContain">Does Not Contains</option>
                                </select>
                                {operatorErr === true ? <span className='text-danger'>Operator is required</span> : ""}
                            </Col>
                        : ""}
                        
                        {dataType === 1 ?
                            <Col lg={3} md={3} sm={12}>
                                <label className="mb-2">Value</label>
                                <input type='number' className='form-control input_bg' onChange={(e)=>{onValue(e)}}/>
                                {valueErr === true ? <span className='text-danger'>Value is required</span> : ""}
                            </Col>
                        : dataType === 2 ?
                            <Col lg={3} md={3} sm={12}>
                                <label className="mb-2">Value</label>
                                <input type='text' className='form-control input_bg' onChange={(e)=>{onValue(e)}}/>
                                {valueErr === true ? <span className='text-danger'>Value is required</span> : ""}
                            </Col>
                        : dataType === 3 ?
                        <Col lg={3} md={3} sm={12}>
                            <label className="mb-2">Value</label>
                            <input type='text' className='form-control input_bg' onChange={(e)=>{onValue(e)}}/>
                            {valueErr === true ? <span className='text-danger'>Value is required</span> : ""}
                        </Col>
                        : ""}
                        
                    </Row>

                    : ""}

                  {showFact == true && dataType != null ?
                      <Row className='text-right'>
                          <Col lg={12} md={12} sm={12}>
                              <button className='btn btn-success' onClick={onsave}>Save</button>
                          </Col>
                      </Row>
                  : ""}

                  <Row className="my-3">
                      <Col lg={6} md={6} sm={12} xs={12}>
                          <label className="mb-2">Max Scratch Cards Available</label>
                          <input type="number" min="1" className="form-control input_bg" defaultValue={scratchCardData[0].scratchCardTotalLimitCount} onChange={(e)=>{setMaxScratchCard(parseInt(e.target.value))}}/>
                          {maxCardErr == true ? <span className='text-danger'>Operator is required</span> : ""}
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12}>
                          <label className="mb-2">Max Scratch Card Limit per User</label>
                          <input type="number" min="1" className="form-control input_bg" defaultValue={scratchCardData[0].scratchCardTotalLimitCountPerUser} onChange={(e)=>{setCardLimit(parseInt(e.target.value))}}/>
                          {cardLimitErr == true ? <span className='text-danger'>Operator is required</span> : ""}
                      </Col>
                  </Row>

                  <Row>  
                      <Col lg={6} md={6} sm={12} xs={12}>
                          <label className="mb-3" disabled for="IncludeCountry">Select Countries</label>
                          <Autocomplete multiple disabled disableCloseOnSelect  
                          options={country} getOptionLabel={(option)=> option.CountryName}
                          renderOption={(option, {selected})=>(
                              <React.Fragment>
                                  <Checkbox icon={icon}
                                  checkedIcon={checkedIcon} style={{marginLeft: "10px"}}
                                  checked={selected}  allowSelectAll={true}/>
                                  {option.CountryName} &nbsp;&nbsp; <span onClick={() => handleDeleteShow()}>edit</span>
                              </React.Fragment>  
                          )}
                          renderInput={(params)=>(<TextField {...params} variant="outlined" />)} />
                      </Col>
                  </Row>


                  <Row className="my-5">
                      <Col>
                          <button className="btn btn-success" onClick={CreateScratchCard}>Edit Scratch Card</button>
                      </Col>
                  </Row>

              </div>

              <CountryModal show={deleteShow} onHide={handleDeleteClose}></CountryModal>
          </div>
      </>
  )
  }else{
    return(
      <div className="text-center">
          <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
          {/* <p className="my-5"><p><button className="text-center" type="button" className="btn btn-success mx-4" onClick={Redirect}>To Main Page</button></p></p> */}
      </div>
  )
  }
}

export default EditScratchCard