import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faSearch, faPlusCircle, faTrash, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col,InputGroup, Row, Nav, Button, ButtonGroup, Card, ListGroup, ListGroupItem} from '@themesberg/react-bootstrap';
import { Breadcrumb } from "react-bootstrap";
import { Link , useHistory} from "react-router-dom";
import { Routes } from "../../routes";
import {useDispatch, useSelector} from 'react-redux';
import { getallGamesAction } from '../../redux/action/gameAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../utlis/interceptor'
import { BASE_URL, GET_GAME } from '../../resources/APIEndpoints';


function Gamesincompetition(props) {

    const dispatch = useDispatch();

    const [page, setPage] = useState(1)
    const [counts , setCounts] = useState()
    const [limit, setLimit] = useState(10)

    const getAllGames = () => {
        const data = {
            gameFilters : {
              isDraft : [false],
              archive : [false],
              pagination : {
                offset : ((page-1)*limit),
                limit : limit
              }
            }
          }
          dispatch(getallGamesAction(data))
    }

    const paginateGames = async() => {
        let gamesData = {
          gameFilters:{
            isDraft : [false],
            archive : [false],
            pagination : {}
          },
          entities:[{
              value: "Match",
              attributes : ["id","matchName"]
          }]
        }
    
        let noOfGames = await axios.post(BASE_URL + GET_GAME, gamesData)
        const pageNo = Math.ceil(noOfGames.data.gameData.length/limit)
        setCounts(pageNo)
      }

    useEffect(()=>{
        getAllGames();
        paginateGames();
    }, [page])

    let games = useSelector((state)=>{ 
        return state.game.games
    })

    return (
        <>
        <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                        <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                            <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                            <Breadcrumb.Item href="#competition">Competition</Breadcrumb.Item>
                            <Breadcrumb.Item className="text-dark" active>Games</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
    
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded gamestarz">
                <div className="row mb-4 px-4">
                    <div className="col col-lg-6 col-md-12 text-left">
                        <h4>Games</h4>
                    </div>
                    {/* <div className="col col-lg-6 col-md-12 col-sm-12 text-right">
                        <span className='filter' style={{cursor: "pointer"}}><i className="fas fa-filter mx-2"></i><span>Filter</span></span>
                    </div> */}
                </div>

                <div className="container payout">
                    <div className="my-5">
                        <Row className='text-center' style={{cursor: 'pointer'}} >
                            {games && games.map((e)=>{
                                if(e.archive === false && e.isDraft === false){
                                    return(
                                        <Col lg={6} md={6} sm={6}>
                                            <Card as={Link} to={{pathname: Routes.gamesWithComp.path, id: e.id}} style={{width: '100%', border : '1px solid #eee', padding : '10px 0px', margin : '10px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'}} inverse outline color="danger">
                                                    <ListGroup flush>
                                                        <ListGroupItem><b>{e.gameName}</b></ListGroupItem>
                                                    </ListGroup>
                                            </Card>
                                        </Col>
                                    )
                                }
                            })}
                        </Row>     
                    </div> 
            </div>
        </div>
    </>
  )
}

export default Gamesincompetition