import React, {useState} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBan} from '@fortawesome/free-solid-svg-icons'


function EndLiveCompetitionModal({show, onHide, Stop}) {


    const[showLive, setShowLive] = useState()
    
    const setLiveNow = (e) => {
        setShowLive(false)
        setDate(moment().format("YYYY-MM-DD"))
        setTime(moment().format('LTS'))
    }

    const setLiveLater = (e) => {
        setShowLive(true)
    }

    const [date, setDate] = useState("")

    const onSetDate = (e) => {
        setDate(e.target.value)
    }

    const [time, setTime] = useState("")

    const onSetTime = (e) => {
        setTime(e.target.value)
    }

    const close = () => {
        onHide();
    }

    const Save = () => {
        Stop(date, time)
        onHide();
    }

    return (
        <Modal scrollable={true} size="md" centered show={show} onHide={onHide}>
                <Modal.Header>
                    <div>
                        <h6>Stop Live Toggle</h6>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Row className="justify-content-center">
                        <Col>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <input type="radio" name="live" onClick={(e)=>{setLiveNow(e)}}/>
                                    <label className="mx-2" for="live">Stop Now</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <input type="radio" name="live" onClick={(e)=>{setLiveLater(e)}}/>
                                    <label className="mx-2" for="live">Schedule Stop Time</label>
                                </Col>
                            </Row>
                            {showLive ? 
                                <div>
                                    <Row className="my-2">
                                        <Col>
                                            <div className="form-group">
                                                <label>Set Date</label>
                                                <input type="date" className="form-control" onChange={(e)=>{onSetDate(e)}}></input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="my-2">
                                        <Col>
                                            <div className="form-group">
                                                <label>Set Time</label>
                                                <input type="time" step="1" className="form-control" onChange={(e)=>{onSetTime(e)}}></input>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            : ""}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col>
                            <Button className="btn btn-success btn -sm mx-2" onClick={Save}>Save</Button>
                            <Button className="btn btn-danger btn -sm mx-2" onClick={close}>Cancel</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
    )
}


export default EndLiveCompetitionModal