import React, {useEffect, useState} from 'react'
import {Dropdown, Row, Col, ButtonGroup, Button, InputGroup, Form, Nav} from '@themesberg/react-bootstrap'
import {Game_Details, Game_Id,Game_Name, Game_Description, Game_How_to, Game_Platform, Asset_Bundle_Version, Minimum_App_Version, Game_Genre, Screen_Orientation, Game_World, Match_Name, Match_Format, Match_How_To, Match_Outcome_Type, Leaderboard_Outcome_Type, Included_Countries, Phase, States, Match_Details, Game_Screenshots} from '../../constants/LabelConstants'
import XLSX from 'xlsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../utlis/interceptor';
import { ExternalGameByIdAction, EditExternalGameAction } from '../../redux/action/gameAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


function ViewExternalGames(props) {

    const dispatch = useDispatch()
    const history = useHistory()

    const [rejectReason, setRejectReason] = useState("")

    const onRejectionReason = (e) => {
        setRejectReason(e.target.value)
    }

    const [showReject, setShowReject] = useState(false)
    const onShowReject = () => {
        setShowReject(!showReject)
    }

    useEffect(()=>{
        getExternalGameById();
    }, [])

    const getExternalGameById = () => {

        let externalGames = {
            gameSubmissionFilters : {
                id : [props.location.id],
                pagination : {}
            }
        }
        dispatch(ExternalGameByIdAction(externalGames))
    }

    
    let gamesDetail = useSelector((state)=>{ 
        return state.game.externalGameDetail
    })

    const accept = () => {
        
        let acceptedGame = {
            id :  gamesDetail[0].id,
            gameSubmissionStatus : "approved"
        }
        dispatch(EditExternalGameAction(acceptedGame, props.history))
    }

    const reject = () => {
        let rejectedGames = {
            id :  gamesDetail[0].id,
            gameSubmissionStatus : "rejected"
        }
        dispatch(EditExternalGameAction(rejectedGames, props.history))
    }

    const cancel = () => {
        history.push('/external-games')
    }

    const onCancel = () => {
        setShowReject(false)
    }

    const onRejectCancel = () => {
        history.push('/external-games')
    }

    if(gamesDetail && gamesDetail.length){
    return (
        <>
        <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                    <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                        <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                        <Breadcrumb.Item href='#games'>Games</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-dark" active>External Games</Breadcrumb.Item>
                    </Breadcrumb>
                    </Col>
                </Row>
            </div>
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0"></Col>
            </div>
                        
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5 gamedetail-model">
                <div className="container gamedetail-body">
                    <Row className="mt-3">
                            <Col lg={6} md={12} sm={12}>
                                <label>{Game_Name}</label>
                                <input type='text' disabled className='form-control' defaultValue={gamesDetail[0].gameName != "" ? gamesDetail[0].gameName : "No info"} /> 
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <span>{Game_Id}</span> 
                                <input type='text' disabled className='form-control' defaultValue={gamesDetail[0].id != "" ? gamesDetail[0].id : "No info"}/>
                            </Col>
                    </Row>
                        <Row className="mt-3">
                            <Col lg={12} md={12} sm={12}>
                                <span>{Game_Description}</span>
                                <textarea rows={5} type='text' disabled className='form-control' defaultValue={gamesDetail[0].gameDescription =="" || gamesDetail[0].gameDescription == null  ? "No info" : gamesDetail[0].gameDescription !="" ? gamesDetail[0].gameDescription : ""}></textarea> 
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col lg={12} md={12} sm={12}>
                                <span>{Game_How_to} </span>
                                <textarea rows={5} type='text' disabled className='form-control' defaultValue={gamesDetail[0].gameHowTo =="" || gamesDetail[0].gameHowTo == null  ? "No info" : gamesDetail[0].gameHowTo !="" ? gamesDetail[0].gameHowTo : ""}></textarea> 
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col lg={6} md={12} sm={12}>
                                <span>Game Platforms</span>
                                <p className='input-bg'>{gamesDetail[0].gameSubmissionPlatforms.length !=0 ? gamesDetail[0].gameSubmissionPlatforms && gamesDetail[0].gameSubmissionPlatforms.map((p,i)=>{return(<span>{(i ? ', ' : '') + p.gamePlatformMaster.platformName}</span>)}) : "-"}</p>
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <span>{Screen_Orientation}</span> 
                                <input type='text' disabled className='form-control' defaultValue= {gamesDetail[0].isGameScreenOrientationLandscape === true ? "Landscape" : gamesDetail[0].isGameScreenOrientationLandscape === false ? "Portrait" : "No info"}/>
                            </Col>
                        </Row>

                        {gamesDetail[0].gameSubmissionPlatforms.length > 0 && gamesDetail[0].gameSubmissionPlatforms[0].gameUrl == null  ?
                        <Row className="mt-3">
                            <Col lg={6} md={12} sm={12}>
                                <div className="mb-2">Android Asset Bundle</div> 
                                {gamesDetail[0].gameSubmissionPlatforms.length != 0 &&  gamesDetail[0].gameSubmissionPlatforms[0].gamePlatformMaster.platformName === 'android' ? <a className='download-btn' href={gamesDetail[0].gameSubmissionPlatforms[0].gameAssetBundleUrl}>Download file</a> : gamesDetail[0].gameSubmissionPlatforms.length > 1 &&  gamesDetail[0].gameSubmissionPlatforms[1].gamePlatformMaster.platformName === 'android' ? <a className='download-btn' href={gamesDetail[0].gameSubmissionPlatforms[1].gameAssetBundleUrl}>Download file</a>  : "No info"}
                                
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <div className="mb-2">iOS Asset Bundle</div> 
                                {gamesDetail[0].gameSubmissionPlatforms.length != 0 &&  gamesDetail[0].gameSubmissionPlatforms[0].gamePlatformMaster.platformName === 'ios' ? <a className='download-btn' href={gamesDetail[0].gameSubmissionPlatforms[0].gameAssetBundleUrl}>Download file</a> : gamesDetail[0].gameSubmissionPlatforms.length > 1 &&  gamesDetail[0].gameSubmissionPlatforms[1].gamePlatformMaster.platformName === 'ios' ? <a className='download-btn' href={gamesDetail[0].gameSubmissionPlatforms[1].gameAssetBundleUrl}>Download file</a>  :"No info"}
                            </Col>
                        </Row>
                        :gamesDetail[0].gameSubmissionPlatforms.length > 0 && gamesDetail[0].gameSubmissionPlatforms[0].gameAssetBundleUrl == null?
                        
                        <Row className="mt-3">
                            <Col lg={6} md={12} sm={12}>
                                <div className="mb-2">Playstore Link</div> 
                                {gamesDetail[0].gameSubmissionPlatforms.length != 0 &&  gamesDetail[0].gameSubmissionPlatforms[0].gamePlatformMaster.platformName === 'android' ? <a target="_blank" className='download-btn' href={gamesDetail[0].gameSubmissionPlatforms[0].gameUrl}>View</a> : gamesDetail[0].gameSubmissionPlatforms.length > 1 &&  gamesDetail[0].gameSubmissionPlatforms[1].gamePlatformMaster.platformName === 'android' ? <a target="_blank" className='download-btn'  href={gamesDetail[0].gameSubmissionPlatforms[1].gameUrl}>View</a> : "No info"}
                                
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <div className="mb-2">Appstore Link</div> 
                                {gamesDetail[0].gameSubmissionPlatforms.length != 0 &&  gamesDetail[0].gameSubmissionPlatforms[0].gamePlatformMaster.platformName === 'ios' ? <a target="_blank" className='download-btn' href={gamesDetail[0].gameSubmissionPlatforms[0].gameUrl}>View</a> : gamesDetail[0].gameSubmissionPlatforms.length > 1 &&  gamesDetail[0].gameSubmissionPlatforms[1].gamePlatformMaster.platformName === 'ios' ? <a target="_blank" className='download-btn' href={gamesDetail[0].gameSubmissionPlatforms[1].gameUrl}>View</a> : "No info"}
                            </Col>
                        </Row>
                        :''}

                        <Row className="mt-3">
                            <Col lg={6} md={12} sm={12}>
                                <span>{Game_Genre}</span>
                                <input type='text' disabled className='form-control' defaultValue={gamesDetail[0].gameGenreMaster == null ? "-" : gamesDetail[0].gameGenreMaster != null ? gamesDetail[0].gameGenreMaster.genreName : ""} /> 
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <span>{Game_World}</span> 
                                <input type='text' disabled className='form-control' defaultValue="Sports"/>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <span>{Game_Screenshots}</span>
                                <p className='input-bg'>
                                    <Row>
                                        {gamesDetail[0].gameScreenShotUrl !="" && gamesDetail[0].gameScreenShotUrl != null ? 
                                        gamesDetail[0].gameScreenShotUrl !="" && gamesDetail[0].gameScreenShotUrl != null && gamesDetail[0].gameScreenShotUrl && gamesDetail[0].gameScreenShotUrl.map((e,i)=>{
                                            return(
                                            <Col xl={2} lg={2} md={3} sm={4}>
                                                    <img src={e} className='img-responsive my-2' alt='Screenshot'/>
                                            </Col>
                                            )
                                        })  
                                        : <p>No Info</p>}
                                    </Row>
                                </p>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <span>Preview Video</span>
                                <p className='input-bg'>
                                    <Row>
                                    <Col lg={4}>
                                        {gamesDetail[0].gamePreviewVideoUrl && gamesDetail[0].gamePreviewVideoUrl != null ? <video width="200" controls src={gamesDetail[0].gamePreviewVideoUrl !='' ? gamesDetail[0].gamePreviewVideoUrl  : ''} type="video/mp4"/> : 'No Info'}
                                    </Col>
                                    </Row>
                                </p>
                            </Col>
                        </Row>
                        
                        {showReject == true ?
                        <Row className="mt-3">
                            <Col lg={12} md={12} sm={12}>
                                <label className='my-2'>Rejection Reason</label>
                                <textarea className='form-control' rows={4} onChange={(e)=>{onRejectionReason(e)}}></textarea>
                            </Col>
                        </Row>: ""}

                        <Row className="mt-3">
                            <Col>
                                {gamesDetail[0].gameSubmissionStatus == 'rejected' ?
                                    <div>
                                        <Button className="btn btn-success btn-md mx-2" onClick={accept}>Approve</Button>
                                        <Button className="btn btn-danger btn-md mx-2" onClick={cancel}>Cancel</Button>
                                    </div>
                                : ""}   
                                {gamesDetail[0].gameSubmissionStatus == 'approved' && showReject == false ?
                                    <div>
                                        <Button className="btn btn-danger btn-md mx-2" onClick={onShowReject}>Reject</Button>
                                        <Button className="btn btn-success btn-md mx-2" onClick={onRejectCancel}>Cancel</Button>
                                    </div>
                                :gamesDetail[0].gameSubmissionStatus == 'approved' && showReject == true ?
                                    <div>
                                        <Button className="btn btn-danger btn-md mx-2" onClick={reject}>Confirm Reject</Button>
                                        <Button className="btn btn-success btn-md mx-2" onClick={onCancel}>Cancel</Button>
                                    </div>
                                : ""}
                                {gamesDetail[0].gameSubmissionStatus == 'in review' && showReject == false  ?
                                    <Row>
                                        <Col>
                                            <Button className="btn btn-success btn-md mx-2" onClick={accept}>Approve</Button>
                                            <Button className="btn btn-danger btn-md mx-2" onClick={onShowReject}>Reject</Button>
                                            <Button className="btn btn-warning btn-md mx-2" onClick={cancel}>Cancel</Button>
                                        </Col>
                                    </Row>
                                : gamesDetail[0].gameSubmissionStatus == 'in review' && showReject == true  ?
                                    <Row>
                                        <Col>
                                            <Button className="btn btn-danger btn-md mx-2" onClick={reject}>Confirm Reject</Button>
                                            <Button className="btn btn-warning btn-md mx-2" onClick={onCancel}>Cancel</Button>
                                        </Col>
                                    </Row>
                                : ""}  
                            </Col>
                        </Row>
                </div>
            </div>
        </>
    )
    }else{
        return(<p></p>)
    }
}

export default ViewExternalGames