import React, { useEffect, useState } from 'react'
import { Dropdown, Col,InputGroup, Row, Nav, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import { Routes } from '../../routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getAllVersionAction, getAllVesionByIdAction, clearVersionAction } from '../../redux/action/versionAction';
import AddVersionModel from '../modals/VersionModels/AddVersionModel';
import EditVersionModel from '../modals/VersionModels/EditVersionModel';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Breadcrumb } from "react-bootstrap";

function Version() {

  const dispatch = useDispatch()
  const [showVersion, setShowVersion] = useState(false)

  const addVersion = () => {
    setShowVersion(!showVersion)
  }

  useEffect(()=>{
    dispatch(getAllVersionAction())
  }, [showeditVersion, setEditVersion])

  let versionData = useSelector((state)=>{
    return state.version.versions
  })

  const [showeditVersion, setEditVersion] = useState(false)
  const editVersion = (platform) => {
      if(!showeditVersion === true){
        let editData = {
          platform : platform
        }
      dispatch(getAllVesionByIdAction(editData))
      dispatch(clearVersionAction())
      }else{
        let getData = {

        }
        dispatch(getAllVersionAction(getData))
      }

      setEditVersion(!showeditVersion)
  }

  return (
    <>
    <ToastContainer />
    <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
          <Row>
              <Col lg={12}>
                  <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                      <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                      <Breadcrumb.Item className="text-dark" active>Version</Breadcrumb.Item>
                  </Breadcrumb>
              </Col>
          </Row>
        </div>

      <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
          <Button as={Link} onClick={addVersion} className="button-style me-2">
            <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
            <span>Add Version</span>
          </Button>
        </Col>

        <Col xs={12} lg={6} className="px-0 mb-0 text-right">
            <ButtonGroup>
                {/* <Button variant="outline-primary" className='export' size="sm">Export</Button> */}
                {/* <CSVLink {...csvReport}>Export</CSVLink>    */}
            </ButtonGroup>
        </Col>
      </div>
      <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded version">
          <div className="row mb-4 px-4">
                <div className="col col-lg-6 col-md-12 text-left">
                    <p className='page-heading'>Versions</p>
                </div>
                <div className="col col-lg-12 col-md-12 col-sm-12 text-right">

                </div>
            </div>
          <div>
            <Row className="container-fluid table-responsive">
                <Col lg={12} md={12} sm={12}>
                    <table  className="table table-striped text-center table-custom">
                        <thead className='table-head'>
                          <tr>
                              <td className='head-border-left'>Platform</td>   
                              <td>Major Version</td>                
                              <td>Minor Version</td>   
                              <td>Patch Version</td>  
                              <td className='head-border-right'>Actions</td>  
                          </tr>
                        </thead>
                        <tbody className='table-body'>
                            {versionData && versionData.map((e)=>{
                              return(
                                <tr className='table-body' key={e.id}>
                                  <td>{e.platform}</td>
                                  <td>{e.majorVersion}</td>
                                  <td>{e.minorVersion}</td>
                                  <td>{e.patchVersion}</td>
                                  <td>
                                    <FontAwesomeIcon className="mx-2" icon={faEdit} style={{color: "grey", cursor: "pointer", fontSize: "20px"}} onClick={()=>{editVersion(e.platform)}}/>
                                  </td>
                                </tr>
                              )
                            })}          
                        </tbody>
                    </table>
                </Col>
            </Row> 
          </div>
          <AddVersionModel show={showVersion} onHide={addVersion}/>
          <EditVersionModel show={showeditVersion} onHide={editVersion} />
      </div>
    </>
  )
}

export default Version