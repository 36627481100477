import {combineReducers} from 'redux'
import {gameReducer} from './gameReducer'
import {leaderboardReducer} from './leaderboardReducer';
import {competitionReducer} from './competitionReducer'
import {playstarzbankReducer} from './playstarzbankReducer';
import {referralReducer} from './referralReducer';
import {authReducer} from './authReducer';
import {versionReducer} from './versionReducer';
import {analyticsReducer} from './analyticsReducer';
import {organisationReducer} from './organisationReducer';
import {teamsReducer} from './teamsReducer';
import {businessLogicReducer} from './businessLogicReducer';
import {documentsReducer} from './documentsReducer'

export default combineReducers(
    {
        game : gameReducer,
        leaderboard : leaderboardReducer,
        competition : competitionReducer,
        playstarzbank : playstarzbankReducer,
        referral : referralReducer,
        auth: authReducer,
        version : versionReducer,
        analytics : analyticsReducer, 
        organization : organisationReducer,
        team : teamsReducer,
        businessLogic : businessLogicReducer,
        documents: documentsReducer
    }
);