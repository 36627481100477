import {GET_COMPETITION, SEARCH_TEMPLATE, STOP_COMPETITION, CREATE_COMPETITION, EDIT_COMPETITION, SEARCH_COMPETITION, GET_COMPETITION_DETAIL, GET_COMPETITION_BY_ID, GET_TEMPLATES, CREATE_TEMPLATES, EDIT_TEMPLATES, GET_TEMPLATES_BY_ID, GENRATE_PD, CLEAR_COMPETITION, CLEAR_TEMPLATES, GET_ORGANIZED_COMPETITION, CREATE_ORGANIZED_COMPETITION, EDIT_ORGANIZED_COMPETITION} from '../actionType/actionTypes'

const initialState = {
    competitions : [],
    competitionDetail : [],
    competitionById : [],
    templates : [],
    templatesDetail : [],
    PD : [],
    organizedCompetition : [],
    isLoading : true
}

export const competitionReducer = (state = initialState, action)=>{
    switch(action.type){

        case GET_COMPETITION : {
            return {
                ...state,
                competitions : action.payload,
                isLoading : false
            }
        }

        case GET_TEMPLATES : {
            return{
                ...state,
                templates : action.payload
            }
        }

        case GET_TEMPLATES_BY_ID : {
            return {
                ...state,
                templatesDetail :  action.payload
            }
        }

        case GET_COMPETITION_DETAIL : {
            return {
                ...state,
                competitionDetail : action.payload
            }
        }

        case CREATE_COMPETITION : {
            return {
                ...state,
                competitions : action.payload
            }
        }

        case CREATE_TEMPLATES : {
            return {
                ...state,
                templates : action.payload
            }
        }

        case EDIT_COMPETITION : {
            return {
                ...state,
                competitions : action.payload
            }
        }

        case EDIT_TEMPLATES : {
            return {
                ...state,
                templates : action.payload
            }
        }

        case SEARCH_COMPETITION : {
            return {
                ...state,
                competitions : action.payload
            }
        }

        case GET_COMPETITION_BY_ID : {
            return {
                ...state,
                competitionById : action.payload
            }
        }

        case STOP_COMPETITION : {
            return {
                ...state,
                competitions : action.payload
            }
        }

        case SEARCH_TEMPLATE : {
            return {
                ...state,
                templates : action.payload
            }
        }

        case GENRATE_PD : {
            return {
                ...state,
                PD : action.payload
            }
        }

        case CLEAR_COMPETITION : {
            return {
                ...state,
                competitionDetail : []
            }
        }

        case CLEAR_TEMPLATES : {
            return {
                ...state,
                templatesDetail : []
            }
        }

        case GET_ORGANIZED_COMPETITION : {
            return {
                ...state,
                organizedCompetition : action.payload
            }
        }

        case CREATE_ORGANIZED_COMPETITION : {
            return {
                ...state,
                organizedCompetition : action.payload
            }
        }

        case EDIT_ORGANIZED_COMPETITION : {
            return {
                ...state,
                organizedCompetition : action.payload
            }
        }

        default : {
            return {
                ...state
            }
        }
    }
}