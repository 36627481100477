import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faEye, faArchive, faPlus, faSearch, faPlusCircle, faTrash, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Col,InputGroup, Row, Nav, Button, ButtonGroup} from '@themesberg/react-bootstrap';
import { Breadcrumb } from "react-bootstrap";
import { Link , useHistory} from "react-router-dom";
import { Routes } from "../../../routes";
import {useDispatch, useSelector} from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QueryBuilder, { formatQuery } from "react-querybuilder";
import axios from '../../../utlis/interceptor'
import { BASE_URL, GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE, GET_ALL_OPERATOR } from '../../../resources/APIEndpoints';
import { createRuleAction } from '../../../redux/action/businessLogicAction';

export default function Rules(props){

    const dispatch = useDispatch();
    const history = useHistory()

    const [ruleName, setRuleName] = useState("")
    const [ruleDescription, setRuleDescription] = useState("")

    const onRuleName = (e) => {
        if(e.target.value == '' || e.target.value == undefined){
            setRuleNameErr(true)
        }else{
            setRuleNameErr(false)
            setRuleName(e.target.value)
        }
    }

    const onRuleDescription = (e) => {
        if(e.target.value == '' || e.target.value == undefined){
            setRuleDescriptionErr(true)
        }else{
            setRuleDescriptionErr(false)
            setRuleDescription(e.target.value)
        }
        
    }

    const [showDecision, setShowDecision] = useState(false)
    const [showBtns, setShowBtns] = useState(false)

    const onCreateDecision = () => {
        setShowDecision(true)
        setShowBtns(true)
    }


    // Decision

    const [fields, setFields] = useState([{name: "Attribute One", label: "Attribute One"},{name: "Attribute Two", label: "Attribute Two"},
    {name: "Attribute Three", label: "Attribute Four"},{name: "Attribute Four", label: "Attribute Four"}])
  
    // const operators = [
    //   { name: "equal", label: "equal to" },
    //   { name: "notEqual", label: "not equal to" },
    //   { name: "lessThan", label: "less than" },
    //   { name: "greaterThan", label: "greater than" },
    //   { name: "lessThanEqualTo", label: "less than equal to" },
    //   { name: "greaterThanEqualTo", label: "greater than equal to" }
    // ];

    const [operators, setOperators] = useState([])
  
    const combinators = [{ name: "and", label: "AND" },{ name: "or", label: "OR" }];
  
    const existingQuery = {
      id: "g-qwjn8HiAtZ4-Z9i5Bi2br",
      rules: [
        {
          id: "r-wHzK6u-5a0YBETrkTFj3B",
          field: "firstName",
          value: "Steve",
          operator: "="
        },
        {
          id: "r-ISUlfhfzTULS5LTKjeKUH",
          field: "lastName",
          value: "Vai",
          operator: "="
        }
      ],
      combinator: "and",
      not: false
    };
  
    const buildClasses = () => {
      return {
        queryBuilder: "qb-container", // Root <div> element
        ruleGroup: "rule-group notification", // <div> containing the RuleGroup
        combinators: "select select-combinator", // <select> control for combinators
        addRule: "btn-add-rule button is-info is-small is-rounded", // <button> to add a Rule
        addGroup: "btn-add-group button is-info is-small is-rounded", // <button> to add a RuleGroup
        removeGroup: "button is-danger is-small is-rounded", // <button> to remove a RuleGroup
        notToggle: "label", // <label> on the "not" toggle
        rule: "rule notification", // <div> containing the Rule
        fields: "select select-field", // <select> control for fields
        operators: "select select-operator", // <select> control for operators
        value: "input input-value is-small", // <input> for the field value
        removeRule: "button is-danger is-small is-rounded" // <button> to remove a Rule
      };
    };

    const getAttributeDetail = async() => {
        let attributeDetail = {
            conditionTypeAttributeFilters : {
                conditionTypeId : null,
                pagination : {
 
                }
            }
        }

        let result = []

        let res = await axios.post(BASE_URL + GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE, attributeDetail)
        for(var i = 0; i < res.data.attributeDetails.length ; i++){
            // result.push({name : res.data.attributeDetails[i].attributeName, label : res.data.attributeDetails[i].attributeName})
            let eachOperator = []
            for(var j = 0 ; j < res.data.attributeDetails[i].dataTypeMaster.operator.length ; j++){
                eachOperator.push({name : res.data.attributeDetails[i].dataTypeMaster.operator[j].operatorSymbol, label : res.data.attributeDetails[i].dataTypeMaster.operator[j].operatorSymbol})
            }
            let type = ''
            if(res.data.attributeDetails[i].dataTypeMaster.dataTypeName == 'string'){
                type = 'text'
            }else if(res.data.attributeDetails[i].dataTypeMaster.dataTypeName == 'integer'){
                type = 'number'
            }else if(res.data.attributeDetails[i].dataTypeMaster.dataTypeName == 'boolean'){
                type = 'text'
            }else if(res.data.attributeDetails[i].dataTypeMaster.dataTypeName == 'float'){
                type = 'number'
            }
            result.push({name : res.data.attributeDetails[i].attributeName, label : res.data.attributeDetails[i].attributeName, inputType : type, operators: eachOperator})
        }

        setFields(result)
    }
  
    useEffect(()=> {
        getAttributeDetail();
        getAllOperator();
    }, [])
  
  
    const [newRules, setNewRules] = useState([])
    const [rules, setRules] = useState()
  
    const onQueryChange = queryJSON => {
      setRules(queryJSON)
      setShowBtns(true)
    };
  
    const saveRule = () => {

        var result = JSON.parse(JSON.stringify(rules));

        function recursive(result){
            for(var i = 0 ; i < result.length ; i++){
                var current = result[i]
                current.fact = current['field'];
                delete current.valueSource
                delete current.field;
                delete current.id
                if((current.rules || current.any || current.all) && (current.rules.length || current.any.length || current.all.length) > 0){
                    if(current.combinator == 'and'){
                        current.all = current['rules'];
                    }else{
                        current.any = current['rules'];
                    }
                    delete current.fact;
                    recursive(current.rules)
                    delete current.rules
                    delete current.combinator
                    delete current.not
                }
            }
        }

        recursive(result.rules)

        setNewRules(result.rules)

        toast.success("Decision Created Successfully.")

        setDecisionErr(false)

    }

    // Outcome

    const [outcome, setOutcome] = useState({key: "", value: ""})
    const [results, setResult] = useState([])

    const cancel = (i) => {
        const values = [...results];
        values.splice(i, 1)
        setResult(values)
    }

    const [showOutcome, setShowOutcome] = useState(false)

    const onCreateOutcome = () => {
        setShowOutcome(true)

        let tempOutcome = []
        tempOutcome.push(...results, {key: "", value: ""})
        setResult(tempOutcome)
    }

    const onOutcomeKey = (e,i) => {
        results[i].key = e.target.value
    }

    const onOutcomeValue = (e,i) => {
        results[i].value = e.target.value
    }


    const saveRules = () => {

        if(rules.combinator == 'and'){
            let createRule = {
                ruleName : ruleName,
                ruleDescription : ruleDescription,
                rule: {
                    all: newRules,
                    event : {
                        type : true,
                        params : results
                    }
                },
                conditionTypeId: props.location.id
            }
            if(ruleName == '' || ruleDescription == '' || newRules.length == 0){
                validation();
            }else{
                dispatch(createRuleAction(createRule, props.history))
            }
            
        }else if(rules.combinator == 'or'){
            let createRule = {
                ruleName : ruleName,
                ruleDescription : ruleDescription,
                rule: {
                    any: newRules,
                    event : {
                        type : true,
                        params : results
                    }
                },
                conditionTypeId: props.location.id
            }
            if(ruleName == '' || ruleDescription == '' || newRules.length == 0){
                validation();
            }else{
                dispatch(createRuleAction(createRule, props.history))
            }
        }

    }

    const [ruleNameErr, setRuleNameErr] = useState(false)
    const [ruleDescriptionErr, setRuleDescriptionErr] = useState(false)
    const [decisionErr, setDecisionErr] = useState(false)
    const [outcomeErr, setOutcomeErr] = useState(false)

    const validation = () => {
        if(ruleName == '' || ruleName == undefined){
            setRuleNameErr(true)
        }
        if(ruleDescription == '' || ruleDescription == undefined){
            setRuleDescriptionErr(true)
        }
        if(newRules.length == 0){
            setDecisionErr(true)
        }
    }

    const getAllOperator = async() => {
        let getOperator = {
            operatorTypeFilters : {
                pagination : {
                    
                }
            }
        }
        let res = await axios.post(BASE_URL + GET_ALL_OPERATOR, getOperator)
        let initial = []
        for(var i = 0 ; i < res.data.ruleDetails.length; i++){
            initial.push({name : res.data.ruleDetails[i].operatorSymbol , label : res.data.ruleDetails[i].operatorSymbol })
        }
        setOperators(initial)
    }

    const cancelRule = () => {
        history.push(Routes.BusinessLogic.path)
    }

    return (
    <>
            <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                        <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                            <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                            <Breadcrumb.Item href="#business-logic">Business Logic</Breadcrumb.Item>
                            <Breadcrumb.Item className="text-dark" active>Rules</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
    
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded gamestarz">
                <div className="row mb-4 px-4">
                    <div className="col col-lg-6 col-md-12 text-left">
                        <h4>Create Rules</h4>
                    </div>
                </div>

                <div className="container-fluid payout">
                    <div className="my-3">
                        <Row className='my-3'>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <label className='my-2'>Rule Name</label>
                                <input type="text" className='form-control' onChange={(e)=>{onRuleName(e)}}/>
                                {ruleNameErr ? <span className='text-danger'>* Rule Name is required.</span> : ''}
                            </Col>
                        </Row>

                        <Row className='my-3'>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <label className='my-2'>Rule Description</label>
                                <textarea className='form-control' rows={5} onChange={(e)=>{onRuleDescription(e)}}></textarea>
                                {ruleDescriptionErr ? <span className='text-danger'>* Rule Description is required.</span> : ''}
                            </Col>
                        </Row>

                        <Row className='my-4'>
                          <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                              <Button onClick={onCreateDecision} className="button-style me-3" style={{width: "200px", display: "Inline !important"}}>
                                  <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                                  <span>Create Decision</span>
                              </Button>
                              {decisionErr ? <span className='text-danger'>*Decision is required.</span> : ''}
                          </Col>
                        </Row>

                        {showDecision ?
                            <>
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <div className="payout">
                                
                                            <Row className='my-3'>
                                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                    <QueryBuilder
                                                    fields={fields}
                                                    operators={operators}
                                                    combinators={combinators}
                                                    onQueryChange={onQueryChange}
                                                    controlClassnames={buildClasses()}
                                                    showCombinatorsBetweenRules={false} />
                                                </Col>
                                            </Row>
                                            {showBtns ? 
                                            <Row className='my-2'>
                                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                    <button className='btn btn-outline-success mx-3' onClick={saveRule}>Save</button>
                                                    <button className='btn btn-outline-danger'>Cancel</button>
                                                </Col>
                                            </Row>
                                            : ''}
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        : ""}

                        <Row className='my-4'>
                          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Button onClick={onCreateOutcome} className="button-style me-3" style={{width: "200px", display: "Inline !important"}}>
                                  <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                                  <span>Create Outcome</span>
                              </Button>
                          </Col>
                        </Row>

                        {showOutcome ?
                            <>
                                <Row>
                                    <Col>
                                        {results && results.map((e, i)=>{
                                            return(
                                                <>
                                                    <Row className='my-3 align-items-center'>
                                                        <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                                            <input type="text" className='form-control' placeholder='Key' onChange={(e)=>{onOutcomeKey(e,i)}}/>
                                                        </Col>

                                                        <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                                            <input type="number" className='form-control' placeholder='Value' onChange={(e)=>{onOutcomeValue(e,i)}}/>
                                                        </Col>
                                                        <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                                            <Button className='btn btn-sm btn-danger' onClick={()=>{cancel(i)}}>X</Button>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                        })}
                                    </Col>
                                </Row>
                            </>
                        : ""}
                        <Row className='my-4'>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                <button className='btn btn-success' disabled={newRules.length == 0 ? true : false} onClick={saveRules}>Save</button>
                                <button className='btn btn-danger mx-3' onClick={cancelRule}>Cancel</button>
                            </Col>
                        </Row>
                    </div> 
                </div>
            </div>
        </>
  )
}
