import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col, Dropdown} from '@themesberg/react-bootstrap'
import { clearSCAction } from '../../../../redux/action/playstarzbankAction';

function ScratchCardDetailModal({show, onHide}) {

    const dispatch = useDispatch()
    const close = () => {
        // dispatch(clearSCAction())
        onHide();
    }

    let scratchCardData = useSelector((state)=>{ 
        return state.playstarzbank.ScratchCardDetail
    })

    if(scratchCardData && scratchCardData.length != 0){
    return (
        <div>
            <Modal size="lg" centered show={show} onHide={onHide} className='gamestarz-modal'>
                <Modal.Header>
                    <div className='scrachcard-modal'>
                        <span className='sc-head'>{scratchCardData[0].scratchCardName}</span>
                    </div>
                </Modal.Header>
                <Modal.Body className='scrachcard-modal'>
                    <div className='sc-body'>
                        <Row className="my-2">
                            <Col lg={6} md={6} sm={12}>
                                <span>Scratch Card Name</span>
                                <input disabled type='text' className='form-control input-bg' defaultValue={scratchCardData[0].scratchCardName} />
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <span>Validity Period</span>
                                <input disabled type='text' className='form-control input-bg' defaultValue={scratchCardData[0].scratchCardValidityPeriod} />
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col lg={6} md={6} sm={12}>
                                <span>Start Date</span>
                                <input disabled type='text' className='form-control input-bg' defaultValue={scratchCardData[0].startDate.slice(0,10)} />
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <span>End Date</span>
                                <input disabled type='text' className='form-control input-bg' defaultValue={scratchCardData[0].endDate.slice(0,10)} />
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col lg={6} md={6} sm={12}>
                                <span>Max Scratch Card Available</span>
                                <input disabled type='text' className='form-control input-bg' defaultValue={scratchCardData[0].scratchCardTotalLimitCount} />
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <span>Max Scratch Card Limit per User</span>
                                <input disabled type='text' className='form-control input-bg' defaultValue={scratchCardData[0].scratchCardTotalLimitCountPerUser} />
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col lg={6} md={6} sm={12}>
                                <span>Geography</span>
                                <input disabled type='text' className='form-control input-bg' defaultValue='India' />
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <span>Scratch Card Status</span>
                                <input disabled type='text' className='form-control input-bg' defaultValue={scratchCardData[0].scratchCardStatus} />
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={close}>Close</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
    }else{
        return(
            <span></span>
        )
    }
}

export default ScratchCardDetailModal
