import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {Row, Col} from 'react-bootstrap'
import {Button} from '@themesberg/react-bootstrap';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Checkbox, Select, TextField } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CountryModal from '../../modals/CountryModal';
import { createCouponsAction } from '../../../redux/action/playstarzbankAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faRubleSign, faTrash} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import { Breadcrumb } from "react-bootstrap";

const getDatafromLS=()=>{
    const data = localStorage.getItem('rules');
    if(data){
      return JSON.parse(data);
    }
    else{
      return []
    }
}

function CreateCoupan(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const [Rules, setRules] = useState(getDatafromLS())

    const Countrydata = [{CountryName: 'India', id:1}]
    const[country, setCountry] = useState(Countrydata)
    const[countrynew, setCountryNew] = useState()

    const icon = <CheckBoxOutlineBlankIcon fontSize = "small" />
    const checkedIcon = <CheckBoxIcon fontSize = "small" />

    const [deleteShow, setDeleteShow] = useState(false);
    
    const handleDeleteClose = () => {
        setDeleteShow(false)
    }
    const handleDeleteShow = () => {
        setDeleteShow(true)
    }

    const [couponName, setCouponName] = useState()
    const [couponDescription, setCouponDiscription] = useState()
    const [couponCashbackType, setCashbackType] = useState()
    const [maxCashback, setMaxCashBack] = useState()
    const [couponTotalLimitCount, setCoponLimit] = useState()
    const [couponTotalLimitCountPerUser, setCouponLimitPerUser] = useState()
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [startTime, setStartTime] = useState("")
    const [endTime, setEndTime] = useState("")
    const [referralRewardsTypeId, setReferralRewardsTypeId] = useState()
    const [cashbackAmount, setCashbackAmount] = useState()
    const [factName, setFactname] = useState()
    const [operator, setOperator] = useState()
    const [value, setValue] = useState()
    const [showFact, setShowFact] = useState(false)
    const [dataType, setDataType] = useState()

    const [couponNameErr, setCouponNameError] = useState(false)
    const [couponDescErr, setDescriptionError] = useState(false)
    const [cashbackTypeErr, setCashbackTypeError] = useState(false)
    const [maxCashbackErr, setMaxCashBackError] = useState(false)
    const [totalError, setTotalError] = useState(false)
    const [totalperUserErr, setTotalPerError] = useState(false)
    const [startDateErr, setStartDateError] = useState(false)
    const [endDateErr, setEndDateError] = useState(false)
    const [startTimeErr, setStartTimeError] = useState(false)
    const [endTimeError, setEndTimeError] = useState(false)
    const [rewardTypeError, setRewardTypeError] = useState(false)
    const [factNameErr, setFactError] = useState(false)
    const [operatorErr, setOperatorError] = useState(false)
    const [valueErr, setValueError] = useState(false)
    const [dataTypeErr, setDataTypeError] = useState(false)
    const [sbError, setCBError] = useState(false)
    const[countryError, setCountryError] = useState(false);

    const validation = () => {
        if(couponName === "" || couponName === null || couponName === undefined){
            setCouponNameError(true)
        }
        if(couponDescription === "" || couponDescription === null || couponDescription === undefined){
            setDescriptionError(true)
        }
        if(couponCashbackType === "" || couponCashbackType === null || couponCashbackType === undefined){
            setCashbackTypeError(true)
        }
        if(maxCashback === "" || maxCashback === null || maxCashback === undefined){
           setMaxCashBackError(true)
        }
        if(couponTotalLimitCount === "" || couponTotalLimitCount === null || couponTotalLimitCount === undefined){
            setTotalError(true)
        }
        if(couponTotalLimitCountPerUser === "" || couponTotalLimitCountPerUser === null || couponTotalLimitCountPerUser === undefined){
            setTotalPerError(true)
        }
        if(startDate === "" || startDate === null || startDate === undefined){
            setStartDateError(true)
        }
        if(endDate === "" || endDate === null || endDate === undefined){
            setEndDateError(true)
        }
        if(startTime === "" || startTime === null || startTime === undefined){
            setStartTimeError(true)
        }
        if(endTime === "" || endTime === null || endTime === undefined){
            setEndTimeError(true)
        }
        if(cashbackAmount === "" || cashbackAmount === null ||cashbackAmount === undefined){
            setCBError(true)
        }
    }

    const onCouponName = (e) => {
        setCouponName(e.target.value)
        setCouponNameError(false)
    }

    const onCouponDesciption = (e) => {
        setCouponDiscription(e.target.value)
        setDescriptionError(false)
    }

    const onRewardType = (e) => {
        setCashbackType(parseInt(e.target.value))
        setCashbackTypeError(false)
    }

    const onCBAmount = (e) => {
        setMaxCashBack(e.target.value)
        setMaxCashBackError(false)
    }

    const onStartDate = (e) => {
        setStartDate(e.target.value)
        setStartDateError(false)
    }
  
    const onEndDate = (e) => {
        setEndDate(e.target.value)
        setEndDateError(false)
    }

    const onStartTime = (e) => {
        setStartTime(e.target.value)
        setStartTimeError(false)
    }

    const onEndTime = (e) => {
        setEndTime(e.target.value)
        setEndTimeError(false)
    }

    const onTotalCoupon = (e) => {
        setCoponLimit(parseInt(e.target.value))
        setTotalError(false)
    }

    const onMaxLimitCoupon = (e) => {
        setCouponLimitPerUser(parseInt(e.target.value))
        setTotalPerError(false)
    }
    
    const onCashback = (e) => {
        setCashbackAmount(parseInt(e.target.value))
        setCBError(false)
    }

    const onFactName = (e) => {
        setFactname(e.target.value)
        setFactError(false)
    }

    const onOperator = (e) => {
        setOperator(e.target.value)
        setOperatorError(false)
    }

    const onValue = (e) => {
        setValue(parseInt(e.target.value))
        setValueError(false)
    }

    const onDataChange = (e) => {
        setDataType(parseInt(e.target.value))
        setDataTypeError(false)
    }

    const Showfact = () => {
        setShowFact(true)
    }

    const onsave = () => {
        // setShowFact(false)
        RuleValidation();
        let newRules = {
                fact: factName,
                operator: operator,
                value: value
        }
        if(newRules.factName === "" || newRules.operator === "" || newRules.value === undefined){
            RuleValidation();
        }else{ 
            setRules([...Rules, newRules])
        }
        
    }

    const RuleValidation = () => {
        if(factName === "" || factName === null || factName === undefined){
            setFactError(true)
        }
        if(operator === "" || operator === null || operator === undefined){
            setOperatorError(true)
        }
        if(value === "" || value === null || value === undefined){
            setValueError(true)
        }
        if(dataType === "" || dataType === null || dataType === undefined){
            setDataTypeError(true)
        }
    }

    /* Data to DB */
    const [stateData, setStateData] = useState([])
    const a = (data) =>{
        setStateData(data)
    }
    
    /* Data for Mapping */
    const [exclude, setExclude] = useState([])
    const statesShow = (data) => {
        setExclude(data)
    }

    useEffect(()=>{
        localStorage.setItem('rules',JSON.stringify(Rules));
        const Token = localStorage.getItem('rules')
      },[Rules])

    const SaveCoupon = () => {
        const newCoupon = {
            couponName : couponName,
            couponDescription : couponDescription,
            couponCashbackType : maxCashback,
            maxCashback : cashbackAmount,
            couponTotalLimitCount : couponTotalLimitCount,
            couponTotalLimitCountPerUser : couponTotalLimitCountPerUser,
            startDate : startDate.concat(" ", startTime),
            endDate : endDate.concat(" ", endTime),
            couponRule : {
                all : Rules
            },
            referralRewardsTypeId : couponCashbackType,
            couponGeo : [{
                countryId : 1,
                stateId : stateData
            }]
        }
        if(newCoupon.couponName === "" || newCoupon.couponDescription === "" || newCoupon.couponCashbackType === "" || newCoupon.maxCashback === undefined || newCoupon.couponTotalLimitCount === undefined || newCoupon.couponTotalLimitCountPerUser === undefined || newCoupon.startDate === ""|| newCoupon.endDate === ""|| newCoupon.referralRewardsTypeId === undefined){
            validation()
            dispatch(createCouponsAction(newCoupon))
        }else{
            dispatch(createCouponsAction(newCoupon, history))
        }
       
    }

    return (
        <>
        <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                        <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                            <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                            <Breadcrumb.Item href='#coupons'>Coupons</Breadcrumb.Item>
                            <Breadcrumb.Item className="text-dark" active>Create Coupon</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5 gamestarz">
                <div className="container coupon">
                    <Row className="my-3">
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <h4>Create Coupon</h4>
                        </Col>
                    </Row>

                    <Row className="my-3">
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <label className="mb-2">Coupon Name</label>
                            <input type="text" className="form-control input_bg input_bg" onChange={(e)=>{onCouponName(e)}} />
                            {couponNameErr ==true ? <span className='text-danger'>Coupon Name is required.</span> : ""}
                        </Col>

                        <Col lg={6} md={6} sm={12} xs={12}>
                            <label className="mb-2">Reward Type</label>
                            <select className="form-select input_bg" onChange={(e)=>{onRewardType(e)}} >
                                <option>Choose Reward Type</option>
                                <option value="1">Cashback</option>
                                <option value="2">Voucher</option>
                                <option value="3">Virtual Currency</option>
                                <option value="4">Inventory Reward</option>
                            </select>
                            {cashbackTypeErr ==true ? <span className='text-danger'>Reward Type is required.</span> : ""}
                        </Col>
                    </Row>

                    <Row className="my-3">
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <label className="mb-2">Coupon Description</label>
                            <textarea rows={4} className="form-control input_bg" onChange={(e)=>{onCouponDesciption(e)}}></textarea>
                            {couponDescErr == true ? <span className='text-danger'>Coupon Description is required.</span> : ""}
                        </Col>
                    </Row>

                    {couponCashbackType === 1 ? 
                    <Row className="my-3">
                        <Col lg={6} md={6} sm={12}>
                            <label className="mb-2">Cashback Type</label>
                            <select className="form-select input_bg" onChange={(e)=>{onCBAmount(e)}}>
                                <option>Choose Cashback Type</option>
                                <option value="Percentage">Percentage</option>
                                <option value="flat">Fixed</option>
                            </select>
                            {maxCashbackErr == true ? <span className='text-danger'>Cashback is required.</span> : ""}
                        </Col>
                        {maxCashback === "flat" ?
                        <Col>
                            <label className="mb-2">Cashback Amount (Fixed)</label>
                            <input className="form-control input_bg" type="number" min="1" onChange={(e)=>{onCashback(e)}}/>
                            {sbError == true ? <span className='text-danger'>Cashback Amount is required</span> : ""}
                        </Col> : ""}
                    </Row> : ""}

                    {maxCashback === "Percentage" ?
                            <div>
                                <Row>
                                    <Col>
                                        <label className="mb-2">Cashback Amount (in %)</label>
                                        <input className="form-control input_bg" type="number" min="1" />
                                    </Col> 
                                    <Col>
                                        <label className="mb-2">Cashback Max Amount</label>
                                        <input className="form-control input_bg" type="number" min="1" onChange={(e)=>{onCashback(e)}}/>
                                    </Col> 
                                </Row>
                            </div>
                        : ""}

                    <Row className="my-3">
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <Row className="my-2">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <label>Validity Period</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <Row>
                                        <Col lg={6} md={6} sm={12}>
                                            <label>Start Date</label>
                                            <input type="date" className="form-control input_bg" min={new Date().toISOString().split('T')[0]} onChange={(e)=>{onStartDate(e)}}/>
                                            {startDateErr == true ? <span className='text-danger'>Start Date is required.</span> : ""}
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <label>Start Time</label>
                                            <input type="time" className="form-control input_bg" onChange={(e)=>{onStartTime(e)}}/>
                                            {startTimeErr == true ? <span className='text-danger'>Start Time is required.</span> : ""}
                                        </Col>
                                    </Row>
                                </Col>

                                <Col lg={6} md={6} sm={12}>
                                    <Row>
                                        <Col lg={6} md={6} sm={12}>
                                            <label>End Date</label>
                                            <input type="date" className="form-control input_bg" min={new Date().toISOString().split('T')[0]} onChange={(e)=>{onEndDate(e)}} />
                                            {endDateErr == true ? <span className='text-danger'>End Date is required.</span> : ""}
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <label>End Time</label>
                                            <input type="time" className="form-control input_bg"  onChange={(e)=>{onEndTime(e)}}/>
                                            {endTimeError == true ? <span className='text-danger'>End Time is required.</span> : ""}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                    </Row>

                    <Row className="my-3">
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <label>User Level</label>
                            <input type="number" min="1" className="form-control input_bg" disabled/>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <label>User Season Pass Tier</label>
                            <input type="text" className="form-control input_bg" disabled/>
                        </Col>
                    </Row>

                    {Rules && Rules.length !=0 ? 
                        <div>
                            <Row className="mt-2 mb-1 container">
                                <Col sm={12} md={12} lg={12}>
                                    <h6>Rules Created</h6>
                                </Col>
                            </Row>
                                                        
                            <Row className="mb-3">
                                <Col>
                                    <table class="table table-hover text-centered">
                                        <thead>
                                            <tr className='text-center'>
                                                <th scope="col">Fact Name</th>
                                                <th scope="col">Operator</th>
                                                <th scope="col">Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {Rules && Rules.map((e, i)=>{
                                            return(
                                                <tr className='text-center'>
                                                    <td>{e.fact}</td>
                                                    <td>{e.operator}</td>
                                                    <td>{e.value}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </Col>  
                            </Row>
                        </div>
                    : ""}

                    <Row className='my-2'>
                        <Col lg={4} md={4} sm={12}>
                            <label className="mb-2">Select Top Level</label>
                            <select className='form-select input_bg'>
                                <option>Select Top Level</option>
                                <option>All</option>
                                <option>Any</option>
                            </select>
                        </Col>
                        <Col lg={4} md={4} sm={12} className="mt-4 pt-2">
                            <button className='btn btn-outline-warning' onClick={Showfact}>Add Facts</button>
                        </Col>
                    </Row>

                    {showFact == true ?
                        <Row className='my-3'>
                            <Col lg={3} md={3} sm={12}>
                                <label className="mb-2">Fact Name</label>
                                <select className='form-select input_bg' onChange={(e)=>{onFactName(e)}}>
                                    <option>Select FactName</option>
                                    <option value="deposit">Deposit</option>
                                </select>
                                {factNameErr === true ? <span className='text-danger'>Fact Name is required</span> : ""}
                            </Col>

                            <Col lg={3} md={3} sm={12}>
                                <label className="mb-2">Data Type</label>
                                <select className='form-select input_bg' onChange={(e)=>{onDataChange(e)}}>
                                    <option>Select Data Type</option>
                                    <option value="1">number</option>
                                    <option value="2">string</option>
                                    <option value="3">array</option>
                                </select>
                                {dataTypeErr === true ? <span className='text-danger'>Data Type is required</span> : ""}
                            </Col>
                        {dataType === 1 ?
                            <Col lg={3} md={3} sm={12}>
                                <label className="mb-2">Operator</label>
                                <select className='form-select input_bg' onChange={(e)=>{onOperator(e)}}>
                                    <option>Select Operator</option>
                                    <option value="lessThan">Less Than</option>
                                    <option value="lessThanInclusive">Less Than Inclusive</option>
                                    <option value="greaterThan">Greater Than</option>
                                    <option value="greaterThanInclusive">Greater Than Inclusive</option>
                                </select>
                                {operatorErr === true ? <span className='text-danger'>Operator is required</span> : ""}
                            </Col>
                            : dataType === 2 ? 
                            <Col lg={3} md={3} sm={12}>
                                <label className="mb-2">Operator</label>
                                <select className='form-select input_bg' onChange={(e)=>{onOperator(e)}}>
                                    <option>Select Operator</option>
                                    <option value="equal">Equal</option>
                                    <option value="notEqual">Not Equal</option>
                                </select>
                                {operatorErr === true ? <span className='text-danger'>Operator is required</span> : ""}
                            </Col> : dataType === 3 ?
                            <Col lg={3} md={3} sm={12}>
                                <label className="mb-2">Operator</label>
                                <select className='form-select input_bg' onChange={(e)=>{onOperator(e)}}>
                                    <option>Select Operator</option>
                                    <option value="in">In</option>
                                    <option value="notIn">Not In</option>
                                    <option value="contains">Contains</option>
                                    <option value="doesNotContain">Does Not Contains</option>
                                </select>
                                {operatorErr === true ? <span className='text-danger'>Operator is required</span> : ""}
                            </Col>
                        : ""}
                        
                        {dataType === 1 ?
                            <Col lg={3} md={3} sm={12}>
                                <label className="mb-2">Value</label>
                                <input type='number' className='form-control input_bg' onChange={(e)=>{onValue(e)}}/>
                                {valueErr === true ? <span className='text-danger'>Value is required</span> : ""}
                            </Col>
                        : dataType === 2 ?
                            <Col lg={3} md={3} sm={12}>
                                <label className="mb-2">Value</label>
                                <input type='text' className='form-control input_bg' onChange={(e)=>{onValue(e)}}/>
                                {valueErr === true ? <span className='text-danger'>Value is required</span> : ""}
                            </Col>
                        : dataType === 3 ?
                        <Col lg={3} md={3} sm={12}>
                            <label className="mb-2">Value</label>
                            <input type='text' className='form-control input_bg' onChange={(e)=>{onValue(e)}}/>
                            {valueErr === true ? <span className='text-danger'>Value is required</span> : ""}
                        </Col>
                        : ""}
                        
                    </Row>

                    : ""}

                    {showFact == true && dataType != null ?
                        <Row className='text-left mb-2'>
                            <Col lg={12} md={12} sm={12}>
                                <button className='btn btn-outline-success' onClick={onsave}>Save</button>
                            </Col>
                        </Row>
                    : ""}
                    

                    <Row>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <label className="mb-2">Max Coupons Available</label>
                            <input type="number" min="1" className="form-control input_bg" onChange={(e)=>{onTotalCoupon(e)}}/>
                            {totalError == true ? <span className='text-danger'>Maximum Coupons is required</span> : ""}
                        </Col>

                        <Col lg={6} md={6} sm={12} xs={12}>
                            <label className="mb-2">Max Coupon Limit per User</label>
                            <input type="number" min="1" className="form-control input_bg" onChange={(e)=>{onMaxLimitCoupon(e)}}/>
                            {totalperUserErr == true ? <span className='text-danger'>Maximum Coupons Limit is required</span> : ""}
                        </Col>
                    </Row>

                    <Row className='my-2'>
                        <Col lg={6} md={6} sm={12} xs={12}>
                        <label className="mb-3" for="IncludeCountry">Select Countries</label>
                        <Autocomplete multiple disableCloseOnSelect filterSelectedOptions 
                            options={country} getOptionLabel={(option)=> option.CountryName}
                            onChange={(e,newvalue)=>{setCountryNew(newvalue); setCountryError(false)}}
                            renderOption={(option, {selected})=>(
                                <React.Fragment>
                                    <Checkbox icon={icon}
                                    checkedIcon={checkedIcon} style={{marginLeft: "10px"}}
                                    checked={selected}  allowSelectAll={true} onClick={() => handleDeleteShow()}
                                    // onClick={() => handleDeleteShow()}
                                    />
                                    {option.CountryName}
                                    {/* &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <span onClick={() => handleDeleteShow()}>Edit</span> */}
                                </React.Fragment>  
                            )}
                            renderInput={(params)=>(<TextField {...params} variant="outlined" />)} />
                        </Col>
                    </Row>

                    <Row className="my-5">
                        <Col>
                            <button className="btn btn-success" onClick={SaveCoupon}>Create Coupon</button>
                        </Col>
                    </Row>

                </div>

                <CountryModal show={deleteShow} onHide={handleDeleteClose} filter={a} ExcludedState={statesShow}></CountryModal>
            </div>
        </>
    )
}

export default  CreateCoupan