import React, {useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import { DropzoneArea } from 'material-ui-dropzone'
import { createStyles, makeStyles } from '@material-ui/core/styles';
import '../css/AddGame.css'
import { common, green } from '@material-ui/core/colors';
import XLSX from 'xlsx'
import  TablePagination  from '../components/TablePagination';
import { colors } from '@material-ui/core';
import { Breadcrumb } from "react-bootstrap";

function BulkPayout() {
    
    const useStyles = makeStyles(theme => createStyles({
        previewChip: {
          minWidth: 160,
          maxWidth: 210,
        },
      }));
      
    const classes = useStyles();

    const[colTitle, setColTitle] = useState()
    const[data, setData] = useState([])

    const convertToJson = (headers, data) =>{
        const rows = []
        data.forEach(row=>{
            let rowData = {}
            row.forEach((ele, index)=>{
                rowData[headers[index]] = ele;
            })
            rows.push(rowData)
        })
        return rows
    }
    const [fileName, setFilename] = useState("")
    const onDrop = (e) => {
        var file = e[0];
        setFilename(e[0].name)
        const reader = new FileReader()
        reader.onload=(event)=>{
            const bstr = event.target.result
            const workBook = XLSX.read(bstr, {type:"binary"})

            const worksheetName = workBook.SheetNames[0]
            const workSheet =workBook.Sheets[worksheetName]

            const fileData = XLSX.utils.sheet_to_json(workSheet, {header:1})

            const headers =fileData[0]
            fileData.splice(0,1)

            setData(convertToJson(headers, fileData))

            

            const heads = headers.map(head=>({title:head, field: head}))
            setColTitle(heads)
        }
        
        reader.readAsBinaryString(file)
        
    }

    let total = 0
    data.forEach((e,i)=>{
        total = total + e.TransactionAmount 
    })

    const [currentPage, setCurrentPage] = useState(1);
    const [entryPerPage, setEntryPerPage] = useState(10)


    const indexOfLastEntry = currentPage * entryPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entryPerPage;
    const currentEntry = data.slice(indexOfFirstEntry, indexOfLastEntry)
 
    const Paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const changePageEntry = (e) => {
        const chanegedPage = parseInt(e.target.value)
        setEntryPerPage(chanegedPage)
    }

    const [schedule, setSchedule] = useState()
    const onSchedule = (e) => {
        setSchedule(e.target.value)
    }

    return (
        <>
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                <Row>
                    <Col lg={12}>
                        <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                            <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                            <Breadcrumb.Item href='#payout'>Payout</Breadcrumb.Item>
                            <Breadcrumb.Item className="text-dark" active>Bulk Payout</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5">
                <div className="container">
                    <Row className="my-3">
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <h4>Bulk Payout</h4>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <label className="mb-2">Transaction Source</label>
                            <input type="text" disabled value="Playstarz Bank" className="form-control" />
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <label className="mb-2">Upload file (.xlsx or .csv)</label>
                            <DropzoneArea useChipsForPreview previewGridProps={{container: { spacing: 1, direction: 'row' }}} previewChipProps={{classes: { root: classes.previewChip } }}
                                showPreviews={true} showPreviewsInDropzone={false} dropzoneText="Drop files here to upload" filesLimit={50} 
                                onDrop={(e)=> onDrop(e)}></DropzoneArea>
                                {/* <input type="file" onChange={(e)=>{onDrop(e)}} className="form-control"/> */}
                        </Col>
                    </Row>
                    {data && data !="" && data[0].TransactionContact ? 
                        <div>
                            <Row className="my-3">
                                <p>Batch Name : {fileName}</p>
                                <p>Payouts Count : {data.length}</p>
                                <p>Payouts Total Amount : &#8377; {total}</p>
                            </Row>
                            <Row>
                                <Col style={{overflowX : "scroll"}} md={12}>
                                    <table className="table table-striped text-center">
                                        <thead>
                                            <tr>
                                                <th scope="col">Transaction Contact</th>
                                                <th scope="col">Transaction Recipient</th>
                                                <th scope="col">Transaction Amount</th>
                                                <th scope="col">Transaction Purpose Type</th>
                                                <th scope="col">Transaction Purpose</th>
                                                <th scope="col">Transaction Event</th>
                                                <th scope="col">Transaction Notes (Optional)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentEntry && currentEntry.map((e)=>{
                                                return(
                                                    <tr>
                                                        <td>{e.TransactionContact}</td>
                                                        <td>{e.TransactionRecipient}</td>
                                                        <td>{e.TransactionAmount}</td>
                                                        <td>{e.TransactionPurposeType}</td>
                                                        <td>{e.TransactionPurpose}</td>
                                                        <td>{e.TransactionEvent}</td>
                                                        <td>{e.TransactionNotes}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </div>
                    : ""}
                            {data && data != "" &&  data[0].TransactionContact ?
                                <div className="mt-3">
                                    <Row>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <Col>
                                                    <label style={{paddingRight: "10px"}}>Rows Per Page</label>
                                                    <select onChange={(e)=>{changePageEntry(e)}}>
                                                        <option value="10">10</option>
                                                        <option value="20">20</option>
                                                        <option value="30">30</option>
                                                    </select>
                                                </Col>
                                            </div>
                                            <div>
                                                <Col>
                                                    <TablePagination entryPerPage={entryPerPage} totalEntry={data.length} Paginate={Paginate}/>
                                                </Col>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            : data && data != ""  ? <div><p className="text-danger font-weight-bold">Please upload valid file.</p></div> : ""}
                        <Row>
                            <Col lg={12} md={12} sm={12} className="mt-4">
                                <label className="mb-2 mr-3">Payout Scheduling</label>
                                <input className="form-check-input mx-3" type="radio" value="Immediate" name="schedule" onChange={(e)=>{onSchedule(e)}}/>
                                <label className="form-check-label" for="flexRadioDefault1">Immediate</label>
                                <input className="form-check-input mx-3" type="radio" value="Scheduled" name="schedule" onChange={(e)=>{onSchedule(e)}}/>
                                <label className="form-check-label" for="flexRadioDefault1">Set Date/Time</label>
                            </Col>
                        </Row>
                        {schedule === "Scheduled" ? 
                        <Row className="my-3 d-flex align-items-center">
                            <Col lg={6} md={6} sm={12}>
                                <label className="mb-2">Set Date</label>
                                <input type="date" className="form-control" min={new Date().toISOString().split('T')[0]}/>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <label className="mb-2">Set Time</label>
                                <input type="time" className="form-control" />
                            </Col>
                        </Row> : ""}
                        <Row className="my-4">
                            <Col lg={12} md={6} sm={12}>
                                <button className="btn btn-success">Create Payout</button>
                            </Col>
                        </Row>
                </div>
            </div>
        </>
    )
}

export default BulkPayout