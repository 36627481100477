import React, {useEffect, useState} from 'react'
import {Dropdown, Row, Col, ButtonGroup, Button, InputGroup, Form, Nav} from '@themesberg/react-bootstrap'
import XLSX from 'xlsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../utlis/interceptor';
import { BASE_URL, GET_TEMPLATES } from '../../resources/APIEndpoints';
import  TablePagination  from '../components/TablePagination';
import { useDispatch } from 'react-redux';
import { editTemplatesAction } from '../../redux/action/competitionAction';
import { useHistory } from 'react-router-dom';

function EditTemplates(props) {

    const dispatch = useDispatch()
    const history = useHistory()

    const [Competition, setCompetition] = useState("")
    const [templateName, setTemplateName] = useState("")
    const [entryFeeTotal, setEntryFeeTotal] = useState("")
    const [bonusEntryFee, setBonusEntryFee] = useState("")
    const [minPlayers, setMinPlayer] = useState("")
    const [maxPlayers, setMaxPlayer] = useState("")
    const [attempts, setAttempts] = useState("")
    const [entries, setEntries] = useState("")
    const [winners, setWinners] = useState("")
    const [userAmount, setUserAmount] = useState("")
    const [pdRule, setPDRule] = useState([])
    const [PDMode, setPDMode] = useState("")
    const [hostingFeeType, setHostingFeeType] = useState("")
    const [UserFee, setUserFee] = useState("")
    const [flatHost, setFlatHost] = useState("")
    const[colTitle, setColTitle] = useState()
    const[data, setData] = useState([])
    const [competitionFormat, setCompetitionFormat] = useState('')
    const[checkName, setCheckname] = useState('')
    const[totalAmount, setTotalAmount] = useState()

    useEffect(()=>{
        getTemplateById();
    }, [])

    const getTemplateById = async() => {

        let templateResponse = {
            templatesFilters : {
                id : props.location.id,
                pagination : {
                    offset : 0,
                    limit : 10
                }
            }
        }

        let res = await axios.post(BASE_URL + GET_TEMPLATES, templateResponse)
        setTemplateName(res.data.templateData[0].templatesName)
        setCheckname(res.data.templateData[0].templatesName)
        setCompetition(res.data.templateData[0].competitionFormatTypeMaster.id)
        setCompetitionFormat(res.data.templateData[0].competitionFormatTypeMaster.leaderBoardOutComeName)
        setEntryFeeTotal(res.data.templateData[0].entryFee)
        setBonusEntryFee(res.data.templateData[0].maxBonusLimit)
        setMinPlayer(res.data.templateData[0].minPlayers)
        setMaxPlayer(res.data.templateData[0].maxPlayers)
        setEntries(res.data.templateData[0].maxEntryAllowed)
        setWinners(res.data.templateData[0].numberOfWinner)
        setPDRule(res.data.templateData[0].prizeDistributionRule)
        setData(res.data.templateData[0].prizeDistributionRule)
        setPDMode(res.data.templateData[0].prizeDistributionMode)
        setHostingFeeType(res.data.templateData[0].hostingFeeType)
        setFlatHost(res.data.templateData[0].hostingFee)
        setAttempts(res.data.templateData[0].maxAttemptAllowed)
        setTotalAmount(res.data.templateData[0].totalAmount)
    }



    /*   Front End Pagination to view xlsx or csv data  */
        const [currentPage, setCurrentPage] = useState(1);
        const [entryPerPage, setEntryPerPage] = useState(10)
    
    
        const indexOfLastEntry = currentPage * entryPerPage;
        const indexOfFirstEntry = indexOfLastEntry - entryPerPage;
        const currentEntry = data.slice(indexOfFirstEntry, indexOfLastEntry)
     
        const Paginate = (pageNumber) => {
            setCurrentPage(pageNumber)
        }
    
        const changePageEntry = (e) => {
            const chanegedPage = parseInt(e.target.value)
            setEntryPerPage(chanegedPage)
        }
    
    
        const saveTemplates = () => {
            if(templateName == checkName){
                let saveTemplate = {
                    id : props.location.id,
                    competitionFormatTypeMasterId: Competition,
                    minPlayers: minPlayers,
                    maxPlayers: maxPlayers,
                    maxEntryAllowed: entries,
                    entryFee: entryFeeTotal,
                    maxBonusLimit: bonusEntryFee,
                    numberOfWinner: winners,
                    hostingFee: flatHost,
                    hostingFeeType: hostingFeeType,
                    hostingFeeprizeDistributionMode: PDMode,
                    prizeDistributionRule : data,
                    maxAttemptAllowed : attempts,
                }
                if(saveTemplate.prizeDistributionRule[0].TotalAmount == undefined || saveTemplate.prizeDistributionRule[0].TotalAmount == null){
                    let totalAmount;
                    if(hostingFeeType == 'percentage'){
                        totalAmount = (entryFeeTotal * maxPlayers * (1-(flatHost/100)))
                    }else{
                        totalAmount = (entryFeeTotal * maxPlayers - (maxPlayers*flatHost)) 
                    }
                    saveTemplate.totalAmount = totalAmount
                    dispatch(editTemplatesAction(saveTemplate, props.history))
                }else{
                    saveTemplate.totalAmount = saveTemplate.prizeDistributionRule[0].TotalAmount
                    delete saveTemplate.prizeDistributionRule[0].TotalAmount
                    dispatch(editTemplatesAction(saveTemplate, props.history))
                }
            }else{
                let saveTemplate = {
                    id : props.location.id,
                    competitionFormatTypeMasterId: Competition,
                    templatesName: templateName,
                    minPlayers: minPlayers,
                    maxPlayers: maxPlayers,
                    maxEntryAllowed: entries,
                    entryFee: entryFeeTotal,
                    maxBonusLimit: bonusEntryFee,
                    numberOfWinner: winners,
                    hostingFee: flatHost,
                    hostingFeeType: hostingFeeType,
                    hostingFeeprizeDistributionMode: PDMode,
                    prizeDistributionRule : data,
                    maxAttemptAllowed : attempts,
                }
                if(saveTemplate.prizeDistributionRule[0].TotalAmount == undefined || saveTemplate.prizeDistributionRule[0].TotalAmount == null){
                    let totalAmount;
                    if(hostingFeeType == 'percentage'){
                        totalAmount = (entryFeeTotal * maxPlayers * (1-(flatHost/100)))
                    }else{
                        totalAmount = (entryFeeTotal * maxPlayers - (maxPlayers*flatHost)) 
                    }
                    dispatch(editTemplatesAction(saveTemplate, props.history))
                }else{
                    saveTemplate.totalAmount = saveTemplate.prizeDistributionRule[0].TotalAmount
                    delete saveTemplate.prizeDistributionRule[0].TotalAmount
                    dispatch(editTemplatesAction(saveTemplate, props.history))
                }
            }
        }    

        const cancel = () => {
            history.push('/competition')
        }

        /* Prize Distribution */

        const [fileName, setFilename] = useState("")
        const[checkAmount, setCheckAmount] = useState()

        const convertToJson = (headers, data) =>{
            const rows = []
            data && data.forEach(row=>{
                if(row.length != 0){
                    let rowData = {}
                    row.forEach((ele, index)=>{
                        rowData[headers[index]] = ele;
                    })
                    rows.push(rowData)
                }
            })
            setCheckAmount(rows)
            return rows
    
        }

        const [GPD, setGPD] = useState()

        const onPD = (e) => {
            setGPD(false)
            var file = e.target.files[0];
            setFilename(e.target.files[0].name)
            const reader = new FileReader()
            reader.onload=(event)=>{
                const bstr = event.target.result
                const workBook = XLSX.read(bstr, {type:"binary"})
    
                const worksheetName = workBook.SheetNames[0]
                const workSheet =workBook.Sheets[worksheetName]
    
                const fileData = XLSX.utils.sheet_to_json(workSheet, {header:1})
    
                const headers = fileData[0]
                fileData.splice(0,1)
                
                setData(convertToJson(headers, fileData))
    
                const heads = headers.map(head=>({title:head, field: head}))
                setColTitle(heads)
            }
            reader.readAsBinaryString(file) 
        }

        const GenratePD = async() => {
            setGPD(true)
            let getPD = {
                playersNumber: maxPlayers,
                entryFee: entryFeeTotal,
                hostingFee: (flatHost/100)
            }
            if(getPD.playersNumber == '' || getPD.playersNumber == undefined || getPD.playersNumber == null || getPD.entryFee == '' || getPD.entryFee == undefined || getPD.entryFee == null || getPD.hostingFee == 0 || getPD.hostingFee == undefined || getPD.hostingFee == null){
                toast.error(`Maximum Players, Entry Fee and Hosting Fee is required`)
            }else{
                let res = await axios.post('https://staging.gamestarz.xyz/v1/admin/generatePrizeDistribution', getPD)
                setData(res.data.prizeDistributionData)
            }
        }

        return (
            <>
                <ToastContainer />
                <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
                    <Row>
                        <Col lg={12}>
                        <Breadcrumb listProps={{className: "breadcrumb-transparent text-secondary"}}>
                            <Breadcrumb.Item href='#overview'>Gamestarz</Breadcrumb.Item>
                            <Breadcrumb.Item href='#competition'>Competition</Breadcrumb.Item>
                            <Breadcrumb.Item className="text-dark" active>Edit Template</Breadcrumb.Item>
                        </Breadcrumb>
                        </Col>
                    </Row>
                </div>
                <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
                            
                        </Col>
                    </div>
                    <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5 create-competition">
                        <div className="container">
                            <Row className="mb-3">
                                <Col lg={12} md={12}>
                                    <p className='page-title'>Edit Template</p>
                                </Col>
                            </Row>

                            <Row className="my-3">
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <label className='mb-1' for="competitionFormat">Choose Competition Format</label>
                                    <select className="form-select input_bg" disabled>
                                        <option value={Competition}>{competitionFormat}</option>
                                        <option value="2">Tournament</option>
                                        <option value="3">Instant Battles</option>
                                        <option value="5">Bracket</option>
                                    </select>
                                </Col>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <label className='mb-1' for="templateName">Template Name</label>
                                        <input className='form-control input_bg' defaultValue={templateName} type='text' onChange={(e)=>{setTemplateName(e.target.value)}}/>
                                    </Col> 
                            </Row>
                                <div>
                                    <Row className="my-3">
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <label className='mb-1' for="entryFee">Entry Fee Total</label>
                                            <input className='form-control input_bg' defaultValue={entryFeeTotal} type='number' onChange={(e)=>{setEntryFeeTotal(parseInt(e.target.value))}}/>
                                        </Col>

                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <label className='mb-1' for="bonusWallet" >Entry Fee Bonus Wallet Limit</label>
                                            <input className='form-control input_bg' type='number' defaultValue={bonusEntryFee} onChange={(e)=>{setBonusEntryFee(parseInt(e.target.value))}}/>
                                        </Col>
                                    </Row>

                                    <Row className="my-3">
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <label className='mb-1' for="minimumPlayer">Minimum Player</label>
                                            <input className='form-control input_bg' type='number' defaultValue={minPlayers} onChange={(e)=>{setMinPlayer(parseInt(e.target.value))}}/>
                                        </Col>

                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <label className='mb-1' for="maximumPlayer" >Maximum Player</label>
                                            <input className='form-control input_bg' type='number' defaultValue={maxPlayers} onChange={(e)=>{setMaxPlayer(parseInt(e.target.value))}}/>
                                        </Col>
                                    </Row>

                                    <Row className="my-3">
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <label className='mb-1' for="noOfAttempts">Number Of Attempts</label>
                                            <input className='form-control input_bg' type='number' disabled={Competition == 2 ? false : Competition == 3 ? true : false} defaultValue={entries} onChange={(e)=>{setEntries(parseInt(e.target.value))}}/>
                                        </Col>

                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <label className='mb-1' for="noOfWinners">Number Of Winners</label>
                                            <input className='form-control input_bg' type='text' defaultValue={winners} onChange={(e)=>{setWinners(parseInt(e.target.value))}}/>
                                        </Col>
                                    </Row>
                                    
                                    <Row className="my-3">
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <label className='mb-1' for="noofEntry">Number Of Entries</label>
                                            <input className='form-control input_bg' type='number' disabled={(Competition == 3 || Competition == 2) ? true : false} defaultValue={attempts} onChange={(e)=>{setAttempts(parseInt(e.target.value))}}/>
                                        </Col>

                                        <Col xl={6} lg={6} md={6} sm={12} xs={12} className='input-file'>
                                            <label className='mb-1' for="noOfWinners">Prize Distribution</label> <br />
                                            <div className='d-flex'>
                                                <input id='PD' type="file" accept=".csv, .xlsx" className="form-control" onChange={(e)=>{onPD(e)}}/>
                                                <label for='PD' className='label-btn'><FontAwesomeIcon icon={faPlusCircle}/> Choose File</label>
                                                <Button className='label-btn mx-3' onClick={GenratePD}>Genrate PD</Button>
                                            </div>
                                        </Col>

                                    </Row>

                                    {/* View Uploaded xlsx or csv File */}

                                    {data && data !="" && data[0].startRank ? 
                                        <div>
                                        <Row>
                                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <table className="table table-striped text-center table-custom">
                                                <thead className='table-head'>
                                                    <tr>
                                                        <th className='head-border-left' scope="col">Rank</th>
                                                        <th className='head-border-right' scope="col">Prize</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentEntry && currentEntry.map((e)=>{
                                                        return(
                                                            <tr className='table-body'>
                                                                <td>{e.startRank} {e.endRank ? "-" : ""} {e.endRank}</td>
                                                                <td>{e.amount}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                                </table>
                                            </Col>
                                        </Row>
                                </div>
                            : ""}
                            {data && data != "" && data[0].startRank && data[0].amount ?
                                <Row>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <Col>
                                                <TablePagination entryPerPage={entryPerPage} totalEntry={data.length} Paginate={Paginate}/>
                                            </Col>
                                        </div>
                                        <div>
                                            <Col>
                                                <label style={{paddingRight: "10px"}}>Rows Per Page</label>
                                                <select className='entries' onChange={(e)=>{changePageEntry(e)}}>
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="30">30</option>
                                                </select>
                                            </Col>
                                        </div>
                                    </div>
                                </Row>
                            : data && data != ""  ? <div><p className="text-danger font-weight-bold">Please upload valid file.</p></div> : ""}

                                    <Row className="my-3">
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <label className='mb-1' for="prizeUser">Prize Distribution Mode</label>
                                            <select className='form-select input_bg' onChange={(e)=>{setPDMode(e.target.value)}}>
                                                <option disabled selected>{PDMode}</option>
                                                <option value="dynamic">Dynamic</option>
                                                <option value='guaranteed'>Guaranteed</option>
                                            </select>
                                        </Col>
                                        
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <label className='mb-1' for="prizeUser">Hosting Fee Per User</label>
                                            <select className='form-select input_bg' onChange={(e)=>{setHostingFeeType(e.target.value)}}>
                                                <option disabled selected>{hostingFeeType}</option>
                                                <option value="percentage">Percentage</option>
                                                <option value='flat'>Flat</option>
                                            </select>
                                        </Col>

                                    
                                    </Row>

                                    <Row className='my-3'>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12} className='input-file'>
                                        {hostingFeeType === "flat" ? 
                                            <div>
                                                <label>Hosting Fee Per User (flat)</label>
                                                <input className='form-control input_bg' type='text' defaultValue={flatHost} onChange={(e)=>{setFlatHost(parseInt(e.target.value))}}/>
                                            </div>
                                        : hostingFeeType === "percentage" ?
                                        <div>
                                            <label>Hosting Fee Per User (%)</label>
                                            <input className='form-control input_bg' type='text' defaultValue={flatHost} onChange={(e)=>{setFlatHost(parseInt(e.target.value))}}/>
                                        </div>
                                        : ""}
                                        </Col>
                                        {hostingFeeType === "percentage" ? 
                                            <Col lg={6} md={6} sm={12}>
                                                <label>Total Prize Money Available (for Percentage)</label>
                                                <input type = "number" className='form-control input_bg' disabled value = {entryFeeTotal * maxPlayers * (1-(flatHost/100))}/>
                                            </Col>
                                            : hostingFeeType === "flat" ?
                                            <Col lg={6} md={6} sm={12}>
                                            <label>Total Prize Money Available (for Flat)</label>
                                            <input type = "number" className='form-control input_bg' disabled value = {entryFeeTotal * maxPlayers - (maxPlayers*flatHost)} />
                                        </Col>: ""}
                                    </Row>

                                    <Row className='my-2'>
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <button className='btn btn-success' onClick={saveTemplates}>Save Template</button>
                                            <button className='btn btn-danger mx-2' onClick={cancel}>Cancel</button>
                                        </Col>
                                    </Row>

                                </div>
                        </div>
                    </div>
            </>
        )
}

export default EditTemplates