import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import { Link} from "react-router-dom";
import { Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from 'react-redux';

function viewPDDetails({show, onHide}) {
        
    const close = () => {
            onHide();
    }
    
    return (
            <div>
                <Modal scrollable={true} size="lg" centered show={show} onHide={onHide}>
                    <Modal.Header>
                        <div className="modal_head">
                            <h6>PD Detail</h6>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg={6}>
                                <p>Leaderboard Name : zb</p>
                            </Col>
                            <Col lg={6}>
                                <p>GAME NAME : New_Game_For_Testing	</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <p>GAME WORLD : casino</p>
                            </Col>
                            <Col lg={6}>
                                <p>MATCH NAME : zb</p>	
                            </Col>
                        </Row>
    
                        <Row>
                            <Col lg={6}>
                                <p>	MATCH FORMAT : Multiplayer</p>
                            </Col>
                            <Col lg={6}>
                                <p>LEADERBOARD OUTCOME TYPE : High Score</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={6}>
                                <p> NO OF WINNERS : 10</p>
                            </Col>
                            <Col lg={6}>
                                <p>Minimum Player : 50</p>
                            </Col>
                        </Row>
    
                    </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col>
                            <Button className="btn btn-danger btn -sm mx-2" onClick={close}>Cancel</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal> 
        </div>
    )
}

export default viewPDDetails