import React, {useState, useEffect} from 'react'
import {Row, Col, Button} from '@themesberg/react-bootstrap'
import XLSX from 'xlsx'
import MaterialTable from "material-table";
import  TablePagination  from './components/TablePagination';
import axios from 'axios';
import customAxios from '../utlis/interceptor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from 'react-redux';
import { Leaderboards } from '../constants/LabelConstants';
import { createPlaystarzAction, getPPSALLAction, editPPSALLAction } from '../redux/action/leaderboardAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlus, faTrash, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { BASE_URL , GET_PLAYSTARZ_SCORING} from '../resources/APIEndpoints';
import { Breadcrumb } from "react-bootstrap";
import { CSVLink, CSVDownload } from "react-csv";

export default function PPSALL(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    
    useEffect(() => {
        const PPSALL =  {

        }
        dispatch(getPPSALLAction(PPSALL))
    }, [])

   const [filesData, setFileData] = useState("") 

    const[colTitle, setColTitle] = useState()
    const[data, setData] = useState([])
    const convertToJson = (headers, data) =>{
        const rows = []
        data.forEach(row=>{
            let rowData = {}
            row.forEach((ele, index)=>{
                rowData[headers[index]] = ele;
            })
            rows.push(rowData)
        })
        return rows
    }

    const onCSV = async (e) => {
        var file = e.target.files[0];   
        const reader = new FileReader()
        reader.onload=(event)=>{
            const bstr = event.target.result
            const workBook = XLSX.read(bstr, {type:"binary"})

            const worksheetName = workBook.SheetNames[0]
            const workSheet =workBook.Sheets[worksheetName]

            const fileData = XLSX.utils.sheet_to_json(workSheet, {header:1})

            const headers =fileData[0]
            fileData.splice(0,1)

            setData(convertToJson(headers, fileData))

            const heads = headers.map(head=>({title:head, field: head}))
            setColTitle(heads)

        }
        reader.readAsBinaryString(file) 
        
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
    
        const res = await axios.get(`${BASE_URL}/upload-url?contentType=xlsx/xlsx`)
        setFileData(res.data.getUrl);
        var config = {
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
          }
          axios(config)
          .then(function (response) {

          })
          .catch(function (error) {
              toast.error("File not uploaded. Please try again!")
          }); 
        
    }
        // To Convert Base64 to Binary
        function convertDataURIToBinary(dataURI) {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));
          
            for(var i = 0; i < rawLength; i++) {
              array[i] = raw.charCodeAt(i);
            }
            return array;
        }
    
        // To Convert file into base64 format
        const convertbase64 = (file) => {
            return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
            })
        }

    const [currentPage, setCurrentPage] = useState(1);
    const [entryPerPage, setEntryPerPage] = useState(100)


    const indexOfLastEntry = currentPage * entryPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entryPerPage;
    const currentEntry = data.slice(indexOfFirstEntry, indexOfLastEntry)
 
    const Paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const changePageEntry = (e) => {
        const chanegedPage = parseInt(e.target.value)
        setEntryPerPage(chanegedPage)
    }

    let PPSALL = useSelector((state)=>{ 
        return state.leaderboard.PPSALL
    })

    let PPSALLID = useSelector((state)=>{ 
        return state.leaderboard.Id
    })

    let Link = useSelector((state)=>{
        return state.leaderboard.Link
    })


    const indexOfLastEntryAPI = currentPage * entryPerPage;
    const indexOfFirstEntryAPI = indexOfLastEntryAPI - entryPerPage;
    const currentEntryForAPI = PPSALL.slice(indexOfFirstEntryAPI, indexOfLastEntryAPI)
    
    const cancel = () => {
        setData([])
    }

    const Update = () => {
        const playstarzScoringEdit = {
            id : PPSALLID,
            playstarzScoring : data,
            playstarzScoringFileUrl : filesData
        }
        const headersData = Object.keys(data[0])
        if(headersData[0] === "ranks"){
            dispatch(editPPSALLAction(playstarzScoringEdit, props.history))
        }else{
            toast.error("Please upload valid file.")
        }
        
    } 

    const back = () => {
        history.push('/main-leaderboard');
        window.location.reload();
    }
    
    const save = () => {
        const playstarzScoring = {
            playstarzScoring : data,
            playstarzScoringFileUrl : filesData
        }
        const headersData = Object.keys(data[0])
        if(headersData[0] === "ranks"){
            dispatch(createPlaystarzAction(playstarzScoring, props.history))
            // history.push('/main-leaderboard');
            // window.location.reload()
        }else{
            toast.error("Please upload valid file.")
        }
        
    
    }  

    const [gameStarzScoring, setGamestarzScoring] = useState([])

    const getPPSALL = async() => {
        const gamestarzScoring =  {

        }

        let res = await customAxios.post(BASE_URL + GET_PLAYSTARZ_SCORING, gamestarzScoring)
        setGamestarzScoring(res.data.data[0].playstarzScoring)
    }

    useEffect(()=>{
        getPPSALL();
    }, [])
    
    
    let headers = [
        {label : 'Rank', key : 'ranks'},
        {label : 'Prize', key : 'points'}
    ]

    const csvReport = {
        filename : `PPSALL_${new Date().toISOString().slice(0, 10)}.csv`,
        headers : headers,
        data : gameStarzScoring
      }

    return (
        <>
            <ToastContainer />
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
                    
                </Col>
            </div>
            <div className="task-wrapper border bg-white border-light shadow-sm pt-5 rounded pb-5">
                <div className="container">
                    <Row>
                        <Col lg={10} md={10} >
                            <h5>Gamestarz Scoring</h5>
                        </Col>
                        <Col lg={2} md={2}>
                            {/* <Button onClick={back} variant="secondary" className="text-dark me-2">
                                <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                                <span>Back</span>
                            </Button> */}
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col></Col>
                    </Row>
                    <Row className="my-3">
                        <Col md={6}>
                            <label>{Leaderboards.Prize_Distribution}</label>
                            <input type="file" className="form-control" onChange={(e)=>{onCSV(e)}}/>
                            {/* <a href={Link != "" ? Link : ""} style={{fontSize : "11px", textDecoration: "underline"}}>Download File</a> */}
                            <CSVLink {...csvReport}>Download File</CSVLink>
                        </Col>
                    </Row>
                    {data && data !="" && data[0].ranks ? 
                        <Row>
                            <Col md={12}>
                                <table className="table table-striped text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">Rank</th>
                                            <th scope="col">Prize</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentEntry && currentEntry.map((e)=>{
                                            return(
                                                <tr>
                                                    <td>{e.ranks}</td>
                                                    <td>{e.points}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    : ""}
                    {data && data != "" && data[0].ranks && data[0].points ?
                        <Row>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <Col>
                                        <label style={{paddingRight: "10px"}}>Rows Per Page</label>
                                        <select onChange={(e)=>{changePageEntry(e)}}>
                                            <option value="100">100</option>
                                            <option value="150">150</option>
                                            <option value="200">200</option>
                                        </select>
                                    </Col>
                                </div>
                                <div>
                                    <Col>
                                        <TablePagination entryPerPage={entryPerPage} totalEntry={data.length} Paginate={Paginate}/>
                                    </Col>
                                </div>
                            </div>
                        </Row>
                    : data && data != "" && data[0].ranks && data[0].points ? <div><p className="text-danger font-weight-bold">Please upload valid file.</p></div> : ""}
                    
                     {PPSALL && PPSALL.length !=0 && data.length == 0 ?
                        <Row>
                        <Col md={12}>
                            <table className="table table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Rank</th>
                                        <th scope="col">Prize</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentEntryForAPI && currentEntryForAPI.map((e)=>{
                                        return(
                                            <tr>
                                                <td>{e.ranks}</td>
                                                <td>{e.points}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                : ""}
                {PPSALL && PPSALL.length !=0 && data.length == 0 ?
                    <Row className="my-2">
                    <div className="d-flex justify-content-between">
                        <div>
                            <Col>
                                <label style={{paddingRight: "10px"}}>Rows Per Page</label>
                                <select onChange={(e)=>{changePageEntry(e)}}>
                                    <option value="100">100</option>
                                    <option value="150">150</option>
                                    <option value="200">200</option>
                                </select>
                            </Col>
                        </div>
                        <div>
                            <Col>
                                <TablePagination entryPerPage={entryPerPage} totalEntry={PPSALL.length} Paginate={Paginate}/>
                            </Col>
                        </div>
                    </div>
                </Row>
                : ""}
                {PPSALL && PPSALL.length != 0 && data !=""  ?
                     <div>
                        <Row className="mt-3">
                            <Col>
                                <button className="btn btn-success mx-3" onClick={Update}>Update</button>
                                <button className="btn btn-danger" onClick={cancel}>Cancel</button>
                            </Col>
                        </Row>
                    </div>
                    : data && data != "" && PPSALL.length == 0 ?
                     <div>
                     <Row className="mt-3">
                         <Col>
                             <button className="btn btn-success mx-3" onClick={save}>Save</button>
                             <button className="btn btn-danger" onClick={cancel}>Cancel</button>
                         </Col>
                     </Row>
                 </div> : ""}
                </div>
            </div>
        </>
    )
}
