import React, {useState} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import {Row, Col} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrashAlt, faArchive, faTrash, faBan} from '@fortawesome/free-solid-svg-icons'

function DeletePD(props) {
    
    return (
        <React.Fragment>
           <Modal isOpen={props.addOpen} toggle={props.Addtoggle} centered>
               <ModalHeader><h6>Leaderboard Name</h6></ModalHeader>
               <ModalBody>
                   <Row className="text-center mt-3">
                        <Col>
                            <FontAwesomeIcon icon={faTrashAlt} className="me-2 fa-3x text-danger" />
                        </Col>
                   </Row>
                   <Row className="text-center mt-3">
                        <Col>
                            <h6>Do you want to Delete this Leaderboard?</h6>
                        </Col>
                   </Row>
                   <Row className="justify-content-center text-center my-3">
                        <Col lg={12} md={12} sm={12}>
                            <button className="btn btn-danger mx-2">Delete</button>
                            <button className="btn btn-warning mx-2" onClick={props.Addtoggle}>Cancel</button>
                        </Col>
                   </Row>
               </ModalBody>
           </Modal>
       </React.Fragment>
    )
}

export default DeletePD