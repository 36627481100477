/* Base URL */

export const BASE_URL = localStorage.getItem('type') === 'Development' ? "https://dev.gamestarz.xyz/v1/admin" : localStorage.getItem('type') === 'Staging' ? "https://staging.gamestarz.xyz/v1/admin" : localStorage.getItem('type') === 'Production' ? "https://api.gamestarz.xyz/v1/admin" : 'https://staging.gamestarz.xyz/v1/admin'

/* External Games */

export const EXTERNAL_GET_GAME = "/game-submission/getAllSubmittedGame"
export const EXTERNAL_EDIT_GAME = "/game-submission/editSubmissionGame"
export const EXTERNAL_SEARCH_GAMES = "/game-submission/search"

/* Game API EndPoints */

export const CREATE_GAME = "/game/createGame"
export const GET_GAME = "/game/getGame"
export const CREATE_MATCH = "/matches/createMatch"
export const EDIT_GAME = "/game/editGame"
export const MATCH_BY_GAME_ID = "/matches/createMatch"
export const SORT = "/game/getGameBySort"
export const SEARCH_GAMES = "/game/search"
export const DELETE_PLATFORM = "/gamePlatforms/deleteGamePlatforms"

/* Leaderboard API EndPoints */

export const GET_LEADERBOARD = '/leaderboard/getLeaderboard'
export const GET_UNIVERSAL_LEADERBOARD = "/leaderboard/getUniversalLeaderboardDetails"
export const CREATE_CUSTOM_LEADERBOARD = "/leaderboard/makeCustomLeaderboard"
export const GET_LEADERBOARD_DETAIL = "/leaderboard/getLeaderboardDetails"
export const GET_PLAYER_DISTRIBUTION = "/leaderboard/getPrizeDistrubutedLeaderboard"
export const SEARCH_LEADERBOARD = "/leaderboard/search"
export const CREATE_PD_LEADERBOARD = "/leaderboard/createPrizeDistribution"
export const CREATE_PLAYSTARZ_SCORING = "/playstarz-scoring/createPlaystarzScoring"
export const GET_PLAYSTARZ_SCORING = "/playstarz-scoring/getPlaystarzScoring"
export const EDIT_PLAYSTARZ_SCORING = "/playstarz-scoring/editPlaystarzScoring"
export const SEARCH_UNIVERSAL_LEADERBOARD = "/leaderboard/searchUniversalLeaderboardDetailsByPlayerId"
export const SEARCH_LEADERBOARD_DETAILS = '/leaderboard/searchLeaderboardDetailsByPlayerId'

/* Competition API EndPoints */

export const CREATE_COMPETITION = "/competition/createCompetition"
export const GET_COMPETITION = "/competition/getCompetition"
export const EDIT_COMPETITION = "/competition/editCompetition"
export const SEARCH_COMPETITION = '/competition/search'
export const GET_COMPETITION_DETAIL = "/leaderboard/getCompetitionLeaderboardDetails"
export const STOP_COMPETITION = '/competition/stop'
export const GET_COMPETITION_BY_GAME = '/competition/getCompetitionByGameId'
export const GET_ORGANIZED_COMPETITION = "/competitions-sorting/get"
export const CREATE_ORGANIZED_COMPETITION = "/competitions-sorting/create"
export const EDIT_ORGANIZED_COMPETITION = "/competitions-sorting/edit"

export const GET_TEMPLATES = '/templates/getTemplate'
export const CREATE_TEMPLATES = '/templates/createTemplate'
export const EDIT_TEMPLATES = '/templates/editTemplate'
export const SEARCH_TEMPLATE = '/templates/search'
export const GET_PRIZE_DISTRIBUTION = '/generatePrizeDistribution'

/* Playstarz Bank API EndPoints */

export const GET_TRANSACTION = "/wallet/getTransactions"
export const GET_COUPONS = '/coupon/getCoupon'
export const CREATE_COUPONS = '/coupon/createCoupon'
export const EDIT_COUPON = "/coupon/editCoupon"
export const SEARCH_COUPON = "/coupon/search"
export const GET_SCRATCH_CARD = '/scratch-card/getScratchCard'
export const ADD_SCRATCH_CARD = '/scratch-card/createScratchCard'
export const EDIT_SCRATCH_CARD = "/scratch-card/editScratchCard"
export const SEARCH_SCRATCH_CARD = '/scratch-card/search'
export const SEARCH_TRANSACTION = '/wallet/search'
export const SINGLE_PAYOUT = "/payout/manual"
export const GET_SINGLE_PAYOUT = "/payout/getManual"

/* Referral API EndPoints */

export const GET_REFERRAL = '/referral/getReferralActionRewardMapping'
export const ADD_REFERRAL = '/referral/referralActionRewardMapping'
export const EDIT_REFERRAL = '/referral/editReferralActionRewardMapping'

/* Version API EndPoints */

export const GET_VERSION = '/versions/get'
export const ADD_VERSION = '/versions/add'
export const EDIT_VERSION = '/versions/update'

/* Partners */

export const GET_PARTNERS = '/organization/getAllOrganization'

/* Authentication API EndPoints */

export const LOGIN = '/user/login'
export const LOGOUT = '/user/logout'

/* Analytics API EndPoints */

export const TOTAL_MONEY_COLLECTED = "/analytics/totalMoneyCollected"
export const TOTAL_HOSTING_FEE_COLLECTED = "/analytics/totalHostingFeeCollected"
export const SIGN_UP = "/analytics/totalSignUps"
export const TOTAL_LIVE_COMPETITIONS = '/analytics/totalLiveCompetitions'
export const TOTAL_CHALLENGES = '/analytics/totalChallenges'

/* Organization API EndPoints */

export const VERIFY_PARTNER = "/organization/verify"

/* Team API Endpoints */

export const GET_TEAM = '/teams/getTeams'
export const CREATE_TEAM = '/teams/createTeam'
export const GET_TEAM_BY_ID = "/teams/getTeams"
export const EDIT_TEAM = "/teams/updateTeam"

/* Business Logic Endpoints */

export const GET_ALL_CONDITIONTYPE = '/rule-engine/getAllConditionType'
export const GET_ALL_ATTRIBUTE_BY_CONDITIONTYPE = '/rule-engine/getAllAttributeByConditionType'
export const GET_RULE = "/rule-engine/getRule"
export const CREATE_RULE = "/rule-engine/createRule"
export const EDIT_RULE = '/rule-engine/editRule'
export const CREATE_CONDITIONTYPE = "/rule-engine/createConditionType"
export const CREATE_ATTRIBUTE = "/rule-engine/createAttribute"
export const GET_ALL_OPERATOR = '/rule-engine/getAllOperatorByDataType'
export const SEARCH_MODULE = '/rule-engine/moduleSearch'
export const SEARCH_ATTRIBUTE = '/rule-engine/attributeSearch'

/*  Document Endpoints  */

export const GET_DOCUMENTS = '/documents/get'
export const CREATE_DOCUMENTS = '/documents/create'
export const EDIT_DOCUMENTS = '/documents/edit'

/* USER Logo */

export const USER_EDIT = '/user/edit'